(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('userMessage',{
                parent: 'home2',
                url: '/userMessage/{mid}',
                data:{
                    authorities: [],
                    pageTitle: 'userMessage.home.title'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/user-message/user-message.html',
                        controller: 'UserMessageController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userMessage');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity:['$stateParams','UserMessage',function ($stateParams,UserMessage){
                        if ($stateParams.mid) {
                            return UserMessage.getOne({id: $stateParams.mid}).$promise;
                        }
                    }]
                }
            })
    }

})();
