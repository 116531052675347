(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal', '$uibModalInstance', 'AccountP', '$scope', 'MessageService', '$timeout', '$state', '$stateParams', '$cookieStore'];

    function PasswordController(Auth, Principal, $uibModalInstance, AccountP, $scope, MessageService, $timeout, $state, $stateParams, $cookieStore) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.clear = clear;
        vm.storeId = $stateParams.storeId|| $cookieStore.get("store-id");
        if (!vm.storeId) {
            return;
        }
        vm.check = {};
        vm.check.old = 0;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        Principal.identity().then(function (account) {
            vm.account = account;
        });

        $scope.checkOldPassword = function () {
            AccountP.checkPassword({id: vm.account.id, password: vm.oldPassword}, function (msg) {
                if (msg[0] == 't') {
                    vm.check.old = 1;
                    return
                }
                vm.check.old = 0;
                $(".yzmStatus i:eq(1)").show();
            })
        };

        function changePassword() {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                var user = {
                    name: vm.account.name,
                    password: vm.password
                };
                vm.doNotMatch = null;
                Auth.changePassword(user).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                    $timeout(function () {
                        $uibModalInstance.close();
                        $state.go('home');
                    }, 1000);
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
            var t1 = setInterval(function () {
                $uibModalInstance.close(true);
                window.clearTimeout(t1);
            }, 2000);

        } 
    }
})();
