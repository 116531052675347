(function () {
    'use strict';

    var styleSettingControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/controls/style-setting.control.html',
        controllerAs: 'vm',
        bindings: {
            style:'<',
            options:'<',
            obj:'<',
            settings:'<',
            onChange:'&'
        },

        controller: ['$scope','$timeout', function ($scope,$timeout) {
            var vm = this;
            vm.data = {};
            vm.textAligns=[
                {id:'left',value:'左对齐'},
                {id:'center',value:'居中'},
                {id:'right',value:'右对齐'},
            ]

            vm.$onInit = function() {
                // vm.data.settings = vm.settings;
                // if (vm.data.settings && !vm.data.settings.border.lineType) {
                //     vm.data.settings.border.lineType = 'solid';
                // }
            }
            // 每當 one-way binding 更新時，changesObj 就是改變前後的差異
            vm.$onChanges = function(data) {
                if(data.settings&&data.settings.currentValue){
                    vm.data.settings = data.settings.currentValue;
                }
                if (vm.data.settings && !vm.data.settings.border.lineType) {
                    vm.data.settings.border.lineType = 'solid';
                }
            }
            vm.$postLink = function () {

            }
            vm.$doCheck = function() {
                // deep watch
            }
            vm.$onDestroy = function () {

            }

            $scope.onCheckboxChange = function() {
                onSettingsChange();
            }

            $scope.onBorderInputChange = function(num) {
                onSettingsChange();
            }

            $scope.onColorChange = function(text) {
                onSettingsChange();
            }
            var a = null;
            $scope.onInputChange = function(num, index) {
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function () {
                    if(!num || num == 'undefined'){
                        vm.data.settings.items[index].textSize = 12;
                    }
                    $scope.onItemChange();
                },800)
            }

            $scope.onItemChange = function() {
                $timeout(function () {
                    onSettingsChange();
                })
            }

            function onSettingsChange() {
                if (vm.onChange) {
                    vm.onChange({text: JSON.stringify(vm.data.settings)});
                }
            }

        }]
    };
    angular.module('editorApp')
        .component('styleSetting', styleSettingControl);

})();
