(function () {
    'use strict';

    var articlePreview = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/product/article-preview.html',
        controllerAs: 'vm',
        bindings: {
            documentPage: '<',
            editorType: '<',
            article: '<',
            isLandscape: '<',
            isNav: '<',
            showMessage:'=',
        },

        controller: ['$scope', '$stateParams', '$q', '$localStorage', 'Qinius', 'Store', '$http', '$rootScope', 'Theme', 'Article',
            'FontManagement','OrderArticle', 'Convert', '$timeout', 'MessageService','MessageNotification','DateUtils',
            function ($scope, $stateParams, $q, $localStorage, Qinius, Store, $http, $rootScope, Theme, Article,
                      FontManagement, OrderArticle, Convert, $timeout, MessageService,MessageNotification,DateUtils) {
                var vm = this;
                vm.loading = true;
                vm.landscape = false;
                vm.thisPage = 0;
                vm.bindingtypes = [{id:'Tile',name:'平铺模式'}];
                vm.storeId = $stateParams.storeId != null ?  $stateParams.storeId: $localStorage.storeId;
                vm.$onInit = function(){
                    if(vm.documentPage.config && vm.documentPage.config.productType == 'BOOK'){
                        vm.bindingtypes = [
                            {id:'Left',name:'左右滑动'},
                            {id:'Tile',name:'平铺展示'},
                        ]
                        vm.bindingtype = "Left";
                    }
                    if(vm.documentPage.config && vm.documentPage.config.productType == 'CALENDAR'){
                        vm.bindingtypes = [
                            {id:'Top',name:'上下滑动'},
                            {id:'Tile',name:'平铺模式'},
                        ]
                        vm.bindingtype = 'Top';
                    }
                    editorInit()
                }
                vm.messageShow = true;
                vm.messageList = "";
                getMessageList();
                function getMessageList() {
                    MessageNotification.getAllByCondition({
                            items:[
                                {key:"pageType", op : "=", value:"View"},
                                {key:"storeId", op : "=", value:vm.storeId},
                                {key:"available", op : "=", value:"true"},
                                {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                        }, function (res) {
                            res.forEach(function (item, index) {
                                vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                            })
                        }
                    )
                }
                function editorInit() {
                    vm.windowW1 = 140;
                    vm.windowH1 = 140;
                    vm.insidePages = [];
                    $scope.template = angular.copy(vm.documentPage);
                    vm.parts = $scope.template.config.parts.list;
                    $scope.template.pages.page=[];
                    vm.documentPage.pages.page.forEach(function (page) {
                        if(!page.backup){
                            $scope.template.pages.page.push(angular.copy(page));
                        }
                    })
                    vm.isEdit = true;
                    documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                        page.custom.ratio1 = 140 / page.custom.width;
                        page.custom.ratio = 800 / page.custom.width;
                    }, function () {
                        $scope.template.pages.page.forEach(function (page) {
                            if (!page.scene) {
                                pageTrimbox(page)
                            }
                        })
                        $timeout(function () {
                            ratioInfo()
                            $timeout(function () {
                                if(vm.bindingtype == 'Left'||vm.bindingtype == 'Top'){
                                    Page.init();
                                }
                                $timeout(function () {
                                    vm.loading = false;
                                },100)
                            },500)
                        },1000)
                    })
                }
                function ratioInfo() {
                    vm.windowH = window.innerHeight-300;
                    if(vm.isLandscape){
                        vm.windowW = window.innerWidth-32;
                    }else{
                        vm.windowW = window.innerWidth*0.8;
                    }
                    vm.pageMaxWidth = 0;
                    vm.pageMaxHeight = 0;
                    var pageMaxWidtTile = 0;
                    $scope.template.pages.page.forEach(function (page) {
                        page.isUse = false;
                        if (pageMaxWidtTile < page.custom.width) {
                            pageMaxWidtTile = page.custom.width;
                        }
                    })
                    vm.ratioTile = vm.windowW * 0.7 / pageMaxWidtTile;
                    if(vm.bindingtype == "Left" || vm.bindingtype == "Tile"){
                        $scope.template.pages.page.forEach(function (page) {
                            page.isUse = false;
                            if(page.config.partid != 'P'){
                                if(page.config.partid == 'B1' || page.config.partid == 'B2'){
                                    if(vm.pageMaxWidth < page.custom.width){
                                        vm.pageMaxWidth = page.custom.width;
                                    }
                                    if(vm.pageMaxHeight < page.custom.height){
                                        vm.pageMaxHeight = page.custom.height;
                                    }
                                }
                            }else{
                                if(vm.pageMaxWidth < (page.spread ? page.custom.width : page.custom.width*2)){
                                    vm.pageMaxWidth = (page.spread ? page.custom.width : page.custom.width*2);
                                }
                                if(vm.pageMaxHeight < page.custom.height){
                                    vm.pageMaxHeight = page.custom.height;
                                }
                            }
                        })
                    }
                    if(vm.bindingtype == "Top"){
                        $scope.template.pages.page.forEach(function (page) {
                            page.isUse = false;
                            if(vm.pageMaxWidth < page.custom.width){
                                vm.pageMaxWidth = page.custom.width;
                            }
                            if(vm.pageMaxHeight < (page.spread ? page.custom.height : page.custom.height*2)){
                                vm.pageMaxHeight = (page.spread ? page.custom.height : page.custom.height*2);
                            }
                        })
                    }
                    if(!vm.landscape){
                        if(vm.windowH / vm.pageMaxHeight >= vm.windowW / vm.pageMaxWidth ){
                            vm.ratio = vm.windowW / vm.pageMaxWidth;
                        }else {
                            vm.ratio = vm.windowH / vm.pageMaxHeight;
                        }
                    }else{
                        if(vm.windowH / vm.pageMaxWidth >= vm.windowW / vm.pageMaxHeight ){
                            vm.ratio = vm.windowW / vm.pageMaxHeight;
                        }else {
                            vm.ratio = vm.windowH / vm.pageMaxWidth;
                        }
                    }
                    if(vm.bindingtype == "Top"){
                        vm.ratio1 = 160 / vm.pageMaxHeight;
                    }else{
                        vm.ratio1 = 80 / vm.pageMaxHeight;
                    }
                    vm.pageMaxWidth = vm.pageMaxWidth*vm.ratio;
                    vm.pageMaxHeight = vm.pageMaxHeight*vm.ratio;
                    $scope.template.pages.page.forEach(function (page, index) {
                        if(page.config.partid == 'P' || page.config.partid == 'B1' || page.config.partid == 'B2'){
                            page.ratio = vm.ratio;
                        }else{
                            var _width = (page.spread || page.config.partid != 'P' ? page.custom.width : page.custom.width*2);
                            var _height = page.custom.height;
                            if(vm.bindingtype == "Top"){
                                _width = page.custom.width;
                                _height = page.custom.height*2
                            }
                            if(!vm.landscape){
                                if(vm.windowH / _height >= vm.windowW / _width ){
                                    page.ratio = vm.windowW / _width;
                                }else {
                                    page.ratio = vm.windowH / _height;
                                }
                            }else{
                                if(vm.windowH / _width >= vm.windowW / _height ){
                                    page.ratio = vm.windowW / _height;
                                }else {
                                    page.ratio = vm.windowH / _width;
                                }
                            }
                        }
                        // if(index == 0){
                        //     vm.pageMaxWidth = _width * page.ratio;
                        //     vm.pageMaxHeight = _height * page.ratio;
                        // }else{
                            if(vm.pageMaxWidth < _width * page.ratio){
                                vm.pageMaxWidth = _width * page.ratio;
                            }
                            if(vm.pageMaxHeight < _height * page.ratio){
                                vm.pageMaxHeight = _height * page.ratio;
                            }
                        // }
                        for(var i=0; i<vm.parts.length; i++){
                            if(vm.parts[i].id == page.config.partid){
                                if(!vm.parts[i].pages){
                                    vm.parts[i].pages = [];
                                }
                                vm.parts[i].pages.push(page)
                                break
                            }
                        }
                    })
                    if($scope.template.config.productType == 'BOOK'||$scope.template.config.productType == 'CALENDAR'){
                        for(var i=0; i<vm.parts.length; i++){
                            if(vm.parts[i].pages &&vm.parts[i].pages.length>0){
                                if(vm.parts[i].id == 'P'){
                                    vm.parts[i].pages.forEach(function (page, index) {
                                        var emptyPage = angular.copy(page);
                                        if(!emptyPage.background){
                                            emptyPage.background = {
                                                color:'#000000',
                                                resource:{
                                                    identifier:''
                                                }
                                            }
                                        }else{
                                            emptyPage.background.color = "#000000";
                                            emptyPage.background.resource.identifier="";
                                        }
                                        emptyPage.barcode = null;
                                        emptyPage.qrcode = null;
                                        emptyPage.levels = {
                                            level: []
                                        };
                                        emptyPage.marginLeft = 0;
                                        if(!page.isUse){
                                            if(index == 0 && $scope.template.config.innerOffset == 1 && !page.spread){
                                                page.isUse = true;
                                                page.marginLeft = 0;
                                                if(page.spread){
                                                    page.custom.width = page.custom.width/2;
                                                    page.spread = false;
                                                }
                                                if(emptyPage.spread){
                                                    emptyPage.custom.width = emptyPage.custom.width/2;
                                                    emptyPage.spread = false;
                                                }
                                                emptyPage.marginLeft = vm.bindingtype == 'Left' ? vm.pageMaxWidth/2-emptyPage.custom.width * emptyPage.ratio : vm.pageMaxHeight/2-emptyPage.custom.height * emptyPage.ratio;
                                                setPageOrder(page)
                                                vm.insidePages.push({
                                                    page1:emptyPage,
                                                    page2:page,
                                                    partid: vm.parts[i].id,
                                                    spread:false
                                                })
                                            }else{
                                                if(page.spread){
                                                    page.isUse = true;
                                                    page.marginLeft = vm.bindingtype == 'Left' ? (vm.pageMaxWidth-page.custom.width * page.ratio) / 2 : (vm.pageMaxHeight-page.custom.height * page.ratio) / 2;
                                                    var _page = angular.copy(page);
                                                    _page.marginLeft = vm.bindingtype == 'Left' ? -_page.custom.width*_page.ratio/2 : -_page.custom.height*_page.ratio/2;
                                                    setPageOrder(page)
                                                    setPageOrder(_page)
                                                    vm.insidePages.push({
                                                        page1:page,
                                                        page2:_page,
                                                        partid: vm.parts[i].id,
                                                        spread:true
                                                    })
                                                }else{
                                                    var _page1 = vm.parts[i].pages[index];
                                                    var _page2 = index+1 >= vm.parts[i].pages.length ? null : vm.parts[i].pages[index+1];
                                                    _page1.isUse = true;
                                                    _page1.marginLeft = vm.bindingtype == 'Left' ? vm.pageMaxWidth/2-_page1.custom.width * _page1.ratio : vm.pageMaxHeight/2-_page1.custom.height * _page1.ratio;
                                                    setPageOrder(_page1)
                                                    if(_page2 && !_page2.spread){
                                                        _page2.isUse = true;
                                                        _page2.marginLeft = 0;
                                                        setPageOrder(_page2)
                                                        vm.insidePages.push({
                                                            page1:_page1,
                                                            page2:_page2,
                                                            partid: vm.parts[i].id,
                                                            spread:false
                                                        })
                                                    }else{
                                                        emptyPage.marginLeft = 0;
                                                        vm.insidePages.push({
                                                            page1:_page1,
                                                            page2:emptyPage,
                                                            partid: vm.parts[i].id,
                                                            spread:false
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }else{
                                    vm.parts[i].pages.forEach(function (page, index) {
                                        if($scope.template.config.productType == 'CALENDAR' && (vm.parts[i].id == 'B1' || vm.parts[i].id == 'B2')){
                                            if(page.spread){
                                                page.isUse = true;
                                                page.marginLeft = vm.bindingtype == 'Left' ? (vm.pageMaxWidth-page.custom.width * page.ratio) / 2 : (vm.pageMaxHeight-page.custom.height * page.ratio) / 2;
                                                var _page = angular.copy(page);
                                                _page.marginLeft = vm.bindingtype == 'Left' ? -_page.custom.width*_page.ratio/2 : -_page.custom.height*_page.ratio/2;
                                                vm.insidePages.push({
                                                    page1:page,
                                                    page2:_page,
                                                    partid: vm.parts[i].id,
                                                    spread:true
                                                })
                                            }else{
                                                var emptyPage = angular.copy(page);
                                                if(!emptyPage.background){
                                                    emptyPage.background = {
                                                        color:'',
                                                        resource:{
                                                            identifier:''
                                                        }
                                                    }
                                                }else{
                                                    emptyPage.background.color = "";
                                                    emptyPage.background.resource.identifier="";
                                                }
                                                emptyPage.boxShadow = true;
                                                emptyPage.barcode = null;
                                                emptyPage.qrcode = null;
                                                emptyPage.levels = {
                                                    level: []
                                                };
                                                emptyPage.marginLeft = 0;
                                                page.isUse = true;
                                                if(vm.parts[i].id == 'B1'){
                                                    page.marginLeft = 0;
                                                    emptyPage.marginLeft = vm.bindingtype == 'Left' ? vm.pageMaxWidth/2-emptyPage.custom.width * emptyPage.ratio : vm.pageMaxHeight/2-emptyPage.custom.height * emptyPage.ratio;
                                                    vm.insidePages.push({
                                                        page1:emptyPage,
                                                        page2:page,
                                                        partid: vm.parts[i].id,
                                                        spread:false
                                                    })
                                                }
                                                if(vm.parts[i].id == 'B2'){
                                                    emptyPage.marginLeft = 0;
                                                    page.marginLeft = vm.bindingtype == 'Left' ? vm.pageMaxWidth/2-page.custom.width * page.ratio : vm.pageMaxHeight/2-page.custom.height * page.ratio;
                                                    vm.insidePages.push({
                                                        page1:page,
                                                        page2:emptyPage,
                                                        partid: vm.parts[i].id,
                                                        spread:false
                                                    })
                                                }
                                            }
                                        }else{
                                            page.pageNumber=index;
                                            page.isUse = true;
                                            page.marginLeft = vm.bindingtype == 'Left' ? (vm.pageMaxWidth-page.custom.width * page.ratio) / 2 : (vm.pageMaxHeight-page.custom.height * page.ratio) / 2;
                                            var _page = angular.copy(page);
                                            _page.marginLeft = vm.bindingtype == 'Left' ? -_page.custom.width*_page.ratio/2 : -_page.custom.height*_page.ratio/2;
                                            vm.insidePages.push({
                                                page1:page,
                                                page2:_page,
                                                partid: vm.parts[i].id,
                                                spread:false
                                            })
                                        }
                                    })
                                }
                            }
                        }
                        vm.navParts = angular.copy(vm.parts);
                    }
                }
                var pageOrder = 0;
                function setPageOrder(page){
                    pageOrder++;
                    page.pageNumber=pageOrder<10?'0'+pageOrder:pageOrder;
                }
                $scope.bindingtypeInfo = function () {
                    var _name = "";
                    vm.bindingtypes.forEach(function (item) {
                        if(vm.bindingtype == item.id){
                            _name = item.name;
                        }
                    })
                    return _name
                };
                $scope.bindingtypeSelect = function (item) {
                    vm.bindingtype = item.id;
                    if(vm.bindingtype == 'Left'||vm.bindingtype == 'Top'){
                        vm.loading = true;
                        $timeout(function () {
                            vm.thisPage = 0;
                            config.$bookBlock.bookblock('update');
                            config.$bookBlock.bookblock('jump', (vm.thisPage-0+1));
                            vm.loading = false;
                        },100)
                    }
                }
                var config = {
                    $bookBlock: $('#bb-bookblock'),
                    $navNext: $('#bb-nav-next'),
                    $navPrev: $('#bb-nav-prev'),
                    $slide1: $('#slide1'),
                    $slide2: $('#slide2')
                };
                var Page = (function() {
                    var config = {
                            $bookBlock : $( '#bb-bookblock' ),
                            $navNext : $( '#bb-nav-next' ),
                            $navPrev : $( '#bb-nav-prev' )
                        },
                        init = function() {
                            config.$bookBlock.bookblock( {
                                orientation : vm.bindingtype == "Left" ? 'vertical' : 'horizontal',
                                speed : 800,
                                shadowSides : 0.8,
                                shadowFlip : 0.7,
                                onBeforeFlip: function(page) {
                                    vm.isBefore = true;
                                },
                                onEndFlip: function(page, isLimit) {
                                    vm.isBefore = false;
                                    return false;
                                },
                            } );
                            initEvents();
                            $scope.$apply();
                        },
                        initEvents = function() {
                            var $slides = config.$bookBlock.children();
                            // add navigation events
                            config.$navNext.on( 'click touchstart', function() {
                                config.$bookBlock.bookblock( 'next' );
                                return false;
                            } );

                            config.$navPrev.on( 'click touchstart', function() {
                                config.$bookBlock.bookblock( 'prev' );
                                return false;
                            } );

                            // add keyboard events
                            $(document).keydown( function(e) {
                                var keyCode = e.keyCode || e.which,
                                    arrow = {
                                        left : 37,
                                        up : 38,
                                        right : 39,
                                        down : 40
                                    };

                                if(vm.bindingtype =='Top') {
                                    switch (keyCode) {
                                        case arrow.up:
                                            config.$bookBlock.bookblock('prev');
                                            e.preventDefault();
                                            break;
                                        case arrow.down:
                                            config.$bookBlock.bookblock('next');
                                            e.preventDefault();
                                            break;
                                    }
                                }
                                if(vm.bindingtype =='Left'){
                                    switch (keyCode) {
                                        case arrow.left:
                                            config.$bookBlock.bookblock( 'prev' );
                                            e.preventDefault();
                                            break;
                                        case arrow.right:
                                            config.$bookBlock.bookblock( 'next' );
                                            e.preventDefault();
                                            break;
                                    }
                                }

                            } );
                        };

                    return { init : init };

                })();
                $scope.nextPage = function(index){
                    if(vm.isBefore){
                        return;
                    }
                    if(index==vm.insidePages.length-1){
                        MessageService.error("最后一页");
                        return
                    }
                    vm.thisPage = index+1;
                    config.$bookBlock.bookblock('next');
                    firstPageInit()
                };
                $scope.prevPage = function(index){
                    if(vm.isBefore){
                        return;
                    }
                    if(index==0){
                        if(window.innerWidth>1000){
                            MessageService.error("第一页");
                        }
                        return
                    }
                    vm.thisPage = index-1;
                    config.$bookBlock.bookblock('prev');
                    firstPageInit()
                };
                var a = null;
                $scope.landscape = function (item) {
                    if(vm.isBefore){
                        return;
                    }
                    if (a !== null) {
                        clearTimeout(a)
                    }
                    a = setTimeout(function () {
                        vm.landscape = item;
                        vm.insidePages = [];
                        for(var i=0; i<vm.parts.length; i++){
                            vm.parts[i].pages = [];
                        }
                        ratioInfo();
                        $timeout(function () {
                            vm.thisPage = 0;
                            Page.init();
                        },100)
                        $scope.$apply();
                    },200)
                };

                $scope.firstPageCb = function () {
                    if(vm.thisPage==0){
                        MessageService.error("第一页");
                        return
                    }
                    vm.thisPage = 0;
                    config.$bookBlock.bookblock('first');
                    firstPageInit()
                };
                $scope.lastPageCb = function () {
                    if(vm.thisPage==vm.insidePages.length-1){
                        MessageService.error("最后一页");
                        return
                    }
                    vm.thisPage = vm.insidePages.length-1;
                    config.$bookBlock.bookblock('last');
                    firstPageInit()
                };
                function firstPageInit(){
                    //console.log(vm.thisPage)
                }
                $scope.jumpPage = function (partid, index) {
                    if(vm.isBefore){
                        return
                    }
                    var _pageIndex = 0;
                    if(partid != 'P'){
                        for(var i=0; i<vm.insidePages.length; i++){
                            if(vm.insidePages[i].partid == partid){
                                _pageIndex = i;
                                break
                            }
                        }
                    }else{
                        _pageIndex = index;
                    }
                    vm.thisPage = _pageIndex;
                    config.$bookBlock.bookblock('jump', (_pageIndex-0+1));
                    firstPageInit()
                }
                $scope.prevPartShow = function (item) {
                    if(!vm.insidePages || vm.insidePages.length == 0 || !vm.insidePages[vm.thisPage]){
                        return
                    }
                    var _part = vm.insidePages[vm.thisPage].partid;
                    var _returnItem = false;
                    if(item == 'prev'){
                        for(var i=0; i<vm.thisPage; i++){
                            if(vm.insidePages[i].partid != _part){
                                _returnItem = true;
                                break
                            }
                        }
                    }
                    if(item == 'next'){
                        for(var i=vm.thisPage; i<vm.insidePages.length; i++){
                            if(vm.insidePages[i].partid != _part){
                                _returnItem = true;
                                break
                            }
                        }
                    }
                    return _returnItem
                }
                $scope.prevPart = function () {
                    if(vm.isBefore){
                        return
                    }
                    var _part = vm.insidePages[vm.thisPage].partid;
                    for(var i=vm.thisPage; i>=0; i--){
                        if(vm.insidePages[i].partid != _part){
                            vm.thisPage = i;
                            config.$bookBlock.bookblock('jump', (vm.thisPage-0+1));
                            break
                        }
                    }
                }
                $scope.nextPart = function () {
                    if(vm.isBefore){
                        return
                    }
                    var _part = vm.insidePages[vm.thisPage].partid;
                    for(var i=vm.thisPage; i<vm.insidePages.length; i++){
                        if(vm.insidePages[i].partid != _part){
                            vm.thisPage = i;
                            config.$bookBlock.bookblock('jump', (vm.thisPage-0+1));
                            break
                        }
                    }
                }
                $scope.flipPage = function(event,index, bindingtype){
                    var element = $("#bb-bookblock");
                    if(bindingtype){
                        var clickX = event.clientY;
                        var elementX = element.offset().top;
                        var halfWidth = element.outerHeight() / 2;
                        if (clickX < elementX + halfWidth) {
                            $scope.prevPage(index)
                        } else {
                            $scope.nextPage(index)
                        }
                    }else{
                        if(!vm.landscape){
                            var clickX = event.clientX;
                            var elementX = element.offset().left;
                            var halfWidth = element.outerWidth() / 2;
                            if (clickX < elementX + halfWidth) {
                                $scope.prevPage(index)
                            } else {
                                $scope.nextPage(index)
                            }
                        }else{
                            var _h = $("#bb-bookblock").width();
                            if(event.pageY-$("#bb-bookblock").offset().top > _h / 2){
                                $scope.nextPage(index)
                            }else{
                                $scope.prevPage(index)
                            }
                        }
                    }
                    vm.isBefore = true;
                    $timeout(function () {
                        vm.isBefore = false;
                    },800)
                }
            }]
    };

    angular
        .module('editorApp')
        .component('articlePreview', articlePreview);
})();
