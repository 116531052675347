(function () {
    'use strict';

    var ProgresViewControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/progres-view.html',
        controllerAs: 'vm',
        bindings: {
            progres: '<',
            src: '<',
        },

        controller: ['$scope','FontManagement','$timeout', '$http', function ($scope,FontManagement,$timeout,$http){
    	    var vm = this;
    	    var percent = vm.progres || 0;
    	    var rate = 0;
    	    vm.transform1 = 'rotate(0)';
    	    vm.transform2 = 'rotate(0)';
    	    vm.borderColor = '';
    	    vm.borderColor1 = '';
            vm.$onInit = function() {
                renderRightRate()
            }
            vm.$onChanges = function(data) {
                if(data.progres && data.progres.currentValue){
                    percent = data.progres.currentValue;
                    renderRightRate()
                }
            }
            function renderRightRate(){
                rate += 1;
                if (rate < 50) {
                    vm.transform2 = 'rotate(' + 3.6 * rate + 'deg)';
                    vm.borderColor1 = '';
                } else {
                    vm.transform2 = 'rotate(0)';
                    vm.borderColor1 = 'var(--themecolor)';
                    vm.transform1 = 'rotate(' + 3.6 * (rate - 50) + 'deg)';
                }
                if(rate<percent){
                    $timeout(renderRightRate,5)
                }
            };
        }]
    };

    angular.module('editorApp')
    	.component('progresView', ProgresViewControl);

})();
