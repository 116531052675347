(function () {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('404',{
                parent:'app',
                url:'/{storeId}/404',
                data:{
                    authorities: [],
                    pageTitle: ''
                },
                views:{
                    'index@': {
                        templateUrl: 'app/entities/page/page-404.html',
                        controller: 'Page404Controller',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    tips: null
                }
            })
            .state('page-my-suffix', {
                parent: 'homeEditor',
                url: '/{soid}/{plid}/page-my-suffix',
                data: {
                    authorities: [],
                    pageTitle: 'title.editorAll'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-all.html',
                        controller: 'EditorAllController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'add'
                        };
                    }
                }
            })
            .state('page-my-suffix-edit', {
                parent: 'homeEditor',
                url: '/{storeId}/page-my-suffix/{pid}/{vid}/{xmlId}/{soid}/{plid}?returnUrl',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-all.html',
                        controller: 'EditorAllController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'add'
                        };
                    }
                }
            })
            .state('page-my-suffix-copy', {
                parent: 'homeEditor',
                url: '/page-my-suffix/{id}/copy?returnUrl',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-all.html',
                        controller: 'EditorAllController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    copy:true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })

            .state('page-my-suffix-remake', {
                parent: 'homeEditor',
                url: '/{storeId}/page-my-suffix/{aid}/{soid}/{plid}?returnUrl',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-all.html',
                        controller: 'EditorAllController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })

            .state('page-my-suffix-orderArticle', {
                parent: 'homeEditor',
                url: '/{storeId}/page-my-suffix-orderArticle/{orderArticleId}?source&authorization&returnUrl',
                data: {
                    // authorities: ['ROLE_USER'],
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-all.html',
                        controller: 'EditorAllController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })

            .state('page-view', {
                parent: 'homeEditor',
                url: '/{storeId}/webView/{aid}/{plId}/{soId}?returnUrl',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.viewTitle'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/page-view-article.html',
                        controller: 'PageViewArticleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })
            .state('page-view2', {
                parent: 'homeEditor',
                url: '/{storeId}/webView2/{orderArticleId}?source&returnUrl&authorization',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.viewTitle'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/page-view-article.html',
                        controller: 'PageViewArticleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrderArticle', function ($stateParams, OrderArticle) {
                        return OrderArticle.get({id: $stateParams.orderArticleId}).$promise;
                    }]
                }
            })
            .state('editorAll', {
                parent: 'homeEditor',
                url: '/{storeId}/editorAll/{pid}/{vid}/{xmlId}/{soid}/{plid}?returnUrl',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-all.html',
                        controller: 'EditorAllController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'add'
                        };
                    }
                }
            })
            .state('editorAllEdit', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/editorAllEdit?returnUrl',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-all.html',
                        controller: 'EditorAllController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })
            .state('orderArticleEditorAllEdit', {
                parent: 'homeEditor',
                url: '/{storeId}/{orderArticleId}/orderArticleEditorAllEdit?source&authorization&returnUrl',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-all.html',
                        controller: 'EditorAllController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })
            .state('editorPhotoWeb', {
                parent: 'homeEditor',
                url: '/{storeId}/editorPhotoWeb/{pid}/{vid}/{xmlId}/{soid}/{plid}?returnUrl',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-photo-web.html',
                        controller: 'EditorPhotoWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'add'
                        };
                    }
                }
            })
            .state('editorPhotoWebEdit', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/editorPhotoWebEdit?returnUrl',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-photo-web.html',
                        controller: 'EditorPhotoWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })
            .state('orderArticlePhotoWebEdit', {
                parent: 'homeEditor',
                url: '/{storeId}/{orderArticleId}/orderArticlePhotoWebEdit?source&authorization&returnUrl',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/page/editor-photo-web.html',
                        controller: 'EditorPhotoWebController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })
        ;
    }

})();
