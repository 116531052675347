(function() {
    "use strict";
    angular
        .module("editorApp")
        .controller("StorageDelController", StorageDelController);
    StorageDelController.$inject = ['$state', '$scope', '$stateParams', '$localStorage', '$uibModalInstance', 'DateUtils', 'CustomerStorages']

    function StorageDelController($state, $scope, $stateParams, $localStorage, $uibModalInstance, DateUtils, CustomerStorages) {
        var vm = this;
        vm.price = null;
        // 是否点击移动按钮
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        if ($localStorage.user !=null) {
            vm.userId = $localStorage.user.id
        }
        vm.customerStorage =  CustomerStorages.getByCustomer({customerId: vm.userId});
        
        $scope.close = function(){
            $uibModalInstance.dismiss('cancel');
        }
 
    }
})();
