(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CartController', CartController);

    CartController.$inject = ['$state', 'Cart', '$scope', 'paginationConstants', 'pagingParams', '$stateParams', '$q',
        '$timeout', 'MessageService', 'Product', 'entity', 'ItemValue', '$localStorage','SkuSellLimits','$uibModal'
    ];

    function CartController($state, Cart, $scope, paginationConstants, pagingParams, $stateParams, $q,
        $timeout, MessageService, Product, entity, ItemValue, $localStorage, SkuSellLimits, $uibModal) {

        var vm = this;
        vm.cart = entity;
        vm.availableList= [];
        vm.unAvailableList= [];

        $scope.goodIds = [];
        $scope.amount = 0;
        $scope.allSelected = false;
        vm.imgLoad = imgLoad;
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.store = $localStorage.store;
        $scope.domain = $localStorage.domain;
        $q.all([vm.cart.$promise]).then(function() {
            if (vm.cart != null  && vm.cart.goods.length > 0) {
                angular.forEach(vm.cart.goods, function(g) {
                    ItemValue.get({ id: g.itemValueId }, function(data) {
                        if (data != null && data.id != null) {
                            g.minimumOrder = data.minimumOrder;
                            g.jhiPackage = data.jhiPackage;
                            g.editorType = data.productEditorType;
                            g.productType = data.productType;

                            var parameter = {
                                productId:g.productId,
                                itemValueId: g.itemValueId,
                                itemValueLineId:g.itemValueLineId,
                                quantity:g.quantity,
                                copy : g.copy,
                            }
                            if(g.orderWay == 'editorThenOrder' && data.pictureType == 'spread'){
                                parameter.quantity = parameter.quantity/2;
                            }
                            Product.getCostV2(parameter , function(data) {
                                if (data != null && data.status == 200) {
                                    g.amount = data.message.toFixed(2);
                                    g.price = (data.message/g.copy).toFixed(2);
                                };
                            },function(error){
                                // MessageService.error(error.data.errorMessage)
                            })

                        };
                    }, function() {})

                    SkuSellLimits.getByItemValueLineId({productId:g.productId,itemValueLineId:g.itemValueLineId},function(res){
                        if (res.message) {
                            g.limitQuantity = res.message.limitQuantity
                        }else{
                            g.limitQuantity = null
                        }
                        if (g.articleInvalid|| g.invalid || (g.articleId && !g.articleCompleted ) || (g.limitQuantity !=null && g.limitQuantity < g.copy)) {
                            vm.unAvailableList.push(g);
                        } else {
                            vm.availableList.push(g);
                        }
                    }, function() {})
                })
            }
        });


        function imgLoad(index) {
            var img = document.getElementById('img-' + index);
            return true;
        }

        $scope.orderNew = function() {
            if ($scope.goodIds == null || $scope.goodIds.length == 0) {
                MessageService.info("请先勾选想要购买的作品");
                return;
            }
            var _isCustomPrint = false;
            for(var i=0; i<vm.availableList.length; i++){
                var _item = vm.availableList[i];
                if($scope.goodIds.includes(_item.id) && _item.productType== "CustomPrint"){
                    _isCustomPrint = true;
                    break
                }
            }
            if(!_isCustomPrint){
                $state.go("sales-order-cart", { goodIds: $scope.goodIds, storeId: vm.storeId }, { reload: true });
            }else{
                $uibModal.open({
                    templateUrl: 'app/entities/sales-order/modal/protocol-agreement2.html',
                    controller: 'OrderProtocolAgreement2Controller',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: [function () {
                            var _obj = {
                                orderWay: true
                            }
                            return _obj;
                        }]
                    }
                }).result.then(function (data) {
                    if (data && data.agree) {
                        $state.go("sales-order-cart", { goodIds: $scope.goodIds, storeId: vm.storeId }, { reload: true });
                    }
                })
            }
        };

        $scope.getName = function(g){
            if (g == null) {
                return;
            }
            var name = g.articleName || g.productName;
            name = name;
            if (name != null && name.length>38) {
                return name.substring(0,36)+"...";
            }
            return name;
        }
        $scope.selectAll = function() {
            $scope.allSelected = !$scope.allSelected;
            $scope.goodIds = [];
            if (!$scope.allSelected) {
                for (var i = vm.availableList.length - 1; i >= 0; i--) {
                    var g = vm.availableList[i];
                    g.selected = false;
                }
                $scope.amount = 0.00;
            }else{
                for (var i = vm.availableList.length - 1; i >= 0; i--) {
                    var g = vm.availableList[i];
                    if ((!!g.articleId  && !g.articleCompleted) || g.cancelled ) {
                        continue;
                    }
                    g.selected = true;
                    $scope.goodIds.push(g.id);
                }
                $scope.getAmount();
            }

        };


        $scope.selectGoods = function(g) {
            if (g.articleInvalid|| g.invalid||(g.articleId && !g.articleCompleted ) || (g.limitQuantity !=null && g.limitQuantity < g.copy)) {
                g.selected = false;
                MessageService.error("该作品已经失效，不能再购买");
                return;
            }
            if (g.orderWay != "orderThenEditor" &&!!g.articleId && !g.articleCompleted) {
                MessageService.error("作品未完成，不能下单");
                return;
            }
            g.selected = !g.selected;
            if (g.selected) {
                $scope.goodIds.push(g.id);
                if ($scope.goodIds.length == vm.availableList.length) {
                    $scope.allSelected = true;
                }
                $scope.getAmount();
            } else {
                $scope.allSelected = false;
                var ind = $scope.goodIds.indexOf(g.id);
                if (ind >= 0) {
                    $scope.goodIds.splice(ind, 1);
                }
                $scope.getAmount();
            }
        };

        $scope.getAmount = function() {
            $scope.amount = 0;
            for (var i = vm.availableList.length - 1; i >= 0; i--) {
                var g = vm.availableList[i];
                if(g.selected) {
                    $scope.amount += g.copy * Number(g.price);
                }
            }
        };

        $scope.minusCopy = function(item, e) {
            fn(e);
            if (item.copy > 1) {
                item.copy--;
            }
            if (item.copy < item.minimumOrder) {
                item.copy  = item.minimumOrder;
                MessageService.error("商品数量不能再减少了");
            }
            quantityChange(item, 'minus');
        }
        $scope.addCopy = function(item, e) {
            fn(e);
            if (item.limitQuantity <= item.copy && item.limitQuantity!=null) {
                MessageService.error("库存不足，无法购买更多");
                return
            }
            item.copy++;
            quantityChange(item, 'add');
        }

        function quantityChange(g, attr) {
            Cart.quantityChange({ goodId: g.id, copy: g.copy }, function(data) {
                var parameter = {
                    productId:g.productId,
                    itemValueId: g.itemValueId,
                    itemValueLineId:g.itemValueLineId,
                    quantity: g.quantity,
                    copy: g.copy,
                }
                $scope.$emit('editorApp:cartUpdate', {});
                ItemValue.get({ id: g.itemValueId }, function(itemValue) {
                        if (g.orderWay == 'editorThenOrder' && itemValue.pictureType == 'spread') {
                            parameter.quantity = parameter.quantity / 2;
                        }
                        Product.getCostV2(parameter, function(data) {
                            if (data != null && data.status == 200) {
                                g.amount = data.message.toFixed(2);
                                g.price = (data.message/g.copy).toFixed(2);
                                $scope.getAmount();
                            };
                        },function(error){
                            MessageService.error(error.data.errorMessage)
                        })
                })
            }, function() {
                MessageService.error("数量修改失败");
                if (attr == 'minus') {
                    g.copy--;
                } else if (attr == 'add') {}{
                    g.copy++;
                }
            })
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                // e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        function isPositiveInteger(s) { //是否为正整数
            var re = /^[0-9]+$/;
            return re.test(s)
        }

        $scope.changeQuantity = function(g, e) {
            fn(e);
            var num = g.quantity;
            if (isPositiveInteger(num)) {

            } else {
                g.quantity = 1;
            }
            quantityChange(g);
        };

        //购物车直接删除
        $scope.delete = function(g,e) {
            fn(e);
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确认要将选择的作品从购物车删除？'
            }, function() {
                Cart.removeGoods({ goodIds: g.id }, function(data) {
                    MessageService.success('删除成功！');
                    $scope.removeGoods(g.id);
                }, function() {});
            })
        };

        $scope.removeGoods = function(id){
            if (vm.cart) {
                for (var i = vm.availableList.length - 1; i >= 0; i--) {
                    if (vm.availableList[i].id == id) {
                        vm.availableList.splice(i,1);
                        break;
                    }
                }
                for (var i = vm.unAvailableList.length - 1; i >= 0; i--) {
                    if (vm.unAvailableList[i].id == id) {
                        vm.unAvailableList.splice(i,1);
                        break;
                    }
                }
            }
            $scope.$emit('editorApp:cartUpdate', {});
        }

        //购物车勾选删除
        $scope.deleteGoods = function() {
            if ($scope.goodIds == null || $scope.goodIds.length == 0) {
                MessageService.error("请先勾选想要删除的作品");
                return;
            };
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确认要将选择的作品从购物车删除？'
            }, function() {
                Cart.removeGoods({ goodIds: $scope.goodIds }, function(data) {
                    MessageService.success('删除成功！');
                    for (var i = $scope.goodIds.length - 1; i >= 0; i--) {
                        $scope.removeGoods($scope.goodIds[i]);
                    }
                }, function() {});
            })
        };

        $scope.invalidRemove = function(){
            if (vm.unAvailableList == null || vm.unAvailableList.length == 0) {
                return;
            }
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确认要清空所有失效宝贝？'
            }, function(){
                var ids=[];
                for (var i = vm.unAvailableList.length - 1; i >= 0; i--) {
                   ids.push(vm.unAvailableList[i].id);
                }
                Cart.removeGoods({goodIds:ids},function(data){
                    MessageService.success("删除成功");
                    vm.unAvailableList =[]
                    $scope.$emit('editorApp:cartUpdate', {});
                },function(){});
            })
        }
    }
})();
