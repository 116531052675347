(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('AfterSaleReplyController', AfterSaleReplyController);

    AfterSaleReplyController.$inject = ['$scope', '$q', '$stateParams', 'previousState', 'SalesOrder', '$state', 'Qinius', 'ImageService', 
    '$localStorage', 'AfterSale', '$timeout', 'AfterSaleProblem', 'UidService', 'entity', 'MessageService'];

    function AfterSaleReplyController($scope, $q, $stateParams, previousState, SalesOrder, $state, Qinius, ImageService,
        $localStorage, AfterSale, $timeout, AfterSaleProblem, UidService, entity, MessageService) {
        var vm = this;
        vm.contents = [];
        vm.afterSale = entity;
        console.log(entity);
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.id = $stateParams.id;
        if (!vm.id) {
            return;
        }

        vm.removeFile = removeFile;
        vm.goList = goList;
        vm.domains = Qinius.getDomain1();
        vm.tokens = Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"});

        if (vm.afterSale && vm.afterSale.salesOrder) {
            vm.salesOrder = vm.afterSale.salesOrder;
        }
        if (vm.salesOrder && vm.salesOrder.orderLines && vm.salesOrder.orderLines[0] && vm.salesOrder.orderLines[0].orderArticle 
            && vm.salesOrder.orderLines[0].orderArticle.prototype == 'StoreSelf' ) {
            vm.problemsAfterSales =AfterSaleProblem.getAllByCondition({items:[{key: "enabled", op: "=", value: "true"},
                {key: "storeId", op: "=", value: vm.storeId}], sort:["sort,asc"]});
        }else{
            vm.problemsAfterSales =AfterSaleProblem.getAllByCondition1({items:[{key: "enabled", op: "=", value: "true"}], sort:["sort,asc"]});
        }

        vm.photos = [];

        function goList() {
           $state.go('after-sale', {storeId: vm.storeId});
        }

        $scope.problemChange = function(problem) {
            if (!!problem) {
                AfterSaleProblem.getListByparent({
                    id: problem.id
                }, function(data) {
                    vm.problemsAfterSales2 = data;
                });
            }
        };

        $q.all([vm.domains.$promise, vm.problemsAfterSales.$promise]).then(function() {
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            vm.token = vm.tokens.token;
            $scope.problems = vm.problemsAfterSales.filter(function(item){return item.enabled == true});
            document.addEventListener("DOMContentLoaded", function(event){
                ImagesZoom.init({
                    "elem": ".list"
                });
            }, false);

            console.log(vm.problemsAfterSale);


            $scope.save = function () {
                vm.afterSale.photo = vm.photos.toString();
                if (vm.problemsAfterSale != null) {
                    vm.afterSale.type = vm.problemsAfterSale.content;
                }
                if (!!vm.problemsAfterSale2) {
                    vm.afterSale.problem = vm.problemsAfterSale2.content;
                } else {
                    vm.afterSale.problem = "";
                }
                vm.afterSale.describe = vm.describe;
                vm.afterSale.storeId = vm.storeId;
                vm.afterSale.id = vm.id;
                // console.log(vm.afterSale);
                AfterSale.save(vm.afterSale, function (data) {
                    MessageService.success("售后反馈成功");
                    goList();
                }) 
            }
        });

        //产品 coverImg更改
        window.updateImgFile = function ($event) {
            var file = $event.target.files[0];
            ImageService.backendUploadPics(file,$scope.domain, vm.token, function (data,f) {
                vm.photos.push(data.key);
                $scope.$digest()
            },function (res) {
                MessageService.error("文件上传失败");
            })
        };

        // 删除上传图片
        function removeFile(index) {
            vm.photos.splice(index, 1);
        }

    }
})();
