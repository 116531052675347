(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('CommoditySearchController', CommoditySearchController);

    CommoditySearchController.$inject = ['$scope', '$q', 'Product', '$state', 'MessageService', '$rootScope', 'Qinius', '$stateParams', '$uibModal',
     '$localStorage', 'pagingParams', '$cookieStore', 'Store','DateUtils', 'CommonService'];

    function CommoditySearchController($scope, $q, Product, $state, MessageService, $rootScope, Qinius, $stateParams, $uibModal,
     $localStorage, pagingParams, $cookieStore, Store,DateUtils, CommonService ) {
        var vm = this;
        vm.storeId = $stateParams.storeId || $cookieStore.get("store-id");
        vm.commodityList = [];
        vm.hidden = false;
        vm.store = $localStorage.store;
        vm.domain = $localStorage.domain;
        vm.searchQuery = pagingParams.search;
        vm.search = pagingParams.search;
        vm.historyWord = JSON.parse(localStorage.getItem('historyWord')) || [];
        vm.productinfo = {
            storeId:vm.storeId,
            name: vm.search,
        }
        init();

        $scope.login = function () {
            createModal('app/account/register/register.html', 'RegisterController', "Login").result.then(
                function () {
            });
        };

        var createModal = function (url, controller, type) {
            if (type == null) {
                type = "Login";
            }
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type
                    },
                }
            });
        };

        function getProduct(){
            Product.getProductsClient(vm.productinfo,function(data){
                vm.choicenessList = data;
                vm.commodityList =data;
                vm.commodityList = vm.commodityList.sort(function(a, b){
                    return a.seq - b.seq;
                })
            })
        }
         
        function init(){
            if ($localStorage != null && $localStorage.user != null && $localStorage.authenticationToken != null) {
                vm.productinfo.branchStoreId= $localStorage.user.branchStoreId;
            }  
            getProduct()
            if (vm.domain == null || vm.domain == "") {
                Qinius.getDomain1({storeId: vm.storeId}, function(data){
                     vm.domain = 'https://' + data.domains[0] + '/';
                });
            }
            if (vm.store == null) {
                Store.get({id:vm.storeId},function(data){
                    vm.store = data;
                    if (vm.store.storeType2.indexOf("WEB")<0) {
                        vm.hidden = true;
                        CommonService.setDocumentTitle(vm.store.name);
                    }
                });
            }else{
                if (vm.store.storeType2.indexOf("WEB")<0) {
                    vm.hidden = true;
                    CommonService.setDocumentTitle(vm.store.name);
                }
            }
        }
        
        $scope.commoditySelect = function (data) {
            if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                $scope.login();
                return;
            } 
            $state.go('standardCommodityDetails', {storeId: vm.storeId, pid: data.id,from:'commoditySearch'});
        };
         
        /*
            首页搜索input和弹窗操作
         */

        $scope.clear = function(){
            vm.search = null;
            vm.searchQuery = null;
            vm.productinfo.name= null;
            getProduct();
        }
        $scope.searchMethodClick = function () {
            vm.historyWord = vm.historyWord.filter(function (item) {
                return vm.search != item
            })
            if (!!vm.search) {
                vm.historyWord.unshift(vm.search);
                vm.historyWord = vm.historyWord.slice(0,15);
                localStorage.setItem('historyWord', JSON.stringify(vm.historyWord));
            }
            // 这里写逻辑，vm.searchQuery,搜索内容
            vm.searchQuery = vm.search;
            vm.productinfo.name= vm.search;
            getProduct();
        }
    }
})();
