(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CodeListController', CodeListController);

    CodeListController.$inject = ['$uibModalInstance',  '$scope', 'entity' ];

    function CodeListController($uibModalInstance,  $scope, entity) {
        var vm = this;
        vm.data = entity.line;
        console.log(vm.data)

        $scope.clear = function() {
            $uibModalInstance.dismiss();
        }
    }
})();
