(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('ClauseModalController', ClauseModalController);

    ClauseModalController.$inject = ['$uibModalInstance', 'StoreConfig', '$q', '$sce'];

    function ClauseModalController ($uibModalInstance, StoreConfig, $q, $sce) {
        var vm = this;
        vm.cancel = cancel;
        vm.useTerms = "";
        vm.storeConfig = StoreConfig.byStoreIdAndType({type:'STORAGETERMS'});
        $q.all([vm.storeConfig.$promise]).then(function(){
            vm.useTerms = $sce.trustAsHtml(vm.storeConfig.value);
        })
        function cancel() {
            $uibModalInstance.close();
        }
    }
})();
