(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('GiftVoucherController', GiftVoucherController);

    GiftVoucherController.$inject = ['$state',  'Store', '$scope', 'paginationConstants', 'pagingParams','$stateParams','Customer','$q', 'GiftVoucher', 
    'ExchangeCode', 'MessageService', 'DateUtils'];

    function GiftVoucherController($state,  Store, $scope, paginationConstants, pagingParams, $stateParams, Customer, $q, GiftVoucher,
     ExchangeCode, MessageService, DateUtils) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.status = 'unUsed';
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.storeId = $stateParams.storeId || $state.params.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.store = Store.get({id:vm.storeId});
        vm.user = Customer.current({});

        $q.all([vm.user.$promise, vm.store.$promise]).then(function(){
            loadAll();
        });

        function loadAll () {
            GiftVoucher.getAllByCondition3({
                items: [{ key: "used", op: "=",  value: "false" }, 
                {key: "periodOfValidity", op: ">=",  value: DateUtils.convertLocalDateToServer(new Date())}]
            }, function(data){
                vm.giftVoucherList = data;
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        $scope.exchange = function(){
            if (vm.code == null || vm.code == "") {
                MessageService.error("请录入兑换码");
                return;
            }
            vm.code = trim(vm.code);
            ExchangeCode.toGiftVoucher({code:vm.code}, function(data){
                if (data.status == 200) {
                    MessageService.success("兑换成功");
                    loadAll();
                }else{
                    MessageService.error(data.message);
                }
            },function(data){
                MessageService.error("兑换失败，请稍后再试");
            })
        }

        function trim(s){
            if (s == null || s =="") {
                return s;
            }
            var reg = new RegExp(" ","g"); 
            return s.replace(reg,"");
        }

        $scope.gotoProduct = function (giftVoucher) {
            $state.go('commoditySelect', {storeId:vm.storeId, pid: giftVoucher.productId})
        }

         
        vm.giftVoucherStatus = false;
        $scope.showGiftVoucherDetail = function (giftVoucher) {
            vm.giftVoucherItem = giftVoucher;
            vm.giftVoucherStatus = true;
        }
    }
})();
