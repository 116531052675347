(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('ConfirmPropertyController', ConfirmPropertyController);

    ConfirmPropertyController.$inject = ['$stateParams', 'Product', '$state', '$uibModalInstance', 'MessageService',  '$scope',
    '$q', 'ItemValue', 'entity' , 'SkuSellLimits' , 'Cart', '$localStorage','$uibModal','StoreConfig','Theme'];

    function ConfirmPropertyController($stateParams, Product, $state, $uibModalInstance, MessageService,  $scope,
     $q , ItemValue, entity, SkuSellLimits , Cart, $localStorage, $uibModal, StoreConfig,Theme) {
        var vm = this;
        vm.entity = entity;
        vm.title = "立即下单";
        vm.storeId = $stateParams.storeId;
        vm.id = $stateParams.id;
        vm.warningQuantity = null;
        vm.limitQuantity = null;
        vm.data={copy:1};
        $scope.domain = $localStorage.domain;
        vm.storeCartConfig = StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"SHOPPINGCART"});
        if (!vm.storeId) {
            return;
        }
        vm.clear = clear;
        vm.save = save;
        vm.itemValue = ItemValue.get({id:vm.entity.itemValueId});console.log(vm.itemValue)
        $q.all([vm.entity.$promise, vm.itemValue.$promise]).then(function(){
             vm.data.copy= vm.itemValue.minimumOrder;
            $scope.selectServices();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.selectServices = function() {
            if (vm.itemValue.itemValueLines == null || vm.itemValue.itemValueLines.length == 0) {
                return;
            }
            //将itemvalueLine的属性值 做成数组 绑定到 itemValueLine上
            for (var i = vm.itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var l = vm.itemValue.itemValueLines[i];
                if (l.cancelled || !l.saleable) {
                    continue;
                }
                l.valueArray = l.skuNumber.split("-");
            }
            //将默认属性行 绑定到订单行上 如果没有默认属性行则取第一行
            for (var i = vm.itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var l = vm.itemValue.itemValueLines[i];
                if (l.cancelled || !l.saleable) {
                    continue;
                }
                if (l.default) {
                    vm.itemValueLine = angular.copy(l);
                    if (vm.itemValueLine.itemValueObjectValues && vm.itemValueLine.itemValueObjectValues.length > 0) {
                        for (var z = vm.itemValueLine.itemValueObjectValues.length - 1; z >= 0; z--) {
                            var value = vm.itemValueLine.itemValueObjectValues[z];
                            value.selected = true;
                            for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                                if (value.propertyValueId == value.propertyValues[j].id) {
                                    value.propertyValues[j].selected = true;
                                }
                            }
                        }
                    }
                    vm.data.itemValueLineId = vm.itemValueLine.id;
                    vm.data.lineSku = vm.itemValueLine.sku;
                    vm.data.lineSkuNumber = vm.itemValueLine.skuNumber;
                    $scope.getCost();
                    break;
                }
            }
            if (vm.itemValueLine == null) {
                for (var i = vm.itemValue.itemValueLines.length - 1; i >= 0; i--) {
                    var line = vm.itemValue.itemValueLines[i];
                    if (line.cancelled || !line.saleable) {
                        continue;
                    } else {
                        vm.itemValueLine = angular.copy(line);
                        break;
                    }
                }
                if (vm.itemValueLine == null) {
                    MessageService.error("商品配置不全请联系客服后再下单");
                    return;
                }
                if (vm.itemValueLine.itemValueObjectValues == null || vm.itemValueLine.itemValueObjectValues.length == 0) {
                    vm.data.itemValueLineId = vm.itemValueLine.id;
                    vm.data.lineSku = vm.itemValueLine.sku;
                    vm.data.lineSkuNumber = vm.itemValueLine.skuNumber;
                    $scope.getCost();
                }
            }

            var valueArray = vm.itemValue.skuNumber.split("-");
            if (vm.itemValueLine.itemValueObjectValues && vm.itemValueLine.itemValueObjectValues.length > 0) {
                for (var i = vm.itemValueLine.itemValueObjectValues.length - 1; i >= 0; i--) {
                    var value = vm.itemValueLine.itemValueObjectValues[i];
                    for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                        var pv = value.propertyValues[j];
                        if (pv.selected) {
                            valueArray.push(pv.propertyId + "_" + pv.id);
                        }
                    }
                }
            }

            getByItemValueLineId()
            $scope.getItemValueObjectValues();
            var itemValueLine = $scope.selectedItemValueLine(valueArray);
            $scope.restoreAvailable(vm.itemValueLine.itemValueObjectValues);
            $scope.getAvailable( vm.itemValueLine.itemValueObjectValues);
        }

        $scope.getItemValueObjectValues = function(){
            for (var i = vm.itemValueLine.itemValueObjectValues.length - 1; i >= 0; i--) {
                var ivo = vm.itemValueLine.itemValueObjectValues[i];
                for (var j = ivo.propertyValues.length - 1; j >= 0; j--) {
                    var pv = ivo.propertyValues[j];
                    var result = $scope.objectValueInclue(pv);
                    if (!result) {
                        pv.show = false;
                    }else{
                         pv.show = true;
                    }
                }

            }
        }

        $scope.objectValueInclue = function(propertyValue){
            for (var i = vm.itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var ivl =  vm.itemValue.itemValueLines[i];
                for (var j = ivl.itemValueObjectValues.length - 1; j >= 0; j--) {
                    var ivov = ivl.itemValueObjectValues[j];
                    if (propertyValue.id== ivov.propertyValueId) {
                        return true;
                    }
                }
            }
            return false;
        }
        $scope.getCost = function(){
            var parameter = {
                productId:vm.itemValue.productId,
                itemValueId: vm.itemValue.id,
                itemValueLineId: vm.data.itemValueLineId,
                quantity:vm.entity.article.pictureType == 'spread' ? vm.entity.pageCount/2 : vm.entity.pageCount,
                copy : vm.data.copy,
            }

            Product.getCostV2(parameter, function(data){
                if (data != null && data.status == 200) {
                    vm.amount  = data.message.toFixed(2);
                }else{
                    MessageService.error('价格获取失败,请稍后再试');
                }
            },function(error){
                // MessageService.error(error.data.errorMessage)
            })
        }

        $scope.copyChange = function(){
            if (vm.data.copy < vm.itemValue.minimumOrder) {
                vm.data.copy  = vm.itemValue.minimumOrder;
                MessageService.error("商品数量不能再减少了");
            }
            if (vm.data.copy > vm.limitQuantity && vm.limitQuantity != null) {
                vm.data.copy  = vm.limitQuantity;
                MessageService.error("库存不足，无法购买更多");
            }
            $scope.getCost();
        }

        $scope.minusNum = function (){
            if(vm.data.copy > 1){
                vm.data.copy -= 1;
            }
            if (vm.data.copy < vm.itemValue.minimumOrder) {
                vm.data.copy  = vm.itemValue.minimumOrder;
                MessageService.error("商品数量不能再减少了");
            }
            $scope.getCost();
        }
        $scope.addNum = function (){
            vm.data.copy += 1;

            if (vm.data.copy > vm.limitQuantity && vm.limitQuantity != null) {
                vm.data.copy  = vm.limitQuantity;
                MessageService.error("库存不足，无法购买更多");
            }
            $scope.getCost();
        }

         //点选属性值事件
        $scope.switchPaper = function( values, value, pValue) {
            if (!pValue.available) {
                return;
            }
            for (var i = value.propertyValues.length - 1; i >= 0; i--) {
                if (value.propertyValues[i].id == pValue.id) {
                    continue;
                }
                value.propertyValues[i].selected = false;
            }
            pValue.selected = !pValue.selected;
            if (pValue.selected) {
                value.selected = true;
            } else {
                value.selected = false;
            }
            $scope.restoreAvailable(values);
            $scope.getAvailable(values);

            var valueArray = vm.itemValue.skuNumber.split("-");
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    var pv = value.propertyValues[j];
                    if (pv.selected) {
                        valueArray.push(pv.propertyId + "_" + pv.id);
                    }
                }
            }
            var itemValueLine = $scope.selectedItemValueLine(valueArray);
            if (itemValueLine) {
                vm.data.itemValueLineId =  itemValueLine.id;
                vm.data.lineSku = itemValueLine.sku;
                vm.data.lineSkuNumber = itemValueLine.skuNumber;
                $scope.getCost();
            }else{
                vm.data.itemValueLineId =  null;
                vm.data.lineSku = null;
                vm.data.lineSkuNumber = null;
                vm.amount = null;
            }
            getByItemValueLineId()
        }

        //判断是否已经匹配到ItemValueline了
        $scope.selectedItemValueLine = function(valueArray) {
            for (var z = vm.itemValue.itemValueLines.length - 1; z >= 0; z--) {
                var l = vm.itemValue.itemValueLines[z];
                if (l.cancelled || !l.saleable) {
                    continue;
                }
                if (JSON.stringify(l.valueArray.sort()) === JSON.stringify(valueArray.sort())) {
                    return l;
                }
            }
            return null;
        }

        //先将所有可选项置为可选择的
        $scope.restoreAvailable = function(values) {
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    value.propertyValues[j].available = true;
                }
            }
        }

        //判断余下属性值是否可以点选
        $scope.getAvailable = function(values) {
            var selectedValue = [];
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    var propertyValue = value.propertyValues[j];
                    if (propertyValue.cancelled || !propertyValue.show) {
                        propertyValue.available = false;
                    }else if (!$scope.isAvailable([propertyValue.propertyId + "_" + propertyValue.id])) {
                        propertyValue.available = false;
                    }
                }
            }

            //先将选择的都拿出来
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (value.selected) {
                    for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                        var propertyValue = value.propertyValues[j];
                        if (propertyValue.selected) {
                            selectedValue.push(propertyValue.propertyId + "_" + propertyValue.id);
                        }
                    }
                }
            }
            //没有选中的行 选项是否可以被选择
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (!value.selected) {
                    for (var z = value.propertyValues.length - 1; z >= 0; z--) {
                        var pValue = value.propertyValues[z];
                        if (!pValue.available) {
                            continue;
                        }
                        var selectedValueBack = angular.copy(selectedValue);
                        selectedValueBack.push(pValue.propertyId + "_" + pValue.id);
                        if (!$scope.isAvailable(selectedValueBack)) {
                            pValue.available = false;
                        }
                    }
                }
            }

            //选中的行  将本行取出 拿本行其他选项与 其他行已经选中的搭配看是否可以选中
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (value.selected) {
                    var selectedValueBack = angular.copy(selectedValue);
                    for (var j = selectedValueBack.length - 1; j >= 0; j--) {
                        var selectV = selectedValueBack[j]; //3_10
                        if ((value.propertyId + "_" + value.propertyValueId) == selectV) {
                            for (var z = value.propertyValues.length - 1; z >= 0; z--) {
                                var pValue = value.propertyValues[z];
                                if ((pValue.propertyId + "_" + pValue.id) == selectV || !pValue.available) {
                                    continue;
                                }
                                if (!$scope.isAvailable(selectedValueBack)) {
                                    pValue.available = false;
                                }
                            }
                        }
                    }
                }
            }
        }

        //判断属性值是否可以点选
        $scope.isAvailable = function(selectedValueBack) {
            for (var z = vm.itemValue.itemValueLines.length - 1; z >= 0; z--) {
                var l = vm.itemValue.itemValueLines[z];
                if (l.cancelled) {
                    continue;
                }
                var includes = true;
                for (var i = selectedValueBack.length - 1; i >= 0; i--) {
                    var el = selectedValueBack[i];
                    if (!$scope.isAvailable2(el, l.valueArray)) {
                        includes = false;
                        break;
                    }
                }
                if (includes) {
                    return true;
                }
            }
            return false;
        }

        $scope.isAvailable2 = function(elememt, array) {
            if (array == null) {
                return false;
            }
            if (array.indexOf(elememt) >= 0) {
                return true;
            }
            return false;
        }

        $scope.validata = function(){
            if (vm.data.itemValueLineId == null) {
                MessageService.error("请确认参数后再下单");
                return false;
            }
            if (vm.limitQuantity === 0 || (vm.limitQuantity < vm.itemValue.minimumOrder && vm.limitQuantity != null)) {
                MessageService.error("库存不足，无法购买更多");
                return false;
            }
            return true;
        }

        function save(){
            if (!$scope.validata()) {
                return;
            }

            if (vm.entity.title == '加入购物车') {
                save1();
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/modal/protocol-agreement2.html',
                controller: 'OrderProtocolAgreement2Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            orderWay: true
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (data) {
                if (data && data.agree) {
                    save1()
                }
            })
        }
        function save1() {
            var parameter = {
                productId:vm.itemValue.productId,
                itemValueId: vm.itemValue.id,
                itemValueLineId: vm.data.itemValueLineId,
                quantity:vm.entity.article.pictureType == 'spread' ? vm.entity.pageCount/2 : vm.entity.pageCount,
                copy : vm.data.copy,
            }
            Product.getCostV2(parameter, function(data){
                if (data != null && data.status == 200) {
                    vm.amount  = data.message.toFixed(2);
                    if (vm.entity.title == '加入购物车') {
                        var verify = false;
                        Cart.countByItemValueLineId({itemValueLineId: vm.itemValueLine.id},function(res){
                            var goodsNumber = res.message
                            if ((vm.limitQuantity - goodsNumber <= 0 || vm.limitQuantity === 0) && vm.limitQuantity != null) {
                                verify = true;
                                MessageService.error("库存不足，无法购买更多");
                                return
                            }
                            if ((vm.limitQuantity < goodsNumber + vm.data.copy) && vm.limitQuantity != null) {
                                vm.data.copy = vm.limitQuantity - goodsNumber
                            }
                            if (!verify) {
                                $uibModalInstance.close(vm.data);
                            }
                        })
                    }else{
                        $uibModalInstance.close(vm.data);
                    }
                }
            },function(error){
                MessageService.error(error.data.errorMessage);
            })
        }

        //加购
        $scope.addCart = function () {
            if (!vm.entity.article) {
                MessageService.error("作品信息丢失无法操作，请稍后尝试");
                return;
            }
            if (!$scope.validata()) {
                return;
            }

            var parameter = {
                productId:vm.itemValue.productId,
                itemValueId: vm.itemValue.id,
                itemValueLineId: vm.data.itemValueLineId,
                quantity:vm.entity.article.pictureType == 'spread' ? vm.entity.article.pageCount/2 : vm.entity.article.pageCount,
                copy : vm.data.copy,
            }
            Product.getCostV2(parameter, function(data){
                if (data != null && data.status == 200) {
                    vm.amount  = data.message.toFixed(2);
                    var verify = false;
                    Cart.countByItemValueLineId({itemValueLineId: vm.itemValueLine.id},function(res){
                        var goodsNumber = res.message
                        if ((vm.limitQuantity - goodsNumber <= 0 || vm.limitQuantity === 0) && vm.limitQuantity != null) {
                            verify = true;
                            MessageService.error("库存不足，无法购买更多");
                            return
                        }
                        if ((vm.limitQuantity < goodsNumber + vm.data.copy) && vm.limitQuantity != null) {
                            vm.data.copy = vm.limitQuantity - goodsNumber
                        }
                        if (!verify) {
                            Cart.addGoods({articleId:vm.entity.article.id, quantity:vm.entity.article.pageCount, copy:vm.data.copy,
                                itemValueLineId:vm.data.itemValueLineId, orderWay: vm.entity.orderWay, productId: vm.entity.article.productId,
                            itemValueId: vm.entity.article.itemValueId, productXmlId:vm.entity.article.productXmlId,}, function(res){
                                if(res.status == 400){
                                    swal({
                                        title: "失败",
                                        text: res.message || "加入购物车失败",
                                        imageUrl: Theme.sweetHintImg(),
                                        timer: 2000,
                                        showConfirmButton: false
                                    });
                                    return
                                }
                                $uibModalInstance.dismiss('cancel');
                                $scope.$emit('editorApp:cartUpdate', {});
                                $state.go("cart", {storeId: vm.storeId});
                            })
                        }
                    })
                }
            },function(error){
                MessageService.error(error.data.errorMessage);
            })
        }

        $scope.ordered = function() {
            if (!vm.entity.article) {
                MessageService.error("作品信息丢失无法操作，请稍后尝试");
                return;
            }
            if (!$scope.validata()) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/modal/protocol-agreement2.html',
                controller: 'OrderProtocolAgreement2Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            orderWay: true
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (data) {
                if (data && data.agree) {
                    var parameter = {
                        productId:vm.itemValue.productId,
                        itemValueId: vm.itemValue.id,
                        itemValueLineId: vm.data.itemValueLineId,
                        quantity:vm.entity.article.pictureType == 'spread' ? vm.entity.pageCount/2 : vm.entity.pageCount,
                        copy : vm.data.copy,
                    }
                    Product.getCostV2(parameter, function(data){
                        if (data != null && data.status == 200) {
                            vm.amount  = data.message.toFixed(2);
                            $uibModalInstance.dismiss('cancel');
                            $state.go("sales-order-reorder", {storeId: vm.storeId, articleId: vm.entity.article.id,
                                productId:vm.entity.article.productId, itemValueLineId: vm.data.itemValueLineId, lineSku:vm.data.lineSku ,
                                lineSkuNumber:vm.data.lineSkuNumber, copy:vm.data.copy });
                        }
                    },function(error){
                        MessageService.error(error.data.errorMessage);
                    })
                }
            })
        }

        function getByItemValueLineId(){
            if (!vm.itemValueLine) {
                vm.limitQuantity = null;
                vm.warningQuantity = null;
                return
            }
            SkuSellLimits.getByItemValueLineId({productId:vm.itemValueLine.productId,itemValueLineId:vm.itemValueLine.id},function(res){
                if (res.message) {
                    vm.limitQuantity = res.message.limitQuantity
                    vm.warningQuantity = res.message.warningQuantity
                }else{
                    vm.limitQuantity = null
                    vm.warningQuantity = null
                }
            })
        }
    }
})();
