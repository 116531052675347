(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductDetailController', ProductDetailController);

    ProductDetailController.$inject = ['$state', '$scope', 'Product', '$stateParams', '$q', 'ListService', '$localStorage','Theme'];

    function ProductDetailController($state,  $scope, Product, $stateParams, $q, ListService , $localStorage,Theme) {

        var vm = this;
        vm.showDetail = false;
        wx.hideOptionMenu();
		$scope.screenWidth = window.screen.width;
        vm.storeId = $stateParams.storeId != null ? $stateParams.storeId : $localStorage.storeId;
        vm.pid = $stateParams.pid;
        vm.connectedProduct = null;
        $scope.minPrice = 100000;
        $scope.maxPrice = 0;
        vm.product = Product.get({id:$stateParams.pid});

        $scope.showDetail = function(){
            vm.showDetail = !vm.showDetail;
        };


        //风格切换  引入不同的css文件
        Theme.judge();

        $scope.productChoose = function(){
            if (vm.connectedProduct == null || vm.connectedProduct.id == null) {
                swal({
                    title: "请先确定参数",
                    text: "",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }
            startMade(vm.connectedProduct.id);
            $state.go("product.choose", {pid:vm.connectedProduct.id, storeId:vm.storeId});

        };

        $scope.itemValueSelected = function(itemValue){
            vm.connectedProduct = {
                id:itemValue.connectedProductId,
                name:itemValue.connectedProductName,
                price:itemValue.connectedProductPrice,
                itemValueId:itemValue.id,
                coverImg : itemValue.connectedProductCoverImg,
                detailImg: itemValue.connectedProductDetailImg
            }
        };

        $scope.getItemValueStr = function(itemValue){
            var valueStr="";
            if (itemValue == null || itemValue.propertyValues == null) {
                return valueStr;
            }
            for (var i = 0; i< itemValue.propertyValues.length  ; i++) {
                var propertyValue = itemValue.propertyValues[i];
                valueStr = valueStr+" "+propertyValue.value;
            }
            return valueStr;
        };

        $q.all([vm.product.$promise]).then(function(){
            if (vm.product == null || vm.product.itemValues == null) {
                return;
            }
            if (vm.product.productType=="Simple") {
                vm.connectedProduct = {
                id:vm.product.id,
                name:vm.product.name,
                price:vm.product.price,
                itemValueId:null,
                coverImg : vm.product.coverImg,
                detailImg: vm.product.detailImg
            }
            };
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var itemValue = vm.product.itemValues[i];
                if (!itemValue.used) {
                    continue;
                }
                if (itemValue.connectedProductPrice > $scope.maxPrice) {
                    $scope.maxPrice =  itemValue.connectedProductPrice;
                }
                if ($scope.minPrice > itemValue.connectedProductPrice ) {
                    $scope.minPrice = itemValue.connectedProductPrice;
                }
            }
        });

        var startMade=function (vid) {
            localStorage.setItem("Production","start");
            localStorage.removeItem("vid"+vid+"-choose");
            localStorage.removeItem("vid"+vid+"-index");
            localStorage.removeItem("vid"+vid+"-i");
            localStorage.removeItem("vid"+vid+"-go");
            localStorage.removeItem("vid"+vid+"-single");
            localStorage.removeItem("vid"+vid+"-pages");
            localStorage.removeItem("vid"+vid+"-marginLeft");
            localStorage.removeItem("vid"+vid+"-$index");
            var gallerys = JSON.parse(localStorage.getItem("vid"+vid+'-gallery'));
            angular.forEach(gallerys,function (gallery) {
                gallery.useNum=0;       //次数清零
            });
            localStorage.setItem("vid"+vid+'-gallery',JSON.stringify(gallerys));

        }
    }
})();
