(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('FontManagement', FontManagement)
        .factory('FontXmlsManger', FontXmlsManger)
        .factory('FontXmlsFileServes', FontXmlsFileServes);

    FontManagement.$inject = ['$resource'];
    FontXmlsManger.$inject = ['$resource'];
    FontXmlsFileServes.$inject = ['$resource'];

    function FontManagement ($resource) {
        var resourceUrl =  'manager/' + 'api/font-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            "checkContent":{
                method:"POST",
                params:{
                    path:"checkContent"
                }
            },
            "fontSupport2":{
                method:"POST",
                    params:{
                    path:"font-support2"
                }
            },
            "getDefaultShow":{
                method:"GET",
                params:{
                    path:"getDefaultShow"
                }
            },
            "getCss": {
                method: "GET",
                params: {
                    path: "getCss2"
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "upload": {
                method: "POST",
                params: {
                    path: "upload"
                }
            },
            "getAll": {
                method: "POST",
                params: {
                    path: "getAll"
                },
                isArray: true
            },
            "getAll2": {
                method: "GET",
                params: {
                    path: "getAll2"
                },
                isArray: true
            },
            "updateType": {
                method: "GET",
                params: {
                    path: "updateType"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }


    function FontXmlsManger($resource) {
        var resourceUrl = 'manager/' + 'api/font-xmls/:path/:id';

        return $resource(resourceUrl, {}, {
            "save": {
                method: 'POST'
            },
            "delete": {
                method: 'DELETE'
            },
            "release": {
                method: 'POST',
                params:{
                    path:"release"
                },
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "getAll":{
                method:"POST",
                params:{
                    path:"getAll"
                },
                isArray:true
            },
            "getAll2":{
                method:"POST",
                params:{
                    path:"getAll2"
                },
                isArray:true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'onlyRelease': {
                method: 'GET',
                params:{
                    path:"onlyRelease"
                },
            },
            'share': {
                method: 'GET',
                params:{
                    path:"share"
                },
            },
            'auditing': {
                method: 'GET',
                params:{
                    path:"auditing"
                },
            },
        });
    }
    function FontXmlsFileServes($resource) {
        var resourceUrl = 'fileserves/' + 'api/font-xmls/:path/:id';

        return $resource(resourceUrl, {}, {
            "save": {
                method: 'POST'
            },
            "delete": {
                method: 'DELETE'
            },
            "release": {
                method: 'POST',
                params:{
                    path:"release"
                },
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "getAll":{
                method:"POST",
                params:{
                    path:"getAll"
                },
                isArray:true
            },
            "getAll2":{
                method:"POST",
                params:{
                    path:"getAll2"
                },
                isArray:true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'onlyRelease': {
                method: 'GET',
                params:{
                    path:"onlyRelease"
                },
            },
            'share': {
                method: 'GET',
                params:{
                    path:"share"
                },
            },
            'auditing': {
                method: 'GET',
                params:{
                    path:"auditing"
                },
            },
        });
    }
})();
