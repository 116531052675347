(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('ClipArt', ClipArt)
        .factory('Backgrounds', Backgrounds)
        .factory('Masks', Masks)
        .factory('Material', Material);

    ClipArt.$inject = ['$resource', 'DateUtils'];
    Backgrounds.$inject = ['$resource', 'DateUtils'];
    Masks.$inject = ['$resource', 'DateUtils'];
    Material.$inject = ['$resource', 'DateUtils'];

    function ClipArt($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/clipArt/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'byCondition3': {
                method: 'POST',
                params: {
                    path: 'byCondition3'
                },
                isArray: true
            },
            'byCondition4': {
                method: 'POST',
                params: {
                    path: 'byCondition4'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "updateType": {
                method: "GET",
                params: {
                    path: "updateType"
                }
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'getone'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                params: {
                    path: 'update'
                },
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                params: {
                    path: 'save'
                },
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'batchCreate':{
                method: 'GET',
                params: {
                    path: 'batchCreate'
                }
            },
            'delete': {
                method: 'DELETE',
                params: {
                    path: 'delete'
                }
            }
        });
    }

    function Backgrounds($resource) {
        var service = $resource('manager/api/backgrounds/:path/:id', {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray:true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
        return service;
    }

    function Masks($resource) {
        var service = $resource('manager/api/masks/:path/:id', {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray:true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
        return service;
    }

    function Material($resource) {
        var service = $resource('manager/api/materials/:path/:id', {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAll'
                },
                isArray: true
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray:true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
        return service;
    }
})();
