(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider 
        .state('webWechat', {
            parent: 'entity',
            url: '/{storeId}/wechat',
            data: {
                authorities: [],
                 pageTitle: 'global.menu.entities.wechat-binding'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/web-wechat/wechat.html',
                    controller: 'WebWechatController',
                    controllerAs: 'vm'
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Customer', function($stateParams, Customer) {
                    return Customer.current().$promise;
                }]
            }
        });
    }

})();
