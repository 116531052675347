(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('StandardCommodityDetailsNullController', StandardCommodityDetailsNullController);

    StandardCommodityDetailsNullController.$inject = ['$scope', 'ManagerCoupon', 'Coupon', '$state', 'MessageService', 'DateUtils', "$sce",
    '$q', '$stateParams', 'Qinius', 'entity', '$uibModal', 'StoreConfig', 'Product', 'Store', 'Cart', '$localStorage', '$timeout',
     'Editors','SkuSellLimits', 'Collection', 'CategoryStore', 'Theme'];

    function StandardCommodityDetailsNullController ($scope, ManagerCoupon, Coupon, $state, MessageService, DateUtils, $sce,
     $q, $stateParams, Qinius, entity, $uibModal, StoreConfig, Product, Store, Cart, $localStorage, $timeout,
      Editors,SkuSellLimits, Collection, CategoryStore, Theme) {
        var vm = this;
        $(".wrap").show();
        $(".navbar").show();
        vm.product = entity;
        
        vm.storeId = $stateParams.storeId;
        if (vm.storeId == null || vm.storeId == "") {
            return;
        }
        vm.pid = $stateParams.pid;
        vm.from = $stateParams.from;
    }
})();
