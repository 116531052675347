(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Qinius', 'LoginService', '$state', 'MessageService', 'Customer', '$localStorage', '$q', '$stateParams', '$cookieStore', 'Store', 'Theme',
        'MessageNotification','DateUtils', 'StoreConfig'];

    function HomeController ($scope, Qinius, LoginService, $state, MessageService, Customer, $localStorage, $q, $stateParams, $cookieStore, Store,Theme,
                             MessageNotification, DateUtils, StoreConfig) {
        var vm = this;
        handleFontSizeInit();
        vm.storeId = $stateParams.storeId || $cookieStore.get("store-id") || $state.params.storeId;
        vm.store = $localStorage.store;
        if (!vm.store) {
            vm.store = Store.get({id:vm.storeId});
        }
        Theme.judge();
        vm.auth = true;
        if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
            vm.auth = false;
        }
        if (vm.auth) {
            Customer.current({}, function(data){
                vm.customer =  data;
                $localStorage.user = data;
                if($localStorage.user.inactive){
                    $state.go('outofservice',{storeId: vm.storeId})
                }
            }, function(res){
                MessageService.error(res.data.message);
            });
        }

        StoreConfig.byStoreIdAndType2({type: 'Theme', storeId: vm.storeId},function(res){
            if (res) {
                localStorage.setItem('Theme', res.value);
            } else {
                localStorage.setItem('Theme', 'one');
            }
            Theme.judge();
        })
        $scope.domain = $localStorage.domain;
        if ($scope.domain == null  || $scope.domain == "") {
            Qinius.getDomain1({storeId: vm.storeId},function(da){
                $scope.domain = 'https://' + da.domains[0] + '/';
                $localStorage.domain = $scope.domain;
            });
        }

        vm.messageList = "";
        MessageNotification.getAllByCondition2({
                items:[
                    {key:"pageType", op : "=", value:"Index"},
                    {key:"storeId", op : "=", value:vm.storeId},
                    {key:"available", op : "=", value:"true"},
                    {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
            }, function (res) {
                res.forEach(function (item, index) {
                    vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                })
            }
        )

    }
})();
