(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ThumbnailatorController', ThumbnailatorController);

    ThumbnailatorController.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService','entity',
     'Convert', 'Base64Image', 'Qinius', 'ImageService'];

    function ThumbnailatorController( $scope, Category, $uibModalInstance, MessageService,entity,
     Convert, Base64Image, Qinius, ImageService) {

        var vm = this;
        vm.files = entity.files;
        vm.tokenTime = 360000;
        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });

        angular.forEach(vm.files,function (f) {
            f.blobCover = URL.createObjectURL(f);
        })
        vm.clear = clear;
        vm.save = save;
        vm.newFiles = [];

        $scope.deletePic = function ($index) {
            vm.files.splice($index,1)
        }

        function blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        };
        $scope.compression = function () {
            vm.loading = true;
            var f = 0;
            angular.forEach(vm.files,function (file,i) {
                var img = new  Image();
                img.src = file.blobCover;
                img.onload = function () {
                    var imgLength = img.height>img.width?img.height:img.width;
                    Base64Image.compress(file.blobCover, imgLength, 9.5, function(newBase64) {
                        var compressedSize = Base64Image.getSize(newBase64);
                        var blob = dataURLtoBlob(newBase64);
                        var aasfile = blobToFile(blob, file["name"]);
                        var aafile = new File([aasfile], file["name"], { type: file["type"] });
                        ImageService.backendUploadPics(aafile, $scope.domain, vm.token, function (res, aafile) {
                            f++;
                            file.lowerSize = Math.ceil(compressedSize/1024/1024);
                            file.newSize = compressedSize;
                            aafile.key = res.key;
                            aafile.hash = res.hash;
                            vm.newFiles.push(aafile);
                            if(f == vm.files.length){
                                vm.isEnd = true;
                                vm.loading = false;
                                $scope.$digest();
                            }
                        },function (res) {
                            f++;
                            if(f == vm.files.length){
                                vm.isEnd = true;
                                vm.loading = false;
                                $scope.$digest();
                            }
                        });
                    });
                }
            })
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            $uibModalInstance.close(vm.newFiles);
            vm.isSaving = false;
            // onSaveSuccess(vm.files);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();
