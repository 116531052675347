(function () {
    'use strict';

    var copyRight = {
        template: '<div style="text-align: center; margin-top: 15px;">'+
                    '<div id="icpView" style="font-size:13px;color: #bdb9b9; cursor: pointer;"></div>'+
                    '<div id="copyRight" style="font-size:13px;color: #bdb9b9;"></div>'+
            '</div>',
        bindings: {
            storeId:'=',
            column:'='
        },

        controller: ['$stateParams', '$state', '$scope', 'Store', '$localStorage' ,  function ($stateParams, $state, $scope, Store, $localStorage ) {
            var vm = this;
            document.getElementById("copyRight").innerText="由杉卓科技提供技术支持";
            if ($localStorage.store && $localStorage.store.id == vm.storeId) {
                    vm.store =  $localStorage.store;
                    console.log(vm.store)
                    if (!vm.store.techProvider) {
                        document.getElementById("copyRight").innerText=vm.store.techProvider;
                    }
                    if(vm.store.icp){
                        document.getElementById("icpView").innerText=vm.store.icp;
                    }
            }else{
                Store.get({id:vm.storeId}, function(data){
                    vm.store = data;
                    console.log(vm.store)
                    if (!vm.store.techProvider) {
                        document.getElementById("copyRight").innerText=vm.store.techProvider;
                    }
                    if(vm.store.icp){
                        document.getElementById("icpView").innerText=vm.store.icp;
                    }
                })
            }
            
            $("#icpView").on('click',function () {
                if(vm.store.icpLink){
                    window.open(vm.store.icpLink)
                }
            })
        }]
    };

    angular
        .module('editorApp')
        .component('copyRight', copyRight);
})();
