(function () {
    'use strict';

    var FontFamilyControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/controls/font-family.html',
        controllerAs: 'vm',
        bindings: {
            family: "=",
            onChange:'&'
        },

        controller: ['$scope', 'FontManagement', '$q', 'Qinius', function ($scope, FontManagement, $q, Qinius) {
            var vm = this;
            $scope.domains = Qinius.getDomain();
            $q.all([$scope.domains.$promise]).then(function () {
                vm.url = $scope.domains.domain;
            })
            //获取字体
            vm.fontList = [];
            vm.fontList1 = [];
            FontManagement.getAll2({}, function (resp) {
                for (var i = 0; i < resp.length; i++) {
                    vm.fontList.push(resp[i]);
                }
                vm.fontList1 = angular.copy(vm.fontList);
            })
            $scope.selectFontName = function (item) {
                if (item) {
                    for (var i = 0; i < vm.fontList1.length; i++) {
                        if (vm.fontList1[i].uuid == item) {
                            return vm.fontList1[i].family
                        }
                    }
                }
            }
            vm.selectFont = '';
            vm.selectFontBtn = fontInit;
            var a = null;
            function fontInit() {
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function () {
                    vm.fontList = [];
                    FontManagement.getAll2({family: vm.selectFont}, function (res) {
                        vm.fontList = res;
                    });
                },800)
            }

            $scope.updateTextFont = function (data) {
                vm.family = data.uuid;
                vm.onChange({text: vm.family});
                getFontCSS(FontManagement, {
                    fontuuid: vm.family
                }, $scope)
            }

        }]
    };
    angular.module('editorApp')
        .component('fontFamily', FontFamilyControl);

})();
