(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('Cart', Cart)
        .factory('CustomerConsumptionList', CustomerConsumptionList)
        .factory('CustomerPayOrder', CustomerPayOrder)
        .factory('StoreRechargeSetting', StoreRechargeSetting);
 

    Cart.$inject = ['$resource'];
    StoreRechargeSetting.$inject = ['$resource'];
    CustomerConsumptionList.$inject = ['$resource'];
    CustomerPayOrder.$inject = ['$resource'];

    function CustomerPayOrder ($resource) {
        var resourceUrl =  'manager/' + 'api/customer-pay-orders/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            "byCondition1":{
                method: 'POST',
                params: {
                    path: 'byCondition1'
                },
                isArray:true,
            },
            "weChatQRCode":{
                method: 'GET',
                params: {
                    path: 'weChatQRCode'
                }
            }
        });
    }

    function CustomerConsumptionList ($resource) {
        var resourceUrl =  'manager/' + 'api/customer-consumption-lists/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            "byCondition":{
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray:true,
            },
            "getAllByCondition":{
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray:true,
            },
        });
    }


    function Cart ($resource) {
        var resourceUrl =  'service/' + 'api/carts/:path/';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            
            'addGoods':{
                method: 'GET',
                params: {
                    path: 'addGoods'
                }
            },
            'countGoods':{
                method: 'GET',
                params: {
                    path: 'countGoods'
                }
            },
            "quantityChange":{
                method: 'GET',
                params: {
                    path: 'quantityChange'
                }
            },
            'removeGoods':{
                method: 'GET',
                params: {
                    path: 'removeGoods'
                }
            },
            'countByItemValueLineId':{
                method: 'GET',
                params: {
                    path: 'countByItemValueLineId'
                }
            }
        });
    }

    function StoreRechargeSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/store-recharge-settings/:path/';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            "byCondition":{
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray:true,
            },
            'getAllByCondition':{
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray:true,
            }
        });
    }
})();
