(function () {
    'use strict';
    angular
        .module('editorApp')
        .factory('Product', Product)
        .factory('MessageNotification', MessageNotification)
        .factory('ItemValue', ItemValue)
        .factory('Qinius', Qinius)
        .factory('WxJS', WxJS)
        .factory('ServiceWxJS', ServiceWxJS)
        .factory('WechatAuth', WechatAuth)
        .factory('PlatformWechatSetting', PlatformWechatSetting)
        .factory('StoreStyleSetting', StoreStyleSetting)
        .factory('StoreWechatSetting', StoreWechatSetting)
        .factory('Store', Store)
        .factory('Convert', Convert)
        .factory('Convert1', Convert1)
        .factory('PageForView', PageForView)
        .factory('PlatformAbutment', PlatformAbutment)
        .factory('Cart', Cart)
        .factory('Iphone', Iphone)
        .factory('OrderLine', OrderLine)
        .factory('ProductLine', ProductLine)
        .factory('FilterOrderRecords', FilterOrderRecords)
        .factory('PreviewSettings', PreviewSettings)
        .factory('Collection', Collection)
        .factory('StoreWorkEditConfigs', StoreWorkEditConfigs)
        .factory('Algorithm', Algorithm);

    WechatAuth.$inject = ['$resource'];
    Product.$inject = ['$resource'];
    MessageNotification.$inject = ['$resource'];
    ItemValue.$inject = ['$resource'];
    Qinius.$inject = ['$resource'];
    WxJS.$inject = ['$resource'];
    ServiceWxJS.$inject = ['$resource'];
    Convert.$inject = ['$resource'];
    Convert1.$inject = ['$resource'];
    StoreWechatSetting.$inject = ['$resource'];
    Store.$inject = ['$resource'];
    PlatformWechatSetting.$inject = ['$resource'];
    StoreStyleSetting.$inject = ['$resource'];
    PageForView.$inject = ['$resource'];
    PlatformAbutment.$inject = ['$resource'];
    Cart.$inject = ['$resource'];
    OrderLine.$inject = ['$resource'];
    ProductLine.$inject = ['$resource'];
    FilterOrderRecords.$inject = ['$resource'];
    PreviewSettings.$inject = ['$resource'];
    Collection.$inject = ['$resource'];
    StoreWorkEditConfigs.$inject = ['$resource'];
    Algorithm.$inject = ['$resource'];

    function Collection($resource) {
        var resourceUrl = 'manager/' + 'api/collections/:path/:id';
        return $resource(resourceUrl, {}, {
            "add": {
                method: "get",
                params: {
                    path: "add"
                }
            },
            "isCollected": {
                method: "POST",
                params: {
                    path: "isCollected"
                }
            },
            'delete': {
                method: 'DELETE'
            },
            'byCondition':{
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            'getAllByCondition':{
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                }
            },
            'batchDelete':{
                method: 'POST',
                params:{
                    path: 'batchDelete'
                }
            }
        });
    }

    function Algorithm($resource) {
        var resourceUrl = 'service/' + 'api/algorithm/:path/:id';
        return $resource(resourceUrl, {}, {
            "pageSelect": {
                method: "POST",
                params: {
                    path: "pageSelect"
                }
            },
            "pageSelectV2": {
                method: "POST",
                params: {
                    path: "pageSelectV2"
                }
            },
        });
    }
    function StoreWorkEditConfigs($resource) {
        var resourceUrl = 'manager/' + 'api/store-work-edit-configs/:path/:id';
        return $resource(resourceUrl, {}, {
            "storeId": {
                method: "GET",
                params: {
                    path: "storeId"
                }
            },
        });
    }
    function PreviewSettings($resource) {
        var resourceUrl = 'manager/' + 'api/preview-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            "byStoreId": {
                method: "GET",
                params: {
                    path: "byStoreId"
                }
            },
        });
    }
    function FilterOrderRecords($resource) {
        var resourceUrl = 'manager/' + 'api/filter-order-records/:path/:id';
        return $resource(resourceUrl, {}, {
            "byStore": {
                method: "GET",
                params: {
                    path: "byStore"
                },
                isArray: true
            },
        });
    }
    function WechatAuth($resource) {
        var resourceUrl = 'manager/' + 'wx/:path/:id';
        return $resource(resourceUrl, {}, {
            "auth_notify3": {
                method: "GET",
                params: {
                    path: "customer/auth_notify3"
                }
            },
            "auth_notify4": {
                method: "GET",
                params: {
                    path: "customer/auth_notify4"
                }
            },

        });
    }

    function Product($resource) {
        var resourceUrl = 'manager/' + 'api/products/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "getCategoriesByProduct":{
                method:"GET",
                params:{
                    path:"getCategoriesByProduct"
                },
                isArray: true
            },
            "getAllByCondition2": {
                method: "POST",
                params: {
                    path: "getAllByCondition2"
                },
                isArray: true
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },

            "getPrice":{
                method: "GET",
                params: {
                    path: "getPrice"
                },
            },
            "getCost":{
                method: "GET",
                params: {
                    path: "getCost"
                },
            },
            "getCost2":{
                method: "POST",
                params: {
                    path: "getCost2"
                },
            },
            "getProductsClient":{
                method: "GET",
                params: {
                    path: "getProductsClient"
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "getFreight":{
                method:"GET",
                params:{
                    path:"getFreight"
                }
            },
            "getCostV2":{
                method: "GET",
                params: {
                    path: "getCost_v2"
                },
            },
        });
    }

    function MessageNotification ($resource) {
        var resourceUrl =  'manager/' + 'api/message-notifications/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            }
        });
    }

    function ItemValue($resource) {
        var resourceUrl = 'manager/' + 'api/item-values/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function Qinius($resource) {
        return $resource('manager/api/qiniu/:path/:id', {}, {
            'get': {
                method: 'GET',
                params: {
                    path: 'upload-token'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'tokenWithPolicy': {
                method: 'GET',
                params: {
                    path: 'tokenWithPolicy'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getDomain': {
                method: 'GET',
                params: {
                    path: 'bucket-domains'
                }
            },
            'getDomain1': {
                method: 'GET',
                params: {
                    path: 'bucket-domains1'
                }
            }
        });
    }

    function WxJS($resource) {
        var resourceUrl = 'manager/' + 'api/wechat/:path';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params: {
                    path: "webconfig"
                }
            },
            'get1': {
                method: 'GET',
                params: {
                    path: "small_webconfig"
                }
            },
            'get2': {
                method: 'GET',
                params: {
                    path: "webconfig2"
                }
            },
            'getTicket': {
                method: 'GET',
                params: {
                    path: "js-ticket"
                }
            },
            'payConfig': {
                method: 'GET',
                params: {
                    path: "payConfig"
                }
            },
            'wechartQRPay': {
                method: 'GET',
                params: {
                    path: 'wechartQRPay'
                }
            }
        });
    }

    function ServiceWxJS ($resource) {
        var resourceUrl =  'service/' + 'api/wechat/:path';

        return $resource(resourceUrl, {}, {
            'payConfig': {
                method: 'GET',
                params:{
                    path:"payConfig"
                }
            }
        });
    }

    function StoreWechatSetting($resource) {
        var resourceUrl = 'manager/' + 'api/store-wechat-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            "get": {
                method: "GET"
            },
            "getByStoreId": {
                method: "GET",
                params: {
                    path: "getByStoreId"
                }
            },
            "getAuthorizerInfo": {
                method: "GET",
                params: {
                    path: "getAuthorizerInfo"
                },
                isArray: true
            },
            "getPublicWechatSetting":{
                method:"GET",
                params:{
                    path:"getPublicWechatSetting"
                }
            }
        });
    }

    function Store($resource) {
        var resourceUrl = 'manager/' + 'api/stores/:path/:id';

        return $resource(resourceUrl, {}, {
            "get": {
                method: "GET"
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "current": {
                method: "GET",
                params: {
                    path: "getStore"
                }
            }
        });
    }

    function PlatformWechatSetting($resource) {
        var resourceUrl = "manager/api/platform-wechat-settings/:path/:id";

        return $resource(resourceUrl, {}, {
            "get": {
                method: "GET"
            }
        });
    }

    function StoreStyleSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/store-style-settings/:id/:storeId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function Convert ($resource) {
        var resourceUrl =  'fileserves/' + 'api/convert/:path/:storeId';

        return $resource(resourceUrl, {}, {
            'xmlToPicLayoutDocument': {
                method: 'GET',
                params: {
                    path: 'xmlToPicLayoutDocument'
                }
            },
            "getItemDocument": {
                method:"GET",
                params:{
                    path:"getItemDocument"
                }
            },
            "downloadImg4AliH5AngUpload7Niu": {
                method:"POST",
                params:{
                    path:"downloadImg4AliH5AngUpload7Niu"
                }
            },
            "fileUpload": {
                method:"POST",
                params:{
                    path:"fileUpload"
                }
            },
            "thumbnailator": {
                method:"GET",
                params:{
                    path:"thumbnailator"
                }
            },
            "xmlToDocument2":{
                method:"GET",
                params:{
                    path:"xmlToDocument2"
                }
            },
            "xmlToDocument":{
                method:"GET",
                params:{
                    path:"xmlToDocument"
                }
            },
            "imgFilter":{
                method: 'GET',
                params: {
                    path: "filter"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'xmlToImg'
                }
            },
            'imageEnhance': {
                method: 'GET',
                params: {
                    path: 'imageEnhance'
                }
            },
            'getProductDocument': {
                method: 'GET',
                params: {
                    path: 'getProductDocument'
                }
            }
        });
    }
    function Convert1 ($resource) {
        var resourceUrl =  'justify/' + 'api/:path/:storeId';

        return $resource(resourceUrl, {}, {
            'documentToImg': {
                method: 'POST',
                params: {
                    path: 'documentToImg'
                },
                isArray: true
            },
        });
    }

    function PageForView($resource) {
        var resourceUrl = "service/api/page-for-views/:path/:id";
        return $resource(resourceUrl, {}, {
            "get": {
                method: "GET"
            }
        });
    }

    function PlatformAbutment ($resource) {
        var resourceUrl =  'manager/' + 'api/platform-abutment/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            'getAllByCondition':{
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray:true
            }
        });
    }

    function Cart ($resource) {
        var resourceUrl =  'service/' + 'api/carts/:path/';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            'addGoods':{
                method: 'GET',
                params: {
                    path: 'addGoods'
                }
            },
            "quantityChange":{
                method: 'GET',
                params: {
                    path: 'quantityChange'
                }
            },
            'removeGoods':{
                method: 'GET',
                params: {
                    path: 'removeGoods'
                }
            }
        });
    }

    function OrderLine ($resource) {
        var resourceUrl =  'service/' + 'api/order-lines/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            'addGoods':{
                method: 'GET',
                params: {
                    path: 'addGoods'
                }
            },
            "quantityChange":{
                method: 'GET',
                params: {
                    path: 'quantityChange'
                }
            },
            'removeGoods':{
                method: 'GET',
                params: {
                    path: 'removeGoods'
                }
            }
        });
    }
    function ProductLine ($resource) {
        var resourceUrl =  'service/' + 'api/product-lines/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            'addGoods':{
                method: 'GET',
                params: {
                    path: 'addGoods'
                }
            },
            "quantityChange":{
                method: 'GET',
                params: {
                    path: 'quantityChange'
                }
            },
            'removeGoods':{
                method: 'GET',
                params: {
                    path: 'removeGoods'
                }
            }
        });
    }


    function Iphone () {
        var isIphone = /iphone/gi.test(window.navigator.userAgent);
        var isIphone678 = window.screen.width === 375 && window.screen.height === 667;
        var isIphone678p = window.screen.width === 414 && window.screen.height === 736;
        var isIphoneSE = window.screen.width === 320 && window.screen.height === 568;
        var isIphone5 = window.screen.width === 320 && window.screen.height === 568;
        if (!isIphone || isIphone && (isIphone678 || isIphone678p || isIphoneSE || isIphone5)) {
            return false
        } else {
            return true
        }
    }
})();
