(function () {
    'use strict';

    var dot = {
        templateUrl: 'app/entities/sales-order/components/dot.html',
        bindings: {
            orderAchieve:'=',//状态
        },
        controller: ['$stateParams', '$state', '$scope', 'Store', '$localStorage', '$q', 'Qinius', '$timeout','Gallery','Folder','UidService',
            function ($stateParams, $state, $scope, Store, $localStorage, $q, Qinius, $timeout,Gallery,Folder, UidService) {
            var vm = this;
            $scope.orderAchieve = vm.orderAchieve;
            console.log(vm.orderAchieve);
        }]
    };
    
    angular
        .module('editorApp')
        .component('dot', dot);
})();
