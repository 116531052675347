(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('LocationProvice', LocationProvice)
        .factory('LocationCity', LocationCity)
        .factory('StoreConfig', StoreConfig)
        .factory('SkuSellLimits', SkuSellLimits)
        .factory('CategoryStore', CategoryStore)
        .factory('HotWords', HotWords)
        .factory('LocationDistrict', LocationDistrict);

    LocationProvice.$inject = ['$resource']

    LocationCity.$inject = ['$resource']

    StoreConfig.$inject = ['$resource']

    SkuSellLimits.$inject = ['$resource']

    CategoryStore.$inject = ['$resource']

    HotWords.$inject = ['$resource']

    LocationDistrict.$inject = ['$resource']

    function StoreConfig($resource) {
        var resourceUrl = 'manager/' + 'api/store-configs/:client/:path/:id';

        return $resource(resourceUrl, {}, {
            'getCurrent': {
                method: 'GET',
                params: {
                    path: "current"
                },
            },
            'getAllByTypes': {
                method: 'POST',
                params: {
                    path: "getAllByTypes"
                }
            },

             'byStoreIdAndType': {
                method: 'GET',
                params: {
                    path: "byStoreIdAndType"
                },
            },

            'byStoreIdAndType2': {
                method: 'GET',
                params: {
                    path: "byStoreIdAndType",
                    client:"mini"
                },
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'update': {
                method: 'PUT',
            }
        });
    }

    function LocationProvice($resource) {
        var resourceUrl = 'manager/' + 'api/location-provinces/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                },
            }
        });
    }
    function SkuSellLimits($resource) {
        var resourceUrl = 'manager/' + 'api/sku-sell-limits/:path/:id';
        return $resource(resourceUrl, {}, {
            "getByItemValueLineId":{
                method:"GET",
                params:{
                    path:"getByItemValueLineId"
                }
            },
        });
    }

    function LocationCity($resource) {
        var resourceUrl = 'manager/' + 'api/location-cities/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByProvince":{
                method:"GET",
                params:{
                    path:"findByProvince"
                },
                isArray: true
            },
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                },
            }
        });
    }

    function LocationDistrict($resource) {
        var resourceUrl = 'manager/' + 'api/location-districts/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            "findByCities":{
                method:"GET",
                params:{
                    path:"findByCities"
                },
                isArray: true
            },
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                },
            }

        });
    }

    function CategoryStore ($resource) {
        var resourceUrl =  'manager/' + 'api/categories/:store/:client/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition",
                    store:"store"
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition",
                    client:"mini",
                    store:"store"
                },
                isArray: true
            },
            "get":{
                method:"GET",
            }
        });
    }

    function HotWords ($resource) {
        var resourceUrl =  'manager/' + 'api/hot-words/store/:path/:id';

        return $resource(resourceUrl, {}, {
            'enabled':{
                method: 'GET',
                params:{
                    path:"enabled"
                }
            }
        });
    }
})();
