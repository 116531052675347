(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('StandardCommodityDetailsController', StandardCommodityDetailsController);

    StandardCommodityDetailsController.$inject = ['$scope', 'ManagerCoupon', 'Coupon', '$state', 'MessageService', 'DateUtils', "$sce",
    '$q', '$stateParams', 'Qinius', 'entity', '$uibModal', 'StoreConfig', 'Product', 'Store', 'Cart', '$localStorage', '$timeout',
     'Editors','SkuSellLimits', 'Collection', 'CategoryStore', 'Theme'];

    function StandardCommodityDetailsController ($scope, ManagerCoupon, Coupon, $state, MessageService, DateUtils, $sce,
     $q, $stateParams, Qinius, entity, $uibModal, StoreConfig, Product, Store, Cart, $localStorage, $timeout,
      Editors,SkuSellLimits, Collection, CategoryStore, Theme) {
        var vm = this;
        $(".wrap").show();
        $(".navbar").show();
        vm.product = entity;
        vm.storeId = $stateParams.storeId;
        if (vm.storeId == null || vm.storeId == "") {
            return;
        }
        if(!vm.product.saleable || vm.product.cancelled){
            $state.go('standardCommodityDetailsNull', {storeId: vm.storeId, pid: vm.product.id, from:$stateParams.from});
            return
        }
        vm.pid = $stateParams.pid;
        vm.from = $stateParams.from;
        vm.price = 0;
        vm.copy = 1;
        vm.quantity = 1;
        vm.itemValueLine = null;
        vm.itemValue = null;
        vm.store = $localStorage.store;
        $scope.domain = $localStorage.domain;
        vm.storeConfig = StoreConfig.byStoreIdAndType({storeId:vm.storeId,type:'COUPON'});
        vm.storeCartConfig = StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"SHOPPINGCART"});
        vm.storeDeliveryConfig = StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"PLACEOFDELIVERY"});
        Product.getFreight({productId: vm.pid}, function(data){
            if (data.status == 200) {
                vm.freight =  data.message;
            }
        });
        vm.couponsData = [];
        vm.pics = [];
        vm.warningQuantity = null;
        vm.limitQuantity = null;

        $scope.getCollections = function(){
            Collection.isCollected([vm.pid], function(res){
                if (res && res.status && res.status == 200) {
                    vm.collection =res.message[0];
                }
            });
        }
        $scope.getCollections();

        $scope.collect = function () {
            if(vm.collection && vm.collection.collected){
                Collection.delete({id:vm.product.id}, function (res) {
                    if(res.status == 200){
                        MessageService.success("取消收藏成功");
                        $scope.getCollections()
                    }else{
                        MessageService.error(res.message || "取消收藏失败");
                    }
                })
            }else{
                Collection.add({productId:vm.product.id}, function (res) {
                    if(res.status == 200){
                        MessageService.success("收藏成功");
                        $scope.getCollections()
                    }else{
                        MessageService.error(res.message || "收藏失败");
                    }
                })
            }
        }

        $scope.plus = function(){
            $(".copy-min").removeAttr("disabled");
            if ((vm.limitQuantity!=null && vm.copy< vm.limitQuantity)||vm.limitQuantity == null) {
                vm.copy ++;
            } else{
                MessageService.error("库存不足，无法购买更多");
            }
            if (vm.limitQuantity!=null && vm.copy >= vm.limitQuantity) {
                $(".copy-plus").attr("disabled", "disabled");
            }
            $scope.getCost();
        }

        $scope.min = function(){
            if (vm.copy>1 && vm.copy>vm.product.minimumOrder ) {
                vm.copy --;
            }else{
                MessageService.error("商品数量不能再减少了");
            }
            if (vm.copy<=1 || vm.copy<=vm.product.minimumOrder) {
                $(".copy-min").attr("disabled", "disabled");
            }
            $scope.getCost();
        }

        $scope.plusQuantity = function(){
            vm.quantity ++;
            $(".quantity-min").removeAttr("disabled");
            $scope.getCost();
        }

        $scope.minQuantity = function(){
            if (vm.quantity>1) {
                vm.quantity --;
            }else{
                MessageService.error("数量不能再减少了");
            }
            if (vm.quantity<=1) {
                $(".quantity-min").attr("disabled", "disabled");
            }

            $scope.getCost();
        }

        $scope.getCost = function(){
            if ( vm.itemValueLine  && vm.product) {
                var parameter = {
                    productId: vm.product.id,
                    itemValueId: vm.itemValueLine.itemValueId,
                    itemValueLineId: vm.itemValueLine.id,
                    quantity: vm.quantity,
                    copy : vm.copy,
                }
                Product.getCostV2(parameter,function(data){
                    if (data.status == 200) {
                        vm.amount = data.message.toFixed(2);
                        vm.price = (data.message/vm.copy).toFixed(2);
                    }
                },function(error){
                    MessageService.error(error.data.errorMessage)
                })
            }
        }

        $scope.copyChange = function(){
            if (vm.copy < vm.product.minimumOrder) {
                vm.copy  = vm.product.minimumOrder;
                MessageService.error("商品数量不能再减少了");
            }

            var quantity = 0;
            if (vm.product.editorType == 'PICTURE' && !vm.product.jhiPackage ) {
                quantity = vm.quantity*vm.copy;
            }else{
                quantity = vm.copy;
            }
            if (vm.limitQuantity!=null&& quantity > vm.limitQuantity) {
                vm.copy  = vm.product.minimumOrder ||1;
                MessageService.error("库存不足，无法购买更多");
                return
            }
            $scope.getCost();
        }

        function swiper() {
            var swiper = new Swiper('.swiper-container', {
                slidesPerView: 5,
                slidesPerGroup : 5,
                spaceBetween : 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }

        $scope.getImg = function(pic){
            if (pic == null) {
                return;
            }
            if (pic.type == 'img') {
                vm.videoUrl = null;
                vm.cover = pic.imgUrl;

            }else{
                vm.videoUrl = pic.imgUrl;
                vm.cover = null;
            }
        }

        $q.all([vm.product.$promise, vm.storeConfig.$promise, vm.storeCartConfig.$promise]).then(function () {
            vm.copy = vm.product.minimumOrder;
            if (vm.from == 'commodity') {
                if (vm.product.categoryStoreId) {
                    CategoryStore.get({id:vm.product.categoryStoreId}, function(data){
                        vm.categoryName = data.name;
                        if (data.haveSuperior) {
                            vm.parentCategoryName = data.categoryName;
                        }
                    })
                }
            }
            if (vm.product.productPics.length > 0) {
                vm.product.productPics = vm.product.productPics.filter(function(pic) {
                    return !pic.cancelled;
                });
                vm.product.productPics.sort(function(a, b) {
                    return a.sort - b.sort;
                });
                vm.product.productPics.forEach(function (item) {
                    if(!item.cancelled){
                        item.type = "img";
                        vm.pics.push(item)
                    }
                })
            }

            if (!!vm.product.videoUrl) {
                $timeout(function() {
                    document.getElementById('source').setAttribute("src", $scope.domain + vm.product.videoUrl);
                    document.getElementById("video").load();
                }, 100);
                vm.pics.unshift({imgUrl:vm.product.videoUrl, type:'video', sort:0,cancelled:false});
            }
            if(vm.pics.length == 0){
                vm.pics.push({
                    imgUrl:vm.product.coverImg,
                    type:'img',
                    sort:0,
                    cancelled:false
                })
            }
            $timeout(function() {
                $scope.getImg(vm.pics[0])
                swiper();
            }, 200);


            $scope.html = $sce.trustAsHtml(vm.product.richText);

            for (var i = 0; i < vm.product.itemValues.length; i++) {
                var item = vm.product.itemValues[i];
                item.itemValueLines = item.itemValueLines.filter(function(line) {
                    return !line.cancelled && line.saleable;
                });
            }
            vm.product.itemValues = vm.product.itemValues.filter(function(item) {
                return !item.cancelled&& item.itemValueLines.length>0;
            });
            for (var i = 0; i < vm.product.itemValues.length; i++) {
                var item = vm.product.itemValues[i];
                if (item.default) {
                    if (vm.product.itemValue == null) {
                        vm.product.itemValue = angular.copy(item);
                        vm.itemValue = angular.copy(item);
                        if (vm.product.itemValue.objectValues && vm.product.itemValue.objectValues.length > 0) {
                            for (var z = vm.product.itemValue.objectValues.length - 1; z >= 0; z--) {
                                var value = vm.product.itemValue.objectValues[z];
                                value.selected = true;
                                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                                    if (value.propertyValueId == value.propertyValues[j].id) {
                                        value.propertyValues[j].selected = true;
                                    }
                                }
                            }
                        }
                    }
                    if (item.itemValueLines) {
                        for (var j = item.itemValueLines.length - 1; j >= 0; j--) {
                            var itemLine = item.itemValueLines[j];
                            if (itemLine.default) {
                                vm.product.itemValueLine = angular.copy(itemLine);
                                vm.itemValueLine = angular.copy(itemLine);
                                if (vm.product.itemValueLine.itemValueObjectValues && vm.product.itemValueLine.itemValueObjectValues.length > 0) {
                                    for (var z = vm.product.itemValueLine.itemValueObjectValues.length - 1; z >= 0; z--) {
                                        var value = vm.product.itemValueLine.itemValueObjectValues[z];
                                        value.selected = true;
                                        for (var x = value.propertyValues.length - 1; x >= 0; x--) {
                                            if (value.propertyValueId == value.propertyValues[x].id) {
                                                value.propertyValues[x].selected = true;
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                        }
                        if (vm.itemValueLine == null) {
                            for (var j = item.itemValueLines.length - 1; j >= 0; j--) {
                                var itemLine = item.itemValueLines[j];
                                vm.product.itemValueLine = angular.copy(itemLine);
                                vm.itemValueLine = angular.copy(itemLine);
                                if (vm.product.itemValueLine.itemValueObjectValues && vm.product.itemValueLine.itemValueObjectValues.length > 0) {
                                    for (var z = vm.product.itemValueLine.itemValueObjectValues.length - 1; z >= 0; z--) {
                                        var value = vm.product.itemValueLine.itemValueObjectValues[z];
                                        value.selected = true;
                                        for (var x = value.propertyValues.length - 1; x >= 0; x--) {
                                            if (value.propertyValueId == value.propertyValues[x].id) {
                                                value.propertyValues[x].selected = true;
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                        }
                    }
                }
            }
            if (vm.product.itemValue == null || vm.product.itemValueLine == null) {
                for (var i = 0; i < vm.product.itemValues.length; i++) {
                    var item = vm.product.itemValues[i];
                    if (vm.product.itemValue == null) {
                        vm.product.itemValue = angular.copy(item);
                        if (item.itemValueLines) {
                            var itemLineDefault = null;
                            for (var j = item.itemValueLines.length - 1; j >= 0; j--) {
                                var itemLine = item.itemValueLines[j];
                                if (itemLine.default) {
                                    vm.product.itemValueLine = angular.copy(itemLine);
                                    break;
                                }
                                if (itemLineDefault == null) {
                                    itemLineDefault = angular.copy(itemLine);
                                }
                            }
                            if (vm.product.itemValueLine == null && itemLineDefault != null) {
                                vm.product.itemValueLine = itemLineDefault;
                            }
                        }
                    }

                    if (vm.product.itemValueLine == null) {
                        if (vm.product.itemValue.itemValueLines != null) {
                            var itemLineDefault = null;
                            for (var j = vm.product.itemValue.itemValueLines.length - 1; j >= 0; j--) {
                                var itemLine = vm.product.itemValue.itemValueLines[j];
                                if (itemLine.default) {
                                    vm.product.itemValueLine = angular.copy(itemLine);
                                    break;
                                }
                                if (itemLineDefault == null) {
                                    itemLineDefault = angular.copy(itemLine);
                                }
                            }
                            if (vm.product.itemValueLine == null && itemLineDefault != null) {
                                vm.product.itemValueLine = itemLineDefault;
                            }
                        }
                    }
                }
            }

            if (vm.product.itemValueLine == null || vm.product.itemValue == null) {
                MessageService.error("商品配置不全，无法下单");
                return;
            }

            vm.product.itemValue.valueArray = vm.product.itemValue.skuNumber.split("-");
            vm.product.itemValueLine.itemValueObjectValues = vm.product.itemValue.objectValues.concat(vm.product.itemValueLine.itemValueObjectValues);
            vm.product.itemValueLine.valueArray = vm.product.itemValueLine.skuNumber.split("-");
            vm.price = vm.product.itemValue.price;

            if (vm.product.itemValues && vm.product.itemValues.length>0) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var item = vm.product.itemValues[i];
                    item.valueArray = item.skuNumber.split("-");
                }
            }

            if (vm.product.itemValue) {
                for (var i = vm.product.itemValue.objectValues.length - 1; i >= 0; i--) {
                    var objectValue = vm.product.itemValue.objectValues[i];
                    for (var j = objectValue.propertyValues.length - 1; j >= 0; j--) {
                        var value = objectValue.propertyValues[j];
                        if (!value.selected) {
                            objectValue.propertyValues.splice(j, 1);
                        }
                    }
                }
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var itL = vm.product.itemValues[i];
                    for (var j = itL.objectValues.length - 1; j >= 0; j--) {
                        var ov = itL.objectValues[j];
                        $scope.setPropertyValue(vm.product.itemValue, ov);
                    }
                }
                $scope.restoreAvailable(vm.product.itemValue.objectValues);
                $scope.getAvailable(vm.product.itemValues, vm.product.itemValue.objectValues);
            }

            var itemValueLines = [];
            if (vm.product.itemValues && vm.product.itemValues.length>0) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var item = vm.product.itemValues[i];
                    if (item.itemValueLines) {
                        for(var j = item.itemValueLines.length -1 ; j>=0; j--){
                            var itemLine = item.itemValueLines[j];
                            itemLine.itemValueObjectValues = item.objectValues.concat(itemLine.itemValueObjectValues);
                            itemLine.valueArray = itemLine.skuNumber.split("-");
                            itemValueLines.push(itemLine);
                        }
                    }
                }
            }
            vm.product.itemValueLines = itemValueLines;

            if (vm.product.itemValueLine && (vm.product.productType!= 'CustomPrint'||  vm.store.orderWay == 'orderThenEditor')) {
                for (var i = vm.product.itemValueLine.itemValueObjectValues.length - 1; i >= 0; i--) {
                    var objectValue = vm.product.itemValueLine.itemValueObjectValues[i];
                    // objectValue.propertyValues = [];
                    for (var j = objectValue.propertyValues.length - 1; j >= 0; j--) {
                        var value = objectValue.propertyValues[j];
                        if (!value.selected) {
                            objectValue.propertyValues.splice(j, 1);
                        }
                    }
                }
                for (var i = vm.product.itemValueLines.length - 1; i >= 0; i--) {
                    var itL = vm.product.itemValueLines[i];
                    for (var j = itL.itemValueObjectValues.length - 1; j >= 0; j--) {
                        var ov = itL.itemValueObjectValues[j];
                        $scope.setItemValueObjectValuePropertyValue(vm.product.itemValueLine, ov);
                    }
                }
                $scope.restoreAvailable(vm.product.itemValueLine.itemValueObjectValues);
                $scope.getAvailable(vm.product.itemValueLines, vm.product.itemValueLine.itemValueObjectValues);
            }
            $scope.getItemValueAndItemValueLine();
            //优惠券
            if (vm.storeConfig.open) {
                vm.coupons2 = Coupon.getAllByCondition({items:[]});
                vm.coupons = ManagerCoupon.getAllByCondition({items:[
                    {key: "endTime", op: ">=", value: DateUtils.convertLocalDateToServer(new Date())},
                    {key: "sendRule", op: "=",value: "Get"},
                    {key:"products.id", op:"=", value:vm.pid }]});
                $q.all([vm.coupons.$promise, vm.coupons2.$promise]).then(function(){
                    angular.forEach(vm.coupons,function (coupon) {
                        angular.forEach(coupon.products,function (products) {
                            if(products.id==vm.product.id){
                                vm.couponsData.push(coupon);
                            }
                        })
                    })
                    vm.len = vm.couponsData.length;
                    if (vm.couponsData.length > 0) {
                        for (var i = 0; i < vm.couponsData.length; i ++) {
                            for (var j = 0; j < vm.coupons2.length; j ++) {
                                if (vm.couponsData[i].id == vm.coupons2[j].couponId) {
                                    vm.couponsData[i].cancelled = true;
                                    vm.len--;
                                    break;
                                }
                            }
                        }
                    }
                })
            }
            if (vm.product.editorType == 'PICTURE'  && vm.product.jhiPackage) {
                if(vm.itemValue && vm.itemValue.packageQuantity){
                    vm.quantity = vm.itemValue.packageQuantity;
                }else{
                    vm.quantity =1 ;
                }
            }
            $scope.copyChange();
            getByItemValueLineId()
        });

        //当只有一个有效itevalue 和 itemvalueline的时候
        $scope.getItemValueAndItemValueLine = function(){
            vm.valueCount = 0;
            vm.valueLineCount = 0;
            if (vm.product.itemValues) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var item = vm.product.itemValues[i];
                    vm.valueCount ++;
                    for (var j = item.itemValueLines.length - 1; j >= 0; j--) {
                        var line = item.itemValueLines[j];
                        vm.valueLineCount++;
                    }
                }
            }
            if (vm.itemValue == null && vm.valueCount == 1) {
                vm.itemValue = vm.product.itemValue;
            }
            if (vm.itemValueLine == null && vm.valueLineCount == 1) {
                vm.itemValueLine = vm.product.itemValueLine;
            }
        }

        $scope.setItemValueObjectValuePropertyValue = function(itemLine, obValue){
            if (itemLine == null || itemLine.itemValueObjectValues == null || itemLine.itemValueObjectValues.length == 0) {
                return null;
            }
            for (var i = itemLine.itemValueObjectValues.length - 1; i >= 0; i--) {
                var objectValue = itemLine.itemValueObjectValues[i];
                if (objectValue.propertyId == obValue.propertyId) {
                    if (!$scope.includePropertyValue(objectValue.propertyValues, obValue)) {
                        objectValue.propertyValues.push({
                            id:obValue.propertyValueId,
                            value :obValue.propertyValueValue,
                            propertyId:obValue.propertyId,
                            propertyName: obValue.propertyName
                        })
                    }
                }
            }
        }

        $scope.setPropertyValue = function(item, obValue){
            if (item == null || item.objectValues == null || item.objectValues.length == 0) {
                return null;
            }
            for (var i = item.objectValues.length - 1; i >= 0; i--) {
                var objectValue = item.objectValues[i];
                if (objectValue.propertyId == obValue.propertyId) {
                    if (!$scope.includePropertyValue(objectValue.propertyValues, obValue)) {
                        objectValue.propertyValues.push({
                            id:obValue.propertyValueId,
                            value :obValue.propertyValueValue,
                            propertyId:obValue.propertyId,
                            propertyName: obValue.propertyName
                        })
                    }
                }
            }
        }

        $scope.includePropertyValue = function(propertyValues, obValue){
            if (propertyValues == null || propertyValues.length == 0) {
                return false;
            }
            for (var i = propertyValues.length - 1; i >= 0; i--) {
                var pValue = propertyValues[i];
                if (pValue.id == obValue.propertyValueId ) {
                    return true;
                }
            }
            return false;
        }


        $scope.itemValueSelected = function(itemValue){
            for (var i = 0; i < vm.product.itemValues.length; i ++) {
                if (vm.product.itemValues[i].used && vm.product.itemValues[i].id != itemValue.id) {
                    vm.product.itemValues[i].used = false;
                }
            }
            if (itemValue.used) {
                itemValue.used = false;
                 vm.itemValue = null;
            } else {
                itemValue.used = true;
                vm.itemValue = itemValue;
            }
        };

        //先将所有可选项置为可选择的
        $scope.restoreAvailable = function(values) {
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    value.propertyValues[j].available = true;
                }
            }
        }

        //判断余下属性值是否可以点选
        $scope.getAvailable = function(arrayValues, values) {
            var selectedValue = [];
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    var pv = value.propertyValues[j];
                    if (!$scope.isAvailable(arrayValues, [pv.propertyId + "_" + pv.id])) {
                        value.propertyValues[j].available = false;
                    }
                }
            }

            //先将选择的都拿出来
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (value.selected) {
                    for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                        var pv = value.propertyValues[j];
                        if (pv.selected) {
                            selectedValue.push(pv.propertyId + "_" + pv.id);
                        }
                    }
                }
            }
            //没有选中的行 选项是否可以被选择
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (!value.selected) {
                    for (var z = value.propertyValues.length - 1; z >= 0; z--) {
                        var pv = value.propertyValues[z];
                        if (!pv.available) {
                            continue;
                        }
                        var selectedValueBack = angular.copy(selectedValue);
                        selectedValueBack.push((pv.propertyId + "_" + pv.id));
                        if (!$scope.isAvailable(arrayValues, selectedValueBack)) {
                            pv.available = false;
                        }
                    }
                }
            }
            //选中的行  将本行取出 拿本行其他选项与 其他行已经选中的搭配看是否可以选中
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (value.selected ) {
                    var selectedValueBack = angular.copy(selectedValue);
                    for (var j = selectedValueBack.length - 1; j >= 0; j--) {
                        var selectV = selectedValueBack[j];  //3_10
                        if (selectV.split("_")[0]== value.propertyId) {
                            selectedValueBack.splice(j,1);
                            for (var z = value.propertyValues.length - 1; z >= 0; z--) {
                                var pValue = value.propertyValues[z];
                                if (pValue.selected || !pValue.available) {
                                    continue;
                                }
                                var selectedValueBack2 = angular.copy(selectedValueBack);
                                selectedValueBack2.push(pValue.propertyId + "_" + pValue.id);
                                if (!$scope.isAvailable(arrayValues, selectedValueBack2)) {
                                    pValue.available = false;
                                }
                            }
                        }
                    }
                }
            }
        }

        //判断属性值是否可以点选
        $scope.isAvailable = function(arrayValues, selectedValueBack) {
            for (var z = arrayValues.length - 1; z >= 0; z--) {
                var l = arrayValues[z];
                if (l.cancelled) {
                    continue;
                }

                var includes = true;
                for (var i = selectedValueBack.length - 1; i >= 0; i--) {
                    var el = selectedValueBack[i];
                    if (!$scope.isInclude(el, l.valueArray)) {
                        includes = false;
                        break;
                    }
                }
                if (includes) {
                    return true;
                }
            }
            return false;
        }

        $scope.isInclude = function(elememt, array) {
            if (array.indexOf(elememt)>=0) {
                return true;
            }
            return false;
        }

        //商户：先编辑后支付 且 商品：定制 点选属性值事件
        $scope.switchPaper = function (value , pValue) {
            if (!pValue.available) {
                return;
            }
            for (var i = value.propertyValues.length - 1; i >= 0; i--) {
                if (value.propertyValues[i].id == pValue.id) {
                    continue;
                }
                value.propertyValues[i].selected = false;
            }
            pValue.selected  = !pValue.selected;
            if (pValue.selected) {
                value.selected = true;
            }else{
                value.selected = false;
            }
            vm.cover = null;
            vm.videoUrl = null;
            $scope.restoreAvailable(vm.product.itemValue.objectValues);
            $scope.getAvailable(vm.product.itemValues, vm.product.itemValue.objectValues);

            var valueArray = [];
            for (var i = vm.product.itemValue.objectValues.length - 1; i >= 0; i--) {
                var value = vm.product.itemValue.objectValues[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    var pv = value.propertyValues[j];
                    if (pv.selected) {
                        valueArray.push(pv.propertyId + "_" + pv.id);
                    }
                }
            }
            vm.product.itemValue.valueArray = valueArray;
            vm.itemValue = null;
            for(var i=0;i<vm.product.itemValues.length;i++){
                var itemValue = vm.product.itemValues[i];
                if (JSON.stringify(itemValue.valueArray.sort()) === JSON.stringify(vm.product.itemValue.valueArray.sort())) {
                    vm.itemValue = itemValue;
                    vm.price = itemValue.price;
                    vm.cover = itemValue.coverImg;
                }
            }
            if (vm.cover == null && vm.pics.length>0) {
                $scope.getImg(vm.pics[0]);
            }
        }

        //非定制或 先支付 后编辑的 点选属性值事件
        $scope.switchPaper2 = function (value , pValue) {
            if (!pValue.available) {
                return;
            }

            for (var i = value.propertyValues.length - 1; i >= 0; i--) {
                if (value.propertyValues[i].id == pValue.id) {
                    continue;
                }
                value.propertyValues[i].selected = false;
            }
            pValue.selected  = !pValue.selected;
            if (pValue.selected) {
                value.selected = true;
            }else{
                value.selected = false;
            }
            vm.cover = null;
            vm.videoUrl = null;
            $scope.restoreAvailable(vm.product.itemValueLine.itemValueObjectValues);
            $scope.getAvailable(vm.product.itemValueLines, vm.product.itemValueLine.itemValueObjectValues);

            var valueArray = [];
            for (var i = vm.product.itemValueLine.itemValueObjectValues.length - 1; i >= 0; i--) {
                var value = vm.product.itemValueLine.itemValueObjectValues[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    var pv = value.propertyValues[j];
                    if (pv.selected) {
                        valueArray.push(pv.propertyId + "_" + pv.id);
                    }
                }
            }
            vm.product.itemValueLine.valueArray = valueArray;
            vm.itemValueLine = null;
            for(var i=0;i<vm.product.itemValueLines.length;i++){
                var itemValueLine = vm.product.itemValueLines[i];
                if (JSON.stringify(itemValueLine.valueArray.sort()) === JSON.stringify(vm.product.itemValueLine.valueArray.sort())) {
                    vm.itemValueLine = itemValueLine;
                    for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                        var item = vm.product.itemValues[i];
                        if (item.id == vm.itemValueLine.itemValueId) {
                            vm.cover = item.coverImg;
                            vm.itemValue = item;
                            break;
                        }
                    }
                    break;
                }
            }
            if (vm.product.editorType == 'PICTURE'  && vm.product.jhiPackage) {
                if(vm.itemValue && vm.itemValue.packageQuantity){
                    vm.quantity = vm.itemValue.packageQuantity;
                }else{
                    vm.quantity =1 ;
                }
            }
            $scope.copyChange();
            getByItemValueLineId();
            if (vm.cover == null && vm.pics.length>0) {
                $scope.getImg(vm.pics[0]);
            }
        }


        function compare(propertyName) {
            return function(object1, object2) {
                var value1 = object1[propertyName];
                var value2 = object2[propertyName];
                if (value2 < value1) {
                    return 1;
                } else if (value2 > value1) {
                    return -1;
                } else {
                    return 0;
                }
            }
        }

        $scope.createArticle = function () {
            if (vm.itemValue == null) {
                MessageService.error("请先选择属性");
                return;
            }

            if (vm.product.productType == 'CustomPrint' && vm.itemValue.productXmlLines && vm.itemValue.productXmlLines.length > 0) {
                var templateCount = 0;
                var template = null;
                for (var i = 0; i < vm.itemValue.productXmlLines.length; i++) {
                    var t = vm.itemValue.productXmlLines[i];
                    if (!t.cancelled) {
                        templateCount ++;
                        template = t;
                    }
                }
                if (templateCount == 1 && template) {
                    Editors.navigateTo(vm.storeId, {
                        editorType: vm.itemValue.productEditorType,
                        pid: vm.pid,
                        vid: vm.itemValue.id,
                        xmlId: template.id
                    });
                    return;
                }
            }

            $state.go("commoditySelect", {storeId: vm.storeId, pid: vm.pid, vid: vm.itemValue.id, from:vm.from});
        };


        $scope.valueSeleted = function () {
            if (vm.itemValueLine == null) {
                MessageService.error("请先选择属性");
                return;
            }
            if (!vm.copy) {
                MessageService.error("请选择份数");
                return;
            }
            if (vm.product.editorType == 'PICTURE' && !vm.quantity) {
                MessageService.error("冲印商品，请选择页数");
                return;
            }
            if (vm.limitQuantity === 0 || (vm.limitQuantity < vm.product.minimumOrder && vm.limitQuantity != null)) {
                MessageService.error("库存不足，无法购买更多");
                return;
            }
            $state.go("sales-order-reorder", {storeId: vm.storeId, productId:vm.product.id, itemValueId:vm.itemValueLine.itemValueId, itemValueLineId:vm.itemValueLine.id, articleId: null,
                copy:vm.copy, quantity:vm.quantity });

        };

        $scope.addCart = function () {
            if (vm.itemValueLine == null) {
                MessageService.error("请先选择属性");
                return;
            }
            if (!vm.copy) {
                MessageService.error("请选择份数");
                return;
            }
            if (vm.product.editorType == 'PICTURE' && !vm.quantity) {
                MessageService.error("冲印商品，请选择页数");
                return;
            }
            if (vm.product.editorType == 'PICTURE' && !vm.quantity) {
                MessageService.error("冲印商品，请选择页数");
                return;
            }
            if (vm.limitQuantity === 0 || (vm.limitQuantity < vm.product.minimumOrder && vm.limitQuantity != null)) {
                MessageService.error("库存不足，无法购买更多");
                return
            }
            var verify = false;
            Cart.countByItemValueLineId({itemValueLineId: vm.itemValueLine.id},function(res){
                var goodsNumber = res.message
                if ((vm.limitQuantity - goodsNumber <= 0 || vm.limitQuantity === 0) && vm.limitQuantity !=null) {
                    verify = true;
                    MessageService.error("库存不足，无法购买更多");
                    return
                }
                if ((vm.limitQuantity < goodsNumber + vm.copy) && vm.limitQuantity !=null) {
                    vm.copy = vm.limitQuantity - goodsNumber
                }
                if (!verify) {
                    Cart.addGoods({productId:vm.product.id, orderWay: vm.store.orderWay, itemValueLineId:vm.itemValueLine.id, quantity: vm.quantity,  copy: vm.copy,
                        itemValueId: vm.itemValue.id},function(res){
                        if(res.status == 400){
                            swal({
                                title: "失败",
                                text: res.message || "加入购物车失败",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            return
                        }
                        $scope.$emit('editorApp:cartUpdate', {});
                        $state.go("cart", {storeId: vm.storeId});
                    },function(data){
                        MessageService.error("系统错误，请稍后再试");
                    })
                }
            })

        };

        function getByItemValueLineId(){
            if (!vm.itemValueLine) {
                vm.limitQuantity = null;
                vm.warningQuantity = null;
                return
            }
            SkuSellLimits.getByItemValueLineId({productId:vm.product.id,itemValueLineId:vm.itemValueLine.id},function(res){
                if (res.message) {
                    vm.limitQuantity = res.message.limitQuantity
                    vm.warningQuantity = res.message.warningQuantity
                }else{
                    vm.limitQuantity = null
                    vm.warningQuantity = null
                }
            })
        }

        $scope.getCoupon = function(){
            $uibModal.open({
                templateUrl: 'app/entities/coupon/coupon-detail.html',
                controller: 'CouponDetailController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            storeId:vm.storeId,
                            pId:vm.product.id
                        };
                    },
                }
            }).result.then(function(data) {

            }, function() {

            });
        }
    }
})();
