(function () {
    'use strict';
    angular
        .module('editorApp')
        .factory("OrderArticle", OrderArticle)
        .factory('Article', Article)
        ;

    OrderArticle.$inject = ['$resource'];
    Article.$inject = ['$resource'];


    function OrderArticle($resource) {
        var resourceUrl = 'service/' + 'api/order-articles/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'byUser':{
                method: 'GET',
                params:{
                    path: "byUser"
                }
            },
            "updateName":{
                method:"GET",
                params:{
                    path:"updateName"
                }
            },
        })
    }


    function Article($resource) {
        var resourceUrl = 'service/' + 'api/articles/:path/:id';

        return $resource(resourceUrl, {}, {
            "updateMusic":{
                method:"GET",
                params:{
                    path:"updateMusic"
                }
            },
            "standardCreate":{
                method:"GET",
                params:{
                    path:"standardCreate"
                }
            },
            "standardCreateAndAddCart":{
                method:"GET",
                params:{
                    path:"standardCreateAndAddCart"
                }
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                },
                isArray: true
            },
            "updateName": {
                method: "GET",
                params: {
                    path: "updateName"
                },
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byUser':{
                method: 'GET',
                params:{
                    path: "byUser"
                }
            },
            'update': {method: 'PUT'},
            'delete': {
                method: 'DELETE'
            },
            'moveFolder': {
                method: "GET",
                params: {
                    path: "moveFolder"
                },
            },
            'batchDelete': {
                method: 'GET',
                params: {
                    path: "batchDelete"
                }
            },
            'shareCallBack':{
                method: 'GET',
                params: {
                    path: "shareCallBack"
                }
            }
        });
    }
})();
