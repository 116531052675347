(function () {
    'use strict';

    var editorPreviewWeb = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/page/editor-preview-web.html',
        // controller: 'articlePreviewController',
        controllerAs: 'vm',
        bindings: {
            previewArticle: '=',
            source: '='
        },
        controller: ['$scope', '$stateParams', 'Qinius', '$http', '$q', 'ImageService', 'PlatformWechatSetting',
            'Article', 'Music', 'ItemValue', 'Store', 'MessageService', '$state', '$compile', '$timeout', 'FontManagement',
            function ($scope, $stateParams, Qinius, $http, $q, ImageService, PlatformWechatSetting,
                      Article, Music, ItemValue, Store, MessageService, $state, $compile, $timeout, FontManagement) {
                var vm = this;
                $q.all([vm.previewArticle.$promise]).then(function() {
                    vm.pages=[];
                    vm.previewArticle.pages.page.forEach(function (page) {
                        if(!page.backup){
                            vm.pages.push(page)
                        }
                    })

                    vm.windowH = 400;
                    vm.windowW = 500;
                    vm.windowW1=140;
                    vm.windowH1=140;
                    angular.forEach(vm.pages, function (page, index) {
                        (function (page, index) {
                            if (page.editbox == null) {
                                page.editbox = page.trimbox;
                            }
                            page.editbox = {
                                width: transitionPx(page.editbox.width),
                                height: transitionPx(page.editbox.height),
                                x: transitionPx(page.editbox.x),
                                y: transitionPx(page.editbox.y)
                            };
                            page.mediabox = {
                                width: transitionPx(page.mediabox.width),
                                height: transitionPx(page.mediabox.height),
                                x: transitionPx(page.mediabox.x),
                                y: transitionPx(page.mediabox.y)
                            };
                            page.trimbox = {
                                width: transitionPx(page.trimbox.width),
                                height: transitionPx(page.trimbox.height),
                                x: transitionPx(page.trimbox.x),
                                y: transitionPx(page.trimbox.y)
                            };
                            page.custom = {};
                            if(vm.windowH / page.trimbox.height >= vm.windowW / page.trimbox.width ){
                                page.custom.ratio = vm.windowW / page.trimbox.width;
                                page.custom.ratio2 = vm.windowW1 / page.trimbox.width;
                            }else {
                                page.custom.ratio = vm.windowH / page.trimbox.height;
                                page.custom.ratio2 = vm.windowH1 / page.trimbox.height;
                            }
                            page.custom.ratio1 = vm.windowW1 / page.mediabox.width;
                            page.custom.width = page.trimbox.width;
                            page.custom.height = page.trimbox.height;
                            //条形码
                            if(page.barcode){
                                page.barcode.geometry = {
                                    width: transitionPx(page.barcode.geometry.width),
                                    height: transitionPx(page.barcode.geometry.height),
                                    x: transitionPx(page.barcode.geometry.x) - page.trimbox.x,
                                    y: transitionPx(page.barcode.geometry.y) - page.trimbox.y
                                };
                            }

                            //背景
                            if(page.background && page.background.resource && page.background.resource.identifier){
                                getBackgroundSource($http, page.background.resource,page.trimbox.width,page.trimbox.height,page.background, $scope, function () {
                                });
                                page.background.offsetx = transitionPx(page.background.offsetx) - page.trimbox.x;
                                page.background.offsety = transitionPx(page.background.offsety) - page.trimbox.y;
                            }
                            if(!page.levels.level){
                                page.levels.level = [];
                            }
                            angular.forEach(page.levels.level, function (level, i) {
                                if(level.imageart){
                                    level.imageart.lock = true;
                                    level.imageart.offsetx = transitionPx(level.imageart.offsetx);
                                    level.imageart.offsety = transitionPx(level.imageart.offsety);
                                    level.imageart.geometry = {
                                        width: transitionPx(level.imageart.geometry.width),
                                        height: transitionPx(level.imageart.geometry.height),
                                        x: transitionPx(level.imageart.geometry.x) - page.trimbox.x,
                                        y: transitionPx(level.imageart.geometry.y) - page.trimbox.y
                                    };
                                    level.imageart.rotation.angle = -level.imageart.rotation.angle;
                                    if(level.imageart.resource.identifier){
                                        getBackgroundSource($http, level.imageart.resource,level.imageart.geometry.width,level.imageart.geometry.height,level.imageart, $scope);
                                    }
                                }
                                if(level.imagebox){
                                    level.imagebox.lock = true;
                                    level.imagebox.geometry = {
                                        width: transitionPx(level.imagebox.geometry.width),
                                        height: transitionPx(level.imagebox.geometry.height),
                                        x: transitionPx(level.imagebox.geometry.x) - page.trimbox.x,
                                        y: transitionPx(level.imagebox.geometry.y) - page.trimbox.y
                                    };
                                    level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                                    level.imagebox.useradded = true;
                                    level.imagebox.bokehtypes = level.imagebox.bokehtype;
                                    if(level.imagebox.image){
                                        level.imagebox.image.offsetx = transitionPx(level.imagebox.image.offsetx);
                                        level.imagebox.image.offsety = transitionPx(level.imagebox.image.offsety);
                                        if(level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                            getBackgroundSource1(level.imagebox,level.imagebox.geometry.width,level.imagebox.geometry.height,level.imagebox.image, level.imagebox.bokehtypes, $scope)
                                        }
                                    }
                                    if(level.imagebox.blurredrectangle){
                                        level.imagebox.blurredrectangle = {
                                            width: transitionPx(level.imagebox.blurredrectangle.width),
                                            height: transitionPx(level.imagebox.blurredrectangle.height),
                                            x: transitionPx(level.imagebox.blurredrectangle.x),
                                            y: transitionPx(level.imagebox.blurredrectangle.y)
                                        };
                                    }
                                }
                                if(level.textbox){
                                    level.textbox.lock = true;
                                    level.textbox.geometry = {
                                        width: transitionPx(level.textbox.geometry.width),
                                        height: transitionPx(level.textbox.geometry.height),
                                        x: transitionPx(level.textbox.geometry.x) - page.trimbox.x,
                                        y: transitionPx(level.textbox.geometry.y) - page.trimbox.y
                                    };
                                    level.textbox.rotation.angle = -level.textbox.rotation.angle;
                                    if(level.textbox.fontuuid){
                                        //下载字体
                                        getFontCSS(FontManagement, level.textbox, $scope);
                                    }
                                    if (level.textbox.style.bold && level.textbox.style.italic) {
                                        level.textbox.styles = 'BOLD_ITALIC';
                                    } else if (level.textbox.style.bold) {
                                        level.textbox.styles = 'BOLD';
                                    } else if (level.textbox.style.italic) {
                                        level.textbox.styles = 'ITALIC';
                                    } else {
                                        level.textbox.styles = 'NORMAL';
                                    }
                                }
                                if (level.textdocument && level.textdocument.geometry) {
                                    var textdocument = angular.copy(level.textdocument);
                                    level.textdocument = {
                                        background: textdocument.background,
                                        geometry: {
                                            width: transitionPx(textdocument.geometry.width),
                                            height: transitionPx(textdocument.geometry.height),
                                            x: transitionPx(textdocument.geometry.x) - page.trimbox.x,
                                            y: transitionPx(textdocument.geometry.y) - page.trimbox.y
                                        },
                                        rotation: {
                                            angle:-textdocument.rotation.angle||0
                                        },
                                        current: 'textdocument',
                                        levels: angular.copy(textdocument.levels),
                                        lock: true,
                                        transparency: textdocument.transparency,
                                        movable: textdocument.movable,
                                        useradded: textdocument.useradded,
                                        editable: textdocument.editable,
                                        scale:textdocument.geometry.width/page.trimbox.width
                                    }

                                    var backg = textdocument.background;
                                    var pageW = textdocument.geometry.width,
                                        pageH = textdocument.geometry.height;
                                    if(backg.resource.identifier){
                                        getBackgroundSource($http, backg.resource,pageW,pageH,backg, $scope);
                                        backg.offsetx = transitionPx(backg.offsetx);
                                        backg.offsety = transitionPx(backg.offsety);
                                    }

                                    angular.forEach(textdocument.levels.level, function (lev, l) {
                                        if (lev.imageart && lev.imageart.geometry) {
                                            var art = lev.imageart;
                                            $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                                level.textdocument.levels.level[l].imageart = {
                                                    geometry:{
                                                        width: transitionPx(art.geometry.width),
                                                        height: transitionPx(art.geometry.height),
                                                        x: transitionPx(art.geometry.x),
                                                        y: transitionPx(art.geometry.y)
                                                    },
                                                    rotation: angular.copy(art.rotation),
                                                    type: art.type,
                                                    resource: {
                                                        identifier:art.resource.identifier,
                                                        provider:art.resource.provider,
                                                        width:data.width,
                                                        height:data.height
                                                    },
                                                    scale:art.scale,
                                                    offsetx: transitionPx(art.offsetx),
                                                    offsety: transitionPx(art.offsety),
                                                    current: 'imageart',
                                                    lock: false,
                                                    transparency: art.transparency,
                                                    onlyshow: art.onlyshow,
                                                    movable: art.movable,
                                                    useradded: art.useradded,
                                                    editable: art.editable,
                                                }
                                            }).error(function (error) {});
                                        }
                                        if (lev.textbox && lev.textbox.geometry) {
                                            var text = lev.textbox;
                                            level.textdocument.levels.level[l].textbox = {
                                                geometry: {
                                                    width: transitionPx(text.geometry.width),
                                                    height: transitionPx(text.geometry.height),
                                                    x: transitionPx(text.geometry.x),
                                                    y: transitionPx(text.geometry.y)
                                                },
                                                identifier: text.identifier,
                                                rotation: angular.copy(text.rotation),
                                                angle: angular.copy(text.angle),
                                                status: angular.copy(text.status),
                                                style: angular.copy(text.style),
                                                type: angular.copy(text.type),
                                                text: angular.copy(text.text),
                                                fontStyle: [{
                                                    style: '',
                                                    path: text.identifier
                                                }],
                                                current: 'textbox',
                                                lock: false,
                                                transparency: text.transparency,
                                                fontuuid:text.fontuuid,
                                                version:text.version
                                            }
                                            getFontCSS(FontManagement, text, $scope);
                                        }
                                    })
                                }
                            })
                        })(page, index)
                    })
                    $timeout(function () {
                        console.log(vm.pages)
                        loadApp()
                        $scope.$digest();
                    },100)
                })
                function loadApp() {
                    $('.flipbook').turn({
                        width: vm.pages[0].custom.width * vm.pages[0].custom.ratio*2,
                        height: vm.pages[0].custom.height * vm.pages[0].custom.ratio,
                        accelerator: true,
                        when:{
                            turned: function(event, page, pageObject){
                                // if(vm.lastPage == pageObject[1]){
                                //     MessageService.error("这是最后一页");
                                //     return
                                // }
                                // if(!vm.firstPage || vm.firstPage == pageObject[0]){
                                //     MessageService.error("这是第一页");
                                //     return
                                // }
                                $timeout(function () {
                                    vm.firstPage = pageObject[0];
                                    vm.lastPage = pageObject[1];
                                })
                            }
                        }
                    });
                }

                $scope.jumpPage = function(index){
                    $('.flipbook').turn("page", index+1);
                    vm.isCatalogue = false;
                }
                $scope.firstPageCb = function(){
                    if(vm.firstPage == 0){
                        MessageService.error("这是第一页");
                        return
                    }
                    $('.flipbook').turn("page", 1);
                }
                $scope.previousCb = function(){
                    if(vm.firstPage == 0){
                        MessageService.error("这是第一页");
                        return
                    }
                    $('.flipbook').turn("previous");
                }
                $scope.nextCb = function(){
                    if(vm.lastPage == vm.pages.length || !vm.lastPage){
                        MessageService.error("这是最后一页");
                        return
                    }
                    $('.flipbook').turn("next");
                }
                $scope.lastPageCb = function(){
                    if(vm.lastPage == vm.pages.length || !vm.lastPage){
                        MessageService.error("这是最后一页");
                        return
                    }
                    $('.flipbook').turn("page", vm.pages.length);
                }
                $scope.toggleFullScreen = function () {
                    if (!vm.fullFlag) {
                        //进入全屏
                        var de = document.documentElement;
                        if (de.requestFullscreen) {
                            de.requestFullscreen();
                        } else if (de.mozRequestFullScreen) {
                            de.mozRequestFullScreen();
                        } else if (de.webkitRequestFullScreen) {
                            de.webkitRequestFullScreen();
                        }
                        vm.fullFlag = true;
                    } else {
                        //退出全屏
                        var de = document;
                        if (de.exitFullscreen) {
                            de.exitFullscreen();
                        } else if (de.mozCancelFullScreen) {
                            de.mozCancelFullScreen();
                        } else if (de.webkitCancelFullScreen) {
                            de.webkitCancelFullScreen();
                        }
                        vm.fullFlag = false;
                    }
                }

                //边框
                $scope.imageboxW = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.width
                            // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                        }
                    } else {
                        return imagebox.geometry.width
                    }
                }
                $scope.imageboxH = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.height
                            // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                        }
                    } else {
                        return imagebox.geometry.height
                    }
                }
                $scope.imageboxL = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.x
                            // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                        }
                    } else {
                        return imagebox.geometry.x
                    }
                }
                $scope.imageboxT = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.y
                            // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                        }
                    } else {
                        return imagebox.geometry.y
                    }
                }
                $scope.imageboxWEdge = function (imagebox, edge) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            if (edge == 'left') {
                                return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                            }
                            if (edge == 'top') {
                                return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                            }
                            if (edge == 'right') {
                                return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                            }
                            if (edge == 'bottom') {
                                return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                            }
                        }
                    } else {
                        return 0
                    }
                }
                $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        var _ratio = 1;
                        if(ratio && ratio1){
                            if(imagebox.geometry.width > imagebox.geometry.height){
                                _ratio = ratio1
                            }else{
                                _ratio = ratio
                            }
                        }
                        if(ratio && !ratio1){
                            _ratio = ratio
                        }
                        if (imagebox.border.shape == 'Ellipse') {
                            return '50%'
                        }
                        if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                            var _radius = imagebox.border.radius[radius];
                            return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                        }else{
                            var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                            radiusW = radiusW + imagebox.border.lineWidth;
                            var _r = radiusW * imagebox.border.radius[radius] / 100;
                            return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                        }
                    } else {
                        return 0
                    }
                }
                $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        var _ratio = 1;
                        if(ratio && ratio1){
                            if(imagebox.geometry.width > imagebox.geometry.height){
                                _ratio = ratio1
                            }else{
                                _ratio = ratio
                            }
                        }
                        if(ratio && !ratio1){
                            _ratio = ratio
                        }
                        if (imagebox.border.shape == 'Ellipse') {
                            return '50%'
                        }
                        if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                            var _radius = imagebox.border.radius[radius];
                            return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                        }else{
                            var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                            radiusW = radiusW + imagebox.border.lineWidth;
                            var _r = radiusW * imagebox.border.radius[radius] / 100;
                            return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                        }
                    } else {
                        return 0
                    }
                }
                $scope.dropShadow = function(imagebox,ratio,ratio1){
                    if (imagebox.shadow == null) {
                        return
                    }
                    var width = $scope.imageboxW(imagebox) * ratio;
                    var height =$scope.imageboxH(imagebox) * ratio1;
                    return dropShadow(imagebox,width,height)
                }
            }
        ]
    };

    angular
        .module('editorApp')
        .component('editorPreviewWeb', editorPreviewWeb);
})();
