(function () {
    'use strict';
    angular
        .module('editorApp')
        .factory('UserMessage', UserMessage);

        UserMessage.$inject = ['$resource'];

    function UserMessage($resource) {
        var resourceUrl = 'service/' + 'api/user-messages/:path/:id';
        return $resource(resourceUrl, {}, {
            "unreadCount": {
                method: "GET",
                params: {
                    path: "unreadCount"
                }
            },
            "getList": {
                method: "GET",
                isArray: true
            },
            "getOne": {
                method: "GET"
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
        })
    }
})();
