(function () {
    'use strict';

    var canvasImageFilter = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/canvas-image-filter.html',
        controllerAs: 'vm',
        bindings: {
            productTitle:'<',
            productXmlLine:'<',
            logo:'<',
            elementOn:'=',
            domain:'<'
        },

        controller: ['$scope', '$timeout', 'MessageService', '$translate','$window','$rootScope',
            function ($scope, $timeout, MessageService, $translate, $window, $rootScope) {
                var vm = this;
                vm.Theme = localStorage.getItem('Theme');
                vm.isPng = false;
                vm.loading = true;
                vm.imgUrl = '';
                vm.imgWidth = 0;
                vm.imgHeight = 0;
                vm.scale = 0;
                vm.contrast = false;
                vm.loading = false;
                init();
                vm.$onInit = function() {
                    var imageInfo2 = orientationInit(angular.copy(vm.elementOn.item.image.imageInfo))
                    vm.imgWidth = imageInfo2.width;
                    vm.imgHeight = imageInfo2.height;
                    // 判断png
                    $.ajax({
                        type: "GET",
                        url: vm.elementOn.item.image.resource.identifier + "?imageInfo",
                        async: false,
                        success: function (va) {
                            if(va.format == "png"){
                                vm.isPng = true;
                            }
                            vm.changeImageFilter();
                        }
                    })
                }
                angular.element($window).bind('resize', function() {
                    $scope.$apply(function() {
                        init();
                    });
                });
                $scope.mouseDown = function() {
                    // 鼠标按下时执行的操作
                    vm.contrast = true;
                };
                
                $scope.mouseUp = function() {
                    // 鼠标抬起时执行的操作
                    vm.contrast = false;
                };
                angular.element(document).on('mouseup', function() {
                    // vm.contrast = false;
                    $scope.$apply(function() {
                        vm.contrast = false;
                    });
                });

                // 保存事件处理函数的变量
                var mousewheelHandler = function(event){
                    if (event.ctrlKey === true || event.metaKey) {
                        var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : ( - event.detail / 3);
                        if(wheelDelta>0){
                            vm.scale += vm.scale * 0.1;
                        }else{
                            vm.scale -= vm.scale * 0.1;
                        }
                        $scope.$apply();
                        event.preventDefault();
                        event.stopPropagation();
                    }
                };

                // 添加事件监听器
                window.addEventListener('mousewheel', mousewheelHandler, { passive: false });
                $scope.ratioJian = function(){
                    vm.scale -= vm.scale * 0.1;
                }
                $scope.ratioJia = function(){
                    vm.scale += vm.scale * 0.1;
                }
                $scope.ratioInit = function(){
                    init();
                }
                $scope.ratioOriginal = function(){
                    vm.scale = 1;
                }
                function init(){
                    var imageInfo2 = orientationInit(angular.copy(vm.elementOn.item.image.imageInfo))
                    var imgWidth = imageInfo2.width;
                    var imgHeight = imageInfo2.height;
                
                    // 获取canvas的父元素div的宽度和高度
                    var canvasParent = document.getElementById('canvas');
                    var parentWidth = canvasParent.offsetWidth - 150;
                    var parentHeight = canvasParent.offsetHeight - 150;
                
                    // 计算缩放比例
                    var scaleWidth = parentWidth / imgWidth;
                    var scaleHeight = parentHeight / imgHeight;
                    var scale = Math.min(scaleWidth, scaleHeight);
                    vm.scale = scale;
                }
                // 防抖，防止500毫秒内重复触发事件
                var timer = null;
                $scope.changeOperation = function () {
                    vm.loading = true;
                    if (timer !== null) {
                        clearTimeout(timer)
                    }
                    timer = setTimeout(function() {
                        vm.changeImageFilter()
                    }, 1000);
                }

                vm.changeImageFilter =  function (){
                    vm.loading = true;
                    vm.imgUrl = '';
                    var imgUrl = vm.domain + vm.elementOn.item.image.resource.primary;
                    var brightness = vm.elementOn.item.image.brightness == null ? 0 : vm.elementOn.item.image.brightness;
                    var contrast = vm.elementOn.item.image.contrast == null ? 0 : vm.elementOn.item.image.contrast;
                    var saturate = vm.elementOn.item.image.saturate == null ? 0 : vm.elementOn.item.image.saturate;
                    var hue = vm.elementOn.item.image.hue == null ? 0 : vm.elementOn.item.image.hue;
                    var temperature = vm.elementOn.item.image.temperature == null ? 0 : vm.elementOn.item.image.temperature;
                    canvasImageFilter(imgUrl,vm.imgWidth,vm.imgHeight,brightness,contrast,saturate,hue,temperature,vm.isPng)
                }

                function canvasImageFilter(imgUrl,imgWidth,imgHeight,brightness,contrast,saturate,hue,temperature,isPng){
                    var cav = $('#imageFilter')[0],
                    ctx = cav.getContext('2d',{ willReadFrequently: true });
                    cav.width = imgWidth;
                    cav.height = imgHeight;
                    var img = new Image();
                    var base64 = '';
                    img.crossOrigin = '*';
                    img.onload = function () {
                        ctx.filter = "brightness("+(Number(brightness)+100)+"%) contrast("+(Number(contrast)+100)+"%) saturate("+(Number(saturate)+100)+"%) hue-rotate("+ hue +"deg)";
                            ctx.drawImage(img, 0, 0);
                            var imageData = ctx.getImageData(0, 0, imgWidth, imgHeight);
                            var data = imageData.data;
                            for (var i = 0; i < data.length; i += 4) {
                                data[i] = data[i] + data[i]*temperature*0.005;     // red
                                data[i + 1] = data[i + 1]; // green
                                data[i + 2] = data[i + 2] - data[i + 2] * temperature * 0.005; // blue
                            }
                            ctx.putImageData(imageData, 0, 0);
                        try {
                            if (isPng) {
                                base64 = cav.toDataURL("image/png", 0.9);
                            }else{
                                base64 = cav.toDataURL("image/jpeg", 0.9);
                            }
                        } catch (err) {
                        }
                        $scope.$apply(function() {
                            vm.loading = false;
                            vm.imgUrl = base64;
                        });

                    }
                    img.src = imgUrl;
                }

                // 清空画布内容删除画布，移除监听事件
                function clearCanvasComponent(){
                    var cav = $('#imageFilter')[0];
                    var ctx = cav.getContext('2d');
                    ctx.clearRect(0, 0, cav.width, cav.height);
                    cav.remove()
                    angular.element($window).unbind('resize');
                    window.removeEventListener('mousewheel', mousewheelHandler);
                }

                $scope.close = function(){
                    clearCanvasComponent();
                    $rootScope.$emit('editorApp.closeImageFilter');
                }
                $scope.save = function(){
                    if (!vm.imgUrl || vm.loading) {
                        return MessageService.error('图片处理中请稍后');
                    }
                    clearCanvasComponent();
                    $rootScope.$emit('editorApp.saveImageFilter',vm.imgUrl);
                }
            }]
    };

    angular
        .module('editorApp')
        .component('canvasImageFilter', canvasImageFilter);
})();
