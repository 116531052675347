(function () {
    'use strict';

    var titleCellControl = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/controls/title-cell.control.html',
        controllerAs: 'vm',
        bindings: {
            widget: "=",
            width: "<",
            height: "<",
            year: "<",
            month: "<",
            scale: "<"
        },

        controller: ['$scope', '$timeout','FontManagement','CalendarService', function ($scope, $timeout,FontManagement,CalendarService) {
            var vm = this;
            vm.data = {
                width: 200,
                height: 80,
                items: []
            };

            vm.$onInit = function() {
            }
            // 每當 one-way binding 更新時，changesObj 就是改變前後的差異
            vm.$onChanges = function(data) {
                if(data.year&&data.year.currentValue&&data.year.previousValue>0){vm.year = data.year.currentValue; vm.widget.obj = null;}
                if(data.month&&data.month.currentValue&&data.month.previousValue>0){vm.month = data.month.currentValue; vm.widget.obj = null;}
                if (data.widget && data.widget.currentValue) {
                    vm.widget = data.widget.currentValue;
                }
                if(vm.widget){
                    if(!vm.widget.ctrl){
                        vm.widget.ctrl = {}
                    }
                    if(vm.widget.geometry){
                        vm.widget.width = vm.widget.geometry.width;
                        vm.widget.height = vm.widget.geometry.height;
                        if(vm.widget.layout){
                            vm.widget.layout.width = vm.widget.layout.geometry.width;
                            vm.widget.layout.height = vm.widget.layout.geometry.height;
                            if(vm.widget.layout.items&&vm.widget.layout.items.item){
                                vm.widget.layout.items = vm.widget.layout.items.item;
                                vm.widget.layout.items.forEach(function (item) {
                                    item.w = item.geometry.width;
                                    item.h = item.geometry.height;
                                    item.x = item.geometry.x;
                                    item.y = item.geometry.y;
                                })
                            }
                        }
                        if(vm.widget.settings){
                            if(vm.widget.settings.items&&vm.widget.settings.items.item){
                                vm.widget.settings.items = vm.widget.settings.items.item;
                                vm.widget.settings.items.forEach(function (item) {
                                    if(item.geometry){
                                        item.w = item.geometry.width;
                                        item.h = item.geometry.height;
                                        item.x = item.geometry.x;
                                        item.y = item.geometry.y;
                                    }
                                })
                            }
                        }
                    }
                    queryYearMonthDataset(function () {
                        vm.widget.ctrl.settings = vm.widget.settings;
                        vm.widget.ctrl.obj = vm.widget.obj || [];
                        constructCell(vm.widget.layout, vm.widget.ctrl.settings, vm.widget.ctrl.obj);
                    })
                }
            }
            vm.$postLink = function () {

            }
            vm.$doCheck = function() {
                // deep watch
            }
            vm.$onDestroy = function () {

            }
            function queryYearMonthDataset(callback) {
                if(vm.widget.obj){
                    if(callback){callback()}
                    return
                }
                vm.widget.obj = null;
                var req = {
                    year: vm.widget.sampleYear || 2023,
                    month: vm.widget.sampleMonth || 1
                };
                var formats = [];
                var layout = vm.widget.layout || {};
                if (layout.items && layout.items.length) {
                    for (var i = 0; i < layout.items.length; i++) {
                        var item = layout.items[i];
                        if (item.type == 'year' || item.type == 'month') {
                            formats.push({type: item.type, format: item.format});
                        }
                    }
                }
                req.formats = formats;
                CalendarService.queryYearMonthSet(req, function (res) {
                    vm.widget.obj = res.data || null;
                    if(callback){callback()}
                });
            }

            function textStyleObj(obj) {
                var styles = {
                    'position': 'absolute',
                    'left': obj.x + 'px',
                    'top': obj.y + 'px',
                    'width': obj.textSize > 12 ? obj.w : obj.w / (1 / 12 * obj.textSize) + 'px',
                    'height': obj.textSize > 12 ? obj.h : obj.h / (1 / 12 * obj.textSize) + 'px',
                    'line-height': (obj.textSize > 12 ? obj.h : obj.h / (1 / 12 * obj.textSize)) + 'px',
                    'fontSize': (obj.textSize > 12 ? obj.textSize : 12) + 'px',
                    'font-family': obj.textFontUuid,
                    'color': obj.textColor,
                    'text-align': obj.textAlign || 'center',
                    'transform':'scale('+(1 / 12 * obj.textSize > 1 ? 1 : 1 / 12 * obj.textSize)+')',
                    'transform-origin': '0 0',
                };
                if (obj.textWeight) {
                    styles['font-weight'] = obj.textWeight;
                }
                if (obj.textStyle) {
                    styles['font-style'] = obj.textStyle;
                }
                if (obj.backgroundColor) {
                    styles['background-color'] = obj.backgroundColor;
                }
                if (obj.debug) {
                    styles['border'] = '1px dotted #ccc';
                }
                if (obj.vertical) {
                    styles['writing-mode'] = "vertical-lr";
                    styles['line-height'] = (obj.textSize > 12 ? obj.w : obj.w / (1 / 12 * obj.textSize)) + 'px';
                }
                return styles;
            }
            function imageStyleObj(obj) {
                var styles = {
                    'position': 'absolute',
                    'left': obj.x + 'px',
                    'top': obj.y + 'px',
                    'width': obj.w + 'px',
                    'height': obj.h + 'px'
                };
                if (obj.debug) {
                    styles['border'] = '1px dotted #ccc';
                }

                return styles;
            }

            function findItemFromSettings(settings, itemName) {
                if (!settings || !settings.items || !settings.items.length || !itemName) {
                    return {};
                }
                for (var i = 0; i < settings.items.length; i++) {
                    var item = settings.items[i];
                    if (item.name == itemName) {
                        return item;
                    }
                }

                return {};
            }

            function findDataFromObj(obj, type, format) {
                if (!obj || !obj.items || !obj.items.length) {
                    return {};
                }
                for (var i = 0; i < obj.items.length; i++) {
                    var item = obj.items[i];
                    if (item.type == type && item.format == format) {
                        return item;
                    }
                }
                return {};
            }

            function constructCell(layout, settings, myObj) {
                settings.width = layout.width;
                settings.height = layout.height;
                var width = vm.width || settings.width || 200;
                var height = vm.height || settings.height || 80;
                vm.data = {
                    width: width,
                    height: height,
                    styles: {
                        width: width + 'px',
                        height: height + 'px',
                        'transform' : 'scale('+(vm.scale || 1) + ')',
                        'transform-origin' : '0px 0px',
                        'overflow': 'hidden'
                    },
                    items: []
                };
                if (settings.backgroundColor) {
                    vm.data.styles['background-color'] = settings.backgroundColor;
                }
                if (settings.border && settings.border.lineType && settings.border.lineWidth) {
                    var border = settings.border;
                    vm.data.styles['border'] = border.lineWidth + 'px ' + border.lineType + " " + border.color;
                    if (border.tlRadius) {
                        vm.data.styles['border-top-left-radius'] = border.tlRadius + 'px';
                    }
                    if (border.trRadius) {
                        vm.data.styles['border-top-right-radius'] = border.trRadius + 'px';
                    }
                    if (border.blRadius) {
                        vm.data.styles['border-bottom-left-radius'] = border.blRadius + 'px';
                    }
                    if (border.brRadius) {
                        vm.data.styles['border-bottom-right-radius'] = border.brRadius + 'px';
                    }
                }
                if (settings && settings.items && settings.items.length) {
                    var cw = settings.width || 200;
                    var ch = settings.height || 80;
                    for (var i = 0; i < settings.items.length; i++) {
                        var ci = settings.items[i];
                        if(!ci.format){
                            ci.format = layout.items[i].format;
                        }
                        if(!ci.w || !ci.h){
                            ci.w = layout.items[i].w;
                            ci.h = layout.items[i].h;
                            ci.x = layout.items[i].x;
                            ci.y = layout.items[i].y;
                        }
                        if (ci.type == "image") {
                            var element = {
                                x: ci.x * width / cw,
                                y: ci.y * height / ch,
                                w: ci.w * width / cw,
                                h: ci.h * height / ch
                            };
                            if (settings.debug) {
                                element.debug = true;
                            }
                            var img = {
                                type: ci.type,
                                url: ci.url,
                                styles: imageStyleObj(element)
                            };
                            vm.data.items.push(img);
                        }
                        else if (ci.type) {
                            var element = {
                                x: ci.x * width / cw,
                                y: ci.y * height / ch,
                                w: ci.w * width / cw,
                                h: ci.h * height / ch,
                                textSize: ci.textSize * Math.min(width / cw, height / ch),
                                textFont: ci.textFont,
                                textColor: ci.textColor,
                                textAlign: ci.textAlign,
                                textWeight: ci.textWeight,
                                textFontUuid: ci.textFontUuid,
                                vertical:ci.vertical
                            };
                            var item = findItemFromSettings(settings, ci.name) || {};
                            if (item.textFontUuid) {
                                element.textFontUuid = item.textFontUuid;
                                getFontCSS(FontManagement, {
                                    fontuuid: element.textFontUuid
                                }, $scope)
                            }
                            if (item.textSize) {
                                element.textSize = item.textSize * Math.min(width / cw, height / ch);
                            }
                            if (item.textFont) {
                                element.textFont = item.textFont;
                            }
                            if (item.textColor) {
                                element.textColor = item.textColor;
                            }
                            if (item.textAlign) {
                                element.textAlign = item.textAlign;
                            }
                            if (item.textBold) {
                                element.textWeight = "600";
                            }
                            if (item.textItalic) {
                                element.textStyle = "italic";
                            }
                            if (item.backgroundColor) {
                                element.backgroundColor = item.backgroundColor;
                            }

                            if (settings.debug) {
                                element.debug = true;
                            }

                            var data = findDataFromObj(myObj, ci.type, ci.format) || {};

                            var text = {
                                type: ci.type,
                                format: ci.format,
                                value: data.value || ci.value || ci.sample,
                                styles: textStyleObj(element)
                            };
                            vm.data.items.push(text);
                        }

                    }
                } else {
                    var t = {
                        type: "year",
                        format: "$yyyy",
                        value: "2023",
                        textSize: 18 * width / 100,
                        textFont: '',
                        textFontUuid: '',
                        textColor: '#000000',
                        x: 5 * width / 200,
                        y: 10 * height / 80,
                        w: 90 * width / 200,
                        h: 30  * height / 80,
                        fixedTo: '',
                        vertical:false
                    };
                    vm.data.items.push({
                        type: t.type,
                        format: t.format,
                        value: t.value || t.sample,
                        styles: textStyleObj(t)
                    });
                }
            } // end of constructCell

        }]
    };
    angular.module('editorApp')
        .component('titleCell', titleCellControl);

})();
