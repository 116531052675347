(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('PageMySuffixController', PageMySuffixController)
        .filter('reverse', function() {
            return function(items) {
                if(items){
                    return items.slice().reverse();
                }
            };
        });

    PageMySuffixController.$inject = ['$state', '$scope', 'Folder', 'Qinius', 'Article', 'FontManagement', '$q', '$http', 'MessageService', '$stateParams',
        'ImageService', 'ClipArt', 'UidService', 'ItemValue', 'Convert', 'Gallery', '$uibModal', 'Store', 'PlatformWechatSetting', 'Product', '$localStorage',
        'MessageNotification', 'ThemeClassify', 'Backgrounds', 'Material', 'FontXmlsFileServes', '$timeout', 'FontXmlsManger', 'ListService','SalesOrder',
        'OrderArticle', 'CustomerUploadAble','Customer', 'StoreConfig', 'CustomerStorages', 'FrameConfigs'];

    function PageMySuffixController($state, $scope, Folder, Qinius, Article, FontManagement, $q, $http, MessageService, $stateParams,
                                    ImageService, ClipArt, UidService, ItemValue, Convert, Gallery, $uibModal, Store, PlatformWechatSetting, Product, $localStorage,
                                    MessageNotification, ThemeClassify, Backgrounds, Material, FontXmlsFileServes, $timeout, FontXmlsManger, ListService,SalesOrder,
                                    OrderArticle, CustomerUploadAble,Customer, StoreConfig, CustomerStorages, FrameConfigs) {
        var vm = this;


        $(".navbar").hide();
        $(".save-page").removeClass('hide1');
        // $(".nav-bg").show();
        $(".wrap").hide();
        vm.indexNum = 0;
        vm.itemsPerPage = 40;
        vm.more = true;
        vm.folderShow = false;
        var flag = true;
        vm.source = $stateParams.source;
        vm.sourceEdit="edit";
        if($stateParams.authorization){
            if(vm.source){
                $localStorage.authenticationTokenSource = $stateParams.authorization;
            }else{
                $localStorage.authenticationToken = $stateParams.authorization;
            }
        };
        vm.orderArticleId = $stateParams.orderArticleId;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.soid = $stateParams.soid;
        vm.plid = $stateParams.plid;
        if ($localStorage.user != null) {
            vm.userId = $localStorage.user.id
        }
        vm.photosTab = 'all';
        vm.exitSure = false;
        vm.maskType='recommend';

        var completed = false;
        var arr = localStorage.getItem('save-article');
        if (arr && vm.pid) {
            $state.go("page-my-suffix-remake", {storeId: vm.storeId, aid: arr, soid: vm.soid, plid: vm.plid});
        }
        localStorage.removeItem('save-article');

        $scope.folderOn = function () {
            vm.folderShow = !vm.folderShow;
        };

        vm.isMoveToFolder = false;

        //键盘操作array
        vm.CtrlKeyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: []};
        vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, textdocument: {}};
        // 复制array
        vm.copyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: []};
        vm.copyBox = null;
        vm.customer = Customer.current();

        vm.photoSort = '1';

        $scope.changeSortType = function(item){
            $scope.photos = [];
            vm.indexNum = 0;
            if ($scope.folderOne) {
                loadAll($scope.folderOne.id);
            } else {
                loadAll('isNull');
            }
        }
        vm.windowDpiH = vm.windowDpiW = js_getDPI();
        $scope.isMoveOut = false;

        vm.colors = [
            '#FFFFFF', '#DFDFDF', '#B7B7B7', '#919191', '#787878', '#5A5A5A', '#464646', '#000000',
            '#FFD9D9', '#FFADAD', '#E95050', '#FFCD48', '#BF9249', '#E57C41', '#B45C12', '#CA7F7F',
            '#7E76CE', '#423D99', '#5567CE', '#3F7AA6', '#A5BEFF', '#5092DD', '#559296', '#739C8B',
            '#CA8FC8', '#AA9398', '#7F6D78', '#CAB99C', '#7C8A77', '#A5CF6C', '#9F9219', '#626923'
        ];

        vm.colorArr1 = ['#ffffff', '#666666', '#7babaf', '#e69594', '#93a9da', '#eac8fb'];
        vm.colorArr2 = ['#000000', '#666666', '#a8a8a8', '#d9d9d9', '#ffffff', '#647c6e', '#ff5b5c', '#ffbd4a', '#fef952', '#99e264', '#36b72a', '#44d9e5', '#c27171', '#2fb3ff', '#b760a6'];

        vm.colorList = [
            {name: '#000000', id: '#000000'},
            {name: '#666666', id: '#666666'},
            {name: '#a8a8a8', id: '#a8a8a8'},
            {name: '#d9d9d9', id: '#d9d9d9'},
            {name: '#ffffff', id: '#ffffff'},
            {name: '#647c6e', id: '#647c6e'},
            {name: '#ffbd4a', id: '#ffbd4a'},
            {name: '#fef952', id: '#fef952'},
            {name: '#99e264', id: '#99e264'},
            {name: '#36b72a', id: '#36b72a'},
            {name: '#44d9e5', id: '#44d9e5'},
            {name: '#c27171', id: '#c27171'},
            {name: '#2fb3ff', id: '#2fb3ff'},
            {name: '#b760a6', id: '#b760a6'},
        ]

        //键盘操作array
        $scope.dHeight = vm.dHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        $scope.dWidth = vm.dWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var heightDiffer = parseInt(($scope.dHeight - 100) / 2);
        vm.galleryPage = [];
        $scope.usePhotos = [];
        $scope.gallaryPic = [];
        $scope.template = {pages: {page: []}};
        vm.pageProp = 0.8

        $scope.template.letterspacing = 0,
            $scope.template.lineheight = 0,
            $scope.template.text = '',
            $scope.pageAllMm = {page: []};
        $scope.document = {
            pages: [{}]
        };
        // 不含备用页的总页数
        $scope.PAGELEN = 0;
        $scope.stickerList = [];
        $scope.maskList = [];
        $scope.selPanel = 'photo';

        vm.fontNames = [];
        $scope.fontList = [];
        function fontSizeInit(size){
            var _size = 101;
            var _size1 = Math.floor(size/2);
            $scope.fontSizes = [];
            for (var f = 6; f < _size; f++) {
                $scope.fontSizes.push(f);
            }
            if(_size1 > _size){
                $scope.fontSizes.push(_size1);
            }
            $scope.size = $scope.fontSizes[10];
        }

        $scope.mHeight = 100;

        vm.indexBgNum = 0;

        var tempMasks = [];
        vm.windowH = window.innerHeight - 100;

        var unitTransform = function (mm) {
            // mm换算成px  Math.ceil(mm / 25.4 * 300);
            // return (mm * 3) - 0;
            return mm / 25.4 * vm.windowDpiW;
        };
        var unitTransformPx = function (px) {
            // px换算成mm  Math.floor(px / 300 * 25.4)
            // return (px * 1 / 3).toFixed(2) - 0;
            return px / vm.windowDpiW * 25.4;
        };

        $scope.domains = Qinius.getDomain1();
        vm.tokenTime = 360000;
        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });

        vm.store = Store.get({id: vm.storeId});
        vm.fontuuids=[];
        //得到原始模板框中最大宽高比例
        var $con = $(".content-scroll");
        $scope.labels = ['推荐'];

        $scope.bglabels = ['推荐'];
        $scope.masklabels = ['推荐'];
        $scope.fontlabels=['推荐'];
        vm.indexMaskNum = 0;
        vm.totalMaskItems = 0;
        vm.textIndexNum = 0;

        $scope.moveGalleryIds = [];
        vm.platformSetting = PlatformWechatSetting.get();

        $scope.tempList = [];
        $scope.tempCategories = [];

        // 显示区域（包含上/下页按钮）
        $scope.pageW = $(".web-con").width();
        $scope.pageH = $(".web-con").height();

        function myBrowser() {
            var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
            var isOpera = userAgent.indexOf("Opera") > -1;
            if (isOpera) {
                return "Opera"
            }
            ; //判断是否Opera浏览器
            if (userAgent.indexOf("Firefox") > -1) {
                return "FF";
            } //判断是否Firefox浏览器
            if (userAgent.indexOf("Chrome") > -1) {
                return "Chrome";
            }
            if (userAgent.indexOf("Safari") > -1) {
                return "Safari";
            } //判断是否Safari浏览器
            if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
                return "IE";
            }
            ; //判断是否IE浏览器
        }
        var mb = myBrowser();


        /***************页面处理************start*****************/
        $q.all([$scope.domains.$promise, vm.platformSetting.$promise, vm.store.$promise, vm.customer.$promise]).then(function () {
            $(".navbar").hide();
            $(".save-page").removeClass('hide1');
            // $(".nav-bg").show();
            $(".wrap").hide();
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            setTimeout(function fn() {
                Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            vm.logo = vm.store.menuLogo ? $scope.domain + vm.store.menuLogo : 'content/images/momentsgo.png';
            var monthStr = new Date().getMonth() + 1;
            var dayStr = new Date().getDate();
            var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
            //编辑
            MessageNotification.getAllByCondition({
                items: [
                    {key: "available", op: "=", value: "true"},
                    {key: "validate", op: ">=", value: timestr},
                    {key:"storeId", op : "=", value:vm.storeId},
                    {key: "pageType", op: "=", value: "Edit"}]
            }, function (res) {
                res.sort(compare('seq'))
                vm.message = angular.copy(res);
                vm.messageEdit = angular.copy(res);
                vm.messageEditText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                angular.forEach(res, function (resp, i) {
                    vm.messageEditText = vm.messageEditText + (i + 1) + "." + resp.content
                    if (i != res.length - 1) {
                        vm.messageEditText += "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                    }
                })
            });
            vm.defaultFont = FontManagement.getDefaultShow();

            //获取字体
            FontManagement.getAll2({family: ""}, function (res) {
                vm.fontList = angular.copy(res);
                vm.fontList1 = angular.copy(res);
                $scope.item = vm.fontList[0];
            });

            loadAll('isNull');
            loadFolders();
            goToMake();
            getByCustomer();

            vm.generalMask=[{
                cancelled: false,
                id: 646,
                identifier: $scope.domain + "Fn-LPd5yTT4zPO-JspyDjsXQKxKw",
                name: "圆形蒙版",
                opened: true,
                provider: "qiniu",
                themeClassifyId: '',
                themeClassifyName: "",
                type: "round",
            },{
                cancelled: false,
                id: 646,
                identifier: $scope.domain + "Fi-MC4JZuNy300zbGEUu2VpRDj0i",
                name: "方形蒙版",
                opened: true,
                provider: "qiniu",
                themeClassifyId: '',
                themeClassifyName: "",
                type: "square",
            }]
        }).catch(function (error) {
            if(error.data.status === 401){//未登录
                $scope.login("Login");
            }
        });
        var createLoginModal = function (url, controller, type) {
            if (type == null) {
                type = "Login";
            }
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type,
                        showTips:true,
                    },
                }
            });
        };

        $scope.login = function (type) {
            createLoginModal('app/account/register/register.html', 'RegisterController', type).result.then(
                function () {
                    $state.reload();
                });
        };
        function getByCustomer() {
            CustomerStorages.getByCustomer({
                customerId: vm.customer.id
            }, function (res) {
                vm.isProgress = true;
                progressInit(res)
            })
        }
        function progressInit(item){
            vm.sumSize =  Number(item.initSize) + Number(item.buySize);
            vm.useSize = Number(item.usedSize);
            vm.percentage = 0;
            $timeout(function () {
                vm.percentage = vm.useSize / vm.sumSize;
            });
        };
        $scope.progressColor = function (size) {
            return progressColor(size)
        }
        vm.selectFont = '';
        vm.selectFontBtn = fontInit;
        vm.selectFontName = selectFontName;
        function fontInit(){
            vm.fontList = [];
            FontManagement.getAll2({family: vm.selectFont}, function (res) {
                vm.fontList = angular.copy(res);
            });
        }
        function selectFontName(item){
            if(item){
                for(var i=0;i<vm.fontList1.length; i++){
                    if(vm.fontList1[i].uuid == item){
                        return vm.fontList1[i].family
                    }
                }
            }
        }

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        vm.fontDoc = [];
        function loadTextDocList(id) {
            FontXmlsManger.byCondition({
                items: [{key: "status", op: "=", value: 'Approved'},{key: "themeClassify.id", op: "=", value: id}],
                page: vm.textIndexNum,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (resp, headers) {
                vm.totalTextItems = headers('X-Total-Count');
                angular.forEach(resp, function (arr) {
                    vm.fontDoc.push(arr);
                })
                $timeout(function () {
                    vm.loading = false;
                    var h = $(".leftPanel-main-wrap").height() - $(".fontdoc-head").height() - 220
                    $(".fontdoc-con").height(h);
                },800)
            })
        }

        $scope.showfontdocLabels = false;
        $scope.showMorefontdocLabels = function () {
            $scope.showfontdocLabels = !$scope.showfontdocLabels;
            setTimeout(function () {
                var h = $(".leftPanel-main-wrap").height() - $(".fontdoc-head").height() - 220
                $(".fontdoc-con").height(h);
            }, 800);
        }

        $scope.loadMoreTextDocs = function () {
            if (vm.fontDoc.length == vm.totalTextItems) {
                MessageService.error("已全部加载完成");
                return
            }
            vm.textIndexNum++;
            loadTextDocList();
        }

        function goToMake() {
            if (vm.pid) {
                // 新建作品
                ItemValue.get({id: $stateParams.vid}, function (res) {
                    vm.bindingtype = res.bindingType;
                    vm.pattern = res.patternType;
                    vm.productXmlNumber = res.productXmlNumber;
                    angular.forEach(res.productXmlLines, function (line) {
                        if (line.id == vm.xmlId) {
                            vm.productXmlLine = angular.copy(line);
                        }
                    })
                    vm.article = {
                        cover: vm.productXmlLine.coverImg,
                        themeClassifyId: vm.productXmlLine.productXmlThemeId,
                        themeClassifyName: vm.productXmlLine.productXmlThemeName,
                        productXmlId: vm.productXmlLine.productXmlId,
                        productXmlName: vm.productXmlLine.productXmlName,
                        musicIdentify: vm.productXmlLine ? vm.productXmlLine.musicIdentify : res.musicIdentify,
                        sku: res.sku,
                        skuNumber: res.skuNumber,
                        name: res.productName,
                        productId: res.productId,
                        productName: res.productName,
                        categoryId: res.categoryId,
                        categoryName: res.categoryName,
                        categoryStoreId: res.categoryStoreId,
                        categoryStoreName: res.categoryStoreName,
                        editorType: res.productEditorType,
                        productType: res.productType,
                        itemValueId: res.id || vm.vid
                    };
                    if (!res.productBokeh) {
                        $scope.productBokehType = 'None';
                    } else {
                        $scope.productBokehType = res.productBokehType || 'None';
                    }

                    Convert.xmlToDocument2({
                        identifier: vm.productXmlLine ? vm.productXmlLine.productXmlIdentifier : res.productXmlIdentifier,
                        provider: 'qiniu',
                        editorType:vm.productXmlLine.productXmlVersion == "4.0" ? "" : vm.article.editorType,
                    }, function (resp) {
                        vm.replace = resp.replace;
                        vm.name = res.sku || res.productName;
                        setPageContent(resp);
                    });
                });
            } else if (vm.aid) {

                // 编辑作品
                Article.byUser({id: $stateParams.aid}, function (resp) {
                    ItemValue.get({id: resp.itemValueId}, function (res) {
                        vm.bindingtype = res.bindingType;
                        vm.pattern = res.patternType;
                        vm.article = angular.copy(resp);
                        vm.replace = resp.document.replace;
                        vm.name = resp.name;
                        $scope.update = true;
                        $scope.isHide = true;
                        setPageContent(resp.document);
                    },function(error){
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    });
                });
            }else if(vm.orderArticleId){
                OrderArticle.byUser({id: vm.orderArticleId}, function (resp) {
                    ItemValue.get({id: resp.itemValueId}, function (res) {
                        vm.bindingtype = res.bindingType;
                        vm.pattern = res.patternType;
                        vm.article = angular.copy(resp);
                        vm.replace = resp.document.replace;
                        vm.name = resp.name;
                        $scope.update = true;
                        $scope.isHide = true;
                        setPageContent(resp.document);
                    },function(){});
                },function(error){
                    if(error.data.status === 404){//未找到作品
                        $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                    }
                });
            }
        }

        /**
         * Get document
         * @param obj
         */
        function setPageContent(obj) {
            // 获取模板列表
            Product.get({id: vm.article.productId, cancelled: false}, function (res) {
                vm.forceFill = res.forceFill;
                if (!res.bokeh) {
                    $scope.productBokehType = 'None';
                } else {
                    $scope.productBokehType = res.bokehType || 'None';
                }
                $scope.productBokeh = res.bokeh;
                $scope.productTitle = res.name;
                for (var i = 0; i < res.itemValues.length; i++) {
                    var itemValue = res.itemValues[i];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    if (itemValue.id == vm.vid) {
                        $scope.tempList = angular.copy(itemValue);
                    }
                }

                angular.forEach($scope.tempList.productXmlLines, function (line, i) {
                    if ($scope.tempCategories.indexOf(line.productXmlThemeName) == -1 && line.productXmlThemeName != null) {
                        $scope.tempCategories.push(line.productXmlThemeName);
                    }
                })
                if ($scope.tempCategories.length) {
                    $scope.selectCategory($scope.tempCategories[0])
                }

            })
            // 正常页
            var arrUse = {
                pages: {
                    page: []
                }
            };
            // 备用页
            var arrUnUse = {
                pages: {
                    page: []
                }
            };
            vm.counts = 0;
            //新建
            if (vm.pid) {
                var usebox = []; //模板中标记使用的页
                var unusebox = []; //模板中标记不使用的页
                for (var i = 0; i < obj.pages.page.length; i++) {
                    var page = obj.pages.page[i];
                    if (page.uuid == null) {
                        page.uuid = UidService.get();
                    }
                    if (!page.backup) {
                        page.count = 1;
                        arrUse.pages.page.push(page);
                        $scope.PAGELEN++;
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox&&level.imagebox.geometry) {
                                vm.counts++;
                                if ($scope.PAGELEN <= 7) {
                                    vm.FirstSeven++;
                                }
                            }
                        })
                    }
                    var p = angular.copy(page);
                    //将模板所有页设为备用页
                    if (p.backup) {
                        p.count = 0;
                        unusebox.push(p);
                    } else {
                        p.backup = true;
                        usebox.push(p);
                    }
                }

                // 将备用页放在正常页面的后面
                arrUnUse.pages.page = unusebox.concat(usebox);
            } else {
                for (var i = 0; i < obj.pages.page.length; i++) {
                    var page = obj.pages.page[i];
                    if (page.uuid == null) {
                        page.uuid = UidService.get();
                    }
                    if (!page.backup) {
                        arrUse.pages.page.push(page);
                        $scope.PAGELEN++;
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox&&level.imagebox.geometry) {
                                vm.counts++;
                                if ($scope.PAGELEN <= 7) {
                                    vm.FirstSeven++;
                                }
                            }
                        })

                    } else {
                        arrUnUse.pages.page.push(page);
                    }
                }
                //计算页面使用次数
                for (var i = 0; i < arrUnUse.pages.page.length; i++) {
                    var p1 = arrUnUse.pages.page[i];
                    var count = 0;
                    for (var j = arrUse.pages.page.length - 1; j >= 0; j--) {
                        var p2 = arrUse.pages.page[j];
                        if (p1.uuid == p2.uuid) {
                            count++;
                        }
                    }
                    p1.count = count;
                }
            }

            obj.pages = angular.copy(arrUse.pages);
            vm.sources = obj;
            vm.cover = angular.copy(obj.cover);
            vm.article.document = {
                pages: obj.pages
            };

            //单页编辑 底下可选择的模板
            if (obj.pages.page && obj.pages.page.length > 0) {
                for (var i = 0; i < arrUnUse.pages.page.length; i++) {
                    var p = arrUnUse.pages.page[i];
                    var count = 0;
                    for (var j = 0; j < obj.pages.page.length; j++) {
                        var p2 = obj.pages.page[j];
                        if (p.uuid == p2.uuid) {
                            count++;
                        }
                    }
                    p.count = count;
                }
            }

            if (vm.pattern == 'OTHER') {
                vm.pageProp = 0.6
            }
            // 备用页
            $scope.pageAllMm = angular.copy(arrUnUse.pages);
            // getAll(arrUnUse.pages, true);
            // 文档中的数据
            tempBgimgs = angular.copy(obj.bgimgs2);
            $scope.bgImgsList = [];
            $scope.bglabelChooseId = $scope.bglabels[0].id || null;
            $scope.bglabelChooseIndex = 0;
            if(tempBgimgs==null){
                tempBgimgs={
                    bgimg:[]
                }
            }
            getRecommendSource(tempBgimgs.bgimg, $scope.bglabels, Backgrounds, vm.indexBgNum, 'Background', vm.totalBgItems);

            $scope.artlabelChooseId = $scope.labels[0].id || null;
            $scope.artlabelChooseIndex = 0;
            tempArts = angular.copy(obj.cliparts);
            if(tempArts==null){
                tempArts={
                    clipart:[]
                }
            }
            getRecommendSource(tempArts.clipart, $scope.labels, ClipArt, vm.indexArtNum, 'ClipArt', vm.totalClipartItems);

            $scope.masklabelChooseId = $scope.masklabels[0].id || null;
            $scope.masklabelChooseIndex = 0;
            tempMasks = angular.copy(obj.masks);
            if(tempMasks==null){
                tempMasks={
                    mask:[]
                }
            }
            getRecommendSource(tempMasks.mask, $scope.masklabels, Material, vm.indexMaskNum, 'Material', vm.totalMaskItems);
            $scope.fontdocsList = [];
            //分类
            ThemeClassify.getAllByCondition4({
                type: 'FONT',
                storeId: vm.storeId
            }, function (res) {
                $scope.fontlabels = angular.copy(res.message);
                $scope.fontlabelChooseId = null;
                $scope.fontlabelChooseIndex = 0;
                if($scope.fontlabels.length){
                    $scope.fontlabelChooseId = $scope.fontlabels[0].id;
                    loadTextDocList($scope.fontlabels[0].id);
                }
            });
            // getRecommendSource(tempFonts.font, $scope.fontlabels, null, vm.textIndexNum, 'FONT', vm.totalTextItems);

            // getMaskList(obj);
            //非备用页
            var page0 = vm.article.document.pages.page[0];
            getAll(vm.article.document.pages, false);
        }
        $scope.selectFontDocLabel = function (label, index) {
            // ClipArt, vm.indexArtNum, 'ClipArt', vm.indexArtNum
            $scope.fontlabelChooseId = label.id;
            $scope.fontlabelChooseIndex = index;
            $scope.fontdocsList = [];
            vm.fontDoc = [];
            vm.textIndexNum = 0;
            if(!label.firstClick){
                vm.loading = true;
                label.firstClick = true;
            }
            loadTextDocList(label.id)
        };

        function getRecommendSource(objs, labels, url, num, attr, items) {
            var list = []
            angular.forEach(objs, function (obj, i) {
                var res = obj;
                if (attr != 'Background') {
                    res = obj.resource;
                }
                var arr = {
                    name: obj.name,
                    identifier: res.identifier,
                    provider: res.provider,
                    themeClassifyName: '推荐',
                    themeClassifyId: null,
                    resource: obj.resource
                }
                list.push(arr);
                // $scope.bgImgs = angular.copy($scope.bgImgsList);
            })

            var len = 0;
            if (objs && objs.length) {
                len = objs.length;
            }
            //分类
            ThemeClassify.getAllByCondition4({
                type: attr,
                storeId: vm.storeId
            }, function (res) {
                labels = angular.copy(res.message);
                if (len) {
                    labels.unshift({
                        name: '推荐',
                        id: null
                    })
                    setSource(attr, list, labels)
                    vm.totalClipartItems = len;
                } else if (labels.length) {
                    getBackgrounds(labels[0], url, num, function (res) {
                        setSource(attr, res.arr, labels,res.items);
                    });
                }
            });
        }

        function setSource(attr, list, labels,items) {
            if (attr == 'Background') {
                $scope.bgImgsList = angular.copy(list);
                vm.bgimgs2 = angular.copy($scope.bgImgsList);
                $scope.bglabels = labels;
                $scope.bglabelChooseId = labels[0].id;
                $scope.bglabelChooseIndex = 0;
                vm.totalBgItems = items;

            } else if (attr == 'ClipArt') {
                $scope.stickerList = angular.copy(list);
                vm.cliparts = angular.copy($scope.stickerList);
                $scope.labels = labels;
                $scope.artlabelChooseId = labels[0].id;
                $scope.artlabelChooseIndex = 0;
                vm.totalClipartItems = items

            } else if (attr == 'Material') {
                $scope.masklabels = labels;
                getMaskList(list, 'all');
                $scope.masklabelChooseId = labels[0].id;
                $scope.masklabelChooseIndex = 0;
                vm.totalMaskItems = items;

            }
        }

        function getBackgrounds(label, url, num, callback) {
            var list = {
                items: 0,
                arr: []
            };
            url.byCondition3({
                items: [
                    {key: "themeClassify.id", op: "=", value: label.id}],
                page: num,
                size: vm.itemsPerPage
            }, function (res, headers) {
                list.items = headers('X-Total-Count');
                angular.forEach(res, function (resp) {
                    var arr = {
                        name: resp.name,
                        identifier: $scope.domain + resp.identifier,
                        provider: resp.provider,
                        themeClassifyName: resp.themeClassifyName,
                        themeClassifyId: resp.themeClassifyId,
                        resource: {
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                        }
                    }
                    list.arr.push(arr);
                })
                callback.call(this, list);
            });
        }

        var undonePage = null;
        function getAll(pages, isUnUse) {
            var template = angular.copy(pages);
            angular.forEach(template.page,function (page,index) {
                var mm = angular.copy(page.trimbox.width);
                page.widthM= angular.copy(page.mediabox.width);
                page.heightM= angular.copy(page.mediabox.height);
                page.seq = index + 1;
                page.mediabox = {
                    width: unitTransform(page.mediabox.width),
                    height: unitTransform(page.mediabox.height),
                    x: unitTransform(page.mediabox.x),
                    y: unitTransform(page.mediabox.y)
                };
                page.trimbox = {
                    width: unitTransform(page.trimbox.width),
                    height: unitTransform(page.trimbox.height),
                    x: unitTransform(page.trimbox.x),
                    y: unitTransform(page.trimbox.y)
                };
                // 兼容旧模板
                if (!page.editbox) {
                    page.editbox = angular.copy(page.trimbox);
                }else{
                    mm = angular.copy(page.editbox.width)
                    page.editbox = {
                        width: unitTransform(page.editbox.width),
                        height: unitTransform(page.editbox.height),
                        x: unitTransform(page.editbox.x),
                        y: unitTransform(page.editbox.y)
                    };
                }
                if(!page.editboxes || !page.editboxes.editbox){
                    page.editboxes = {
                        editbox:[page.editbox]
                    }
                }else{
                    page.editboxes.editbox.forEach(function (editbox,index1) {
                        page.editboxes.editbox[index1].width= unitTransform(editbox.width);
                        page.editboxes.editbox[index1].height= unitTransform(editbox.height);
                        page.editboxes.editbox[index1].x= unitTransform(editbox.x);
                        page.editboxes.editbox[index1].y= unitTransform(editbox.y);
                    })
                }
                page.proportionText = page.trimbox.width / (page.mediabox.width / 120) / (mm / 25.4 * vm.windowDpiW);

                page.custom = {};
                //条形码
                if(page.barcode){
                    page.barcode.geometry = {
                        width: unitTransform(page.barcode.geometry.width),
                        height: unitTransform(page.barcode.geometry.height),
                        x: unitTransform(page.barcode.geometry.x),
                        y: unitTransform(page.barcode.geometry.y)
                    };
                }
                if(page.qrcode){
                    page.qrcode.geometry = {
                        width: unitTransform(page.qrcode.geometry.width),
                        height: unitTransform(page.qrcode.geometry.height),
                        x: unitTransform(page.qrcode.geometry.x),
                        y: unitTransform(page.qrcode.geometry.y)
                    };
                }

                if (page.background&&page.background.resource&&page.background.resource.identifier) {
                    page.background.offsetx = unitTransform(page.background.offsetx);
                    page.background.offsety = unitTransform(page.background.offsety);
                    getBackgroundSource(page.background.resource,page.mediabox.width,page.mediabox.height,page.background);
                }
                if (page.spread) {
                    vm.pageProp = 0.6
                }
                page.isMaxWidth = getAdaptation($con.width() * vm.pageProp, $con.height() * 0.8, page.mediabox.width, page.mediabox.height, page);

                angular.forEach(page.levels.level, function (level, n) {
                    if(level.imageart&&level.imageart.geometry){
                        var art = level.imageart;
                        art.current = 'imageart';
                        art.levelI = n;
                        art.pageI = index;
                        art.lock = true;
                        art.offsetx = unitTransform(art.offsetx);
                        art.offsety = unitTransform(art.offsety);
                        art.geometry = {
                            width: unitTransform(art.geometry.width),
                            height: unitTransform(art.geometry.height),
                            x: unitTransform(art.geometry.x),
                            y: unitTransform(art.geometry.y)
                        };
                        art.rotation.angle = -art.rotation.angle;
                        if(art.resource.identifier){
                            if(!art.width && !art.height){
                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                    art.imageInfo = data;
                                    art.width = data.width;
                                    art.height = data.height;
                                }).error(function (error) {
                                    MessageService.error("获取图片信息失败");
                                });
                            }
                        }
                    }
                    if(level.imagebox&&level.imagebox.geometry){
                        var img = level.imagebox;
                        img.geometry = {
                            width: unitTransform(img.geometry.width),
                            height: unitTransform(img.geometry.height),
                            x: unitTransform(img.geometry.x),
                            y: unitTransform(img.geometry.y)
                        };
                        img.transparency = isNaN(img.transparency) ? 1 : img.transparency;
                        img.rotation.angle = -img.rotation.angle;
                        img.imgUrl = 'content/images/mask_souce.png';
                        img.maskUrl = 'content/images/mask_souce.png';
                        img.current = 'imagebox';
                        img.levelI = n;
                        img.pageI = index;
                        img.lock = true;
                        if(!img.bokehtype){
                            img.bokehtype = img.bokehtype || 'None';
                        }

                        if (img.mask.resource.identifier) {
                            img.maskUrl = img.mask.resource.identifier + '?imageMogr2';
                        }

                        if (img.image && img.image.resource && img.image.resource.identifier) {       //qiniu
                            img.image = {
                                resource: {
                                    identifier: img.image ? img.image.resource.identifier : '',
                                    provider: img.image ? img.image.resource.provider : 'qiniu'
                                },
                                filterResource: {
                                    identifier: img.image && img.image.filterResource ? img.image.filterResource.identifier : '',
                                    provider: img.image && img.image.filterResource ? img.image.filterResource.provider : 'qiniu'
                                },
                                imgsize: img.image.imgsize == null ? '' : img.image.imgsize,
                                parameter: img.image.parameter == null ? [] : angular.fromJson(img.image.parameter),
                                filter: img.image.filter == null ? '' : img.image.filter,
                                offsetx: img.image.offsetx?unitTransform(img.image.offsetx):0,
                                offsety: img.image.offsety?unitTransform(img.image.offsety):0,
                                angle: img.image && img.image.angle ? img.image.angle : 0,
                                scale: img.image.scale == 1 ? 1 : img.image.scale,
                                index: (!img.image) ? '' : img.image.index,
                                resourceid: (!img.image) ? '' : img.image.resourceid,
                                imageInfo: (!img.image) ? '' : img.image.imageInfo,
                                width: (!img.image) ? '' : unitTransform(img.image.width),
                                height: (!img.image) ? '' : unitTransform(img.image.height),
                            }
                            if(vm.aid||(!img.image.width&&!img.image.height)){
                                // img.image.offsetx = unitTransform(img.image.offsetx);
                                // img.image.offsety = unitTransform(img.image.offsety);
                                if(img.blurredrectangle){
                                    img.blurredrectangle={
                                        width:unitTransform(img.blurredrectangle.width),
                                        height:unitTransform(img.blurredrectangle.height),
                                        x:unitTransform(img.blurredrectangle.x),
                                        y:unitTransform(img.blurredrectangle.y)
                                    }
                                }
                                getBackgroundSource1(img,img.geometry.width,img.geometry.height,img.image)
                            }else{
                                imgScale(img, img.image.offsetx, img.image.offsety, false);
                            }
                        }else{
                            img.image = {
                                resource: {
                                    identifier: '',
                                    provider: 'qiniu'
                                },
                                filterResource: {
                                    identifier: '',
                                    provider: ''
                                },
                                offsetx: 0,
                                offsety: 0,
                                width: '',
                                height: '',
                                imgsize: '',
                                index: '',
                                parameter: [],
                                scale: 1,
                                angle: 0,
                            }
                            if(vm.aid&&undonePage==null&&!isUnUse){
                                undonePage=page;
                            }
                        }
                    }
                    if(level.textbox&&level.textbox.geometry){
                        var textbox = level.textbox;
                        textbox.current = 'textbox';
                        textbox.levelI = n;
                        textbox.pageI = index;
                        textbox.lock = true;
                        textbox.geometry = {
                            width: unitTransform(textbox.geometry.width),
                            height: unitTransform(textbox.geometry.height),
                            x: unitTransform(textbox.geometry.x),
                            y: unitTransform(textbox.geometry.y)
                        };
                        textbox.rotation.angle = -textbox.rotation.angle;
                        if (textbox.fontuuid) {
                            if (vm.fontuuids.indexOf(textbox.fontuuid) == -1) {
                                vm.fontuuids.push(textbox.fontuuid);
                                //下载字体
                                getFont(textbox);
                                textbox.loading = false;
                            }
                            fontStyle(textbox);
                            FontSupport2(FontManagement, textbox)
                        }
                        if (textbox.style.bold && textbox.style.italic) {
                            textbox.styles = 'BOLD_ITALIC';
                        } else if (textbox.style.bold) {
                            textbox.styles = 'BOLD';
                        } else if (textbox.style.italic) {
                            textbox.styles = 'ITALIC';
                        } else {
                            textbox.styles = 'NORMAL';
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = level.textdocument;
                        textdocument.current = 'textdocument';
                        textdocument.levelI = n;
                        textdocument.pageI = index;
                        textdocument.lock = true;
                        textdocument.scale = textdocument.geometry.width/page.mediabox.width;
                        textdocument.geometry = {
                            width: unitTransform(textdocument.geometry.width),
                            height: unitTransform(textdocument.geometry.height),
                            x: unitTransform(textdocument.geometry.x),
                            y: unitTransform(textdocument.geometry.y)
                        }

                        var backg = textdocument.background;
                        var pageW = textdocument.geometry.width,
                            pageH = textdocument.geometry.height;
                        if(backg.resource.identifier){
                            getBackgroundSource(backg.resource,pageW,pageH,backg);
                            backg.offsetx = unitTransform(backg.offsetx);
                            backg.offsety = unitTransform(backg.offsety);
                        }

                        angular.forEach(textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var docArt = lev.imageart;
                                docArt.current = 'imageart';
                                docArt.levelI = n;
                                docArt.pageI = index;
                                docArt.lock = true;
                                docArt.offsetx = unitTransform(docArt.offsetx);
                                docArt.offsety = unitTransform(docArt.offsety);
                                docArt.geometry = {
                                    width: unitTransform(docArt.geometry.width),
                                    height: unitTransform(docArt.geometry.height),
                                    x: unitTransform(docArt.geometry.x),
                                    y: unitTransform(docArt.geometry.y)
                                }
                                docArt.rotation.angle = -docArt.rotation.angle;
                                $http({url: docArt.resource.identifier + '?imageInfo'}).success(function (data) {
                                    docArt.width = data.width;
                                    docArt.height = data.height;
                                }).error(function (error) {
                                    MessageService.error("获取图片信息失败");
                                });
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var docText = lev.textbox;
                                docText.geometry = {
                                    width: unitTransform(docText.geometry.width),
                                    height: unitTransform(docText.geometry.height),
                                    x: unitTransform(docText.geometry.x),
                                    y: unitTransform(docText.geometry.y)
                                }
                                docText.fontStyle = [{
                                    style: '',
                                    path: docText.identifier
                                }];

                                docText.current = 'textbox';
                                docText.parentCurrent = 'textdocument';
                                docText.levelI = n;
                                docText.pageI = index;
                                docText.lock = true;

                                docText.rotation.angle = -docText.rotation.angle;
                                if (vm.fontuuids.indexOf(docText.fontuuid) == -1) {
                                    vm.fontuuids.push(docText.fontuuid);
                                    //下载字体
                                    getFont(docText);
                                    docText.loading = false;
                                }
                            }
                        })
                    }
                })
                page.navRatioW = page.mediabox.width/120;
                page.navRatioH = (page.mediabox.height/(page.mediabox.height*120/page.mediabox.width))
            })
            if (isUnUse) {
                $scope.pageAllPx = template;
            } else {
                $scope.template.pages = template;
                $scope.isLeftRotation = !$scope.template.pages.page[0].centerrotation;
                getSameUseNum();
                getUsedImageboxsNum();
                // vm.pattern = 'BOOK2';
                // 翻页
                var arr = $scope.template;
                $scope.originTemp = angular.copy($scope.template);
                angular.forEach(arr.pages.page, function (obj, index) {
                    getArr(arr, obj, index);
                });
                vm.picNums++
            }
            hideChangeBtn();
            return
        }
        function getPage(item, index,callback) {
            var page = angular.copy(item);
            var index = angular.copy(index-1)
            var mm = angular.copy(page.trimbox.width);
            page.widthM= angular.copy(page.mediabox.width);
            page.heightM= angular.copy(page.mediabox.height);
            page.seq = index + 1;
            page.mediabox = {
                width: unitTransform(page.mediabox.width),
                height: unitTransform(page.mediabox.height),
                x: unitTransform(page.mediabox.x),
                y: unitTransform(page.mediabox.y)
            };
            page.trimbox = {
                width: unitTransform(page.trimbox.width),
                height: unitTransform(page.trimbox.height),
                x: unitTransform(page.trimbox.x),
                y: unitTransform(page.trimbox.y)
            };
            // 兼容旧模板
            if (!page.editbox) {
                page.editbox = angular.copy(page.trimbox);
            }else{
                mm = angular.copy(page.editbox.width)
                page.editbox = {
                    width: unitTransform(page.editbox.width),
                    height: unitTransform(page.editbox.height),
                    x: unitTransform(page.editbox.x),
                    y: unitTransform(page.editbox.y)
                };
            }
            if(!page.editboxes || !page.editboxes.editbox){
                page.editboxes = {
                    editbox:[page.editbox]
                }
            }else{
                page.editboxes.editbox.forEach(function (editbox,index1) {
                    page.editboxes.editbox[index1].width= unitTransform(editbox.width);
                    page.editboxes.editbox[index1].height= unitTransform(editbox.height);
                    page.editboxes.editbox[index1].x= unitTransform(editbox.x);
                    page.editboxes.editbox[index1].y= unitTransform(editbox.y);
                })
            }
            page.proportionText = page.trimbox.width / (page.mediabox.width / 120) / (mm / 25.4 * vm.windowDpiW);

            page.custom = {};
            //条形码
            if(page.barcode){
                page.barcode.geometry = {
                    width: unitTransform(page.barcode.geometry.width),
                    height: unitTransform(page.barcode.geometry.height),
                    x: unitTransform(page.barcode.geometry.x),
                    y: unitTransform(page.barcode.geometry.y)
                };
            }
            if(page.qrcode){
                page.qrcode.geometry = {
                    width: unitTransform(page.qrcode.geometry.width),
                    height: unitTransform(page.qrcode.geometry.height),
                    x: unitTransform(page.qrcode.geometry.x),
                    y: unitTransform(page.qrcode.geometry.y)
                };
            }

            if (page.background&&page.background.resource&&page.background.resource.identifier) {
                page.background.offsetx = unitTransform(page.background.offsetx);
                page.background.offsety = unitTransform(page.background.offsety);
                getBackgroundSource(page.background.resource,page.mediabox.width,page.mediabox.height,page.background);
            }
            if (page.spread) {
                vm.pageProp = 0.6
            }
            page.isMaxWidth = getAdaptation($con.width() * vm.pageProp, $con.height() * 0.8, page.mediabox.width, page.mediabox.height, page);

            angular.forEach(page.levels.level, function (level, n) {
                if(level.imageart&&level.imageart.geometry){
                    var art = level.imageart;
                    art.current = 'imageart';
                    art.levelI = n;
                    art.pageI = index;
                    art.lock = true;
                    art.offsetx = unitTransform(art.offsetx);
                    art.offsety = unitTransform(art.offsety);
                    art.geometry = {
                        width: unitTransform(art.geometry.width),
                        height: unitTransform(art.geometry.height),
                        x: unitTransform(art.geometry.x),
                        y: unitTransform(art.geometry.y)
                    };
                    art.rotation.angle = -art.rotation.angle;
                    if(art.resource.identifier && !art.width && !art.height){
                        $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                            art.width = data.width;
                            art.height = data.height;
                        }).error(function (error) {
                            MessageService.error("获取图片信息失败");
                        });
                    }
                }
                if(level.imagebox&&level.imagebox.geometry){
                    var img = level.imagebox;
                    img.geometry = {
                        width: unitTransform(img.geometry.width),
                        height: unitTransform(img.geometry.height),
                        x: unitTransform(img.geometry.x),
                        y: unitTransform(img.geometry.y)
                    };
                    img.transparency = isNaN(img.transparency) ? 1 : img.transparency;
                    img.rotation.angle = -img.rotation.angle;
                    img.imgUrl = 'content/images/mask_souce.png';
                    img.maskUrl = 'content/images/mask_souce.png';
                    img.current = 'imagebox';
                    img.levelI = n;
                    img.pageI = index;
                    img.lock = true;
                    if(!img.bokehtype){
                        img.bokehtype = img.bokehtype || 'None';
                    }

                    if (img.mask.resource.identifier) {
                        img.maskUrl = img.mask.resource.identifier + '?imageMogr2';
                    }

                    if (img.image && img.image.resource && img.image.resource.identifier) {       //qiniu
                        img.image = {
                            resource: {
                                identifier: img.image ? img.image.resource.identifier : '',
                                provider: img.image ? img.image.resource.provider : 'qiniu'
                            },
                            filterResource: {
                                identifier: img.image && img.image.filterResource ? img.image.filterResource.identifier : '',
                                provider: img.image && img.image.filterResource ? img.image.filterResource.provider : 'qiniu'
                            },
                            imgsize: img.image.imgsize == null ? '' : img.image.imgsize,
                            parameter: img.image.parameter == null ? [] : angular.fromJson(img.image.parameter),
                            filter: img.image.filter == null ? '' : img.image.filter,
                            offsetx: img.image.offsetx?unitTransform(img.image.offsetx):0,
                            offsety: img.image.offsety?unitTransform(img.image.offsety):0,
                            angle: img.image && img.image.angle ? img.image.angle : 0,
                            scale: img.image.scale == 1 ? 1 : img.image.scale,
                            index: (!img.image) ? '' : img.image.index,
                            resourceid: (!img.image) ? '' : img.image.resourceid,
                            imageInfo: (!img.image) ? '' : img.image.imageInfo,
                            width: (!img.image) ? '' : img.image.width,
                            height: (!img.image) ? '' : img.image.height,
                        }
                        if(vm.aid||(!img.image.width&&!img.image.height)){
                            // img.image.offsetx = unitTransform(img.image.offsetx);
                            // img.image.offsety = unitTransform(img.image.offsety);
                            if(img.blurredrectangle){
                                img.blurredrectangle={
                                    width:unitTransform(img.blurredrectangle.width),
                                    height:unitTransform(img.blurredrectangle.height),
                                    x:unitTransform(img.blurredrectangle.x),
                                    y:unitTransform(img.blurredrectangle.y)
                                }
                            }
                            getBackgroundSource1(img,img.geometry.width,img.geometry.height,img.image)
                        }else{
                            imgScale(img, img.image.offsetx, img.image.offsety, false);
                        }
                    }else{
                        img.image = {
                            resource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            filterResource: {
                                identifier: '',
                                provider: ''
                            },
                            offsetx: 0,
                            offsety: 0,
                            width: '',
                            height: '',
                            imgsize: '',
                            index: '',
                            parameter: [],
                            scale: 1,
                            angle: 0,
                        }
                        if(vm.aid&&undonePage==null&&!isUnUse){
                            undonePage=page;
                        }
                    }
                }
                if(level.textbox&&level.textbox.geometry){
                    var textbox = level.textbox;
                    textbox.current = 'textbox';
                    textbox.levelI = n;
                    textbox.pageI = index;
                    textbox.lock = true;
                    textbox.geometry = {
                        width: unitTransform(textbox.geometry.width),
                        height: unitTransform(textbox.geometry.height),
                        x: unitTransform(textbox.geometry.x),
                        y: unitTransform(textbox.geometry.y)
                    };
                    textbox.rotation.angle = -textbox.rotation.angle;
                    if (textbox.fontuuid) {
                        if (vm.fontuuids.indexOf(textbox.fontuuid) == -1) {
                            vm.fontuuids.push(textbox.fontuuid);
                            //下载字体
                            getFont(textbox);
                            textbox.loading = false;
                        }
                        fontStyle(textbox);
                    }
                    if (textbox.style.bold && textbox.style.italic) {
                        textbox.styles = 'BOLD_ITALIC';
                    } else if (textbox.style.bold) {
                        textbox.styles = 'BOLD';
                    } else if (textbox.style.italic) {
                        textbox.styles = 'ITALIC';
                    } else {
                        textbox.styles = 'NORMAL';
                    }
                }
                if (level.textdocument && level.textdocument.geometry) {
                    var textdocument = level.textdocument;
                    textdocument.current = 'textdocument';
                    textdocument.levelI = n;
                    textdocument.pageI = index;
                    textdocument.lock = true;
                    textdocument.scale = textdocument.geometry.width/page.mediabox.width;
                    textdocument.geometry = {
                        width: unitTransform(textdocument.geometry.width),
                        height: unitTransform(textdocument.geometry.height),
                        x: unitTransform(textdocument.geometry.x),
                        y: unitTransform(textdocument.geometry.y)
                    }

                    var backg = textdocument.background;
                    var pageW = textdocument.geometry.width,
                        pageH = textdocument.geometry.height;
                    if(backg.resource.identifier){
                        getBackgroundSource(backg.resource,pageW,pageH,backg);
                        backg.offsetx = unitTransform(backg.offsetx);
                        backg.offsety = unitTransform(backg.offsety);
                    }

                    angular.forEach(textdocument.levels.level, function (lev, l) {
                        if (lev.imageart && lev.imageart.geometry) {
                            var docArt = lev.imageart;
                            docArt.current = 'imageart';
                            docArt.levelI = n;
                            docArt.pageI = index;
                            docArt.lock = true;
                            docArt.offsetx = unitTransform(docArt.offsetx);
                            docArt.offsety = unitTransform(docArt.offsety);
                            docArt.geometry = {
                                width: unitTransform(docArt.geometry.width),
                                height: unitTransform(docArt.geometry.height),
                                x: unitTransform(docArt.geometry.x),
                                y: unitTransform(docArt.geometry.y)
                            }
                            docArt.rotation.angle = -docArt.rotation.angle;
                            $http({url: docArt.resource.identifier + '?imageInfo'}).success(function (data) {
                                docArt.width = data.width;
                                docArt.height = data.height;
                            }).error(function (error) {
                                MessageService.error("获取图片信息失败");
                            });
                        }
                        if (lev.textbox && lev.textbox.geometry) {
                            var docText = lev.textbox;
                            docText.geometry = {
                                width: unitTransform(docText.geometry.width),
                                height: unitTransform(docText.geometry.height),
                                x: unitTransform(docText.geometry.x),
                                y: unitTransform(docText.geometry.y)
                            }
                            docText.fontStyle = [{
                                style: '',
                                path: docText.identifier
                            }];

                            docText.current = 'textbox';
                            docText.parentCurrent = 'textdocument';
                            docText.levelI = n;
                            docText.pageI = index;
                            docText.lock = true;

                            docText.rotation.angle = -docText.rotation.angle;
                            if (vm.fontuuids.indexOf(docText.fontuuid) == -1) {
                                vm.fontuuids.push(docText.fontuuid);
                                //下载字体
                                getFont(docText);
                                docText.loading = false;
                            }
                        }
                    })
                }
            })
            page.navRatioW = page.mediabox.width/120;
            page.navRatioH = (page.mediabox.height/(page.mediabox.height*120/page.mediabox.width));
            $timeout(function (){
                callback(page);
            },1000);
        }
        vm.picNums = 1;

        function getGeometry(page) {
            return {
                height: unitTransform(page.height),
                width: unitTransform(page.width),
                x: unitTransform(page.x),
                y: unitTransform(page.y)
            }
        }

        $scope.$watch("vm.picNums",function(newValue,oldValue){
            if(oldValue){
                $scope.originTemp = angular.copy($scope.template);
                var p = [];
                angular.forEach($scope.document.pages, function (item, index) {
                    p.push(item.arr);
                    if (item.arr2) {
                        p.push(item.arr2);
                    }
                });
                $scope.history = [angular.copy($scope.template)];
                $scope.pageHistory = [];

                angular.forEach($scope.template.pages.page, function (p, i) {
                    if (!$scope.pageHistory[i]) {
                        $scope.pageHistory[i] = {
                            history: []
                        }
                    }
                    $scope.pageHistory[i].history.push(angular.copy(p));
                    $scope.pageHistory[i].currentHistoryIndex = 0;
                })
            }
        })

        function getBackgroundSource1(res,pageW,pageH,item) {
            if(item.imageInfo){
                getBackgroundSource11(item.imageInfo)
            }else{
                $.ajax({
                    type: "GET",
                    url: item.resource.identifier + "?imageInfo",
                    success: function (newImg) {
                        item.imageInfo = newImg;
                        getBackgroundSource11(item.imageInfo)
                    }
                })
            }
            function getBackgroundSource11(imageInfo) {
                if(typeof(imageInfo) == 'string'){
                    imageInfo = JSON.parse(imageInfo)
                }
                f++;
                if (f == vm.counts) {
                    setPageHistory();
                }
                var w1 = 0,h1 = 0;
                w1 = imageInfo.width;
                h1 = imageInfo.height;
                item.imgsize = imageInfo.size;
                var width = item.width = w1;
                var height = item.height = h1;
                if(item.angle == 90||item.angle ==270){
                    width = h1;
                    height = w1;
                }
                var wOrigin = angular.copy(width);
                var hOrigin = angular.copy(height);

                if ((wOrigin <= hOrigin || pageW <= pageH) && (wOrigin / hOrigin < pageW / pageH) || wOrigin >= hOrigin && pageW >= pageH && (wOrigin / hOrigin < pageW / pageH)) {
                    res.adaptation = 'Width';
                    item.width= pageW;
                    item.height= pageW/width*height;
                }else {
                    res.adaptation = 'Height';
                    item.width= pageH/height * width;
                    item.height= pageH;
                }
                pxInsufficient(res,vm.imgSize, vm.imgDpi);
                vm.picNums++;
            }
        }

        function getBackgroundSource(res, pageW, pageH, arr) {
            if(arr.imageInfo){
                getBackgroundSourceCb(arr.imageInfo)
            }else{
                $.ajax({
                    type: "GET",
                    url: res.identifier + "?imageInfo",
                    success: function (data) {
                        arr.imageInfo = data;
                        getBackgroundSourceCb(arr.imageInfo)
                    }
                })
            }
            function getBackgroundSourceCb(imageInfo){
                var bgW = imageInfo.width,
                    bgH = imageInfo.height;

                // 高适配
                var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                // 宽适配
                if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                    w = pageW;
                    h = pageW * bgH / bgW;
                    adaptation = 'Width';
                }
                arr.width = bgW;
                arr.height = bgH;
                arr.width = w;
                arr.height = h;
                arr.type = 'Pic';
                arr.adaptation = adaptation;
                vm.picNums++;
            }
        }

        // px---mm
        function ThreeHundredDpiToMm(attr1, attr2) {
            var mm1 = parseFloat((attr1 / vm.windowDpiW * 25.4 - 0)).toFixed(2) - 0;
            var mm2 = parseFloat((attr2 / vm.windowDpiH * 25.4 - 0)).toFixed(2) - 0;
            return {
                mm1: mm1,
                mm2: mm2
            }

        }

        function getArr(arr, obj, index) {
            var w = obj.mediabox.width;
            var h = obj.mediabox.height;

            $scope.document.pages[index] = {
                arr: obj,
                arr2: '',
                width: w,
                height: h,
                spread: obj.spread,
                'thumbnail': 1,
                'thumbnail2': 1,
                id: obj.id,
                mediabox: angular.copy(obj.mediabox),
                scale: 1
            };
            setBgImg(index, arr, obj);

            if (index == $scope.PAGELEN - 1) {
                var page = $scope.document.pages;
                //第一个page宽度 和 最大page宽度 的比值
                getThumbnail(page[0], page[0]);
                for (var j = 0; j < page.length; j++) {
                    //每个page宽度 和 最大page宽度 的比值
                    getThumbnail(page[j], page[0]);
                    if (j + 1 != page.length) {
                        getThumbnail(page[j + 1], page[j + 1]);
                    }
                    //跨页
                    if (page[j].spread == true || vm.pattern == 'OTHER' || (j == 0 || j == page.length - 1)&&vm.pattern != 'BOOK2') {
                        //左右翻页的清空

                    } else if (page[j].spread == false && (page[j + 1] || []).spread == false) {
                        var arr = page[j + 1];
                        var arr0 = page[j];
                        page[j] = {
                            id: arr0.id,
                            width: arr0.mediabox.width,
                            height: arr0.mediabox.height,
                            arr: arr0.arr,
                            spread: arr0.spread,
                            thumbnail: arr0.thumbnail,
                            id2: arr.id,
                            width2: arr.mediabox.width,
                            height2: arr.mediabox.height,
                            arr2: arr.arr,
                            spread2: arr.spread,
                            thumbnail2: arr.thumbnail,
                            scale: 1
                        };
                        page.splice(j + 1, 1);
                    }
                    setOriginalWaH(page[j])
                    if (j == page.length - 1) {
                        $scope.document.pages = page;
                        $scope.docLen = $scope.document.pages.length;
                        $scope.pageW = $con.width() * vm.pageProp;
                        if ($scope.docLen > 2) {
                            $scope.visibleLeft = false;
                            $scope.visibleRight = true;
                        } else {
                            $scope.visibleLeft = false;
                            $scope.visibleRight = false;
                        }

                    }
                }
                $scope.document.pages = page;
                // 跳转到未完成那一页
                if(undonePage==null){
                    undonePage=$scope.document.pages[0].arr
                }
                $scope.selectPages(undonePage);

                setPageHistory();
                // $scope.currentPage = $scope.document.pages[0].arr;
                // $scope.pageI = 0;
                // $scope.arrI = 1;
                fontSizeInit($scope.document.pages[0].mediabox.height)
            }
        }

        function setOriginalWaH(page) {
            if ($scope.isMaxWidth) {
                page.liWidth = $con.width() * vm.pageProp
            } else {
                var h = page.arr2 ? page.width2 / page.height2 : page.width / page.height;
                page.liWidth = ($scope.dHeight - heightDiffer) * (page.width / page.height + h);
            }
            page.proportion = (page.liWidth / $con.width() * vm.pageProp);
        }

        /**
         * 根据 宽度 / 高度 适配
         * @param screenW
         * @param screenH
         * @param pageW
         * @param pageH
         * @returns {boolean}
         */
        function getAdaptation(screenW, screenH, pageW, pageH, page) {
            var newW = angular.copy(screenW);

            if (!(page.spread || vm.pattern == 'OTHER')) {
                newW /= 2;
            }

            if (newW / screenH < pageW / pageH) {
                //宽定，高不定
                $scope.isMaxWidth = true;
                return true;
            } else {
                //高定，宽不定
                $scope.isMaxWidth = false;
                return false;
            }
        }

        function getThumbnail(page, page2) {
            var w = page.width;
            var w2 = page.width;
            var maxW = page.mediabox.width;
            if (!(page.spread || vm.pattern == 'OTHER') && page.arr2) {
                w /= 2;
                maxW /= 2;
            }
            if (maxW - w >= 0) {
                // if (!(page2.spread || vm.pattern == 'OTHER') && page2.arr2) {
                //     w2 /= 2;
                // }
                page.thumbnail = w2 / maxW
            }
            console.log(page.thumbnail)
        }

        function setBgImg(index, documents, obj) {
            if (index <= 2) {
                return
            }
            if (!documents.pages.page[index].identifier) {
                return
            }
            if (index >= (documents.pages.page.length - 1)) {
                return
            }
            if (obj.spread || vm.pattern == 'OTHER') {
                return
            }
            if (!documents.pages.page[index - 2].spread && (documents.pages.page[index - 2].spread2) || vm.pattern == 'OTHER') {
                $scope.document.pages[index - 1].arr2 = documents.pages.page[index];
                return
            }
            if (!(documents.pages.page[index] || []).spread || vm.pattern == 'OTHER') {
                $scope.document.pages[index - 1].arr2 = documents.pages.page[index];
            }
        }


        // 左边侧边栏点击切换
        $scope.selectPanel = function (attr) {
            removeContenteditable();
            if (!vm.leftShow) {
                $scope.hidePanel(1)
            }
            $scope.selPanel = attr;
            if (attr == 'page') {
                setTimeout(function () {
                    waterFall('main3');
                }, 200);
            }
            if (attr == 'bgImg') {
                setTimeout(function () {
                    waterFall('main4');
                    // var h = $(".leftPanel-main-wrap").height() - $(".bgimg-color").height() - $(".bgimg-head").height() - 60
                    // $(".bgimg-con").height(h);
                }, 400);
            }

            if (attr == 'mask') {
                // setTimeout(function () {
                //     // waterFall('main2');
                //     var h = $(".leftPanel-main-wrap").height() - $(".mask-head").height() - 30
                //     $(".mask-con").height(h);
                // }, 200);
            }

            if (attr == 'material') {
                waterFallCliparts();
            }
            if(attr == 'text'){
                // setTimeout(function () {
                //     var h = $(".leftPanel-main-wrap").height() - $(".fontdoc-head").height() - 220
                //     $(".fontdoc-con").height(h);
                // }, 200);
            }
            if(attr == 'border'){
                borderInit()
            }
            hideChangeBtn();
        }

        function waterFallCliparts() {
            // setTimeout(function () {
            //     // waterFall('main1');
            //     var h = $(".leftPanel-main-wrap").height() - $(".clipart-head").height() - 30
            //     $(".clipart-con").height(h);
            // }, 800);
        }

        /**
         * 上一张，下一张
         */
        $scope.pageDirection = function (flag,e) {
            $scope.canvasCb()
            fn(e);
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            $scope.template.levelI = null;
            vm.isTransp = false;
            // removeContenteditable();
            // 上一张
            if (flag) {
                $scope.pageI = $scope.pageI - 1;
            } else {
                $scope.pageI = $scope.pageI - 0 + 1;
            }
            $scope.arrI = 1;
            $scope.currentPage = $scope.document.pages[$scope.pageI].arr;
            isCanLeftAndRightPage();

            var h = 0;
            for (var i = 0; i < $scope.currentPage.seq - 6; i++) {
                var page = $scope.template.pages.page[i];
                if (vm.rightShow) {
                    h += page.height * 120 / page.width + 10;
                } else {
                    h += 44;
                }

            }
            $(".rightPanel").scrollTop(h);
        };

        //点击页面显示左右方向
        function isCanLeftAndRightPage() {
            $scope.hasText = false;
            $scope.hasImgUrl = false;
            if ($scope.docLen == 1) {
                $scope.visibleLeft = false;
                $scope.visibleRight = false;
            } else if ($scope.pageI == 0) {
                $scope.visibleLeft = false;
                $scope.visibleRight = true;
            } else if ($scope.pageI == $scope.docLen - 1) {
                $scope.visibleLeft = true;
                $scope.visibleRight = false;
            } else {
                $scope.visibleLeft = true;
                $scope.visibleRight = true;
            }
            hideChangeBtn();
        }

        var f = 0;

        function imgScale(imagebox, x, y, attr, tag) {
            $timeout(function () {
                var img = imagebox.image.resource.identifier + '?imageMogr2/thumbnail/!40p';
                if (imagebox.image.angle != 0) {
                    img += '/rotate/' + imagebox.image.angle
                }
                if(!imagebox.image.width&&!imagebox.image.height){}
                // var newImg = new Image();
                // newImg.crossOrigin = "Anonymous";
                // newImg.src = img;

                // newImg.onload = function () {
                //     newImg.onload = null;
                imagebox.image.width1= imagebox.image.width;
                imagebox.image.height1= imagebox.image.height;
                var width = imagebox.image.width;
                var height = imagebox.image.height;
                var wOrigin = angular.copy(width);
                var hOrigin = angular.copy(height);
                // 单页编辑占位框宽高
                var maskWidth = imagebox.geometry.width;
                var maskHight = imagebox.geometry.height;
                if ((wOrigin <= hOrigin || maskWidth <= maskHight) && (wOrigin / hOrigin < maskWidth / maskHight) || wOrigin >= hOrigin && maskWidth >= maskHight && (wOrigin / hOrigin < maskWidth / maskHight)) {
                    imagebox.adaptation = 'Width';
                    imagebox.image.width= imagebox.geometry.width;
                    imagebox.image.height= imagebox.geometry.width*height/width;
                    imagebox.image.offsetx= x;
                    if(imagebox.bokehtype=='None'&&tag == 'center'){
                        imagebox.image.offsety = -(imagebox.image.height-imagebox.geometry.height)/2;
                    }else{
                        imagebox.image.offsety= y;
                    }
                    imagebox.blurredrectangle = {
                        width: imagebox.geometry.height*width/height,
                        height: imagebox.geometry.height,
                        x: (imagebox.geometry.width - imagebox.geometry.height*width/height) / 2,
                        y: 0,
                    }
                }else {
                    imagebox.adaptation = 'Height';
                    imagebox.image.width= imagebox.geometry.height*width/height;
                    imagebox.image.height= imagebox.geometry.height;
                    imagebox.image.offsety= y;
                    imagebox.blurredrectangle = {
                        width: imagebox.geometry.width,
                        height: imagebox.geometry.width*height/width,
                        x: 0,
                        y: (imagebox.geometry.height - imagebox.geometry.width*height/width) / 2,
                    }
                    if(imagebox.bokehtype=='None'&&tag == 'center'){
                        imagebox.image.offsetx = -(imagebox.image.width-imagebox.geometry.width)/2;
                    }else{
                        imagebox.image.offsetx= x;
                    }
                }
                if(imagebox.bokehtype != 'None'){
                    imagebox.image.offsetx= 0;
                    imagebox.image.offsety= 0;
                    imagebox.image.scale= 1;
                }
                pxInsufficient(imagebox,vm.imgSize, vm.imgDpi);

                f++;
                if (f == vm.counts) {
                    setPageHistory();
                }
                $timeout(function () {
                    imagebox.loading = false;
                    // 拖拽进占位框时记录下历史纪录
                    if(attr){
                        updateHistorys()
                    }
                },400)
                // $scope.$digest();
                // };
            })
        }

        var boxW = 0, boxH = 0, picW = 0, picH = 0, scale = 0;
        function getDpi(box) {
            boxW = unitTransformPx(box.geometry.width);
            boxH = unitTransformPx(box.geometry.height);
            picW = box.image.width;
            picH = box.image.height;
            scale = box.image.scale;
            var x = 0, y = 0;
            // 宽度适配
            if (box.adaptation=='Width') {
                x = picW / (boxW / 25.4) / scale;
                y = boxH * picW / boxW / (boxH / 25.4) / scale;
            } else {
                x = boxW * picH / boxH / (boxW / 25.4) / scale;
                y = picH / (boxH / 25.4) / scale;
            }
            if (x < 150 || y < 150) {
                box.image.lowSize = true;
            } else {
                box.image.lowSize = false;
            }
        }

        function setPageHistory() {
            $(".save-page").addClass('hide1');
            $(".used-pic-mask").show();
            if (mb == 'FF') {
                $(".leftPanel-con li").addClass('mar-left-6');
            }
            //
            // var p = [];
            // angular.forEach($scope.document.pages, function (item, index) {
            //     p.push(item.arr);
            //     if (item.arr2) {
            //         p.push(item.arr2);
            //     }
            // });
            // $scope.originTemp = angular.copy($scope.template);
            //
            // $scope.history = [angular.copy($scope.template)];
            // $scope.pageHistory = [];
            //
            // angular.forEach($scope.template.pages.page, function (p, i) {
            //     if (!$scope.pageHistory[i]) {
            //         $scope.pageHistory[i] = {
            //             history: []
            //         }
            //     }
            //     $scope.pageHistory[i].history.push(angular.copy(p));
            //     $scope.pageHistory[i].currentHistoryIndex = 0;
            // })
            pageListDrag()
            pageListDrag1()
        }

        $scope.getPageWaHLar = function (slide, flag, marginFlag) {
            if (!slide) {
                return
            }

            if (!slide.arr) {
                return
            }

            if (flag) {
                var w = 0;
                if (!marginFlag) {
                    if (!slide.arr2) {
                        return
                    }

                    if (slide.arr2.isMaxWidth) {
                        if (vm.pattern != 'OTHER') {
                            w = $con.width() * vm.pageProp * 0.5 * slide.thumbnail2;
                        } else {
                            w = $con.width() * vm.pageProp;
                        }
                    } else {
                        if (vm.pattern != 'OTHER') {
                            w = ($scope.dHeight - heightDiffer) * slide.width2 / slide.height2 * slide.thumbnail2
                        } else {
                            w = ($scope.dHeight - heightDiffer) * slide.width2 / slide.height2
                        }
                    }
                    slide.pageW2 = w;
                    slide.arr2.pageScaleW = w / slide.width2;
                    slide.arr2.ratio = w / (slide.arr2.widthM / 25.4 * vm.windowDpiW);
                } else {
                    if (slide.arr.isMaxWidth) {
                        if (vm.pattern != 'OTHER') {
                            w = $con.width() * vm.pageProp * 0.5 * slide.thumbnail;
                        } else {
                            w = $con.width() * vm.pageProp;
                        }
                    } else {
                        if (vm.pattern != 'OTHER') {
                            w = ($scope.dHeight - heightDiffer) * slide.width / slide.height * slide.thumbnail
                        } else {
                            w = ($scope.dHeight - heightDiffer) * slide.width / slide.height
                        }
                    }

                    slide.pageW1 = w;
                    slide.arr.pageScaleW = w / slide.width;
                    // slide.arr.pageScaleW1 = slide.arr.pageScaleW;
                    slide.arr.ratio = w / (slide.arr.widthM / 25.4 * vm.windowDpiW);

                }
                return w;
            } else {
                var h = 0;
                if (!marginFlag) {
                    if (!slide.arr2) {
                        return
                    }

                    if (slide.arr2.isMaxWidth) {
                        if (vm.pattern != 'OTHER') {
                            h = $con.width() * vm.pageProp * 0.5 * slide.height2 * slide.thumbnail2 / slide.width2
                        } else {
                            h = $con.width() * vm.pageProp * slide.height2 / slide.width2
                        }
                    } else {
                        if (vm.pattern != 'OTHER') {
                            h = ($scope.dHeight - heightDiffer) * slide.thumbnail2
                        } else {
                            h = $scope.dHeight - heightDiffer
                        }
                    }

                    slide.pageH2 = h;
                    slide.arr2.pageScaleH = h / slide.height2;
                } else {
                    if (slide.arr.isMaxWidth) {
                        if (vm.pattern != 'OTHER') {
                            h = $con.width() * vm.pageProp * 0.5 * slide.height * slide.thumbnail / slide.width
                        } else {
                            h = $con.width() * vm.pageProp * slide.height / slide.width
                        }

                    } else {
                        if (vm.pattern != 'OTHER') {
                            h = ($scope.dHeight - heightDiffer) * slide.thumbnail
                        } else {
                            h = $scope.dHeight - heightDiffer
                        }
                    }

                    slide.pageH1 = h;
                    slide.arr.pageScaleH = h / slide.height;
                }
                return h;
            }
        };

        $scope.getLiW = function (slide) {
            // if ($scope.isMaxWidth) {
            //     slide.liW = $con.width() * vm.pageProp;
            // } else {
            //     var h = slide.arr2 ? slide.width2 / slide.height2 : slide.width / slide.height;
            //     slide.liW = ($scope.dHeight - heightDiffer) * (slide.width / slide.height + h);
            // }
            // return slide.liW;
        };

        // 点击显示 占位框中的 切换按钮
        $scope.isReplaceShow = true;
        $scope.replaceImage = function () {
            $timeout(function () {
                var box = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;
                if (!box.image.resource.identifier) {
                    MessageService.error("请先填充照片！")
                    return
                }

                if ($scope.isReplaceShow) {
                    $(".change-btn" + $scope.arrI).show();
                } else {
                    $(".change-btn" + $scope.arrI).hide();
                }
                $("#change-btn" + $scope.arrI + '' + $scope.pageI + '' + $scope.template.levelI).hide();
                $scope.isReplaceShow = !$scope.isReplaceShow;
            })
        };

        // 切换占位框图片
        $scope.changeMaskImage = function (e, index, i) {
            fn(e);
            // 需要切换的占位框
            var box0 = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;

            // 被切换的占位框
            var box = $scope.currentPage.levels.level[i].imagebox;
            var img0 = box0.image;
            var imgUrl0 = box0.imgUrl;
            box0.image = box.image;
            box.image = img0;
            box0.imgUrl = box.imgUrl;
            box.imgUrl = imgUrl0;
            box.image.angle = 0;
            box0.image.angle = 0;

            imgScale(box0, 0, 0, false, 'center');
            imgScale(box, 0, 0, false, 'center');
            hideChangeBtn();
        };

        // 透明度
        $scope.transparencySelect = function (attr) {
            var _attr = angular.copy(attr);
            toolbarStatus();
            if (!$scope.selectBox) {
                return
            }
            var current = $scope.selectBox.current;
            if (current == 'imageart' || current == 'textbox' || current == 'imagebox') {
                vm.isTransp = _attr;
            } else {
                MessageService.error("请选中一个素材或文字");
            }
            $scope.template.transparency = $scope.selectBox.transparency * 100;
            $(elem).val($scope.selectBox.transparency);
        }

        var elem = document.querySelector('input[type="range"]');

        var rangeValue = function () {
            $(".transparency-num").val(elem.value);
            $scope.template.transparency = elem.value * 100;
            var all = getAllCtrlKeyArray();
            if(all.length>0){
                levelMove(null, 'transparency')
            }else{
                $scope.selectBox.transparency = parseFloat(elem.value);
            }
            $scope.$digest();
        };

        elem.addEventListener("input", rangeValue);

        $scope.transparencyChange = function (attr) {
            if ($scope.template.transparency > 100) {
                MessageService.error("透明度有效范围：0~100");
                $scope.template.transparency = 100;
            }

            var all = getAllCtrlKeyArray();
            if(all.length>0){
                levelMove(null, 'transparency')
            }else{
                var level = $scope.currentPage.levels.level[$scope.template.levelI];
                $(elem).val($scope.template.transparency / 100);
                level[$scope.selectBox.current].transparency = $scope.template.transparency / 100;
            }
            updateHistorys();
        }


        $scope.coverageSelect = function (attr) {
            if ($scope.selectBox.lock) {
                return
            }
            toolbarStatus();
            var current = $scope.selectBox.current;
            if (current != 'page') {
                vm.isCoverage = attr;
            }
        }

        /**
         * 图层
         * @param attr----up,down,top,bottom
         */
        $scope.coverageMove = function (attr) {
            var levels = $scope.currentPage.levels.level;
            if (levels.length == 1) {
                return
            }

            var levelI = $scope.template.levelI;
            // 上移一层
            if (attr == 'up') {
                if (levels[levelI].seq == levels.length) {
                    MessageService.error("已在最上层")
                    return
                }
                var seq = angular.copy(levels[levelI].seq+1);
                levels.forEach(function (level) {
                    if(level.seq == seq){
                        level.seq--
                    }
                })
                levels[levelI].seq++;
            }

            // 下移一层
            if (attr == 'down') {
                if (levels[levelI].seq < 2) {
                    MessageService.error("已在最下层");
                    return
                }
                var seq = angular.copy(levels[levelI].seq-1);
                levels.forEach(function (level) {
                    if(level.seq == seq){
                        level.seq++
                    }
                })
                levels[levelI].seq--;
            }

            // 置顶
            if (attr == 'top') {
                if (levels[levelI].seq == levels.length) {
                    MessageService.error("已在最上层")
                    return
                }
                var seq = angular.copy(levels[levelI].seq);
                for (var i = 0; i < levels.length; i++) {
                    if(levels[i].seq > seq){
                        levels[i].seq--;
                    }
                }
                levels[levelI].seq = levels.length;
            }

            // 置底
            if (attr == 'bottom') {
                if (levels[levelI].seq < 2) {
                    MessageService.error("已在最下层");
                    return
                }
                var seq = angular.copy(levels[levelI].seq);
                for (var i = 0; i < levels.length; i++) {
                    if(levels[i].seq < seq){
                        levels[i].seq+=1;
                    }
                }
                levels[levelI].seq = 1;
            }
            var _seq = angular.copy(levels[levelI].seq);
            var _levelI = levels[levelI].seq - 1;
            levels.sort(compare('seq', true));
            for (var i = 0; i < levels.length; i++) {
                var item = levels[i].seq;
                if(item == _seq){
                    _levelI = i;
                    break
                }
            }
            $scope.template.levelI = _levelI;
        }

        // 锁定
        $scope.locking = function () {
            if ($scope.selectBox != undefined || $scope.selectBox != null) {
                $scope.selectBox.lock = !$scope.selectBox.lock;
            }

            // 文字模板不显示属性栏
            if ($scope.selectBox.current == 'textbox' && !$scope.selectBox.lock&&!$scope.selectBox.parentCurrent) {
                $scope.hasText = true
            }else{
                $scope.hasText = false
            }
        }


        // 删除
        $scope.deleteBox = function (e) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            var levels = $scope.currentPage.levels;
            var level = levels.level[$scope.template.levelI];

            if ($scope.selectBox == null) {
                return;
            }

            if ($scope.selectBox.current == 'imagebox') {
                if (level.imagebox.lock) {
                    return
                }
                for (var j = 0; j < $scope.photos.length; j++) {
                    var arr = $scope.photos[j];
                    if (arr.id == level.imagebox.image.resourceid) {
                        arr.usenum == 0 ? 0 : arr.usenum--;
                    }
                }
            }

            if ($scope.selectBox.current == 'textbox') {
                if (level.textbox.lock) {
                    return
                }
            }
            if ($scope.selectBox.current == 'imageart') {
                if (level.imageart.lock) {
                    return
                }
            }
            // levels.level[$scope.template.levelI].delete = true;
            levels.level.splice($scope.template.levelI, 1);

            for (var x = 0; x < levels.level.length; x++) {
                var l = levels.level[x];
                if (l.imagebox && l.imagebox.current) {
                    l.imagebox.levelI = x;
                }
                if (l.imageart && l.imageart.current) {
                    l.imageart.levelI = x;
                }
                if (l.textbox && l.textbox.current) {
                    l.textbox.levelI = x;
                }
                // if(l.textdocument&&l.textdocument.current){
                //     l.textdocument.levelI = x;
                // }
                l.seq = x + 1;
            }

            getUsedImageboxsNum();

            $scope.RightPanel = 'page';
            $scope.selectBox = {};
            updateHistorys();
        };


        vm.fullFlag = false;
        $scope.toggleFullScreen = function () {
            if (!vm.fullFlag) {
                //进入全屏
                var de = document.documentElement;
                if (de.requestFullscreen) {
                    de.requestFullscreen();
                } else if (de.mozRequestFullScreen) {
                    de.mozRequestFullScreen();
                } else if (de.webkitRequestFullScreen) {
                    de.webkitRequestFullScreen();
                }
                vm.fullFlag = true;
            } else {
                //退出全屏
                var de = document;
                if (de.exitFullscreen) {
                    de.exitFullscreen();
                } else if (de.mozCancelFullScreen) {
                    de.mozCancelFullScreen();
                } else if (de.webkitCancelFullScreen) {
                    de.webkitCancelFullScreen();
                }
                vm.fullFlag = false;
            }

        }


        vm.leftShow = true;
        vm.rightShow = true;
        $scope.hidePanel = function (attr) {
            if (attr == 1) {
                // 左边菜单栏
                $(".leftPanel-mainContent").toggleClass('hide1');
                vm.leftShow = !vm.leftShow;

            } else {
                // 右菜单栏
                // $(".rightPanel-2").toggleClass('hide1');
                vm.rightShow = !vm.rightShow;
                // $(".rightPanel-mainContent-toggle").toggleClass('rightPanel-toggle');
                // $(".rightPanel-bottom").toggleClass('bottom-100');
            }
            removeAllClass();
            if (vm.leftShow) {
                if (vm.rightShow) {
                    // 左边显示，右边显示
                    $(".centerPanel").addClass('hidePanel-leftShow-rightShow');
                    $(".rightPanel-mainContent-toggle").removeClass('rightPanel-mainContent-toggle2');
                } else {
                    // 左边显示，右边隐藏
                    $(".centerPanel").addClass('hidePanel-leftShow-rightHide');
                    $(".rightPanel-mainContent-toggle").addClass('rightPanel-mainContent-toggle2');
                    // $(".page-scale").addClass('page-scale-52');

                }
            } else {
                if (vm.rightShow) {
                    // 左边隐藏，右边显示
                    $(".centerPanel").addClass('hidePanel-leftHide-rightShow');
                    $(".rightPanel-mainContent-toggle").removeClass('rightPanel-mainContent-toggle2');
                    // $(".page-scale").addClass('page-scale-39');
                } else {
                    // 左边隐藏，右边隐藏
                    $(".centerPanel").addClass('hidePanel-leftHide-rightHide');
                    $(".rightPanel-mainContent-toggle").addClass('rightPanel-mainContent-toggle2');
                    // $(".page-scale").addClass('page-scale-46');
                }
            }

            resizeWindow();

            function removeAllClass() {
                $(".centerPanel").removeClass('hidePanel-leftShow-rightShow').removeClass('hidePanel-leftHide-rightShow').removeClass('hidePanel-leftShow-rightHide').removeClass('hidePanel-leftHide-rightHide');
            }
        }

        /**
         *
         * 自定义元素右键菜单样式  复制、剪切、删除
         *
         */
        document.onkeydown = function (event) {
            if (vm.textFont) {
                return
            }
            if(event.keyCode == 13){
                if(vm.isImageartTrim){
                    $scope.imageartTrimTrueClick();
                }
                if(vm.isImageboxTrim){
                    $scope.imageboxTrueClick();
                }
                if($scope.showBg){
                    $scope.showBg = false;
                }
                $scope.$apply();
                return false;
            }
            $timeout(function () {
                if (event.keyCode == 27) {
                    if (vm.fullFlag) {
                        vm.fullFlag = false;
                    }
                    $scope.showBg = false;
                    vm.isImageartTrim = false;
                    $scope.imageboxFalseClick();
                }
                // 删除
                if (event.keyCode == 46) {
                    $scope.deleteBox();
                    $scope.$digest();
                }
                if(event.ctrlKey){
                    if(event.keyCode == 67){
                        // vm.previousPage = angular.copy($scope.currentPage);
                        // vm.previousPageI = angular.copy($scope.pageI);
                        if(vm.CtrlKeyArray.imageboxes.length||vm.CtrlKeyArray.imagearts.length||vm.CtrlKeyArray.textboxes.length){
                            vm.copyArray = angular.copy(vm.CtrlKeyArray);
                            vm.copyBox = null;
                        }else{
                            vm.copyBox = angular.copy($scope.selectBox);
                            vm.copyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: []};
                        }
                        // alert("你使用了Ctrl+C组合件功能！");
                    }
                    if(event.keyCode == 86){
                        // alert("你使用了Ctrl+V组合件功能！");
                        var currentPage = $scope.currentPage;
                        if(vm.copyArray.imageboxes.length||vm.copyArray.imagearts.length||vm.copyArray.textboxes.length){
                            copyTogether($scope.document.pages[$scope.pageI],currentPage);
                        }else{
                            $scope.copyimg(event);
                        }
                        $scope.$digest();
                        return;
                    }
                }

                if(event.ctrlKey == true || event.metaKey == true){
                    if($scope.currentPage.levels.level[$scope.template.levelI] && $scope.currentPage.levels.level[$scope.template.levelI].imagebox && !$scope.currentPage.levels.level[$scope.template.levelI].imagebox.ctrlKey){
                        $scope.selectImg(event, $scope.template.levelI, $scope.arrI, $scope.pageI, $scope.currentPage.levels.level[$scope.template.levelI].imagebox)
                    }
                    if($scope.currentPage.levels.level[$scope.template.levelI] && $scope.currentPage.levels.level[$scope.template.levelI].imageart && !$scope.currentPage.levels.level[$scope.template.levelI].imageart.ctrlKey){
                        $scope.selectArt(event, $scope.template.levelI, $scope.arrI, $scope.pageI, $scope.currentPage.levels.level[$scope.template.levelI].imageart)
                    }
                    if($scope.currentPage.levels.level[$scope.template.levelI] && $scope.currentPage.levels.level[$scope.template.levelI].textbox && !$scope.currentPage.levels.level[$scope.template.levelI].textbox.ctrlKey){
                        $scope.selectText(event, $scope.template.levelI, $scope.currentPage.levels.level[$scope.template.levelI].textbox, $scope.arrI)
                    }
                }
                // 上
                if (event.keyCode == 38) {
                    keycodeArrow(38);
                }
                // 下
                if (event.keyCode == 40) {
                    keycodeArrow(40);

                }
                // 左
                if (event.keyCode == 37) {
                    keycodeArrow(37);
                }
                // 右
                if (event.keyCode == 39) {
                    keycodeArrow(39);
                }
            })
            event.returnvalue = false;
        };
        document.onkeyup = function(event){
            $timeout(function () {
                vm.ctrlKey = false;
            })
        }

        function keycodeArrow(keyCode) {
            var currentPage = $scope.currentPage;
            var levels = $scope.currentPage.levels;
            var level = levels.level[$scope.template.levelI];

            var box = [];
            if ($scope.selectBox.current == 'imagebox') {
                box = level.imagebox;
            }
            if ($scope.selectBox.current == 'textbox') {
                box = level.textbox;
            }
            if ($scope.selectBox.current == 'imageart') {
                box = level.imageart;
            }
            if ($scope.selectBox.current == 'textdocument') {
                box = level.textdocument;
            }
            if (box.lock || box.movable==false) {
                return
            }

            // 上
            if (keyCode == 38 && box.geometry.y > 0) {
                box.geometry.y--;
            }

            // 下
            if (keyCode == 40 && box.geometry.y < currentPage.trimbox.height - box.geometry.height) {
                box.geometry.y++;

            }
            // 左
            if (keyCode == 37 && box.geometry.x > 0) {
                box.geometry.x--;
            }
            // 右
            if (keyCode == 39 && box.geometry.x < currentPage.trimbox.width - box.geometry.width) {
                box.geometry.x++;
            }
            updateHistorys();
            $scope.$apply();
        }

        $scope.copyClick = function(e){
            vm.copyBox = null;
            vm.copyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: []};
            vm.CtrlKeyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: []};
            if (!$scope.selectBox) {
                MessageService.error("复制失败，请选中页面元素！");
                return
            }
            $scope.copyimg(e);
        }
        //复制
        $scope.copyimg = function (e) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            var all = getAllCtrlKeyArray();
            if(all.length>0){
                all.forEach(function (item) {
                    (function (item1) {
                        copyimg1(item1)
                    })(item)
                })
                updateHistorys();
            }else{
                copyimg1($scope.selectBox, vm.copyBox);
                updateHistorys();
            }
        };

        function copyimg1(selectBox, copyBox) {
            var currentPage = $scope.currentPage;
            var currentLevel = addLevel(currentPage);

            //点击后选择复制按钮
            var chooseBox = angular.copy(selectBox);
            // ctrl+c
            if(copyBox){
                chooseBox = angular.copy(copyBox);
            }

            if (chooseBox.current == 'imagebox') {
                currentLevel.imagebox = copyDetail(chooseBox);
                for (var j = 0; j < $scope.photos.length; j++) {
                    var arr = $scope.photos[j];
                    if (arr.id == chooseBox.image.resourceid) {
                        arr.usenum++;
                    }
                }
            }
            if (chooseBox.current == 'textbox') {
                currentLevel.textbox = copyDetail(chooseBox);

            }
            if (chooseBox.current == 'imageart') {
                currentLevel.imageart = copyDetail(chooseBox);
            }
            if (chooseBox.current == 'code') {
                currentLevel.code = copyDetail(chooseBox);
            }

            $scope.currentPage = currentPage;
        }
        function copyDetail(box) {
            box.levelI = $scope.currentPage.levels.level.length-1;
            box.pageI = $scope.pageI;
            box.lock = false;

            var page = $scope.document.pages[$scope.pageI];
            var proW = $scope.currentPage.pageScaleW * page.scale;
            var proH = $scope.currentPage.pageScaleH * page.scale;
            var slideW = $(".slide" +$scope.arrI + $scope.pageI).width();
            var slideH = $(".slide" +$scope.arrI+ $scope.pageI).height();

            var x = unitTransformPx((slideW - box.geometry.width * proW)/2/proW)+$scope.template.levelI;
            var y = unitTransformPx((slideH - box.geometry.width * proH)/2/proH)+$scope.template.levelI;
            box.geometry.x = unitTransform(x);
            box.geometry.y = unitTransform(y);
            resetCtrlKeyArray();
            return box;
        }

        function copyTogether(page,currentPage){
            angular.forEach(vm.copyArray.imageboxes, function (imagebox, i) {
                if(imagebox&&imagebox.geometry){
                    // 当前页所有占位框
                    var box = angular.copy(imagebox);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.imagebox = copyDetail(box);
                    for (var j = 0; j < $scope.photos.length; j++) {
                        var arr = $scope.photos[j];
                        if (arr.id == box.image.resourceid) {
                            arr.usenum++;
                        }
                    }
                }
            })

            angular.forEach(vm.copyArray.imagearts, function (imageart, i) {
                if(imageart&&imageart.geometry){
                    // 当前页所有素材
                    var box = angular.copy(imageart);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.imageart = copyDetail(box);
                }

            })

            angular.forEach(vm.copyArray.textboxes, function (textbox, i) {
                if(textbox&&textbox.geometry){
                    // 当前页所有文字
                    var box = angular.copy(textbox);
                    var currentLevel = addLevel(currentPage);
                    currentLevel.textbox = copyDetail(box);
                }
            })
        }

        /***************页面处理************end*******************/


        /*******************照片处理***********start*********************/

        // 背景图和素材的上传
        $scope.uploadVerify = function(){
            uploadVerify(null,CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                $("#uploadImg2").trigger("click");
            }, function () {
                getStorageBuy()
            })
        };

        window.updatePic = function ($event) {
            $timeout(function () {
                vm.picLoading = true;
                var len = $scope.photos.length;
                repeatCount = 0;
                var files = [];
                var higherSize = [];
                bigSizePics = [];
                for (var i = 0; i < $event.target.files.length; i++) {
                    var f = $event.target.files[i];
                    f.imageSize = Math.ceil(f.size/1024/1024);
                    files.push(f)
                }
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    if (file.size>=20*1024*1024 ){
                        file.imageSize= Math.ceil(file.size/1024/1024);
                        bigSizePics.push(file);
                    }else{
                        getBgImg(files, i, len);
                        if (bigSizePics.length==0 && i == (files.length-1)) {
                            vm.picLoading = false;
                            setTimeout(function () {
                                angular.forEach($scope.photos, function (item, j) {
                                    item.index = j;
                                });
                            }, 400)
                        }
                    }
                }
                if (bigSizePics.length >0) {
                    thumbnailatorPic();
                }
                $event.srcElement.value = ""
            })
        };
        var repeatCount = 0;
        var bigSizePics = [];
        function getBgImg(files, i, len) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";
            ImageService.backendUploadPics(files[i],$scope.domain, vm.token,function (data,f) {
                Gallery.upload({
                    identifiers: data.key,
                    provider: "QINIU",
                    fileName: f.name,
                    imgSize: f.size,
                    folderId: vm.folderId ? vm.folderId : ''
                }, function (resp) {
                    var arr = resp;
                    arr.bgImg = $scope.domain + arr.identifier;
                    arr.usenum = 0;
                    $scope.photos.unshift(arr);
                    vm.totalPhotoItems++

                }, function () {
                    vm.picLoading = false;
                    MessageService.error("上传失败");
                });
            },function (res) {
                vm.picLoading = false;

            })
        }

        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics=[];
                if(values){
                    for (var j = 0; j < values.length; j++) {
                        var value = values[j];
                        var data = {key:value.key};
                        Gallery.upload({
                            identifiers: data.key,
                            provider: "QINIU",
                            fileName: value.name,
                            imgSize: value.size,
                            width: value.width,
                            height: value.height,
                            folderId: vm.folderId ? vm.folderId : ''
                        }, function (resp) {
                            var arr = resp;
                            arr.bgImg = $scope.domain + arr.identifier;
                            arr.usenum = 0;
                            $scope.photos.unshift(arr);
                            vm.totalPhotoItems++
                        }, function () {
                            vm.picLoading = false;
                            MessageService.error("上传失败");
                        });
                    }
                    vm.picLoading = false;
                    setTimeout(function () {
                        angular.forEach($scope.photos, function (item, z) {
                            item.index = z;
                        });
                    }, 400)
                }
            }, function(){
                vm.picLoading = false;
            });
        }

        $scope.switchBgTab = function (attr) {
            vm.photosTab = attr;
            if(vm.photosTab!='all'){
                vm.unUsedImageboxsNum = 0;
                angular.forEach($scope.photos,function (p) {
                    if(p.usenum==0){
                        vm.unUsedImageboxsNum++;
                    }
                })
            }

            setTimeout(function () {
                waterFallPic('main5');
                $(".photo-imgs").height($scope.photoHight);
            }, 200);
        }

        function loadAll(folderId) {
            vm.photoLoadding = true;
            var sort = ["id,desc"];
            if(vm.photoSort == 1){
                sort = ["id,desc"]
            }else if(vm.photoSort == 2){
                sort = ["orderBySeq,desc","name,desc"]
            }else if(vm.photoSort == 3){
                sort = ["orderBySeq,asc","name,asc"]
            }
            if (folderId == 'isNull') {
                $scope.selectFolder = '已传照片';
                Gallery.getPage({
                    items: [{key: "folderId", op: "isNull", value: 1}],
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            } else {
                Gallery.getPage({
                    items: [{key: "folderId", op: "=", value: folderId}],
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            }
        }

        function loadFolders() {
            Folder.getAllByCondition({
                items: [{key: "type", op: "=", value: "Gallery"}],
                sort: ["id,asc"]
            }, function (resp) {
                $scope.Folders = resp;
            })
        }

        // 增加文件夹
        $scope.addFolder = function () {
            createModal('app/entities/page/pagesmySuffix-create.html', 'PageMySuffixCreateController').result.then(
                function (data) {
                    Folder.save({
                        name: data,
                        type: 'Gallery'
                    }, function (resp) {
                        $scope.Folders.push(resp);
                    })
                });
        }

        $scope.deleteShow = function (ids) {
            vm.folderIds=ids;
            vm.deleteSure = true;
        }

        //删除多个文件夹
        $scope.deleteFolders = function () {
            vm.deleteSure = false;
            Folder.batchDelete({ids: vm.folderIds}, function () {
                loadFolders();
                $scope.folderOne = null;
                vm.indexNum = 0;
                vm.galleryPage = [];
                $scope.photos = [];
                loadAll('isNull');
                MessageService.success("删除成功");
            }, function () {
                MessageService.error("删除失败");
            });
        };

        // 进入某一文件夹中
        $scope.showFolderContent = function (folder) {
            $scope.gallaryPic = [];
            $scope.folderOne = angular.copy(folder);
            vm.indexNum = 0;
            vm.galleryPage = [];
            $scope.photos = [];
            vm.folderId = folder ? folder.id : '';
            if (folder) {
                loadAll(folder.id);
            } else {
                loadAll('isNull');
            }

        }

        vm.qrcodeShow = false;
        vm.fid = 0;
        var qrcode = null;
        var phonetimer = null;
        var newCreateDate = 0;
        $scope.uploadByPhone = function () {
            uploadVerify(null,CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                vm.qrcodeShow = !vm.qrcodeShow;
                if (qrcode != null) {
                    // qrcode.clear();
                    $('#qrcode1').html("");
                }

                //$scope.folderOne -----某一文件夹信息
                if ($scope.folderOne == undefined || $scope.folderOne == null) {
                    vm.fid = 0;
                } else {
                    vm.fid = $scope.folderOne.id;
                }

                // 手机传图二维码
                qrcode = $('#qrcode1').qrcode({
                    render: "canvas",
                    width: 150,
                    height: 150,
                    text: '' + vm.platformSetting.storeNotifyUrl + "/#/" + vm.storeId + "/" + vm.fid + "/gallery/" + vm.userId
                });
                // (new Date("2012/12/25 20:11:11")).getTime()

                if (vm.qrcodeShow) {
                    phonetimer = setInterval(function () {
                        vm.temp = $scope.photos.map(function (item) {
                            return item.identifier;
                        });

                        var gallery_items = [{key: "storeId", op: "=", value: vm.storeId}];
                        if ($scope.folderOne) {
                            gallery_items.push({key: "folderId", op: "=", value: $scope.folderOne.id});
                        } else {
                            gallery_items.push({key: "folderId", op: "isNull", value: 1});
                        }
                        var sort = ["createdDate,desc"];
                        if(vm.photoSort == 1){
                            sort = ["createdDate,desc"]
                        }else if(vm.photoSort == 2){
                            sort = ["orderBySeq,desc","name,desc"]
                        }else if(vm.photoSort == 3){
                            sort = ["orderBySeq,asc","name,asc"]
                        }

                        Gallery.getPage({
                            items: gallery_items,
                            page: 0,
                            size: vm.itemsPerPage,
                            sort: sort
                        }, onSuccessToPhone, onError);
                    }, 3000);
                } else {
                    clearInterval(phonetimer);
                    phonetimer = null;
                    setTimeout(function () {
                        waterFallPic('main5');
                        $(".photo-imgs").height($scope.photoHight);
                    }, 600);
                }
            }, function () {
                getStorageBuy()
            })
        }

        $scope.getStorageBuy = getStorageBuy;
        function getStorageBuy(){
            $uibModal.open({
                templateUrl: "app/entities/web-gallery/storage-buy.html",
                controller: "StorageBuyController",
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                }
            }).result.then(function() {
                getByCustomer();
            })
        }
        function onSuccessToPhone(data) {
            if (data == null || data.length == 0) {
                return;
            }
            var loadCreateDate = new Date(data[0].createdDate).getTime();
            for (var i = 0; i < data.length; i++) {
                var d = data[i];
                if(vm.temp.indexOf(d.identifier)<0){
                    if (new Date(d.createdDate).getTime() > newCreateDate) {
                        d.bgImg = $scope.domain + d.identifier;
                        d.select = false;
                        d.index = i;
                        $scope.photos.unshift(d);
                    } else {
                        newCreateDate = loadCreateDate;
                        break;
                    }
                }
            }
            if (newCreateDate == 0) {
                newCreateDate = loadCreateDate;
            }
        }

        /* =========================================
         左边侧边栏拖动事件
         =========================================== */
        var content = $(".page-center")[0];
        content.ondragenter = ignoreDrag;
        // 拖动文件的鼠标指针位置放置区之上时发生
        content.ondragover = ignoreDrag;
        content.ondrop = drop;

        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
            var dataset = e.target.dataset;
            if (e.target.nodeName == 'IMG'&&dataset.imgindex) {
                var textIndex = dataset.imgindex || $scope.template.levelI;
                $scope.template.levelI = textIndex;
                $scope.pageI = dataset.docindex;
                $scope.arrI = dataset.arrindex;
                $(".imgBoxes").removeClass('selected-blue')
                $(".imgBoxes"+$scope.pageI+$scope.arrI+$scope.template.levelI).addClass('selected-blue');
            }else{
                $(".imgBoxes").removeClass('selected-blue')
            }

        }

        function drop(e) {
            ignoreDrag(e);
            var data = e.dataTransfer.getData("img");
            var dataIndex = $('#' + data)[0].getAttribute('data-index');
            //  判断拖放的位置
            var page = $scope.document.pages[$scope.pageI];
            // 320 + 30 左边侧边栏+预测宽度
            var isArr2 = e.clientX - 350 - (parseFloat($(".page-center").css('left')) + parseFloat($(".page-center").css('margin-left'))) - (parseFloat($(".web" + $scope.pageI).css('margin-left')) || 0) > $(".slide1" + $scope.pageI).width();

            var rect = $(".page-center")[0].getBoundingClientRect();

            var offsetX= e.clientX - 320 - (parseFloat($(".page-center").css('left')) + parseFloat($(".page-center").css('margin-left'))) - (parseFloat($(".web" + $scope.pageI).css('margin-left')));
            var offsetY= e.clientY - rect.y - (parseFloat($(".web" + $scope.pageI).css('top')));

            // 判断是否拖动到右边page
            if (page.arr2&&isArr2 && !$scope.currentPage.spread && vm.pattern != 'OTHER') {
                $scope.arrI = 2;
                $scope.currentPage = page.arr2;
                offsetX = offsetX-$(".slide1" + $scope.pageI).width();
            } else {
                $scope.arrI = 1;
                $scope.currentPage = page.arr;
            }
            if (data.indexOf('dragtargetFrameConfig') != '-1') {
                var item = $('#' + data).attr('data-item');
                var imgindex = e.target.dataset.imgindex;
                if(imgindex == null){
                    var borderList = vm.borderList[$('#' + data).attr('data-index')];
                    $http({url: borderList.img + '?imageInfo'}).success(function (data) {
                        var imgZoom = 1;
                        if (data.width > data.height) {
                            imgZoom = 300 / data.width
                        } else {
                            imgZoom = 300 / data.height
                        }
                        var _w2 = (imgZoom * data.width) / (1 + borderList.marginLeft / 100 + borderList.marginRight / 100);
                        var _h2 = (imgZoom * data.height) / (1 + borderList.marginBottom / 100 + borderList.marginTop / 100);
                        var _level = {
                            imagebox:{},
                            seq: $scope.currentPage.levels.level.length + Number(1)
                        }
                        var _imagebox = {
                            blurredrectangle:null,
                            bokehtype:$scope.productBokehType || 'None',
                            geometry:{
                                width: _w2,
                                height: _h2,
                                x:($scope.currentPage.trimbox.width - _w2) / 2 || 0,
                                y:($scope.currentPage.trimbox.height - _h2) / 2 || 0
                            },
                            image: {
                                resource: {
                                    identifier: item.identifier,
                                    provider: 'qiniu'
                                },
                                filterResource: {
                                    identifier: '',
                                    provider: 'qiniu'
                                },
                                width: item.width,
                                height: item.height,
                                imgSize: item.imgSize,
                                index: item.seq,
                                parameter: angular.fromJson(item.parameter),
                                scale: 1,
                                angle: 0
                            },
                            mask: {
                                resource:{
                                    identifier: '',
                                    provider: '',
                                }
                            },
                            rotation:{
                                angle: 0
                            },

                            border: {
                                shape: borderList.shape || 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0
                                },
                                "edge": {
                                    top: borderList.marginTop,
                                    right: borderList.marginRight,
                                    bottom: borderList.marginBottom,
                                    left: borderList.marginLeft
                                },
                                lineWidth: 0,
                                type: "Pic",
                                resource: {
                                    "identifier": borderList.img
                                },
                                color: '',
                            },
                            shadow:{
                                color:'#777777',
                                x:0,
                                y:0,
                                blur:0
                            },
                            useradded: true
                        }
                        _level.imagebox = _imagebox;
                        $scope.currentPage.levels.level.push(_level);
                    })
                }else {
                    vm.levelIndex = imgindex;
                    var borderList = vm.borderList[$('#' + data).attr('data-index')];
                    $scope.currentPage.levels.level[vm.levelIndex].imagebox.border = {
                        shape: borderList.shape || 'Rectangle',
                        radius: {
                            lt: 0,
                            rt: 0,
                            rb: 0,
                            lb: 0
                        },
                        "edge": {
                            top: borderList.marginTop,
                            right: borderList.marginRight,
                            bottom: borderList.marginBottom,
                            left: borderList.marginLeft
                        },
                        lineWidth: 0,
                        type: "Pic",
                        resource: {
                            "identifier": borderList.img
                        },
                        color: '',
                    }
                    $scope.currentPage.levels.level[vm.levelIndex].imagebox.shadow = {
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    }
                }
            }

            //拖拽图片进入占位框
            if (data.indexOf('dragtargetPhoto') != '-1') {
                $(".imgBoxes").removeClass('selected-blue')
                var identifier = $('#' + data)[0].getAttribute('data-photo');
                var docIndex = e.target.dataset.docindex;
                var arrIndex = e.target.dataset.arrindex;
                var imgIndex = e.target.dataset.imgindex;
                if (docIndex == null || arrIndex == null || imgIndex == null) {
                    s1 = $('#' + data).attr('data-mask');
                    var mask = {
                        identifier: null,
                        provider: 'qiniu',
                        resource: {
                            identifier: null,
                            provider: 'qiniu'
                        }
                    }
                    $scope.addPlacement(e, mask, 'add',offsetX,offsetY, $scope.domain + identifier, function () {
                        $scope.hasImgUrl = true;
                        if ($scope.gallaryPic.length <= 1) {
                            var image = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;
                            // 当 和占位框中的不是同一张图片
                            if (image.image.resourceid != $scope.photos[dataIndex].id) {
                                if($scope.photos[dataIndex].imageInfo){
                                    image.image.imageInfo = $scope.photos[dataIndex].imageInfo;
                                    addImg(image.image.imageInfo)
                                }else{
                                    $.ajax({
                                        type: "GET",
                                        url: $scope.domain + identifier + "?imageInfo",
                                        success: function (va) {
                                            image.image.imageInfo = va;
                                            addImg(image.image.imageInfo)
                                        }
                                    })
                                }
                                function addImg(imageInfo) {
                                    for (var j = 0; j < $scope.photos.length; j++) {
                                        var arr = $scope.photos[j];
                                        if (arr.id == image.image.resourceid) {
                                            arr.usenum == 0 ? 0 : arr.usenum--;
                                        }
                                    }
                                    image.flip = orientationInit1(imageInfo.orientation).flip;
                                    $scope.photos[dataIndex].width = orientationInit(imageInfo).width;
                                    $scope.photos[dataIndex].height = orientationInit(imageInfo).height;
                                    // 使用次数
                                    $scope.photos[dataIndex].usenum = $scope.photos[dataIndex].usenum - 0 + 1;
                                    image.image.index = dataIndex;
                                    image.image.resourceid = $scope.photos[dataIndex].id;
                                    image.image.width1 = $scope.photos[dataIndex].width;
                                    image.image.imageInfo = $scope.photos[dataIndex].imageInfo;
                                    image.image.width = $scope.photos[dataIndex].width;
                                    image.image.height1 = $scope.photos[dataIndex].height;
                                    image.image.height = $scope.photos[dataIndex].height;
                                    image.image.offsetx = 0;
                                    image.image.offsety = 0;
                                    image.image.imgsize = $scope.photos[dataIndex].imgsize;
                                    image.image.angle = 0;
                                    image.image.angle = orientationInit1(imageInfo.orientation).angle;
                                    image.image.scale = 1;
                                    image.bokehtype = $scope.productBokehType || 'None';
                                    image.transparency = image.transparency>=0 ? image.transparency : 1;
                                    image.imgUrl = $scope.domain + identifier + '?imageMogr2/thumbnail/!40p';
                                    image.image.resource = {
                                        identifier: $scope.domain + identifier,
                                        provider: 'qiniu',
                                        width:$scope.photos[dataIndex].width,
                                        height:$scope.photos[dataIndex].height,
                                    }

                                    if ($scope.productBokeh) {
                                        image.blurredrectangle = {
                                            x: 0,
                                            y: 0,
                                            width: image.geometry.width,
                                            height: image.geometry.height
                                        }
                                    }
                                    image.loading = true;

                                    imgScale(image, image.image.offsetx, image.image.offsety, true, 'center');
                                    getUsedImageboxsNum();
                                }
                            }
                        }
                        setTimeout(function () {
                            waterFallPic('main5');
                            $(".photo-imgs").height($scope.photoHight);
                        }, 200);
                    });
                    // return
                } else {
                    $scope.pageI = docIndex;
                    $scope.arrI = arrIndex;
                    $scope.template.levelI = imgIndex;
                    var page = $scope.document.pages[docIndex];
                    var arr = page.arr;
                    if (arrIndex == 2) {
                        arr = page.arr2;
                    }
                    $scope.currentPage = arr;
                    $scope.hasImgUrl = true;
                    if ($scope.gallaryPic.length <= 1) {
                        var image = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;
                        // 当 和占位框中的不是同一张图片
                        if (image.image.resourceid != $scope.photos[dataIndex].id) {
                            if($scope.photos[dataIndex].imageInfo){
                                image.image.imageInfo = $scope.photos[dataIndex].imageInfo;
                                addImg1(image.image.imageInfo)
                            }else{
                                $.ajax({
                                    type: "GET",
                                    url: $scope.domain + identifier + "?imageInfo",
                                    success: function (va) {
                                        image.image.imageInfo = va;
                                        addImg1(image.image.imageInfo)
                                    }
                                })
                            }
                            function addImg1(imageInfo) {
                                for (var j = 0; j < $scope.photos.length; j++) {
                                    var arr = $scope.photos[j];
                                    if (arr.id == image.image.resourceid) {
                                        arr.usenum == 0 ? 0 : arr.usenum--;
                                    }
                                }
                                image.flip = orientationInit1(imageInfo.orientation).flip;
                                $scope.photos[dataIndex].width = orientationInit(imageInfo).width;
                                $scope.photos[dataIndex].height = orientationInit(imageInfo).height;
                                // 使用次数
                                $scope.photos[dataIndex].usenum = $scope.photos[dataIndex].usenum - 0 + 1;
                                image.image.index = dataIndex;
                                image.image.resourceid = $scope.photos[dataIndex].id;
                                image.image.width1 = $scope.photos[dataIndex].width;
                                image.image.imageInfo = $scope.photos[dataIndex].imageInfo;
                                image.image.width = $scope.photos[dataIndex].width;
                                image.image.height1 = $scope.photos[dataIndex].height;
                                image.image.height = $scope.photos[dataIndex].height;
                                image.image.offsetx = 0;
                                image.image.offsety = 0;
                                image.image.imgsize = $scope.photos[dataIndex].imgsize;
                                image.image.angle = 0;
                                image.image.angle = orientationInit1(imageInfo.orientation).angle;
                                image.image.scale = 1;
                                image.bokehtype = $scope.productBokehType || 'None';
                                image.transparency = image.transparency>=0 ? image.transparency : 1;
                                image.imgUrl = $scope.domain + identifier + '?imageMogr2/thumbnail/!40p';
                                image.image.resource = {
                                    identifier: $scope.domain + identifier,
                                    provider: 'qiniu',
                                    width:$scope.photos[dataIndex].width,
                                    height:$scope.photos[dataIndex].height,
                                }

                                if ($scope.productBokeh) {
                                    image.blurredrectangle = {
                                        x: 0,
                                        y: 0,
                                        width: image.geometry.width,
                                        height: image.geometry.height
                                    }
                                }
                                image.loading = true;

                                imgScale(image, image.image.offsetx, image.image.offsety, true, 'center');
                                getUsedImageboxsNum();
                            }
                        }
                    }
                    setTimeout(function () {
                        waterFallPic('main5');
                        $(".photo-imgs").height($scope.photoHight);
                    }, 200);
                }

                return
            }
            // 判读是否是占位框
            if (data.indexOf('dragtargetMask') != '-1') {
                $(".imgBoxes").removeClass('selected-blue')
                var docIndex = e.target.dataset.docindex;
                var arrIndex = e.target.dataset.arrindex;
                var imgIndex = e.target.dataset.imgindex;
                s1 = $('#' + data).attr('data-mask');
                var mask = {
                    identifier: s1,
                    provider: 'qiniu',
                    resource: {
                        identifier: s1,
                        provider: 'qiniu'
                    }
                }
                if (docIndex == null || arrIndex == null || imgIndex == null){
                    if (data.indexOf('Round') > '-1') {
                        addPlacement1(e, mask,false,offsetX,offsetY, s1, 'Ellipse');
                    } else if (data.indexOf('Rectangle') > '-1') {
                        addPlacement1(e, mask,false,offsetX,offsetY, s1, 'Rectangle');
                    }else{
                        $scope.addPlacement(e, mask,false,offsetX,offsetY, s1);
                    }
                }else{
                    $scope.pageI = docIndex;
                    $scope.arrI = arrIndex;
                    $scope.template.levelI = imgIndex;
                    var page = $scope.document.pages[docIndex];
                    var arr = page.arr;
                    if (arrIndex == 2) {
                        arr = page.arr2;
                    }
                    $scope.currentPage = arr;
                    var image = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;

                    image.mask = mask;
                    image.maskUrl = mask.resource.identifier;
                    if (data.indexOf('Round') > '-1') {
                        image.border= {
                            shape: 'Ellipse',
                            radius: {
                                lt: 0,
                                rt: 0,
                                rb: 0,
                                lb: 0,
                                all: 0
                            },
                            "edge": {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                            lineWidth: 0,
                            type: "Color",
                            lineType:"solid",
                            resource: {
                                "identifier": ''
                            },
                            color: '#000000',
                        };
                        image.shadow ={
                            color:'#777777',
                            x:0,
                            y:0,
                            blur:0
                        }
                    } else if (data.indexOf('Rectangle') > '-1') {
                        image.border= {
                            shape: 'Rectangle',
                            radius: {
                                lt: 0,
                                rt: 0,
                                rb: 0,
                                lb: 0,
                                all: 0
                            },
                            "edge": {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                            lineWidth: 0,
                            type: "Color",
                            lineType:"solid",
                            resource: {
                                "identifier": ''
                            },
                            color: '#000000',
                        };
                        image.shadow ={
                            color:'#777777',
                            x:0,
                            y:0,
                            blur:0
                        }
                    }else{
                        // image.border = null;
                    }
                }
                $scope.$digest();
            }


            // 模板页切换
            if (data.indexOf('dragtargetTemp') != '-1') {
                $scope.changeTemp(dataIndex, $scope.pageAllMm.page[dataIndex]);
                return
            }

            var s = $('#' + data)[0].src;
            var mask = $('#' + data)[0].getAttribute('data-mask');
            var s1 = s.split(/\//)[3];

            // 替换背景图片
            if (data.indexOf('dragtargetBgImg') != '-1') {
                $scope.chooseBgimg(mask, dataIndex)
                $scope.$digest();
                return
            }

            //判读是否是素材
            if (data.indexOf('dragtargetClipArt') != '-1') {
                $scope.addSticker($scope.stickerList[dataIndex], e,offsetX,offsetY);
                $scope.$digest();
            }
        }

        // 多选图片进入占位框
        function dragChange() {
            var t = 0;
            for (var n = 0; n < $scope.document.pages.length; n++) {
                var page = $scope.document.pages[n];
                angular.forEach(page.arr.levels.level, function (level, i) {
                    if (level.imagebox) {
                        var img = level.imagebox;
                        if (t >= $scope.picArr.length) {
                            return
                        }
                        if (img.image && img.image.resource && img.image.resource.identifier) {
                            return
                        }
                        setImage(img, t);
                        t++;
                    }

                });
                if (page.arr2) {
                    angular.forEach(page.arr2.levels.level, function (level, i) {
                        if (level.imagebox) {
                            var img = level.imagebox;
                            if (t >= $scope.picArr.length) {
                                return
                            }
                            if (img.image && img.image.resource && img.image.resource.identifier) {
                                return
                            }
                            setImage(img, t);
                            t++;
                        }
                    });
                }
            }

            function setImage(image, t, flag) {
                // 当 和占位框中的不是同一张图片
                if (image.image.resourceid != $scope.picArr[t].id) {
                    if($scope.picArr[t].imageInfo){
                        image.image.imageInfo = $scope.picArr[t].imageInfo;
                        setImage1(image, t, flag, image.image.imageInfo)
                    }else{
                        $.ajax({
                            type: "GET",
                            url: $scope.domain + $scope.picArr[t].identifier + "?imageInfo",
                            async: false,
                            success: function (va) {
                                image.image.imageInfo = va;
                                setImage1(image, t, flag, image.image.imageInfo)
                            }
                        });
                    }
                }
            }

            function setImage1(image, t, flag, imageInfo) {
                image.image.angle = orientationInit1(imageInfo.orientation).angle;
                image.flip = orientationInit1(imageInfo.orientation).flip;
                $scope.picArr[t].width = orientationInit(imageInfo).width;
                $scope.picArr[t].height = orientationInit(imageInfo).height;
                // 使用次数
                if (image.image && image.image.resource && image.image.resource.identifier && flag) {
                    for (var j = 0; j < $scope.photos.length; j++) {
                        var arr = $scope.photos[j];
                        if (arr.id == image.image.resourceid) {
                            arr.usenum == 0 ? 0 : arr.usenum--;
                        }
                    }
                }
                // $scope.photos[$scope.picArr[t].index].usenum = $scope.photos[$scope.picArr[t].index].usenum - 0 + 1;
                // image.image.index = $scope.picArr[t].index;
                image.image.resourceid = $scope.picArr[t].id;
                image.image.width = $scope.picArr[t].width;
                image.image.height = $scope.picArr[t].height;
                image.image.imgsize = $scope.picArr[t].imgsize;
                image.image.offsetx = 0;
                image.image.offsety = 0;
                image.image.angle = image.image.angle || 0;
                image.image.scale = 1;
                image.bokehtype = $scope.productBokehType || 'None';
                image.imgUrl = $scope.domain + $scope.picArr[t].identifier + '?imageMogr2/thumbnail/!40p';
                image.image.resource = {
                    identifier: $scope.domain + $scope.picArr[t].identifier,
                    provider: 'qiniu',
                    width: $scope.picArr[t].width,
                    height: $scope.picArr[t].height,
                }
                image.loading = true;
                image.transparency = image.transparency>=0 ? image.transparency : 1;
                imgScale(image, image.image.offsetx, image.image.offsety, false ,'center');
                getSameUseNum()
            }
        }

        $scope.moveToFolder = function () {
            if ($scope.gallaryPic.length < 1) {
                MessageService.error("请至少选择一张图片");
                return
            }
            vm.isMoveToFolder = true;
        }

        $scope.chooseFolder = function (folder, index) {
            vm.selectFolderId = null;
            folder.selected = !folder.selected;
            angular.forEach($scope.Folders, function (f, i) {
                if (index != i) {
                    f.selected = false;
                }
            })
            if (folder.selected) {
                vm.selectFolderId = folder.id;
            }
        }

        $scope.saveMoveToFolder = function () {
            if (vm.selectFolderId == null) {
                MessageService.error("请选择文件夹！");
                return
            }
            $scope.moveGalleryIds = [];
            angular.forEach($scope.gallaryPic, function (p, i) {
                $scope.moveGalleryIds.push(p.id);
            })

            Gallery.moveFolder({
                galleries: $scope.moveGalleryIds,
                folderId: vm.selectFolderId
            }, function () {
                angular.forEach($scope.Folders, function (f, i) {
                    f.selected = false;
                })
                $scope.photos = [];
                vm.isMoveToFolder = false;
                if ($scope.folderOne) {
                    loadAll($scope.folderOne.id);
                } else {
                    loadAll('isNull');
                }
            })
        }


        window.doubleClickbgImg = function (e) {
            fn(e);
            var currentPage = $scope.currentPage;
            if (currentPage.background && currentPage.background.resource && currentPage.background.resource.identifier) {
                $scope.cutBgImg(currentPage, $scope.pageI);
            }
            return
        }

        /**
         * 进入图片编辑模式
         */
        $scope.cutBgImg = function (page, pageI) {
            var page = $scope.document.pages[$scope.pageI];
            var arr = page.arr;
            if ($scope.arrI == 2) {
                arr = page.arr2
            }

            $scope.currentPage = arr;

            $scope.showBg = true;
            $scope.showImageMenu = false;

            $scope.bgw = $('.bg-image' + $scope.arrI + pageI).width();
            $scope.bgh = $('.bg-image' + $scope.arrI + pageI).height();
            $scope.slideW = $(".slide" + $scope.arrI + pageI).width();
            $scope.slideH = $(".slide" + $scope.arrI + pageI).height();


            $("#cut-mask-bg").attr('src', '');

            var background = arr.background;
            background.width = $scope.bgw / arr.pageScaleW / page.scale;
            background.height = $scope.bgh / arr.pageScaleH / page.scale;

            // 显示区域（包含上/下页按钮）
            var bodyW = $(".web-con").width();
            var bodyH = $(".web-con").height();

            // 裁切框显示位置
            var left = parseFloat($(".page-center").css('marginLeft')) + parseFloat($(".page-center").css('left')) + parseFloat($(".web" + $scope.pageI).css('marginLeft'));
            // var top = bodyH / 2 - $(".page-center").height() / 2;
            var top = parseFloat($(".page-center").css('marginTop')) + parseFloat($(".page-center").css('top'));
            if ($scope.arrI == 2) {
                left += $(".slide1" + $scope.pageI).width();
            }
            $scope.bgl = left;
            $scope.bgt = top;
            $("#cut-bg").css({
                left: left + background.offsetx * arr.pageScaleW * page.scale,
                top: top + background.offsety * arr.pageScaleH / page.scale
            });

            ImageService.convertImgToBase64('content/images/white.png', function (s) {
                var imagecanvas = $("#mask-canvas")[0];
                var imagecontext = imagecanvas.getContext('2d');
                var newImg = document.createElement('img');
                // newImg.crossOrigin = "Anonymous";
                newImg.src = 'content/images/zhezhao1.png';
                newImg.onload = function () {
                    newImg.onload = null;
                    var masks = document.createElement('img');
                    masks.src = s;
                    // masks.crossOrigin = "Anonymous";
                    masks.onload = function () {
                        masks.onload = null;
                        imagecanvas.width = bodyW;
                        imagecanvas.height = bodyH;
                        imagecontext.drawImage(masks, left, top, $scope.slideW, $scope.slideH);
                        imagecontext.globalCompositeOperation = 'source-out';
                        imagecontext.drawImage(newImg, 0, 0, bodyW, bodyH);

                        $("#cut-mask-bg").attr('src', ImageService.dataURLtoBlob(imagecanvas.toDataURL()));

                        $scope.$digest();
                    };
                    // masks.src = box.maskUrl;
                };
                newImg.src = 'content/images/zhezhao1.png';
            });
        };

        var drag = false;
        var dragBox = {};
        /**
         * 拖拽编辑的图片
         */
        window.startBgEdit = function (e) {
            fn(e);

            var page = $scope.document.pages[$scope.pageI];
            dragBox.clientX = e.clientX ? e.clientX : 0;
            dragBox.clientY = e.clientY ? e.clientY : 0;
            dragBox.picW = $("#cut-bg").width();
            dragBox.picH = $("#cut-bg").height();
            var background = $scope.currentPage.background;
            dragBox.coordX = background.offsetx * $scope.currentPage.pageScaleW * page.scale;
            dragBox.coordY = background.offsety * $scope.currentPage.pageScaleH * page.scale;

            drag = true;
            document.addEventListener("mousemove", dragBgEdit, true);
            document.addEventListener("mouseup", stopBgEdit, true);
        };

        function dragBgEdit(e) {

            if (drag) {
                var page = $scope.document.pages[$scope.pageI];
                var background = $scope.currentPage.background;
                var x = 0, y = 0;

                //手指离开屏幕
                if (background.adaptation == 'Height') {    //宽图
                    x = (dragBox.coordX + (e.clientX - dragBox.clientX));
                    //向左滑动;        <-----
                    if (-x >= ($scope.bgw - $scope.slideW)) {
                        x = -($scope.bgw - $scope.slideW);
                    }
                    // 向右滑动;    ---->
                    if (x >= 0) {
                        x = 0;
                    }
                } else {     //高图
                    y = (dragBox.coordY + (e.clientY - dragBox.clientY));
                    //向上滑动;
                    if (-y >= ($scope.bgh - $scope.slideH)) {
                        y = -($scope.bgh - $scope.slideH);
                    }
                    //向下滑动;
                    if (y >= 0) {
                        y = 0;
                    }
                }

                $("#cut-bg").css({left: x + $scope.bgl, top: y + $scope.bgt});

                background.offsetx = x / ($scope.currentPage.pageScaleW * page.scale);
                background.offsety = y / ($scope.currentPage.pageScaleH * page.scale);

                $scope.$digest();
                e.stopPropagation();
                e.preventDefault();
                return false;

            }

        };

        function stopBgEdit() {
            drag = false;
            dragBox = {};
            document.removeEventListener("mousemove", dragBgEdit, true);
            document.removeEventListener("mouseup", stopBgEdit, true);
        };

        $scope.chooseColor = function () {
            $scope.chooseBgColor(vm.bgColor);
            vm.bgColor = null;
        }

        $scope.chooseBgColor = function (color) {
            $scope.currentPage.background = {
                resource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                offsetx: 0,
                offsety: 0,
                type: 'Color',
                color: color,
                width: 0,
                height: 0,
                adaptation: ''
            }
            // $scope.currentPage = currentPage;
            updateHistorys();
        }

        $scope.chooseBgimg = function (identifier, index, e) {
            fn(e)
            var bgW = $(".bgImg" + index).width();
            var bgH = $(".bgImg" + index).height();
            // $http({url: identifier + '?imageInfo'}).success(function (data) {
            //     var bgW = data.width;
            //     var bgH = data.height;
            var pageW = $(".slide" + $scope.arrI + $scope.pageI).width();
            var pageH = $(".slide" + $scope.arrI + $scope.pageI).height();
            var page = $scope.document.pages[$scope.pageI];

            if ($scope.arrI == 1) {
                $scope.currentPage = page.arr;
            } else {
                $scope.currentPage = page.arr2;
            }

            var prow = $scope.currentPage.pageScaleW * page.scale;
            var proh = $scope.currentPage.pageScaleH * page.scale;
            // 高适配
            var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
            // 宽适配
            if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                w = pageW;
                h = pageW * bgH / bgW;
                adaptation = 'Width';
            }
            $scope.currentPage.background = {
                resource: {
                    identifier: identifier,
                    provider: 'qiniu',
                    width: bgW,
                    height: bgH
                },
                offsetx: 0,
                offsety: 0,
                type: 'Pic',
                color: '',
                widthM: unitTransformPx(w / prow),
                heightM: unitTransformPx(h / proh),
                width: w / prow,
                height: h / proh,
                adaptation: adaptation,
                scale: 1
            }
            updateHistorys();
            // });
        }

        $scope.loadMoreMasks = function () {
            if ($scope.maskList.length == vm.totalMaskItems) {
                MessageService.error("已全部加载完成");
                return
            }
            vm.indexMaskNum++;
            getBackgrounds($scope.masklabels[$scope.masklabelChooseIndex], Material, vm.indexMaskNum, function (res) {
                vm.totalMaskItems = res.items;
                getMaskList(res.arr);
                $timeout(function () {
                    vm.loading = false;
                },1500)
            });
        }
        $scope.selectMaskLabel = function (label, i) {
            $scope.masklabelChooseId = label.id;
            $scope.masklabelChooseIndex = i;
            $scope.maskList = [];
            vm.indexMaskNum = 0;
            if(!label.firstClick){
                vm.loading = true;
                label.firstClick = true;
            }
            if (label.id == null) {
                vm.totalMaskItems = vm.masks.length;
                $scope.maskList = angular.copy(vm.masks)
                vm.loading = false;
            } else {
                getBackgrounds(label, Material, vm.indexMaskNum, function (res) {
                    // $scope.maskList = res.arr;
                    vm.totalMaskItems = res.items;
                    getMaskList(res.arr);
                    $timeout(function () {
                        vm.loading = false;
                    },1500)
                });
            }

            // setTimeout(function () {
            //     // waterFall('main2');
            //     var h = $(".leftPanel-main-wrap").height() - $(".mask-head").height() - 30
            //     $(".mask-con").height(h);
            // }, 400);
        };

        window.dragStart = function (event) {
            var target = event.target;
            event.dataTransfer.setData("img", target.id);
            if (target.id.indexOf('dragtargetClipArt') != '-1') {
                event.dataTransfer.setData("Art", target.dataset.clipart);
            }
            $timeout(function () {
                vm.dragDom = true;
            })
        };
        window.dragOn = function(event){
            $timeout(function () {
                vm.dragDom = false;
            })
        }

        //图片占位框点击时
        $scope.selectImg = function (e, $index, arrIndex, outerIndex, img) {
            fn(e);
            toolbarStatus()
            vm.isTransp = false;
            if(vm.fontOn||vm.fontDocOn){
                return
            }

            $scope.template.levelI = $index;
            scrollIntoView();
            $scope.arrI = arrIndex;
            $scope.pageI = outerIndex;
            // removeContenteditable();
            if (img.image && img.image.resource && img.image.resource.identifier) {
                $scope.hasImgUrl = true;
                $scope.imgUrl = img.image.resource.identifier + '?imageMogr2/thumbnail/!40p';
            } else {
                $scope.hasImgUrl = false;
                $scope.imgUrl = 'content/images/mask_souce.png'
            }
            $scope.hasText = false;
            if (img.image.angle != 0) {
                $scope.imgUrl += '/rotate/' + img.image.angle
            }
            getChooseBox(img, 'imagebox');
            //如果是按住ctrl键 实现多选复制
            // var mm = $scope.currentPage.imageboxes.imagebox[$index];
            if (e.ctrlKey == true || e.metaKey == true) {
                img.ctrlKey = true;
                vm.CtrlKeyArray.imageboxes[$index] = angular.copy(img);
                ctrlAddSingleArray();
            } else {
                if(!img.ctrlKey){
                    resetCtrlKeyArray();
                }
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                if (img.lock) {
                    return
                }
                vm.singleArray.imagebox = angular.copy(img);
            }
            e.stopPropagation();
        };

        function getChooseBox(box, attr) {
            $scope.selectBox = box;
            $scope.selectBox.current = attr;
            hideChangeBtn();
        }

        $scope.chooseCurrentPage = function (e,slide, page, index, arrIndex) {
            fn(e);
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            $scope.currentPage = page;
            $scope.pageI = index;
            $scope.arrI = arrIndex;
            vm.isTransp = false;
            vm.isCoverage = false;
            vm.textFont = false;
            toolbarStatus();
            // $scope.selectBox = null;
            // removeContenteditable();
        }

        function removeContenteditable() {
            if(vm.fontOn){
                // vm.fontOn.css({position:'absolute','margin-left':0,'margin-top':0});
                var span = vm.fontOn.find('span');
                if(span[0]){
                    $(span[0]).css({'cursor':'pointer'});
                    span[0].setAttribute("contenteditable", "false");
                }
            }

            if(vm.fontDocOn){
                vm.fontDocOn.css({position:'absolute','margin-left':0,'margin-top':0})
                var spanDoc = vm.fontDocOn.find('span');
                if(spanDoc[0]){
                    $(spanDoc[0]).css({'cursor':'pointer'});
                    spanDoc[0].setAttribute("contenteditable", "false");
                }
            }

            window.getSelection().removeAllRanges();
            vm.textFont = false;
        }

        $scope.changeBlurred = function () {
            var img = $scope.currentImage;
            if (vm.imageboxDouble.bokehtype == 'None') {
                $.ajax({
                    type: "GET",
                    url: vm.imageboxDouble.image.resource.identifier + "?imageInfo",
                    async: false,
                    success: function (va) {
                        vm.imageboxDouble.image.angle = orientationInit1(va.orientation).angle;
                        vm.imageboxDouble.flip = orientationInit1(va.orientation).flip;
                        var data = orientationInit(va);
                        vm.imageboxDouble.image.width = data.width;
                        vm.imageboxDouble.image.height = data.height;
                        vm.imageboxDouble.bokehtype = $scope.productBokehType || 'None';
                        vm.imageboxDouble.image.offsetx = 0;
                        vm.imageboxDouble.image.offsety = 0;
                        vm.imageboxDouble.image.scale = 1;
                        // imgScale(vm.imageboxDouble, 0, 0, false,'center')
                        imgScale(vm.imageboxDouble, vm.imageboxDouble.image.offsetx, vm.imageboxDouble.image.offsety, false ,'center');
                    }
                })
            } else {
                vm.imageboxDouble.bokehtype = 'None'
            }
        }

        var drag = false;
        var dragBox = {};

        //编辑图片框图片
        $scope.doubleClickImagebox = function (index, item) {
            if(!index&&!item){
                var page = $scope.document.pages[$scope.pageI];
                var arr = page.arr;
                if ($scope.arrI == 2) {
                    arr = page.arr2
                }

                var box = arr.levels.level[$scope.template.levelI].imagebox;
                if (box.image && box.image.resource && box.image.resource.identifier) {
                    vm.imageboxDouble=angular.copy(box);
                    vm.imageboxDouble1 = $scope.template.levelI;
                    vm.isImageboxTrim = true;
                    // $(".selected").hide()
                }
                return
            }

            if(item.image && item.image.resource && item.image.resource.identifier){
                vm.imageboxDouble1 = angular.copy($scope.template.levelI);
                vm.imageboxDouble = angular.copy(item);
                vm.isImageboxTrim = true;
                // $(".selected").hide()
            }
        }
        $scope.imageboxFalseClick = function () {
            vm.isImageboxTrim = false;
            $(".selected").show();
        };
        $scope.imageboxTrueClick = function () {
            if (vm.imageboxDouble.bokehtype != 'None') {
                // vm.imageboxDouble.image.angle = 0;
                vm.imageboxDouble.image.offsetx = 0;
                vm.imageboxDouble.image.offsety = 0;
                vm.imageboxDouble.image.scale = 1;
            }
            var page = $scope.document.pages[$scope.pageI];
            var arr = page.arr;
            if ($scope.arrI == 2) {
                arr = page.arr2
            }
            arr.levels.level[vm.imageboxDouble1].imagebox = vm.imageboxDouble;
            vm.isImageboxTrim = false;
            $(".selected").show();
            pxInsufficient(arr.levels.level[vm.imageboxDouble1].imagebox,vm.imgSize, vm.imgDpi);
            uploadRightPlanel();
        };
        var isImageboxMouseDown = false;
        $scope.imageboxMouseDown = function ($event) {
            fn($event)
            if (vm.imageboxDouble.bokehtype != 'None') {
                return
            }
            var x = $event.clientX;
            var y = $event.clientY;
            var currentPage = $scope.currentPage;
            var page = $scope.document.pages[$scope.pageI];
            var proW = currentPage.pageScaleW * page.scale;
            var proH = currentPage.pageScaleH * page.scale;

            var l = vm.imageboxDouble.image.offsetx * proW * vm.imageboxDouble.image.scale;
            var t = vm.imageboxDouble.image.offsety * proH * vm.imageboxDouble.image.scale;
            var r = -(vm.imageboxDouble.image.width * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width) * proW;
            var b = - (vm.imageboxDouble.image.height * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height) * proH;
            var angle = Number(vm.imageboxDouble.rotation.angle);
            //开关打开
            isImageboxMouseDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageboxMouseDown == false) {
                    return;
                }
                fn(e);
                var cx = (l + r) / 2;
                var cy = (t + b) / 2;
                var pt0 = rotatePoint({x: x, y: y}, {x: cx, y: cy}, -angle);
                var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
                var deltaX = pt1.x - pt0.x;
                var deltaY = pt1.y - pt0.y;
                var nl = l + deltaX;
                var nt = t + deltaY;
                if (nl < r) {
                    nl = r
                };
                if (nl > 0) {
                    nl = 0
                };
                if (nt < b) {
                    nt = b
                };
                if (nt > 0) {
                    nt = 0
                };

                vm.imageboxDouble.image.offsetx = nl / proW / vm.imageboxDouble.image.scale;
                vm.imageboxDouble.image.offsety = nt/ proH / vm.imageboxDouble.image.scale;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageboxMouseDown = false;
                $scope.$digest();
            }
        }

        $scope.rotateImagebox = function () {
            if (vm.imageboxDouble.bokehtype != 'None') {
                return
            }
            vm.imageboxDouble.image.angle += 90;
            if (Math.abs(vm.imageboxDouble.image.angle) == 360) {
                vm.imageboxDouble.image.angle = 0
            }

            vm.imageboxDouble.image.offsetx = 0;
            vm.imageboxDouble.image.offsety = 0;

            var imageEdit = getImageScale(vm.imageboxDouble.image, vm.imageboxDouble.geometry);

            vm.imageboxDouble.image.width = imageEdit.nWidth
            vm.imageboxDouble.image.height = imageEdit.nHeight
        }

        $scope.rotateImagebox = function () {
            if (vm.imageboxDouble.bokehtype != 'None') {
                return
            }
            vm.imageboxDouble.image.angle += 90;
            if (Math.abs(vm.imageboxDouble.image.angle) == 360) {
                vm.imageboxDouble.image.angle = 0
            }
            vm.imageboxDouble.image.scale = 1;
            vm.imageboxDouble.image.offsetx = 0;
            vm.imageboxDouble.image.offsety = 0;
            getBackgroundSource2(vm.imageboxDouble, vm.imageboxDouble.geometry.width, vm.imageboxDouble.geometry.height, vm.imageboxDouble.image, vm.imageboxDouble.bokehtype, $scope, function () {})
        }

        // 滚动放大
        $scope.scale = 1;
        window.bbimg = function (delta) {
            if(vm.ctrlKey){
                return
            }
            // var pic = o.firstElementChild;
            // var zoom = parseInt(pic.style.zoom, 10) || 100;
            // zoom += event.wheelDelta / 12;
            // if (zoom > 0) pic.style.zoom = zoom + '%';
            if (vm.imageboxDouble.bokehtype != 'None') {
                return
            }
            var _image = angular.copy(vm.imageboxDouble.image);

            if (delta < 0 || delta == undefined && event.wheelDelta > 0) {
                vm.imageboxDouble.image.scale *= 1.1;
            } else {
                vm.imageboxDouble.image.scale /= 1.1;
                if (vm.imageboxDouble.image.scale <= 1) {
                    vm.imageboxDouble.image.scale = 1;
                }
            }
            ImageboxCb1(_image, vm.imageboxDouble, $scope.document.pages[$scope.pageI]);
            $scope.$digest();

            return false;
        }

        function ImageboxCb1(_image, imagebox){
            imagebox.image.offsetx = (_image.offsetx * scaleInit(_image) - (imagebox.image.width*scaleInit(imagebox.image)-_image.width*scaleInit(_image))/2) / scaleInit(imagebox.image);
            imagebox.image.offsety = (_image.offsety * scaleInit(_image) - (imagebox.image.height*scaleInit(imagebox.image)-_image.height*scaleInit(_image))/2) / scaleInit(imagebox.image);
            if(imagebox.image.width*scaleInit(imagebox.image) + imagebox.image.offsetx*scaleInit(imagebox.image) <= (imagebox.geometry.width * $scope.currentPage.pageScaleW*$scope.document.pages[$scope.pageI].scale)){
                imagebox.image.offsetx = ((imagebox.geometry.width * $scope.currentPage.pageScaleW*$scope.document.pages[$scope.pageI].scale) - imagebox.image.width*scaleInit(imagebox.image)) / scaleInit(imagebox.image);
            }
            if(imagebox.image.height*scaleInit(imagebox.image) + imagebox.image.offsety*scaleInit(imagebox.image) <= (imagebox.geometry.height * $scope.currentPage.pageScaleW*$scope.document.pages[$scope.pageI].scale)){
                imagebox.image.offsety = ((imagebox.geometry.height * $scope.currentPage.pageScaleW*$scope.document.pages[$scope.pageI].scale) - imagebox.image.height*scaleInit(imagebox.image)) / scaleInit(imagebox.image);
            }
            if(imagebox.image.offsetx > 0){
                imagebox.image.offsetx = 0;
            }
            if(imagebox.image.offsety > 0){
                imagebox.image.offsety = 0;
            }
        }
        function scaleInit(image) {
            return $scope.currentPage.pageScaleW*$scope.document.pages[$scope.pageI].scale*image.scale
        }

        //Firefox只识别DOMMouseScroll
        var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel";

        //判断是否是IE
        if (document.attachEvent) {
            $("#mouseWheel")[0].attachEvent("on" + mousewheelevt, function (e) {
                window.bbimg();
            });
        } else if (document.addEventListener) { //FireFox(DOMMouseScroll)、Chrome、Opera、safari
            $("#mouseWheel")[0].addEventListener(mousewheelevt, function (e) {
                e = e || window.event;
                if (e.detail) {//Firefox
                    window.bbimg(e.detail);
                }
                else if (e.wheelDelta) {
                    window.bbimg();
                }
            }, false);
        }

        $scope.deleteImage = function () {
            $(".selected").show();
            var page = $scope.document.pages[$scope.pageI];
            var arr = page.arr;
            if ($scope.arrI == 2) {
                arr = page.arr2
            }
            var box = arr.levels.level[$scope.template.levelI].imagebox;
            for (var j = 0; j < $scope.photos.length; j++) {
                var arr = $scope.photos[j];
                if (arr.id == vm.imageboxDouble.image.resourceid) {
                    arr.usenum == 0 ? 0 : arr.usenum--;
                }
            }

            if (vm.imageboxDouble.bokehtype) {
                box.blurredrectangle = {
                    x: 0,
                    y: 0,
                    width: box.geometry.width,
                    height: box.geometry.height
                }
            }
            box.imgUrl = "content/images/mask_souce.png";
            box.image = {
                resource: null,
                filterResource: {
                    identifier: '',
                    provider: ''
                },
                offsetx: 0,
                offsety: 0,
                width: '',
                height: '',
                imgsize: '',
                index: '',
                parameter: [],
                scale: 1,
                angle: 0,
            }

            vm.isImageboxTrim = false;
            vm.imageboxDouble = null;
            getUsedImageboxsNum();
        }

        function getImageScale(imagebox, images) {
            var w0 = angular.copy(imagebox.width);
            var h0 = angular.copy(imagebox.height);
            if(imagebox.resource){
                w0 = angular.copy(imagebox.width);
                h0 = angular.copy(imagebox.height);
                if (imagebox.angle == 90 || imagebox.angle == 270) {
                    w0 = angular.copy(imagebox.height);
                    h0 = angular.copy(imagebox.width);
                }
            }else{
                if (imagebox.angle == 90 || imagebox.angle == 270) {
                    w0 = angular.copy(imagebox.height);
                    h0 = angular.copy(imagebox.width);
                }
            }

            var x = 0, y = 0;

            var w = angular.copy(w0);
            var h = angular.copy(h0);
            var maskWidth = images.width;
            var maskHight = images.height;
            var isWidth = true;
            if ((w0 <= h0 || maskWidth <= maskHight) && (w0 / h0 < maskWidth / maskHight) || w0 >= h0 && maskWidth >= maskHight && (w0 / h0 < maskWidth / maskHight)) {
                w0 = maskWidth;
                h0 = maskWidth * h / w;
                isWidth = true;
            } else {
                h0 = maskHight;
                w0 = maskHight * w / h;
                isWidth = false;
            }

            var scaleW = w0 / w;
            var scaleH = h0 / h;
            x = x / scaleW;
            y = y / scaleH;
            return {
                isWidth: isWidth,
                nWidth: w0,
                nHeight: h0,
                scaleW: scaleW,
                scaleH: scaleH,
                x: x,
                y: y
            }
        }

        function addLevel(currentPage) {
            vm.isTransp = false;
            vm.isCoverage = false;

            var arr = {
                seq: 1,
                imagebox: {},
                textbox: {},
                imageart: {},
                textdocument: {}
            };

            if (!currentPage.levels.level) {
                currentPage.levels.level = [arr];
            } else {
                currentPage.levels.level.push(arr);
            }

            $scope.template.levelI = currentPage.levels.level.length - 1;
            var currentLevel = currentPage.levels.level[$scope.template.levelI];

            currentLevel.seq = $scope.template.levelI + 1;
            $scope.template.angle = 0;
            $scope.hasText = false;
            return currentLevel;
        }


        //增加图片占位框
        $scope.addPlacement = function (e, mask, attr,offsetX,offsetY, identifier, callback) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = identifier;
            newImg.onload = function () {
                var currentPage = $scope.currentPage;
                var page = $scope.document.pages[$scope.pageI];
                var currentLevel = addLevel(currentPage);

                var w = 110;
                var h = 100;
                var w1 = 200;
                var h1 = 200;
                var imgR = w1/h1;
                var imgR1 = newImg.width/newImg.height;

                if(imgR >= imgR1){
                    w= h1/newImg.height*newImg.width;
                    h= h1;
                }else {
                    w= w1;
                    h= w1/newImg.width*newImg.height;
                }
                var proW = currentPage.pageScaleW * page.scale;
                var proH = currentPage.pageScaleH * page.scale;
                var slideW = $(".slide" +$scope.arrI + $scope.pageI).width();
                var slideH = $(".slide" +$scope.arrI+ $scope.pageI).height();
                var imgOffsetX = 0, imgOffsetY = 0;
                var x = unitTransformPx((slideW - w * proW)/2/proW)+$scope.template.levelI;
                var y = unitTransformPx((slideH - h * proH)/2/proH)+$scope.template.levelI;

                if (e) {
                    imgOffsetX = offsetX - w * proW / 2;
                    imgOffsetY = offsetY - h * proH / 2;
                    //拖拽边界判断
                    if (imgOffsetX <= 0) {
                        imgOffsetX = 0;
                    }

                    if (imgOffsetX >= slideW - w * proW) {
                        imgOffsetX = slideW - w * proW;
                    }

                    if (imgOffsetY <= 0) {
                        imgOffsetY = 0;
                    }

                    if (imgOffsetY >= (slideH - h * proW)) {
                        imgOffsetY = slideH - h * proH;
                    }
                    x = unitTransformPx(imgOffsetX / proW);
                    y = unitTransformPx(imgOffsetY / proH);
                }

                currentLevel.imagebox = ({
                    transparency: 1,
                    geometry: {
                        width: w,
                        height: h,
                        x: unitTransform(x),
                        y: unitTransform(y)
                    },
                    rotation: {
                        angle: 0
                    },
                    mask: {
                        resource: {
                            identifier: mask.identifier,
                            provider: 'qiniu'
                        }
                    },
                    border: {
                        shape: 'Rectangle',
                        radius: {
                            lt: 0,
                            rt: 0,
                            rb: 0,
                            lb: 0,
                            all:0
                        },
                        "edge": {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                        lineWidth: 0,
                        type: "Color",
                        lineType:"solid",
                        resource: {
                            "identifier": ''
                        },
                        color: '#000000',
                    },
                    shadow:{
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    },
                    blurredrectangle: {},
                    image: {},
                    imgUrl: 'content/images/mask_souce.png',
                    maskUrl: mask.identifier || 'content/images/mask_souce.png',
                    bgImg: '',
                    zoom: 1,
                    levelI: $scope.template.levelI,
                    // bokehtype: $scope.productBokehType,
                    bokehtype: 'None',
                    pageI: $scope.pageI,
                    current: 'imagebox',
                    lock: false
                });
                var image = currentPage.levels.level[$scope.template.levelI].imagebox;

                getChooseBox(image, 'imagebox');
                vm.counts++;
                getUsedImageboxsNum();
                updateHistorys();
                if(callback)(callback())
                $scope.$apply();
            }
        };
        function addPlacement1(e, mask, attr,offsetX,offsetY, identifier, shape) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            var newImg = new Image();
            newImg.crossOrigin = "Anonymous";
            newImg.src = identifier;
            newImg.onload = function () {
                var currentPage = $scope.currentPage;
                var page = $scope.document.pages[$scope.pageI];
                var currentLevel = addLevel(currentPage);

                var w = 110;
                var h = 100;
                var w1 = 200;
                var h1 = 200;
                var imgR = w1/h1;
                var imgR1 = newImg.width/newImg.height;

                if(imgR >= imgR1){
                    w= h1/newImg.height*newImg.width;
                    h= h1;
                }else {
                    w= w1;
                    h= w1/newImg.width*newImg.height;
                }
                var proW = currentPage.pageScaleW * page.scale;
                var proH = currentPage.pageScaleH * page.scale;
                var slideW = $(".slide" +$scope.arrI + $scope.pageI).width();
                var slideH = $(".slide" +$scope.arrI+ $scope.pageI).height();
                var imgOffsetX = 0, imgOffsetY = 0;
                var x = unitTransformPx((slideW - w * proW)/2/proW)+$scope.template.levelI;
                var y = unitTransformPx((slideH - h * proH)/2/proH)+$scope.template.levelI;

                if (e) {
                    imgOffsetX = offsetX - w * proW / 2;
                    imgOffsetY = offsetY - h * proH / 2;
                    //拖拽边界判断
                    if (imgOffsetX <= 0) {
                        imgOffsetX = 0;
                    }

                    if (imgOffsetX >= slideW - w * proW) {
                        imgOffsetX = slideW - w * proW;
                    }

                    if (imgOffsetY <= 0) {
                        imgOffsetY = 0;
                    }

                    if (imgOffsetY >= (slideH - h * proW)) {
                        imgOffsetY = slideH - h * proH;
                    }
                    x = unitTransformPx(imgOffsetX / proW);
                    y = unitTransformPx(imgOffsetY / proH);
                }

                currentLevel.imagebox = ({
                    transparency: 1,
                    geometry: {
                        width: w,
                        height: h,
                        x: unitTransform(x),
                        y: unitTransform(y)
                    },
                    rotation: {
                        angle: 0
                    },
                    mask: {
                        resource: {
                            identifier: mask.identifier,
                            provider: 'qiniu'
                        }
                    },
                    border: {
                        shape: shape,
                        radius: {
                            lt: 0,
                            rt: 0,
                            rb: 0,
                            lb: 0,
                            all: 0
                        },
                        "edge": {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                        lineWidth: 0,
                        type: "Color",
                        lineType:"solid",
                        resource: {
                            "identifier": ''
                        },
                        color: '#000000',
                    },
                    shadow:{
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    },
                    blurredrectangle: {},
                    image: {},
                    imgUrl: 'content/images/mask_souce.png',
                    maskUrl: mask.identifier || 'content/images/mask_souce.png',
                    bgImg: '',
                    zoom: 1,
                    levelI: $scope.template.levelI,
                    // bokehtype: $scope.productBokehType,
                    bokehtype: 'None',
                    pageI: $scope.pageI,
                    current: 'imagebox',
                    lock: false
                });
                var image = currentPage.levels.level[$scope.template.levelI].imagebox;

                getChooseBox(image, 'imagebox');
                vm.counts++;
                getUsedImageboxsNum();
                updateHistorys();
                $scope.$apply();
            }
        };
        function uploadRightPlanel() {
            // $scope.template.pages.page[$scope.currentPage.seq - 1] = angular.copy($scope.currentPage);
            var p = [];
            angular.forEach($scope.document.pages, function (item, index) {
                p.push(item.arr);
                if (item.arr2) {
                    p.push(item.arr2);
                }
            });
            $scope.template.pages.page = p;
        }

        // 占位框背景
        window.updateMask = function ($event) {
            var files = $event.target.files;
            angular.forEach(files, function (file, i) {
                var url = window.URL || window.webkitURL;
                var pic = url.createObjectURL(file);

                ImageService.backendUploadPics(files[i],$scope.domain, vm.token, function (data,f) {
                    files[i] = f;
                    var imagecanvas = $("#mask-canvas")[0];
                    var imagecontext = imagecanvas.getContext('2d');
                    var newImg = document.createElement('img');
                    newImg.crossOrigin = "Anonymous";
                    newImg.src = 'content/images/mask_souce2.png';
                    newImg.onload = function () {
                        newImg.onload = null;
                        var width = newImg.width;
                        var height = newImg.height;
                        newImg.setAttribute('data-mask', pic);
                        var masks = document.createElement('img');
                        masks.src = newImg.getAttribute('data-mask');
                        masks.crossOrigin = "anonymous";
                        masks.onload = function () {
                            masks.onload = null;
                            imagecanvas.width = width;
                            imagecanvas.height = height;
                            imagecontext.drawImage(masks, 0, 0, width, height);
                            imagecontext.globalCompositeOperation = 'source-atop';
                            imagecontext.drawImage(newImg, 0, 0);
                            $scope.template.masks.mask.push({
                                resource: {
                                    'identifier': data.key,
                                    'provider': 'qiniu'
                                },
                                bgImg: ImageService.dataURLtoBlob(imagecanvas.toDataURL()),
                                isEdit: false,
                                type: 'User'
                            });
                            // updatePageHistory();
                            $scope.$digest();
                        };
                    };
                },function (res) {

                })
            });
        };

        $scope.moveImgStart = function (e, item, slide,arrindex,index,outerIndex) {
            fn(e);
            if(vm.fontOn || vm.fontDocOn || item.lock){
                return
            }
            $scope.hasText = false;
            $scope.pageI = outerIndex;
            $scope.arrI = arrindex;
            $scope.template.levelI = index;
            var targ = e.target ? e.target : e.srcElement;
            var page = $scope.document.pages[$scope.pageI];
            $scope.currentPage = slide;
            getChooseBox(item, 'imagebox');

            var all = getAllCtrlKeyArray();
            if (all.length || e.ctrlKey) {
                dragBox.ctrlKey = true;
            }else{
                dragBox.ctrlKey = false;
            }
            dragBox.slideW = $(".slide" + $scope.arrI + $scope.pageI).width();
            dragBox.slideH = $(".slide" + $scope.arrI + $scope.pageI).height();
            boxWebdown($scope, e,item, $scope.currentPage,$scope.currentPage.pageScaleW * page.scale, dragBox,vm.CtrlKeyArray, function () {
                $scope.isMoveOut = false;
                uploadRightPlanel();
                updateHistorys();
            })
        };

        var isTextRotate = false;
        $scope.rotateImgStart = function($event, item,slide){
            if(item.lock){return}
            var $event = $event || window.event;
            //防止浏览器默认行为(W3C)
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            //IE中组织浏览器行为
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
            var _top = $($event.target).parents('.imgBoxes').offset().top + (item.geometry.height * slide.arr.pageScaleH*slide.scale / 2);
            var _left = $($event.target).parents('.imgBoxes').offset().left + (item.geometry.width * slide.arr.pageScaleW*slide.scale / 2);
            //开关打开
            isTextRotate = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isTextRotate == false) {
                    return;
                }
                item.rotation.angle = getAngle(_left,_top, e.clientX, e.clientY);
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                if(isTextRotate){
                    uploadRightPlanel();
                    updateHistorys();
                }
                isTextRotate = false;
            };
        }

        /**
         *放大占位框
         * @param e
         */
        var dragFlag = false;
        var dragMask = {};
        window.resizeStart = function (e) {
            fn(e);
            $scope.hasText = false;
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.dataset.index;
            var currentImg = $scope.currentPage.levels.level[$scope.template.levelI].imagebox;

            if (currentImg.lock) {
                return
            }
            dragMask.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragMask.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragMask.coordX = targ.offsetLeft + 14;        //鼠标到模板左边的距离
            dragMask.coordY = targ.offsetTop + 14;         //鼠标到模板顶部的距离

            dragMask.maxX = $(".slide" + $scope.arrI + $scope.pageI).width();
            dragMask.maxY = $(".slide" + $scope.arrI + $scope.pageI).height();


            dragFlag = true;

            document.addEventListener("mousemove", resizeOn, true);
            document.addEventListener("mouseup", resizeEnd, true);
            // setZindex(e.target.dataset.index);
        };
        function resizeOn(e) {
            if (dragFlag) {
                fn(e);
                // 是否按住shift键
                dragMask.shiftKey = e.shiftKey;
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = $scope.template.levelI;
                if (imgIndex == null) {
                    return;
                }
                var currentPage = $scope.currentPage;
                if (currentPage == null) {
                    return;
                }
                var page = $scope.document.pages[$scope.pageI];
                var pressImg = currentPage.levels.level[$scope.template.levelI].imagebox;
                if (pressImg == null) {
                    return;
                }

                var w = (dragMask.coordX + e.clientX - dragMask.clientX);
                var h = (dragMask.coordY + e.clientY - dragMask.clientY);
                if (dragMask.shiftKey) {
                    h = w / pressImg.geometry.width * pressImg.geometry.height
                }
                if (w < 40 || h < 40) {
                    return
                }

                var moveW = dragMask.maxX - pressImg.geometry.x * currentPage.pageScaleW * page.scale;
                var moveH = dragMask.maxY - pressImg.geometry.y * currentPage.pageScaleH * page.scale;
                if (w < moveW && h < moveH && dragMask.shiftKey) {
                    pressImg.geometry.width = w / currentPage.pageScaleW / page.scale;
                    pressImg.geometry.height = h / currentPage.pageScaleH / page.scale;
                } else if (!dragMask.shiftKey) {
                    if (w < moveW) {
                        pressImg.geometry.width = w / currentPage.pageScaleW / page.scale;
                    }
                    if (h < moveH) {
                        pressImg.geometry.height = h / currentPage.pageScaleH / page.scale;
                    }
                }

                pressImg.image.offsetx = 0;
                pressImg.image.offsety = 0;
                if (pressImg.image && pressImg.image.resource && pressImg.image.resource.identifier) {
                    pressImg.image.angle = 0;
                    imgScale(pressImg, pressImg.image.offsetx, pressImg.image.offsety, false);
                }

            }
            $scope.$digest();
            return false;
        }

        function resizeEnd() {
            dragFlag = false;
            dragMask = {};
            uploadRightPlanel();
            updateHistorys();
            document.removeEventListener("mousemove", resizeOn, true);
            document.removeEventListener("mouseup", resizeEnd, true);
            $scope.$digest();
        }


        var isZoomDown = false;
        $scope.resizeStartRigth = function($event, item,pro,index,slide){
            if(item.lock){return}
            fn($event);
            // imageboxZoom($event, item, 'right',pro,index,slide)
            editorBoxZoom($event, item, 'rightBottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$digest();
            }, function (it) {
                if(it.image && it.image.resource&& it.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    });
                }
            });
        }
        $scope.resizeStartLeft = function ($event, item,pro,index,slide) {
            if(item.lock){return}
            fn($event);
            // imageboxZoom($event, item, 'left',pro,index,slide)
            editorBoxZoom($event, item, 'leftTop', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$digest();
            }, function (it) {
                if(it.image && it.image.resource&& it.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    });
                }
            });
        }
        $scope.resizeStartTop = function ($event, item,pro,index,slide) {
            if(item.lock){return}
            fn($event);
            // imageboxZoom($event, item, 'top',pro,index,slide)
            editorBoxZoom($event, item, 'rightTop', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$digest();
            }, function (it) {
                if(it.image && it.image.resource&& it.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    });
                }
            });
        }
        $scope.resizeStartBottom = function ($event, item,pro,index,slide) {
            if(item.lock){return}
            fn($event);
            // imageboxZoom($event, item, 'bottom',pro,index,slide)
            editorBoxZoom($event, item, 'leftBottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$digest();
            }, function (it) {
                if(it.image && it.image.resource&& it.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    });
                }
            });
        }
        $scope.resizeStartLeft1 = function ($event, item,pro,index,slide) {
            if(item.lock){return}
            fn($event);
            // imageboxZoom1($event, item, 'left',pro,index,slide)
            editorBoxZoom($event, item, 'left', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$digest();
            }, function (it) {
                if(it.image && it.image.resource&& it.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    });
                }
            });
        }
        $scope.resizeStartTop1 = function ($event, item,pro,index,slide) {
            if(item.lock){return}
            fn($event);
            // imageboxZoom1($event, item, 'top',pro,index,slide)
            editorBoxZoom($event, item, 'top', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$digest();
            }, function (it) {
                if(it.image && it.image.resource&& it.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    });
                }
            });
        }
        $scope.resizeStartRight1 = function ($event, item,pro,index,slide) {
            if(item.lock){return}
            fn($event);
            // imageboxZoom1($event, item, 'right',pro,index,slide)
            editorBoxZoom($event, item, 'right', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$digest();
            }, function (it) {
                if(it.image && it.image.resource&& it.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    });
                }
            });
        }
        $scope.resizeStartBottom1 = function ($event, item,pro,index,slide) {
            if(item.lock){return}
            fn($event);
            // imageboxZoom1($event, item, 'bottom',pro,index,slide)
            editorBoxZoom($event, item, 'bottom', pro, $($event.target).parents('.selected'), function (it) {
                $scope.$digest();
            }, function (it) {
                if(it.image && it.image.resource&& it.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    });
                }
            });
        }
        function imageboxZoom($event,item, direction,pro,index,slide) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;

            var maxX = ($scope.currentPage.trimbox.width + $scope.currentPage.trimbox.x) * pro;
            var maxY = ($scope.currentPage.trimbox.height + $scope.currentPage.trimbox.y) * pro;

            var _left = $($event.target).parents('.selected').offset().left + (nw/2);
            var _top = $($event.target).parents('.selected').offset().top + (nh/2);

            var x3 = Math.abs(_left - $event.clientX);
            var y3 = Math.abs(_top - $event.clientY);
            var distance = Math.sqrt(Math.pow(x3, 2) + Math.pow(y3, 2));
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                var nl = 0;
                var x = Math.abs(_left + nl - e.clientX);
                var y = Math.abs(_top - e.clientY);
                var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
                nl = (parseInt(z - distance))*2 / pro;
                var _w = (Number(nw) + Number(nl));
                var _h = _w / nw * nh;
                item.geometry.width = _w / pro;
                item.geometry.height = _h / pro;
                item.geometry.x = (x2 - (_w - nw)/2) / pro;
                item.geometry.y = (y2 - (_h - nh)/2) / pro;
                if (item.image) {
                    item.image.width = _w / nw * nw2 / pro;
                    item.image.height = _w / nw * nh2 / pro;
                    item.image.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / pro;
                    item.image.offsety = (_h / (nh / nh1)) / (nh1 / y1) / pro;
                }
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if(item.image && item.image.resource&& item.image.resource.identifier){
                    getBackgroundSource3(item,item.geometry.width,item.geometry.height,item.image, item.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    })
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                }
                $event.stopPropagation();
                isZoomDown = false;
            }
        }
        function imageboxZoom1($event,item, direction, pro,index,slide) {
            var x = $event.clientX;
            var y = $event.clientY;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
            var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
            var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
            var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
            var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
            var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
            var x2 = item.geometry.x * pro;
            var y2 = item.geometry.y * pro;
            var maxX = ($scope.currentPage.trimbox.width + $scope.currentPage.trimbox.x) * pro;
            var maxY = ($scope.currentPage.trimbox.height + $scope.currentPage.trimbox.y) * pro;
            var _left = $($event.target).parents('.selected').offset().left + (nw/2);
            var _top = $($event.target).parents('.selected').offset().top + (nh/2);

            var x3 = Math.abs(_left - $event.clientX);
            var y3 = Math.abs(_top - $event.clientY);
            var distance = Math.sqrt(Math.pow(x3, 2) + Math.pow(y3, 2));
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nl = 0;
                var x = Math.abs(_left + nl - e.clientX);
                var y = Math.abs(_top - e.clientY);
                var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
                nl = (parseInt(z - distance))*2 / pro;
                var _w = (Number(nw) + Number(nl));
                var _h = (Number(nh) + Number(nl));
                if (item.image) {
                    item.image.offsetx = 0;
                    item.image.offsety = 0;
                }
                if (direction == 'left' || direction == 'right') {
                    item.geometry.width = _w / pro;
                    item.geometry.x = (x2 - (_w - nw)/2) / pro;
                }
                if (direction == 'top' || direction == 'bottom') {
                    item.geometry.height = _h / pro;
                    item.geometry.y = (y2 - (_h - nh)/2) / pro;
                }
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if(item.image && item.image.resource&& item.image.resource.identifier){
                    // imgScale(item, item.image.offsetx, item.image.offsety, false);
                    getBackgroundSource3(item,item.geometry.width,item.geometry.height,item.image, item.bokehtype, $scope, function () {
                        pxInsufficient(item,vm.imgSize, vm.imgDpi);
                    })
                }
                $event.stopPropagation();
                isZoomDown = false;
            }
        }


        /*******************照片处理***********end************************/

        /***********************贴纸功能**********start***********************/

        /**
         * 获取贴纸列表
         */
        var artList = [];
        var tempArts = [];
        vm.indexArtNum = 0;

        $scope.loadMoreCliparts = function () {
            if ($scope.stickerList.length == vm.totalClipartItems) {
                MessageService.error("已全部加载完成");
                return
            }
            vm.indexArtNum++;
            getBackgrounds($scope.labels[$scope.artlabelChooseIndex], ClipArt, vm.indexArtNum, function (res) {
                vm.totalClipartItems = res.items;
                angular.forEach(res.arr, function (arr) {
                    $scope.stickerList.push(arr);
                })
                waterFallCliparts();
            });
        }

        vm.loading = false;
        $scope.selectLabel = function (label, index) {
            // ClipArt, vm.indexArtNum, 'ClipArt', vm.indexArtNum
            $scope.artlabelChooseId = label.id;
            $scope.artlabelChooseIndex = index;
            $scope.stickerList = [];
            vm.indexArtNum = 0;
            if(!label.firstClick){
                vm.loading = true;
                label.firstClick = true;
            }
            if (label.id == null) {
                vm.totalClipartItems = vm.cliparts.length;
                $scope.stickerList = angular.copy(vm.cliparts);
                waterFallCliparts();
                vm.loading = false;
            } else {
                getBackgrounds(label, ClipArt, vm.indexArtNum, function (res) {
                    vm.totalClipartItems = res.items;
                    $scope.stickerList = res.arr;
                    waterFallCliparts();

                    $timeout(function () {
                        vm.loading = false;
                    },1500)
                });
            }
        };

        $scope.showLabels = false;
        $scope.showMoreLabels = function () {
            $scope.showLabels = !$scope.showLabels;
            setTimeout(function () {
                var h = $(".leftPanel-main-wrap").height() - $(".clipart-head").height() - 30
                $(".clipart-con").height(h);
            }, 800);
        }

        $scope.showBgLabels = false;
        $scope.showMoreBgLabels = function () {
            $scope.showBgLabels = !$scope.showBgLabels;
            setTimeout(function () {
                var h = $(".leftPanel-main-wrap").height() - $(".bgimg-color").height() - $(".bgimg-head").height() - 30
                $(".bgimg-con").height(h);
            }, 200);
        }

        $scope.showMaskLabels = false;
        $scope.showMoreMaskLabels = function () {
            $scope.showMaskLabels = !$scope.showMaskLabels;
        }

        $scope.loadMoreBgimgs = function () {
            if ($scope.bgImgsList.length >= vm.totalBgItems) {
                MessageService.error("已全部加载完成");
                return
            }
            vm.indexBgNum++;
            // Backgrounds, vm.indexBgNum, 'Background', vm.totalBgItems
            getBackgrounds($scope.bglabels[$scope.bglabelChooseIndex], Backgrounds, vm.indexBgNum, function (res) {
                vm.totalBgItems = res.items;
                angular.forEach(res.arr, function (arr) {
                    $scope.bgImgsList.push(arr);
                })
                setTimeout(function () {
                    waterFall('main4');
                    var h = $(".leftPanel-main-wrap").height() - $(".bgimg-color").height() - $(".bgimg-head").height() - 60
                    $(".bgimg-con").height(h);
                }, 300);
            });
        }

        function addLevel(currentPage) {
            vm.isTransp = false;
            vm.isCoverage = false;

            var arr = {
                seq: 1,
                imagebox: null,
                textbox: null,
                imageart: null,
                textdocument: null
            };

            if (!currentPage.levels.level) {
                currentPage.levels.level = [arr];
            } else {
                currentPage.levels.level.push(arr);
            }

            $scope.template.levelI = currentPage.levels.level.length - 1;
            var currentLevel = currentPage.levels.level[$scope.template.levelI];

            currentLevel.seq = $scope.template.levelI + 1;

            return currentLevel;
        }

        /**
         * 添加贴纸
         */
        $scope.addSticker = function (sticker, e,offsetX,offsetY) {
            fn(e);
            var currentPage = $scope.currentPage;
            var page = $scope.document.pages[$scope.pageI];
            var currentLevel = addLevel(currentPage);

            $scope.hasText = false;
            $scope.hasImgUrl = false;

            var img = new Image();
            img.src = sticker.identifier;
            img.onload = function () {

                var arr = ThreeHundredDpiToMm(img.width, img.height);

                var bgImgW = unitTransform(arr.mm1);
                var bgImgH = unitTransform(arr.mm2);
                var w = '';
                var h = '';
                var bgImgR = bgImgW/bgImgH;
                if(bgImgR >= 1){
                    if(bgImgW > currentPage.trimbox.width/2){
                        w = currentPage.trimbox.width/2;
                        h = currentPage.trimbox.width/2 * bgImgH / bgImgW;
                    }else {
                        w = bgImgW;
                        h = bgImgH;
                    }
                }else {
                    if(bgImgH > currentPage.trimbox.height/2){
                        h = currentPage.trimbox.height/2;
                        w = currentPage.trimbox.height/2 * bgImgW / bgImgH;
                    }else {
                        w = bgImgW;
                        h = bgImgH;
                    }
                }
                arr.mm1 = unitTransformPx(w)
                arr.mm2 = unitTransformPx(h)

                var proW = currentPage.pageScaleW * page.scale;
                var proH = currentPage.pageScaleH * page.scale;
                var slideW = $(".slide" +$scope.arrI + $scope.pageI).width();
                var slideH = $(".slide" +$scope.arrI+ $scope.pageI).height();
                var imgOffsetX = 0, imgOffsetY = 0;
                var x = unitTransformPx((slideW - w * proW)/2/proW)+$scope.template.levelI;
                var y = unitTransformPx((slideH - h * proH)/2/proH)+$scope.template.levelI;


                if (e) {
                    imgOffsetX = offsetX - w * proW / 2;
                    imgOffsetY = offsetY - h * proH / 2;
                    //拖拽边界判断
                    if (imgOffsetX <= 0) {
                        imgOffsetX = 0;
                    }

                    if (imgOffsetX >= slideW - w * proW) {
                        imgOffsetX = slideW - w * proW;
                    }

                    if (imgOffsetY <= 0) {
                        imgOffsetY = 0;
                    }

                    if (imgOffsetY >= (slideH - h * proH)) {
                        imgOffsetY = slideH - h * proH;
                    }
                    x = unitTransformPx(imgOffsetX / proW);
                    y = unitTransformPx(imgOffsetY / proH);
                }

                if(arr.mm1>=currentPage.trimbox.width){
                    x = currentPage.trimbox.x;
                }

                if(arr.mm2>=currentPage.trimbox.height){
                    y = currentPage.trimbox.y;
                }

                currentLevel.imageart = ({
                    geometry: {
                        x: unitTransform(x),
                        y: unitTransform(y),
                        width: w,
                        height: h
                    },
                    rotation: {
                        angle: 0
                    },
                    type: 'dynamic',
                    resource: {
                        identifier: sticker.identifier,
                        provider: 'qiniu',
                        width: img.width,
                        height: img.height
                    },
                    scale: 1,
                    offsetx: 0,
                    offsety: 0,
                    isArt: false,
                    levelI: $scope.template.levelI,
                    pageI: $scope.pageI,
                    transparency: 1,
                    onlyshow: false,
                    movable: true,
                    editable: true,
                    useradded:true
                });

                getChooseBox(currentLevel.imageart, 'imageart');
                uploadRightPlanel();
                updateHistorys();
                $scope.$apply();
            }
        };

        //去除所有html标签，只保留文字
        var reg=/<\/?.+?\/?>/g;
        var reg2=/<[^>]+>/g;
        // 过滤style行内标签
        var reg1=/ style\s*?=\s*?(['"])[\s\S]*?\1/g;
        // /<(?!\/?br\/?.+?>|\/?img.+?>)[^<>]*>/gi
        window.textPaste = function (e) {
            if(vm.textFont&&$scope.selectBox.current=='textbox'){
                setTimeout(function () {
                    var textSpan = $(".textbox" + $scope.pageI+'-' + $scope.arrI + $scope.template.levelI).find('.c-xml-page-textboxe-span')[0];
                    textSpan.innerHTML = textSpan.innerHTML.replace(/&nbsp;/g,' ').replace(/&amp;/g,'&').replace(reg1,'').replace(/<br>/g,'\n').replace(reg2,'');

                    sizeHeight($scope.selectBox, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
                    if (window.getSelection) {
                        window.getSelection().modify("move", "forward", "documentboundary");
                    }
                },200)
            }

            if(vm.textFont&&($scope.selectBox.current=='textdocument'||$scope.selectBox.parentCurrent=='textdocument')){
                setTimeout(function () {
                    var textDocSpan = $(".textbox" + $scope.pageI+'-' + $scope.arrI + $scope.template.levelI + $scope.docI).find('.c-xml-page-textboxe-span')[0];

                    var box =null;
                    if($scope.selectBox.current=='textdocument'){
                        box = $scope.selectBox.levels.level[$scope.docI].textbox;
                    }else{
                        box = $scope.selectBox;
                    }


                    textDocSpan.innerHTML = textDocSpan.innerHTML.replace(/&nbsp;/g,' ').replace(/&amp;/g,'&').replace(reg1,'').replace(/<br>/g,'\n').replace(reg2,'');
                    sizeHeight(box, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI + $scope.docI);
                    if (window.getSelection) {
                        window.getSelection().modify("move", "forward", "documentboundary");
                    }
                },200)
            }
        }


        //图片占位框点击时
        $scope.selectArt = function (e, $index, arrIndex, outerIndex, imageart) {
            vm.isTransp = false;
            if(vm.fontOn||vm.fontDocOn){
                return
            }
            $scope.hasImgUrl = false;
            $scope.template.levelI = $index;
            $scope.arrI = arrIndex;
            $scope.pageI = outerIndex;
            $scope.selectBox = imageart;
            scrollIntoView();
            removeContenteditable();
            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                imageart.ctrlKey = true;
                vm.CtrlKeyArray.imagearts[$index] = angular.copy(imageart);
                ctrlAddSingleArray();
            } else {
                if(!imageart.ctrlKey){
                    resetCtrlKeyArray();
                }
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                vm.singleArray.imageart = angular.copy(imageart);
            }
            var page = $scope.document.pages[$scope.pageI];
            if ($scope.arrI == 1) {
                $scope.currentPage = page.arr;
            } else {
                $scope.currentPage = page.arr2;
            }
            e.stopPropagation();
        };

        // 素材鼠标事件
        var dragArt = {};
        $scope.imageartdown = function (e, item, slide,arrindex,index,outerIndex) {
            if(vm.fontOn || vm.fontDocOn || item.lock || !item.movable){
                return
            }
            fn(e)
            $scope.hasText = false;
            $scope.hasImgUrl = false;
            $scope.arrI = arrindex;
            $scope.template.levelI = index;
            $scope.pageI = outerIndex;
            var page = $scope.document.pages[$scope.pageI];
            $scope.currentPage = slide;
            getChooseBox(item, 'imageart');
            removeContenteditable();

            dragArt.slideW = $(".slide" + $scope.arrI + $scope.pageI).width();
            dragArt.slideH = $(".slide" + $scope.arrI + $scope.pageI).height();
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragArt.ctrlKey = true;
            } else {
                dragArt.ctrlKey = false;
            }
            boxWebdown($scope, e,item, $scope.currentPage,$scope.currentPage.pageScaleW * page.scale, dragArt,vm.CtrlKeyArray, function () {
                $scope.isMoveOut = false;
                uploadRightPlanel();
                updateHistorys();
            })
        }


        var isBoxDown = false;
        function boxWebdown($scope, $event, item, currentPage, ratio,dragBox,CtrlKeyArray, callback) {
            var x = $event.clientX;
            var y = $event.clientY;
            var l = item.geometry.x * ratio;
            var t = item.geometry.y * ratio;
            var r = (currentPage.trimbox.width - item.geometry.width + currentPage.trimbox.x) * ratio;
            var b = (currentPage.trimbox.height - item.geometry.height + currentPage.trimbox.y) * ratio;
            if (item.rotation.angle >= 360) {
                item.rotation.angle %= 360;
            }
            //开关打开
            isBoxDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (e.target.nodeName == 'SPAN') {
                    return;
                }
                if (isBoxDown) {

                    //获取x和y
                    var nx = e.clientX;
                    var ny = e.clientY;
                    //计算移动后的左偏移量和顶部的偏移量
                    var nl = nx - (x - l);
                    var nt = ny - (y - t);
                    var imgWidth = item.geometry.width * ratio;
                    var imgHeight = item.geometry.height * ratio;
                    var x1 = $scope.currentPage.trimbox.x * ratio;
                    var y1 = $scope.currentPage.trimbox.y * ratio;

                    // if (nl <= x1) {nl = x1;}
                    // if (nl >= r) {nl = r;}
                    //
                    // if (nt <= y1) {nt = y1;}
                    // if (nt >= b) {nt = b}

                    if (dragBox.ctrlKey) {
                        moveTogether($scope.currentPage, nx-x, ny-y, dragBox);
                    } else {
                        item.geometry.x = nl / ratio;
                        item.geometry.y = nt / ratio;
                    }
                    $scope.isMoveOut = !editboxIn($scope.currentPage.editboxes.editbox , item, item.rotation.angle);
                    $scope.$digest();

                }

            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if (dragBox.ctrlKey) {
                    moveTogetherEnd()
                }
                if(isBoxDown){
                    callback()
                }
                isBoxDown = false;
                dragBox = {};
                $event.stopPropagation();
            }
        }


        /**
         *放大素材
         * @param e
         */
        window.resizeArtStart = function (e) {
            fn(e);
            $scope.hasText = false;
            $scope.hasImgUrl = false;
            var targ = e.target ? e.target : e.srcElement;
            dragMask.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragMask.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragMask.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft + 10;        //鼠标到模板左边的距离
            dragMask.coordY = targ.offsetParent.offsetTop + targ.offsetTop + 10;         //鼠标到模板顶部的距离
            dragMask.maxX = $(".slide" + $scope.arrI + $scope.pageI).width();
            dragMask.maxY = $(".slide" + $scope.arrI + $scope.pageI).height();

            var imgIndex = targ.dataset.index || $scope.template.levelI;
            $scope.template.levelI = imgIndex;
            var imageart = $scope.currentPage.levels.level[imgIndex].imageart;

            getChooseBox(imageart, 'imageart');
            if (imageart.lock) {
                return
            }
            dragFlag = true;
            dragMask.orignW = imageart.geometry.width;
            dragMask.orignH = imageart.geometry.height;
            dragMask.orignSourceW = imageart.resource.widthPx;
            dragMask.orignSourceH = imageart.resource.heightPx;
            dragMask.orignOffsetx = imageart.offsetx;
            dragMask.orignOffsety = imageart.offsety;
            document.addEventListener("mousemove", resizeArtOn, true);
            document.addEventListener("mouseup", resizeArtEnd, true);
            // setZindex(e.target.dataset.index);
        };

        function resizeArtOn(e) {
            if (dragFlag) {
                fn(e);
                // 是否按住shift键
                dragMask.shiftKey = e.shiftKey;
                var page = $scope.document.pages[$scope.pageI];
                var currentPage = $scope.currentPage;
                if (currentPage == null) {
                    return;
                }
                var pressArt = currentPage.levels.level[$scope.template.levelI].imageart;
                if (pressArt == null) {
                    return;
                }

                var w = (dragMask.coordX + 10 + e.clientX - dragMask.clientX - pressArt.geometry.x * currentPage.pageScaleW * page.scale) / (currentPage.pageScaleW * page.scale);

                var h = w / pressArt.geometry.width * pressArt.geometry.height

                if (w < 40 || h < 40) {
                    return
                }

                var moveW = (dragMask.maxX - pressArt.geometry.x * currentPage.pageScaleW * page.scale) / (currentPage.pageScaleW * page.scale);
                var moveH = (dragMask.maxY - pressArt.geometry.y * currentPage.pageScaleH * page.scale) / (currentPage.pageScaleW * page.scale);

                if (w < moveW && h < moveH) {
                    pressArt.geometry.width = w;
                    pressArt.geometry.height = h;

                    var scaleW = pressArt.geometry.width / dragMask.orignW;
                    var scaleH = pressArt.geometry.height / dragMask.orignH
                    pressArt.offsetx = scaleW * dragMask.orignOffsetx;
                    pressArt.offsety = scaleH * dragMask.orignOffsety;
                }
            }
            $scope.$digest();
            return false;
        }

        function resizeArtEnd() {
            dragFlag = false
            uploadRightPlanel();
            updateHistorys();
            document.removeEventListener("mousemove", resizeArtOn, true);
            document.removeEventListener("mouseup", resizeArtEnd, true);
            $scope.$digest();
        }

        //素材缩放right
        var isZoomDown = false;
        $scope.imageartdownZoomRight = function($event, item){
            // if(item.movable){return}
            fn($event)
            var page = $scope.document.pages[$scope.pageI];
            imageartZoom($scope, $event, item, 'right', $scope.currentPage.pageScaleW * page.scale, $scope.currentPage.trimbox, function () {
                updateHistorys()
            });
            editorBoxZoom($event, item, 'rightBottom', $scope.currentPage.pageScaleW * page.scale, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$apply();
            }, function (it) {
                updateHistorys()
            });
        }
        //素材缩放left
        $scope.imageartdownZoomLeft = function ($event, item) {
            // if(item.movable){return}
            fn($event)
            var page = $scope.document.pages[$scope.pageI];

            editorBoxZoom($event, item, 'leftTop', $scope.currentPage.pageScaleW * page.scale, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$apply();
            }, function (it) {
                updateHistorys()
            });
        }
        //素材缩放top
        $scope.imageartdownZoomTop = function ($event, item) {
            // if(item.movable){return}
            fn($event)
            var page = $scope.document.pages[$scope.pageI];
            editorBoxZoom($event, item, 'rightTop', $scope.currentPage.pageScaleW * page.scale, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$apply();
            }, function (it) {
                updateHistorys()
            });
        }
        //素材缩放bottom
        $scope.imageartdownZoomBottom = function ($event, item) {
            // if(!item.movable){return}
            fn($event)
            var page = $scope.document.pages[$scope.pageI];
            editorBoxZoom($event, item, 'leftBottom', $scope.currentPage.pageScaleW * page.scale, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$apply();
            }, function (it) {
                updateHistorys()
            });
        }


        //素材旋转
        $scope.rotateArtStart = function($event, item,slide){
            $scope.currentPage = slide;
            var page = $scope.document.pages[$scope.pageI];
            if(item.lock){return}
            imageartWebRotate($scope, $event, item, $scope.currentPage.pageScaleW * page.scale, function () {
                uploadRightPlanel();
                updateHistorys();
            })
            fn($event);
        }
        function getAngle(px,py,mx,my){//获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
            var x = Math.abs(px-mx);
            var y = Math.abs(py-my);
            var z = Math.sqrt(Math.pow(x,2)+Math.pow(y,2));
            var cos = y/z;
            var radina = Math.acos(cos);//用反三角函数求弧度
            var angle = Math.floor(180/(Math.PI/radina));//将弧度转换成角度
            if(mx<px&&my>py){//鼠标在第三象限
                angle = angle;
            }
            if(mx<px&&my<py){
                angle = 180 - angle;
            }
            if(mx>px&&my<py){
                angle = 180 + angle;
            }
            if(mx>px&&my>py){
                angle = 360 - angle;
            }
            return angle;
        }

        window.doubleClickClipart = function (e, item) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            var imageart = $scope.currentPage.levels.level[$scope.template.levelI].imageart;
            $scope.template.angle = imageart.rotation.angle;

            if (!imageart.editable) {
                return
            }


            vm.isImageartTrim = true;
            vm.imageartTrimIndex = angular.copy($scope.template.levelI);
            vm.imageartTrim = angular.copy(imageart);
            vm.imageartTrim.geometry.width1 = angular.copy(imageart.geometry.width);
            vm.imageartTrim.geometry.height1 = angular.copy(imageart.geometry.height);
            vm.imageartTrim.geometry.x1 = angular.copy(imageart.geometry.x);
            vm.imageartTrim.geometry.y1 = angular.copy(imageart.geometry.y);

            var pro = $scope.currentPage.pageScaleW;
            if(vm.imageartTrim.geometry.width * pro == vm.imageartTrim.geometry.width1 * pro * vm.imageartTrim.scale){
                vm.imageartTrim.geometry.width = vm.imageartTrim.geometry.width * 0.8;
                vm.imageartTrim.geometry.height = vm.imageartTrim.geometry.height * 0.8;
                vm.imageartTrim.geometry.x = Number(vm.imageartTrim.geometry.x) + Number(vm.imageartTrim.geometry.width / 0.8 * 0.2 / 2);
                vm.imageartTrim.geometry.y = Number(vm.imageartTrim.geometry.y) + Number(vm.imageartTrim.geometry.height / 0.8 * 0.2 / 2);
            }
            $scope.$digest();
        }

        //拖动素材裁切
        var isImageartTrim = false;
        $scope.imageartTrimdown = function ($event) {
            fn($event)
            var pro = $scope.currentPage.pageScaleW*$scope.document.pages[$scope.pageI].scale;
            var l = vm.imageartTrim.geometry.x * pro;
            var t = vm.imageartTrim.geometry.y * pro;
            var x = $event.clientX;
            var y = $event.clientY;
            var r = (vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale - vm.imageartTrim.geometry.width) * pro;
            var b = (($("#c-xml-imageart-trim-img").find('img').height()) / pro - vm.imageartTrim.geometry.height) * pro;

            var slide1W = $(".slide1" + $scope.pageI).width();

            var x1 = (Number(vm.imageartTrim.geometry.x1) + Number(vm.imageartTrim.offsetx)) * pro;
            var y1 = (Number(vm.imageartTrim.geometry.y1) + Number(vm.imageartTrim.offsety)) * pro;
            var x2 = vm.imageartTrim.geometry.x1 * pro;
            var y2 = vm.imageartTrim.geometry.y1 * pro;
            var x3 = vm.imageartTrim.offsetx * pro;
            var y3 = vm.imageartTrim.offsety * pro;
            if ($scope.arrI == 2) {
                x1 += slide1W;
                x2 += slide1W;
                l += slide1W;
            }
            //开关打开
            isImageartTrim = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrim == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - (x - l);
                var nt = ny - (y - t);

                if ($scope.arrI == 2) {
                    if (nl < x1) {
                        nl = x1
                    }
                    ;
                    if (nl > Number(r) + Number(x1)) {
                        nl = Number(r) + Number(x1)
                    }
                    ;
                    vm.imageartTrim.geometry.x = (nl - slide1W) / pro;
                } else {
                    if (nl < x1) {
                        nl = x1
                    }
                    ;
                    if (nl > Number(r) + Number(x1)) {
                        nl = Number(r) + Number(x1)
                    }
                    ;
                    vm.imageartTrim.geometry.x = nl / pro;
                }

                if (nt < y1) {
                    nt = y1
                }
                ;
                if (nt > Number(b) + Number(y1)) {
                    nt = Number(b) + Number(y1)
                }
                ;

                vm.imageartTrim.geometry.y = nt / pro;

                $("#c-xml-imageart-trim-frame").css({
                    left: nl + 'px',
                    top: nt + 'px',
                });
                $("#c-xml-imageart-trim-frame-img").css({
                    left: Number(x2 - nl) + Number(x3) + 'px',
                    top: Number(y2 - nt) + Number(y3) + 'px',
                });
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrim = false;
                $event.stopPropagation();
            }
        }

        //素材裁切放大缩小
        var isImageartTrimRight = false;
        $scope.imageartTrimRight = function ($event, item) {
            fn($event)
            var x = $event.clientX;
            var y = $event.clientY;
            var pro = $scope.currentPage.pageScaleW*$scope.document.pages[$scope.pageI].scale;

            var nw = Number(item.geometry.width * pro) + Number(item.geometry.x * pro);
            var nh = Number(item.geometry.height * pro) + Number(item.geometry.y * pro);
            var _l = item.geometry.x * pro;
            var _t = item.geometry.y * pro;

            var r = Number(item.geometry.x1 * pro) + Number(item.offsetx * pro) + Number(item.geometry.width1 * pro * item.scale);
            var b = Number(item.geometry.y1 * pro) + Number(item.offsety * pro) + Number($("#imageartTrimRight1").height());

            //开关打开
            isImageartTrimRight = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImageartTrimRight == false) {
                    return;
                }

                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = Number(nw) + Number(nx - x);
                var nt = Number(nh) + Number(ny - y);

                if(nl >= r){nl = r}
                if(nt >= b){ nt = b}

                if(nl - _l<=20){
                    nl = _l+20
                }
                if(nt - _t<=20){
                    nt = _t+20
                }

                item.geometry.width = (nl - _l) / pro;
                item.geometry.height = (nt - _t) / pro;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImageartTrimRight = false;
                $event.stopPropagation();
            }
        }
        $scope.imageartTrimTrueClick = function () {
            vm.isImageartTrim = false;
            var pro = $scope.currentPage.pageScaleW
            vm.imageartTrim.scale = (vm.imageartTrim.geometry.width1 * pro * vm.imageartTrim.scale) / (vm.imageartTrim.geometry.width * pro);
            vm.imageartTrim.offsetx = (Number(-(vm.imageartTrim.geometry.x - vm.imageartTrim.geometry.x1)) + Number(vm.imageartTrim.offsetx)).toFixed(2)-0;
            vm.imageartTrim.offsety = (Number(-(vm.imageartTrim.geometry.y - vm.imageartTrim.geometry.y1)) + Number(vm.imageartTrim.offsety)).toFixed(2)-0;
            vm.imageartTrim.geometry.x = $scope.currentPage.levels.level[vm.imageartTrimIndex].imageart.geometry.x;
            vm.imageartTrim.geometry.y = $scope.currentPage.levels.level[vm.imageartTrimIndex].imageart.geometry.y;
            if(vm.imageartTrim.offsetx > 0){
                vm.imageartTrim.offsetx = 0;
            }
            if(vm.imageartTrim.offsety > 0){
                vm.imageartTrim.offsety = 0;
            }
            if(vm.imageartTrim.scale<1){
                vm.imageartTrim.scale = 1;
            }
            $scope.currentPage.levels.level[vm.imageartTrimIndex].imageart = vm.imageartTrim;
        }
        $scope.imageartTrimFalseClick = function () {
            vm.isImageartTrim = false;

        }


        /************************贴纸功能************end************************/


        /****************************文字处理***********start**************************/

        //增加文本框
        $scope.addText = function (size, e) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            var currentPage = $scope.currentPage;
            $scope.size = size;
            var page = $scope.document.pages[$scope.pageI];
            var currentLevel = addLevel(currentPage);
            var proW = currentPage.pageScaleW * page.scale;
            var proH = currentPage.pageScaleH * page.scale;
            var slideW = $(".slide" +$scope.arrI + $scope.pageI).width();
            var slideH = $(".slide" +$scope.arrI+ $scope.pageI).height();

            textboxWebAdd(vm.defaultFont, vm.fontList[0].fontStyles, size, function (_font) {
                _font.pageI = $scope.pageI;
                _font.levelI = $scope.template.levelI = $scope.currentPage.levels.level.length-1;
                _font.current = 'textbox';
                currentLevel.textbox = _font;

                $(".block-width,.block-height").css({left: 0});
                $scope.template.letterspacing = $scope.template.lineheight = 0;

                $(".space-slider").addClass('hide1');
                $(".nav_spacing").removeClass('nav-spacing-active');
                $scope.hasText = true;
                $scope.hasImgUrl = false;

                vm.fontHide = currentLevel.textbox;
                $scope.ratio = currentPage.ratio;
                $timeout(function () {
                    var DivRect = $("#c-xml-page-material-fontId")[0].getBoundingClientRect();
                    var geo = currentLevel.textbox.geometry;
                    geo.width = Number((DivRect.width||60)) + Number(10);
                    geo.height = Number((DivRect.height||20)) + Number(5);

                    var x = unitTransformPx((slideW - geo.width * proW)/2/proW)+$scope.template.levelI;
                    var y = unitTransformPx((slideH - geo.width * proH)/2/proH)+$scope.template.levelI;

                    var offsetx = 0, offsety = 0;
                    if (e) {
                        offsetx = e.offsetx - 100 / 2;
                        offsety = e.offsety - 24 / 2;
                        //拖拽边界判断
                        if (offsetx <= 0) {
                            offsetx = 0;
                        }

                        if (offsetx >= (slideW - 100)) {
                            offsetx = slideW - 100;
                        }

                        if (offsety <= 0) {
                            offsety = 0;
                        }

                        if (offsety >= (slideH - 24)) {
                            offsety = slideH - 24;
                        }
                        x = unitTransformPx(offsetx / proW);
                        y = unitTransformPx(offsety / proH);
                    }

                    geo.x = unitTransform(x);
                    geo.y = unitTransform(y);
                    $scope.currentPage = currentPage;
                    $scope.selectBox = currentLevel.textbox;
                    // $scope.item = vm.defaultFont;
                    angular.forEach(vm.fontList, function (list) {
                        if (list.uuid == currentLevel.textbox.fontuuid) {
                            $scope.item = list
                        }
                    })
                    if (vm.fontuuids.indexOf(currentLevel.textbox.fontuuid) == -1) {
                        vm.fontuuids.push(currentLevel.textbox.fontuuid);
                        getFont(currentLevel.textbox,true);
                    }else{
                        currentLevel.textbox.loading = false;
                    }
                    FontSupport2(FontManagement, currentLevel.textbox)
                    fontStyle(currentLevel.textbox);
                    uploadRightPlanel();
                })
            })
        };

        function fontStyle(font) {
            if(!vm.fontList){
                return
            }
            var _fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == font.fontuuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        _fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            font.style.bold1 = false;
            font.style.italic1 = false;
            if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                font.style.bold1 = true;
                font.style.italic1 = true;
            } else {
                if (_fontStyle.indexOf('ITALIC') != '-1') {
                    font.style.italic1 = true;
                }
                if (_fontStyle.indexOf('BOLD') != '-1') {
                    font.style.bold1 = true;
                }
            }
        }

        $scope.updateTextSize = function (value) {
            var text = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            if (text.lock) {
                return
            }

            text.style.size = value;
            text.style.lineheight = value;

            var page = $scope.document.pages[$scope.pageI];
            var pro = $scope.currentPage.pageScaleW * page.scale;
            var preDiv = $(".textbox" + $scope.pageI+'-' + $scope.arrI + $scope.template.levelI).find('pre')[0];
            var arr = page.arr
            if ($scope.arrI == 2) {
                arr = page.arr2
            }

            setTimeout(function () {
                var minSize = (parseFloat(preDiv.style.fontSize)*arr.ratio*2+parseFloat(preDiv.style.letterSpacing)*arr.ratio*2)* 72 / vm.windowDpiW;
                if(text.geometry.width*pro < minSize){
                    text.geometry.width = minSize / pro;
                }
                sizeHeight(text, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
                updateHistorys();
            })
        };

        $('.color-show').hover(function () {
            $('.tip1').show();
        }, function () {
            $('.tip1').hide();
        });

        $scope.updateTextColor = function () {
            $(".color-list").toggleClass('hide1');
            $(".nav_spacing").toggleClass('nav-spacing-active');
        };

        $scope.updateTextFont = function (e,data) {
            if (data == undefined) {
                MessageService.error("文字添加失败，请上传字体!");
                return
            }
            if (!data.enable) {
                MessageService.error("当前字体不可用，请更换字体");
                return
            }
            var text = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            if (text.lock) {
                return
            }
            vm.fontStyle = [];
            for(var i=0; i<vm.fontList.length; i++){
                if(vm.fontList[i].uuid == data.uuid){
                    for(var j=0; j<vm.fontList[i].fontStyles.length; j++){
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold = false;
            text.style.italic = false;
            if(vm.fontStyle.indexOf('NORMAL') != '-1'){
                text.style.bold = false;
                text.style.italic = false;
            }else {
                if(vm.fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                    text.style.bold = true;
                    text.style.italic = true;
                }else {
                    if(vm.fontStyle.indexOf('ITALIC') != '-1'){
                        text.style.italic = true;
                    }
                    if(vm.fontStyle.indexOf('BOLD') != '-1'){
                        text.style.bold = true;
                    }
                }
            }
            text.style.font = data.family;
            text.fontuuid = data.uuid;
            text.identifier = $scope.domain + data.fontStyles[0].identifier;
            $scope.item = data;
            if (vm.fontuuids.indexOf(text.fontuuid) == -1) {
                vm.fontuuids.push(text.fontuuid);
                getFont(text,true);
            }
            fontStyle(text);
            // sizeHeight(text, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            vm.fontbox = text;
            vm.fontIndex = $scope.pageI + '-' + $scope.arrI + $scope.template.levelI;
            $scope.blur(e, vm.fontbox, vm.fontIndex)
        };

        function getFont(item,attr) {
            item.loading = true;
            FontManagement.getCss({uuid: item.fontuuid}, function (res) {
                var style = document.createElement("style");
                style.type = 'text/css';
                try {
                    style.appendChild(document.createTextNode(res.message));
                } catch (ex) {
                    style.stylesheet.cssText = res.message;
                }
                var Head = document.getElementsByTagName("head")[0];
                Head.appendChild(style);
                document.fonts.ready.then(function() {
                    item.loading = false;
                    if(attr){
                        updateHistorys();
                    }
                    $scope.$digest();
                });
            });
        }

        $scope.showTextSpacing = function () {
            if (!$scope.template.text.length) {
                return
            }
            $(".space-slider").toggleClass('hide1');
            $(".nav_spacing").toggleClass('nav-spacing-active');
        }

        $scope.spacingClick = function (e) {
            fn(e);
            var text = $scope.currentPage.levels.level[[$scope.template.levelI]].textbox;
            var targ = e.target ? e.target : e.srcElement;
            var value = targ.dataset.value;
            var l = 120 - Math.abs(e.offsetX);
            if (value == 'width') {
                text.style.letterspacing = $scope.template.letterspacing = l;
                $(".block-width").css({left: l});

            } else {
                if (l < text.style.size) {
                    MessageService.error("行间距有效范围：" + text.style.size + "~110");
                    $scope.template.lineheight = text.style.size;
                    return
                }
                text.style.lineheight = $scope.template.lineheight = l;
                $(".block-height").css({left: l});
            }

            reSetTextWidth(text);
        }

        $scope.spacingChange = function (attr) {
            var text = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            // if (text.lock) {
            //     return
            // }
            if (attr) {
                if ($scope.template.letterspacing > 110) {
                    MessageService.error("字间距有效范围：0~110");
                    $scope.template.letterspacing = 110;
                }
                text.style.letterspacing = $scope.template.letterspacing;
                $(".block-width").css({left: text.style.letterspacing});
            } else {
                if ($scope.template.lineheight > 110||$scope.template.lineheight < text.style.size) {
                    MessageService.error("行间距有效范围：" + text.style.size + "~110");
                    $scope.template.lineheight = text.style.size;
                }

                text.style.lineheight = $scope.template.lineheight;
                $(".block-height").css({left: text.style.lineheight});
            }

            reSetTextWidth(text);
        }

        function reSetTextWidth(text) {
            var page = $scope.document.pages[$scope.pageI];
            var pro = $scope.currentPage.pageScaleW * page.scale;
            var preDiv = $(".textbox" + $scope.pageI+'-' + $scope.arrI + $scope.template.levelI).find('pre')[0];
            var arr = page.arr
            if ($scope.arrI == 2) {
                arr = page.arr2
            }

            setTimeout(function () {
                var minSize = (parseFloat(preDiv.style.fontSize)*arr.ratio*2+parseFloat(preDiv.style.letterSpacing)*arr.ratio*2)* 72 / vm.windowDpiW;
                if(text.geometry.width*pro < minSize){
                    text.geometry.width = minSize / pro;
                }
                sizeHeight(text, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
                updateHistorys();
            })
        }

        // 字/行间距鼠标点击移动
        var spacingW = {};
        var flagSpacing = false
        window.resizeSpacingStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            spacingW.clientX = e.clientX || 0;
            spacingW.originLeft = targ.offsetLeft;
            spacingW.value = targ.dataset.value;
            var text = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            if (text.lock) {
                return
            }
            flagSpacing = true;
            document.addEventListener("mousemove", resizeSpacingOn, true);
            document.addEventListener("mouseup", resizeSpacingEnd, true);
        }

        function resizeSpacingOn(e) {
            if (flagSpacing) {
                var text = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
                var l = spacingW.originLeft + e.clientX - spacingW.clientX;
                if (spacingW.value == 'height' && l < text.style.size) {
                    l = text.style.size;
                } else if (spacingW.value == 'width' && l < 0) {
                    l = 0;
                }
                if (l > 110) {
                    l = 110
                }
                if (spacingW.value == 'width') {
                    $(".block-width").css({left: l});
                    text.style.letterspacing = $scope.template.letterspacing = l;
                } else {
                    $(".block-height").css({left: l});
                    text.style.lineheight = $scope.template.lineheight = l;
                }
                reSetTextWidth(text);
                $scope.$digest();
            }
        }

        function resizeSpacingEnd() {
            spacingW = {}
            flagSpacing = false;
            document.removeEventListener("mousemove", resizeSpacingOn, true);
            document.removeEventListener("mouseup", resizeSpacingEnd, true);
        }

        /********************************文字处理*********end***************************/


        /***********************************模板替换**********start****************************/
        /***********************************模板替换***********end*****************************/


        /***************************************相框操作************start****************************/
        function getMaskList(obj, attr) {
            angular.forEach(obj, function (mask, i) {
                var arr = {
                    identifier: mask.resource.identifier,
                    provider: mask.resource.provider,
                    resource: mask.resource
                }
                $scope.maskList.push(arr);
                if (attr == 'all') {
                    vm.masks = angular.copy($scope.maskList);
                }
            })
        }

        /***************************************相框操作*************end*****************************/


        /******************************************背景操作*************start*****************************/

        var tempBgimgs = [];

        $scope.selectBgLabel = function (label, i) {
            $scope.bglabelChooseId = label.id;
            $scope.bglabelChooseIndex = i;
            $scope.bgImgsList = [];
            vm.indexBgNum = 0;
            if (label.id == null) {
                $scope.bgImgsList = angular.copy(vm.bgimgs2)
                setTimeout(function () {
                    waterFall('main4');
                    var h = $(".leftPanel-main-wrap").height() - $(".bgimg-color").height() - $(".bgimg-head").height() - 60
                    $(".bgimg-con").height(h);
                }, 400);
            } else {
                getBackgrounds(label, Backgrounds, vm.indexBgNum, function (res) {
                    $scope.bgImgsList = res.arr;
                    vm.totalBgItems = res.items;
                    setTimeout(function () {
                        waterFall('main4');
                        var h = $(".leftPanel-main-wrap").height() - $(".bgimg-color").height() - $(".bgimg-head").height() - 60
                        $(".bgimg-con").height(h);
                    }, 300);
                });
            }

        };

        //模板预览点击时
        $scope.selectPages = function (page,e) {
            $scope.canvasCb()
            if(e){
                fn(e)
            }
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            $scope.template.levelI = null;
            vm.isTransp = false;
            // removeContenteditable();
            $scope.hasText = false;
            $scope.hasImgUrl = false;
            if (vm.isImageboxTrim) {
                $scope.imageboxFalseClick();
            }

            angular.forEach($scope.document.pages, function (p, i) {
                if (p.arr.seq == page.seq) {
                    $scope.pageI = i;
                    $scope.arrI = 1;
                    $scope.currentPage = p.arr;
                }
                if (p.arr2) {
                    if (p.arr2.seq == page.seq) {
                        $scope.pageI = i;
                        $scope.arrI = 2;
                        $scope.currentPage = p.arr2;
                    }
                }
            })
            isCanLeftAndRightPage();
        };


        /**
         * 切换当前页所使用的模板
         * @param index     点击模板页下标
         * @param page      被点击模板页
         */
        $scope.changeTemp = function (index, item) {
            $scope.canvasCb()
            // 两个page 相同时不交换
            if ($scope.currentPage.uuid == item.uuid) {
                MessageService.error('已在当前页，不可替换')
                return;
            }
            var _index = angular.copy($scope.currentPage.seq - 1);
            getPage(item,_index,function (page){
                $scope.hasText = false;
                $scope.hasImgUrl = false;
                var seq = $scope.currentPage.seq;
                var currentPage = angular.copy($scope.currentPage);
                // 模板选中状态判断条件
                $scope.selectedLi = page.uuid;

                //使用次数+1
                page.count++;

                // 拷贝被点击模板     page不能受影响
                var clickPage = angular.copy(page);

                var smallI = 0;

                angular.forEach(currentPage.levels.level, function (level) {
                    // 用户添加的"素材"需要留在当前页面
                    if (level.imageart && level.imageart.geometry) {
                        var imageart = level.imageart;
                        if (imageart.useradded) {
                            var currentLevel = addLevel(clickPage);
                            currentLevel.imageart = imageart;
                        }
                    }

                    // 用户添加的"文字"需要留在当前页面
                    if (level.textbox && level.textbox.geometry) {
                        var textbox = level.textbox;
                        if (textbox.useradded) {
                            var currentLevel = addLevel(clickPage);
                            currentLevel.textbox = textbox;
                        }
                    }
                })

                // 开始交换
                $scope.currentPage = angular.copy(clickPage);
                $scope.currentPage.backup = false;
                // $scope.currentPage.uuid = page.uuid;

                // 占位框从图片 有---->无 排序
                var arrs = [];

                angular.forEach(currentPage.levels.level, function (level) {
                    if (level.imagebox && level.imagebox.geometry) {
                        var imagebox = level.imagebox;
                        if (imagebox.image && imagebox.image.resource && imagebox.image.resource.identifier) {
                            if ($scope.photos.length && $scope.photos[imagebox.image.index]) {
                                $scope.photos[imagebox.image.index].usenum == 0 ? 0 : $scope.photos[imagebox.image.index].usenum--;
                            }
                            arrs.push(imagebox)
                        }
                    }
                })

                var i = 0;
                angular.forEach($scope.currentPage.levels.level, function (level) {
                    if (level.imagebox && level.imagebox.geometry) {
                        var imagebox = level.imagebox;
                        if (arrs[i]) {
                            verifyPic(arrs,i,imagebox)
                        } else {
                            // imgScale(imagebox, 0, 0, false);
                        }
                        i++;
                    }
                })

                $scope.currentPage.seq = seq;
                var docpage = $scope.document.pages[$scope.pageI]
                if ($scope.arrI == 2) {
                    docpage.arr2 = $scope.currentPage;
                } else {
                    docpage.arr = $scope.currentPage;
                }

                var pageW = docpage.width,
                    pageH = docpage.height;

                var backg = $scope.currentPage.background;
                if (backg.resource&&backg.resource.identifier) {
                    getBackgroundSource(backg.resource, pageW, pageH, backg)
                }

                setTimeout(function () {
                    $scope.template.pages.page[$scope.currentPage.seq-1] = $scope.currentPage;
                    // 使用次数-1
                    for (var i = $scope.pageAllMm.page.length - 1; i >= 0; i--) {
                        var element = $scope.pageAllMm.page[i];
                        if ($scope.currentPage.uuid == element.uuid) {
                            element.count++;
                        }
                    }
                    getUsedImageboxsNum();
                    $scope.$digest();
                },600)

                updateHistorys();
                // setGalleryStorage();
                // singleToRemember();
            })
        };

        function verifyPic(arrs,i,imagebox){
            $.ajax({
                type: "GET",
                url: arrs[i].image.resource.identifier + "?imageInfo",
                success: function (va) {
                    if(va.format=='heic'){
                        swal({
                            title: "照片格式错误",
                            text: "请更换使用JPG格式图片添加至相框！",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        // i++;
                        // return
                    }else{
                        imagebox.blurred = $scope.productBokeh;
                        imagebox.bokehtype = $scope.productBokehType || 'None';
                        if ($scope.productBokeh) {
                            imagebox.blurredrectangle = {
                                x: 0,
                                y: 0,
                                width: imagebox.geometry.width,
                                height: imagebox.geometry.height
                            }
                        }
                        imagebox.imgUrl = arrs[i].imgUrl;
                        imagebox.image = angular.copy(arrs[i].image);
                        imagebox.image.angle = 0;

                        imgScale(imagebox, 0, 0, false, 'center');
                        if (imagebox.image && $scope.photos.length && $scope.photos[imagebox.image.index]) {
                            $scope.photos[imagebox.image.index].usenum++;
                        }
                    }


                }
            });
        }


        $scope.showCategories = false;
        $scope.showMoreCategories = function () {
            $scope.showCategories = !$scope.showCategories;
        }

        $scope.selectCategory = function (c) {
            $scope.categoryNow = {
                label: c
            };
        };

        $scope.changeAllTemp = function (index, temp) {
            if (temp.id == vm.xmlId) {
                swal({
                    title: "替换失败",
                    text: "已在当前模板，请选择其他模板！",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }

            swal({
                title: '温馨提示',
                type: 'info',
                text: '更换模板后，除了所导入照片外其他所有编辑内容将丢失并且无法恢复，确定要更换？',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }, function (isConfirm) {
                if (isConfirm) {
                    swal.close();
                    $state.go("page-my-suffix-edit", {
                        storeId: vm.storeId,
                        pid: vm.article.productId,
                        vid: vm.vid,
                        xmlId: temp.id,
                        soid: vm.soid,
                        plid: vm.plid
                    });
                } else {
                    console.log('取消');
                }
            })
        }

        //预览
        $scope.tempPreview = function (temp) {
            $uibModal.open({
                templateUrl: 'app/entities/commodity/commodity-preview.html',
                controller: 'CommodityPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            type: 'temp',
                            storeId :vm.storeId,
                            pid :vm.pid,
                            productXmlLine: temp,
                            vid: vm.vid,
                            editorType: vm.article.editorType
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (backData) {
                console.log(backData);
            });
        };
        /******************************************背景操作**************end******************************/

        $scope.choosePhoto = function (photo) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            photo.select = !photo.select;
            angular.forEach($scope.photos, function (p, i) {
                // p.index = i;
                if (p.select) {
                    $scope.gallaryPic.push(p);      //选择导入图片的数组
                    $scope.moveGalleryIds.push(p.id);
                }
            });
        }

        $scope.whetherChoose = function (attr) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            // if($scope.selectAll == attr){
            //     return
            // }
            // $scope.selectAll = attr;
            $scope.photos.some(function (p, i) {
                if (attr) {
                    p.select = true;
                    $scope.gallaryPic.push(p);      //选择导入图片的数组
                    $scope.moveGalleryIds.push(p.id);
                } else {
                    p.select = false;
                    $scope.gallaryPic = [];      //选择导入图片的数组
                    $scope.moveGalleryIds = [];
                }
            })
        }

        $scope.directFill = function () {
            if(vm.forceFill && $scope.gallaryPic.length < (vm.counts-vm.usedImageboxsNum)){
                MessageService.error("请选择" + (vm.counts-vm.usedImageboxsNum) + "张照片，再尝试一键填充！");
                return;
            }
            var page = $scope.document.pages[$scope.pageI];
            var arr = page.arr;
            if ($scope.arrI == 2) {
                arr = page.arr2;
            }
            $scope.currentPage = arr;

            $scope.hasImgUrl = true;
            if ($scope.gallaryPic && $scope.gallaryPic.length > 0) {
                $scope.picArr = angular.copy($scope.gallaryPic);
                dragChange();
                $scope.gallaryPic = [];
                $scope.moveGalleryIds = [];
                angular.forEach($scope.photos, function (p, i) {
                    p.select = false;
                });

                if (vm.photosTab != 'all') {
                    setTimeout(function () {
                        waterFallPic('main5');
                        $(".photo-imgs").height($scope.photoHight);
                    }, 200);
                }
                // setTimeout(function () {
                getUsedImageboxsNum();
                updateHistorys();
                // },600)

            }
        }

        $scope.deleteBatchPhotos = function () {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error("操作失败,请至少选择一个照片");
                return
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'删除照片将移除作品中正在使用的图片，确认删除么'
            },function(){
                angular.forEach($scope.moveGalleryIds, function(id){
                    angular.forEach($scope.template.pages.page, function (item, index) {
                        angular.forEach(item.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resourceid == id) {
                                var box = level.imagebox
                                box.blurredrectangle = {
                                    x: 0,
                                    y: 0,
                                    width: box.geometry.width,
                                    height: box.geometry.height
                                }
                                box.imgUrl = "content/images/mask_souce.png";
                                box.image = {
                                    resource: null,
                                    filterResource: {
                                        identifier: '',
                                        provider: ''
                                    },
                                    offsetx: 0,
                                    offsety: 0,
                                    width: '',
                                    height: '',
                                    imgsize: '',
                                    index: '',
                                    parameter: [],
                                    scale: 1,
                                    angle: 0,
                                }
                            }
                        })
                    });
                });
                getUsedImageboxsNum();
                Gallery.batchDelete($scope.moveGalleryIds, function () {
                    MessageService.success("删除成功！");
                    // 若被选中的pic被删除，就重新生成该数组
                    $scope.gallaryPic = [];
                    $scope.moveGalleryIds = [];
                    for(var i = $scope.photos.length-1;i>=0;i--){
                        var item = $scope.photos[i];
                        if (item.select) {
                            $scope.photos.splice(i, 1);
                        }
                    }
                    if($scope.photos.length==0){
                        vm.indexNum = -1;
                        loadMore();
                        return
                    }
                    setTimeout(function () {
                        waterFallPic('main5');
                        $(".photo-imgs").height($scope.photoHight);
                    }, 200);
                }, function (error) {
                    console.log(error)
                    MessageService.error("删除失败！");
                });
            })
        }

        $scope.loadMoreBackgrounds = function () {
            loadMore();
        }

        //更新所有占位框的使用照片图片
        function getUsedImageboxsNum() {
            vm.usedImageboxsNum= 0;
            vm.counts= 0;
            angular.forEach($scope.template.pages.page, function (item, index) {
                angular.forEach(item.levels.level, function (level) {
                    if (level.imagebox) {
                        vm.counts++;
                    }
                    if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                        vm.usedImageboxsNum++;
                    }
                })
            });

            if(vm.photosTab!='all'){
                vm.unUsedImageboxsNum = 0;
                angular.forEach($scope.photos,function (p) {
                    if(p.usenum==0){
                        vm.unUsedImageboxsNum++;
                    }
                })
                setTimeout(function () {
                    waterFallPic('main5');
                    $(".photo-imgs").height($scope.photoHight);
                }, 200);
            }
            hideChangeBtn();
        }

        vm.watchSure = false;
        $scope.watchArticle = function (){
            var savePages = [];
            angular.forEach($scope.document.pages, function (item) {
                savePages.push(item.arr);
                if (item.arr2) {
                    savePages.push(item.arr2);
                }
            });

            var template = {
                pages: {
                    page: savePages
                },
                bgimgs2: vm.sources.bgimgs2,
                documentpics: vm.sources.documentpics,
                masks: vm.sources.masks,
                cliparts: vm.sources.cliparts,
                replace: vm.replace,
                cover: vm.cover,
                version: '3.0'
            };

            template = saveData(template);

            vm.watchSure = true;
            vm.previewArticle = template;
        }

        vm.isSaving = false;
        $scope.exitWork = function (e) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            vm.exitSure = true;
        }

        // 保存判断
        $scope.saveWork = function (e) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            // 判断是否有图片未上传  且是否继续保存
            if(vm.usedImageboxsNum < vm.counts){
                completed = false;
                vm.saveSure = true;
            }else{
                completed = true;
                generateJsonFile(0);
            }
        };

        // 确认保存
        $scope.saveClick = function () {
            generateJsonFile(0);
            vm.saveSure = false;
        }

        // 即时保存
        $scope.immediateSave = function (e) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            if(vm.usedImageboxsNum < vm.counts){
                completed = false;
            }else{
                completed = true;
            }
            generateJsonFile(1);
        }

        var _index1=0;
        vm.isPageWarnExamine = false;
        $scope.generateJsonFile = generateJsonFile;
        //json下载文件中需要的内容
        function generateJsonFile(flag){
            var _documentTextDetection = documentTextDetection($scope.template.pages.page);
            if(!_documentTextDetection.pass){
                MessageService.error("第" + _documentTextDetection.pageIndex + '页的【' + _documentTextDetection.text + '】的字体不可用，请更换字体');
                return;
            }

            vm.deleteList = 0;
            var  gallieryIds = [];
            for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                var page = $scope.template.pages.page[i];
                if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                    continue;
                }
                for (var j = page.levels.level.length - 1; j >= 0; j--) {
                    var level = page.levels.level[j];
                    if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                        continue;
                    }
                    gallieryIds.push(level.imagebox.image.resourceid);
                }
            }
            if (gallieryIds.length>0) {
                Gallery.checkDeleted(gallieryIds, function(list){
                    // vm.deleteList = [{id:gallieryIds[0]}];
                    // vm.completed = false;
                    // vm.isDestroyed = true;
                    if (list == null|| list.length ==0) {
                        thumbnailInit1(function (){
                            generateJsonFile1(flag)
                        })
                    }else{
                        vm.deleteList = list;
                        vm.completed = false;
                        vm.isDestroyed = true;
                        return;
                    }
                }, function(){
                    MessageService.error("检测图片失败，请稍后再试。");
                })
            }else{
                thumbnailInit1(function (){
                    generateJsonFile1(flag)
                })
            }
        }
        function generateJsonFile1(flag) {
            vm.saveflag = flag;
            if(!vm.isPageWarnExamine && pageWarnExamine($scope.template.pages.page, vm.imgSize, vm.imgDpi)){
                $timeout(function () {
                    vm.isPageWarnExamine = true;
                })
                return
            }
            if(vm.isSaving){
                return;
            }
            var savePages = [];
            angular.forEach($scope.document.pages, function (item,i) {
                item.arr.arrI=1;
                item.arr.outerIndex=i;
                savePages.push(item.arr);
                if (item.arr2) {
                    item.arr2.arrI=2;
                    item.arr2.outerIndex=i;
                    savePages.push(item.arr2);
                }
            });
            if(!documentDetection(savePages).pass && vm.source){
                MessageService.error("第" + documentDetection(savePages).pageIndex + '页的图片未填充满，请调整');
                return;
            }


            var template = {
                pages: {
                    page: savePages
                },
                bgimgs2: vm.sources.bgimgs2,
                documentpics: vm.sources.documentpics,
                masks: vm.sources.masks,
                cliparts: vm.sources.cliparts,
                frameConfigs: vm.sources.frameConfigs,
                replace: vm.replace,
                cover: vm.cover,
                version: '3.0'
            };
            vm.isSaving = true;
            template = saveData(template);
            _index1=0;
            if(template.textRest){
                angular.forEach(template.pages.page, function (page, index) {
                    angular.forEach(page.levels.level, function (level, i) {
                        if(level.textbox&&level.textbox.reSet){
                            sizeHeight2(level.textbox, page.outerIndex + '-' + page.arrI + i);
                        }
                    })

                })
            }

            //作品保存的时候 将用户选择照片填入 文档
            $scope.qiniuId = JSON.parse(localStorage.getItem("gallery"));
            var newData = [];
            if(template.galleriesAll&&template.galleriesAll.length){
                for (var i = 0; i<template.galleriesAll.length ; i++) {
                    var pr = template.galleriesAll[i];
                    var result = ListService.inList(pr, newData, ["id"]);
                    if (!result){
                        newData.push(pr)
                    }else{
                        result.usenum++;
                    }
                }
                template.galleries = {gallery: newData};
            }

            console.log(template);
            console.log(savePages);
            console.log(newData);

            var aId = localStorage.getItem('save-article');
            if (aId) {
                vm.article.id = aId;
                $scope.update = true;
            }
            template.hostinfo = hostInfo();

            var _timer = setInterval(saveArticle, 400);

            function saveArticle() {
                if(template.textRestLen!=0 && template.textRestLen == _index1 || template.textRestLen==0){
                    window.clearInterval(_timer);
                    doSave()
                }
                console.log("setInterval")
            }

            function doSave() {
                if(vm.source){
                    OrderArticle.update({
                        id: vm.article.id,
                        name: vm.article.name,
                        completed: completed,
                        sku: vm.article.sku,
                        skuNumber: vm.article.skuNumber,
                        cover: vm.article.cover,
                        document: template,
                        fileName: vm.article.fileName,
                        productId: vm.article.productId,
                        productName: vm.article.productName,
                        categoryId: vm.article.categoryId,
                        categoryName: vm.article.categoryName,
                        categoryStoreId: vm.article.categoryStoreId,
                        categoryStoreName: vm.article.categoryStoreName,
                        folderId: vm.article.folderId,
                        itemValueId: vm.article.itemValueId,
                        themeClassifyId: vm.article.themeClassifyId,
                        themeClassifyName: vm.article.themeClassifyName,
                        productXmlId: vm.article.productXmlId,
                        productXmlName: vm.article.productXmlName,
                        musicIdentify: vm.article.musicIdentify,
                        editorType: vm.article.editorType,
                        productType: vm.article.productType,
                        ordered: vm.article.ordered,
                        unFilledCount: (vm.counts - vm.usedImageboxsNum < 0 ? 0 : vm.counts - vm.usedImageboxsNum),
                        version: '3.0'
                    }, function (resp) {
                        vm.isSaving = false;
                        // window.close();
                        swal({
                            title: "保存成功",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        $state.go("page-view2", {storeId: vm.storeId, orderArticleId: vm.orderArticleId, source: 'external'}, {reload: true});
                    }, function () {
                        swal({
                            title: "保存失败",
                            text: "保存出错了，请稍后再试，或者联系工作人员",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        vm.isSaving = false;
                    })
                    return
                }
                if ($scope.update && !vm.article.ordered) {
                    Article.update({
                        id: vm.article.id,
                        name: vm.article.name,
                        completed: completed,
                        sku: vm.article.sku,
                        skuNumber: vm.article.skuNumber,
                        cover: vm.article.cover,
                        document: template,
                        fileName: vm.article.fileName,
                        productId: vm.article.productId,
                        productName: vm.article.productName,
                        categoryId: vm.article.categoryId,
                        categoryName: vm.article.categoryName,
                        categoryStoreId: vm.article.categoryStoreId,
                        categoryStoreName: vm.article.categoryStoreName,
                        folderId: vm.article.folderId,
                        itemValueId: vm.article.itemValueId,
                        themeClassifyId: vm.article.themeClassifyId,
                        themeClassifyName: vm.article.themeClassifyName,
                        productXmlId: vm.article.productXmlId,
                        productXmlName: vm.article.productXmlName,
                        musicIdentify: vm.article.musicIdentify,
                        editorType: vm.article.editorType,
                        productType: vm.article.productType,
                        ordered: vm.article.ordered,
                        unFilledCount: (vm.counts - vm.usedImageboxsNum < 0 ? 0 : vm.counts - vm.usedImageboxsNum),
                        version: '3.0'
                    }, function (resp) {
                        vm.isSaving = false;
                        if (flag) {
                            MessageService.success("保存成功");
                            // localStorage.setItem('save-article',resp.id);
                        } else {
                            $scope.bool = false;
                            if(vm.plid){
                                SalesOrder.updateByArticle({id:vm.soid,productLineId:vm.plid,articleId:vm.article.id},function (da) {
                                    swal({
                                        title: "修改成功",
                                        text: "作品已同步到我的作品",
                                        timer: 2000,
                                        showConfirmButton: false
                                    });
                                    $(".wrap").show();
                                    $(".navbar").show();
                                    $state.go("page-view", {storeId: vm.storeId, aid: vm.article.id, plId:vm.plid, soId:vm.soid}, {reload: true});
                                })
                                return
                            }
                            if (vm.article.ordered) {
                                swal({
                                    title: "修改成功",
                                    text: "作品已同步到我的作品,由于作品已经被下单,所以系统会保留原作品，而生成新作品",
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                $(".wrap").show();
                                $(".navbar").show();
                                $state.go("webArticle", {storeId: vm.storeId});
                                // $state.go("webArticle", {storeId: vm.storeId}, {reload: true});
                            } else {
                                swal({
                                    title: "修改成功",
                                    text: "作品已同步到我的作品",
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                $(".wrap").show();
                                $(".navbar").show();
                                $state.go("page-view", {storeId: vm.storeId, aid: vm.article.id, plId:vm.plid, soId:vm.soid}, {reload: true});
                            }
                        }

                    }, function () {
                        swal({
                            title: "保存失败",
                            text: "保存出错了，请稍后再试，或者联系工作人员",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        vm.isSaving = false;
                    })
                } else {
                    var itemValueId = vm.vid;
                    if (vm.article.ordered && vm.article.itemValueId) {
                        itemValueId = vm.article.itemValueId;
                    }

                    Article.save({
                        name: vm.article.name,
                        sku: vm.article.sku,
                        skuNumber: vm.article.skuNumber,
                        productId: vm.article.productId,
                        productName: vm.article.productName,
                        cover: vm.article.cover,
                        completed: completed,
                        document: template,
                        itemValueId: itemValueId,
                        categoryId: vm.article.categoryId,
                        categoryName: vm.article.categoryName,
                        categoryStoreId: vm.article.categoryStoreId,
                        categoryStoreName: vm.article.categoryStoreName,
                        themeClassifyId: vm.article.themeClassifyId,
                        themeClassifyName: vm.article.themeClassifyName,
                        productXmlId: vm.article.productXmlId,
                        productXmlName: vm.article.productXmlName,
                        musicIdentify: vm.article.musicIdentify,
                        productType: vm.article.productType,
                        editorType: vm.article.editorType,
                        unFilledCount: (vm.counts - vm.usedImageboxsNum < 0 ? 0 : vm.counts - vm.usedImageboxsNum),
                        version: '3.0'
                    }, function (resp) {
                        vm.isSaving = false;
                        if (flag) {
                            MessageService.success("保存成功");
                            if(vm.plid){
                                SalesOrder.updateArticle({id:vm.soid,productLineId:vm.plid,articleId:resp.id,copy:1},function (da) {
                                    if(vm.article.ordered){
                                        $state.go("page-my-suffix-remake", {storeId:vm.storeId, aid:resp.id, soid: vm.soid, plid: vm.plid} )
                                    }
                                    localStorage.setItem('save-article', resp.id);
                                })
                            }else{
                                if(vm.article.ordered){
                                    $state.go("page-my-suffix-remake", {storeId:vm.storeId, aid:resp.id} )
                                }
                                localStorage.setItem('save-article', resp.id);
                            }

                        } else {
                            $scope.bool = false;
                            swal({
                                title: "保存成功",
                                text: "作品已同步到我的作品",
                                timer: 2000,
                                showConfirmButton: false
                            });
                            $(".wrap").show();
                            $(".navbar").show();
                            if(vm.plid){
                                SalesOrder.updateArticle({id:vm.soid,productLineId:vm.plid,articleId:resp.id,copy:1},function (da) {
                                    $state.go("page-view", {storeId: vm.storeId, aid: resp.id, plId:vm.plid, soId:vm.soid}, {reload: true});
                                })
                            }else{
                                $state.go("page-view", {storeId: vm.storeId, aid: resp.id, plId:vm.plid, soId:vm.soid}, {reload: true});
                            }
                            // $state.go("webArticle", {storeId: vm.storeId}, {reload: true});
                        }
                    }, function (resp) {
                        swal({
                            title: "保存失败",
                            text: "保存出错了，请稍后再试，或者联系工作人员",
                            timer: 3000,
                            showConfirmButton: false
                        });
                        vm.isSaving = false;

                    });
                }
            }

        };

        function saveData(temp) {
            var template = documentWebMM(temp,$scope.domain,[],'web');
            $scope.pageAllMm.page.forEach(function (item){
                template.pages.page.push(item)
            })
            return template
        }

        $scope.exitPage = function () {
            if($stateParams.orderArticleId){
                $state.go("page-view2", {storeId: vm.storeId, orderArticleId: $stateParams.orderArticleId, source: 'external'}, {reload: true});
                return
            }
            $(".wrap").show();
            $(".navbar").show();
            if(vm.pid){
                $state.go("productXmlSelect", {
                    storeId: vm.storeId,
                    pid: vm.pid,
                    vid: vm.vid,
                    orderId: vm.soid,
                    plid: vm.plid,
                }, {reload: true});
            }else{
                $state.go("page-view", {storeId: vm.storeId, aid: vm.article.id, plId:vm.plid, soId:vm.soid}, {reload: true});
            }
        }

        // function sorts() {
        //     return [vm.sortType.value + ',' + vm.sortType.reverse];
        // }

        $scope.photos = [];
        function onSuccess(data, headers) {
            vm.totalPhotoItems = headers('X-Total-Count');
            // $scope.gallaryPic = [];
            if (data.length < vm.itemsPerPage) {
                vm.more = false;
                flag = false;
            } else {
                vm.more = true;
                flag = true;
            }
            var len = $scope.photos.length;
            console.log(data);
            angular.forEach(data, function (item, i) {
                item.bgImg = $scope.domain + item.identifier;
                item.imgsize = item.imgSize;
                item.select = false;
                item.index = i + len;
                item.usenum = 0;
                $scope.photos.push(item);
            });

            if(vm.photosTab!='all'){
                vm.unUsedImageboxsNum = 0;
                angular.forEach($scope.photos,function (p) {
                    if(p.usenum==0){
                        vm.unUsedImageboxsNum++;
                    }
                })
            }

            setTimeout(function () {
                waterFallPic('main5');
                $(".photo-imgs").height($scope.photoHight);
            }, 1000);

            if($scope.photos.length > 0){
                newCreateDate = new Date($scope.photos[0].createdDate).getTime();
            }

            getSameUseNum();
            vm.photoLoadding = false;
            console.log($scope.photos)
        }

        function onError(error) {
            vm.photoLoadding = false;
            MessageService.error("照片库获取失败");
        }

        $('.web-leftPanel-photo').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if($scope.photos.length < vm.totalPhotoItems){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++;
                    if ($scope.folderOne) {
                        loadAll($scope.folderOne.id);
                    } else {
                        loadAll('isNull');
                    }
                }
            }
        });
        var createModal = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };


        function waterFallPic(main) {
            var lis = $('#' + main).children('li');
            var liw = 100;
            var counts = 2;
            var arr = [];
            var picList = [];
            $scope.photoHight = 0;
            lis.each(function (index, value) {//index索引值和当前的值
                if ($('.photoLi' + index).is(":visible")) {
                    picList.push($('.photoLi' + index));
                }
            });

            // for (var i = 0; i < picList.length; i++) {
            //     var pic = picList[i];
            //     var index = pic[0].getAttribute('data-index');
            //     if (i < counts) {
            //         arr.push(lis.eq(index).innerHeight())
            //         // lis.eq(index).css({
            //         //     'position': 'static'
            //         // });
            //         var minH = Math.min.apply(null, arr);
            //         $scope.photoHight = minH + 250;
            //     } else {
            //         var minH = Math.min.apply(null, arr);
            //         var minIndex = $.inArray(minH, arr);
            //         lis.eq(index).css({
            //             'position': 'absolute',
            //             'top': (minH + 30) + 'px',
            //             'left': minIndex == 0 ? (minIndex * liw + 5) + 'px' : (minIndex * liw + 25) + 'px'
            //         });
            //         $scope.photoHight = minH + 30 + 200;
            //         arr[minIndex] += lis.eq(index).innerHeight() + 10;
            //     }
            // }

        }


        //历史纪录
        var updateHistorys = function () {
            $scope.history.push(angular.copy($scope.template));
            if ($scope.history.length > 50) {
                $scope.history.splice($scope.history.length - 4, $scope.history.length - 5)
            }

            var i = $scope.currentPage.seq - 1;

            //每一页的历史记录
            if (!$scope.pageHistory[i]) {
                $scope.pageHistory[i] = {
                    history: []
                }
            }
            $scope.pageHistory[i].history.push(angular.copy($scope.template.pages.page[i]));
            if ($scope.pageHistory[i].history.length > 50) {
                $scope.pageHistory[i].history.splice($scope.pageHistory[i].history.length - 4, $scope.pageHistory[i].history.length - 5)
            }
            $scope.pageHistory[i].currentHistoryIndex = $scope.pageHistory[i].history.length - 1;
            // console.log($scope.pageHistory[i])
        };

        //撤销，重做按钮事件
        $scope.rollback = function (forward) {
            var i = $scope.currentPage.seq - 1;
            if ((!forward && !$scope.pageHistory[i].currentHistoryIndex)) {
                return;
            }

            if (forward) {
                $scope.template.pages.page[i] = angular.copy($scope.originTemp.pages.page[i]);
            } else {
                $scope.pageHistory[i].currentHistoryIndex = $scope.pageHistory[i].currentHistoryIndex - 1;
                $scope.template.pages.page[i] = angular.copy($scope.pageHistory[i].history[$scope.pageHistory[i].currentHistoryIndex]);
                $scope.pageHistory[i].history.pop();
            }
            $scope.currentPage = $scope.template.pages.page[i];
            if ($scope.arrI == 1 || $scope.arrI == undefined) {
                $scope.document.pages[$scope.pageI].arr = $scope.template.pages.page[i];
            } else {
                $scope.document.pages[$scope.pageI].arr2 = $scope.template.pages.page[i];
            }

            // 使用次数
            for (var x = 0; x < $scope.pageAllMm.page.length; x++) {
                var p = $scope.pageAllMm.page[x];
                var count = 0;
                for (var j = 0; j < $scope.template.pages.page.length; j++) {
                    var p2 = $scope.template.pages.page[j];
                    if (p.uuid == p2.uuid) {
                        count++;
                    }
                }
                p.count = count;
            }

            getSameUseNum();
        };

        function loadMore() {
            console.log($scope.photos.length)
            if ($scope.photos.length >= vm.totalPhotoItems) {
                MessageService.error("已全部加载完成");
                return
            }
            vm.indexNum++;
            if ($scope.folderOne) {
                loadAll($scope.folderOne.id);
            } else {
                loadAll('isNull');
            }
        }

        function getSameUseNum() {
            for (var x = 0; x < $scope.photos.length; x++) {
                $scope.photos[x].usenum = 0;
            }
            angular.forEach($scope.template.pages.page, function (page, index) {
                angular.forEach(page.levels.level, function (level) {
                    if (level.imagebox && level.imagebox.image&& level.imagebox.image.resource) {
                        var image = level.imagebox.image;
                        for (var j = 0; j < $scope.photos.length; j++) {
                            if ($scope.photos[j].id == image.resourceid) {
                                $scope.photos[j].usenum = $scope.photos[j].usenum - 0 + 1;
                                image.index = j;
                                image.imgsize = $scope.photos[j].imgsize;
                            }
                        }
                        // angular.forEach(vm.docGalleries, function (gallery, x) {
                        //     if (gallery.id == image.resourceid) {
                        //         image.imgsize = gallery.imgsize;
                        //     }
                        // })

                    }
                })
            })
        }

        vm.waterfallArr = [];
        function waterFall(main) {
            var lis = $('#' + main).children('li');
            // console.log(lis.length)
            var liw = 100;
            var counts = 2;
            var arr = [];vm.waterfallArr = [];
            lis.each(function (index, value) {//index索引值和当前的值
                if (index < counts) {
                    arr.push(lis.eq(index).innerHeight())
                    var minH = Math.min.apply(null, arr);
                    // $scope.photoHight = lis.eq(index).innerHeight();
                } else {
                    var minH = Math.min.apply(null, arr);
                    var minIndex = $.inArray(minH, arr);
                    lis.eq(index).css({
                        'position': 'absolute',
                        'top': (minH + 30) + 'px',
                        'left': minIndex == 0 ? (minIndex * liw + 5) + 'px' : (minIndex * liw + 25) + 'px'
                    });
                    arr[minIndex] += lis.eq(index).innerHeight() + 20;
                    vm.waterfallArr.push((minH + 30))
                }
            });
            if(main=='main1'){
                for(var i=0;i<vm.waterfallArr.length;i++) {
                    if (vm.waterfallArr[i] == vm.waterfallArr[i + 1]&&vm.waterfallArr[i+1]==vm.waterfallArr[i+2]) {
                        console.log("数组重复内容：" + vm.waterfallArr[i]);
                        waterFallCliparts();
                    }
                }
            }

            //
            // var picList = [];
            // $scope.photoHight = 0;
            // lis.each(function (index, value) {//index索引值和当前的值
            //     if ($('.clipart' + index).is(":visible")) {
            //         picList.push($('.clipart' + index));
            //     }
            // });
            //
            // for (var i = 0; i < picList.length; i++) {
            //     var pic = picList[i];
            //     var index = pic[0].getAttribute('data-index');
            //     if (i < counts) {
            //         arr.push(lis.eq(index).innerHeight())
            //         lis.eq(index).css({
            //             'position': 'static'
            //         });
            //         var minH = Math.min.apply(null, arr);
            //         $scope.photoHight = minH + 250;
            //     } else {
            //         var minH = Math.min.apply(null, arr);
            //         var minIndex = $.inArray(minH, arr);
            //         lis.eq(index).css({
            //             'position': 'absolute',
            //             'top': (minH + 30) + 'px',
            //             'left': minIndex == 0 ? (minIndex * liw + 5) + 'px' : (minIndex * liw + 25) + 'px'
            //         });
            //         $scope.photoHight = minH + 30 + 200;
            //         arr[minIndex] += lis.eq(index).innerHeight() + 10;
            //     }
            // }
        }

        // 窗口宽高变化
        window.onresize = function () {
            resizeWindow();
            pageElementInit();
            $scope.$digest();
        };
        function pageElementInit() {
            $timeout(function () {
                var nl = angular.copy(vm.pageElementR);
                var nt = angular.copy(vm.pageElementT);
                if(nl < $(".rightPanel").width()){
                    nl = $(".rightPanel").width()
                }
                if(nl > ($(".rightPanel").width() + $(".centerPanel").width() - vm.pageElementW)){
                    nl = ($(".rightPanel").width() + $(".centerPanel").width() - vm.pageElementW)
                }
                if(nt < $(".centerPanel").offset().top+42){
                    nt = $(".centerPanel").offset().top+42
                }
                if(nt > ($(".centerPanel").offset().top + $(".centerPanel").height() - vm.pageElementH)){
                    nt = ($(".centerPanel").offset().top + $(".centerPanel").height() - vm.pageElementH)
                }
                vm.pageElementR = nl;
                vm.pageElementT = nt;
            })
        }

        function resizeWindow() {
            $scope.pageW = $con.width() * vm.pageProp;
            var page = $scope.document.pages[$scope.pageI];
            $scope.getLiW(page);
            $scope.getPageWaHLar(page, true, true)
            $scope.getPageWaHLar(page, false, true)
            $scope.getPageWaHLar(page, true, false)
            $scope.getPageWaHLar(page, true, false)
        }


        /**
         * 放大缩小及还原
         */
        $scope.changeShirnk = function (zoomIn) {
            var i = $scope.pageI;
            var currentPage = $scope.document.pages[i];
            var number = 0.02;

            // 还原
            if (zoomIn == 0) {
                currentPage.scale = 1;
                $(".page-item").css({'margin': 'auto'});
            }

            // 缩小
            if (zoomIn == 1) {
                if (currentPage.scale <= 0.5) {
                    return;
                }
                currentPage.scale = Math.abs(currentPage.scale - number).toFixed(2);

            }

            // 放大
            if (zoomIn == 2) {
                currentPage.scale = Math.abs(currentPage.scale - 0 + number).toFixed(2);
            }

        }

        //防止事件冒泡，默认事件
        function fn(e) {
            // e = e || window.event;
            // e.cancelBubble = true;
            // e.stopPropagation();
            // e.returnValue = false;
            // e.preventDefault();
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        function hideChangeBtn() {
            $timeout(function () {
                $(".change-btn").hide();
                $scope.isReplaceShow = true;
            })
        }

        //斜体
        $scope.operationItalic = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                if(level.textbox.fontStyle.indexOf('ITALIC') != '-1'){
                    level.textbox.style.italic = !level.textbox.style.italic;
                    if (level.textbox.fontStyle.indexOf('BOLD') == '-1' && !level.textbox.style.italic) {
                        if (level.textbox.style.bold) {
                            level.textbox.style.bold = !level.textbox.style.bold;
                        }
                    }
                } else {
                    if (level.textbox.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                        level.textbox.style.italic = !level.textbox.style.italic;
                        if (level.textbox.fontStyle.indexOf('ITALIC') == '-1') {
                            level.textbox.style.bold = level.textbox.style.italic;
                        }
                    }
                }
                updateHistorys();
            }
        }
        //粗体
        $scope.operationBold = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                if (level.textbox.fontStyle.indexOf('BOLD') != '-1') {
                    level.textbox.style.bold = !level.textbox.style.bold;
                    if (level.textbox.fontStyle.indexOf('ITALIC') == '-1' && !level.textbox.style.bold) {
                        if (level.textbox.style.italic) {
                            level.textbox.style.italic = !level.textbox.style.italic;
                        }
                    }
                } else {
                    if (level.textbox.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                        level.textbox.style.bold = !level.textbox.style.bold;
                        if (level.textbox.fontStyle.indexOf('ITALIC') == '-1') {
                            level.textbox.style.italic = level.textbox.style.bold;
                        }
                    }
                }
                updateHistorys();
            }
        }

        //间距
        vm.isOperationSpacing = false
        $scope.operationSpacing = function (attr) {
            toolbarStatus();
            vm.isOperationSpacing = attr;

            var text = $scope.currentPage.levels.level[[$scope.template.levelI]].textbox;
            $scope.template.letterspacing = text.style.letterspacing;
            $scope.template.lineheight = text.style.lineheight;
            setTimeout(function () {
                $(".block-width").css({left: text.style.letterspacing});
                $(".block-height").css({left: text.style.lineheight});
            }, 100)

            sizeHeight(text, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
        }

        //对齐点击
        vm.isOperationDir = false
        $scope.operationDir = function (attr) {
            toolbarStatus();
            vm.isOperationDir = attr;
        }

        //文字对齐
        $scope.operationBarDir = function (attr) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.align = attr;
            }
            console.log(attr)
            updateHistorys();
        }
        //横版
        $scope.textHengban = function () {
            var text = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            var text1 = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].textbox);
            text.style.writingmode = 'horizontal-tb';
            $timeout(function () {
                text.geometry.width = text1.geometry.height;
                text.geometry.height = text1.geometry.width;
            },200)
        };
        //竖版
        $scope.textShuban = function (item) {
            var text = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            var text1 = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].textbox);
            var _writingmode = angular.copy(text.style.writingmode);
            text.style.writingmode = item;
            $timeout(function () {
                if(!_writingmode || _writingmode == 'horizontal-tb'){
                    text.geometry.width = text1.geometry.height;
                    text.geometry.height = text1.geometry.width;
                }
            },200)
        };

        //颜色
        $scope.operationColorOpen = function ($event) {
            fn($event)
            vm.isOperationColor = !vm.isOperationColor;
        }

        $scope.operationColor = function (item) {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            if (level.textbox && level.textbox.current) {
                level.textbox.style.color = item.id;
            }
            vm.textColor = null;
            console.log(level.textbox.style.color)
            updateHistorys();
        }

        $scope.chooseTextColor = function () {
            var level = $scope.currentPage.levels.level[$scope.template.levelI];
            level.textbox.style.color = vm.textColor;
            vm.textColor = null;
        }

        //文字缩放right
        // var isZoomDown = false;
        $scope.textboxdownZoomRight = function($event, item){
            var page = $scope.document.pages[$scope.pageI];
            var currentPage = $scope.currentPage;
            if (currentPage == null) {
                return;
            }
            var pro = currentPage.pageScaleW * page.scale;

            editorBoxZoom($event, item, 'rightBottom', pro, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$digest();
            }, function (it) {
                vm.fontHide = it;
                sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
                $scope.$digest();
            });
        }
        //文字缩放left
        $scope.textboxdownZoomLeft = function ($event, item) {
            var page = $scope.document.pages[$scope.pageI];
            var currentPage = $scope.currentPage;
            if (currentPage == null) {
                return;
            }
            var pro = currentPage.pageScaleW * page.scale;
            editorBoxZoom($event, item, 'leftTop', pro, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$digest();
            }, function (it) {
                vm.fontHide = it;
                sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
                $scope.$digest();
            });
        }
        //文字缩放top
        $scope.textboxdownZoomTop = function ($event, item) {
            var page = $scope.document.pages[$scope.pageI];
            var currentPage = $scope.currentPage;
            if (currentPage == null) {
                return;
            }
            var pro = currentPage.pageScaleW * page.scale;
            editorBoxZoom($event, item, 'rightTop', pro, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$digest();
            }, function (it) {
                vm.fontHide = it;
                sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
                $scope.$digest();
            });
        }
        //文字缩放bottom
        $scope.textboxdownZoomBottom = function ($event, item) {
            var page = $scope.document.pages[$scope.pageI];
            var currentPage = $scope.currentPage;
            if (currentPage == null) {
                return;
            }
            var pro = currentPage.pageScaleW * page.scale;
            editorBoxZoom($event, item, 'leftBottom', pro, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$digest();
            }, function (it) {
                vm.fontHide = it;
                sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
                $scope.$digest();
            });
        }
        //文字缩放bottom
        $scope.textboxdownZoomRightCenter = function ($event, item, ratio) {
            // if(!item.movable){return}
            fn($event)
            // textboxZoom($event, item, 'rightCenter')

            editorBoxZoom($event, item, 'right', ratio, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$digest();
            }, function (it) {
                sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            });
            // textboxWebZoom($event, item, 'right', ratio, $scope.currentPage.trimbox, function (item) {
            //     // vm.elementOn.item = item;
            //     $scope.$digest();
            // }, function (item) {
            //     sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            // })
        }
        //文字缩放bottom
        $scope.textboxdownZoomLeftCenter = function ($event, item, ratio) {
            // if(!item.movable){return}
            fn($event)
            editorBoxZoom($event, item, 'left', ratio, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$digest();
            }, function (it) {
                sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            });
            // textboxWebZoom($event, item, 'left', ratio, $scope.currentPage.trimbox, function (item) {
            //     // vm.elementOn.item = item;
            //     $scope.$digest();
            // }, function (item) {
            //     sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            // })
        }
        //文字缩放top
        $scope.textboxZoomTop = function ($event, item, ratio) {
            // if(!item.movable){return}
            fn($event)
            editorBoxZoom($event, item, 'top', ratio, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$digest();
            }, function (it) {
                sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            });
            // textboxWebZoom($event, item, 'top', ratio, $scope.currentPage.trimbox, function (item) {
            //     // vm.elementOn.item = item;
            //     $scope.$digest();
            // }, function (item) {
            //     sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            // })
        }
        //文字缩放bottom
        $scope.textboxZoomBottom = function ($event, item, ratio) {
            fn($event)
            editorBoxZoom($event, item, 'bottom', ratio, $($event.target).parents('.selectedBorder'), function (it) {
                $scope.$digest();
            }, function (it) {
                sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            });
            // textboxWebZoom($event, item, 'bottom', ratio, $scope.currentPage.trimbox, function (item) {
            //     // vm.elementOn.item = item;
            //     $scope.$digest();
            // }, function (item) {
            //     sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
            // })
        }
        $scope.writingmodeInit = function (item) {
            if(item == 'vertical-lr' || item == 'vertical-rl'){
                return true
            }else{
                return false
            }
        }
        function textboxZoom($event,item, direction) {
            var page = $scope.document.pages[$scope.pageI];
            var currentPage = $scope.currentPage;
            if (currentPage == null) {
                return;
            }
            var pressArt = currentPage.levels.level[$scope.template.levelI].textbox;
            if (pressArt == null||item.lock) {
                return;
            }

            $(".sel-btn").hide();
            $(".sel-"+direction).show();

            $scope.RightPanel = 'text';
            var pro = currentPage.pageScaleW * page.scale;
            var maxX = $(".slide" + $scope.arrI + $scope.pageI).width();
            var maxY = $(".slide" + $scope.arrI + $scope.pageI).height();
            var page = $scope.document.pages[$scope.pageI];
            var currentPage = $scope.currentPage;
            var arr = page.arr
            if ($scope.arrI == 2) {
                arr = page.arr2
            }
            var minX = currentPage.trimbox.x * pro;
            var minY = currentPage.trimbox.y * pro;
            var x = $event.clientX;
            //获取w和h
            var nw = angular.copy(item.geometry.width) * pro;
            var nh = angular.copy(item.geometry.height) * pro;
            var x2 = item.geometry.x * pro;

            var _left = angular.copy(item.geometry.x) * pro;
            var _top = angular.copy(item.geometry.y) * pro;
            var _size = angular.copy(parseInt(item.style.size));
            var _lineheight = angular.copy(parseInt(item.style.lineheight));

            var orignW = angular.copy(item.geometry.width);
            var scale = orignW/_size;
            var geoX = item.geometry.x;
            var preDiv = $(".textbox" + $scope.pageI+'-' + $scope.arrI + $scope.template.levelI).find('pre')[0];
            //开关打开
            isZoomDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isZoomDown == false) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - x;
                if(direction == 'bottom' || direction == 'left'||direction=='leftCenter'){
                    var _w = (Number(nw) - Number(nl));
                }

                if(direction == 'top' || direction == 'right'||direction == 'rightCenter'){
                    var _w = (Number(nw) + Number(nl));
                }

                // pt = px * dpi / 72
                // px = pt * 72 / dpi
                var minSize = parseFloat(preDiv.style.fontSize)*2+parseFloat(preDiv.style.letterSpacing)*2;
                var minSize2 = parseFloat(preDiv.style.fontSize)*arr.ratio*2+parseFloat(preDiv.style.letterSpacing)*arr.ratio*2;
                var minSizePx = minSize2* 72 / vm.windowDpiW;

                if(((direction=='leftCenter'||direction=='rightCenter')&&_w > minSizePx)||(direction!='leftCenter'&&direction!='rightCenter')){
                    var moveW = (maxX - item.geometry.x * pro);
                    var moveH = (maxY - item.geometry.y * pro);
                    // 左侧按钮
                    if(direction == 'left'||direction == 'bottom'||direction == 'leftCenter'){
                        geoX = (Number(x2) - Number(_w - nw)) / pro;
                        if(geoX<=0){
                            geoX = 0;
                        }else if (_w < moveW) {
                            item.geometry.width = _w / pro;
                        }
                    }else{
                        if (_w < moveW) {
                            item.geometry.width = _w / pro;
                        }
                    }



                    if(direction == 'left'){
                        if((Number(nw) - Number(nl)) < 20){
                            nl = Number(nw) - 20;
                        }
                        item.geometry.width = (Number(nw) - Number(nl))/ pro;
                        item.geometry.x = (Number(_left) + Number(nl))/ pro;
                        item.style.size = Math.ceil(((Number(nw) - Number(nl)) / nw * _size));
                        item.style.lineheight = Math.ceil(((Number(nw) - Number(nl)) / nw * _lineheight));
                        item.geometry.y = (Number(_top) - Number(item.geometry.height*pro - nh))/ pro;
                    }else if(direction == 'top'){
                        if((Number(nw) + Number(nl)) < 20){
                            nl = 20 - Number(nw);
                        }
                        item.geometry.width = (Number(nw) + Number(nl))/ pro;
                        item.style.size = Math.ceil(((Number(nw) + Number(nl)) / nw * _size));
                        item.style.lineheight = Math.ceil(((Number(nw) + Number(nl)) / nw * _lineheight));
                        item.geometry.y = (Number(_top) - Number(item.geometry.height*pro - nh))/ pro;
                    }else if(direction == 'right'){
                        if((Number(nw) + Number(nl)) < 20){
                            nl = 20 - Number(nw);
                        }
                        item.geometry.width = (Number(nw) + Number(nl) + Number(5))/ pro;
                        item.style.size = Math.ceil(((Number(nw) + Number(nl)) / nw * _size));
                        item.style.lineheight = Math.ceil(((Number(nw) + Number(nl)) / nw * _lineheight));
                    }else if(direction == 'bottom'){
                        if((Number(nw) - Number(nl)) < 20){
                            nl = Number(nw) - 20;
                        }
                        item.geometry.width = (Number(nw) - Number(nl))/ pro;
                        item.geometry.x = (Number(_left) + Number(nl))/ pro;
                        item.style.size = Math.ceil(((Number(nw) - Number(nl)) / nw * _size));
                        item.style.lineheight = Math.ceil(((Number(nw) - Number(nl)) / nw * _lineheight));
                    }else{
                        item.geometry.x = geoX;
                    }


                    // if(direction != 'rightCenter' && direction != 'leftCenter'){
                    //     item.style.lineheight = item.style.size = parseInt(item.geometry.width/scale);
                    //
                    //     var s = parseInt(item.geometry.width/scale);
                    //     if(s>101){
                    //         s=100;
                    //         item.geometry.width = s*scale
                    //     }
                    //     if(s<5) {
                    //         s = 6;
                    //         item.geometry.width = s*scale
                    //     }
                    //
                    //     item.style.lineheight = item.style.size = s;
                    // }

                    var radio = 1 / 12 * item.style.size * arr.ratio > 1 ? 1 : 1 / 12 * item.style.size * arr.ratio
                    $timeout(function () {
                        //获取缩放后的高度
                        var originH = Number($(".textbox" + $scope.pageI + '-' + $scope.arrI + $scope.template.levelI).find('.c-xml-page-textboxe-span').height()) + Number(10);
                        var _h = Number(originH * radio - 0 + 2);

                        // item.geometry.height = _h / pro;
                        item.geometry.height = originH;
                        if(direction == 'left'||direction == 'top'){
                            // item.geometry.y = (Number(y2) - Number(_h - nh)) / pro;
                        }
                        $scope.$digest();
                    })
                }
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                if(isZoomDown){
                    isZoomDown = false;
                    $(".sel-btn").show();
                    console.log("onmouseup: ")
                    sizeHeight(item, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);
                    $event.stopPropagation();
                }
            }
        }

        //文字放大缩小
        window.resizeTextStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            dragMask.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragMask.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragMask.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragMask.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
            dragMask.maxX = $(".slide" + $scope.arrI + $scope.pageI).width();
            dragMask.maxY = $(".slide" + $scope.arrI + $scope.pageI).height();

            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            var textbox = $scope.currentPage.levels.level[$scope.template.levelI].textbox;
            if (textbox.lock) {
                return
            }

            dragMask.orignW = textbox.geometry.width;
            dragMask.size = textbox.style.size;


            dragFlag = true;
            document.addEventListener("mousemove", resizeTextOn, true);
            document.addEventListener("mouseup", resizeTextEnd, true);
            // setZindex(e.target.dataset.index);
        };
        function resizeTextOn(e) {
            if (dragFlag) {
                fn(e);
                // 是否按住shift键
                dragMask.shiftKey = e.shiftKey;
                $scope.RightPanel = 'text';
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var page = $scope.document.pages[$scope.pageI];
                var imgIndex = targ.dataset.index || $scope.template.levelI;
                if (imgIndex == null) {
                    return;
                }
                var pageIndex = $scope.currentPage;
                if (pageIndex == null) {
                    return;
                }
                var pressArt = pageIndex.levels.level[$scope.template.levelI].textbox;
                if (pressArt == null) {
                    return;
                }

                var pro = pageIndex.pageScaleW * page.scale;

                //20 小图标的宽高
                var w = (dragMask.coordX + 10 + e.clientX - dragMask.clientX - pressArt.geometry.x * pro) / pro;
                var h = 0;
                if (dragMask.shiftKey) {
                    h = w / pressArt.geometry.width * pressArt.geometry.height
                } else {
                    h = (dragMask.coordY + 10 + e.clientY - dragMask.clientY - pressArt.geometry.y * pro) / pro;
                }

                if (w < 20 || h < 20) {
                    return
                }
                var size = dragMask.size + parseInt((w - dragMask.orignW) / 15);
                if (size < 6 || size > 100) {
                    // MessageService.error("已经是最小字号了！")
                    return
                }

                var moveW = (dragMask.maxX - pressArt.geometry.x * pro) / pro;
                var moveH = (dragMask.maxY - pressArt.geometry.y * pro) / pro;
                if (w < moveW && h < moveH && dragMask.shiftKey) {
                    pressArt.geometry.width = w;
                } else if (!dragMask.shiftKey) {
                    if (w < moveW) {
                        pressArt.geometry.width = w;
                    }
                }

                sizeHeight(pressArt, $scope.pageI + '-' + $scope.arrI + $scope.template.levelI);

                pressArt.style.lineheight = size;
                $scope.size = pressArt.style.size = size;

            }
            $scope.$digest();
            return false;
        }

        function resizeTextEnd() {
            dragFlag = false;
            document.removeEventListener("mousemove", resizeTextOn, true);
            document.removeEventListener("mouseup", resizeTextEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        $scope.rotateTextStart = function($event, item,slide){
            if(item.lock){return}
            fn($event);
            var page = $scope.document.pages[$scope.pageI];
            $scope.currentPage = slide;
            textboxWebRotate($scope, $event, item, $scope.currentPage.pageScaleW * page.scale, function () {
                uploadRightPlanel();
                updateHistorys();
            })
        }

        //文本框点击时
        $scope.selectText = function (e, $index, text, arrI) {
            vm.isTransp = false;
            if (e && e.stopPropagation) {
                e.stopPropagation();
            } else {
                window.event.cancelBubble = true;
            }
            if(vm.textFont&&$scope.selectBox.current=='textbox'){
                return
            }
            if (!text.lock) {
                $scope.hasText = true;
            } else {
                $scope.hasText = false;
            }

            $scope.template.levelI = $index;
            $scope.arrI = arrI;
            scrollIntoView();

            angular.forEach(vm.fontList, function (list) {
                if (list.uuid == text.fontuuid) {
                    $scope.item = list
                }
            })
            fontStyle(text);
            $scope.template.text = text.text;
            $scope.size = text.style.size;
            $scope.template.angle = text.rotation.angle;

            $scope.template.letterspacing = text.style.letterspacing;
            $(".block-width").css({left: text.style.letterspacing});
            $scope.template.lineheight = text.style.lineheight;
            $(".block-height").css({left: text.style.lineheight});

            text.current = 'textbox';
            $scope.selectBox = text;

            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                text.ctrlKey = true;
                vm.CtrlKeyArray.textboxes[$index] = angular.copy(text);
                ctrlAddSingleArray();
            } else {
                if(!text.ctrlKey){
                    resetCtrlKeyArray();
                }
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
                if (text.lock) {
                    return
                }
                vm.singleArray.textbox = angular.copy(text);
            }
        };

        $scope.selectTextDocFont = function (e, levelIndex, docIndex, text) {
            if (e && e.stopPropagation) {
                e.stopPropagation();
            } else {
                window.event.cancelBubble = true;
            }
            $scope.template.levelI = levelIndex;
            $scope.template.docI = docIndex;
            vm.textDocCon = text.text;

        };

        $scope.doubleClicktext = function (e, item) {
            fn(e);
            if (item.lock || !item.editable) {
                return
            }
            var page = $scope.document.pages[$scope.pageI];
            var currentPage = $scope.currentPage;
            var textL = item.geometry.x*currentPage.pageScaleW*page.scale;
            vm.fontbox = item;
            vm.fontIndex = $scope.pageI +'-'+ $scope.arrI + $scope.template.levelI;
            vm.fontOn = $(".textbox" + $scope.pageI +'-'+ $scope.arrI + $scope.template.levelI);
            item.text = vm.fontOn.find('span')[0].innerHTML;
            var $textCopy = vm.fontOn.clone().css({transform: 'rotate(0deg)',opacity:0,filter:'alpha(opacity=0)',zIndex:-1})
            vm.fontOn.parent().append($textCopy);
            var boxLeft = $textCopy.offset().left;
            $textCopy.remove();

            // var l = boxLeft-$(".page-center").offset().left-textL;
            var l = $(".page-center").offset().left;
            var t = $(".page-center").offset().top;
            if ($scope.arrI==2) {
                l+= $(".slide1" + $scope.pageI).width();
            }

            // vm.fontOn.css({position:'fixed','margin-left':l,'margin-top':t})
            fontSelect(vm.fontOn,item);
        }

        $scope.doubleClicktextdoc = function (e, item, outerIndex, arrI, levelI, docIndex) {
            $scope.pageI = outerIndex;
            $scope.arrI = arrI;
            $scope.template.levelI = levelI;
            $scope.docI = docIndex;
            fn(e);
            $scope.selectBox = item;
            if(item.lock|| !item.editable){
                return
            }

            vm.fontbox = item;
            vm.fontIndex = outerIndex+'-' + arrI + levelI + docIndex;
            vm.fontDocOn = $(".textbox" + outerIndex+'-' + arrI + levelI + docIndex);
            item.text = vm.fontDocOn.find('span')[0].innerHTML;
            vm.fontDocOn.css({position:'fixed'})
            fontSelect(vm.fontDocOn,item);
        }

        function fontSelect(font,item) {
            vm.selectP = false;
            var span = font.find('span');
            span[0].innerHTML = ''
            span.focus();
            vm.textFont = true;
            span[0].innerHTML = item.text;
            $(span[0]).css({'cursor':'text'});
            span[0].setAttribute("contenteditable", "true");
            if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText(span[0]);
                range.select();
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNodeContents(span[0]);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
        }
        $scope.blur = function (e, item, i) {
            fn(e);
            if(vm.selectP){
                return
            }

            item.text = $(".textbox" + i).find('span')[0].innerHTML.replace(/&nbsp;/g,' ').replace(/&amp;/g,'&').replace(reg1,'').replace(/<br>/g,'\n').replace(reg2,'');

            FontManagement.fontSupport2({uuid:item.fontuuid,bold:item.style.bold,italic:item.style.italic,content:item.text},function (res) {
                var msg = res.message
                if(res.status==200){
                    item.success = msg.success;
                    if(msg.success&&!msg.supported){
                        item.text = msg.passed;
                        $(".textbox" + i).find('span')[0].innerHTML = msg.passed;
                        vm.editFailedFont = true;
                        vm.failedFontArr = {
                            failed:msg.failed,
                            e:e,
                            textbox:item
                        }
                    }else{
                        vm.editFailedFont = false;
                        vm.failedFontArr = null;
                    }
                }
                // console.log(item.text)
                // sizeHeight(item, i);
                sizeHeight(item, i, true,function (text) {
                    $(".textbox" + i).find(".c-xml-page-textboxe-span").html("");
                    $timeout(function () {
                        (function (i) {
                            $(".textbox" + i).find(".c-xml-page-textboxe-span").html(text);
                        })(i)
                    },200)
                });
                $timeout(function () {
                    removeContenteditable();
                    vm.fontOn = null;
                    vm.fontDocOn = null;
                    vm.textFont = false;
                })
            }, function (error) {
                $timeout(function () {
                    removeContenteditable();
                    vm.fontOn = null;
                    vm.fontDocOn = null;
                    vm.textFont = true;
                })
            })
        }

        $scope.editFont = function () {
            console.log(vm.failedFontArr)
            vm.editFailedFont = false;
            vm.selectP = false;
            if(vm.fontbox.parentCurrent=="textdocument"){
                $scope.doubleClicktextdoc(vm.failedFontArr.e, vm.failedFontArr.textbox, $scope.pageI, $scope.arrI, $scope.template.levelI, $scope.docI)
            }else{
                $scope.doubleClicktext(vm.failedFontArr.e,vm.failedFontArr.textbox)
            }
        }

        $scope.changeHeight = function (e, item, i) {
            fn(e);
            item.text = $(".textbox" + i).find('span')[0].innerHTML.replace(/&nbsp;/g,' ').replace(/&amp;/g,'&');
            // console.log(item.text)
            sizeHeight(item, i);
        };

        function sizeHeight(_textbox, i, callbackReturn, callback) {
            _textbox.text = _textbox.text.replace(/&nbsp;/g,' ').replace(/&amp;/g,'&').replace(reg1,'').replace(/<br>/g,'\n').replace(reg2,'');

            // var page = $scope.document.pages[$scope.pageI];
            // var currentPage = $scope.currentPage;
            // var maxY = $(".slide" + $scope.arrI + $scope.pageI).height();
            // var arr = page.arr
            // if ($scope.arrI == 2) {
            //     arr = page.arr2
            // }
            // var radio = 1 / 12 * _textbox.style.size * arr.ratio*page.scale > 1 ? 1 : 1 / 12 * _textbox.style.size * arr.ratio*page.scale
            // $timeout(function () {
            //     //获取缩放后的高度
            //     if(!writingmodeInit(_textbox.style.writingmode)){
            //         var originH = Number($(".textbox" + i).find('.c-xml-page-textboxe-span').height())+Number(10);
            //         // var _h = Number(originH * radio - 0 + 2);
            //         // _textbox.geometry.height = _h / (currentPage.pageScaleH * page.scale);
            //         _textbox.geometry.height = originH;
            //         console.log(originH)
            //     }else{
            //         var _w = Number($("#c-xml-page-material-fontId").width()) + Number(10);
            //         // var originW = Number($(".textbox" + i).find('.c-xml-page-textboxe-span').width())+Number(10);
            //         // var _h = Number(originH * radio - 0 + 2);
            //         // _textbox.geometry.height = _h / (currentPage.pageScaleH * page.scale);
            //         _textbox.geometry.width = _w;
            //     }
            // })


            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1").width()) + Number(10);
                if(!writingmodeInit(_textbox.style.writingmode)){
                    _textbox.geometry.height = _h;
                }
                if(writingmodeInit(_textbox.style.writingmode)){
                    _textbox.geometry.width = _w;
                }
                if(callbackReturn){
                    callback(_textbox.text)
                }
                $scope.$digest();
            })
        }

        function sizeHeight2(_textbox, i) {
            _textbox.text = _textbox.text.replace(/&nbsp;/g,' ').replace(/&amp;/g,'&').replace(reg1,'').replace(/<br>/g,'\n').replace(reg2,'');
            $timeout(function () {
                //获取缩放后的高度
                var originH = Number($(".textbox" + i).find('.c-xml-page-textboxe-span').height())+Number(10);
                _textbox.geometry.height = unitTransformPx(originH);
                _index1++;
                console.log(_textbox.geometry.height)
            })
        }

        //文本框鼠标事件
        var dragText;
        var dragTextBox = {};
        $scope.moveTextStart = function (e, item, slide,arrindex,index,outerIndex) {
            if(vm.textFont && $scope.selectBox.current=='textbox' || item.lock || !item.movable){
                return
            }
            vm.fontHide = item;
            // fn(e);
            $scope.buttonShows = true;
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            $scope.template.levelI = index;
            $scope.pageI = outerIndex;
            $scope.arrI = arrindex;
            $scope.currentPage = slide

            $scope.template.angle = item.rotation.angle;
            if (item.lock || !item.movable) {
                return
            }
            $scope.selectBox = item;
            $scope.hasText = true;
            $scope.hasImgUrl = false;
            dragTextBox.clientX = e.clientX || 0;
            dragTextBox.clientY = e.clientY || 0;
            dragTextBox.coordX = targ.offsetParent.offsetLeft;
            dragTextBox.coordY = targ.offsetParent.offsetTop;

            dragTextBox.slideW = $(".slide" + $scope.arrI+ $scope.pageI).width();
            dragTextBox.slideH = $(".slide" + $scope.arrI + $scope.pageI).height();
            dragText = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragTextBox.ctrlKey = true;
            } else {
                // dragTextBox.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveTextOn, true);
            document.addEventListener("mouseup", moveTextEnd, true);
        };

        //文本框move事件
        function moveTextOn(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();
            if (dragText) {

                var page = $scope.document.pages[$scope.pageI];
                var currentPage = $scope.currentPage;
                var pressText = currentPage.levels.level[[$scope.template.levelI]].textbox;

                var w = pressText.geometry.width * currentPage.pageScaleW * page.scale;
                var h = pressText.geometry.height * currentPage.pageScaleW * page.scale;

                var clientX = e.clientX - dragTextBox.clientX;
                var clientY = e.clientY - dragTextBox.clientY;
                var offsetsX = (dragTextBox.coordX + e.clientX - dragTextBox.clientX);
                var offsetsY = (dragTextBox.coordY + e.clientY - dragTextBox.clientY);
                var x = currentPage.trimbox.x * currentPage.pageScaleW * page.scale;
                var y = currentPage.trimbox.y * currentPage.pageScaleW * page.scale;

                // $scope.isMoveOut = false;
                // if (offsetsX <= 0) {
                //     offsetsX = 0;
                // }
                // if (offsetsX >= dragTextBox.slideW - w) {
                //     offsetsX = dragTextBox.slideW - w;
                // }
                //
                //
                // if (offsetsY >= (dragTextBox.slideH - h)) {
                //     offsetsY = dragTextBox.slideH - h;
                // }
                //
                // if (offsetsY <= 0) {
                //     offsetsY = 0;
                // }
                //
                // if (offsetsX <= x || offsetsY <= y || offsetsY >= (dragTextBox.slideH - h - y) || offsetsX >= dragTextBox.slideW - w - x) {
                //     $scope.isMoveOut = true;
                // }

                if (dragTextBox.ctrlKey) {
                    moveTogether(currentPage, clientX, clientY,dragTextBox);
                } else {
                    pressText.geometry.x = offsetsX / currentPage.pageScaleW / page.scale;
                    pressText.geometry.y = offsetsY / currentPage.pageScaleH / page.scale;
                }
                $scope.currentPage = currentPage;

                $scope.isMoveOut = !editboxIn($scope.currentPage.editboxes.editbox , pressText, pressText.rotation.angle);

            }
            return false;
        }

        //文本框鼠标松开
        function moveTextEnd() {
            $scope.isMoveOut = false;
            if (dragTextBox.ctrlKey) {
                moveTogetherEnd();
            }
            $scope.$digest();
            dragText = false;
            document.removeEventListener("mousemove", moveTextOn, true);
            document.removeEventListener("mouseup", moveTextEnd, true);
            dragTextBox = {};
            updateHistorys();
        }

        function toolbarStatus() {
            vm.isOperationDir = false;
            vm.isOperationSpacing = false;
            vm.isTransp = false;
            vm.isCoverage = false;
            // $scope.isDistriHover = false;
            // $scope.isAlignHover = false;
            vm.isOperationColor = false;
            vm.isRoundedSet = false;
            vm.isBorderSet = false;
            vm.isShadowSet = false;
        }

        $scope.addTextDoc = function (text,e) {
            if(vm.fontOn||vm.fontDocOn){
                $scope.blur(e, vm.fontbox, vm.fontIndex)
                return
            }
            FontXmlsFileServes.get({id: text.id}, function (res) {
                vm.data = res;
                // var level = $scope.currentPage.levels.level[$scope.template.levelI];
                var currentPage = $scope.currentPage;
                var page = $scope.document.pages[$scope.pageI];
                var currentLevel = addLevel(currentPage);
                currentLevel.textdocument = angular.copy(vm.data.textDocument);

                var geo = currentLevel.textdocument.geometry;
                var bgImgW = unitTransform(geo.width);
                var bgImgH = unitTransform(geo.height);
                var w = '';
                var h = '';
                var bgImgR = bgImgW/bgImgH;
                if(bgImgR >= 1){
                    if(bgImgW > currentPage.trimbox.width){
                        w = currentPage.trimbox.width;
                        h = currentPage.trimbox.width * bgImgH / bgImgW;
                    }else {
                        w = bgImgW;
                        h = bgImgH;
                    }
                }else {
                    if(bgImgH > currentPage.trimbox.height){
                        h = currentPage.trimbox.height;
                        w = currentPage.trimbox.height * bgImgW / bgImgH;
                    }else {
                        w = bgImgW;
                        h = bgImgH;
                    }
                }
                geo.width = unitTransformPx(w)
                geo.height = unitTransformPx(h)

                var proW = currentPage.pageScaleW * page.scale;
                var proH = currentPage.pageScaleH * page.scale;
                var slideW = $(".slide" +$scope.arrI + $scope.pageI).width();
                var slideH = $(".slide" +$scope.arrI+ $scope.pageI).height();
                var x = unitTransformPx((slideW - w * proW)/2/proW)+$scope.template.levelI;
                var y = unitTransformPx((slideH - h * proH)/2/proH)+$scope.template.levelI;

                // var geo = currentLevel.textdocument.geometry;
                var mm = {
                    width: geo.width,
                    height: geo.height,
                    x: x,
                    y: y
                }
                var dataBackground = vm.data.textDocument.background;
                var textdocument = currentLevel.textdocument;
                textdocument.geometry = getGeometry(mm);
                textdocument.useradded = true;
                textdocument.current = 'textdocument';
                textdocument.rotation = {
                    angle: 0
                };
                textdocument.background.offsetx = unitTransform(dataBackground.offsetx);
                textdocument.background.offsety = unitTransform(dataBackground.offsety);
                var resource = textdocument.background.resource;
                if (resource.identifier) {
                    $http({url: resource.identifier + '?imageInfo'}).success(function (data) {
                        var pageW = textdocument.geometry.width,
                            pageH = textdocument.geometry.height,
                            bgW = data.width,
                            bgH = data.height;

                        // 高适配
                        var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                        // 宽适配
                        if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                            w = pageW;
                            h = pageW * bgH / bgW;
                            adaptation = 'Width';
                        }
                        textdocument.background.width = w;
                        textdocument.background.height = h;
                        textdocument.background.width = bgW;
                        textdocument.background.height = bgH;
                        textdocument.background.type = 'Pic';
                        textdocument.background.adaptation = adaptation
                    }).error(function (error) {
                        MessageService.error("获取图片信息失败");
                    });
                }

                angular.forEach(textdocument.levels.level, function (level, l) {
                    var dataLevel = vm.data.textDocument.levels.level[l];
                    if (level.imageart) {
                        level.imageart.current = 'imageart';
                        level.imageart.geometry = getGeometry(dataLevel.imageart.geometry);
                        var resource = dataLevel.imageart.resource;
                        $http({url: resource.identifier + '?imageInfo'}).success(function (data) {
                            level.imageart.resource = {
                                identifier: resource.identifier,
                                provider: 'qiniu',
                                width: data.width,
                                height: data.height
                            }

                            level.imageart.offsetx = unitTransform(dataLevel.imageart.offsetx);
                            level.imageart.offsety = unitTransform(dataLevel.imageart.offsety);
                        }).error(function (error) {
                            MessageService.error("获取图片信息失败");
                        });
                    }
                    if (level.textbox) {
                        level.textbox.current = 'textbox';
                        level.textbox.parentCurrent = 'textdocument';
                        level.textbox.geometry = getGeometry(dataLevel.textbox.geometry);
                        // level.textbox.scale = textdocument.geometry.width;
                        if (vm.fontuuids.indexOf(text.fontuuid) == -1) {
                            vm.fontuuids.push(text.fontuuid);
                            getFont(level.textbox);
                        }
                    }
                })
                updateHistorys();
                currentLevel.textdocument.levelI = $scope.template.levelI;
                currentLevel.textdocument.pageI = $scope.template.pageI;
                $scope.currentPage = currentPage;
                $scope.selectBox = currentLevel.textdocument;
                console.log($scope.selectBox)
                return
            });
        }

        //文本框点击时
        $scope.selectDoc = function (e, $index, textdocument) {
            fn(e)
            $scope.template.levelI = $index;

            $scope.template.angle = textdocument.rotation.angle;
            textdocument.current = 'textdocument';
            $scope.selectBox = textdocument;
            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                if (textdocument.lock) {
                    return
                }
                textdocument.ctrlKey = true;
                vm.CtrlKeyArray.textdocuments[$index] = angular.copy(textdocument);
                ctrlAddSingleArray();
            } else {
                if(textdocument.ctrlKey){
                    resetCtrlKeyArray()
                }
                vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}, textdocument: {}};
                if (textdocument.lock) {
                    return
                }
                vm.singleArray.textdocument = angular.copy(textdocument);
            }
        };

        var dragTextDoc = {};
        window.moveTextDocStart = function (e) {
            // fn(e)
            if (e.target.nodeName == 'SPAN') {
                return
            }
            $scope.buttonShows = true;
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.parentNode.dataset.index||$scope.template.levelI;
            $scope.pageI = targ.parentNode.dataset.docindex||$scope.pageI;
            $scope.arrI = targ.parentNode.dataset.arrindex||$scope.arrI;
            var elem = $(".textdoc"+$scope.pageI+$scope.arrI+$scope.template.levelI)[0];
            var page = $scope.document.pages[$scope.pageI];
            if ($scope.arrI == 1) {
                $scope.currentPage = page.arr;
            } else if ($scope.arrI == 2){
                $scope.currentPage = page.arr2;
            }else{
                return
            }
            var currentLevel = $scope.currentPage.levels.level[$scope.template.levelI];
            var currentImg = currentLevel.textdocument;
            $scope.template.angle = currentImg.rotation.angle;

            currentImg.current = 'textdocument';
            $scope.selectBox = currentImg;
            if (currentImg.lock||!currentImg.movable) {
                return
            }
            if(vm.textFont&&$scope.selectBox.current=='textdocument'){
                removeContenteditable();
            }
            dragTextDoc.clientX = e.clientX || 0;
            dragTextDoc.clientY = e.clientY || 0;
            dragTextDoc.coordX = elem.offsetLeft;
            dragTextDoc.coordY = elem.offsetTop;
            dragTextDoc.slideW = $(".slide" + $scope.arrI+ $scope.pageI).width();
            dragTextDoc.slideH = $(".slide" + $scope.arrI + $scope.pageI).height();
            drag = true;
            var all = getAllCtrlKeyArray();
            if (all.length) {
                dragTextDoc.ctrlKey = true;
            } else {
                // dragTextDoc.ctrlKey = false;
            }
            document.addEventListener("mousemove", moveTextDocOn, true);
            document.addEventListener("mouseup", moveTextDocEnd, true);

        };
        //move 事件
        function moveTextDocOn(e) {
            fn(e);
            $scope.$digest();
            if (e.target.nodeName == 'SPAN') {
                return;
            }
            if (drag) {
                var page = $scope.document.pages[$scope.pageI];
                var currentPage = $scope.currentPage;
                var currentLevel = currentPage.levels.level[$scope.template.levelI];
                var pressImg = currentLevel.textdocument;
                var angle = (pressImg.rotation.angle) * Math.PI / 180;

                var clientX = e.clientX - dragTextDoc.clientX;
                var clientY = e.clientY - dragTextDoc.clientY;

                var imgOffsetX = (dragTextDoc.coordX + e.clientX - dragTextDoc.clientX);
                var imgOffsetY = (dragTextDoc.coordY + e.clientY - dragTextDoc.clientY);
                var imgWidth = pressImg.geometry.width * currentPage.pageScaleW * page.scale;
                var imgHeight = pressImg.geometry.height * currentPage.pageScaleH * page.scale;
                var x = currentPage.trimbox.x * currentPage.pageScaleW * page.scale;
                var y = currentPage.trimbox.y * currentPage.pageScaleW * page.scale;

                $scope.isMoveOut = false;
                // if (imgOffsetX <= 0) {
                //     imgOffsetX = 0;
                // }
                //
                if (imgOffsetX <= x || imgOffsetY <= y || imgOffsetY >= (dragTextDoc.slideH - imgHeight - y) || imgOffsetX >= dragTextDoc.slideW - imgWidth - x) {
                    $scope.isMoveOut = true;
                }
                //
                // if (imgOffsetX >= dragTextDoc.slideW - imgWidth) {
                //     imgOffsetX = dragTextDoc.slideW - imgWidth;
                // }
                //
                // if (imgOffsetY >= (dragTextDoc.slideH - imgHeight)) {
                //     imgOffsetY = dragTextDoc.slideH - imgHeight;
                // }
                //
                // if (imgOffsetY <= 0) {
                //     imgOffsetY = 0;
                // }
                if (dragTextDoc.ctrlKey) {
                    moveTogether(currentPage, clientX, clientY,dragTextDoc);
                } else {
                    pressImg.geometry.x = imgOffsetX / currentPage.pageScaleW / page.scale;
                    pressImg.geometry.y = imgOffsetY / currentPage.pageScaleH / page.scale;
                }

                $scope.currentPage = currentPage;

            }
            return false;
        }

        //鼠标松开事件
        function moveTextDocEnd() {
            if (dragTextDoc.ctrlKey) {
                moveTogetherEnd();
            }
            drag = false;
            dragTextDoc = {};
            document.removeEventListener("mousemove", moveTextDocOn, true);
            document.removeEventListener("mouseup", moveTextDocEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        window.rotateTextDocStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            var currentLevel = $scope.currentPage.levels.level[$scope.template.levelI];
            var currentTextdocument = currentLevel.textdocument;
            if (currentTextdocument.lock) {
                return
            }
            drag = true;

            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;

            $scope.RightPanel = 'textDoc';


            dragBox.imgRotate = currentTextdocument.rotation.angle ? 360 - Number(currentTextdocument.rotation.angle) : 0;
            var radius = Math.sqrt(currentTextdocument.geometry.width * currentTextdocument.geometry.width + currentTextdocument.geometry.height * currentTextdocument.geometry.height)
            dragBox.originalRotate = parseInt(180 * Math.asin(currentTextdocument.geometry.width / radius) / (2 * Math.asin(1))) + dragBox.imgRotate;
            $(".rotate-icon" + $scope.template.levelI).addClass('rotate-icon-active');

            document.addEventListener("mousemove", rotateDocOn, true);
            document.addEventListener("mouseup", rotateDocEnd, true);
        }

        function rotateDocOn(e) {
            fn(e);
            var currentImg = $scope.currentPage.levels.level[$scope.template.levelI].textdocument;
            if (drag) {
                var page = $scope.document.pages[$scope.pageI];
                var offsetx = (e.clientX - dragBox.clientX);
                var offsety = (e.clientY - dragBox.clientY);
                var newX = (currentImg.geometry.width / 2 * $scope.currentPage.pageScaleW * page.scale + offsetx);
                var newY = (currentImg.geometry.height / 2 * $scope.currentPage.pageScaleH * page.scale + offsety);
                dragBox.newRadius = Math.sqrt(newX * newX + newY * newY);
                var rotate = parseInt(180 * Math.asin(newX / dragBox.newRadius) / (2 * Math.asin(1)));
                if (newX > 0 && newY > 0) {
                    currentImg.rotation.angle = (dragBox.originalRotate - rotate);
                } else if (newX < 0 && newY > 0) {
                    currentImg.rotation.angle = (dragBox.originalRotate - rotate);
                } else if (newX < 0 && newY < 0) {
                    currentImg.rotation.angle = (dragBox.originalRotate + 180 + rotate);
                } else {
                    currentImg.rotation.angle = (dragBox.originalRotate + 180 + rotate);
                }


                if (currentImg.rotation.angle >= 360) {
                    currentImg.rotation.angle %= 360;
                }
                currentImg.rotation.angle = 360 - currentImg.rotation.angle
                $scope.template.angle = currentImg.rotation.angle;

            }
            $scope.$digest();
            // e.stopPropagation();
            // e.preventDefault();
            return false;
        }

        function rotateDocEnd() {
            drag = false;
            dragBox = {};
            dragMask = {};

            $(".rotate-icon" + $scope.template.levelI).removeClass('rotate-icon-active');
            document.removeEventListener("mousemove", rotateDocOn, true);
            document.removeEventListener("mouseup", rotateDocEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        /**
         *放大占位框
         * @param e
         */
        var dragDocFlag = false, dragDoc = {}, textdocW = 1, textdocH = 1, textdocument = null;
        window.resizeDocStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;

            $scope.template.levelI = targ.dataset.index || $scope.template.levelI;
            textdocument = angular.copy($scope.currentPage.levels.level[$scope.template.levelI].textdocument);

            if (textdocument.lock) {
                return
            }
            dragDoc.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragDoc.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragDoc.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragDoc.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
            dragDoc.maxX = $(".slide" + $scope.arrI + $scope.pageI).width();
            dragDoc.maxY = $(".slide" + $scope.arrI + $scope.pageI).height();
            dragDocFlag = true;


            $scope.RightPanel = 'textDoc';
            textdocW = angular.copy(textdocument.geometry.width);
            textdocH = angular.copy(textdocument.geometry.height);

            document.addEventListener("mousemove", resizeDocOn, true);
            document.addEventListener("mouseup", resizeDocEnd, true);
            // setZindex(e.target.dataset.index);
        };
        function resizeDocOn(e) {
            if (dragDocFlag) {
                fn(e);
                // 是否按住shift键
                dragDoc.shiftKey = e.shiftKey;
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = $scope.template.levelI;
                if (imgIndex == null) {
                    return;
                }

                var currentPage = $scope.currentPage;
                if (currentPage == null) {
                    return;
                }
                var page = $scope.document.pages[$scope.pageI];
                var pressImg = $scope.currentPage.levels.level[$scope.template.levelI].textdocument;
                if (pressImg == null) {
                    return;
                }

                var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - pressImg.geometry.x * currentPage.pageScaleW * page.scale) / currentPage.pageScaleW / page.scale;
                var h = w / pressImg.geometry.width * pressImg.geometry.height
                if (w < 40 || h < 40) {
                    return
                }

                var moveW = (dragDoc.maxX - pressImg.geometry.x * currentPage.pageScaleW * page.scale) / (currentPage.pageScaleW * page.scale);
                var moveH = (dragDoc.maxY - pressImg.geometry.y * currentPage.pageScaleH * page.scale) / (currentPage.pageScaleW * page.scale);

                if (w < moveW && h < moveH) {
                    pressImg.geometry.width = w;
                    pressImg.geometry.height = h;
                }

                // if (e.clientX < dragDoc.maxX && e.clientY < dragDoc.maxY) {

                // }

                resizeLevelWaH();
            }
            $scope.$digest();
            return false;
        }

        function resizeDocEnd() {
            dragDocFlag = false;
            dragDoc = {};
            document.removeEventListener("mousemove", resizeDocOn, true);
            document.removeEventListener("mouseup", resizeDocEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        function resizeLevelWaH() {
            var page = $scope.document.pages[$scope.pageI];
            if ($scope.arrI == 1) {
                $scope.currentPage = page.arr;
            } else {
                $scope.currentPage = page.arr2;
            }

            var doc = $scope.currentPage.levels.level[$scope.template.levelI].textdocument;
            doc.background.offsetx = textdocument.background.offsetx * doc.geometry.width / textdocW;
            doc.background.offsety = textdocument.background.offsety * doc.geometry.height / textdocH;
            doc.background.width = textdocument.background.width * doc.geometry.width / textdocW;
            doc.background.height = textdocument.background.height * doc.geometry.height / textdocH;

            angular.forEach(doc.levels.level, function (level, l) {
                var textdoc = textdocument.levels.level[l];
                if (level.imageart) {
                    var geo = {
                        width: textdoc.imageart.geometry.width * doc.geometry.width / textdocW,
                        height: textdoc.imageart.geometry.height * doc.geometry.height / textdocH,
                        x: textdoc.imageart.geometry.x * doc.geometry.width / textdocW,
                        y: textdoc.imageart.geometry.y * doc.geometry.height / textdocH,
                    }
                    level.imageart.geometry = geo;
                    level.imageart.offsetx = textdoc.imageart.offsetx * doc.geometry.width / textdocW;
                    level.imageart.offsety = textdoc.imageart.offsety * doc.geometry.height / textdocH;
                }
                if (level.textbox) {
                    var textGeo = {
                        width: textdoc.textbox.geometry.width * doc.geometry.width / textdocW,
                        height: textdoc.textbox.geometry.height * doc.geometry.height / textdocH,
                        x: textdoc.textbox.geometry.x * doc.geometry.width / textdocW,
                        y: textdoc.textbox.geometry.y * doc.geometry.height / textdocH,
                    }
                    level.textbox.geometry = textGeo;
                    level.textbox.style.size = textdoc.textbox.style.size * doc.geometry.width / textdocW;
                    level.textbox.style.lineheight = textdoc.textbox.style.lineheight * doc.geometry.width / textdocW;
                }
            })
        }

        $scope.slipGroup = function () {
            var currentPage = $scope.currentPage;
            var textdocument = angular.copy(currentPage.levels.level[$scope.template.levelI].textdocument);
            console.log(textdocument);
            // return;
            $scope.deleteBox();
            var textGeo = textdocument.geometry;
            if (textdocument.background && textdocument.background.resource && textdocument.background.resource.identifier) {
                var currentLevel = addLevel($scope.currentPage);
                var mm = {
                    width: unitTransformPx(textGeo.width),
                    height: unitTransformPx(textGeo.height),
                    x: unitTransformPx(textGeo.x),
                    y: unitTransformPx(textGeo.y)
                }

                var angle = 0;
                if (textdocument.rotation.angle) {
                    angle = textdocument.rotation.angle;
                }
                var resource = textdocument.background.resource;
                var scale = 1;
                if (textdocument.background.adaptation == "Height") {
                    scale = textdocument.background.width / textdocument.geometry.width;
                }
                currentLevel.imageart = ({
                    geometry: {
                        width: textdocument.geometry.width,
                        height: textdocument.geometry.height,
                        x: textdocument.geometry.x,
                        y: textdocument.geometry.y
                    },
                    rotation: {
                        angle: angle
                    },
                    // 模板使用，微信端不可进行操作
                    // type: 'static',
                    resource: {
                        identifier: resource.identifier,
                        provider: 'qiniu',
                    },
                    width: resource.width,
                    height: resource.height,
                    scale: scale,
                    offsetx: textdocument.background.offsetx,
                    offsety: textdocument.background.offsety,
                    transparency: 1,
                    onlyshow: false,
                    movable: textdocument.movable,
                    editable: textdocument.editable,
                    useradded:true,
                    bgImg: '',
                    levelI: $scope.template.levelI,
                    pageI: $scope.pageI,
                    current: 'imageart',
                    lock: false
                });

                $scope.selectBox = currentLevel.imageart;
            }
            angular.forEach(textdocument.levels.level, function (level, i) {
                if (level.imageart) {
                    var currentLevel = addLevel($scope.currentPage);
                    var geo = level.imageart.geometry;
                    var mm = {
                        width: unitTransformPx(geo.width),
                        height: unitTransformPx(geo.height),
                        x: unitTransformPx(geo.x + textGeo.x),
                        y: unitTransformPx(geo.y + textGeo.y)
                    }
                    var resource = level.imageart.resource;
                    currentLevel.imageart = ({
                        geometry: {
                            width: level.imageart.geometry.width,
                            height: level.imageart.geometry.height,
                            x: level.imageart.geometry.x + textGeo.x,
                            y: level.imageart.geometry.y + textGeo.y
                        },
                        rotation: {
                            angle: 0
                        },
                        resource: {
                            identifier: resource.identifier,
                            provider: 'qiniu',
                        },
                        width: resource.width,
                        height: resource.height,
                        scale: level.imageart.scale,
                        offsetx: level.imageart.offsetx,
                        offsety: level.imageart.offsety,
                        transparency: level.imageart.transparency,
                        onlyshow: false,
                        movable: textdocument.movable,
                        editable: textdocument.editable,
                        useradded:true,
                        bgImg: '',
                        levelI: $scope.template.levelI,
                        pageI: $scope.pageI,
                        current: 'imageart',
                        lock: false
                    });
                }
                if (level.textbox) {
                    var currentLevel = addLevel($scope.currentPage);
                    var geo = level.textbox.geometry;
                    var mm = {
                        width: unitTransformPx(geo.width),
                        height: unitTransformPx(geo.height),
                        x: unitTransformPx(geo.x + textGeo.x),
                        y: unitTransformPx(geo.y + textGeo.y)
                    }
                    currentLevel.textbox = angular.copy(level.textbox);
                    currentLevel.textbox.movable = textdocument.movable;
                    currentLevel.textbox.useradded = true;
                    currentLevel.textbox.editable = textdocument.editable;
                    currentLevel.textbox.levelI = $scope.template.levelI;
                    currentLevel.textbox.pageI = $scope.pageI;
                    currentLevel.textbox.current = 'textbox';
                    currentLevel.textbox.parentCurrent = null;
                    currentLevel.textbox.lock = false;
                    currentLevel.textbox.geometry.x = level.textbox.geometry.x + textGeo.x;
                    currentLevel.textbox.geometry.y = level.textbox.geometry.y + textGeo.y;
                }
            })
            updateHistorys();
            $scope.currentPage = currentPage;
            console.log($scope.currentPage)

        }

        $scope.isAlignHover = false;
        $scope.isDistriHover = false;
        var toobarFlag1 = true;
        var toobarFlag2 = true;
        $scope.toobarAlignment = function () {
            $(".space-slider").addClass('hide1');
            if (toobarFlag1) {
                $scope.isAlignHover = true;
                $scope.isDistriHover = false;
                toobarFlag1 = false;
                toobarFlag2 = true;
            } else {
                $scope.isAlignHover = false;
                toobarFlag1 = true;
            }
        }

        function compare2(property, attr) {
            return function (a, b) {
                var value1 = a.geometry[property];
                var value2 = b.geometry[property];
                if (attr) {
                    return value1 - value2;
                }
                else {
                    return value2 - value1;
                }
            }
        }
        $scope.boxAlignment = function (attr) {
            var levels = $scope.currentPage.levels;
            var all = getAllCtrlKeyArray();

            if (attr == 'left') {
                all.sort(compare2('x', true));
            } else if (attr == 'right') {
                all.sort(compare2('width', false));
                all.sort(compare2('x', false));
            } else if (attr == 'top') {
                all.sort(compare2('y', true));
            } else if (attr == 'bottom') {
                all.sort(compare2('height', false));
                all.sort(compare2('y', false));
            }
            if(attr == 'vertical' && all.length>0){
                var verticalArr = [];
                all.forEach(function (item) {
                    verticalArr.push(item.geometry.y + item.geometry.height/2)
                })
                var verticalMin = Math.min.apply(null, verticalArr);
                var verticalMax = Math.max.apply(null, verticalArr);
                var verticalY = verticalMin + (verticalMax - verticalMin)/2;
            }
            if(attr == 'standard' && all.length>0){
                var standardArr = [];
                all.forEach(function (item) {
                    standardArr.push(item.geometry.x + item.geometry.width/2)
                })
                var standardMin = Math.min.apply(null, standardArr);
                var standardMax = Math.max.apply(null, standardArr);
                var standardX = standardMin + (standardMax - standardMin)/2;
            }

            for (var i = 0; i < all.length; i++) {
                if(!all[i].lock){
                    if (attr == 'left') {
                        all[i].geometry.x = all[0].geometry.x;

                    } else if (attr == 'right') {
                        all[i].geometry.x = all[0].geometry.x + all[0].geometry.width - all[i].geometry.width;

                    } else if (attr == 'top') {
                        all[i].geometry.y = all[0].geometry.y;

                    } else if (attr == 'bottom') {
                        all[i].geometry.y = all[0].geometry.y + all[0].geometry.height - all[i].geometry.height;

                    } else if (attr == 'vertical') {
                        all[i].geometry.y = all[i].geometry.y - (all[i].geometry.y - verticalY) - all[i].geometry.height / 2;
                    } else {
                        all[i].geometry.x = all[i].geometry.x - (all[i].geometry.x - standardX) - all[i].geometry.width / 2;
                    }


                    if (all[i].current == 'imagebox') {
                        levels.level[all[i].levelI].imagebox = all[i];
                    } else if (all[i].current == 'textbox') {
                        levels.level[all[i].levelI].textbox = all[i];
                    } else {
                        levels.level[all[i].levelI].imageart = all[i];
                    }
                }
            }
            // moveTogetherEnd();
            updateHistorys();
        }

        function getAllCtrlKeyArray() {
            var imageboxes = [],
                textboxes = [],
                imagearts = [],
                codes = [],
                all = [];
            var barcode = [];
            var qrcode = [];

            angular.forEach($scope.currentPage.levels.level, function (level, i) {
                if(level.imageart && level.imageart.ctrlKey){
                    all.push(level.imageart)
                }
                if(level.imagebox && level.imagebox.ctrlKey){
                    all.push(level.imagebox)
                }
                if(level.textbox && level.textbox.ctrlKey){
                    all.push(level.textbox)
                }
                if (level.textdocument && level.textdocument.ctrlKey) {
                    all.push(level.textdocument)
                }
                if (level.textdocument && level.textdocument.ctrlKey) {
                    all.push(level.textdocument)
                }
                if (level.code && level.code.ctrlKey) {
                    all.push(level.code)
                }
            })
            // //选中的占位框
            // for (var i = 0; i < imageboxes.length; i++) {
            //     if (imageboxes[i]) {
            //         all.push(imageboxes[i]);
            //     }
            // }
            // //选中的文本框
            // for (var i = 0; i < textboxes.length; i++) {
            //     if (textboxes[i]) {
            //         all.push(textboxes[i]);
            //     }
            // }
            // //选中的素材
            // for (var i = 0; i < imagearts.length; i++) {
            //     if (imagearts[i]) {
            //         all.push(imagearts[i]);
            //     }
            // }
            // //选中的条码
            // for (var i = 0; i < barcode.length; i++) {
            //     all.push(barcode[i]);
            // }
            // for (var i = 0; i < qrcode.length; i++) {
            //     all.push(qrcode[i]);
            // }
            // //选中的二维码
            // for (var i = 0; i < codes.length; i++) {
            //     all.push(codes[i]);
            // }
            return all;
        }

        function resetCtrlKeyArray() {
            resetPageCtrlKey($scope.currentPage);
            vm.CtrlKeyArray.imageboxes = [];
            vm.CtrlKeyArray.textboxes = [];
            vm.CtrlKeyArray.imagearts = [];
            vm.CtrlKeyArray.barcode = [];
            vm.CtrlKeyArray.qrcode = [];
            multiSelect();
        }

        function resetPageCtrlKey(page) {
            var levels = page.levels;
            if(!levels.level){
                return
            }
            for (var x = 0; x < levels.level.length; x++) {
                var l = levels.level[x];
                if (l.imagebox && l.imagebox.current) {
                    l.imagebox.ctrlKey = false;
                }
                if (l.imageart && l.imageart.current) {
                    l.imageart.ctrlKey = false;
                }
                if (l.textbox && l.textbox.current) {
                    l.textbox.ctrlKey = false;
                }
                if (l.textdocument && l.textdocument.current) {
                    l.textdocument.ctrlKey = false;
                }
            }
            multiSelect();
        }

        function ctrlAddSingleArray() {
            if (vm.singleArray.imagebox.index != undefined) {
                var index = vm.singleArray.imagebox.index;
                $scope.currentPage.imageboxes.imagebox[index].ctrlKey = true;
                vm.CtrlKeyArray.imageboxes[index] = vm.singleArray.imagebox;
            }
            if (vm.singleArray.textbox.index != undefined) {
                var index = vm.singleArray.textbox.index;
                $scope.currentPage.textboxes.textbox[index].ctrlKey = true;
                vm.CtrlKeyArray.textboxes[index] = vm.singleArray.textbox;
            }
            if (vm.singleArray.imageart.index != undefined) {
                var index = vm.singleArray.imageart.index;
                $scope.currentPage.imagearts.imageart[index].ctrlKey = true;
                vm.CtrlKeyArray.imagearts[index] = vm.singleArray.imageart;
            }
            if (vm.singleArray.barcode.index != undefined) {
                vm.singleArray.barcode.ctrlKey = true;
                vm.CtrlKeyArray.barcode[0] = vm.singleArray.barcode;
            }
            if (vm.singleArray.qrcode.index != undefined) {
                vm.singleArray.qrcode.ctrlKey = true;
                vm.CtrlKeyArray.qrcode[0] = vm.singleArray.qrcode;
            }
            vm.singleArray = {imagebox: {}, textbox: {}, imageart: {}, barcode: {}, qrcode: {}};
            multiSelect();
        }

        function moveTogether(currentPage, clientX, clientY,dragbox) {
            var page = $scope.document.pages[$scope.pageI];
            var imgWidth = 0, imgHeight = 0, x = 0, y = 0, pro = currentPage.pageScaleW * page.scale;
            angular.forEach(vm.CtrlKeyArray.imageboxes, function (imagebox, i) {
                // 当前页所有占位框
                var box = currentPage.levels.level[imagebox.levelI].imagebox;
                boxSetting(box, imagebox);
            })

            angular.forEach(vm.CtrlKeyArray.imagearts, function (imageart, i) {
                // 当前页所有素材
                var box = currentPage.levels.level[imageart.levelI].imageart;
                boxSetting(box, imageart);
            })

            angular.forEach(vm.CtrlKeyArray.textboxes, function (textbox, i) {
                // 当前页所有文字
                var box = currentPage.levels.level[textbox.levelI].textbox;
                boxSetting(box, textbox);
            })

            angular.forEach(vm.CtrlKeyArray.textdocuments, function (textdocument, i) {
                // 当前页所有文字
                var box = currentPage.levels.level[textdocument.levelI].textdocument;
                boxSetting(box, textdocument);
            })

            function boxSetting(box, CtrlKeyBox) {
                if(!box || box.lock){return}
                imgWidth = box.geometry.width * pro;
                imgHeight = box.geometry.height * pro;
                x = CtrlKeyBox.geometry.x * pro + clientX;
                y = CtrlKeyBox.geometry.y * pro + clientY;
                // if (x <= 0) {
                //     x = 0;
                // }
                // if (x >= (dragbox.slideW - imgWidth)) {
                //     x = dragbox.slideW - imgWidth;
                // }
                // if (y <= 0) {
                //     y = 0;
                // }
                //
                // if (y >= (dragbox.slideH - imgHeight)) {
                //     y = dragbox.slideH - imgHeight;
                // }
                box.geometry.x = x / pro
                box.geometry.y = y / pro
            }
        }


        function moveTogetherEnd() {
            angular.forEach(vm.CtrlKeyArray.imageboxes, function (imagebox, i) {
                // 当前页所有占位框
                var box = $scope.currentPage.levels.level[imagebox.levelI].imagebox;
                ctrlKeySetting(box, imagebox);
            })

            angular.forEach(vm.CtrlKeyArray.imagearts, function (imageart, i) {
                // 当前页所有素材
                var box = $scope.currentPage.levels.level[imageart.levelI].imageart;
                ctrlKeySetting(box, imageart);
            })

            angular.forEach(vm.CtrlKeyArray.textboxes, function (textbox, i) {
                // 当前页所有文字
                var box = $scope.currentPage.levels.level[textbox.levelI].textbox;
                ctrlKeySetting(box, textbox);
            })

            angular.forEach(vm.CtrlKeyArray.textdocuments, function (textdocument, i) {
                // 当前页所有文字
                var box = $scope.currentPage.levels.level[textdocument.levelI].textdocument;
                ctrlKeySetting(box, textdocument);
            })

            function ctrlKeySetting(box, CtrlKeyBox) {
                CtrlKeyBox.geometry.x = box.geometry.x;
                CtrlKeyBox.geometry.y = box.geometry.y;
            }
        }
        // var sortArray=[];
        // new Sortable(document.getElementById('my-ui-list1'), {
        //     swap: true,
        //     filter: '.filtered',
        //     animation: 150,
        //     onEnd: function (/**Event*/evt) {
        //         console.log('onEnd.foo:', [evt.item, evt.from]);
        //         sortArray=[];
        //         angular.forEach($('#my-ui-list1').find('.web-page-nav-div'),function(item,index){
        //             var seq = item.getAttribute("data-sort-tag");
        //             var value = angular.copy($scope.template.pages.page[seq]);
        //             value.seq = index+1;
        //             sortArray.push(value)
        //         });
        //         $scope.template.pages.page = angular.copy(sortArray);
        //         angular.forEach($scope.template.pages.page, function (obj, index) {
        //             getArr($scope.template, obj, index);
        //         });
        //         $scope.$digest();
        //     }
        // });

        $scope.onDropComplete = function(otherIndex, obj, e) {
            var otherPage = angular.copy($scope.template.pages.page[otherIndex]);
            var index = obj.seq-1;
            var choosePage = $scope.template.pages.page[index];
            if(otherPage.unreplace||otherPage.uuid==choosePage.uuid){
                return
            }
            var docIndex1 = null,docI1 = null,docIndex2 = null,docI2 = null;

            for(var i = 0;i<$scope.document.pages.length;i++){
                var p = $scope.document.pages[i];
                if (p.arr.seq == otherPage.seq) {
                    docIndex1 = i;
                    docI1 = 1;
                }
                if (p.arr2) {
                    if (p.arr2.seq == otherPage.seq) {
                        docIndex1 = i;
                        docI1 = 2;
                    }
                }
                if (p.arr.seq == choosePage.seq) {
                    docIndex2 = i;
                    docI2 = 1;
                }
                if (p.arr2) {
                    if (p.arr2.seq == choosePage.seq) {
                        docIndex2 = i;
                        docI2 = 2;
                    }
                }
            }

            //展示页面
            if(docI1==2) {
                $scope.document.pages[docIndex1].arr2 = choosePage;
                $scope.document.pages[docIndex1].arr2.seq = otherIndex-0+1;
            }else{
                $scope.document.pages[docIndex1].arr = choosePage;
                $scope.document.pages[docIndex1].arr.seq = otherIndex-0+1;
            }

            if(docI2==2) {
                $scope.document.pages[docIndex2].arr2 = otherPage;
                $scope.document.pages[docIndex2].arr2.seq = index-0+1;
            }else{
                $scope.document.pages[docIndex2].arr = otherPage;
                $scope.document.pages[docIndex2].arr.seq = index-0+1;
            }

            // 导航栏
            $scope.template.pages.page[index] = otherPage;
            $scope.template.pages.page[index].seq = index-0+1;
            $scope.template.pages.page[otherIndex] = choosePage;
            $scope.template.pages.page[otherIndex].seq = otherIndex-0+1;
        };

        //画布操作
        vm.moveCanvasView = false;
        $scope.ratioJia = function(){
            var scale = angular.copy($scope.document.pages[$scope.pageI].arr.pageScaleW * $scope.document.pages[$scope.pageI].scale);
            if(scale + 0.02 < 3){
                $scope.document.pages[$scope.pageI].scale = (scale + 0.02) / $scope.document.pages[$scope.pageI].arr.pageScaleW;
            }
        }
        $scope.ratioJian = function(){
            // if($scope.document.pages[$scope.pageI].scale > 1){
                var scale = angular.copy($scope.document.pages[$scope.pageI].arr.pageScaleW * $scope.document.pages[$scope.pageI].scale);
                console.log(scale)
                if(scale>0.1){
                    $scope.document.pages[$scope.pageI].scale = (scale - 0.02) / $scope.document.pages[$scope.pageI].arr.pageScaleW;
                }else{
                    $scope.document.pages[$scope.pageI].scale = 0.1 / $scope.document.pages[$scope.pageI].arr.pageScaleW;
                }
            // }
        };
        $scope.ratioInit = function(){
            var _scale = angular.copy($scope.document.pages[$scope.pageI].scale);
            $scope.document.pages[$scope.pageI].scale = 1;
            $scope.document.pages[$scope.pageI].arr.pageScaleW1 = $scope.document.pages[$scope.pageI].arr.pageScaleW * $scope.document.pages[$scope.pageI].scale / _scale;
            vm.moveCanvasObj = {
                x:0,
                y:0
            };
        };
        $scope.ratioOriginal = function(){
            $scope.document.pages[$scope.pageI].scale = 1 / $scope.document.pages[$scope.pageI].arr.pageScaleW;
        };
        $scope.moveCanvasCb = function(){
            vm.moveCanvasView = !vm.moveCanvasView;
        }
        originalEventRatio();
        function originalEventRatio(){
            window.addEventListener('mousewheel', function(event){
                if (event.ctrlKey === true || event.metaKey) {
                    var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : ( - event.detail / 3);
                    if(wheelDelta>0){
                        $scope.ratioJia();
                    }else{
                        $scope.ratioJian();
                    }
                    $scope.$apply();
                    event.preventDefault();
                }
            },{ passive: false});

            $(document).keydown(function(event){
                if(event.keyCode == 32 && !vm.moveCanvasView && !vm.fontOn && !vm.fontDocOn){
                    vm.moveCanvasView = true;
                    $scope.$apply();
                    return false
                }
            });
            $(document).keyup(function(event){
                vm.moveCanvasView = false;
                $scope.$apply();
                return false
            });
        }
        //移动画布
        vm.moveCanvasObj = {
            x:0,
            y:0
        };
        $scope.moveCanvas = function(e){
            if(!vm.moveCanvasView){
                return
            }
            var _moveCanvasObj = angular.copy(vm.moveCanvasObj);
            moveCanvas(e, function (x, y) {
                vm.moveCanvasObj.x = _moveCanvasObj.x + x;
                vm.moveCanvasObj.y = _moveCanvasObj.y + y;
                $scope.$apply();
            })
        }

        //移除画布透明效果
        $scope.opacityElement = function(slide, geometry, width, height){
            var opacity = 1;
            if(!geometry || !slide){
                return 1
            }
            if(slide.arr2){
                if(geometry.x > -geometry.width && geometry.y > -geometry.height && (geometry.x*slide.arr2.pageScaleW*slide.scale) < width && (geometry.y*slide.arr2.pageScaleH*slide.scale) < height){
                    opacity = 1;
                }else {
                    opacity = 0.3;
                }
            }else{
                if(geometry.x > -geometry.width && geometry.y > -geometry.height && (geometry.x*slide.arr.pageScaleW*slide.scale) < width && (geometry.y*slide.arr.pageScaleH*slide.scale) < height){
                    opacity = 1;
                }else {
                    opacity = 0.3;
                }
            }
            return opacity
        }
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreConfig.getAllByTypes({storeId: vm.storeId,type: ["IMGSIZE", "IMGDPI"]}, function (res) {
            if(res.status == 200){
                res.message.forEach(function (item) {
                    if(item.type === 'IMGSIZE'){
                        vm.imgSize = item.value;
                    }
                    if(item.type === 'IMGDPI'){
                        vm.imgDpi = item.value;
                    }
                })
            }
        });

        var throttledResize = angular.throttle(function (item) {
            console.log('No browser drain at each event please.');
            return pxInsufficient(item, vm.imgSize, vm.imgDpi)
        }, 1000);

        $scope.pxInsufficient = function (item) {
            throttledResize(item);
        }
        //元素四角拖拽
        $scope.cursor = function (angle, source, boolean) {
            return cursorInfo(angle, source, boolean);
        }

        //生成缩略图
        vm.thumbnailScale = 0.6;
        function thumbnailInit(callback){
            thumbnailInit1(function (res){
                vm.article.cover=res;
                callback(res);
            })
        }
        function thumbnailInit1(callback) {
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view'), {
                    onrendered: function (canvas) {
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                var identifier = data.key;
                                console.log(identifier)
                                callback(identifier)
                            }
                        })
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $scope.template.pages.page[0].mediabox.width*vm.thumbnailScale,
                    height: $scope.template.pages.page[0].mediabox.height*vm.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: -($scope.template.pages.page[0].mediabox.width - $scope.template.pages.page[0].mediabox.width*vm.thumbnailScale)/2,
                    y: -($scope.template.pages.page[0].mediabox.height - $scope.template.pages.page[0].mediabox.height*vm.thumbnailScale)/2,
                    ratio: vm.thumbnailScale
                });
            })
        };


        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }


        //页面元素
        vm.isEditorPageElementBody = true;
        $scope.levelInfo = function (level, index) {
            var item = "";
            index = completeIndex(index);
            var i = index+1;
            if(level.imagebox){
                item = "相框" + i
            }
            if(level.imageart){
                item = "素材" + i
            }
            if(level.textbox){
                item = level.textbox.text
            }
            if(level.textdocument){
                item = "文本模板" + i
            }
            return item
        };
        $scope.levelDelete = function (index) {
            var levels = $scope.currentPage.levels.level.splice(completeIndex(index), 1);
            if (levels && levels[0] && levels[0].imagebox && levels[0].imagebox.image&& levels[0].imagebox.image.resourceid) {
                for (var j = 0; j < $scope.photos.length; j++) {
                    var arr = $scope.photos[j];
                    if (arr.id == levels[0].imagebox.image.resourceid) {
                        arr.usenum == 0 ? 0 : arr.usenum--;
                    }
                }
            }
            updateHistorys();
        };

        //页面元素移动
        vm.pageElementW = $('.editor-page-element-title').width();
        vm.pageElementH = $('.editor-page-element-title').height();
        vm.pageElementR = 140;
        vm.pageElementT = 120;
        $scope.movePageElement = function (e) {
            fn(e);
            var x = e.clientX;
            var y = e.clientY;
            var r = angular.copy(vm.pageElementR);
            var t = angular.copy(vm.pageElementT);
            isBoxDown = true;
            window.onmousemove = function (e) {
                if (isBoxDown) {
                    var nx = e.clientX;
                    var ny = e.clientY;
                    console.log(nx - x)
                    var nl = r - (nx - x);
                    var nt = ny - (y - t);
                    if(nl < $(".rightPanel").width()){
                        nl = $(".rightPanel").width()
                    }
                    if(nl > ($(".rightPanel").width() + $(".centerPanel").width() - vm.pageElementW)){
                        nl = ($(".rightPanel").width() + $(".centerPanel").width() - vm.pageElementW)
                    }
                    if(nt < $(".centerPanel").offset().top+42){
                        nt = $(".centerPanel").offset().top+42
                    }
                    if(nt > ($(".centerPanel").offset().top + $(".centerPanel").height() - vm.pageElementH)){
                        nt = ($(".centerPanel").offset().top + $(".centerPanel").height() - vm.pageElementH)
                    }
                    vm.pageElementR = nl;
                    vm.pageElementT = nt;
                    $scope.$apply();
                }

            }
            window.onmouseup = function (e) {
                isBoxDown = false;
                e.stopPropagation();
                $scope.$apply();
            }
        };

        //页面元素拖拽
        var sort1 = null;
        var sortArray1 = [];
        function pageListDrag1() {
            $timeout(function () {
                vm.sortingLevel = angular.copy($scope.currentPage.levels.level);
                sort1 = new Sortable(document.getElementById('pageList1'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    onEnd: function (/**Event*/evt) {
                        sortArray1 = [];
                        angular.forEach($('#pageList1').find(".sortingPageView"), function (item, index) {
                            var page = item.getAttribute("data-page");
                            sortArray1.push(JSON.parse(page));
                        });
                        if(sortArray1 && sortArray1.length>0){
                            $scope.currentPage.levels.level = [];
                            $timeout(function () {
                                $scope.currentPage.levels.level = angular.copy(sortArray1.reverse());
                                $scope.currentPage.levels.level.forEach(function (value, index) {
                                    value.seq = index+1;
                                })
                                sortArray1 = [];
                                $scope.template.levelI = null;
                            })
                        }
                    }
                });
            })
        }

        function documentPageInfo() {
            var _i = 0;
            $scope.document.pages.forEach(function (value) {
                if(value.arr){
                    value.arr = $scope.template.pages.page[_i];
                    _i++
                }
                if(value.arr2){
                    value.arr2 = $scope.template.pages.page[_i];
                    _i++
                }
            })
        }
        $scope.dragCompleteClick = function (e, level, index) {
            index = completeIndex(index);
            if(level.imagebox){
                $scope.selectImg(e, index,$scope.arrI,$scope.pageI,level.imagebox)
                $scope.selectBox = level.imagebox;
            }
            if(level.imageart){
                $scope.selectArt(e, index,$scope.arrI,$scope.pageI,level.imageart)
                $scope.selectBox = level.imageart;
            }
            if(level.textbox){
                $scope.selectText(e, index, level.textbox,$scope.arrI)
                $scope.selectBox = level.textbox;
            }
            if(level.textdocument){
                $scope.template.levelI = completeIndex(index);
                $scope.selectBox = level.textdocument;
            }
            fn(e);
        };
        vm.completeIndex = completeIndex;
        function completeIndex(index) {
            return $scope.currentPage.levels.level.length-1-index
        }
        function scrollIntoView() {
            $timeout(function () {
                if(completeIndex($scope.template.levelI) >= 0 && document.getElementById('editor-page-element-body-view' + completeIndex($scope.template.levelI))){
                    document.getElementById('editor-page-element-body-view' + completeIndex($scope.template.levelI)).scrollIntoView()
                }
            })
        }

        $scope.canvasCb = function (e) {
            $scope.showBg = false;
            vm.isImageartTrim = false;
            $scope.imageboxFalseClick();
            fn(e)
        }

        /***********************  边框  ****************************/
        vm.borderUnfold = false;
        function borderInit(){
            ThemeClassify.getAllByCondition4({
                type: 'BORDER',
                storeId: vm.storeId
            }, function (res) {
                if(res.status == '404'){
                    MessageService.error(res.message);
                    return
                }
                vm.borderLabels = res.message;
                if(vm.sources.frameConfigs && vm.sources.frameConfigs.frameConfig.length>0){
                    vm.borderLabels.unshift({
                        cancelled: false,
                        id: null,
                        name: "推荐",
                        seq: '',
                        storeId: 10,
                        type: "BORDER",
                        used: true
                    })
                }
                vm.borderPage = 0;
                vm.borderList = [];
                if(vm.borderLabels && vm.borderLabels.length > 0){
                    borderInit1(vm.borderLabels[0])
                }
            });
        }
        $scope.bordersCb = function(label, index){
            vm.borderId = label;
            vm.borderPage = 0;
            vm.borderList = [];
            borderInit1(vm.borderId);
        }
        $scope.loadMoreBorder = function () {
            vm.borderPage++;
            borderInit1(vm.borderId);
        }
        function borderInit1(item){
            vm.borderId = item;
            if(!vm.borderId){
                angular.forEach(vm.sources.frameConfigs.frameConfig, function (data) {
                    vm.borderList.push(data);
                })
                return
            }
            vm.borderLoading = true;
            FrameConfigs.byCondition3({
                items: [{key: "themeClassify.id", op: "=", value: item.id},{key: "enable", op: "=", value: true},{key: "status", op: "=", value: 'Approved'}],
                page: vm.borderPage,
                size: vm.itemsPerPage,
                sort: ["seq,asc"]
            }, function (res, headers) {
                vm.borderLoading = false;
                angular.forEach(res, function (resp) {
                    vm.borderList.push(resp);
                })
                var _sum = headers('X-Total-Count');
                if(vm.borderList.length < _sum){
                    vm.borderFulfill = true;
                }else {
                    vm.borderFulfill = false;
                }
            }, function () {
            })
        }
        //拖拽排序
        var sort = null;
        var sortArray = [];
        vm.isSort = false;
        function pageListDrag() {
            $timeout(function () {
                vm.sortingPage = angular.copy($scope.template.pages.page);
                sort = new Sortable(document.getElementById('pageList'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    onMove: function (/**Event*/evt, /**Event*/originalEvent) {
                        $timeout(function () {
                            var order = sort.toArray();
                            $scope.template.pages.page.forEach(function (page, index) {
                                if (page.unreplace) {
                                    var _index = order.indexOf((index + 1).toString());
                                    order.splice(_index, 1)
                                }
                            })
                            $scope.template.pages.page.forEach(function (page, index) {
                                if (page.unreplace) {
                                    order.splice(index, 0, (index + 1).toString())
                                }
                            })
                            sort.sort(order)
                        },150)
                        if(JSON.parse($(evt.related).attr("data-page")).unreplace){
                            return false
                        }
                    },
                    onEnd: function (/**Event*/evt) {
                        $timeout(function () {
                            sortArray = [];
                            angular.forEach($('#pageList').find(".sortingPageView"), function (item, index) {
                                var page = item.getAttribute("data-page");
                                sortArray.push(JSON.parse(page));
                            });
                            if(sortArray && sortArray.length>0){
                                sortArray.forEach(function (value, index) {
                                    value.seq = index+1;
                                })
                                $scope.template.pages.page = [];
                                $timeout(function () {
                                    $scope.template.pages.page = angular.copy(sortArray);
                                    documentPageInfo()
                                    sortArray = [];
                                })
                            }
                        })
                    }
                });
            })
        }
        //元素全选
        $scope.levelInif = function() {
            $scope.template.levelI = null;
            $scope.selectBox = null;
            angular.forEach($scope.template.pages.page, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imageart){
                        level.imageart.ctrlKey = false;
                    }
                    if(level.imagebox){
                        level.imagebox.ctrlKey = false;
                    }
                    if(level.textbox){
                        level.textbox.ctrlKey = false;
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        level.textdocument.ctrlKey = false;
                    }
                })
            })
            multiSelect();
        }
        function levelMove(event, operation) {
            angular.forEach($scope.template.pages.page, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imageart && level.imageart.ctrlKey && !level.imageart.edit){
                        if(operation == 'transparency'){
                            level.imageart.transparency = parseFloat($scope.template.transparency/100);
                            return
                        }
                    }
                    if(level.imagebox && level.imagebox.ctrlKey){
                        if(operation == 'transparency'){
                            level.imagebox.transparency = parseFloat($scope.template.transparency/100);
                            return
                        }
                    }
                    if(level.textbox && level.textbox.ctrlKey){
                        if(operation == 'transparency'){
                            level.textbox.transparency = parseFloat($scope.template.transparency/100);
                            return
                        }
                    }
                    if (level.textdocument && level.textdocument.ctrlKey) {
                        if(operation == 'transparency'){
                            level.textdocument.transparency = parseFloat($scope.template.transparency/100);
                            return
                        }
                    }
                })
            })
        }
        function multiSelect() {
            var all = getAllCtrlKeyArray();
            vm.transparencyBtn = true;//透明度
            vm.lockingBtn = true;//锁定
            vm.coverageBtn = true;//图层
            vm.copyBtn = true;//复制
            vm.deleteBtn = true;//删除
            vm.textboxBtn = true;//文字
            vm.toobarAlignmentBtn = false;//对齐
            if(all.length>0){
                $scope.hasText = false;
                vm.toobarAlignmentBtn = true;
                vm.lockingBtn = false;
                vm.coverageBtn = false;
                vm.deleteBtn = false;
                angular.forEach(all, function (level) {
                    if (level.code && level.code.ctrlKey) {
                        vm.transparencyBtn = false;
                    }
                })
            }else{
                if($scope.selectBox && $scope.selectBox.current== "imagebox"){
                    $scope.hasImgUrl = true;
                }else{
                    $scope.hasImgUrl = false;
                }
                if($scope.selectBox && $scope.selectBox.current== "textbox"){
                    $scope.hasText = true;
                }else{
                    $scope.hasText = false;
                }
                if(!$scope.selectBox){
                    vm.transparencyBtn = false;
                    vm.lockingBtn = false;
                    vm.coverageBtn = false;
                    vm.copyBtn = false;
                    vm.deleteBtn = false;
                }
            }
        }

        vm.borderRadiusAllbtn = true
        $scope.borderRadiusAll = function () {
            $scope.selectBox.border.radius.lt = $scope.selectBox.border.radius.all;
            $scope.selectBox.border.radius.rt = $scope.selectBox.border.radius.all;
            $scope.selectBox.border.radius.rb = $scope.selectBox.border.radius.all;
            $scope.selectBox.border.radius.lb = $scope.selectBox.border.radius.all;
        }
        $scope.borderRadiusAllbtn = function(){
            vm.borderRadiusAllbtn = true;
            $scope.selectBox.border.radius.rt = $scope.selectBox.border.radius.lt;
            $scope.selectBox.border.radius.rb = $scope.selectBox.border.radius.lt;
            $scope.selectBox.border.radius.lb = $scope.selectBox.border.radius.lt;
            $scope.selectBox.border.radius.all = $scope.selectBox.border.radius.lt
        }
        // 圆角设置
        vm.isRoundedSet = false;
        $scope.roundedSet = function(attr){
            toolbarStatus();
            vm.isRoundedSet = attr;
        }

        // 边框设置
        vm.isBorderSet = false;
        $scope.borderSet = function(attr){
            toolbarStatus();
            vm.isBorderSet = attr;
        }

        // 阴影设置
        vm.isShadowSet = false;
        $scope.shadowSet = function(attr){
            toolbarStatus();
            vm.isShadowSet = attr;
        }


        $scope.lineTypeChenge = function(lineType){
            $scope.template.pages.page[$scope.pageI].levels.level[$scope.template.levelI].imagebox.border.lineType = lineType;
            $scope.selectBox.border.lineType = lineType;
        }
        $scope.dropShadow = function(imagebox,ratio,ratio1){
            if (imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio1;
            return dropShadow(imagebox,width,height)
        }

        $scope.removeGallery = function (){
            if (vm.deleteList == null || vm.deleteList.length == 0) {
                return;
            }
            for (var z = vm.deleteList.length - 1; z >= 0; z--) {
                var g = vm.deleteList[z];
                for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                    var page = $scope.template.pages.page[i];
                    if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                        continue;
                    }
                    for (var j = page.levels.level.length - 1; j >= 0; j--) {
                        var level = page.levels.level[j];
                        if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                            continue;
                        }
                        if (level.imagebox.image.resourceid == g.id) {
                            level.imagebox.image.resource.identifier = "";
                            level.imagebox.image.resource.adaptation = "";
                            level.imagebox.image.resource.provider = "";
                            level.imagebox.image.resource.offsetx = 0;
                            level.imagebox.image.resource.offsety = 0;
                            level.imagebox.image.resource.height = "";
                            level.imagebox.image.resource.width = "";
                            level.imagebox.image.resource.scale = 1;
                            level.imagebox.image.resource.imgSize = "";
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.imageInfo = null;
                            break;
                        }
                    }
                }
            }
            getSameUseNum();
            getUsedImageboxsNum();
        }

        $scope.galleryDestroyConfirm = function(){
            $scope.removeGallery();
            thumbnailInit1(function (){
                generateJsonFile1(vm.saveflag);
            })
        };
    }
})
();
