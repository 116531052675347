(function () {
    'use strict';

    var threePreview2 = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/three-preview2.html',
        // controller: 'articlePreviewController',
        controllerAs: 'vm',
        bindings: {
            height: '<',
            width: '<',
            moulds: '<',
            modelPath: '<',
            modelBlendents: '<',
            modelData: '<',
            modelBlendentUuid: '<',
            pages: '<',
            onChange:"&"
        },
        controller: ['$timeout','$scope',
            function ($timeout, $scope) {
                var vm = this;
                vm.viewTab = 'Three';
                vm.$onInit = function(){
                    if(!vm.width){vm.width = $(window).width()}
                    if(!vm.height){vm.height = $(window).height()}
                }
                vm.$onChanges = function(data) {
                    if (data.height&&data.height.currentValue) {
                        vm.height = data.height.currentValue;
                    }
                    if (data.width&&data.width.currentValue) {
                        vm.width = data.width.currentValue;
                    }
                    if (data.moulds&&data.moulds.currentValue) {
                        vm.moulds = data.moulds.currentValue;
                    }
                    if (data.modelPath&&data.modelPath.currentValue) {
                        vm.modelPath = data.modelPath.currentValue;
                    }
                    if (data.modelBlendents&&data.modelBlendents.currentValue) {
                        vm.modelBlendents = data.modelBlendents.currentValue;
                    }
                    if (data.modelData&&data.modelData.currentValue) {
                        vm.modelData = data.modelData.currentValue;
                    }
                    if (data.modelBlendentUuid&&data.modelBlendentUuid.currentValue) {
                        vm.modelBlendentUuid = data.modelBlendentUuid.currentValue;
                    }
                    if (data.pages&&data.pages.currentValue) {
                        vm.pages = null;
                        vm.isUpdate = false;
                        $timeout(function () {
                            vm.pages = data.pages.currentValue;
                        })
                    }
                };

                $scope.viewTabCb = function () {
                    vm.viewTab = 'Two';
                    swiper()
                }
                function swiper() {
                    vm.mouldIndex = 0;
                    if(vm.moulds&&vm.moulds.length>0){
                        $timeout(function () {
                            var swiper = new Swiper('.swiper-container-webmin1', {
                                pagination: {
                                    el: '.swiper-pagination',
                                    type: 'bullets',
                                },
                                navigation: {
                                    nextEl: '.swiper-button-next-webmin1',
                                    prevEl: '.swiper-button-prev-webmin1',
                                },
                                on: {
                                    init: function(swiper){
                                        vm.mouldIndex = this.activeIndex;
                                        $scope.$apply();
                                    },
                                    touchEnd: function(swiper1,event){
                                        $timeout(function () {
                                            vm.mouldIndex = swiper.activeIndex;
                                        })
                                    },
                                },
                            });
                        })
                    }
                }
                $scope.previewTab = function () {
                    vm.onChange();
                }
            }
        ]
    };

    angular
        .module('editorApp')
        .component('threePreview2', threePreview2);
})();
