(function() {
    "use strict";
    angular
        .module("editorApp")
        .controller("WebGalleryFolderDialogController", WebGalleryFolderDialogController);
    WebGalleryFolderDialogController.$inject = ['$state', '$q', 'UidService', 'Gallery', '$scope', '$location', 'paginationConstants', 'pagingParams', '$stateParams', '$localStorage', 'Qinius',
         'Folder', '$uibModal', 'MessageService', 'Theme', 'SweetAlert', '$interval', 'PlatformWechatSetting', 'DateUtils', 'ListService','ImageService', 'CustomerUploadAble',
         'Customer', 'CustomerStorages', "$timeout", "$websocket"
    ];;

    function WebGalleryFolderDialogController($state, $q, UidService, Gallery, $scope, $location, paginationConstants, pagingParams, $stateParams, $localStorage, Qinius,
         Folder , $uibModal, MessageService, Theme, SweetAlert, $interval, PlatformWechatSetting, DateUtils, ListService,ImageService, CustomerUploadAble,
         Customer, CustomerStorages, $timeout, $websocket) {
        var vm = this;
        // 是否点击移动按钮
        $scope.selectAllGallery = false;
        $scope.galleryManager = false;
        vm.uploading = false;
        vm.galleries = [];
        $scope.moveGalleryIds = [];
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        if ($localStorage.user !=null) {
            vm.userId = $localStorage.user.id
        }
        vm.folderId = $stateParams.folderId;
        Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
            vm.currentFolder =  res.message.filter(function(folder){
                return folder.id == vm.folderId;
            })
        });
        // vm.loadPage = loadPage;
        // vm.predicate = pagingParams.predicate;
        // vm.reverse = pagingParams.ascending;
        // vm.transition = transition;
        // vm.jump = jump;
        vm.maxId = null;
        var indd = 0;
        var len = 0;
        var errorImageArr = [];
        var repeatCount = 0;
        var timer = null;
        vm.platformSetting = PlatformWechatSetting.get();

        // vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.itemsPerPage = 40;
        vm.page = 0;

        vm.store = $localStorage.store;
        $scope.domain = $localStorage.domain;
        vm.customer = Customer.current();

        vm.tokens = Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"});

        vm.customerStorage =  CustomerStorages.getByCustomer({customerId: vm.userId});

        $scope.progressColor = function (size) {
            return progressColor(size)
        }

        //使用条款
        $scope.clause = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/web-gallery/clause-modal.html',
                controller: 'ClauseModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xs'
            })
        }


        $scope.storageDel = function() {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                    }
                });
            };
            createModal2('app/entities/web-gallery/storage-del.html', 'StorageDelController').result.then(function() {

            });
        }

        $scope.buySize = function() {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                    }
                });
            };
            createModal2('app/entities/web-gallery/storage-buy.html', 'StorageBuyController').result.then(function() {
                debugger;
                CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                    progressInit(data);
                });
            });
        }


        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                if(values){
                    indd = 0;
                    for (var i = 0; i < values.length; i++) {
                        var value = values[i];
                        var data = {key:value.key,hash:value.hash};
                        serviceUpload(data, value,values.length);
                    }
                }
                bigSizePics = [];
            }, function(){
                bigSizePics=[];
                vm.uploading = false;
                vm.page = 0;
                vm.maxId = null;
                vm.galleries = [];
                vm.dataGalleries = [];
                loadAll()
                CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                    progressInit(data);
                });
            });
        }

        vm.qrcodeShow = false;
        var qrcode = null;
        $scope.uploadByPhone = function () {
            uploadVerify(null,CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                vm.qrcodeShow = true;
                $uibModal.open({
                    templateUrl: 'app/entities/web-gallery/uploadByPhone.html',
                    controller: 'UploadByPhoneController',
                    backdrop: 'static',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: {
                            text: vm.platformSetting.storeNotifyUrl + "/#/" + vm.storeId + "/"+ vm.folderId + "/gallery/" + vm.userId
                        },
                    }
                }).result.then(function() {
                    vm.qrcodeShow = false;
                }, function() {

                });
            }, function () {
                $scope.buySize()
            })
        }

        $scope.closeQrCode = function(){
            $interval.cancel(timer);
            vm.qrcodeShow = false;
        }

        function progressInit(item){
            vm.sumSize =  Number(item.initSize) + Number(item.buySize);
            vm.useSize = Number(item.usedSize);
            vm.percentage = 0;
            $timeout(function () {
                vm.percentage = vm.useSize / vm.sumSize;
            });
        };

        $q.all([vm.platformSetting.$promise, vm.tokens.$promise, vm.customerStorage.$promise]).then(function() {
            vm.token = vm.tokens.token;
            progressInit(vm.customerStorage);
            vm.page = 0;
            vm.galleries = [];
            loadAll();
            websocket();
        });

        $scope.goBack =function(){
            $state.go("webGallery", {storeId:vm.storeId});
        }


        $scope.managerGallery = function() {
            $scope.galleryManager = true;
        }


        $scope.cancelGalleryManager = function() {
            $scope.moveGalleryIds = [];
            $scope.galleryManager = false;
            $scope.selectAllGallery = false;
            for (var i = vm.galleriesAll.length - 1; i >= 0; i--) {
                var a = vm.galleriesAll[i];
                if (a.selected) {
                    a.selected = false;
                }
            }
        }


        $scope.moveToFolder = function() {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error("移动失败,请至少选择一张照片");
                return
            }
            createModal2('app/entities/web-folder/entity-move-folder.html', 'EntityMoveFolderController', $scope.moveGalleryIds, "Gallery",vm.folderId).result.then(
                function() {
                    vm.page = 0;
                    vm.maxId = null;
                    vm.galleries = [];
                    loadAll()
                    $state.reload();
                });
        }
        $scope.moveToFolderOne = function(f,event) {
            event.stopPropagation();
            var ids=[];
            ids.push(f.id);
            createModal2('app/entities/web-folder/entity-move-folder.html', 'EntityMoveFolderController', ids, "Gallery",vm.folderId).result.then(
                function() {
                    vm.page = 0;
                    vm.maxId = null;
                    vm.galleries = [];
                    loadAll()
                    Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
                        $scope.folders = res.message;
                        $state.reload();
                    });
                });
        }


        var createModal2 = function(url, controller, ids, type,folderId) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type,
                        ids: ids,
                        folderId: folderId
                    },
                }
            });
        };



        $scope.folderSelected = function(folder) {
            if (!$scope.folderManager) {
                return;
            }
            if (folder != null) {
                if (folder.id == $scope.folderId) {
                    folder.selected = false;
                    $scope.folderId = null;
                } else {
                    for (var i = $scope.folders.length - 1; i >= 0; i--) {
                        var f = $scope.folders[i];
                        if (f.selected) {
                            f.selected = false;
                        }
                    }
                    folder.selected = true;
                    $scope.folderId = folder.id;
                }
            }
        }



        vm.dataGalleries = [];
        $scope.moveGalleryIds = [];
        vm.galleriesAll =[];
        vm.totalItems = null;
        function loadAll() {
            $scope.selectAllGallery =  false;
            var items = [];
            items.push({key: "folderId", op: "=",value: vm.folderId});
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            Gallery.getPage({
                items: items,
                page: vm.page,
                size:vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                return ["id,desc"]
            }
            function onSuccess(data, headers) {
                if (vm.totalItems == null) {
                    vm.totalItems = Number(headers('X-Total-Count'));
                }
                vm.galleries=[];
                data.forEach(function(g) {
                    vm.galleries.push(g)
                    vm.galleriesAll.push(g)
                })
                if (!vm.maxId && data.length > 0) {
                    vm.maxId = data[0].id;
                }
                for (var i = 0; i < vm.galleries.length; i++) {
                    var g = vm.galleries[i];

                    g.createdDate = DateUtils.convertLocalDateToServer(g.createdDate);
                    var result = ListService.inList(g, vm.dataGalleries, ["createdDate"] );
                    if (!result) {
                        vm.dataGalleries.push({createdDate:g.createdDate, galleries:[g]});
                    }else{
                        result.galleries.push(g);
                    }
                }
            }

            function onError(error) {}
        }

        // function loadPage(page) {
        //     vm.page = page;
        //     vm.transition();
        // }

        // function transition() {
        //     $state.transitionTo($state.$current, {
        //         page: vm.page,
        //         storeId:vm.storeId,
        //         folderId:vm.folderId,
        //         sort: "createdDate,desc",
        //         search: vm.currentSearch
        //     });
        // }

        $scope.selectSingle = function(gallery,event) {
            event.stopPropagation();
            gallery.selected = !gallery.selected;
            if (gallery.selected) {
                $scope.moveGalleryIds.push(gallery.id);
            } else {
                var inde1 = $scope.moveGalleryIds.indexOf(gallery.id);
                $scope.moveGalleryIds.splice(inde1, 1);
            }
            if ($scope.moveGalleryIds.length == vm.galleries.length) {
                $scope.selectAllGallery = true;
            } else {
                $scope.selectAllGallery = false;
            }
        }


        /**
         * 全选
         * @param flag-----  true：文件夹     false:作品
         */
        $scope.chooseAll = function() {
            $scope.moveGalleryIds = [];
            $scope.selectAllGallery = !$scope.selectAllGallery;
            angular.forEach(vm.galleriesAll, function(gallery, i) {
                if ($scope.selectAllGallery) {
                    gallery.selected = true;
                    $scope.moveGalleryIds.push(gallery.id);
                } else {
                    gallery.selected = false;
                    $scope.moveGalleryIds = [];
                }
            })
        }

        $scope.deleteGalleries = function() {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error("操作失败,请至少选择一个照片");
                return
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确认要删除选中的照片么？'
            },function(){
                Gallery.batchDelete($scope.moveGalleryIds, function() {
                    vm.page = 0;
                    vm.maxId = null;
                    vm.galleries = [];
                    loadAll()
                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                        progressInit(data);
                    });
                    $state.reload();
                    MessageService.success("操作成功,您的照片已成功删除")
                }, function() {
                    MessageService.error("操作失败,请稍后操作或者联系管理员")
                })
            });
        }

        $scope.deleteGalleriesOne = function(gallery,event) {
            event.stopPropagation()
            var ids = [];
            ids.push(gallery.id)
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确认要删除选中的照片么？'
            }, function() {
                Gallery.batchDelete(ids, function() {
                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                        progressInit(data);
                    });
                    $state.reload();
                    MessageService.success("操作成功,您的照片已成功删除")
                }, function() {
                    MessageService.error("操作失败,请稍后操作或者联系管理员")
                })
            });
        }

        $scope.uploadVerify = function(){
            uploadVerify(null,CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                $("#uploadImg").click();
            }, function () {
                $scope.buySize()
            })
        };
        window.uploadImageMutiple = function($event) {
            var files = [];
            for (var i = 0; i < $event.target.files.length; i++) {
                files.push($event.target.files[i])
            }
            var lowerSize = [];
            angular.forEach(files, function(item, index) {
                if (item.size / 1024 < 150) {
                    lowerSize.push(index + 1);
                }
            });
            if (lowerSize.length > 0) {
                swal({
                    title: '存在' + lowerSize.length + '张图片小于150k\n确认上传？',
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: "确认",
                    cancelButtonText: "取消"
                },
                function(isConfirm) {
                    if (isConfirm) {
                        asyncUpload(files);
                    } else {
                        files = files.filter(function(item, index) {
                            return item.size / 1024 >= 150;
                        });
                        if (files.length == 0) {
                            vm.page = 0;
                            vm.maxId = null;
                            vm.galleries = [];
                            loadAll()
                        } else {
                            asyncUpload(files);
                        }
                    }
                })
            } else {
                asyncUpload(files);
            }
        };

        var bigSizePics = [];
        function asyncUpload(files) {

            $scope.$digest();

            indd = 0;
            errorImageArr = [];
            len = files.length;
            var files1 = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size>=20*1024*1024 ){
                    file.imageSize= Math.ceil(file.size/1024/1024);
                    bigSizePics.push(file);
                }else{
                    files1.push(file)
                }
            }
            if(files1.length>0) {
                vm.uploading = true;
                for (var i = 0; i < files1.length; i++) {
                    var file = files1[i];
                    if (file.size < 20 * 1024 * 1024) {
                        ImageService.backendUploadPics(file, $scope.domain, vm.token, function (res, f) {
                            serviceUpload(res, f, files1.length);
                        }, function (res) {
                            errorImageArr.push(res);
                            checkUploadResult();
                        });
                    }
                }
            }else{
                if(bigSizePics.length>0){
                    vm.uploading = true;
                    thumbnailatorPic();
                }
            }
        }
        vm.duplicateList = [];
        vm.isDuplicateList = false;
        function serviceUpload(data, file,fileLength) {
            Gallery.upload({
                hash: data.hash,
                identifiers: data.key,
                provider: "QINIU",
                fileName: file.name,
                imgSize: file.size,
                folderId: vm.folderId
            }, function(resp) {
                if(resp.duplicate){
                    resp.identifier= $scope.domain + resp.identifier;
                    vm.duplicateList.push(resp)
                }
                indd++;
                if ( indd == fileLength) {
                    document.getElementById('uploadImg').value = '';//上传完清空输入框
                    vm.uploading = false;
                    if(vm.duplicateList.length>0){
                        MessageService.confirm({
                            title :'提示',
                            cancel :'取消',
                            confirm :'确认',
                            msg :'检测到图库中有'+vm.duplicateList.length+'张重复图片，是否需要自动去重？',
                            html: '<span class="themecolor">查看重复图片</span>'
                        }, function(res) {
                            if(res == 'htmlClick'){
                                vm.isDuplicateList = true;
                            }else{
                                var ids = [];
                                vm.duplicateList.forEach(function (item){
                                    ids.push(item.id)
                                })
                                Gallery.batchDelete(ids, function () {
                                    MessageService.success("去重成功！");
                                    vm.page = 0;
                                    vm.maxId = null;
                                    vm.galleries = [];
                                    loadAll()
                                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                                        progressInit(data);
                                    });
                                    vm.duplicateList=[];
                                    if (bigSizePics.length > 0) {
                                        thumbnailatorPic();
                                    }
                                    $state.reload();
                                }, function (error) {
                                    MessageService.error("删除失败！");
                                    vm.duplicateList=[];
                                    if (bigSizePics.length > 0) {
                                        thumbnailatorPic();
                                    }
                                });
                            }
                        },function (){
                            vm.duplicateList=[];
                            if (bigSizePics.length > 0) {
                                thumbnailatorPic();
                            }
                        })
                    }else{
                        MessageService.successConfirm({
                            title:'上传完成',
                            imgUrl:'content/images/icon/success-icon.png',
                            btnText:'知道了'
                        },function(){
                            // $state.reload();
                            if(bigSizePics.length){
                                thumbnailatorPic();
                            }else{
                                // vm.dataGalleries = [];
                                // vm.page = 0;
                                // vm.galleries = [];
                                // loadAll()
                            }
                        })
                        CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                            progressInit(data);
                        });
                    }
                }else {
                   checkUploadResult();
                }
            }, function(error) {
                errorImageArr.push(file);
                checkUploadResult();
            });
        }
        $scope.duplicateChange = function (){
            vm.isDuplicateList = false;
            vm.duplicateList=[];
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
                return
            }

            vm.page = 0;
            vm.maxId = null;
            vm.galleries = [];
            loadAll()
            CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                progressInit(data);
            });
            $state.reload();
            console.log(vm.duplicateList)
        }

        // function jump() {
        //     vm.page = vm.jumpText<=0?1:vm.jumpText;
        //     loadPage(vm.page);
        // }

        function checkUploadResult(){
            if (errorImageArr.length != 0 && len == (indd + errorImageArr.length)) {
                vm.uploading = false;
                SweetAlert.swal({
                    title: '上传完成',
                    text: "存在" + errorImageArr.length + "张照片中断\n请重新选择上传",
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: "确认",
                    cancelButtonText: "取消"
                }, function(isConfirm) {
                    if (isConfirm) {
                        asyncUpload(errorImageArr);
                    } else {
                        SweetAlert.swal({
                            title: '图片已经取消上传',
                            imageUrl: Theme.sweetHintImg(),
                            text: "",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        vm.page = 0;
                        vm.maxId = null;
                        vm.galleries = [];
                        loadAll()
                    }
                })
            }
        }
        // 滑动加载更多
        $('.home-body').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(vm.galleriesAll.length < vm.totalItems){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.page++;
                    loadAll()
                }
            }
        });

        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("ws://localhost:8096/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == vm.folderId) {
                    vm.galleriesAll.push(photo);
                    vm.totalItems++;
                    photo.createdDate = DateUtils.convertLocalDateToServer(photo.createdDate);
                    var result = ListService.inList(photo, vm.dataGalleries, ["createdDate"] );
                    if (!result) {
                        vm.dataGalleries.unshift({createdDate:photo.createdDate, galleries:[photo]});
                    }else{
                        result.galleries.unshift(photo);
                    }
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }

    }
})();
