(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('NumberOrderedController', NumberOrderedController);

    NumberOrderedController.$inject =  ['$stateParams', 'Store', '$state', '$uibModalInstance', 'MessageService',   '$scope', '$q',
     '$localStorage', 'PlatformAbutment', 'SalesOrder'];

    function NumberOrderedController($stateParams, Store, $state, $uibModalInstance, MessageService,   $scope, $q,
        $localStorage, PlatformAbutment, SalesOrder) {
        var vm = this;
        vm.clear = clear;
        vm.thirdConfigId = null;
        vm.orderSource = null;
        vm.save = save;
        vm.width = 100;
        vm.storeId = $stateParams.storeId;
        vm.store = Store.get({id:vm.storeId});
        vm.thirds = PlatformAbutment.getAllByCondition({
            items: [{
                key: "enabled",
                op: "=",
                value: "true"
            }]
        });

        $(".navbar").show();
        $(".wrap").show();

        $q.all([vm.thirds.$promise]).then(function () {
            if (vm.thirds.length>0) {
                vm.width= vm.thirds.length;
                if (vm.width>3) {
                    vm.width = 3;
                }
                vm.third = vm.thirds[0];
            }

        });

        $scope.changePay = function( third) { //第三方支付的时候 third会有值
            vm.third = third;
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save(){
            if (!vm.sourceNumber) {
                MessageService.error("请输入单号");
                return;
            }
            SalesOrder.createBySourceNumber2({sourNumber:vm.sourceNumber, source:vm.third.platform, sourceType:"WEB"}, function(data){
                if (data && data.status==200 && data.message && data.message.id) {
                    MessageService.success("订单成功确认");
                    $uibModalInstance.close(data.message);
                }else{
                    MessageService.error(data.message);
                    $uibModalInstance.dismiss('cancel');
                }
            },function(data){
                if (data && data.data) {
                    MessageService.error(data.data.message);
                }else{
                    MessageService.error("订单确认失败,请稍后再试");
                }
                $uibModalInstance.dismiss('cancel');
            })

        }

        $scope.abutmentType = abutmentType;

    }
})();
