(function () {
    'use strict';

    var menuBar = {
        templateUrl: 'app/entities/gallery/menuBar.html',
        bindings: {
            storeId:'=',
            column:'='
        },
        controller: ['$rootScope','$q', '$stateParams', '$state', '$scope', 'StoreStyleSetting', 'PlatformWechatSetting','Iphone', function ($rootScope, $q, $stateParams, $state, $scope,StoreStyleSetting, PlatformWechatSetting,Iphone) {
            var vm = this;
            var storeId = this.storeId;
            // var url = window.location.href.split("#")[1];
            var url = window.location.href;
            console.log(url)
            console.log($rootScope)
            vm.storeStyleSetting = StoreStyleSetting.get();
            $scope.navigationSettings = [];
            $scope.column = this.column;
            // 判断是否是小程序
            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake') {
                vm.type = 'Mini';
            }else{
                vm.type = 'Wechat';
            }
            // alert(vm.type)

            $q.all([vm.storeStyleSetting.$promise]).then(function(){
                vm.storeStyleSetting.navigationSettings.sort(compare('seq'));
                console.log(vm.storeStyleSetting)
                $scope.navigateOpen = vm.storeStyleSetting.navigateOpen;
                angular.forEach(vm.storeStyleSetting.navigationSettings,function (nav) {
                    if(!nav.cancelled&&nav.used){
                        $scope.navigationSettings.push(nav);
                        if(url==nav.pathWechat){
                            $scope.nav_id = nav.id;
                        }
                        if (nav.columnName.length > 4) {
                            nav.columnName = nav.columnName.substring(0, 4) + "..";
                        }
                    }
                })

                if(!$scope.navigationSettings.length||!$scope.navigateOpen){
                    vm.platformWechatSetting = PlatformWechatSetting.get({});
                }
            })

            function compare(property) {
                return function (a, b) {
                    var value1 = a[property];
                    var value2 = b[property];
                    return value1 - value2;
                }
            }


            $scope.chooseStyle = function (nav) {
                console.log(nav);
                if(vm.type=='Mini'){
                    wx.miniProgram.redirectTo({url: '/'+nav.pathSmallApp})
                }else{
                    window.location.href = nav.pathWechat;
                }
            }

            //风切切换路由判断
            $scope.homeStyle = function(){
                if(vm.type=='Mini'){
                    wx.miniProgram.redirectTo({url: '/pages/home/startPage/startPage'});
                    return
                }
                if(localStorage.getItem('themeFlag') == 'two'){
                    window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/2';
                }else if(localStorage.getItem('themeFlag') == 'three'){
                    window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/3';
                }else{
                    window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId;
                }
            };

            $scope.categoryStyle = function () {
                if(vm.type=='Mini'){
                    wx.miniProgram.redirectTo({url: '/pages/category/categoryListPage/categoryListPage'});
                    return
                }
                // 切换时隐藏底部栏
                $(".iphoneX-content").height(0);
                localStorage.setItem('categoryIndex', 0);
                if (localStorage.getItem('themeFlag') == 'two') {
                    window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/categoryTwo';
                } else if (localStorage.getItem('themeFlag') == 'three') {
                    window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/categoryThree';
                } else {
                    window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/category';
                }
                // $state.go('categoryTwo',{storeId:vm.storeId})
            };

            $scope.customerStyle = function () {
                if(vm.type=='Mini'){
                    wx.miniProgram.redirectTo({url: '/pages/information/myInformation/myInformation'});
                    return
                }
                $(".iphoneX-content").height(0);
                    window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/customer';
                // if (localStorage.getItem('themeFlag') == 'two' || localStorage.getItem('themeFlag') == 'three') {
                //     window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/customerTwo';
                // } else {
                //     window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/customer';
                // }
            };
            $scope.gotoGallery = function () {
                $state.go('gallery',{storeId:storeId})
            }

            $scope.redeemStyle = function () {
                if(vm.type=='Mini'){
                    wx.miniProgram.redirectTo({url: '/pages/portfolio/myCard/myCard'});
                    return
                }
                window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/redeem-code';
            };

            $scope.isIphoneX = Iphone;
            var windowH = $(window).height();
            console.log("------------- " + windowH + " -------------");
            var h = localStorage.getItem('windowMinH');
            if (!h) {
                localStorage.setItem('windowMinH', windowH);
                h = windowH;
            }

            if ($scope.isIphoneX) {
                var wh = $(window).height(); //页面可视化区域高度
                if (wh > h) {
                    $(".iphoneX-content").height(36);
                } else {
                    $(".iphoneX-content").height(0);
                }
            }

            $(window).scroll(function () {
                if ($scope.isIphoneX) {
                    setTimeout(function () {
                        var wh = $(window).height(); //页面可视化区域高度
                        if (wh > h) {
                            $(".iphoneX-content").height(36);
                        } else {
                            $(".iphoneX-content").height(0);
                        }
                    },400)

                }
            })
        }]
    };

    angular
        .module('editorApp')
        .component('menuBar', menuBar);
})();
