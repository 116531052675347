(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('PageMySuffixCreateController', PageMySuffixCreateController);

    PageMySuffixCreateController.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService', '$stateParams', 'ProductXmlFileServes', 'ThemeClassify', 'ProductXml', 'Store'];

    function PageMySuffixCreateController( $scope, Category, $uibModalInstance, MessageService, $stateParams, ProductXmlFileServes, ThemeClassify, ProductXml, Store) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function () {
            if (vm.name == null || vm.name == "") {
                MessageService.error("请输入文件夹名称");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            onSaveSuccess(vm.name);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();
