(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorMinimalismController', EditorMinimalismController);

    EditorMinimalismController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder','MessageNotification', 'Product', 'SalesOrder', 'ProductLine', 'Iphone', 'StoreConfig',
        'ThemeClassify', 'ProductXml', 'Cart', 'CustomerUploadAble','Customer','SkuSellLimits','MessageService','DateUtils', '$websocket'];

    function EditorMinimalismController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder,MessageNotification, Product, SalesOrder, ProductLine, Iphone, StoreConfig,
                                        ThemeClassify, ProductXml, Cart, CustomerUploadAble,Customer,SkuSellLimits,MessageService,DateUtils, $websocket) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        var vm = this;
        var isWx = false;
        var browser = CommonService.browserVersions();
        vm.hint = null;
        vm.limitQuantity = null;
        vm.warningQuantity = null;
        vm.isHint=false;
        if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
            var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //在微信中打开
                isWx = true;
            }else if(browser.versions.android){
                //是否在安卓浏览器打开
                isWx = false;
            }
            if (ua.match(/WeiBo/i) == "weibo") {
                //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) == "qq") {
                //在QQ空间打开
            }
            if (browser.versions.ios) {
                //是否在IOS浏览器打开
                isWx = false;
            }
        } else {
            //否则就是PC浏览器打开
        }

        //区别小程序、公众号
        // if (window.location.href.indexOf("?") > -1) {
        //     miniProgramUrl($localStorage, window.location.href);
        // }

        localStorage.removeItem('flag');
        if (window.location.href.indexOf("?") > -1) {
            localStorage.setItem('themeFlag', 'two');
            // localStorage.removeItem('guide-step');
            var r = window.location.href.split('?')[1];
            var typeFlag = r.split('&')[0].split('=')[1];
            var authorize = r.split('&')[1].split('=')[1];
            var productionType = r.split('&')[2].split('=')[1];
            var formLibrary = r.split('&')[3].split('=')[1];
            var orderOrigin = null;
            if (r.split('&').length>=5) {
                orderOrigin = r.split('&')[4].split('=')[1];
            }

            orderOrigin = decodeURIComponent(orderOrigin);
            var distinguish = decodeURIComponent(typeFlag);
            var tempAuthorization = decodeURIComponent(authorize);
            var authorization = tempAuthorization.slice(7, tempAuthorization.length);
            var showFlag = decodeURIComponent(productionType);
            var editLibrary = decodeURIComponent(formLibrary);
            if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake' || distinguish == 'toutiaoApp' || distinguish == 'alipayApp' || distinguish == 'jdApp') {
                localStorage.setItem('orderOrigin', orderOrigin);
                localStorage.setItem('distinguish', distinguish);
                $localStorage.authenticationToken = authorization;
                var workSave = JSON.parse(localStorage.getItem("work-save"));
                var pid = editLibrary.split(',')[0];
                var vid = editLibrary.split(',')[1];
                var xmlId = editLibrary.split(',')[2];
                if (workSave == null) {
                    var obj = {
                        pid: pid,
                        vid: vid,
                        xmlId:xmlId,
                        date: new Date().getTime()
                    };
                    localStorage.setItem("work-save", JSON.stringify(obj));
                    localStorage.removeItem("pages");
                    localStorage.removeItem("gallery");
                    localStorage.removeItem("galleryCopy");
                } else {
                    var currentDate = new Date().getTime();
                    var flag = (currentDate - workSave.date) > 3 * 3600 * 1000 ? true : false;
                    if (workSave.pid != pid || workSave.vid != vid || flag) {
                        localStorage.removeItem("pages");
                        localStorage.removeItem("gallery");
                        localStorage.removeItem("galleryCopy");
                        var obj = {
                            pid: pid,
                            vid: vid,
                            xmlId:xmlId,
                            date: new Date().getTime()
                        };
                        localStorage.setItem("work-save", JSON.stringify(obj));
                    }
                }
            }
            vm.target=getQueryVariable('target');
            localStorage.setItem('target', vm.target);
            vm.flag=getQueryVariable('flag');
            localStorage.setItem('flag', vm.flag);
        }

        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };
        /**
         * wechat api
         */
        if (wx) {
            var url = "";
            if (!!window.location.hash) {
                url = window.location.href.slice(0, -window.location.hash.length);
            }else{
                url =window.location.href;
            }
            WxJS.get({url: url}, function (config) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: config.appId, // 必填，公众号的唯一标识
                    timestamp: config.timestamp, // 必填，生成签名的时间戳
                    nonceStr: config.nonceStr, // 必填，生成签名的随机串
                    signature: config.signature,// 必填，签名，见附录1
                    jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(function () {

                    wx.hideMenuItems({
                        menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                    });
                });
                wx.error(function (res) {
                });
            }, function () {
            });
        }

        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.vid = $stateParams.vid;
        vm.salesOrderId = $stateParams.soid ? $stateParams.soid : null;
        vm.productLineId = $stateParams.plid ? $stateParams.plid : null;

        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });
        vm.photoArray = [];
        vm.photoSelectAll=[];
        vm.photoAll = [];
        vm.threeLoading = true;
        vm.threeLoadingText='加载中...';
        vm.threeError = false;
        vm.threeErrorText='';
        vm.page1 = true;
        vm.page2 = false;
        $scope.domains = Qinius.getDomain1();
        vm.platformSetting = PlatformWechatSetting.get();
        vm.store = Store.get({id: vm.storeId});
        vm.product = Product.get({id: vm.pid});
        vm.storeConfig = StoreConfig.get({id: vm.storeId, type: "SHOPPINGCART"});
        vm.size=10;
        if(vm.vid && vm.vid != 'undefined'){
            vm.productDocument = Convert.getItemDocument({productId: vm.pid, size: vm.size, itemValueId: vm.vid, page:0});
        }else{
            vm.productDocument = Convert.getProductDocument({productId: vm.pid, size: vm.size});
        }
        vm.customer = Customer.current();

        vm.isGuidePage = false;
        vm.isGuidePage1 = false;
        vm.guideVersions = '1.0.0';
        if(localStorage.getItem("guide-editor-minimalism") == vm.guideVersions){
            vm.isGuidePage1 = false;
        }else{
            vm.isGuidePage1 =  true;
            localStorage.setItem("guide-editor-minimalism", vm.guideVersions);
            vm.guidePage = 1;
        }
        //获取dpi
        vm.windowDpi = js_getDPI();
        vm.storeCart = StoreConfig.byStoreIdAndType({
            storeId: vm.storeId,
            type:'SHOPPINGCART'
        });
        vm.showTileMessage = true;
        vm.messageShow = true;
        vm.viewMessageTextShow = true;
        //消息列表
        getMessage();
        function getMessage() {
            vm.messageList = "";
            getMessageCustom(vm, DateUtils, MessageNotification, function (res) {
                res.forEach(function (item, index) {
                    vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                })
            })
            //平铺页广播
            vm.TileMessageList = "";
            MessageNotification.getAllByCondition({
                    items:[
                        {key:"pageType", op : "=", value:"Tile"},
                        {key:"storeId", op : "=", value:vm.storeId},
                        {key:"available", op : "=", value:"true"},
                        {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                }, function (res) {
                    res.forEach(function (item, index) {
                        vm.TileMessageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                    })
                }
            )

            // 预览页
            var monthStr = new Date().getMonth() + 1;
            var dayStr = new Date().getDate();
            var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
            MessageNotification.getAllByCondition2({
                items: [{key: "available", op: "=", value: "true"}, {
                    key: "validate",
                    op: ">=",
                    value: timestr
                }, {key: "pageType", op: "=", value: "View"}]
            }, function (res) {
                res.sort(compare('seq'))
                vm.viewMessageText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                angular.forEach(res, function (resp,index) {
                    vm.viewMessageText += (index+1)+ ':' + resp.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                })
                vm.viewMessageTextShow = res.length > 0 ? true : false;
            });
        }
        $q.all([$scope.domains.$promise, vm.store.$promise, vm.product.$promise, vm.storeConfig.$promise, vm.customer.$promise]).then(function () {
            if(!vm.userId){
                vm.userId = vm.customer.id
            }
            vm.minimumOrder = vm.product.minimumOrder;
            vm.quantity = vm.minimumOrder || 1;
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            //判断是ios or Android
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if(!isWx){
                $scope.agentIos = false;
            } else if (isAndroid == true && isiOS == false) {
                $scope.agentIos = true;
            } else if (isAndroid == false && isiOS == true) {
                $scope.agentIos = false;
            }
            vm.isIPhoneX = Iphone;

            if (vm.product.detailsType == 'Video') {
                $timeout(function(){
                    document.getElementById('source').setAttribute("src",  $scope.domain + vm.product.videoUrl);
                    document.getElementById( "video" ).load();
                },200);
            }
            if(vm.product.detailsType == 'Img'){
                vm.product.productPics1=[];
                vm.product.productPics.forEach(function(item){
                    if(!item.cancelled){
                        vm.product.productPics1.push(item)
                    }
                })
                vm.product.productPics1.sort(sortItem("sort"));
                $timeout(function () {
                    var swiper = new Swiper('.swiper-container', {
                        loop : true,
                        autoplay : 5000,
                        pagination : '.swiper-pagination1',
                    });
                    $scope.$digest();
                }, 1000)
            }
            vm.quantityPic = [];
            if(vm.store.orderWay=='orderThenEditor' && vm.salesOrderId){
                if(vm.vid){
                    vm.product.itemValues.forEach(function (itemValue) {
                        if(itemValue.id == vm.vid){
                            itemValue.productXmlLines.forEach(function (productXmlLine) {
                                if(!productXmlLine.cancelled){
                                    vm.quantityPic.push(productXmlLine.picQuantity || 0)
                                }
                            })
                        }
                    })
                }
                getSubmittable();
            }else{
                vm.product.itemValues.forEach(function (itemValue) {
                    if(!itemValue.cancelled){
                        itemValue.productXmlLines.forEach(function (productXmlLine) {
                            if(!productXmlLine.cancelled){
                                vm.quantityPic.push(productXmlLine.picQuantity || 0)
                            }
                        })
                    }
                })
            }
            vm.quantityPic.sort(function(a, b) {return a - b});
            vm.threeLoading = false;
            vm.threeLoadingText='';

            websocket();
        });
        vm.isSubmittable = false;
        function getSubmittable() {
            SalesOrder.submittable({id: vm.salesOrderId},function (res) {
                if(res.status == 200 && res.message == true){
                    vm.isSubmittable = true;
                }
            });
        }

        vm.itemValues = [];
        function documentInit(){
            vm.page1 = false;
            vm.page2 = true;
            vm.threeLoading = true;
            vm.threeLoadingText='载入图片和模板中...，请耐心等待不要退出~';
            vm.itemValues=[];
            $q.all([vm.productDocument.$promise]).then(function () {
                if(vm.productDocument.status == 200){
                    if(vm.store.orderWay == 'orderThenEditor'){
                        vm.itemValues = [];
                        vm.itemValues.push(vm.productDocument.message);
                    }else{
                        if(vm.productDocument.message.itemValues){
                            vm.itemValues = vm.productDocument.message.itemValues;
                        }else{
                            vm.itemValues = [];
                            vm.itemValues.push(vm.productDocument.message);
                        }
                    }
                    console.log(vm.photoSelectAll)
                    vm.itemValues.forEach(function (itemValue, itemValueIndex) {
                        itemValue.page = 0;
                        if(itemValue.productXmlLines && itemValue.productXmlLines.length>0){
                            itemValue.productXmlLines.forEach(function (productXmlLine, productXmlLineIndex) {
                                if(productXmlLine.document.pages.page && productXmlLine.document.pages.page.length > 0){
                                    (function (itemValueIndex, productXmlLineIndex) {
                                        documentPx(itemValueIndex, productXmlLineIndex, function (index, pindex) {
                                            if(index == vm.itemValues.length-1 && pindex == vm.itemValues[index].productXmlLines.length-1){
                                                vm.threeLoading = false;
                                                vm.threeLoadingText='';
                                                console.log(vm.itemValues)
                                            }
                                        })
                                    })(itemValueIndex, productXmlLineIndex)
                                }
                            })
                        }
                    })
                }else{
                    vm.threeLoading = false;
                    threeInfo(vm.productDocument.message || "获取商品模板失败")
                }
            })
        }
        $scope.more = function (itemValueIndex, itemValue) {
            vm.threeLoading = true;
            vm.threeLoadingText='加载中...';
            itemValue.page++
            Convert.getItemDocument({productId: vm.pid, size: vm.size, itemValueId: itemValue.id, page:itemValue.page},function (res) {
                res.message.productXmlLines.forEach(function (productXmlLine) {
                    itemValue.productXmlLines.push(productXmlLine)
                })
                itemValue.productXmlLines.forEach(function (productXmlLine, productXmlLineIndex) {
                    if(productXmlLine.document.pages.page && productXmlLine.document.pages.page.length > 0){
                        (function (itemValueIndex, productXmlLineIndex) {
                            documentPx(itemValueIndex, productXmlLineIndex)
                        })(itemValueIndex, productXmlLineIndex)
                    }
                })
                vm.threeLoading = false;
                vm.threeLoadingText='';
            });
        }
        function documentPx(itemValueIndex, productXmlLineIndex, callback) {
            var itemValue = vm.itemValues[itemValueIndex];
            var document = itemValue.productXmlLines[productXmlLineIndex].document;
            var pages = document.pages.page;
            if(callback){
                if(vm.itemValues.length> 1 && itemValue.productXmlLines.length > 2){
                    itemValue.isMore = false;
                }else{
                    itemValue.isMore = true;
                }
            }
            var _imgSum = 0;
            angular.forEach(pages, function (page, index) {
                page.seq = index;
                page.mediabox = {
                    width: transitionPx(page.mediabox.width),
                    height: transitionPx(page.mediabox.height),
                    x: transitionPx(page.mediabox.x),
                    y: transitionPx(page.mediabox.y)
                };
                page.trimbox = {
                    width: transitionPx(page.trimbox.width),
                    height: transitionPx(page.trimbox.height),
                    x: transitionPx(page.trimbox.x),
                    y: transitionPx(page.trimbox.y)
                };

                if (!page.editbox) {
                    page.editbox = angular.copy(page.trimbox);
                }else{
                    page.editbox = {
                        width: transitionPx(page.editbox.width),
                        height: transitionPx(page.editbox.height),
                        x: transitionPx(page.editbox.x),
                        y: transitionPx(page.editbox.y)
                    };
                }
                if(!page.editboxes || !page.editboxes.editbox){
                    page.editboxes = {
                        editbox: [page.editbox]
                    }
                }else{
                    page.editboxes.editbox.forEach(function (editbox, editboxIndex) {
                        page.editboxes.editbox[editboxIndex] = {
                            width: transitionPx(editbox.width),
                            height: transitionPx(editbox.height),
                            x: transitionPx(editbox.x),
                            y: transitionPx(editbox.y)
                        }
                    })
                }
                page.custom = {};
                if (page.scene) {
                    page.scene.geometry = {
                        width: transitionPx(page.scene.geometry.width),
                        height: transitionPx(page.scene.geometry.height),
                        x: transitionPx(page.scene.geometry.x),
                        y: transitionPx(page.scene.geometry.y)
                    };
                    page.scene.imagebox.geometry = {
                        width: transitionPx(page.scene.imagebox.geometry.width),
                        height: transitionPx(page.scene.imagebox.geometry.height),
                        x: transitionPx(page.scene.imagebox.geometry.x),
                        y: transitionPx(page.scene.imagebox.geometry.y)
                    };
                    if (page.scene.resource) {
                        getBackgroundSource($http, page.scene.resource, page.scene.geometry.width, page.scene.geometry.height, page.scene);
                    }
                    page.custom.width = page.scene.geometry.width;
                    page.custom.height = page.scene.geometry.height;
                } else {
                    page.custom.width = page.mediabox.width;
                    page.custom.height = page.mediabox.height;
                }
                ratioInfo(page)
                //条形码
                if (page.barcode) {
                    page.barcode.geometry = {
                        width: transitionPx(page.barcode.geometry.width),
                        height: transitionPx(page.barcode.geometry.height),
                        x: transitionPx(page.barcode.geometry.x),
                        y: transitionPx(page.barcode.geometry.y)
                    };
                }
                if (page.qrcode) {
                    page.qrcode.geometry = {
                        width: transitionPx(page.qrcode.geometry.width),
                        height: transitionPx(page.qrcode.geometry.height),
                        x: transitionPx(page.qrcode.geometry.x),
                        y: transitionPx(page.qrcode.geometry.y)
                    };
                }
                //背景
                if (page.background && page.background.resource &&page.background.resource.identifier) {
                    getBackgroundSource($http, page.background.resource, page.mediabox.width, page.mediabox.height, page.background);
                    page.background.offsetx = transitionPx(page.background.offsetx);
                    page.background.offsety = transitionPx(page.background.offsety);
                }
                if(!page.levels.level){
                    page.levels.level=[];
                }
                angular.forEach(page.levels.level, function (level, i) {
                    if (level.imageart) {
                        level.imageart.lock = true;
                        level.imageart.offsetx = transitionPx(level.imageart.offsetx);
                        level.imageart.offsety = transitionPx(level.imageart.offsety);
                        level.imageart.geometry = {
                            width: transitionPx(level.imageart.geometry.width),
                            height: transitionPx(level.imageart.geometry.height),
                            x: transitionPx(level.imageart.geometry.x),
                            y: transitionPx(level.imageart.geometry.y)
                        };
                        level.imageart.rotation.angle = -level.imageart.rotation.angle;
                        if (level.imageart.resource.identifier) {
                            getBackgroundSource($http, level.imageart.resource, level.imageart.geometry.width, level.imageart.geometry.height, level.imageart);
                        }
                    }
                    if (level.imagebox) {
                        level.imagebox.lock = true;
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.geometry = {
                            width: transitionPx(level.imagebox.geometry.width),
                            height: transitionPx(level.imagebox.geometry.height),
                            x: transitionPx(level.imagebox.geometry.x),
                            y: transitionPx(level.imagebox.geometry.y)
                        };
                        level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                        if (!vm.isEdit) {
                            level.imagebox.bokehtype = vm.productDocument.bokehtype || "None";
                            level.imagebox.bokehtypes = vm.productDocument.bokehtype || "None";
                            if (vm.photoSelectAll.length > _imgSum && !page.backup) {
                                if (!level.imagebox.image) {
                                    var _imagebox = imgFill($scope, vm, _imgSum);
                                    _imgSum++
                                    level.imagebox.image = {
                                        blurredrectangle: {
                                            width: 0,
                                            height: 0,
                                            x: 0,
                                            y: 0
                                        },
                                        resource: {
                                            identifier: _imagebox ? _imagebox.identifier : '',
                                            provider: _imagebox ? _imagebox.provider : 'qiniu'
                                        },
                                        resourceid:_imagebox ? _imagebox.id : '',
                                        filterResource: {
                                            identifier: '',
                                            provider: 'qiniu'
                                        },
                                        angle: 0,
                                        offsetx: 0,
                                        offsety: 0,
                                        imageInfo: _imagebox ? _imagebox.imageInfo : '',
                                        width: _imagebox ? _imagebox.width : '',
                                        height: _imagebox ? _imagebox.height : '',
                                        imgSize: _imagebox ? _imagebox.imgSize : '',
                                        index: _imagebox ? _imagebox.seq : '',
                                        parameter: _imagebox ? angular.fromJson(_imagebox.parameter) : [],
                                        scale: 1
                                    }
                                    if (level.imagebox.image.resource.identifier) {
                                        addImagebox($http, level.imagebox, function () {})
                                        // getBackgroundSource3(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtypes, $scope)
                                    }
                                }else if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                                    level.imagebox.image = {
                                        resource: {
                                            identifier: level.imagebox.image.resource.identifier,
                                            provider: level.imagebox.image.resource.provider
                                        },
                                        resourceid:level.imagebox.image.resourceid,
                                        filterResource: {
                                            identifier: '',
                                            provider: ''
                                        },
                                        imageInfo: '',
                                        width: '',
                                        height: '',
                                        imgSize: '',
                                        offsetx: transitionPx(level.imagebox.image.offsetx),
                                        offsety: transitionPx(level.imagebox.image.offsety),
                                        index: '',
                                        parameter: [],
                                        scale: level.imagebox.image.scale,
                                        angle: level.imagebox.image.angle,
                                    }
                                    getBackgroundSource1(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtypes, $scope)
                                }
                            } else {
                                if (level.imagebox.image && level.imagebox.image.resource.identifier) {
                                    level.imagebox.image = {
                                        resource: {
                                            identifier: level.imagebox.image.resource.identifier,
                                            provider: level.imagebox.image.resource.provider
                                        },
                                        resourceid:level.imagebox.image.resourceid,
                                        filterResource: {
                                            identifier: '',
                                            provider: ''
                                        },
                                        imageInfo: '',
                                        width: '',
                                        height: '',
                                        imgSize: '',
                                        offsetx: transitionPx(level.imagebox.image.offsetx),
                                        offsety: transitionPx(level.imagebox.image.offsety),
                                        index: '',
                                        parameter: [],
                                        scale: level.imagebox.image.scale,
                                        angle: level.imagebox.image.angle,
                                    }
                                    getBackgroundSource1(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtypes, $scope)
                                } else {
                                    level.imagebox.image = {
                                        resource: {
                                            identifier: '',
                                            provider: ''
                                        },
                                        resourceid:"",
                                        filterResource: {
                                            identifier: '',
                                            provider: ''
                                        },
                                        imageInfo: '',
                                        width: '',
                                        height: '',
                                        imgSize: '',
                                        offsetx: 0,
                                        offsety: 0,
                                        index: '',
                                        parameter: [],
                                        scale: 1,
                                        angle: 0,
                                    }
                                }
                            }
                        }
                        if (vm.isEdit) {
                            level.imagebox.bokehtype = level.imagebox.bokehtype;
                            level.imagebox.bokehtypes = level.imagebox.bokehtype;
                            if (level.imagebox.image) {
                                level.imagebox.image.offsetx = transitionPx(level.imagebox.image.offsetx);
                                level.imagebox.image.offsety = transitionPx(level.imagebox.image.offsety);
                                if (level.imagebox.image.resource.identifier) {
                                    getBackgroundSource1(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtypes, $scope)
                                }
                            }
                            if (level.imagebox.blurredrectangle) {
                                level.imagebox.blurredrectangle = {
                                    width: transitionPx(level.imagebox.blurredrectangle.width),
                                    height: transitionPx(level.imagebox.blurredrectangle.height),
                                    x: transitionPx(level.imagebox.blurredrectangle.x),
                                    y: transitionPx(level.imagebox.blurredrectangle.y)
                                };
                            }
                        }
                    }
                    if (level.textbox) {
                        level.textbox.lock = true;
                        level.textbox.geometry = {
                            width: transitionPx(level.textbox.geometry.width),
                            height: transitionPx(level.textbox.geometry.height),
                            x: transitionPx(level.textbox.geometry.x),
                            y: transitionPx(level.textbox.geometry.y)
                        };
                        level.textbox.rotation.angle = -level.textbox.rotation.angle;
                        if (level.textbox.fontuuid) {
                            //下载字体
                            getFontCSS(FontManagement, level.textbox, $scope)
                            FontSupport2(FontManagement, level.textbox)
                        }
                        if (level.textbox.style.bold && level.textbox.style.italic) {
                            level.textbox.styles = 'BOLD_ITALIC';
                        } else if (level.textbox.style.bold) {
                            level.textbox.styles = 'BOLD';
                        } else if (level.textbox.style.italic) {
                            level.textbox.styles = 'ITALIC';
                        } else {
                            level.textbox.styles = 'NORMAL';
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = angular.copy(level.textdocument);
                        level.textdocument = {
                            background: textdocument.background,
                            geometry: {
                                width: transitionPx(textdocument.geometry.width),
                                height: transitionPx(textdocument.geometry.height),
                                x: transitionPx(textdocument.geometry.x),
                                y: transitionPx(textdocument.geometry.y)
                            },
                            rotation: {
                                angle: -textdocument.rotation.angle!= null? -textdocument.rotation.angle : 0
                            },
                            current: 'textdocument',
                            levels: angular.copy(textdocument.levels),
                            lock: true,
                            transparency: textdocument.transparency,
                            movable: textdocument.movable,
                            useradded: textdocument.useradded,
                            editable: textdocument.editable,
                            scale: textdocument.geometry.width / page.mediabox.width
                        }

                        var backg = textdocument.background;
                        var pageW = textdocument.geometry.width,
                            pageH = textdocument.geometry.height;
                        if (backg.resource.identifier) {
                            getBackgroundSource($http, backg.resource, pageW, pageH, backg);
                            backg.offsetx = transitionPx(backg.offsetx);
                            backg.offsety = transitionPx(backg.offsety);
                        }

                        angular.forEach(textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var art = lev.imageart;
                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                    level.textdocument.levels.level[l].imageart = {
                                        geometry: {
                                            width: transitionPx(art.geometry.width),
                                            height: transitionPx(art.geometry.height),
                                            x: transitionPx(art.geometry.x),
                                            y: transitionPx(art.geometry.y)
                                        },
                                        rotation: angular.copy(art.rotation),
                                        type: art.type,
                                        resource: {
                                            identifier: art.resource.identifier,
                                            provider: art.resource.provider,
                                            width: data.width,
                                            height: data.height
                                        },
                                        scale: art.scale,
                                        offsetx: transitionPx(art.offsetx),
                                        offsety: transitionPx(art.offsety),
                                        current: 'imageart',
                                        lock: false,
                                        transparency: art.transparency,
                                        onlyshow: art.onlyshow,
                                        movable: art.movable,
                                        useradded: art.useradded,
                                        editable: art.editable,
                                    }
                                }).error(function (error) {
                                });
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var text = lev.textbox;
                                level.textdocument.levels.level[l].textbox = {
                                    geometry: {
                                        width: transitionPx(text.geometry.width),
                                        height: transitionPx(text.geometry.height),
                                        x: transitionPx(text.geometry.x),
                                        y: transitionPx(text.geometry.y)
                                    },
                                    identifier: text.identifier,
                                    rotation: angular.copy(text.rotation),
                                    angle: angular.copy(text.angle),
                                    status: angular.copy(text.status),
                                    style: angular.copy(text.style),
                                    type: angular.copy(text.type),
                                    text: angular.copy(text.text),
                                    fontStyle: [{
                                        style: '',
                                        path: text.identifier
                                    }],
                                    current: 'textbox',
                                    lock: true,
                                    transparency: text.transparency,
                                    fontuuid: text.fontuuid,
                                    version: text.version
                                }
                                getFontCSS(FontManagement, text, $scope)
                            }
                        })
                    }
                })
            })
            pageWarnExamine(pages, vm.imgSize, vm.imgDpi)
            _imgSum = 0;
            if(callback){
                callback(itemValueIndex, productXmlLineIndex);
            }
        }
        vm.isEditboxMagnify = false;
        vm.ratioInfo = ratioInfo;
        function ratioInfo(item) {
            vm.windowWidth = document.body.clientWidth;
            var ratio = item.custom.width / item.custom.height;


            item.windowWidth = ((vm.windowWidth-75) / 2);
            item.windowHeight = 220;
            var ratio1 = item.windowWidth / item.windowHeight;
            if (ratio >= ratio1) {
                item.custom.ratio2 = item.windowWidth / item.custom.width;
            } else {
                item.custom.ratio2 = item.windowWidth / item.custom.height;
            }


            vm.windowHeight = document.body.clientHeight * 0.91 - 175;
            if(vm.mapDepot || vm.isMaterial || (vm.istemplate && vm.page4)){
                vm.windowHeight = document.body.clientHeight - 280 - (document.body.clientHeight*0.02);
            }
            if(vm.isEditboxMagnify){
                var ratio2 = item.mediabox.width / item.mediabox.height;
                var ratio3 = vm.windowWidth / vm.windowHeight;
                if (ratio2 >= ratio3) {
                    item.custom.ratio1 = (vm.windowWidth - 30) / item.mediabox.width;
                } else {
                    item.custom.ratio1 = vm.windowHeight / item.mediabox.height;
                }
            }else{
                var ratio2 = item.custom.width / item.custom.height;
                var ratio3 = vm.windowWidth / vm.windowHeight;
                if (ratio2 >= ratio3) {
                    item.custom.ratio1 = (vm.windowWidth - 30) / item.custom.width;
                } else {
                    item.custom.ratio1 = vm.windowHeight / item.custom.height;
                }
            }
            item.custom.thumbnailScale = 300 / item.custom.width;
        }

        function threeInfo(item){
            vm.threeError = true;
            vm.threeErrorText=item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText="";
            },1500)
        }
        /*************************************************************************  page1  *************************************************************************/
        $scope.page1Upload = function(){
            uploadVerify(vm.platformSetting, CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                if($scope.agentIos){
                    $rootScope.$broadcast('clickAndroidUpload',true);
                }else {
                    $timeout(function () {
                        $("#uploadImageMutiple-page0").trigger("click");
                    })
                }
            })
        }
        vm.uploadPhotoItem=[];
        $scope.$on('uoloadFinished', function(event, args) {
            if(args){
                updatePic();
                if(vm.page1){
                    vm.photoSelectAll.reverse();
                    documentInit()
                }
            }
        });
        //获取图片库
        function updatePic() {
            vm.maxId = null;
            if (vm.folderOne && vm.folderOne.id) {
                // $scope.showFolderContent(vm.folderOne);
                vm.photoAll = [];
                vm.galleryPage = [];
                loadAll(vm.folderOne.id);
            } else {
                vm.photoAll=[];
                vm.galleryPage=[];
                vm.indexNum = 0;
                vm.photoCompleted = false;
                // vm.isShowGallery = true;
                loadAll('isNull');
            }
        }
        vm.itemsPerPage = 40;
        function loadAll(folderId) {
            var items = [];
            if (folderId == 'isNull') {
                items.push({key: "folderId", op: "isNull", value: 1})
            }else{
                items.push({key: "folderId", op: "=",value: folderId});
            }
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            Gallery.getPage({
                items: items,
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: sorts()
            }, onSuccess, onError);
        }

        function sorts() {
            return ["id,desc"];
        }

        function onSuccess(res) {
            if (vm.maxId == null && res.length > 0) {
                vm.maxId = res[0].id;
            }
            for (var i = 0; i < res.length; i++) {
                if (!res[i].cancelled) {
                    vm.photoAll.push(res[i])
                    for(var j=0; j<vm.photoSelectAll.length; j++){
                        if(vm.photoSelectAll[j].id == res[i].id){
                            vm.photoSelectAll[j] = res[i];
                        }
                    }
                }
            }
            if (res.length < 10) {
                vm.photoCompleted = true;
            }
            getPagePhoto();
        }

        function onError(data) {
            MessageService.hint("网络错误，请切换网络重试");
        }
        function getPagePhoto() {
            var timeList=[];
            vm.photoArray = [];
            for(var i=0; i<vm.photoAll.length; i++){
                var photoCreatedDate = new Date(vm.photoAll[i].createdDate);
                if (checkExist(timeList, photoCreatedDate)) {
                    timeList.push(photoCreatedDate);
                }
            }
            //将相同日期下的照片整合
            for (var m = 0; m < timeList.length; m++) {
                var tempObj = {};
                var tempItems = [];
                for (var j = 0; j < vm.photoAll.length; j++) {
                    var createdDate = new Date(vm.photoAll[j].createdDate);
                    if (timeList[m].getFullYear() == createdDate.getFullYear() &&
                        timeList[m].getMonth() == createdDate.getMonth() &&
                        timeList[m].getDate() == createdDate.getDate()) {
                        tempItems.push(vm.photoAll[j])
                    }
                }
                var time = timeList[m].getFullYear() + '年' + (timeList[m].getMonth() + 1) + '月' + timeList[m].getDate() + '日';
                tempObj = {"createDate": time, "galleries": tempItems};
                vm.photoArray.push(tempObj);
            }
            getPagePhotoSelected();
        }
        function getPagePhotoSelected() {
            for(var i=0; i<vm.photoArray.length; i++){
                for(var j=0; j<vm.photoArray[i].galleries.length; j++){
                    vm.photoArray[i].galleries[j].selected = false;
                    for(var k=0; k<vm.photoSelectAll.length; k++){
                        if(vm.photoArray[i].galleries[j].id == vm.photoSelectAll[k].id){
                            vm.photoArray[i].galleries[j].selected = true;
                        }
                    }
                }
            }
        }
        // 判断是否重复
        function checkExist(list, obj) {
            if (list == null || list.length == 0) {
                return true;
            }
            for (var i = 0; i < list.length; i++) {
                if (list[i].getFullYear() == obj.getFullYear() &&
                    list[i].getMonth() == obj.getMonth() &&
                    list[i].getDate() == obj.getDate()) {
                    return false;
                }
            }
            return true;
        }


        /*************************************************************************  page4  *************************************************************************/
        $scope.editboxMagnify = function () {
            vm.isEditboxMagnify = !vm.isEditboxMagnify;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        vm.itemValue = {};
        vm.productXmlLine = {};
        $scope.productXmlLineClick = function (itemValue, productXmlLine) {
            vm.page1 = false;
            vm.page2 = false;
            vm.page4 = true;
            vm.itemValue = itemValue;
            vm.productXmlLine = productXmlLine;
            $scope.template = vm.productXmlLine.document;
            vm.pageData = angular.copy($scope.template.pages.page);
            vm.pageSum = $scope.template.pages.page.length - 1;
            vm.pageIndex = 0;
            vm.counts = 0;
            vm.isGuidePage = true;
            angular.forEach(vm.pageData, function (page, i) {
                angular.forEach(page.levels.level, function (level) {
                    if (level.imagebox && level.imagebox.geometry) {
                        vm.counts++;
                    }
                })
            });
        }
        $scope.previousPage = function () {
            vm.pageIndex--;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        $scope.nextPage = function () {
            vm.pageIndex++;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        $scope.goPageContent = function (index) {
            vm.isDocumentTextDetection=false;
            vm.pageIndex = index;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }

        /*******************************************点击模板*************************************************************************/
        $scope.templateShow = function () {
            vm.istemplate = true;
            productInit();
            // ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.templateHide = function () {
            vm.istemplate = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }

        function productInit() {
            vm.tempList = [];
            vm.itemValues.forEach(function (itemValue) {
                itemValue.productXmlLines.forEach(function (productXmlLine) {
                    productXmlLine.itemValue = itemValue;
                    vm.tempList.push(productXmlLine)
                })
            })
        }
        vm.imgs = [];
        var templateSwiper = null;
        $scope.templateCb = function (temp) {
            if (temp.id == vm.productXmlLine.id) {
                vm.threeIsModal1 = true;
                vm.threeModalText1 = "已在当前模板，请选择其他模板！";
                return
            }
            vm.xmlShowPic = true;
            $scope.chooseTemp = temp;
            ProductXml.findOne({id: temp.productXmlId}, function (data) {
                if (data.documentPics && data.documentPics.length > 0) {
                    data.documentPics.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
                vm.imgs = data.documentPics;
                if(vm.imgs.length > 0){
                    $timeout(function () {
                        templateSwiperInit();
                        templateSwiper.init();//现在才初始化
                    },200)
                }
            }, function () {

            })
        }
        function templateSwiperInit() {
            templateSwiper = new Swiper ('.swiper-template-view-content', {
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            })
        }

        var inits = 0, num = 0;
        $scope.previewClose = function () {
            vm.xmlShowPic = false;
        };
        $scope.changeAllTemp = function () {
            vm.threeIsModal = true;
            vm.threeModalText = "更换模板后，除照片自动填充其余自动清除，是否更换？";
        }

        function templateReplace() {
            // $state.go("product.chooseThree", {
            //     storeId: vm.storeId,
            //     pid: vm.pid,
            //     vid: vm.vid,
            //     xmlId: $scope.chooseTemp.id
            // },{reload: true});
            window.location.href = vm.platformSetting.editorNotifyUrl+'/#/'+vm.storeId+'/'+vm.pid+'/'+vm.vid+'/'+$scope.chooseTemp.id+'/'+vm.salesOrderId+'/'+vm.productLineId+'/chooseThree';
            window.location.reload();
            localStorage.setItem("photoSelectAll", JSON.stringify(vm.photoSelectAll));
        }

        /*******************************************点击图片库*************************************************************************/
        vm.mapDepot = false;
        $scope.photoGalleryShow = function () {
            vm.mapDepot = true;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.photoGalleryHide = function () {
            vm.mapDepot = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $('#photoGallery').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.photoCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++
                    loadAll("isNull")
                }
            }
        })


        /*******************************************素材处理*************************************************************************/
        $scope.makeBody = function () {
            vm.levelIndex = null;
            vm.levelType = null;
            vm.isImageboxSelectItem = false;
            vm.textStyleShow = false;
            vm.textboxSelect = false;
            vm.filterShow = false;
            if(vm.mapDepot || vm.isMaterial || vm.istemplate){
                vm.istemplate = false;
                vm.isMaterial = false;
                vm.mapDepot = false;
                ratioInfo(vm.pageData[vm.pageIndex]);
            }else {
                vm.istemplate = false;
                vm.isMaterial = false;
                vm.mapDepot = false;
            }
        }
        vm.levelDome = function(item){
            vm.isImageboxSelectItem = false;
            vm.textStyleShow = false;
            vm.textboxSelect = false;
            vm.filterShow = false;
            vm.mattingShow = false;
            vm.mapDepot = false;
            vm.isAddImagebox = false;
            vm.istemplate = false;
            vm.isMaterial = false;
            if(item){
                vm[item] = true;
            }
        }

        vm.isMaterial = false;
        $scope.materialShow = function () {
            vm.isMaterial = true;
            vm.materialPage = 0;
            vm.materialSum = 20;
            vm.materialData = [];
            //分类
            ThemeClassify.getAllByCondition4({type: 'CLIPART', storeId: vm.storeId}, function (res) {
                vm.classify = [];
                if($scope.template.cliparts && $scope.template.cliparts.clipart && $scope.template.cliparts.clipart.length>0){
                    vm.classify = [{
                        id: "",
                        name: "推荐",
                        seq: 0,
                        type: "CLIPART",
                        used: true
                    }]
                }
                res.message.forEach(function (item) {
                    vm.classify.push(item)
                })
                vm.classifyW = res.message.length * 86;
                initMaterial(vm.classify[0].id)
            });
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.classifyCb = function (item) {
            vm.materialPage = 0;
            vm.materialData = [];
            initMaterial(item.id)
        }
        //获取素材
        vm.isMaterial1 = false;

        function initMaterial(id) {
            vm.classifyId = id;
            if(id){
                ClipArt.byCondition3({
                    items: [
                        {key: "themeClassify.id", op: "=", value: id}],
                    page: vm.materialPage,
                    size: vm.materialSum
                }, function (res) {
                    angular.forEach(res, function (resp) {
                        var arr = {
                            name: resp.name,
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: $scope.domain + resp.identifier,
                                provider: resp.provider
                            }
                        }
                        vm.materialData.push(arr);
                    })
                    vm.materialW = vm.materialData.length * 90 + 20;
                    if (res.length < vm.materialSum) {
                        vm.isMaterial1 = true;
                    }else {
                        vm.isMaterial1 = false;
                    }
                });
            }else{
                $scope.template.cliparts.clipart.forEach(function (clipart) {
                    var arr = {
                        name: "",
                        identifier: clipart.resource.identifier,
                        provider: "",
                        themeClassifyName: "推荐",
                        themeClassifyId: "",
                        resource: clipart.resource
                    }
                    vm.materialData.push(arr);
                })
                vm.materialW = vm.materialData.length * 90 + 20;
                vm.isMaterial1 = true;
            }
        }
        $('#materialView').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.isMaterial1){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.materialPage++;
                    initMaterial(vm.classifyId)
                }
            }
        })
        $scope.materialHide = function () {
            vm.isMaterial = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }

        //添加素材
        vm.repeatAddNumber = 0;
        $scope.addImageart = function (item){
            vm.threeLoading = true;
            vm.threeLoadingText='加载中...';
            imageartAdd($http, item, vm.pageData[vm.pageIndex].trimbox, function(imageart){
                if(vm.repeatAddNumber>4){
                    vm.repeatAddNumber = 0;
                }
                var _num = 10*vm.repeatAddNumber;
                imageart.geometry.x += _num/vm.pageData[vm.pageIndex].custom.ratio1;
                imageart.geometry.y += _num/vm.pageData[vm.pageIndex].custom.ratio1;
                var _level = {
                    seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                    imageart: imageart
                }
                vm.pageData[vm.pageIndex].levels.level.push(_level);
                vm.threeLoading = false;
                vm.repeatAddNumber++;
            }, vm.pageData[vm.pageIndex].custom.ratio1)
        }
        vm.isDocumentTextDetection = false;
        $scope.savePage4 = function () {
            var _documentTextDetection = documentTextDetection1(vm.pageData);
            if(!_documentTextDetection.pass){
                vm.isDocumentTextDetection = true;
                vm.documentTextDetection = _documentTextDetection.pages;
                return;
            }
            $scope.makeBody();
            if(vm.isEditboxMagnify){
                $scope.editboxMagnify();
            }
            vm.page4 = false;
            vm.page3 = true;
            vm.isImageboxSelectItem = false;
            vm.isMaterial = false;
            vm.mapDepot = false;
            vm.textboxSelect = false;
            vm.textStyleShow = false;
            vm.page5 = false;
            vm.filterShow = false;
            $scope.template.pages.page = vm.pageData;
            vm.productXmlLine.document = $scope.template;
            vm.threeLoading = true;
            vm.threeLoadingText = '加载中...';
            ProductXml.get({id: vm.productXmlLine.productXmlId}, function (productXml) {
                vm.threeLoading = false;
                vm.threeLoadingText = '';
                if(productXml.modelType && productXml.modelType === 'THREE'){
                    vm.modelType = 'THREE';
                    if(!productXml.modelPath){
                        MessageService.error("暂无3D模型");
                        vm.threeView = false;
                        return
                    }
                    vm.threeView = true;
                    vm.threeTemplate = vm.productXmlLine.document;
                    vm.threeProductXmlData = productXml;
                }else {
                    vm.modelType = 'TWO';
                }
            })
        }
        /*******************************************文字处理*************************************************************************/
        $scope.colorList = ['#ff001e', '#ff5c00', '#ff9700', '#61734d', '#838c97', '#8d4ac8', '#b8e986', '#7e6565', '#ffffff', '#f1e7d9', '#b5ce99', '#82c281', '#dac2ef', '#000', '#797979', '#7c330a', '#eec78d', '#444444', '#589ceb', '#4a7bc8', '#ddabab'];
        $scope.selectedColor = $scope.colorList[0];

        vm.textboxSelect = false;
        vm.textStyleTab = [
            {name: '字体', id: 'textStyleTabFont'},
            {name: '大小', id: 'textStyleTabSize'},
            {name: '样式', id: 'textStyleTabType'},
            {name: '颜色', id: 'textStyleTabColor'},
        ];
        vm.textStyleItem = 'textStyleTabFont';
        //添加文字
        $scope.fontShow = function () {
            vm.page5 = true;
            vm.fontTab = 'fontColor';
            $scope.selectedColor = $scope.colorList[0];
            var _size = Math.floor(vm.pageData[vm.pageIndex].trimbox.height/8);

            // var _level ={
            //     seq: vm.pageData[vm.pageIndex].levels.level.length+1,
            //     textbox: textbox
            // }
            vm.fontData = {
                seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                textbox: {
                    type: 'add',
                    geometry: {
                        x: '0',
                        y: '0',
                        width: 100,
                        height: 20
                    },
                    rotation: {
                        angle: '0'
                    },
                    text: '',
                    style: {
                        font: "",
                        size: _size || 20,
                        color: $scope.selectedColor,
                        bold: false,
                        italic: false,
                        lineheight: _size || 20,
                        letterspacing: 0,
                        align: 'left',
                        writingmode:'horizontal-tb'
                    },
                    fontuuid: vm.fontList[0].uuid,
                    identifier: "",
                    editable: true,
                    movable: true,
                    lock: false,
                    version: '2.0',
                    transparency: 1,
                    useradded: true
                }
            }
            var _fontStyle = [];
            for (var j = 0; j < vm.fontList[0].fontStyles.length; j++) {
                _fontStyle.push(vm.fontList[0].fontStyles[j].style)
            }
            if (_fontStyle.indexOf('NORMAL') != '-1') {
                vm.fontData.textbox.style.bold = false;
                vm.fontData.textbox.style.italic = false;
            } else {
                if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    vm.fontData.textbox.style.bold = true;
                    vm.fontData.textbox.style.italic = true;
                } else {
                    if (_fontStyle.indexOf('ITALIC') != '-1') {
                        vm.fontData.textbox.style.italic = true;
                        vm.fontData.textbox.style.bold = false;
                    }
                    if (_fontStyle.indexOf('BOLD') != '-1') {
                        vm.fontData.textbox.style.bold = true;
                        vm.fontData.textbox.style.italic = false;
                    }
                }
            }
            getFontCSS(FontManagement, vm.fontData.textbox, $scope)
            vm.text = '';
            $timeout(function () {
                $('textarea.three-font-body-input').focus()
            },100);
        };
        //切换字体
        $scope.updateTextFont = function (data) {
            if (data == undefined) {
                threeInfo("文字添加失败，请上传字体!");
                return
            }
            if (!data.enable) {
                threeInfo("当前字体不可用，请更换字体");
                return
            }
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            vm.fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == data.uuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold = false;
            text.style.italic = false;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = false;
                text.style.italic = false;
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    text.style.bold = true;
                    text.style.italic = true;
                } else {
                    if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                        text.style.italic = true;
                    }
                    if (vm.fontStyle.indexOf('BOLD') != '-1') {
                        text.style.bold = true;
                    }
                }
            }
            text.style.font = data.family;
            text.fontuuid = data.uuid;
            text.identifier = $scope.domain + data.fontStyles[0].identifier;
            text.loading = true;
            FontSupport2(FontManagement, text, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    text.text = msg.passed;
                    if (msg.success && !msg.supported) {
                        setTimeout(function(){
                            $(".sweet-alert").addClass('whiteBg');
                        })
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>温馨提示</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>特殊字符在作品文件中不能正常印刷<br>请您及时修改，当前字体不支持字符<br><span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                            html: true,
                            confirmButtonText: "返回修改"
                        },function(){
                            setTimeout(function(){
                                $(".sweet-alert").removeClass('whiteBg');
                            },200)
                        });
                        text.loading = false;
                    } else {
                        getFontCSS(FontManagement, text, $scope);
                        $timeout(function () {
                            sizeHeight();
                            fontStyle(data.uuid);
                        })
                    }
                }
            })
        };

        function fontStyle(fontuuid) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            vm.fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == fontuuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold1 = false;
            text.style.italic1 = false;
            if (vm.fontStyle.indexOf('NORMAL') == '-1') {
                text.style.normal = false;
            } else {
                text.style.normal = true;
            }
            if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                text.style.bold1 = true;
                text.style.italic1 = true;
            } else {
                if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                    text.style.italic1 = true;
                }
                if (vm.fontStyle.indexOf('BOLD') != '-1') {
                    text.style.bold1 = true;
                }
            }
        }

        window.densityInput = function (e) {
            var val = $('#rangeChange').val();
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size = parseInt(val);
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.lineheight = parseInt(val);
            }

            var currentPage = vm.pageData[vm.pageIndex].levels.level;
            var item = currentPage[vm.levelIndex].textbox;

            if (item.geometry.width < parseInt(item.style.size)) {
                item.geometry.width = (Number(parseInt(item.style.size)) + Number(5));
            }
            if (item.geometry.width > vm.pageData[vm.pageIndex].trimbox.width) {
                item.geometry.width = vm.pageData[vm.pageIndex].trimbox.width;
                // item.geometry.x = 0;
            }
            sizeHeight();
            $scope.$digest();
        }
        window.textStyleTransparency = function (e) {
            var val = $('#textStyleTransparency').val();
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.transparency = parseInt(val) / 100;
                vm.transparency = parseInt(val);
            }
        }
        //斜体
        $scope.operationItalic = function () {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.italic = !text.style.italic;
                $timeout(function () {
                    sizeHeight();
                }, 200)
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('BOLD') != '-1') {
                    if (text.style.bold) {
                        text.style.italic = !text.style.italic;
                        $timeout(function () {
                            sizeHeight();
                        }, 200)
                    }
                }
            }
        }
        //粗体
        $scope.operationBold = function () {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = !text.style.bold;
                $timeout(function () {
                    sizeHeight();
                }, 200)
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('ITALIC') != '-1') {
                    if (text.style.italic) {
                        text.style.bold = !text.style.bold;
                        $timeout(function () {
                            sizeHeight();
                        }, 200)
                    }
                }
            }
        }
        $scope.operationBarDir = function (item) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            text.style.align = item;
        };
        $scope.fontColorCb = function (item) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            text.style.color = item;
        }
        $scope.textLayout = function(item){
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            var text1 = angular.copy(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox);
            var _writingmode = angular.copy(text.style.writingmode);
            text.style.writingmode = item;
            $timeout(function () {
                if(writingmodeInit(_writingmode) && writingmodeInit(text.style.writingmode)){
                }else{
                    text.geometry.width = text1.geometry.height;
                    text.geometry.height = text1.geometry.width;
                }
            },200)
        };
        //获取所有字体
        vm.fontList = [];
        getAllFont();

        function getAllFont() {
            FontManagement.getAll2({family: ""}, function (res) {
                vm.fontList = res;
            });
        }

        //判断是ios or Android
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        var textFlag = true;
        var inputEle = document.getElementById('three-font-body-input');

        // 兼容安卓文字输入（首行空格不显示bug）
        $scope.changeText = function (e) {
            if (isAndroid == true && isiOS == false) {
                fn(e);
                var targ = e.currentTarget ? e.currentTarget : e.srcElement;
                vm.text = vm.fontData.textbox.text = targ.value;
            }
        }

        /**
         * 兼容iOS文字输入
         */
        inputEle.addEventListener('compositionstart', function () {
            textFlag = false;
        });
        inputEle.addEventListener('compositionend', function (event) {
            textFlag = true;
            // compositionend 事件发生在 input 之后，故此需要此处调用 input 中逻辑
            if (isAndroid == false && isiOS == true) {
                vm.text = event.target.value;
            }
        });
        inputEle.addEventListener('input', function (event) {
            // 忽略一切非直接输入，不做逻辑处理
            if (textFlag) {
                if (isAndroid == false && isiOS == true) {
                    vm.text = event.target.value;
                }
            };
        });

        $scope.savePage5 = function () {
            if (!vm.fontData.textbox.text) {
                vm.page4 = true;
                vm.page5 = false;
                return
            }
            if(vm.text){
                vm.fontData.textbox.text =  vm.text;
            }
            FontSupport2(FontManagement, vm.fontData.textbox, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    // vm.fontData.textbox.text = msg.passed;
                    if (msg.success && !msg.supported) {
                        setTimeout(function(){
                            $(".sweet-alert").addClass('whiteBg');
                        })
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>温馨提示</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>特殊字符在作品文件中不能正常印刷<br>请您及时修改，当前字体不支持字符<br><span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                            html: true,
                            confirmButtonText: "返回修改"
                        },function(){
                            setTimeout(function(){
                                $(".sweet-alert").removeClass('whiteBg');
                            },200)
                        });
                    } else {
                        vm.page4 = true;
                        vm.page5 = false;
                        vm.fontData.textbox.style.color = $scope.selectedColor;
                        // vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                        // vm.fontData.textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                        // vm.fontData.textbox.geometry.width = Number(($("#c-xml-page-material-fontId").width() * ( vm.windowDpi / 72) + Number(10)) / vm.pageData[vm.pageIndex].custom.ratio1 * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1));
                        // vm.fontData.textbox.geometry.height = Number(($("#c-xml-page-material-fontId").height() * ( vm.windowDpi / 72) + Number(5)) / vm.pageData[vm.pageIndex].custom.ratio1) * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1);
                        if (vm.fontData.textbox.type == 'add') {
                            vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                            vm.fontData.textbox.geometry.x = (vm.pageData[vm.pageIndex].trimbox.width - vm.fontData.textbox.geometry.width) / 2 + vm.pageData[vm.pageIndex].trimbox.x;
                            vm.fontData.textbox.geometry.y = (vm.pageData[vm.pageIndex].trimbox.height - vm.fontData.textbox.geometry.height) / 2 + vm.pageData[vm.pageIndex].trimbox.y;
                            vm.pageData[vm.pageIndex].levels.level.push(vm.fontData)
                            vm.levelIndex = vm.pageData[vm.pageIndex].levels.level.length-1;
                        }
                        if (vm.fontData.textbox.type == 'edit') {
                            // if (vm.fontData.textbox.geometry.width >= vm.pageData[vm.pageIndex].trimbox.width) {
                            //     // vm.fontData.textbox.geometry.width = vm.pageData[vm.pageIndex].trimbox.width;
                            //     vm.fontData.textbox.geometry.x = vm.pageData[vm.pageIndex].trimbox.x;
                            // }
                            // if (vm.fontData.textbox.geometry.height >= vm.pageData[vm.pageIndex].trimbox.height) {
                            //     // vm.fontData.textbox.geometry.height = vm.pageData[vm.pageIndex].trimbox.height
                            //     vm.fontData.textbox.geometry.y = vm.pageData[vm.pageIndex].trimbox.y;
                            // }
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] = vm.fontData;
                        }
                        sizeHeight1()
                    }
                }
            })
        };
        //删除文字
        $scope.deleteTextbox = function (e) {
            fn(e)
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level.splice(vm.levelIndex, 1);
                vm.levelIndex = null;
                vm.levelType = null;
                $scope.makeBody();
            }
        }
        $scope.editTextbox = function (e, index, item) {
            fn(e)
            vm.page5 = true;
            vm.fontData = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex];
            vm.fontData.textbox.type = 'edit';
            $scope.selectedColor = vm.fontData.textbox.style.color;
            vm.fontTab = 'fontColor';
            vm.text = vm.fontData.textbox.text;
            $timeout(function () {
                $('textarea.three-font-body-input').focus()
            },100);
        }
        //文字样式
        $scope.textStyleTextbox = function () {
            vm.textStyleShow = true;
        }

        function sizeHeight() {
            sizeHeight1();
            // $timeout(function () {
            //     var _h = Number($('.pitch1').find('.c-xml-page-textboxe-text span').height()) + 5;
            //     vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
            //     $scope.$digest();
            // })
        }
        vm.sizeHeight1 = sizeHeight1;
        function sizeHeight1() {
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                if(!writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
                }
                if(writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width = _w;
                }
            })
        }
        //字体样式
        $scope.textStyleTabCb = function (item) {
            vm.textStyleItem = item.id;
        }
        $scope.textStyleHide = function () {
            vm.textStyleShow = false;
        }
        $scope.writingmodeInit = function (item) {
            if(item == 'vertical-lr' || item == 'vertical-rl'){
                return true
            }else{
                return false
            }
        }
        /*******************************************照片框处理*************************************************************************/
        vm.isImageboxSelectItem = false;

        //展开
        vm.isModeOut = false;
        $scope.modeIn = function () {
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if (_imagebox.bokehtype != 'None') {
                if (_imagebox.image && _imagebox.image.resource.identifier) {
                    imageboxModeIn(_imagebox, function (imagebox) {
                        vm.isModeOut = true;
                    })
                }
            }
        };
        //缩放
        $scope.modeOut = function () {
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if (_imagebox.bokehtype == 'None') {
                if (_imagebox.image && _imagebox.image.resource.identifier) {
                    imageboxModeOut(_imagebox, function (imagebox) {
                        vm.isModeOut = false;
                    })
                }
            }
        };

        $scope.cropImageboxMassive = function () {
            imageboxMassive(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, vm.pageData[vm.pageIndex]);
        }
        $scope.cropImageboxMin = function () {
            imageboxMin(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, vm.pageData[vm.pageIndex]);
        }
        $scope.cropImageboxRotate = function (flag) {
            imageboxRotate($http,$scope, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, flag);
        }
        $scope.cropImagRestore = function () {
            imagRestore($http,$scope, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
        }
        $scope.overturnImagebox = function () {
            imageboxOverturn(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
        }

        vm.filterShow = false;
        vm.filterType='';
        vm.filterList=[
            {img:'content/images/filter1.png',type:''},
            {img:'content/images/filter2.png',type:'selfie_anime'},
            {img:'content/images/filter3.png',type:'wave'},
            {img:'content/images/filter4.png',type:'warm'},
            {img:'content/images/filter5.png',type:'scream'},
            {img:'content/images/filter6.png',type:'gothic'},
            {img:'content/images/filter7.png',type:'lavender'},
        ];
        $scope.filterCb = function(item){
            if(!vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox || !vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image || !vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier){
                return;
            }
            if(item.type == vm.filterType){
                return;
            }
            vm.threeLoading = true;
            vm.threeLoadingText = '滤镜加载中...';
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if(_imagebox.image.resourceid){
                Gallery.getOne({id:_imagebox.image.resourceid},function (res) {
                    // if(res.imgSize.size / 1024 < 1024*4){
                    //     threeInfo("图片不能大于4M")
                    //     return
                    // }
                    var _identifier = $scope.domain + res.identifier;
                    if(!item.type){
                        vm.filterType=item.type;
                        vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = _identifier;
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                    }else {
                        Convert.imageEnhance({
                            imageUrl: _identifier,
                            type: item.type
                        }, function (resp) {
                            if(resp.status == 200){
                                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = $scope.domain + resp.message;
                                vm.filterType=item.type;
                            }else{
                                threeInfo(resp.message)
                            }
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                        }, function () {
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            threeInfo("滤镜加载失败")
                        });
                    }
                }, function () {
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    threeInfo("滤镜加载失败")
                })
            }else {
                if(!_imagebox.image.resource.identifier1){
                    _imagebox.image.resource.identifier1 = angular.copy(_imagebox.image.resource.identifier);
                }
                if(!item.type){
                    vm.filterType=item.type;
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = _imagebox.image.resource.identifier1;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                }else {
                    Convert.imageEnhance({
                        imageUrl: _imagebox.image.resource.identifier1,
                        type: item.type
                    }, function (resp) {
                        if(resp.status == 200){
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = $scope.domain + resp.message;
                            vm.filterType=item.type;
                        }else{
                            threeInfo(resp.message)
                        }
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                    }, function () {
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        threeInfo("滤镜加载失败")
                    });
                }
            }
        };
        function threeInfo(item){
            vm.threeError = true;
            vm.threeErrorText=item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText="";
            },1500)
        }
        $scope.cutImage = function () {
            $timeout(function () {
                vm.filterShow = true;
            })
        };
        $scope.filterChoose = function (item) {
            switch (item) {
                case 'selfie_anime':
                    return "人像动漫化";
                    break;
                case 'warm':
                    return "浪漫巴黎";
                    break;
                case 'wave':
                    return "金阳破浪";
                    break;
                case 'lavender':
                    return "紫藤奇缘";
                    break;
                case 'scream':
                    return "挪威森林";
                    break;
                case 'gothic':
                    return "情迷罗马";
                    break;
                default:
                    return "原画";
            }
        }

        var dragGal = {};
        var drag = false;
        var startChangeTimeout;
        vm.isSmallPic = false;
        window.startChange = function (e) {
            startChangeTimeout = setTimeout(function() {
                vm.isSmallPic = true;
                $scope.$digest();
            }, 200);
            drag = true;
            var i = e.target.dataset.imgindex;
            $scope.galleryArr = vm.photoAll[i];
            $scope.galleryChoose = vm.photoAll[i].identifier;

            $scope.galleryId = i;
            $scope.galleryX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX - 53 : 0;
            $scope.galleryY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY - 40 : 0;
            dragGal.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragGal.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragGal.pageX = e.changedTouches[0].pageX ? e.changedTouches[0].pageX : 0;
            dragGal.pageY = e.changedTouches[0].pageY ? e.changedTouches[0].pageY : 0;
            dragGal.coordX = $scope.galleryX;
            dragGal.coordY = $scope.galleryY;
            $scope.$digest();
            return false;
        };

        window.dragChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (drag) {
                //手指离开屏幕
                $scope.galleryX = (dragGal.coordX + (e.changedTouches[0].clientX - dragGal.clientX));
                $scope.galleryY = (dragGal.coordY + (e.changedTouches[0].clientY - dragGal.clientY));

                // if (Math.abs($scope.galleryY - dragGal.coordY) > 10) {
                //     vm.isSmallPic = true;
                // }

                //向上滑动;
                if ($scope.galleryY <= 0) {
                    $scope.galleryY = 0;
                }
                //向左滑动;        <-----
                if ($scope.galleryX <= 0) {
                    $scope.galleryX = 0;
                }
                //向下滑动;
                if ($scope.galleryY >= $scope.dHeight - 62) {
                    $scope.galleryY = $scope.dHeight - 62;
                }
                // 向右滑动;    ---->
                if ($scope.galleryX >= $scope.dWidth - 62) {
                    $scope.galleryX = $scope.dWidth - 62;
                }

                var _picTop = $("#small-pic").offset().top;
                var _picLeft = $("#small-pic").offset().left;
                var _exist = false;
                for (var i1 = vm.pageData[vm.pageIndex].levels.level.length-1; i1 >= 0; i1--) {
                    var _level = vm.pageData[vm.pageIndex].levels.level[i1];
                    if (_level.imagebox) {
                        var _imageboxLeft = $(".imagebox" + i1).offset().left;
                        var _imageboxTop = $(".imagebox" + i1).offset().top;
                        var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                        var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                        if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight) && !_exist) {
                            _exist = true;
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(103, 106, 108, 0.8)")
                            break;
                        }else{
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(0,0,0,0.2)")
                        }
                    }
                }
                $scope.$digest();
                return false;
            }
        };

        window.stopChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (!drag) {
                return
            }
            drag = false;
            var _picTop = $("#small-pic").offset().top;
            var _picLeft = $("#small-pic").offset().left;
            for (var i1 = vm.pageData[vm.pageIndex].levels.level.length-1; i1 >= 0; i1--) {
                var _level = vm.pageData[vm.pageIndex].levels.level[i1];
                if (_level.imagebox) {
                    var _imageboxLeft = $(".imagebox" + i1).offset().left;
                    var _imageboxTop = $(".imagebox" + i1).offset().top;
                    var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                    var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                    if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight)) {
                        $scope.replaceImagebox1($scope.galleryArr, i1)
                        break;
                    }
                }
            }
            vm.isSmallPic = false;
            $scope.$digest();
        };


        $scope.addImageboxImg = function (item) {
            for (var i = 0; i < vm.pageData[vm.pageIndex].levels.level.length; i++) {
                var _level = vm.pageData[vm.pageIndex].levels.level[i];
                if (_level.imagebox && _level.imagebox.image && !_level.imagebox.image.resource.identifier) {
                    vm.levelIndex = i;
                    replaceImagebox(item)
                    break;
                }
            }
        };
        $scope.replaceImagebox1 = function (data, index) {
            vm.levelIndex = index;
            replaceImagebox(data)
        }

        function replaceImagebox(data) {
            var item = angular.copy(data);
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            _imagebox.image = {
                resource: {
                    identifier: $scope.domain + item.identifier,
                    provider: 'qiniu'
                },
                resourceid:item.id,
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                imageInfo: item.imageInfo,
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0
            }
            addImagebox($http, _imagebox, function () {})
            imageSelectInit();
        }

        function imageSelectInit() {
            vm.photoSelectAll = [];
            for (var i = 0; i < vm.pageData.length; i++) {
                for (var j = 0; j < vm.pageData[i].levels.level.length; j++) {
                    var _level = vm.pageData[i].levels.level[j];
                    if (_level.imagebox) {
                        for (var k = 0; k < vm.photoAll.length; k++) {
                            if (_level.imagebox.image.resource.identifier == vm.photoAll[k].identifier) {
                                vm.photoSelectAll.push(vm.photoAll[k]);
                                break
                            }
                        }
                    }
                }
            }
        }
        /*******************************************page3*************************************************************************/
        $scope.reselect = function(){
            $scope.makeBody();
            vm.page3 = false;
            vm.page4 = false;
            vm.page2 = true;
        }
        $scope.editPage3 = function(){
            vm.page3 = false;
            vm.page4 = true;
        }

        vm.isSkuShow = false;
        $scope.shopping = function (item) {
            //判断是否有图片未上传  且是否继续保存
            if (!$scope.isContinue()) {
                SweetAlert.swal({
                    title: '温馨提示',
                    imageUrl: Theme.sweetHintImg(),
                    text: "当前作品没有制作完成",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            vm.property =[];
            vm.itemValueLine = null;
            vm.shoppingCart ={};
            vm.price ="";
            getProperty(item)
        }
        function getProperty(item){
            for(var i=0; i<vm.product.objectProperties.length; i++){
                if(!vm.product.objectProperties[i].cancelled && vm.product.objectProperties[i].propertyType === 'Expanded'){
                    var _property = {
                        name: vm.product.objectProperties[i].propertyName,
                        propertyType: vm.product.objectProperties[i].propertyType,
                        values:[]
                    };
                    for(var j=0; j<vm.product.objectProperties[i].propertyValues.length; j++){
                        var _propertyValues = vm.product.objectProperties[i].propertyValues[j];
                        if(!_propertyValues.cancelled){
                            _propertyValues.propertySku = _propertyValues.propertyId + '_' + _propertyValues.id;
                            _property.values.push(_propertyValues)
                        }
                    }
                    vm.property.push(_property)
                }
            };
            if(vm.property.length>0){
                vm.isSkuShow = true;
                itemValue();
            }else{
                var itemValueLine = null;
                for(var i=0; i<vm.product.itemValues.length; i++){
                    var _itemValues = vm.product.itemValues[i];
                    if(!_itemValues.cancelled && _itemValues.id === vm.itemValue.id){
                        for(var i1=0; i1<_itemValues.itemValueLines.length; i1++){
                            var _itemValueLines = _itemValues.itemValueLines[i1];
                            if(!_itemValueLines.cancelled && _itemValueLines.skuNumber == vm.itemValue.skuNumber){
                                itemValueLine = _itemValueLines;
                            }
                        }
                    }
                };
                vm.itemValueLine = itemValueLine;
                if(item){
                    if(item == 'purchaseSave'){
                        $scope.purchaseSave();
                    }
                    if(item == 'shoppingSave'){
                        $scope.shoppingSave();
                    }
                }else{
                    $scope.purchaseSave();
                }
            }
        };
        function itemValue(){
            for(var i=0; i<vm.product.itemValues.length; i++){
                var _itemValues = vm.product.itemValues[i];
                if(!_itemValues.cancelled && _itemValues.id === vm.itemValue.id){
                    for(var i1=0; i1<_itemValues.itemValueLines.length; i1++){
                        var _itemValueLines = _itemValues.itemValueLines[i1];
                        if(_itemValueLines.saleable){
                            for(var i2 = 0; i2<vm.property.length; i2++){
                                var _property = vm.property[i2];
                                for(var i3=0; i3<_property.values.length; i3++){
                                    var _propertyValues = _property.values[i3];
                                    if(_itemValueLines.skuNumber.indexOf(_propertyValues.propertySku) >= 0 && !
                                        _propertyValues.selectable){
                                        _propertyValues.selectable = true;
                                        _propertyValues.show = true;
                                    }
                                }
                            }
                        }
                    }
                }
            };
        };
        $scope.propertySelect = function(parentIndex, index, item){
            var _propertySku = [];
            var _sku = [];
            if(!item.selectable){
                return
            }
            for(var a=0; a<vm.property[parentIndex].values.length; a++){
                if(a == index){
                    vm.property[parentIndex].values[a].selected = !vm.property[parentIndex].values[a].selected ? true : false;
                }else{
                    vm.property[parentIndex].values[a].selected = false;
                }
            }
            for(var b=0; b<vm.property.length; b++){
                for(var b1=0; b1<vm.property[b].values.length; b1++){
                    if(vm.property[b].values[b1].selected){
                        _propertySku.push(vm.property[b].values[b1].propertySku)
                    }
                }
            }
            if(_propertySku.length>0){
                var _dataProperty = itemValue1(_propertySku);
                _sku = _dataProperty._sku;
                for(var j = 0; j<vm.property.length; j++){
                    var _property = vm.property[j];
                    for(var j1=0; j1<_property.values.length; j1++){
                        var _propertyValues = _property.values[j1];
                        _propertyValues.selectable = false;
                    }
                }


                for(var i2 = 0; i2<vm.property.length; i2++){
                    var _property1 = vm.property[i2];
                    for(var i3=0; i3<_property1.values.length; i3++){
                        var _propertyValues1 = _property1.values[i3];
                        if(!_propertyValues1.selected){
                            var _propertySku1 = _propertyValues1.propertySku;
                            for(var i4=0; i4<_sku.length; i4++){
                                if(_sku[i4].indexOf(_propertySku1) >= 0){
                                    _propertyValues1.selectable = true;
                                }
                            }
                        }else{
                            _propertyValues1.selectable = true;
                        }
                    }
                }
                vm.itemValueLine = _dataProperty.itemValueLines;
                vm.price="";
                if(vm.itemValueLine){
                    getPrice()
                    getByItemValueLineId()
                }
            }else{
                vm.itemValueLine = "";
                vm.price="";
                itemValue();
            }
        };
        function itemValue1(propertySku){
            var _data = {
                _sku:[],
                itemValueLines: null
            }
            for(var i=0; i<vm.product.itemValues.length; i++){
                var _itemValues = vm.product.itemValues[i];
                if(!_itemValues.cancelled && _itemValues.id === vm.itemValue.id){
                    for(var i1=0; i1<_itemValues.itemValueLines.length; i1++){
                        var _itemValueLines = _itemValues.itemValueLines[i1];
                        if(!_itemValueLines.cancelled){
                            if(_data._sku.indexOf(_itemValueLines.skuNumber) == -1){
                                var _propertyLength = 0
                                var isItemValueLines = true;
                                for(var i2 =0; i2<propertySku.length; i2++){
                                    if(_itemValueLines.skuNumber.indexOf(propertySku[i2]) >= 0){
                                        _propertyLength++
                                    }else{
                                        isItemValueLines = false
                                    }
                                }
                                if(isItemValueLines){
                                    _data._sku.push(_itemValueLines.skuNumber)
                                }
                                if(_propertyLength == vm.property.length){
                                    _data.itemValueLines = _itemValueLines;
                                    vm.itemValue = _itemValues;
                                }
                            }
                        }
                    }
                }
            };
            return _data
        };
        $scope.close = function() {
            vm.isSkuShow = false;
        };
        function getPrice(){
            if(!vm.itemValueLine){
                SweetAlert.swal({
                    title: '请选择完整的属性',
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            var parameter = {
                productId: vm.product.id,
                itemValueId: vm.itemValueLine.itemValueId,
                itemValueLineId: vm.itemValueLine.id,
            }
            parameter.copy = vm.quantity;
            Product.getCostV2(parameter,function(data){
                if (data.status == 200) {
                    vm.amount = data.message.toFixed(2);
                    vm.price = (data.message/vm.quantity).toFixed(2);
                }
            });
        };
        $scope.addQuantity = function(){
            if (vm.quantity >= vm.limitQuantity  && vm.limitQuantity!=null) {
                vm.hint = "最多可购买" + vm.limitQuantity + "件";
                vm.isHint = true;
                $timeout(function(){
                    vm.isHint = false;
                },1500)
                return;
            }
            vm.quantity = vm.quantity + 1;
            if(vm.itemValueLine){
                getPrice()
            }
        };
        $scope.subtract = function(){
            if (vm.quantity > vm.minimumOrder) {
                vm.quantity--;
                getPrice()
            }else{
                vm.hint = "起购数是" + vm.minimumOrder + "件";
                vm.isHint = true;
                $timeout(function(){
                    vm.isHint = false;
                },1500)
                return;
            }
            // if(vm.quantity == 1){
            //     SweetAlert.swal({
            //         title: '购买数量不能小于1',
            //         imageUrl: Theme.sweetHintImg(),
            //         text: "",
            //         timer: 2000,
            //         showConfirmButton: false
            //     });
            //     return
            // }
            // vm.quantity--
            // if(vm.itemValueLine){
            //     getPrice()
            // }
        };
        $scope.productXmlLineClick1 = function(itemValue, productXmlLine, item){
            vm.itemValue = itemValue;
            vm.productXmlLine = productXmlLine;
            $scope.template = vm.productXmlLine.document;
            vm.pageData = angular.copy($scope.template.pages.page);
            vm.pageSum = $scope.template.pages.page.length - 1;
            vm.pageIndex = 0;
            vm.counts = 0;
            vm.isGuidePage = true;
            angular.forEach(vm.pageData, function (page, i) {
                angular.forEach(page.levels.level, function (level) {
                    if (level.imagebox && level.imagebox.geometry) {
                        vm.counts++;
                    }
                })
            });
            $scope.shopping(item);
        }
        $scope.shoppingSave = function(){
            if(!vm.itemValueLine){
                SweetAlert.swal({
                    title: '请选择完整的属性',
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            vm.isPurchaseSave = false;
            vm.isBackWorks = false;
            vm.isSubmitBackWorks = false;
            vm.isShoppingSave = true;
            $scope.save();
        };
        $scope.purchaseSave = function(){
            if(!vm.itemValueLine){
                SweetAlert.swal({
                    title: '请选择完整的属性',
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            vm.isBackWorks = false;
            vm.isSubmitBackWorks = false;
            vm.isShoppingSave = false;
            vm.isPurchaseSave = true;
            $scope.save()
        }
        //返回作品库
        $scope.backWorks = function(){
            if (!$scope.isContinue()) {
                SweetAlert.swal({
                    title: '温馨提示',
                    imageUrl: Theme.sweetHintImg(),
                    text: "当前作品没有制作完成",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            vm.isSubmitBackWorks = false;
            vm.isShoppingSave = false;
            vm.isPurchaseSave = false;
            vm.isBackWorks = true;
            $scope.save()
        }
        //提交生产
        $scope.submitBackWorks = function(){
            if (!$scope.isContinue()) {
                SweetAlert.swal({
                    title: '温馨提示',
                    imageUrl: Theme.sweetHintImg(),
                    text: "当前作品没有制作完成",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            vm.isShoppingSave = false;
            vm.isPurchaseSave = false;
            vm.isBackWorks = false;
            vm.isSubmitBackWorks = true;
            $scope.save()
        }

        function purchaseSave(article){
            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                var str = JSON.stringify({
                    articleId:article.id,
                    itemValueLineId:vm.itemValueLine.id,
                    productId: vm.pid,
                    itemValueId: vm.itemValue.id,
                    copy:vm.quantity,
                    quantity: 1,
                    productXmlId: article.productXmlId
                });
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/pages/shop/placeOrder/placeOrder?jsonStr=' + str})
                    return
                }
                uni.redirectTo({
                    url: '/pages/shop/placeOrder/placeOrder?jsonStr=' + str
                })
            } else {
                var arr={
                    ids: article.id,
                    quantity: vm.quantity,
                    itemValueLineId: vm.itemValueLine.id,
                    lineSku: vm.itemValueLine.sku,
                    lineSkuNumber: vm.itemValueLine.skuNumber,
                }
                window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/' + article.id + '/1/'+ arr.itemValueLineId +'/'+ vm.itemValue.id +'/'+ arr.quantity +'/'+ vm.pid + '//new2';
            }
        }
        function addGoods(article){
            Cart.addGoods({
                articleId: article.id,
                copy: vm.quantity,
                quantity: 1,
                productId: vm.pid,
                itemValueLineId:vm.itemValueLine.id,
                itemValueId: vm.itemValue.id,
                orderWay: vm.store.orderWay,
                productXmlId: article.productXmlId,
            }, function (res) {
                if(res.status == 400){
                    swal({
                        title: "失败",
                        text: res.message || "加入购物车失败",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                    return
                }
                swal({
                    title: "成功加入购物车",
                    text: "",
                    imageUrl: Theme.sweetSuccedImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                    if(localStorage.getItem('distinguish') == 'jdApp'){
                        jd.miniProgram.redirectTo({url: '/pages/shop/shoppingCar/shoppingCar'})
                        return
                    }
                    uni.redirectTo({
                        url: '/pages/shop/shoppingCar/shoppingCar'
                    })
                } else {
                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/cart';
                }
            })
        }
        //保存
        $scope.isSave = false;
        $scope.save = function () {
            if ($scope.isSave) {
                return;
            }
            if ($scope.template.pages.page.length < $scope.PAGELEN) {
                SweetAlert.swal({
                    title: '模板加载中，请稍候...',
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            articleInfo()
        }

        function articleInfo() {
            vm.threeLoading = true;
            vm.threeLoadingText='保存中...';
            $scope.isSave = true;
            ItemValue.get({id: vm.itemValue.id}, function (res) {
                vm.article = {
                    modelBlendentUuid: vm.productXmlLine.modelBlendentUuid,
                    cover: vm.productXmlLine.coverImg,
                    themeClassifyId: vm.productXmlLine.productXmlThemeId,
                    themeClassifyName: vm.productXmlLine.productXmlThemeName,
                    productXmlId: vm.productXmlLine.productXmlId,
                    productXmlName: vm.productXmlLine.productXmlName,
                    musicIdentify: vm.productXmlLine ? vm.productXmlLine.musicIdentify : res.musicIdentify,
                    sku: res.sku,
                    skuNumber: res.skuNumber,
                    name: res.productName,
                    productId: res.productId,
                    productName: res.productName,
                    categoryId: res.categoryId,
                    categoryName: res.categoryName,
                    categoryStoreId: res.categoryStoreId,
                    categoryStoreName: res.categoryStoreName,
                    editorType: res.productEditorType,
                    productBokeh: res.productBokeh,
                    productBokehType: res.productBokehType || 'None',
                    itemValueId: res.itemValueId!= null?res.itemValueId : vm.itemValue.id
                };
                if (!res.productBokeh) {
                    vm.article.bokehtype = 'None';
                } else {
                    vm.article.bokehtype = res.productBokehType || 'None';
                }

                vm.isCover = false;
                $timeout(function () {
                    if(!vm.isCover){
                        vm.isCover = true;
                        vm.article.covered=false;
                        vm.article.cover=null;
                        generateJsonFile();
                    }
                },5000)
                thumbnailInit(function (res){
                    if(!vm.isCover){
                        vm.isCover = true;
                        generateJsonFile();
                    }
                })
            });
        }

        $scope.galleryDestroyConfirm = function(){
            $scope.removeGallery();
            generateJsonFile1(vm.saveflag);
        };

        function generateJsonFile() {
            vm.deleteList = 0;
            var gallieryIds = [];
            for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                var page = $scope.template.pages.page[i];
                if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                    continue;
                }
                for (var j = page.levels.level.length - 1; j >= 0; j--) {
                    var level = page.levels.level[j];
                    if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                        continue;
                    }
                    gallieryIds.push(level.imagebox.image.resourceid);
                }
            }
            if (gallieryIds.length>0) {
                Gallery.checkDeleted(gallieryIds, function(list){
                    // vm.deleteList = [{id:gallieryIds[0]}];
                    // vm.completed = false;
                    // vm.isDestroyed = true;
                    if (list == null|| list.length ==0) {
                        generateJsonFile1(flag)
                    }else{
                        vm.deleteList = list;
                        vm.completed = false;
                        vm.isDestroyed = true;
                        return;
                    }
                }, function(){
                    MessageService.error("检测图片失败，请稍后再试。");
                })
            }else{
                generateJsonFile1(flag)
            }
        }

        $scope.removeGallery = function(){
            if (vm.deleteList == null || vm.deleteList.length == 0) {
                return;
            }
            for (var z = vm.deleteList.length - 1; z >= 0; z--) {
                var g = vm.deleteList[z];
                for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                    var page = $scope.template.pages.page[i];
                    if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                        continue;
                    }
                    for (var j = page.levels.level.length - 1; j >= 0; j--) {
                        var level = page.levels.level[j];
                        if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                            continue;
                        }
                        if (level.imagebox.image.resourceid == g.id) {
                            level.imagebox.image.resource.identifier = "";
                            level.imagebox.image.resource.adaptation = "";
                            level.imagebox.image.resource.provider = "";
                            level.imagebox.image.resource.offsetx = 0;
                            level.imagebox.image.resource.offsety = 0;
                            level.imagebox.image.resource.height = "";
                            level.imagebox.image.resource.width = "";
                            level.imagebox.image.resource.scale = 1;
                            level.imagebox.image.resource.imgSize = "";
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.imageInfo = null;
                            break;
                        }
                    }
                }
            }
            imageSelectInit();
        }

        function generateJsonFile1() {
            vm.article.document = saveData();
            vm.article.document.hostinfo = hostInfo();
            //格式化数据
            var data = {
                id: vm.article.id,
                name: vm.article.name,
                completed: vm.completed,
                sku: vm.article.sku,
                skuNumber: vm.article.skuNumber,
                cover: vm.article.cover,
                document: vm.article.document,
                productType: 'CustomPrint',
                fileName: vm.article.fileName,
                productId: vm.article.productId,
                productName: vm.article.productName,
                categoryId: vm.article.categoryId,
                categoryName: vm.article.categoryName,
                categoryStoreId: vm.article.categoryStoreId,
                categoryStoreName: vm.article.categoryStoreName,
                folderId: vm.article.folderId,
                itemValueId: vm.article.itemValueId,
                themeClassifyId: vm.article.themeClassifyId,
                themeClassifyName: vm.article.themeClassifyName,
                productXmlId: vm.article.productXmlId,
                productXmlName: vm.article.productXmlName,
                musicIdentify: vm.article.musicIdentify,
                editorType: vm.article.editorType,
                unFilledCount: vm.unFilledCount,
                modelBlendentUuid: vm.article.modelBlendentUuid,
            }
            Article.save(data, function (resp) {
                swal({
                    title: "保存成功",
                    text: "作品已同步到我的作品",
                    imageUrl: Theme.sweetSuccedImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                $scope.isSave = false;
                if(vm.isPurchaseSave){
                    vm.threeLoading = false;
                    vm.threeLoadingText='';
                    purchaseSave(resp)
                }
                if(vm.isShoppingSave){
                    vm.threeLoading = false;
                    vm.threeLoadingText='';
                    addGoods(resp)
                }
                if(vm.isBackWorks || vm.isSubmitBackWorks){
                    works(resp)
                }
            }, function (resp) {
                vm.threeLoading = false;
                vm.threeLoadingText='';
                $scope.isSave = false;
                swal({
                    title: "保存失败",
                    text: "保存出错了，请稍后再试，或者联系工作人员",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 3000,
                    showConfirmButton: false
                });

            });
            localStorage.removeItem("photoSelectAll");
        }

        function works(article){
            SalesOrder.updateArticle({
                id: vm.salesOrderId,
                productLineId: vm.productLineId,
                articleId: article.id,
                copy: 1,
            }, function (res) {
                if(res.status == 400){
                    vm.threeLoading = false;
                    vm.threeLoadingText='';
                    swal({
                        title: res.message || "保存失败，请稍后再试！",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                    return
                }
                if(vm.isBackWorks){
                    vm.threeLoading = false;
                    vm.threeLoadingText='';
                    backWorksCb()
                }
                if(vm.isSubmitBackWorks){
                    SalesOrder.submit({
                        id: vm.salesOrderId
                    }, function(data) {
                        swal({
                            title: "提交成功",
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                        vm.threeLoading = false;
                        vm.threeLoadingText='';
                        backWorksCb(true)
                    }, function() {
                        swal({
                            title: "提交失败!",
                            text: error.data.message,
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                }
            }, function (res) {
                vm.threeLoading = false;
                vm.threeLoadingText='';
                swal({
                    title: res.data.message || "保存失败，请稍后再试！",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            })
        }
        function backWorksCb(item){
            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                if(!item){
                    if(localStorage.getItem('distinguish') == 'jdApp'){
                        jd.miniProgram.redirectTo({url: '/pages/portfolio/workManagement/workManagement?productLineId=' + vm.productLineId + '&salesOrderId=' + vm.salesOrderId})
                        return
                    }
                    uni.redirectTo({
                        url: '/pages/portfolio/workManagement/workManagement?productLineId=' + vm.productLineId + '&salesOrderId=' + vm.salesOrderId
                    })
                }else{
                    var item = {typeId: 0,name: '全部'};
                    var str = JSON.stringify(item);
                    if(localStorage.getItem('distinguish') == 'jdApp'){
                        jd.miniProgram.redirectTo({url: '/pages/information/orderListPage/orderListPage?jsonStr=' + str})
                        return
                    }
                    uni.redirectTo({
                        url: '/pages/information/orderListPage/orderListPage?jsonStr=' + str
                    });
                }
            } else {
                if(!item){
                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/' + vm.salesOrderId + '/' + vm.productLineId + '/article2';
                }else{
                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/All';
                }
            }
        }

        $scope.isContinue = function () {
            var hasEmpty = true;
            vm.unFilledCount = 0;
            for (var i = 0; i < $scope.template.pages.page.length; i++) {
                var _page = $scope.template.pages.page[i];
                if (_page.levels.level) {
                    for (var j = 0; j < _page.levels.level.length; j++) {
                        var _level = _page.levels.level[j];
                        if (_level.imagebox) {
                            if (!_level.imagebox.image || !_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                hasEmpty = false;
                                vm.unFilledCount++;
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty;
            return hasEmpty;
        };

        function saveData() {
            return documentMM($scope.template, $scope.domain);
        }

        function fn($event) {
            var $event = $event || window.event;
            if ($event && $event.preventDefault) {
                $event.preventDefault();
            } else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }

        $scope.threeModalHide = function () {
            vm.threeIsModal = false;
            vm.threeModalText = "";
            vm.threeIsModal1 = false;
            vm.threeModalText1 = "";
        }
        $scope.threeModalSave = function () {
            vm.threeIsModal = false;
            vm.threeModalText = "";
            vm.threeIsModal1 = false;
            vm.threeModalText1 = "";
            templateReplace();
        }
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreConfig.getAllByTypes({storeId: vm.storeId,type: ["IMGSIZE", "IMGDPI"]}, function (res) {
            if(res.status == 200){
                res.message.forEach(function (item) {
                    if(item.type === 'IMGSIZE'){
                        vm.imgSize = item.value;
                    }
                    if(item.type === 'IMGDPI'){
                        vm.imgDpi = item.value;
                    }
                })
            }
        });
        vm.isIPhone6 = isIPhone6();

        //生成封面图
        function thumbnailInit(callback) {
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view'), {
                    onrendered: function (canvas) {
                        if(!canvas){
                            vm.article.covered=false;
                            vm.article.cover=null;
                            callback();
                            return
                        }
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                vm.article.covered=true;
                                vm.article.cover= data.key;
                                if(callback){callback()};
                            }
                        })
                    },
                    error:function(){
                        vm.article.covered=false;
                        vm.article.cover=null;
                        if (callback) {callback()};
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: vm.pageData[0].custom.width*vm.pageData[0].custom.thumbnailScale,
                    height: vm.pageData[0].custom.height*vm.pageData[0].custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            });
        }


        function getByItemValueLineId(){
                SkuSellLimits.getByItemValueLineId({productId:vm.product.id,itemValueLineId:vm.itemValueLine.id},function(res){
                    if (res.message) {
                        vm.limitQuantity = res.message.limitQuantity
                        vm.warningQuantity = res.message.warningQuantity
                    }else{
                        vm.limitQuantity = null
                        vm.warningQuantity = null
                    }
                })
        }

        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("ws://localhost:8096/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if ((vm.folderOne && vm.folderOne.id == photo.folderId) || (photo.folderId == null && !vm.folderOne)) {
                    var isHave = false;
                    for (var i = 0; i < vm.photoAll.length; i++) {
                        var item = vm.photoAll[i];
                        if (item.id == photo.id) {
                            isHave = true;
                            break;
                        }
                    }
                    if (!isHave) {
                        vm.photoAll.unshift(photo);
                        getPagePhoto();
                    }
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
    }
})();
