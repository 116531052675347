(function () {
    'use strict';

    var homeHead = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/home/home-head.html',
        controllerAs: 'vm',
        bindings: {
            store: '<',
            customer: '<',
            domain: '<',
        },

        controller: ['$scope', '$stateParams', '$q', 'MessageService', '$cookieStore','$state', 'Auth', '$localStorage', '$uibModal',
         'Customer','UserMessage', 'Cart', '$rootScope','$interval','StoreConfig',
            function ($scope, $stateParams, $q, MessageService, $cookieStore, $state, Auth, $localStorage, $uibModal,
             Customer,UserMessage, Cart, $rootScope,$interval, StoreConfig) {
                var vm = this;
                vm.auth = true;
                if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                    vm.auth = false;
                }
                vm.customer = {};
                vm.messageCount = 0;
                vm.messageList = [];
                vm.allMessageList = UserMessage.getList({page:vm.page, size:vm.size, sort: sort()});
                vm.messageListShow = false;


                $scope.getCountGoods =function(){
                    Cart.countGoods({}, function(res){
                        if (res.status == 200) {
                            vm.goodsCount= res.message;
                            if (vm.goodsCount>99) {
                                vm.goodsCount = "99+";
                            }
                        }
                    })
                }
                $scope.getCountGoods();

                var unsubscribe = $rootScope.$on('editorApp:cartUpdate', function(event, result) {
                    $scope.getCountGoods();
                });

                $scope.$on('$destroy', unsubscribe);

                var createModal = function (url, controller, type) {
                    if (type == null) {
                        type = "Login";
                    }
                    return $uibModal.open({
                        templateUrl: url,
                        controller: controller,
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        resolve: {
                            entity: {
                                type: type
                            },
                        }
                    });
                };

                $scope.login = function (type) {
                    createModal('app/account/register/register.html', 'RegisterController', type).result.then(
                        function () {
                            if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                                vm.auth = false;
                            }
                    });
                };

                vm.page = 0;
                vm.size = 20;
                function sort() {
                    return ["id,desc"];
                }
                getList()
                function getList() {
                    UserMessage.getList({read:false,page:vm.page, size:vm.size, sort: sort()},onSuccess, onError);
                    function onSuccess(res,headers) {
                        res.forEach(function (item){
                            vm.messageList.push(item)
                        })
                        vm.totalItems = headers('X-Total-Count');
                    }
                    function onError(error){
                    }
                }

                $('.message-list1').on('scroll', function(event){
                    var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                    if(vm.messageList.length < vm.totalItems){
                        if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                            vm.page++;
                            getList()
                        }
                    }
                });
                $scope.showMessageList = function (){
                    vm.messageListShow = true;
                    UserMessage.unreadCount(function (data){
                        if(data.count <= 99) {
                            vm.messageCount = data.count;
                        }else {
                            vm.messageCount = "99+";
                        }
                    });
                    vm.page=0;
                    vm.messageList = [];
                    getList()
                }
                $scope.closeMessageList = function (){
                    vm.messageListShow = false;
                }
                UserMessage.unreadCount(function (data){
                    if(data.count <= 99) {
                        vm.messageCount = data.count;
                    }else {
                        vm.messageCount = "99+";
                    }
                });
                $scope.timer=$interval(function(){
                    if (vm.auth) {
                        UserMessage.unreadCount(function (data) {
                            if (data.count != vm.messageCount) {
                                if (data.count <= 99) {
                                    vm.messageCount = data.count;
                                } else {
                                    vm.messageCount = "99+";
                                }
                                vm.page = 0;
                                vm.messageList = [];
                                getList();
                            }
                        });
                    }
                },5000);
                $scope.$on('$destroy',function(){
                    $interval.cancel($scope.timer);
                })

                vm.storeId = $stateParams.storeId || $cookieStore.get("store-id") || $state.params.storeId;
                $q.all([vm.store.$promise, vm.domain.$promise]).then(function(){
                    vm.logoUrl = vm.store && vm.store.menuLogo ? vm.domain + vm.store.menuLogo : 'content/images/logo.png';
                    vm.storeCartConfig = StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"SHOPPINGCART"});

                    if (vm.auth) {
                        Customer.current({}, function(data){
                            vm.customer = data;
                            if(data.inactive){
                                $state.go('outofservice',{storeId: vm.storeId})
                            }
                        }, function(res){
                            // MessageService.error(res.data.message);
                        });
                    }
                });
                $scope.uiSrefCb = function (item) {
                    if (!vm.auth) {
                        $scope.login('Login');
                    } else {
                        $state.go(item, {storeId: vm.storeId});
                    }
                }
                vm.logout = function() {
                    Auth.logout();
                    $state.go("commodity",{storeId: vm.storeId}, {reload:true});
                }

                $scope.messageDetails = function (){
                    if (vm.allMessageList.length > 0) {
                        $state.go('userMessage', {storeId: vm.storeId, mid: vm.allMessageList[0].id});
                    }else {
                        $state.go('userMessage', {storeId: vm.storeId});
                    }
                }
                $scope.messageDetail = function (item){
                    $state.go('userMessage', {storeId: vm.storeId, mid: item.id});
                }
            }]
    };

    angular
        .module('editorApp')
        .component('homeHead', homeHead);
})();
