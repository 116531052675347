(function () {
    'use strict';

    var homeNav1 = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/home/home-nav1.html',
        controllerAs: 'vm',
        bindings: {
            store: '<',
            customer: '<',
            domain: '<',
        },

        controller: ['$scope', '$stateParams', '$q', 'MessageService', '$cookieStore', '$state',
            function ($scope, $stateParams, $q, MessageService, $cookieStore, $state) {
                var vm = this;
                vm.storeId = $stateParams.storeId || $cookieStore.get("store-id");
                $scope.navSelect = function (item) {
                    return window.location.href.indexOf(item) > -1 ? true : false
                }
                $scope.uiSrefCb = function (item) {
                    $state.go(item, {storeId: vm.storeId});
                }
            }]
    };

    angular
        .module('editorApp')
        .component('homeNav1', homeNav1);
})();
