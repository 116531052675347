(function () {
    'use strict';

    angular
        .module('editorApp')
        .factory('Theme', Theme);


    function Theme () {
        return{
            judge:function() {
                var newCss = document.getElementById("linkid");
                console.log(localStorage.getItem('Theme'));
                if (localStorage.getItem('Theme') == 'One') {
                    newCss.href = "content/css/themeOrange.css";
                } else if (localStorage.getItem('Theme') == 'Two') {
                    newCss.href = "content/css/themeBlue.css";
                } else if (localStorage.getItem('Theme') == 'Three') {
                    newCss.href = "content/css/themeBlack.css";
                } else if (localStorage.getItem('Theme') == 'Four') {
                    newCss.href = "content/css/themeRed.css";
                } else if (localStorage.getItem('Theme') == 'Five') {
                    newCss.href = "content/css/themeGreen.css";
                }else{
                    newCss.href = "content/css/themeOrange.css";
                }
            },
            sweetHintImg:function(){
                return "content/images/icons/swal-tip.svg";
            },
            sweetSuccedImg: function(){
                return "content/images/icons/swal-yes.svg";
            },
            sweetTipImg: function(){
                return "content/images/icons/swal-tip.svg";
            },
            sweetBrokenImg:function(){
                return "content/images/icons/broken.svg";
            },
        };
    }
})();
