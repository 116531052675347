(function () {
    'use strict';

    var threePreview3 = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/three-preview3.html',
        // controller: 'articlePreviewController',
        controllerAs: 'vm',
        bindings: {
            height: '<',
            width: '<',
            moulds: '<',
            modelPath: '<',
            modelBlendents: '<',
            modelData: '<',
            modelBlendentUuid: '<',
            pages: '<',
            onChange:"&"
        },
        controller: ['$timeout','$scope',
            function ($timeout, $scope) {
                var vm = this;
                vm.viewTab = 'Three';
                vm.$onInit = function(){
                    if(!vm.width){vm.width = $(window).width()}
                    if(!vm.height){vm.height = $(window).height()}
                }
                vm.$onChanges = function(data) {
                    if (data.height&&data.height.currentValue) {
                        vm.height = data.height.currentValue;
                    }
                    if (data.width&&data.width.currentValue) {
                        vm.width = data.width.currentValue;
                    }
                    if (data.moulds&&data.moulds.currentValue) {
                        vm.moulds = data.moulds.currentValue;
                    }
                    if (data.modelPath&&data.modelPath.currentValue) {
                        vm.modelPath = data.modelPath.currentValue;
                    }
                    if (data.modelBlendents&&data.modelBlendents.currentValue) {
                        vm.modelBlendents = data.modelBlendents.currentValue;
                    }
                    if (data.modelData&&data.modelData.currentValue) {
                        vm.modelData = data.modelData.currentValue;
                    }
                    if (data.modelBlendentUuid&&data.modelBlendentUuid.currentValue) {
                        vm.modelBlendentUuid = data.modelBlendentUuid.currentValue;
                    }
                    if (data.pages&&data.pages.currentValue) {
                        vm.pages = data.pages.currentValue;
                    }
                    vm.isUpdate = false;
                    $timeout(function () {
                        vm.isUpdate = true;
                    })
                };

                $scope.viewTabCb = function () {
                    vm.viewTab = 'Two';
                    swiper1()
                }
                vm.isWebMaxThree = false;
                function swiper1() {
                    vm.mouldIndex = 0;
                    if(vm.moulds&&vm.moulds.length>0){
                        $timeout(function () {
                            var webMaxSwiper = new Swiper('.swiper-container-webmin', {
                                slidesPerView: 5,
                                spaceBetween: 16,
                                navigation: {
                                    nextEl: '.swiper-button-next-webmin',
                                    prevEl: '.swiper-button-prev-webmin',
                                },
                                on: {
                                    init: function(swiper){
                                        vm.mouldIndex = this.activeIndex;
                                        console.log(vm.mouldIndex)
                                        webMaxThreeInit()
                                        $scope.$apply();
                                    }
                                },
                            });
                        })
                    }
                }
                $scope.webMaxSwiper = function (item) {
                    if(item==='prev'){
                        if(vm.mouldIndex>0){
                            vm.mouldIndex--
                        }
                    } else if(item==='next'){
                        if(vm.mouldIndex<vm.moulds.length-1){
                            vm.mouldIndex++
                        }
                    }else{
                        vm.mouldIndex = item;
                    }
                    webMaxThreeInit()
                }
                function webMaxThreeInit(){
                    vm.isWebMaxThree = false;
                    $timeout(function () {
                        vm.isWebMaxThree = true;
                    })
                }
            }
        ]
    };

    angular
        .module('editorApp')
        .component('threePreview3', threePreview3);
})();
