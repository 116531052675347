(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CommodityPreviewController', CommodityPreviewController);

    CommodityPreviewController.$inject = ['$scope', 'Principal', 'Product', '$state', 'MessageService', '$rootScope', 'entity', '$stateParams',
     '$uibModalInstance', 'ProductXml', 'Qinius', '$q', '$uibModal', 'StoreConfig', 'Cart', '$localStorage', "Editors"];

    function CommodityPreviewController($scope, Principal, Product, $state, MessageService, $rootScope, entity, $stateParams,
        $uibModalInstance, ProductXml, Qinius, $q, $uibModal, StoreConfig, Cart, $localStorage, Editors) {
        var vm = this;
        $(".wrap").show();
        $(".navbar").show();
        vm.productXmlLine = entity.productXmlLine;
        vm.type = entity.type;
        vm.storeId = entity.storeId;
        vm.returnUrl = entity.returnUrl;
        vm.pid = entity.pid;
        vm.vid = entity.vid;
        vm.plid = $stateParams.plid;
        vm.orderId = $stateParams.orderId;
        vm.store = $localStorage.store;
        vm.ipDerive =false;
        vm.index = 0;
        vm.isMake = entity.isMake || false;
        vm.editorType = entity.editorType;
        vm.height = 937; document.body.clientHeight ;

        vm.product = Product.get({id:vm.pid});
        vm.domains = Qinius.getDomain1({
            storeId: vm.storeId
        });

        $scope.addCart = function () {
            if (vm.ipDerive) {
                var createModal3 = function(url, controller, data) {
                    return $uibModal.open({
                        templateUrl: url,
                        controller: controller,
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        resolve: {
                            entity: data
                        }
                    });
                };
                var data = {title:"加入购物车", itemValueId:vm.vid};
                createModal3('app/entities/sales-order/confirm-property.html', 'ConfirmPropertyController', data).result.then(
                    function(value) {
                        Cart.addGoods({
                            productId:vm.pid,
                            orderWay: vm.store.orderWay,
                            itemValueLineId:value.itemValueLineId,
                            lineSku:value.lineSku ,
                            lineSkuNumber:value.lineSkuNumber,
                            quantity: 1,
                            copy: value.copy,
                            itemValueId: vm.vid,
                            productXmlId: vm.productXmlLine.productXmlId
                        },function(res){
                            if(res.status == 400){
                                swal({
                                    title: "失败",
                                    text: res.message || "加入购物车失败",
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                return
                            }
                            $scope.$emit('editorApp:cartUpdate', {});
                            $state.go("cart", {storeId: vm.storeId});
                        },function(data){
                            MessageService.error("系统错误，请稍后再试");
                        })
                    }
                )
            }
            $uibModalInstance.close();
        };

        vm.storeCartConfig = StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"SHOPPINGCART"});
        $q.all([vm.domains.$promise]).then(function() {
            if (vm.product && vm.product.productType == 'IPDerive') {
                vm.ipDerive = true;
            }
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            init()
        });
        //初始化
        function init() {
            //获取预览图片
            ProductXml.findOne({
                id: vm.productXmlLine.productXmlId
            }, function(data) {
                if (data.documentPics && data.documentPics.length > 0) {
                    data.documentPics.sort(function(a, b) {
                        return a.seq - b.seq;
                    });
                }
                vm.imgs = data.documentPics;
                setTimeout(function () {
                        swiper();
                    },300);
            }, function() {

            });
        };

        $scope.modalHide = function() {
            $uibModalInstance.close();
        };

        $scope.modalSubmint = function() {
            if (vm.ipDerive) {
                var createModal3 = function(url, controller, data) {
                    return $uibModal.open({
                        templateUrl: url,
                        controller: controller,
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        resolve: {
                            entity: data
                        }
                    });
                };
                var data = {title:"立即下单", itemValueId:vm.vid};
                createModal3('app/entities/sales-order/confirm-property.html', 'ConfirmPropertyController', data).result.then(
                    function(value) {
                        $state.go("sales-order-reorder", {storeId: vm.storeId,   productId:vm.pid, itemValueId:vm.vid,
                            itemValueLineId: value.itemValueLineId, lineSku:value.lineSku , lineSkuNumber:value.lineSkuNumber,
                            copy:value.copy, quantity:1, productXmlId: vm.productXmlLine.productXmlId});
                    }
                )
            }else{
               if (Editors.belongToEditorAll(vm.editorType)) {
                    $state.go("editorAll", {
                        storeId: vm.storeId,
                        pid: vm.pid,
                        vid: vm.vid,
                        xmlId: vm.productXmlLine.id,
                        soid: vm.orderId,
                        plid: vm.plid,
                        returnUrl: vm.store.orderWay == 'orderThenEditor'?'':(vm.returnUrl || window.location.href)
                    });
                } else if (vm.editorType == "PICTURE") {
                    $state.go("editorPhotoWeb", {
                        storeId: vm.storeId,
                        pid: vm.pid,
                        vid: vm.vid,
                        xmlId: vm.productXmlLine.id,
                        soid: vm.orderId,
                        plid: vm.plid,
                        returnUrl: vm.store.orderWay == 'orderThenEditor'?'':(vm.returnUrl || window.location.href)
                    });
                } else {
                    $state.go("page-my-suffix-edit", {
                        storeId: vm.storeId,
                        pid: vm.pid,
                        vid: vm.vid,
                        xmlId: vm.productXmlLine.id,
                        soid: vm.orderId,
                        plid: vm.plid,
                        returnUrl: vm.store.orderWay == 'orderThenEditor'?'':(vm.returnUrl || window.location.href)
                    });
                }
            }

            $uibModalInstance.close();
        };

        //swiper
        function swiper() {
            var swiper = new Swiper('.swiper-container', {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
    }
})();
