(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('PayCodeController', PayCodeController);

    PayCodeController.$inject = ['$state', 'Coupon', '$scope', 'paginationConstants', 'pagingParams','$stateParams','Customer','$q', '$localStorage',
    'PayCodes', 'DateUtils', '$timeout', 'MessageService', 'ItemValueLinePackage', 'ListService', 'Product', 'GiftVoucher', 'StoreConfig', 'ExchangeCode'];

    function PayCodeController($state, Coupon, $scope, paginationConstants, pagingParams, $stateParams, Customer, $q, $localStorage,
     PayCodes, DateUtils, $timeout, MessageService, ItemValueLinePackage, ListService, Product, GiftVoucher, StoreConfig, ExchangeCode) {

        var vm = this;
        vm.storeId = $stateParams.storeId || $state.params.storeId;
        vm.status = $stateParams.status || 'payCode';
        if (!vm.storeId) {
            return;
        }
        vm.placeholder = "请输入";
        vm.today = DateUtils.convertLocalDateToServer(new Date());
        vm.store = $localStorage.store;
        vm.user = Customer.current({});
        vm.couponConfig =  StoreConfig.byStoreIdAndType2({type:'COUPON', storeId: vm.storeId});

        function loadAll() {
            //支付码
            vm.unusePayCodeList = [];
            vm.usedPayCodeList = [];
            vm.outPayCodeList = [];
            //优惠券
            vm.unuseCoupons = [];
            vm.usedCoupons = [];
            vm.outCoupons = [];
            //礼物券
            vm.unuseGiftVouchers = [];
            vm.usedGiftVouchers = [];
            vm.outGiftVouchers = [];
            vm.allCoupons =  Coupon.getAllByCondition({items:[]});
            vm.allGiftVouchers = GiftVoucher.getAllByCondition3({items:[]});
            vm.allPayCodes = PayCodes.getAllByCondition3({items:[{key:"usedUserId", op:"=", value:vm.user.id}]});
            $q.all([vm.user.$promise, vm.allGiftVouchers.$promise, vm.allPayCodes.$promise, vm.allCoupons.$promise]).then(function(){
                vm.page = 0;
                // if (!vm.statust &&vm.couponConfig &&vm.couponConfig.open) {
                //     vm.status = "coupon";
                // }
                // if (vm.store.payType.indexOf('giftVoucher')>=0) {
                //     vm.placeholder = vm.placeholder +"兑换码";
                //     vm.status = "giftVoucher"
                // }
                // if (vm.store.payType.indexOf('paycode')>=0) {
                //     vm.placeholder = vm.placeholder +"支付码";
                //     vm.status = "payCode";
                // }

                //礼物券
                for (var i = vm.allGiftVouchers.length - 1; i >= 0; i--) {
                    var giftVoucher = vm.allGiftVouchers[i];
                    if (giftVoucher.used) {
                        vm.usedGiftVouchers.push(giftVoucher);
                    }
                    if (!giftVoucher.used && giftVoucher.periodOfValidity>= vm.today) {
                        vm.unuseGiftVouchers.push(giftVoucher);
                    }
                    if (!giftVoucher.used && giftVoucher.periodOfValidity < vm.today) {
                        vm.outGiftVouchers.push(giftVoucher);
                    }
                }

                vm.giftVouchers = [];
                if (vm.unuseGiftVouchers.length> 0) {
                    vm.giftVouchers = vm.giftVouchers.concat(vm.unuseGiftVouchers);
                }

                if (vm.usedGiftVouchers.length> 0) {
                    vm.giftVouchers = vm.giftVouchers.concat(vm.usedGiftVouchers);
                }
                if (vm.outGiftVouchers.length> 0) {
                    vm.giftVouchers = vm.giftVouchers.concat(vm.outGiftVouchers);
                }


                //优惠券
                for (var i = vm.allCoupons.length - 1; i >= 0; i--) {
                    var coupon = vm.allCoupons[i];
                    if (coupon.used) {
                        vm.usedCoupons.push(coupon);
                    }
                    if (!coupon.used && coupon.endTime>= vm.today) {
                        vm.unuseCoupons.push(coupon);
                    }
                    if (!coupon.used && coupon.endTime < vm.today) {
                        vm.outCoupons.push(coupon);
                    }
                }

                vm.coupons = [];
                if (vm.unuseCoupons.length> 0) {
                    vm.coupons = vm.coupons.concat(vm.unuseCoupons);
                }

                if (vm.usedCoupons.length> 0) {
                    vm.coupons = vm.coupons.concat(vm.usedCoupons);
                }
                if (vm.outCoupons.length> 0) {
                    vm.coupons = vm.coupons.concat(vm.outCoupons);
                }

                //支付码
                for (var i = vm.allPayCodes.length - 1; i >= 0; i--) {
                    var p = vm.allPayCodes[i];
                    if (p.used) {
                        vm.usedPayCodeList.push(p);
                    }
                    if (!p.used && p.periodOfValidity>= vm.today) {
                        vm.unusePayCodeList.push(p);
                    }
                    if (!p.used && p.periodOfValidity < vm.today) {
                        vm.outPayCodeList.push(p);
                    }
                }

                vm.payCodes = [];
                if (vm.unusePayCodeList.length> 0) {
                    vm.payCodes = vm.payCodes.concat(vm.unusePayCodeList);
                }

                if (vm.usedPayCodeList.length> 0) {
                    vm.payCodes = vm.payCodes.concat(vm.usedPayCodeList);
                }
                if (vm.outPayCodeList.length> 0) {
                    vm.payCodes = vm.payCodes.concat(vm.outPayCodeList);
                }

                if (vm.status == 'payCode' && vm.payCodes) {
                    paging(vm.payCodes, 15);
                }
                if (vm.status == 'coupon'&& vm.coupons) {
                    paging(vm.coupons, 15);
                }
                if (vm.status == 'giftVoucher'&& vm.giftVouchers) {
                    paging(vm.giftVouchers, 15);
                }
            });
        }

        $q.all([vm.user.$promise]).then(function(){
            loadAll();
        })

        $scope.statusSelected = function (status) {
            if (vm.status == status) {
                return;
            }
            vm.status = status;
            vm.page = 0;
            if (vm.status == 'payCode' && vm.payCodes) {
                paging(vm.payCodes, 15);
            }
            if (vm.status == 'coupon'&& vm.coupons) {
                paging(vm.coupons, 15);
            }
            if (vm.status == 'giftVoucher'&& vm.giftVouchers) {
                paging(vm.giftVouchers, 15);
            }
        };

        $scope.bind = function(){
            vm.code = trim(vm.code);
            if(vm.code == '' || vm.code == undefined){
                MessageService.error("输入不能为空");
            }else if(vm.code.length < 6  || vm.code.length > 10  ){
                MessageService.error("请输入6~10位码");
            }else{
                if (vm.code.length == 10) {
                    ExchangeCode.toGiftVoucher({code:vm.code}, function(data){
                        if (data.status == 200) {
                            MessageService.success("兑换成功");
                            loadAll();
                            vm.status = 'giftVoucher';
                        }else{
                            MessageService.error(data.message);
                        }
                    },function(data){
                        MessageService.error("兑换失败，请稍后再试");
                    })
                }else{
                    PayCodes.bindUser({code: vm.code}, function (msg) {
                        if (msg.status == 200) {
                            MessageService.success("绑定成功");
                            loadAll();
                            vm.status = 'payCode';
                        }else{
                            MessageService.error(msg.message);
                        }
                    },function(){
                        MessageService.error("绑定失败，请稍后再试");
                    });
                }
            }
        }

        function trim(s){
            if (s == null || s =="") {
                return s;
            }
            var reg = new RegExp(" ","g");
            return s.replace(reg,"");
        }

        //支付码
        $scope.gotoProductsByPayCode = function (payCode) {
            if (payCode.cancelled || payCode.used || payCode.periodOfValidity < DateUtils.convertLocalDateToServer(new Date()) || payCode.startDate > DateUtils.convertLocalDateToServer(new Date())) {
                return;
            }
            if (payCode.range == 'Store') {
                //首页
                $state.go('commodity', {storeId: vm.storeId});
                return;
            }else  if (payCode.range == 'Product') {
                //商品筛选也
                var product = {id:payCode.productId, name:payCode.productName}
                localStorage.setItem('templates', JSON.stringify([product]));
                $state.go('pay-code-production', {storeId: vm.storeId, status:vm.status});
                return;
            }else {
                //商品筛选也
                if (payCode.payBatch && payCode.payBatch.purchaseType == 'COMMODITYPURCHASE') {
                    //商品筛选也
                    var idsStr = payCode.payBatch.productId;
                    var items = [{key: "storeId", op: "=", value: vm.storeId}, {key:"protoProduct.id", op:"in", value:idsStr}];
                    Product.getAllByCondition2({items: items }, function(data){
                        var  products = []
                        if (data &&data.length>0) {
                            for (var j = data.length - 1; j >= 0; j--) {
                                var product = data[j];
                                products.push({id:product.id, name:product.name});
                            }
                            localStorage.setItem('templates', JSON.stringify(products));
                            $state.go('pay-code-production', {storeId: vm.storeId, status:vm.status});
                            return;
                        }
                    }, function(){
                        MessageService.error("数据获取失败,请稍后再试")
                    });
                }
                if (payCode.payBatch && payCode.payBatch.purchaseType == 'GIFTPURCHASE') {
                    //商品筛选也
                    ItemValueLinePackage.get({id:payCode.packageId}, function(data){
                        if (data && data.itemValueLines && data.itemValueLines.length>0) {
                            var products = [];
                            for (var i = data.itemValueLines.length - 1; i >= 0; i--) {
                                var line = data.itemValueLines[i];
                                var p = {id:line.productId};
                                var result = ListService.inList(p, products, ["id"]);
                                if (!result) {
                                    products.push(p);
                                }
                            }
                            localStorage.setItem('templates', JSON.stringify(products));
                            $state.go('pay-code-production', {storeId: vm.storeId, status:vm.status});
                        }
                    }, function(){
                        MessageService.error("数据获取失败,请稍后再试")
                    })
                }
            }
        }

        vm.payCodeStatus = false;
        $scope.showPayCodeDetail = function (payCode) {
            vm.payCodeItem = angular.copy(payCode);
            vm.payCodeStatus = true;
        }

        $scope.gotoProductsByGiftVoucher = function (giftVoucher) {
            if (!giftVoucher.used && giftVoucher.periodOfValidity >= vm.today && giftVoucher.startDate <= vm.today) {
                var product = {id:giftVoucher.productId, name:giftVoucher.productName}
                localStorage.setItem('templates', JSON.stringify([product]));
                $state.go('pay-code-production', {storeId: vm.storeId, status:vm.status});
            }
        }

        $scope.gotoProductsByCoup = function (coup) {
            if (!coup.used && coup.endTime >= vm.today) {
                localStorage.setItem('templates', JSON.stringify(coup.templates));
                $state.go('pay-code-production', {storeId: vm.storeId, status:vm.status});
            }
        }

        function paging(data, nums) {
            // var nums = 15; //每页出现的数量
            var pages = Math.ceil(data.length/nums); //得到总页数
            var thisDate = function(curr){
                //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                var str = [], last = curr*nums - 1;
                last = last >= data.length ? (data.length-1) : last;
                for(var i = (curr*nums - nums); i <= last; i++){
                    str.push(data[i]);
                }
                return str;
            };

            //调用分页
            laypage({
                cont: 'biuuu_city',
                curr: vm.page, //初始化当前页
                pages: pages,
                skip: true, //是否开启跳页
                first: false,
                last: false,
                jump: function(obj){
                    $timeout(function () {
                        vm.datas=thisDate(obj.curr);
                        vm.page = obj.curr;
                    })
                }
            });
        }
    }
})();
