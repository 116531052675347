(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CellSettingsModalController', CellSettingsModalController);

    CellSettingsModalController.$inject = ['$timeout', '$scope', 'MessageService', '$uibModalInstance', 'msg'];

    function CellSettingsModalController ($timeout, $scope, MessageService, $uibModalInstance, msg) {
        var vm = this;

        vm.data = {
            obj: msg.obj || {type: '', value: null},
            layout: msg.layout,
            settings: buildSettings(msg.settings || {}, msg.layout),
            name: msg.name
        };
        vm.clear = clear;
        vm.save = save;

        function clear () {
            console.log('clear')
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            console.log('save')
            $uibModalInstance.close(vm.data);
        }

        function buildSettings(intial, layout) {
            var settings = angular.copy(intial);
            if (!settings.items) {
                settings.items = [];
            }

            if (layout || layout.items && layout.items.length) {
                for (var i = 0; i < layout.items.length; i++) {
                    var ti = layout.items[i];
                    if (!ti.name) {
                        continue;
                    }

                    var found = null;
                    for (var j = 0; j < settings.items.length; j++) {
                        var sj = settings.items[j];
                        if (ti.name == sj.name) {
                            found = sj;
                            break;
                        }
                    }

                    if (!found) {
                        var item = {
                            name: ti.name,
                            type: ti.type,
                            textSize: ti.textSize,
                            textFont: ti.textFont,
                            textColor: ti.textColor,
                            textAlign: ti.textAlign || 'center',
                            textBold: ti.textWeight && (ti.textWeight >= 500 || ti.textWeight == 'bold'),
                            textItalic: undefined
                        };
                        settings.items.push(item);
                    }
                }
            }
            if (!settings.border) {
                settings.border = {
                    lineType: 'solid',
                    lineWidth: 0,
                    color: '#000000',
                    tlRadius: 0,
                    trRadius: 0,
                    blRadius: 0,
                    brRadius: 0
                };
            }
            settings.showOthers = true;
            return settings;
        }

        $scope.onSettingsChange = function (text) {
            console.log(text);
            var data = JSON.parse(text);
            if (data) {
                vm.data.settings = data;

                $timeout(function() {
                    $scope.$apply();
                }, 100);

            }
        }

    }
})();
