(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(localStorageConfig);

    localStorageConfig.$inject = ['$localStorageProvider', '$sessionStorageProvider'];

    function localStorageConfig($localStorageProvider, $sessionStorageProvider) {
        function getCookie (name) {
            if (document.cookie.length > 0) {
                var begin = document.cookie.indexOf(name + '=')
                if (begin !== -1) {
                    begin += name.length + 1 // cookie值的初始位置
                    var end = document.cookie.indexOf(';', begin) // 结束位置
                    if (end === -1) {
                        end = document.cookie.length // 没有;则end为字符串结束位置
                    }
                    return unescape(document.cookie.substring(begin, end))
                }
            }
            return null
        }
        var storeId = getCookie("store-id");
        if (storeId == null || storeId=="") {
            var hash = location.hash.substring(2);
            var index = hash.indexOf("/");
            storeId = index>0?hash.substring(0,index):hash;
            var reg = /^[0-9]*$/;
            var pattern = new RegExp(reg);
            if (!pattern.test(storeId)) {
                return;
            }
        }
        console.log("localStorageConfig.storeId:"+storeId);
        $localStorageProvider.setKeyPrefix('editor-'+ storeId+"-");
        $sessionStorageProvider.setKeyPrefix('editor-'+ storeId+"-");
    }
})();
