(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('AfterSaleController', AfterSaleController);

    AfterSaleController.$inject = ['$q', '$state', '$scope', '$stateParams', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', 'Qinius', 
    'AfterSale', 'SalesOrder', '$localStorage'];

    function AfterSaleController($q, $state, $scope, $stateParams, ParseLinks, MessageService, paginationConstants, pagingParams, Qinius, AfterSale, 
         SalesOrder, $localStorage) {
        var vm = this;
        vm.contents = [];

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.currentSearch = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.changeType = changeType;
        vm.search = search;
        vm.pageType = 'all';
        vm.afterSales = [];
        // vm.itemsPerPage = 5;
        vm.page = 1;

        vm.storeId = $stateParams.storeId || $state.params.storeId;
        if (!vm.storeId) {
            return;
        }

        AfterSale.getSomeTypeByStoreAndUser({id: vm.storeId}, function(data) {
            vm.allNum = data.allNum;
            vm.treatNum = data.treatNum;
            vm.finishNum = data.finishNum;
            vm.untreatedNum = data.untreatedNum;
        });


        $scope.requireRefund = function(order){
            MessageService.confirm({
                title:'提示',
                cancel:'取消',
                confirm:'确认',
                msg:'确认要申请订单退款？'
            },function(){
                SalesOrder.requireRefund({id:order.id}, function(){
                    MessageService.success("申请已提交后台审批，请耐心等待");
                }, function(){
                    MessageService.error("申请失败，请联系稍后再试");
                })
            })
        }

        function changeType(str) {
            vm.pageType = str;
            vm.afterSales = [];
            loadAll();
        }

        function search() {
            vm.afterSales = [];
            loadAll();
        }

        loadAll();

        // 页面滚动，加载数据
        $('.home-body').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(vm.afterSales.length < vm.totalItems){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.page++;
                    loadAll()
                }
            }
        });

        $scope.domain = $localStorage.domain;
        if ($scope.domain == null  || $scope.domain == "") {
            Qinius.getDomain1(function(da){
                $scope.domain = 'https://' + da.domains[0] + '/';
            });
        }
        
        function loadAll() {
            var items = [{key: "storeId", op: "=", value: vm.storeId}];
            if (vm.currentSearch) {
                items.push({key: "salesOrder.number,salesOrder.locations.contact,salesOrder.locations.phone", op: "like", value: vm.currentSearch});
            }
            if (vm.pageType == 'untreated') {
                items.push({key: "statue", op: "in", value: 'Untreated'});
            }  
            if (vm.pageType == 'treated') {
                items.push({key: "statue", op: "in", value: 'Treated;NoTreated'});
            }  
            if (vm.pageType == 'Finish') {
                items.push({key: "statue", op: "=", value: 'Finish'});
            } 
            AfterSale.byCondition2({
                items: items, 
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["lastModifiedDate,desc"]
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                // vm.afterSales = data;
                data.forEach(function(g) {
                    vm.afterSales.push(g)
                })
                vm.page = pagingParams.page;
            }

            function onError(error) {
                MessageService.error("数据加载出错");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                storeId: vm.storeId,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();