(function() {
    'use strict';

    angular
        .module('editorApp')
        .factory('Editors', Editors);

    Editors.$inject = ['$state', 'ItemValue'];

    function Editors ($state, ItemValue) {

        var service = {
            navigateTo: navigateTo,
            navigateSelect: navigateSelect,
            belongToEditorAll:belongToEditorAll
        };

        return service;

        //判断是否属于万物编辑
        function belongToEditorAll (editorType) {
            if (!editorType) {
                console.log("can't belongToEditorAll !!!!, editorType is null");
                return false;
            }
            if (editorType == 'POSTER' || editorType == 'SIMPLEST' || editorType == 'DIY_EDITOR' || editorType == 'FORM_EDITOR') {
                return true;
            } else {
                return false;
            }
        }

        /*
        *   导航到编辑器
        *   storeId - 商户id - 必需
        *   options:
        *       editorType - 编辑器类型 - 必需
        *       pid - 产品ID - 必需
        *       vid - skuID - 必需
        *       xmlId - 模版ID - 必需
        *       soId - 订单ID - 可选
        *       plId - productLineId - 可选
        */
        function navigateTo (storeId, options) {
            if (!storeId || !options || !options.pid || !options.vid || !options.xmlId) {
                console.log("can't navigate to editor!!!!, storeId=" + storeId + ", options:", options);
                return;
            }
            var editorType = options.editorType || '';
            if (editorType == 'PICTURE') {
                $state.go("editorPhotoWeb", {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, soid: options.soId, plid: options.plId, returnUrl: window.location.href});
            } else if (belongToEditorAll(editorType)) {
                $state.go("editorAll", {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, soid: options.soId, plid: options.plId, returnUrl: window.location.href});
            } else {
                $state.go("page-my-suffix-edit", {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: options.xmlId, soid: options.soId, plid: options.plId, returnUrl: window.location.href});
            }
        }

        /*
        *   导航到模版选择或者编辑器
        *   storeId - 商户id - 必需
        *   options:
        *       editorType - 编辑器类型 - 可选
        *       pid - 产品ID - 必需
        *       vid - skuID - 必需
        *       xmlId - 模版ID - 必需
        *       soId - 订单ID - 可选
        *       plId - productLineId - 可选
        *       selectState - 选择器 - 必需
        */
        function navigateSelect(storeId, options) {
            if (!storeId || !options || !options.pid || !options.vid) {
                console.log("can't navigate to editor!!!!, storeId=" + storeId + ", options=" + options);
                return;
            }
            var vm = this;

            var selectState = options.selectState || 'productXmlSelect';
            ItemValue.get({id: options.vid}, function(res) {
                var itemValue = res;

                var xmlCount = 0;
                var xml = null;
                if (itemValue.productXmlLines && itemValue.productXmlLines.length) {
                    for (var i = 0; i < itemValue.productXmlLines.length; i++) {
                        var line = itemValue.productXmlLines[i];
                        if (!line.cancelled) {
                            xmlCount++;
                            xml = line;
                        }
                    }
                    if (xmlCount == 1 && xml) {
                        var editorType = itemValue.productEditorType || options.editorType;
                        vm.navigateTo(storeId, {editorType: editorType, pid: options.pid, vid: options.vid, xmlId: xml.id, soId: options.soId, plId: options.plId});
                        return;
                    }
                }
                if (selectState == 'productXmlSelect') {
                    $state.go(selectState, {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: undefined,
                        orderId: options.soId, plid: options.plId,from:'order'});
                }else{
                    $state.go(selectState, {storeId: storeId, pid: options.pid, vid: options.vid, xmlId: undefined,
                        orderId: options.soId, plid: options.plId});
                }
            });
        }
    }
})();
