(function () {
    'use strict';

    var articlePreviewWeb = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/page/article-preview-web.html',
        controllerAs: 'vm',
        bindings: {
            page: '=',
            previewArticle: '=',
            editorType: '=', //这里为什么传了editorType 和bindingtype 因为插件内没有去查article了 文档是直接从外面传进来的
            bindingtype :'=',
            source :'='
        },

        controller: ['$scope', '$stateParams', '$q', 'Qinius', 'Store', '$http', '$rootScope', 'Article', 'FontManagement', '$state', '$timeout', "Editors", 'MessageService',
            function ($scope, $stateParams, $q, Qinius, Store, $http, $rootScope, Article, FontManagement, $state, $timeout, Editors, MessageService) {
                var vm = this;
                $(".navbar").hide();
                $(".wrap").addClass('no-margins').css({height: '100vh', background: '#fff'});
                vm.counts = 0;
                console.log($stateParams)
                vm.storeId = $stateParams.storeId;
                vm.aid = $stateParams.aid;
                vm.thisPage = 0;

                $scope.isRandomView = false;
                $scope.viewPage = false;
                $scope.viewTypes = ['默认', '斜插式', '横插式', '翻阅式', '下降式', '心跳式（快）', '心跳式（慢）', '摩天轮', '跳跃式', '旋转式', '下缩式', '缩放式', '上移式',
                    '翻转式', '顺滑式', '倒滑式', '滑行式', '轮播式', '扔除式', '左滑式', '右滑式', '左右滑式', '变换式', '挤兑式', '翻转式', '左右轮滑式',
                    '淡出式', '上推式', '下推式', '抖动式']

                var $li = $(".templateView-carousel");
                var returnurl = localStorage.getItem('returnurl');
                var $loading = $(".loading-page2");
                if (vm.page == 1) {
                    $(".save-page").hide();
                } else {
                    $loading.hide();
                }
                var unitTransform = function (mm) {
                    // mm换算成px  Math.ceil(mm / 25.4 * 300);
                    // return (mm * 3) - 0;
                    return mm / 25.4 * vm.windowDpiW;
                };

                js_getDPI()

                function js_getDPI() {
                    var arrDPI = new Array();
                    if (window.screen.deviceXDPI != undefined) {
                        arrDPI[0] = window.screen.deviceXDPI;
                        arrDPI[1] = window.screen.deviceYDPI;
                    } else {
                        var tmpNode = document.createElement("DIV");
                        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                        document.body.appendChild(tmpNode);
                        arrDPI[0] = parseInt(tmpNode.offsetWidth);
                        arrDPI[1] = parseInt(tmpNode.offsetHeight);
                        tmpNode.parentNode.removeChild(tmpNode);
                    }
                    vm.windowDpiW = arrDPI[0];
                    vm.windowDpiH = arrDPI[1];
                    vm.windowDpi = arrDPI[1];
                    console.log(arrDPI)
                    return arrDPI;
                }

                vm.fontuuids = [];
                $scope.k = 0;
                $scope.isText = false;

                var allWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                $scope.dHeight = vm.dHeight = ((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)) / 1.5;
                $scope.dWidth = vm.dWidth = allWidth / 1.5;
                $scope.template = {
                    pages: {
                        page: [{}]
                    }
                };
                $scope.document = {
                    pages: [{}]
                };
                $scope.len = 0;

                $scope.domains = Qinius.getDomain1();
                $(".modal-content").addClass('opacity0');
                vm.store = Store.get({id: vm.storeId});
                $scope.isPull = true;
                $q.all([$scope.domains.$promise, vm.store.$promise]).then(function () {
                    $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                    var doc = vm.previewArticle;
                    if(doc.pages.page && doc.pages.page.length>0){
                        if(doc.pages.page[0].spread && vm.bindingtype == 'Left'){
                            doc.pages.page[0].type = "FrontBack";
                        }
                        if(doc.pages.page[0].type == "FrontBack"){
                            doc.pages.page[0].spread = false;
                            doc.pages.page[0].trimbox.width = doc.pages.page[0].trimbox.width/2;
                            if (vm.bindingtype == 'Left'){
                                var _page0 = angular.copy(doc.pages.page[0]);
                                doc.pages.page.push(_page0)
                            }
                        }
                    }
                    editorInit1(doc)

                    if (Editors.belongToEditorAll(vm.editorType)) {
                        vm.isPoster = true;
                    } else {
                        vm.isPoster = false;
                        editorInit(doc)
                    }
                });

                function editorInit(doc) {

                    angular.forEach(doc.pages.page, function (page) {
                        if (page.backup) {
                            return
                        }
                        $scope.len++;
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.geometry) {
                                vm.counts++;
                            }
                        })
                    });
                    //取得xml文档内容
                    if (vm.bindingtype == 'Left2') {
                        $scope.isPull = false;
                        vm.bindingtype = 'Left';
                    }
                    if (vm.bindingtype == 'Top') {
                        $scope.dHeight = vm.dHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 100;
                    }
                    localStorage.setItem('bindingtype', vm.bindingtype);
                    $scope.maxW = doc.pages.page[0].trimbox.width;
                    $scope.minW = doc.pages.page[0].trimbox.width;
                    $scope.maxH = doc.pages.page[0].trimbox.height;
                    $scope.minH = doc.pages.page[0].trimbox.height;
                    $(".modal-content").addClass('opacity0');
                    var arrUse = {
                        pages: {
                            page: []
                        }
                    };
                    angular.forEach(doc.pages.page, function (page) {
                        if (!page.backup) {
                            arrUse.pages.page.push(page);
                        }
                        page.blood={
                            left:page.trimbox.x,
                            top:page.trimbox.y,
                            right:page.mediabox.width - page.trimbox.width-page.trimbox.x,
                            bottom:page.mediabox.height - page.trimbox.height - page.trimbox.y,
                        }
                    });
                    $scope.getDetail(arrUse, 0);
                }

                function editorInit1(doc) {
                    vm.windowH = 960;
                    vm.windowW = 960;
                    vm.windowW1 = 140;
                    vm.windowH1 = 140;
                    $scope.template1 = angular.copy(doc);
                    $scope.template1.pages.page=[];
                    doc.pages.page.forEach(function (page) {
                        if(!page.backup){
                            $scope.template1.pages.page.push(angular.copy(page));
                        }
                    })
                    angular.forEach($scope.template1.pages.page, function (page, index) {
                        page.custom = {};
                        if (page.scene) {
                            if (vm.windowH / page.scene.geometry.height >= vm.windowW / page.scene.geometry.width) {
                                page.custom.ratio = vm.windowW / page.scene.geometry.width * 0.8;
                                page.custom.ratio2 = vm.windowW1 / page.scene.geometry.width * 0.8;
                            } else {
                                page.custom.ratio = vm.windowH / page.scene.geometry.height * 0.8;
                                page.custom.ratio2 = vm.windowH1 / page.scene.geometry.height * 0.8;
                            }
                            page.custom.ratio1 = vm.windowW1 / page.scene.geometry.width * 0.8;
                            page.custom.width = page.scene.geometry.width;
                            page.custom.height = page.scene.geometry.height;
                        } else {
                            if (vm.windowH / page.trimbox.height >= vm.windowW / page.trimbox.width) {
                                page.custom.ratio = vm.windowW / page.trimbox.width * 0.8;
                                page.custom.ratio2 = vm.windowW1 / page.mediabox.width * 0.8;
                            } else {
                                page.custom.ratio = vm.windowH / page.trimbox.height * 0.8;
                                page.custom.ratio2 = vm.windowH1 / page.mediabox.height * 0.8;
                            }
                            page.custom.ratio1 = vm.windowW1 / page.trimbox.width * 0.8;
                            page.custom.width = page.mediabox.width;
                            page.custom.height = page.mediabox.height;
                        }
                    })
                    $scope.template = angular.copy($scope.template1);
                    showPages();
                }

                function transitionPx(item) {
                    return item * vm.windowDpi / 25.4;
                }

                $scope.edit = function () {
                    if (vm.isPoster) {
                        $state.go('editorAllEdit', {storeId: vm.storeId, aid: vm.aid})
                    } else {
                        $state.go('page-my-suffix-remake', {storeId: vm.storeId, aid: vm.aid})
                    }
                }

                $scope.getDetail = function (document, index) {
                    var obj = document.pages.page[index];

                    $(".carousel-wrapper").height(document.pages.page[0].trimbox.height * (document.pages.page.length + 1.7));

                    $scope.template = document;
                    var bgIdentifier = null;
                    if (obj.background && obj.background.resource && obj.background.resource.identifier) {
                        bgIdentifier = obj.background.resource.identifier;
                    }
                    var isMaxWidth = getScale($scope.dWidth, $scope.dHeight, obj.trimbox.width, obj.trimbox.height, index);

                    $scope.template.pages.page[index] = {
                        background: angular.copy(obj.background),
                        bgImg: bgIdentifier,
                        blood: angular.copy(obj.blood),
                        barcode: angular.copy(obj.barcode),
                        barcodeM: angular.copy(obj.barcode),
                        qrcode: angular.copy(obj.qrcode),
                        qrcodeM: angular.copy(obj.qrcode),
                        barcodeRotation: {
                            angle: obj.barcodeRotation ? obj.barcodeRotation.angle : 0
                        },
                        mediabox: angular.copy(obj.mediabox),
                        trimbox: angular.copy(obj.trimbox),
                        trimboxPx: {},
                        levels: angular.copy(obj.levels),
                        isMaxWidth: isMaxWidth,
                        spread: obj.spread,
                        type: obj.type,
                        centerrotation: obj.centerrotation ? true : false,
                        'thumbnailH': isMaxWidth ? $scope.dWidth * 0.8 * unitTransform(obj.trimbox.height) / unitTransform(obj.trimbox.width) : $scope.dHeight * 0.7 * unitTransform(obj.trimbox.width) / unitTransform(obj.trimbox.height),
                        pageScaleH: 1,
                        pageScaleW: 1
                    };

                    // 无翻页效果
                    if (document.pages.page.length < 2 || vm.bindingtype == 'None') {
                        $scope.template.pages.page[index].styleScale = (vm.dWidth * 0.25 / (unitTransform(obj.trimbox.width)));
                    } else {
                        $scope.template.pages.page[index].styleScale = 1;
                    }

                    $scope.template.pages.page[index].mediabox= {
                        height: unitTransform(obj.mediabox.height) * $scope.template.pages.page[index].styleScale,
                        width: unitTransform(obj.mediabox.width) * $scope.template.pages.page[index].styleScale,
                        x: unitTransform(obj.mediabox.x) * $scope.template.pages.page[index].styleScale,
                        y: unitTransform(obj.mediabox.y) * $scope.template.pages.page[index].styleScale
                    }

                    // 得到压缩比例后的最高page的高度
                    if ($scope.maxPageHeight < $scope.template.pages.page[index].thumbnailH) {
                        $scope.maxPageHeight = $scope.template.pages.page[index].thumbnailH;
                    }
                    if (obj.barcode) {
                        $scope.template.pages.page[index].barcode.geometry = getGeometry(obj.barcode.geometry, index);
                    }
                    if (obj.qrcode) {
                        $scope.template.pages.page[index].qrcode.geometry = getGeometry(obj.qrcode.geometry, index);
                    }
                    $scope.template.pages.page[index].trimboxPx = getGeometry(obj.trimbox, index)
                    $scope.template.pages.page[index].ratio = $scope.template.pages.page[index].trimboxPx.width / ($scope.template.pages.page[index].trimbox.width / 25.4 * vm.windowDpiW)

                    var background = $scope.template.pages.page[index].background;
                    var w = $scope.template.pages.page[index].trimboxPx.width,
                        h = $scope.template.pages.page[index].trimboxPx.height;

                    if (background && background.resource) {
                        getBackgroundSource(background.resource, w, h, background)
                        var bloodLeft = $scope.template.pages.page[index].blood.left;
                        var bloodTop = $scope.template.pages.page[index].blood.top;
                        background.offsetx = unitTransform(background.offsetx -bloodLeft) * $scope.template.pages.page[index].styleScale
                        background.offsety = unitTransform(background.offsety-bloodTop) * $scope.template.pages.page[index].styleScale
                        background.offsetXM = angular.copy(background.offsetx);
                        background.offsetYM = angular.copy(background.offsety);
                    }

                    angular.forEach(obj.levels.level, function (level, l) {
                        var pageLevel = $scope.template.pages.page[index].levels.level[l];
                        if (level.imageart && level.imageart.geometry) {
                            var art = level.imageart;
                            pageLevel.imageart = {
                                geometry: getGeometry(art.geometry, index),
                                rotation: {
                                    angle: -art.rotation.angle
                                },
                                type: art.type,
                                resource: {
                                    identifier: art.resource.identifier,
                                    provider: art.resource.provider
                                },
                                scale: art.scale,
                                widthM: art.geometry.width,
                                heightM: art.geometry.height,
                                width: art.width,
                                height: art.height,
                                XM: art.geometry.x,
                                YM: art.geometry.y,
                                offsetXM: angular.copy(art.offsetx),
                                offsetYM: angular.copy(art.offsety),
                                offsetx: unitTransform(art.offsetx) * $scope.template.pages.page[index].styleScale,
                                offsety: unitTransform(art.offsety) * $scope.template.pages.page[index].styleScale,
                                current: 'imageart',
                                levelI: l,
                                pageI: index,
                                transparency: art.transparency,
                                onlyshow: art.onlyshow,
                                movable: art.movable,
                                editable: art.editable,
                                flip: art.flip,
                            }
                            if(!pageLevel.imageart.width && !pageLevel.imageart.height){
                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                    pageLevel.imageart.width = data.width;
                                    pageLevel.imageart.height = data.height;
                                }).error(function (error) {
                                });
                            }
                        }
                        if (level.textbox && level.textbox.geometry) {
                            var text = level.textbox;
                            pageLevel.textbox = {
                                geometry: getGeometry(text.geometry, index),
                                identifier: text.identifier,
                                style: angular.copy(text.style),
                                rotation: {
                                    angle: -text.rotation.angle
                                },
                                text: text.text,
                                heightM: text.geometry.height,
                                widthM: text.geometry.width,
                                xM: text.geometry.x,
                                yM: text.geometry.y,
                                styles: 'NORMAL',
                                type: text.type,
                                status: text.status,
                                templateType: vm.edit ? text.type : '',
                                levelI: l,
                                transparency: text.transparency,
                                lineheight: text.style.lineheight,
                                letterspacing: text.style.letterspacing,
                                movable: text.movable,
                                editable: text.editable,
                                fontuuid: text.fontuuid,
                                version: text.version
                            };
                            if (text.fontuuid) {
                                //下载字体
                                if (vm.fontuuids.indexOf(text.fontuuid) == -1) {
                                    vm.fontuuids.push(text.fontuuid);
                                    getFontCSS(text.fontuuid)
                                }
                            }
                            var tempText = pageLevel.textbox;
                            if (tempText.style.bold && tempText.style.italic) {
                                tempText.styles = 'BOLD_ITALIC';
                            } else if (tempText.style.bold) {
                                tempText.styles = 'BOLD';
                            } else if (tempText.style.italic) {
                                tempText.styles = 'ITALIC';
                            } else {
                                tempText.styles = 'NORMAL';
                            }
                        }
                        if (level.imagebox && level.imagebox.geometry) {
                            var img = level.imagebox;
                            pageLevel.imagebox = {
                                geometry: getGeometry(img.geometry, index),
                                mask: angular.copy(img.mask),
                                imgUrl: 'content/images/mask_souce.png',
                                maskUrl: 'content/images/mask_souce.png',
                                transparency: isNaN(img.transparency) ? 1 : img.transparency,
                                rotation: {
                                    angle: -img.rotation.angle
                                },
                                scaleW: 1,
                                scaleH: 1,
                                bokehtype: img.bokehtype,
                                flip: img.flip,
                                image: img.image,
                                border: img.border,
                                shadow: img.shadow
                            };
                            if(pageLevel.imagebox.border){
                                pageLevel.imagebox.border.lineWidth = pageLevel.imagebox.border.lineWidth * $scope.template.pages.page[index].styleScale;
                            }

                            if(img.bokehtype!='None'&&img.blurredrectangle){
                                pageLevel.imagebox.blurredrectangle = {
                                    width: transitionPx(img.blurredrectangle.width) * $scope.template.pages.page[index].styleScale,
                                    height: transitionPx(img.blurredrectangle.height) * $scope.template.pages.page[index].styleScale,
                                    x: transitionPx(img.blurredrectangle.x) * $scope.template.pages.page[index].styleScale,
                                    y: transitionPx(img.blurredrectangle.y) * $scope.template.pages.page[index].styleScale
                                };
                            }

                            if (img.mask.resource.identifier) {
                                pageLevel.imagebox.maskUrl = img.mask.resource.identifier + '?imageMogr2';
                            }


                            if (img.image && img.image.resource && img.image.resource.identifier) {       //qiniu
                                pageLevel.imagebox.image = {
                                    resource: {
                                        identifier: img.image.resource.identifier ? img.image.resource.identifier : '',
                                        provider: 'qiniu'
                                    },
                                    rotation: angular.copy(img.rotation),
                                    parameter: (!img.image) || img.image.parameter == null ? [] : angular.fromJson(img.image.parameter),
                                    filter: (!img.image) || img.image.filter == null ? '' : img.image.filter,
                                    offsetx: img.image.offsetx == 0 ? 0 : (unitTransform(img.image.offsetx) * $scope.template.pages.page[index].styleScale),
                                    offsety: img.image.offsety == 0 ? 0 : (unitTransform(img.image.offsety) * $scope.template.pages.page[index].styleScale),
                                    angle: img.image && img.image.angle ? img.image.angle : 0,
                                    scale: (!img.image) || img.image.scale == 1 ? 1 : img.image.scale,
                                    imageInfo: (!img.image) ? null : img.image.imageInfo,
                                    width: (!img.image) ? null : unitTransform(img.image.width),
                                    height: (!img.image) ? null : unitTransform(img.image.height),
                                }
                                // level.imagebox.image.offsetx = unitTransform(img.image.offsetx) * $scope.template.pages.page[index].styleScale;
                                // level.imagebox.image.offsety = unitTransform(img.image.offsety) * $scope.template.pages.page[index].styleScale;
                                if (img.image.resource.identifier) {
                                    getBackgroundSource1(pageLevel.imagebox.image.resource, pageLevel.imagebox.geometry.width, pageLevel.imagebox.geometry.height, pageLevel.imagebox.image, img.bokehtype)
                                }
                            }
                        }
                        if (level.textdocument && level.textdocument.geometry) {
                            var textdocument = level.textdocument;
                            pageLevel.textdocument = {
                                background: textdocument.background,
                                geometry: getGeometry(textdocument.geometry, index),
                                widthM: textdocument.geometry.width,
                                heightM: textdocument.geometry.height,
                                offsetXM: textdocument.geometry.x,
                                offsetYM: textdocument.geometry.y,
                                rotation: {
                                    angle: textdocument.rotation.angle || 0
                                },
                                current: 'textdocument',
                                levels: angular.copy(textdocument.levels),
                                levelI: l,
                                pageI: index,
                                lock: true,
                                transparency: textdocument.transparency,
                                movable: textdocument.movable,
                                editable: textdocument.editable
                            }

                            var backg = pageLevel.textdocument.background;
                            var pageW = pageLevel.textdocument.geometry.width,
                                pageH = pageLevel.textdocument.geometry.height;
                            if (backg.resource.identifier) {
                                getBackgroundSource(backg.resource, pageW, pageH, backg)
                                backg.offsetx = unitTransform(backg.offsetx) * $scope.template.pages.page[index].styleScale
                                backg.offsety = unitTransform(backg.offsety) * $scope.template.pages.page[index].styleScale
                                backg.offsetXM = angular.copy(level.textdocument.background.offsetx);
                                backg.offsetYM = angular.copy(level.textdocument.background.offsety);
                            }

                            angular.forEach(textdocument.levels.level, function (lev, n) {
                                if (lev.imageart && lev.imageart.geometry) {
                                    var art = lev.imageart;
                                    pageLevel.textdocument.levels.level[n].imageart = {
                                        geometry: getGeometry(art.geometry, index),
                                        rotation: {
                                            angle: -art.rotation.angle
                                        },
                                        type: art.type,
                                        resource: {
                                            identifier: art.resource.identifier,
                                            provider: art.resource.provider
                                        },
                                        scale: art.scale,
                                        widthM: art.geometry.width,
                                        heightM: art.geometry.height,
                                        XM: art.geometry.x,
                                        YM: art.geometry.y,
                                        offsetXM: angular.copy(art.offsetx),
                                        offsetYM: angular.copy(art.offsety),
                                        offsetx: unitTransform(art.offsetx) * $scope.template.pages.page[index].styleScale,
                                        offsety: unitTransform(art.offsety) * $scope.template.pages.page[index].styleScale,
                                        current: 'imageart',
                                        levelI: l,
                                        pageI: index,
                                        lock: false,
                                        transparency: art.transparency,
                                        onlyshow: art.onlyshow,
                                        movable: art.movable,
                                        editable: art.editable,
                                    }
                                    $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                        pageLevel.textdocument.levels.level[n].imageart.width = data.width;
                                        pageLevel.textdocument.levels.level[n].imageart.height = data.height;
                                    }).error(function (error) {
                                    });
                                }
                                if (lev.textbox && lev.textbox.geometry) {
                                    var text = lev.textbox;
                                    pageLevel.textdocument.levels.level[n].textbox = {
                                        geometry: getGeometry(text.geometry, index),
                                        identifier: text.identifier,
                                        rotation: {
                                            angle: -text.rotation.angle
                                        },
                                        angle: angular.copy(text.angle),
                                        status: angular.copy(text.status),
                                        style: angular.copy(text.style),
                                        type: angular.copy(text.type),
                                        text: angular.copy(text.text),
                                        fontStyle: [{
                                            style: '',
                                            path: text.identifier
                                        }],
                                        widthM: text.geometry.width,
                                        heightM: text.geometry.height,
                                        offsetXM: text.geometry.x,
                                        offsetYM: text.geometry.y,
                                        current: 'textbox',
                                        levelI: n,
                                        pageI: index,
                                        lock: true,
                                        transparency: text.transparency,
                                        lineheight: text.style.lineheight,
                                        letterspacing: text.style.letterspacing,
                                        fontuuid: text.fontuuid,
                                        version: text.version
                                    }
                                    if (vm.fontuuids.indexOf(text.fontuuid) == -1) {
                                        vm.fontuuids.push(text.fontuuid);
                                        getFontCSS(text.fontuuid)
                                    }
                                }
                            })
                        }
                    });

                    if (index < document.pages.page.length - 1) {
                        $scope.getDetail(document, index + 1);
                    } else {
                        $scope.isLeftRotation = !$scope.template.pages.page[0].centerrotation;
                        if (vm.counts == 0) {
                            showPages();
                            $(".rightBtn").hide();
                        }
                        // 平铺
                        if (vm.bindingtype == 'None' || $scope.template.pages.page.length < 2) {
                            showPages();
                            $(".rightBtn").hide();
                        } else {
                            // 翻页
                            var arr = $scope.template;
                            angular.forEach(arr.pages.page, function (obj, index) {
                                getArr(arr, obj, index);
                            });
                        }

                        $scope.slide = $scope.template.pages.page[0];
                        itemsCount = $scope.len;
                        changeEffect();
                        effectSel.addEventListener('change', changeEffect);
                    }
                    if (vm.counts == 0) {
                        var arr = angular.copy($scope.template);
                        angular.forEach(arr.pages.page, function (obj, index) {
                            getArr(arr, obj, index);
                        });
                    }
                    firstPageInit();
                };

                function getScale(screenW, screenH, pageW, pageH) {
                    var newH = angular.copy(screenH);
                    if (vm.bindingtype == 'Top' && screenW * 0.7 * pageH / pageW * 2 > screenH) {
                        newH = (newH - 100) / 2;
                    }

                    if (vm.bindingtype == 'Top') {
                        if (screenW / newH < pageW / pageH) {
                            //宽定，高不定
                            $scope.isMaxWidth = true;
                            return true;
                        } else {
                            //高定，宽不定
                            $scope.isMaxWidth = false;
                            return false;
                        }
                    } else {
                        if (screenW / newH > pageW / pageH) {
                            //宽定，高不定
                            $scope.isMaxWidth = true;
                            return true;
                        } else {
                            //高定，宽不定
                            $scope.isMaxWidth = false;
                            return false;
                        }
                    }
                }

                function getBackgroundSource(res, pageW, pageH, arr) {
                    if(arr.imageInfo){
                        getBackgroundSourceCb(arr.imageInfo)
                    }else{
                        $http({url: res.identifier + '?imageInfo'}).success(function (data) {
                            getBackgroundSourceCb(data)
                        })
                    }
                    function getBackgroundSourceCb(imageInfo){
                        var bgW = imageInfo.width,
                            bgH = imageInfo.height;
                        // 高适配
                        var adaptation = 'Height', h = pageH, w = pageH * bgW / bgH;
                        // 宽适配
                        if ((bgW <= bgH || pageW <= pageH) && (bgW / bgH < pageW / pageH) || bgW >= bgH && pageW >= pageH && (bgW / bgH < pageW / pageH)) {
                            w = pageW;
                            h = pageW * bgH / bgW;
                            adaptation = 'Width';
                        }
                        arr.width = w;
                        arr.height = h;
                        arr.type = arr.type;
                        arr.adaptation = adaptation;
                    }
                }

                function getBackgroundSource1(res, pageW, pageH, item, bokehtypes) {
                    if(item.width && item.height){
                        getBackgroundSource1Cb()
                    }else{
                        var newImg = new Image();
                        newImg.crossOrigin = "Anonymous";
                        newImg.src = res.identifier + '?imageMogr2/strip/rotate/' + item.angle + '/thumbnail/!40p';
                        newImg.onload = function () {
                            newImg.onload = null;
                            item.width = newImg.width;
                            item.height = newImg.height;
                            getBackgroundSource1Cb()
                            $scope.$digest();
                        };
                    }
                    function getBackgroundSource1Cb() {
                        var width = item.width;
                        var height = item.height;
                        var imgR = pageW / pageH;
                        var imgR1 = width / height;
                        if (bokehtypes == 'None') {
                            if (imgR >= imgR1) {
                                item.resource.adaptation = 'Height';
                                item.width = pageW;
                                item.height = pageW / width * height;
                            } else {
                                item.resource.adaptation = 'Width';
                                item.width = pageH / height * width;
                                item.height = pageH;
                            }
                        } else {
                            if (imgR >= imgR1) {
                                item.resource.adaptation = 'Height';
                                item.width = pageW;
                                item.height = pageW / width * height;
                                item.offsetx = 0;
                                item.offsety = (pageH - pageW / width * height) / 2;
                            } else {
                                item.resource.adaptation = 'Width';
                                item.width = pageH / height * width;
                                item.height = pageH;
                                item.offsetx = (pageW - pageH / height * width) / 2;
                                item.offsety = 0;
                            }
                        }
                        if (item.scale > 1) {
                            item.width *= item.scale;
                            item.height *= item.scale;
                            item.offsetx *= item.scale;
                            item.offsety *= item.scale;
                        }
                    }
                }

                function getBackgroundSource2(res, pageW, pageH, item, bokehtypes) {
                    var newImg = new Image();
                    newImg.crossOrigin = "Anonymous";
                    newImg.src = res.identifier + '?imageMogr2/strip/rotate/' + item.angle + '/thumbnail/!40p';
                    newImg.onload = function () {
                        var imgR = pageW / pageH;
                        var imgR1 = newImg.width / newImg.height;
                        if (bokehtypes == 'None') {
                            if (imgR >= imgR1) {
                                item.resource.adaptation = 'Height';
                                item.width = pageW;
                                item.height = pageW / newImg.width * newImg.height;
                            } else {
                                item.resource.adaptation = 'Width';
                                item.width = pageH / newImg.height * newImg.width;
                                item.height = pageH;
                            }
                        } else {
                            if (imgR >= imgR1) {
                                item.resource.adaptation = 'Height';
                                item.width = pageW;
                                item.height = pageW / newImg.width * newImg.height;
                                item.offsetx = 0;
                                item.offsety = (pageH - pageW / newImg.width * newImg.height) / 2;
                            } else {
                                item.resource.adaptation = 'Width';
                                item.width = pageH / newImg.height * newImg.width;
                                item.height = pageH;
                                item.offsetx = (pageW - pageH / newImg.height * newImg.width) / 2;
                                item.offsety = 0;
                            }
                        }
                        $scope.$digest();
                    };
                }

                function getFontCSS(item) {
                    FontManagement.getCss({uuid: item}, function (res) {
                        var style = document.createElement("style");
                        style.type = 'text/css';
                        try {
                            style.appendChild(document.createTextNode(res.message));
                        } catch (ex) {
                            style.stylesheet.cssText = res.message;
                        }
                        var Head = document.getElementsByTagName("head")[0];
                        Head.appendChild(style);
                    });
                }

                function getScale2(screenW, screenH, pageW, pageH) {
                    var newH = angular.copy(screenH);
                    if (vm.bindingtype == 'Top' && screenW * 0.7 * pageH / pageW * 2 > screenH) {
                        newH /= 2;
                    }

                    if (vm.bindingtype == 'Top') {
                        if (screenW / newH < pageW / pageH) {
                            //宽定，高不定
                            $scope.isMaxWidth = true;
                            // return true;
                        } else {
                            //高定，宽不定
                            $scope.isMaxWidth = false;
                            // return false;
                        }
                    } else {
                        if (screenW / newH > pageW / pageH) {
                            //宽定，高不定
                            $scope.isMaxWidth = true;
                            // return true;
                        } else {
                            //高定，宽不定
                            $scope.isMaxWidth = false;
                            // return false;
                        }
                    }
                }

                function getGeometry(obj, index) {
                    var bloodLeft = $scope.template.pages.page[index].blood.left;
                    var bloodTop = $scope.template.pages.page[index].blood.top;
                    return {
                        height: unitTransform(obj.height) * $scope.template.pages.page[index].styleScale,
                        width: unitTransform(obj.width) * $scope.template.pages.page[index].styleScale,
                        x: unitTransform(obj.x - bloodLeft) * $scope.template.pages.page[index].styleScale,
                        y: unitTransform(obj.y - bloodTop) * $scope.template.pages.page[index].styleScale
                    }
                }

                function getArr(arrs, obj, index) {
                    var w = obj.trimbox.width;
                    var h = obj.trimbox.height;
                    if (obj.spread) {
                        if (w / 2 > $scope.maxW) {
                            $scope.maxW = w / 2;
                        }
                        if (w / 2 < $scope.minW) {
                            $scope.minW = w / 2;
                        }
                    } else {
                        if (w > $scope.maxW) {
                            $scope.maxW = w;
                        }
                        if (w < $scope.minW) {
                            $scope.minW = w;
                        }
                    }

                    if (h > $scope.maxH) {
                        $scope.maxH = h;
                    }
                    if (w < $scope.minH) {
                        $scope.minH = h;
                    }
                    $scope.document.pages[index] = {
                        arr: obj,
                        arr2: '',
                        multiple: 0.45,
                        multiple2: 0.45,
                        width: w,
                        height: h,
                        spread: obj.spread,
                        'marginLeft': 0,
                        'thumbnail': 1,
                        'thumbnail2': 1,
                        id: obj.id,
                        trimbox: angular.copy(obj.trimbox)
                    };
                    setBgImg(index, arrs, obj);
                    if (index == $scope.len - 1 && vm.bindingtype != 'None') {
                        var page = $scope.document.pages;
                        getScale2($scope.dWidth, $scope.dHeight, page[0].trimbox.width, page[0].trimbox.height);

                        getPageThumbnail(page[0], page[0].spread);
                        var x = 1;
                        if (!$scope.isPull) {
                            x = 0;
                        }

                        for (var j = x; j < page.length; j++) {
                            page[j].halfWidth = ($scope.dWidth * page[j].multiple).toFixed(2);
                            //每个page宽度 和 最大page宽度 的比值
                            getPageThumbnail(page[j], page[0].spread);

                            if (j + 1 != page.length) {
                                getPageThumbnail(page[j + 1], page[j + 1].spread);
                            }
                            //跨页
                            if (page[j].spread == true) {
                                //左右翻页的清空
                                if (vm.bindingtype != 'Left') {
                                    if (!$scope.uadHeight) {
                                        $scope.uadHeight = page[j - 1].trimbox.height * 2
                                    }
                                    // page[j].marginTop = $scope.dWidth * 0.7 * ($scope.uadHeight - page[j].trimbox.height) * page[j].thumbnail / page[j].trimbox.width / 2;
                                    if ($scope.isMaxWidth) {
                                        var newW = $scope.dWidth * 0.7
                                    } else {
                                        var newW = ($scope.dHeight - 100) / 2 * page[j].trimbox.width / page[j].trimbox.height
                                    }
                                    page[j].marginTop = newW * ($scope.uadHeight - page[j].trimbox.height) * page[j].thumbnail / page[j].trimbox.width / 2;

                                }

                            } else if (page[j].spread == false && (page[j + 1] || []).spread == false && (!$scope.isPull || (j + 2) != page.length)) {
                                // console.log(j);
                                var arr = page[j + 1];
                                var arr0 = page[j];
                                page[j] = {
                                    id: arr0.id,
                                    width: arr0.trimbox.width,
                                    height: arr0.trimbox.height,
                                    arr: arr0.arr,
                                    spread: arr0.spread,
                                    multiple: arr0.multiple,
                                    thumbnail: arr0.thumbnail,
                                    marginLeft: arr0.halfWidth - $scope.dWidth * arr.multiple * arr.thumbnail,
                                    id2: arr.id,
                                    width2: arr.trimbox.width,
                                    height2: arr.trimbox.height,
                                    arr2: arr.arr,
                                    spread2: arr.spread,
                                    multiple2: arr.multiple,
                                    thumbnail2: arr.thumbnail
                                };
                                page.splice(j + 1, 1);
                                $scope.uadHeight = arr.trimbox.height + arr0.trimbox.height;
                            }
                            if (j == page.length - 1) {
                                $scope.document.pages = page;
                                showPages();

                                angular.forEach(page, function (paged) {
                                    if (vm.bindingtype == 'Top') {
                                        $scope.getPageWaHUad(paged);
                                    }
                                });

                                $scope.$watch('toggle.now', function () {
                                    if ($scope.toggle.now) {
                                        setTimeout(function () {
                                            Page.init();
                                        }, 600)

                                    }
                                });
                                $(".un-last:parent").css({width: '50%!important'})
                            }
                        }
                        $scope.document.pages = page;
                    }
                    if (vm.bindingtype == 'None' || $scope.template.pages.page.length < 2) {
                        showPages();
                        $(".rightBtn").hide();
                    }
                }

                function showPages() {
                    setTimeout(function () {
                        $(".save-page").hide();
                        $loading.hide();
                    })
                }

                function getPageThumbnail(page, spread) {
                    var pageW = page.trimbox.width;
                    if (spread) {
                        pageW /= 2;
                    }
                    if (pageW > 0) {
                        page.thumbnail = (pageW / $scope.maxW).toFixed(2)
                    }
                }

                function setBgImg(index, documents, obj) {
                    if (index <= 2) {
                        return
                    }
                    if (!documents.pages.page[index].identifier) {
                        return
                    }
                    if (index >= (documents.pages.page.length - 1)) {
                        return
                    }
                    if (obj.spread) {
                        return
                    }
                    if (!documents.pages.page[index - 2].spread && (documents.pages.page[index - 2].spread2)) {
                        $scope.document.pages[index - 1].arr2 = documents.pages.page[index];
                        return
                    }
                    if (!(documents.pages.page[index] || []).spread) {
                        $scope.document.pages[index - 1].arr2 = documents.pages.page[index];
                    }
                }

                $scope.getUlW = function () {
                    // vm.bindingtype=='Top'?!isMaxWidth?(dHeight-100)/2*document.pages[0].width/document.pages[0].height:dWidth*0.7:dWidth*0.9
                    if (vm.bindingtype == 'Top') {
                        if ($scope.isMaxWidth) {
                            return $scope.dWidth * 0.7
                        } else {
                            return ($scope.dHeight - 100) / 2 * $scope.document.pages[0].width / $scope.document.pages[0].height
                        }
                    } else {
                        if($scope.document.pages[0].arr2W){
                            return $scope.document.pages[0].arr2W-0+$scope.document.pages[0].arrW
                        }else{
                            return $scope.document.pages[0].arrW*2
                        }
                        // return $scope.dWidth * 0.9
                    }
                };

                $scope.getPageWaHLar = function (slide, flag, marginFlag) {
                    if (vm.bindingtype != 'Left') {
                        return
                    }

                    if (!slide) {
                        return
                    }

                    if (!slide.arr) {
                        return
                    }

                    if (flag) {
                        var w = 0;
                        if (!marginFlag) {
                            if (!slide.arr2) {
                                return
                            }

                            w = slide.arr2W = $scope.dWidth * slide.multiple2 * slide.thumbnail2;
                            slide.arr2.pageScaleW = w / unitTransform(slide.width2);
                        } else {
                            w = slide.arrW = $scope.dWidth * slide.multiple * slide.thumbnail;
                            slide.arr.pageScaleW = w / unitTransform(slide.width);
                            if(slide.arr.type == 'FrontBack'){
                                w=w*2;
                            }
                        }
                        if (marginFlag && $scope.maxW != $scope.minW) {
                            if (slide.spread) {
                                slide.marginLeft = ($scope.dWidth * 0.9 - w) / 2;
                            } else {
                                slide.marginLeft = ($scope.dWidth * 0.45 - w);
                            }
                        }
                        return w;
                    } else {
                        var h = 0;
                        if (!marginFlag) {
                            if (!slide.arr2) {
                                return
                            }

                            h = slide.arr2H = $scope.dWidth * slide.multiple2 * slide.height2 * slide.thumbnail2 / slide.width2
                            slide.arr2.pageScaleH = h / unitTransform(slide.height2);
                        } else {
                            h = slide.arrH = $scope.dWidth * slide.multiple * slide.height * slide.thumbnail / slide.width
                            slide.arr.pageScaleH = h / unitTransform(slide.height);
                        }
                        return h;
                    }
                };
                $scope.getPageWaHUad = function (slide) {
                    if (vm.bindingtype != 'Top') {
                        return
                    }

                    if (!slide) {
                        return
                    }

                    if (!slide.arr) {
                        return
                    }

                    var w1 = 0, w2 = 0, h1 = 0, h2 = 0;
                    if (slide.arr2) {
                        if ($scope.isMaxWidth) {
                            w2 = $scope.dWidth * 0.7 * slide.thumbnail2
                        } else {
                            w2 = ($scope.dHeight - 100) / 2 * slide.width2 / slide.height2 * slide.thumbnail2
                        }
                        h2 = slide.height2*w2/slide.width2;
                        slide.w2 = w2;
                        slide.arr2.w = w2;
                        slide.arr2.pageScaleW = w2 / unitTransform(slide.width2);
                        slide.h2 = h2;
                        slide.arr2.h = h2;
                        slide.arr2.pageScaleH = h2 / unitTransform(slide.height2);

                    }
                    if ($scope.isMaxWidth) {
                        w1 = $scope.dWidth * 0.7 * slide.thumbnail
                    } else {
                        w1 = ($scope.dHeight - 100) / 2 * slide.width / slide.height * slide.thumbnail
                    }
                    h1 = slide.height*w1/slide.width;
                    slide.w1 = w1;
                    slide.arr.w = w1;
                    slide.arr.pageScaleW = w1 / unitTransform(slide.width);
                    slide.h1 = h1;
                    slide.arr.h = h1;
                    slide.arr.pageScaleH = h1 / unitTransform(slide.height);
                };

                $scope.getPageWaHUad1 = function (slide, flag) {
                    if (vm.bindingtype != 'Top') {
                        return
                    }

                    if (!slide) {
                        return
                    }

                    if (!slide.arr) {
                        return
                    }

                    if (flag) {
                        var w = 0;
                        if (slide.arr2) {
                            if ($scope.isMaxWidth) {
                                w = $scope.dWidth * 0.7 * slide.thumbnail2
                            } else {
                                w = ($scope.dHeight - 100) / 2 * slide.width2 / slide.height2 * slide.thumbnail2
                            }
                            slide.w2 = w;
                            slide.arr2.w = w;
                            slide.arr2.pageScaleW = w / unitTransform(slide.width2);

                        } else {
                            if ($scope.isMaxWidth) {
                                w = $scope.dWidth * 0.7 * slide.thumbnail
                            } else {
                                w = ($scope.dHeight - 100) / 2 * slide.width / slide.height * slide.thumbnail
                            }
                            slide.w1 = w;
                            slide.arr.w = w;
                            slide.arr.pageScaleW = w / unitTransform(slide.width);
                        }
                        return w;
                    } else {
                        var h = 0;
                        if (slide.arr2) {
                            if ($scope.isMaxWidth) {
                                h = $scope.dWidth * 0.7 * slide.height2 * slide.thumbnail2 / slide.width2;
                            } else {
                                h = ($scope.dHeight - 100) / 2;
                            }
                            slide.h2 = h;
                            slide.arr2.h = h;
                            slide.arr2.pageScaleH = h / unitTransform(slide.height2);

                        } else {
                            if ($scope.isMaxWidth) {
                                h = $scope.dWidth * 0.7 * slide.height * slide.thumbnail / slide.width;
                            } else {
                                h = ($scope.dHeight - 100) / 2;
                            }
                            slide.h1 = h;
                            slide.arr.h = h;
                            slide.arr.pageScaleH = h / unitTransform(slide.height);

                        }
                        return h;
                    }
                };
                $scope.getPageScaleUad = function (slide, flag) {
                    if (vm.bindingtype != 'Top') {
                        return
                    }

                    if (!slide) {
                        return
                    }

                    if (!slide.arr) {
                        return
                    }

                    if (flag) {
                        var pageScaleW = 0;
                        if (slide.arr2) {
                            slide.arr2.pageScaleW = pageScaleW = slide.arr2.w / unitTransform(slide.width2);

                        } else {
                            slide.arr.pageScaleW = pageScaleW = slide.arr.w / unitTransform(slide.width);
                        }
                        return pageScaleW;
                    } else {
                        var pageScaleH = 0;
                        if (slide.arr2) {
                            slide.arr2.pageScaleH = pageScaleH = slide.arr2.h / unitTransform(slide.height2);

                        } else {
                            slide.arr.pageScaleH = pageScaleH = slide.arr.h / unitTransform(slide.height);

                        }
                        return pageScaleH;
                    }
                };

                $scope.toggle = {
                    now: false
                };
                var config = {
                    $bookBlock: $('#bb-bookblock'),
                    $navNext: $('#bb-nav-next'),
                    $navPrev: $('#bb-nav-prev'),
                    $slide1: $('#slide1'),
                    $slide2: $('#slide2')
                }
                var Page = (function () {
                    config = {
                        $bookBlock: $('#bb-bookblock'),
                        $navNext: $('#bb-nav-next'),
                        $navPrev: $('#bb-nav-prev'),
                        $slide1: $('#slide1'),
                        $slide2: $('#slide2')
                    };
                    var pageI = 0,
                        init = function () {
                            config = {
                                $bookBlock: $('#bb-bookblock'),
                                $navNext: $('#bb-nav-next'),
                                $navPrev: $('#bb-nav-prev'),
                                $slide1: $('#slide1'),
                                $slide2: $('#slide2')
                            };
                            if (vm.bindingtype == 'Left') {
                                config.$bookBlock.bookblock({
                                    speed: 800,
                                    shadowSides: 0.8,
                                    shadowFlip: 0.7,
                                    len: $scope.document.pages.length
                                });
                            } else {
                                $(".bb-item").addClass('lar-noShadow');
                                config.$bookBlock.bookblock({
                                    orientation: 'horizontal',
                                    speed: 700
                                });
                            }
                            initEvents();
                        },
                        initEvents = function () {
                            var $slides = config.$bookBlock.children();

                            // add navigation events
                            config.$navNext.on('click touchstart', function () {
                                config.$bookBlock.bookblock('next');
                                pageI = localStorage.getItem('pageI');
                                nextShow(pageI - 1);
                                return false;
                            });

                            config.$navPrev.on('click touchstart', function () {
                                config.$bookBlock.bookblock('prev');
                                pageI = localStorage.getItem('pageI');
                                prevShow(pageI - 0 + 1);
                                return false;
                            });

                            // add swipe events
                            $slides.on({
                                'click': function (event) {
                                    fn(event);
                                    var index = event.currentTarget.dataset.index;
                                    var page = $scope.document.pages[index];
                                    var index2 = index - 1;

                                    // var mar = (allWidth - $(".modal-content").width()) / 2;
                                    var mar = parseFloat($(".preview-article-con").css("marginLeft")) + parseFloat($(".preview-article-con").css("paddingLeft"))+60
                                    var w = $(".view-article").width() / 2 + mar;

                                    if (vm.bindingtype == 'Left') {
                                        if (event.clientX > (w)) {
                                            index2 = index - 0 + 1;
                                            getPageWidth(index - 0 + 1, 'next', config);
                                            nextShow(index);
                                        } else {
                                            getPageWidth(index - 1, 'prev', config);
                                            prevShow(index);
                                        }

                                        var page2 = $scope.document.pages[index2]
                                        if(page2.arr2W){
                                            $(".ul-width").width(page2.arr2W-0+page2.arrW);
                                            console.log(page2.arr2W-0+page2.arrW)
                                        }else{
                                            if(page2.spread){
                                                // $(".ul-width").width(page2.arrW*2);
                                                console.log(page2.marginLeft)
                                            }else {
                                                $(".ul-width").width(page2.arrW*2)
                                            }

                                        }
                                    } else if (vm.bindingtype == 'Top') {
                                        // 120px---->bb-bg 上方高度
                                        if (event.pageY > 120 + $(".bb-item").eq(index).height() / 2) {
                                            index2 = index - 0 + 1;
                                            //最后一页的时候禁止点击
                                            if (index == $scope.document.pages.length - 1) {
                                                return
                                            }
                                            config.$bookBlock.bookblock('next');
                                            nextShow(index);
                                        } else {
                                            config.$bookBlock.bookblock('prev');
                                            prevShow(index);
                                        }
                                        var page2 = $scope.document.pages[index2]
                                        if(page2.w2&&page2.w2>page2.w1){
                                            $(".ul-width").width(page2.w2);
                                        }else{
                                            $(".ul-width").width(page2.w1)
                                        }

                                        if ($scope.document.pages[index].spread == true) {
                                            $(".bb-horizontal .bb-page").css({top: '50.1%'})
                                        }
                                    }
                                    return false;
                                }
                            });
                        };

                    return {init: init};

                })();

                function nextShow(index) {
                    var len = $scope.document.pages.length;
                    $(".leftBtn").show();
                    if (index - 0 + 1 == len - 1) {
                        $(".rightBtn").hide();
                    }
                    vm.thisPage++;
                    firstPageInit()
                }

                function prevShow(index) {
                    $(".rightBtn").show();
                    if (index - 1 == 0) {
                        $(".leftBtn").hide();
                    }
                    vm.thisPage--;
                    firstPageInit()
                }

                function getPageWidth(index, attr, config) {
                    if (!$scope.isMaxWidth) {
                        var page = $scope.document.pages[index];
                        var w = $scope.dWidth * page.multiple * page.thumbnail + $scope.dWidth * page.multiple2 * page.thumbnail2;
                        // console.log(w);
                        $li.width(w);
                        $("#bb-bookblock").width(w);
                    } else {
                        $li.width($("#bb-bookblock").width());
                    }
                    config.$bookBlock.bookblock(attr);
                }

                $scope.backTo = function () {
                    // $uibModalInstance.dismiss('cancel');

                    $(".listBGC").show();
                    $(".modal-content").show().removeClass('opacity0');
                };
                vm.qrcodeShow = false
                $scope.shareParents = function () {
                    vm.qrcodeShow = !vm.qrcodeShow;
                }

                $scope.reorder = function () {
                    window.location.href = returnurl;
                }

                //防止事件冒泡，默认事件
                function fn(e) {
                    e = e || event;
                    e.cancelBubble = true;
                    e.stopPropagation();
                    e.returnValue = false;
                    e.preventDefault();
                }

                $scope.openViewList = function () {
                    $scope.viewPage = !$scope.viewPage
                }

                var animEndEventNames = {
                        'WebkitAnimation': 'webkitAnimationEnd',
                        'OAnimation': 'oAnimationEnd',
                        'msAnimation': 'MSAnimationEnd',
                        'animation': 'animationend'
                    },
                    // animation end event name
                    animEndEventName = animEndEventNames[Modernizr.prefixed('animation')],
                    effectSel = document.getElementById('fxselect'),
                    component = document.getElementById('component'),
                    items = component.querySelector('ul.itemwrap').children,
                    current = 0,
                    itemsCount = items.length,
                    nav = component.querySelector('nav'),
                    isAnimating = false;

                vm.viewIndex = 0;
                $scope.viewList = function (v, index) {
                    vm.oldViewIndex = vm.viewIndex;
                    vm.viewIndex = index;
                    effectSel.selectedIndex = vm.viewIndex;
                }

                $scope.saveView = function () {
                    $scope.viewPage = !$scope.viewPage;
                    if (vm.viewIndex == 0) {
                        $scope.isRandomView = false;
                        return
                    }

                    $scope.isRandomView = true;
                    changeEffect();
                }

                $scope.cancelView = function () {
                    $scope.viewPage = false;
                    vm.viewIndex = vm.oldViewIndex;
                    effectSel.selectedIndex = vm.viewIndex;
                }

                $scope.pageTurning = function (ev) {
                    ev.preventDefault();
                    if (ev.clientX >= $scope.dWidth / 2) {
                        navigate('next');
                    } else {
                        navigate('prev');
                    }
                }

                function changeEffect() {
                    component.className = component.className.replace(/\bfx.*?\b/g, '');
                    if (effectSel.selectedIndex) {
                        classie.addClass(component, effectSel.options[effectSel.selectedIndex].value);
                    }
                }

                function navigate(dir) {
                    if (isAnimating || !effectSel.selectedIndex) return false;
                    isAnimating = true;
                    var cntAnims = 0;


                    var currentItem = items[current];

                    if (dir === 'next') {
                        current = current < itemsCount - 1 ? current + 1 : 0;
                    } else if (dir === 'prev') {
                        current = current > 0 ? current - 1 : itemsCount - 1;
                    }

                    var nextItem = items[current];

                    var onEndAnimationCurrentItem = function () {
                        this.removeEventListener(animEndEventName, onEndAnimationCurrentItem);
                        classie.removeClass(this, 'current');
                        classie.removeClass(this, dir === 'next' ? 'navOutNext' : 'navOutPrev');
                        ++cntAnims;
                        if (cntAnims === 2) {
                            isAnimating = false;
                        }
                    }

                    var onEndAnimationNextItem = function () {
                        this.removeEventListener(animEndEventName, onEndAnimationNextItem);
                        classie.addClass(this, 'current');
                        classie.removeClass(this, dir === 'next' ? 'navInNext' : 'navInPrev');
                        ++cntAnims;
                        if (cntAnims === 2) {
                            isAnimating = false;
                        }
                    }

                    currentItem.addEventListener(animEndEventName, onEndAnimationCurrentItem);
                    nextItem.addEventListener(animEndEventName, onEndAnimationNextItem);


                    classie.addClass(currentItem, dir === 'next' ? 'navOutNext' : 'navOutPrev');
                    classie.addClass(nextItem, dir === 'next' ? 'navInNext' : 'navInPrev');
                };

                //边框
                $scope.imageboxW = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.width
                        }
                    } else {
                        return imagebox.geometry.width
                    }
                }
                $scope.imageboxH = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.height
                        }
                    } else {
                        return imagebox.geometry.height
                    }
                }
                $scope.imageboxL = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.x
                        }
                    } else {
                        return imagebox.geometry.x
                    }
                }
                $scope.imageboxT = function (imagebox) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            return imagebox.geometry.y
                            // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                        }
                    } else {
                        return imagebox.geometry.y
                    }
                }
                $scope.imageboxWEdge = function (imagebox, edge) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        if (imagebox.border.type == 'Color') {
                            return Number(imagebox.border.lineWidth)
                        }
                        if (imagebox.border.type == 'Pic') {
                            if (edge == 'left') {
                                return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                            }
                            if (edge == 'top') {
                                return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                            }
                            if (edge == 'right') {
                                return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                            }
                            if (edge == 'bottom') {
                                return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                            }
                        }
                    } else {
                        return 0
                    }
                }
                $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        var _ratio = 1;
                        if(ratio && ratio1){
                            if(imagebox.geometry.width > imagebox.geometry.height){
                                _ratio = ratio1
                            }else{
                                _ratio = ratio
                            }
                        }
                        if(ratio && !ratio1){
                            _ratio = ratio
                        }
                        if (imagebox.border.shape == 'Ellipse') {
                            return '50%'
                        }
                        if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                            var _radius = imagebox.border.radius[radius];
                            return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                        }else{
                            var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                            radiusW = radiusW + imagebox.border.lineWidth;
                            var _r = radiusW * imagebox.border.radius[radius] / 100;
                            return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                        }
                    } else {
                        return 0
                    }
                }
                $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
                    if (!imagebox) {
                        return
                    }
                    if (imagebox.border) {
                        var _ratio = 1;
                        if(ratio && ratio1){
                            if(imagebox.geometry.width > imagebox.geometry.height){
                                _ratio = ratio1
                            }else{
                                _ratio = ratio
                            }
                        }
                        if(ratio && !ratio1){
                            _ratio = ratio
                        }
                        if (imagebox.border.shape == 'Ellipse') {
                            return '50%'
                        }
                        if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                            var _radius = imagebox.border.radius[radius];
                            return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                        }else{
                            var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                            radiusW = radiusW + imagebox.border.lineWidth;
                            var _r = radiusW * imagebox.border.radius[radius] / 100;
                            return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                        }
                    } else {
                        return 0
                    }
                }
                $scope.dropShadow = function(imagebox,ratio,ratio1){
                    if (imagebox.shadow == null) {
                        return
                    }
                    var width = $scope.imageboxW(imagebox) * ratio;
                    var height =$scope.imageboxH(imagebox) * ratio1;
                    return dropShadow(imagebox,width,height)
                }

                $scope.toggleFullScreen = function () {
                    if (!vm.fullFlag) {
                        //进入全屏
                        var de = document.documentElement;
                        if (de.requestFullscreen) {
                            de.requestFullscreen();
                        } else if (de.mozRequestFullScreen) {
                            de.mozRequestFullScreen();
                        } else if (de.webkitRequestFullScreen) {
                            de.webkitRequestFullScreen();
                        }
                        vm.fullFlag = true;
                    } else {
                        //退出全屏
                        var de = document;
                        if (de.exitFullscreen) {
                            de.exitFullscreen();
                        } else if (de.mozCancelFullScreen) {
                            de.mozCancelFullScreen();
                        } else if (de.webkitCancelFullScreen) {
                            de.webkitCancelFullScreen();
                        }
                        vm.fullFlag = false;
                    }
                }
                function firstPageInit() {
                    if(vm.thisPage >= $scope.document.pages.length){
                        vm.thisPage = $scope.document.pages.length-1;
                        MessageService.error("这是最后一页");
                        return
                    }
                    if(vm.thisPage<0){
                        vm.thisPage=0;
                        MessageService.error("这是第一页");
                        return
                    }
                    $timeout(function () {
                        vm.firstPage = 0;
                        vm.lastPage = 0;
                        console.log(vm.thisPage)
                        if (vm.bindingtype == 'Top') {
                            $scope.document.pages.forEach(function (page, i) {
                                if(i <= vm.thisPage){
                                    if(i == 0){
                                        if(page.arr && !page.arr2){
                                            vm.firstPage = 1;
                                            vm.lastPage = 0;
                                        }else{
                                            if(page.arr){
                                                vm.firstPage = 1;
                                            }
                                            if(page.arr2){
                                                vm.lastPage = vm.firstPage + 1;
                                            }
                                        }
                                    }else{
                                        if(page.arr){
                                            if(vm.lastPage==0){
                                                vm.firstPage = 2;
                                            }else{
                                                vm.firstPage = vm.lastPage + 1;
                                            }
                                        }
                                        if(page.arr2){
                                            vm.lastPage = vm.firstPage + 1;
                                        }else{
                                            vm.lastPage = vm.firstPage;
                                        }
                                    }
                                }
                            })
                        }else{
                            $scope.document.pages.forEach(function (page, i) {
                                if(i <= vm.thisPage){
                                    if(i == 0){
                                        if(page.arr && !page.arr2){
                                            vm.firstPage = 1;
                                            vm.lastPage = 0;
                                        }else{
                                            if(page.arr){
                                                vm.firstPage = 1;
                                            }
                                            if(page.arr2){
                                                vm.lastPage = vm.firstPage + 1;
                                            }
                                        }
                                    }else{
                                        if(page.arr){
                                            if(vm.lastPage==0){
                                                vm.firstPage = 2;
                                            }else{
                                                vm.firstPage = vm.lastPage + 1;
                                            }
                                        }
                                        if(page.arr2){
                                            vm.lastPage = vm.firstPage + 1;
                                        }else{
                                            vm.lastPage = vm.firstPage;
                                        }
                                    }
                                }
                            })
                        }
                    })
                };
                $scope.lastPageCb = function () {
                    if(vm.thisPage==$scope.document.pages.length-1){
                        MessageService.error("这是最后一页");
                        return
                    }
                    config.$bookBlock.bookblock('last');
                    vm.thisPage = $scope.document.pages.length-1;
                    firstPageInit()
                };
                $scope.firstPageCb = function () {
                    if(vm.thisPage==0){
                        MessageService.error("这是第一页");
                        return
                    }
                    config.$bookBlock.bookblock('first');
                    vm.thisPage = 0;
                    firstPageInit()
                };

                vm.isCatalogue = false;
                $scope.catalogue = function () {
                    vm.isCatalogue = true;
                };
                $scope.jumpPage = function (index) {
                    vm.isCatalogue = false;
                    var _i = -1;
                    var _pageIndex = 0;
                    $scope.document.pages.forEach(function (page, i) {
                        if(page.arr){
                            _i++
                            if(_i == index){
                                _pageIndex = i;
                            }
                        }
                        if(page.arr2){
                            _i++
                            if(_i == index){
                                _pageIndex = i;
                            }
                        }
                    });
                    config.$bookBlock.bookblock('jump', (_pageIndex-0+1));
                    vm.thisPage = _pageIndex;
                    firstPageInit()
                }
            }]
    };

    angular
        .module('editorApp')
        .component('articlePreviewWeb', articlePreviewWeb);
})();
