

function html2canvas_adapt(node, options) {
	// https://github.com/niklasvh/html2canvas/blob/v1.4.1/docs/configuration.md
	options.allowTaint = true;
	options.useCORS = true;
	options.x = 0;
	options.y = 0;
	options.onclone = onElementClone;
	options.logging = true;
    options.backgroundColor=null;
    // try{
        html2canvas(node, options)
            .then(function (canvas) {
                if (options.onrendered) {
                    options.onrendered(canvas);
                }
            });

        function onElementClone (element) {
            if (options.error) {
                // options.error();
            }
        }
    // }catch (e) {
    //     if (options.error) {
    //         options.error();
    //     }
    // }
}
