(function(){
	"use strict";
	angular
	.module("editorApp")
	.controller("ArticleDialogController", ArticleDialogController);
	ArticleDialogController.$inject = ['$scope', '$uibModalInstance', 'MessageService', '$stateParams', 'entity', 'Article'];

    function ArticleDialogController( $scope,  $uibModalInstance, MessageService, $stateParams, entity, Article) {

       var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.id = null;
        vm.article={
            name:entity.type,
            id:entity.id
        }
        // if(entity.id){
        //     Article.get({id:entity.id},function(data){
        //         vm.article = data;
        //     })
        // }
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function () {
            if (vm.article.name == null || vm.article.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }

            if (entity.id) {
                vm.isSaving = true;
                Article.updateName({id:vm.article.id, name:vm.article.name}, onSaveSuccess, onSaveError)
            }
        }
        function onSaveSuccess(result) {
            MessageService.success("保存成功");
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }

    }

})();
