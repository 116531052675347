(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('SalesOrderManagerController', SalesOrderManagerController);

    SalesOrderManagerController.$inject = ['$q', '$state', '$scope', 'entity', '$localStorage', 'SalesOrder', 'Article', 'Store', 'OrderArticle',
     'ItemValue', 'ProductXml', 'MessageService', '$stateParams', 'Editors','$uibModal','Product'];

    function SalesOrderManagerController($q, $state, $scope, entity, $localStorage, SalesOrder, Article, Store, OrderArticle,
                                      ItemValue, ProductXml, MessageService, $stateParams, Editors, $uibModal, Product) {
        var vm = this;
        vm.salesOrder = entity;
        vm.storeId = $stateParams.storeId;
        vm.orderId = $stateParams.id;
        vm.plid = $stateParams.plid;
        vm.store = $localStorage.store;
        $scope.domain = $localStorage.domain;
        vm.lines = [];
        vm.goList = goList;
        vm.productLine = null;

        $scope.createArtcle = function(){
           var copy =  vm.productLine.copy;
            for (var i = vm.lines.length - 1; i >= 0; i--) {
                var line = vm.lines[i];
                copy = copy - line.copy;
                if (copy <=0 ) {
                    MessageService.error("作品份数已经满足订单份数，无法再创建作品");
                    return;
                }
            }
            Editors.navigateSelect(vm.storeId, {
                editorType: vm.productLine.editorType,
                pid: vm.productLine.productId,
                vid: vm.productLine.itemValueId,
                soId: vm.orderId,
                plId: vm.plid,
                selectState: 'productXmlSelect'
            });
            // $state.go('productXmlSelect', {storeId:vm.storeId, plid:vm.plid, vid:vm.productLine.itemValueId, orderId:vm.orderId, pid:vm.productLine.productId})
        }

        $scope.goBack =function(){
            $state.go("sales-order-view", {id:vm.orderId, storeId: vm.storeId, tryEdit:false});
        }

        $scope.lineCompleted =function(){
            vm.lineCompleted = false;
            if (vm.salesOrder && vm.salesOrder.productLines) {
                var completedQuantity = 0;
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.orderLines[i];
                    if (line.orderArticle && line.productLineId == vm.plid) {
                        if(!line.orderArticle.completed){
                            break;
                        }else{
                            completedQuantity = completedQuantity+ line.copy;
                        }
                    }
                }
                if (completedQuantity == vm.productLine.copy) {
                    vm.lineCompleted = true;
                }
            }
        }


        $q.all([vm.salesOrder.$promise]).then(function(){
            if (vm.salesOrder && vm.salesOrder.productLines) {
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.productLines[i];
                    if (line.id == vm.plid) {
                        vm.productLine = line;
                        break;
                    }
                }
                $scope.lineCompleted();
                if (vm.salesOrder.orderLines) {
                    angular.forEach(vm.salesOrder.orderLines, function(line) {
                        if (line.orderArticle && line.productLineId == vm.plid) {
                            Article.get({ id: line.orderArticle.articleId }, function(data) {
                                if (data != null && data.id != null) {
                                    line.orderArticle.completed = data.completed;
                                    line.orderArticle.unFilledCount = data.unFilledCount;
                                    vm.lines.push(line);
                                };
                            }, function() {})
                        }
                    });
                }
            }
        });

        $scope.minusCopy = function(item, e) {
            fn(e);
            if (item.copy > 1) {
                item.copy--;
            }
            quantityChange(item, 'minus');
        }
        $scope.addCopy = function(item, e) {
            if (vm.lineCompleted) {
                return;
            }
            fn(e);
            var copy =  vm.productLine.copy;
            for (var i = vm.lines.length - 1; i >= 0; i--) {
                var line = vm.lines[i];
                copy = copy - line.copy;
                if (copy <=0 ) {
                    MessageService.error("作品总份数与订单总份数不匹配");
                    return;
                }
            }
            item.copy++;
            quantityChange(item, 'add');
        }

        $scope.editName = function(line){
            line.nameEdit = true;
        }

        $scope.saveName = function(line){
            line.nameEdit = false;
            if (line == null || line.orderArticle == null) {
                return;
            }
            OrderArticle.updateName({id:line.orderArticle.id, name: line.orderArticle.name} , function(data){
                MessageService.success("作品名称保存成功");
            },function(){
                MessageService.error("作品名称保存失败，请稍后再试");
                return;
            });
        }

        function quantityChange(line, attr) {
            SalesOrder.updateArticle({ id: vm.orderId, articleId:line.orderArticle.articleId, copy: line.copy, productLineId:vm.plid }, function(data) {
                vm.salesOrder = data;
                $scope.lineCompleted();
            }, function() {
                MessageService.error("数量修改失败");
                if (attr == 'minus') {
                    line.copy--;
                } else if (attr == 'add') {}{
                    line.copy++;
                }
            })
        }

        $scope.deleteOrderLine = function(line, e) {
            fn(e);
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确认删除该作品？'
            }, function(){
                SalesOrder.deleteArticle({id: vm.orderId, orderLineId:line.id }, function(data) {
                    vm.salesOrder = data;
                    var index = vm.lines.indexOf(line);
                    if (index > -1) {
                        vm.lines.splice(index, 1);
                    }
                    MessageService.success("删除成功");
                    $scope.lineCompleted();
                }, function() {
                    MessageService.error("删除失败");
                })
            })

        }

        //检查作品是否能够操作
        $scope.articleCheck = function(orderArticle){
            var xmlLineCancelled = true;
            if (vm.itemValue.productXmlLines && vm.itemValue.productXmlLines.length>0) {
                for (var i = vm.itemValue.productXmlLines.length - 1; i >= 0; i--) {
                    var xmlLine = vm.itemValue.productXmlLines[i];
                    if (xmlLine.productXmlId == orderArticle.productXmlId && !xmlLine.cancelled) {
                        xmlLineCancelled = false;
                        break;
                    }
                }
            }
            if (vm.producxml.cancelled|| xmlLineCancelled || vm.itemValue.productCancelled || !vm.itemValue.productSaleable) {
                MessageService.error("该模板已被下架，不能进行该操作！");
                return false;
            }
            return true;
        }

        $scope.edit = function(orderArticle){
            if ( orderArticle.productType == 'StandardPrint' ||  orderArticle.productType == 'Standard'
                || orderArticle.productType == 'IPDerive') {
                MessageService.error("本客户端暂不支持该品类预览，请在制作端预览");
                return;
            }
            vm.producxml = ProductXml.get({id:orderArticle.productXmlId});
            vm.itemValue = ItemValue.get({id:orderArticle.itemValueId});
            $q.all([vm.producxml.$promise, vm.itemValue.$promise]).then(function(){
                if (!$scope.articleCheck(orderArticle)) {
                    return;
                }
                //后面 需要修改的
                if( Editors.belongToEditorAll(orderArticle.editorType)){
                    $state.go('editorAllEdit', {storeId:vm.storeId,aid:orderArticle.articleId, soid: vm.orderId, plid: vm.plid});
                }else if(orderArticle.editorType == "PICTURE"){
                    $state.go('editorPhotoWebEdit', {storeId:vm.storeId,aid:orderArticle.articleId, soid: vm.orderId, plid: vm.plid});
                }else {
                    $state.go("page-my-suffix-remake", {storeId:vm.storeId, aid:orderArticle.articleId, soid:vm.orderId, plid:vm.plid});
                }
            })
        }



        $scope.view = function (line) {
            Article.get({id: line.orderArticle.articleId},function (res) {
                var article = res;
                if (!article.itemValueId) {
                    MessageService.error("产品信息不全，请重新制作后再尝试下单");
                    return;
                }

                if (line.orderArticle.invalid) {
                    MessageService.error("作品已失效，无法预览");
                    return
                }
                Product.get({id: line.productId},function(res){
                    if(!res.saleable || res.cancelled){
                        MessageService.error("商品已过期，无法预览");
                    }else{
                        var orderArticle = line.orderArticle;
                        if ( orderArticle.productType == 'StandardPrint' ||  orderArticle.productType == 'Standard'
                            || orderArticle.productType == 'IPDerive') {
                            MessageService.error("本客户端暂不支持该品类预览，请在制作端预览");
                            return;
                        }
                        vm.producxml = ProductXml.get({id:orderArticle.productXmlId});
                        vm.itemValue = ItemValue.get({id:orderArticle.itemValueId});
                        $q.all([vm.producxml.$promise, vm.itemValue.$promise]).then(function(){
                            if (!$scope.articleCheck(orderArticle)) {
                                return;
                            }
                            //后面 需要修改的
                            $state.go("page-view" , {storeId:vm.storeId, aid:orderArticle.articleId, soId: vm.orderId, plId: vm.plid})
                        });
                    }
                })
            })
        }




        $(".navbar").show();
        $(".wrap").show().css({'margin': 'margin: 48px 0 0 130px;'});

        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        function goList() {
            $state.go("sales-order", {storeId: vm.salesOrder.storeId});
        }

        $scope.editorName = function(line){
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/editor-name-dialog.html',
                controller: 'EditorNameDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: line.orderArticle.name,
                        };
                    },
                }
            }).result.then(function(name) {
                line.orderArticle.name = name;
                $scope.saveName(line);
            }, function() {

            });
        }
        $scope.submit = function(){
            var order = vm.salesOrder;
            if (!order.submittable) {
                MessageService.error("订单未完成，提交失败");
                return;
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认提交订单？'
            }, function() {
                if (vm.store.orderWay == 'orderThenEditor' && vm.requireUserProtocol){

                    checkAgreement(order, function() {
                        requestSubmit(order);
                    });
                    return;
                } else {
                    requestSubmit(order);
                }
            });
        }
        $scope.purchase = function (line, e) {
            fn(e)
            Article.get({id: line.orderArticle.articleId},function (res) {
                var article = res;
                if (!article.itemValueId) {
                    MessageService.error("产品信息不全，请重新制作后再尝试下单");
                    return;
                }
                if (!article.completed) {
                    MessageService.error("该作品还未完成，不能下单");
                    return;
                }

                if (line.orderArticle.invalid) {
                    MessageService.error("作品已失效，无法购买");
                    return
                }
                Product.get({id: line.productId},function(res){
                    if(!res.saleable || res.cancelled){
                        MessageService.error("商品已过期，无法购买");
                    }else{
                        vm.producxml = ProductXml.get({id:article.productXmlId});
                        vm.itemValue = ItemValue.get({id:article.itemValueId});
                        $q.all([vm.producxml.$promise, vm.itemValue.$promise]).then(function(){
                            if (!$scope.articleCheck(article)) {
                                return;
                            }
                            $state.go("sales-order-reorder", {storeId: vm.salesOrder.storeId, productId:line.orderArticle.productId, itemValueId:line.orderArticle.itemValueId, itemValueLineId:line.orderArticle.itemValueLineId, articleId: article.id,
                                copy:line.quantity, quantity:1,orderLineId: line.id,salesOrderId:vm.salesOrder.id});
                        })
                    }
                })
            })
        }
        //检查作品是否能够操作
        $scope.articleCheck = function(article){
            var xmlLineCancelled = true;
            if (vm.itemValue.cancelled) {
                MessageService.error("该模板已被下架，不能进行该操作！");
                return false;
            }
            if (vm.itemValue.productXmlLines && vm.itemValue.productXmlLines.length>0) {
                for (var i = vm.itemValue.productXmlLines.length - 1; i >= 0; i--) {
                    var xmlLine = vm.itemValue.productXmlLines[i];
                    if (xmlLine.productXmlId == article.productXmlId && !xmlLine.cancelled) {
                        xmlLineCancelled = false;
                        break;
                    }
                }
            }
            if (vm.producxml.cancelled|| xmlLineCancelled || vm.itemValue.productCancelled || vm.itemValue.cancelled || !vm.itemValue.productSaleable) {
                MessageService.error("该模板已被下架，不能进行该操作！");
                return false;
            }
            return true;
        };
        function requestSubmit(order) {
            SalesOrder.submit({
                id: order.id
            }, function(data) {
                MessageService.success("提交订单成功");
                $scope.goBack()
            }, function() {
                MessageService.error("提交订单失败");
            })
        }
        function checkAgreement(order, agreeCallback) {
            var quantity = 0;
            for (var i = 0; i < order.productLines.length; i++) {
                var line = order.productLines[i];
                quantity += (line.copy || 0);
            }
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/modal/protocol-agreement.html',
                controller: 'OrderProtocolAgreementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            number: order.number,
                            quantity: quantity
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (data) {
                if (data && data.agree) {
                    if (agreeCallback) {
                        agreeCallback();
                    }
                }
            });
        }
    }
})();
