(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

    function stateConfig($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.when('/{storeId}', '/{storeId}/commodity');
        $stateProvider.state('homeEditor', {
            parent: 'app',
            url: '',
            abstract: true,
            data: {
                authorities: [],
                pageTitle: 'home.title'
            },
            views: {
                'index@': {
                    templateUrl: 'app/home/home-editor.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider.state('home', {
            parent: 'app',
            url: '/{storeId}',
            data: {
                authorities: [],
                pageTitle: 'home.title'
            },
            views: {
                'index@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider.state('home1', {
            parent: 'app',
            url: '/{storeId}',
            abstract: true,
            data: {
                authorities: [],
                pageTitle: 'home.title'
            },
            views: {
                'index@': {
                    templateUrl: 'app/home/home1.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider.state('home2', {
            parent: 'app',
            url: '/{storeId}',
            abstract: true,
            data: {
                authorities: [],
                pageTitle: 'home.title'
            },
            views: {
                'index@': {
                    templateUrl: 'app/home/home2.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider.state('outofservice', {
            parent: 'app',
            url: '/{storeId}/outofservice',
            data: {
                authorities: [],
                pageTitle: 'home.title'
            },
            views: {
                'index@': {
                    templateUrl: 'app/home/outofservice.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
