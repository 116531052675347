(function () {
    'use strict';

    var editorPhotoUploadPopupC = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/product/editor-photo-upload-popup.html',
        controllerAs: 'vm',
        bindings: {
            product: '<',
            onChange: '&',
        },

        controller: ['$scope', function ($scope){
            var vm = this;
            $scope.product = vm.product;
            $scope.popupSave = function (){
                vm.onChange();
            }
        }]
    };

    angular
        .module('editorApp')
        .component('editorPhotoUploadPopup', editorPhotoUploadPopupC);
})();
