(function () {
    'use strict';

    var dateCellControl = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/controls/date-cell.control.html',
        controllerAs: 'vm',
        bindings: {
            obj: "<",
            layout: "<",
            settings: "<",
            width: "<",
            height: "<",
            trIndex: "<",
            tdIndex: "<",
            dateConfig: "<",
        },

        controller: ['$scope','FontManagement', function ($scope, FontManagement) {
            var vm = this;
            vm.data = {
                width: 100,
                height: 100,
                items: []
            };

            vm.$onInit = function() {
                var settings = vm.settings || {};
                constructCell(vm.layout, settings, vm.obj);

            }
            // 每當 one-way binding 更新時，changesObj 就是改變前後的差異
            vm.$onChanges = function(data) {
                if (data.settings && data.settings.currentValue) {
                    constructCell(vm.layout, vm.settings, vm.obj);
                }
                else if (data.obj && data.obj.currentValue) {
                    constructCell(vm.layout, vm.settings, vm.obj);
                }

            }
            vm.$postLink = function () {

            }
            vm.$doCheck = function() {
                // deep watch
            }
            vm.$onDestroy = function () {

            }

            function textStyleObj(obj) {
                var styles = {
                    'position': 'absolute',
                    'left': obj.x + 'px',
                    'top': obj.y + 'px',
                    'line-height': (obj.textSize > 12 ? obj.h : obj.h / (1 / 12 * obj.textSize)) + 'px',
                    'fontSize': (obj.textSize > 12 ? obj.textSize : 12) + 'px',
                    'font-family': obj.textFontUuid,
                    'color': obj.textColor,
                    'text-align': obj.textAlign || 'center',
                    'transform':'scale('+(1 / 12 * obj.textSize > 1 ? 1 : 1 / 12 * obj.textSize)+')',
                    'transform-origin': '0 0',
                    'width': obj.textSize > 12 ? obj.w : obj.w / (1 / 12 * obj.textSize) + 'px',
                    'height': obj.textSize > 12 ? obj.h : obj.h / (1 / 12 * obj.textSize) + 'px',
                };
                if (obj.textFontUuid) {
                    styles['font-family'] = obj.textFontUuid;
                }
                if (obj.textWeight) {
                    styles['font-weight'] = obj.textWeight;
                }
                if (obj.textStyle) {
                    styles['font-style'] = obj.textStyle;
                }
                if (obj.backgroundColor) {
                    styles['background-color'] = obj.backgroundColor;
                }
                if (obj.debug) {
                    styles['border'] = '1px dotted #ccc';
                }
                return styles;
            }
            function imageStyleObj(obj) {
                var styles = {
                    'position': 'absolute',
                    'left': obj.x + 'px',
                    'top': obj.y + 'px',
                    'width': obj.w + 'px',
                    'height': obj.h + 'px'
                };
                if (obj.debug) {
                    styles['border'] = '1px dotted #ccc';
                }

                return styles;
            }

            function findItemFromSettings(settings, itemName) {
                if (!settings || !settings.items || !settings.items.length || !itemName) {
                    return {};
                }
                for (var i = 0; i < settings.items.length; i++) {
                    var item = settings.items[i];
                    if (item.name == itemName) {
                        return item;
                    }
                }

                return {};
            }

            function findDataFromObj(obj, type, format) {
                if (!obj || !obj.items || !obj.items.length) {
                    return {};
                }
                for (var i = 0; i < obj.items.length; i++) {
                    var item = obj.items[i];
                    if (item.type == type && item.format == format) {
                        return item;
                    }
                }
                return {};
            }

            function constructCell(layout, settings, myObj) {
                var cw = layout.width || 100;
                var ch = layout.height || 50;
                var width = vm.width || 100;
                var height = vm.height || 100;
                vm.data = {
                    width: width,
                    height: height,
                    styles: {
                        width: width + 'px',
                        height: height + 'px'
                    },
                    showOthers:settings.showOthers,
                    items: []
                };
                if (settings.backgroundColor) {
                    vm.data.styles['background-color'] = settings.backgroundColor;
                }
                var lineW = 0;
                if (settings.border && settings.border.lineType) {
                    var border = settings.border;
                    if (settings.border && settings.border.lineType && settings.border.lineWidth && border.color) {
                        vm.data.styles['border'] = (border.lineWidth) + 'px ' + border.lineType + " "  + border.color;
                        lineW = (border.lineWidth)*2;
                        if(vm.dateConfig && vm.dateConfig.removeBorderOverlap){
                            if(vm.trIndex > 0){
                                vm.data.styles['border-top'] = 0 + 'px ' + border.lineType + " "  + border.color;
                            }
                            if(vm.tdIndex > 0){
                                lineW = border.lineWidth;
                                vm.data.styles['border-left'] = 0 + 'px ' + border.lineType + " "  + border.color;
                            }
                        }
                    }
                    if (border.tlRadius) {
                        vm.data.styles['border-top-left-radius'] = border.tlRadius * Math.min(width / cw, height / ch) + 'px';
                    }
                    if (border.trRadius) {
                        vm.data.styles['border-top-right-radius'] = border.trRadius * Math.min(width / cw, height / ch) + 'px';
                    }
                    if (border.blRadius) {
                        vm.data.styles['border-bottom-left-radius'] = border.blRadius * Math.min(width / cw, height / ch) + 'px';
                    }
                    if (border.brRadius) {
                        vm.data.styles['border-bottom-right-radius'] = border.brRadius * Math.min(width / cw, height / ch) + 'px';
                    }
                }
                if (settings && settings.items && settings.items.length) {
                    width = width - lineW;
                    height = height - lineW;
                    for (var i = 0; i < settings.items.length; i++) {
                        var ci = settings.items[i];
                        var ci1 = layout.items[i];
                        if (ci.type == "image" || ci.type == 'userimage') {
                            var element = {
                                x: ci.x * width / cw,
                                y: ci.y * height / ch,
                                w: ci.w * width / cw,
                                h: ci.h * height / ch
                            };
                            if (settings.debug) {
                                element.debug = true;
                            }
                            var img = {
                                type: ci.type,
                                url: ci.url,
                                styles: imageStyleObj(element)
                            };

                            vm.data.items.push(img);
                        }
                        else if (ci.type) {
                            var element = {
                                x: ci.x * width / cw,
                                y: ci.y * height / ch,
                                w: ci.w * width / cw,
                                h: ci.h * height / ch,
                                textSize: Number.parseInt(ci.textSize) * Math.min(width / cw, height / ch),
                                textFont: ci.textFont,
                                textColor: ci.textColor,
                                textAlign: ci.textAlign,
                                textWeight: ci.textWeight,
                                textFontUuid: ci.textFontUuid
                            };
                            var item = findItemFromSettings(settings, ci.name) || {};
                            if (item.textSize) {
                                element.textSize = Number.parseInt(item.textSize) * Math.min(width / cw, height / ch);
                            }
                            if (item.textFontUuid) {
                                element.textFontUuid = item.textFontUuid;
                                getFontCSS(FontManagement, {
                                    fontuuid: element.textFontUuid
                                }, $scope)
                            }
                            if (item.textFont) {
                                element.textFont = item.textFont;
                            }
                            if (item.textColor) {
                                element.textColor = item.textColor;
                            }
                            if (item.textAlign) {
                                element.textAlign = item.textAlign;
                            }
                            if (item.textBold) {
                                element.textWeight = "600";
                            }
                            if (item.textItalic) {
                                element.textStyle = "italic";
                            }
                            if (item.backgroundColor) {
                                element.backgroundColor = item.backgroundColor;
                            }

                            if (settings.debug) {
                                element.debug = true;
                            }

                            var data = findDataFromObj(myObj || vm.obj, ci.type, ci1.format) || {};
                            if (!data.type && ci.type == 'event') {
                                data = {
                                    value: ' '
                                };
                            }

                            var text = {
                                type: ci.type,
                                format: ci1.format,
                                value: data.value || ci.value || ci.sample,
                                styles: textStyleObj(element)
                            };
                            vm.data.items.push(text);
                        }

                    }
                } else {
                    var t = {
                        type: "date",
                        format: "$dd",
                        value: "01",
                        textSize: 18 * width / 100,
                        textFont: '',
                        textFontUuid: '',
                        textColor: '#ff0000',
                        textWeight: 600,
                        x: 5 * width / 100,
                        y: 15 * height / 100,
                        w: 90 * width / 100,
                        h: 40  * height / 100,
                        fixedTo: ''
                    };
                    vm.data.items.push({
                        type: t.type,
                        format: t.format,
                        value: t.value || t.sample,
                        styles: textStyleObj(t)
                    });
                }
            } // end of constructCell

        }]
    };
    angular.module('editorApp')
        .component('dateCell', dateCellControl);

})();
