(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('ThemeClassify', ThemeClassify);

    ThemeClassify.$inject = ['$resource'];

    function ThemeClassify ($resource) {
        var resourceUrl =  'manager/' + 'api/theme-classify/:path/:id';
        return $resource(resourceUrl, {}, {
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                },
                isArray:true
            },
            "getAllByCondition3":{
                method:"POST",
                params:{
                    path:"getAllByCondition3"
                },
                isArray:true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
            "byCondition4":{
                method:"GET",
                params:{
                    path:"byCondition4"
                }
            },
            "getAllByCondition4":{
                method:"GET",
                params:{
                    path:"getAllByCondition4"
                }
            },
            "del":{
                method:"DELETE"
            },
            "changeStatue":{
                method:"GET",
                params:{
                    path:"changeStatue"
                }
            },
            "get":{
                method:"GET"
            },
            "save":{
                method:"PUT"
            }
        });
    }
})();
