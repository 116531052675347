(function () {
    'use strict';

    var homeNav = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/home/home-nav.html',
        controllerAs: 'vm',
        bindings: {
            store: '<',
            customer: '<',
            domain: '<',
        },

        controller: ['$scope', '$stateParams', '$q', 'MessageService', '$cookieStore', '$state', '$uibModal','$localStorage',
        'Store', 'StoreConfig',
            function ($scope, $stateParams, $q, MessageService, $cookieStore, $state, $uibModal, $localStorage,
                Store, StoreConfig) {
                var vm = this;
                vm.storeId = $stateParams.storeId || $cookieStore.get("store-id");
                // vm.store = $localStorage.store;
                if (!vm.store) {
                    vm.store = Store.get({id:vm.storeId});
                }
                vm.storeConig = StoreConfig.getAllByTypes({storeId:vm.storeId, type:['IMGSHOW','PCSHOWIMG']})
                $q.all([vm.storeConig.$promise, vm.store.$promise]).then(function(){
                    if (vm.storeConig.status == 200) {
                        vm.configs = vm.storeConig.message;
                        if (vm.configs && vm.configs.length>0) {
                            for (var i = vm.configs.length - 1; i >= 0; i--) {
                                var config = vm.configs[i];
                                if (config == null) {
                                    continue;
                                }
                                if (config.type == 'IMGSHOW') {
                                    vm.imageShow  = config.open;
                                }
                                if (config.type == 'PCSHOWIMG') {
                                    vm.pcShowImage  = config.value;
                                }
                            }
                        }
                    }
                    if (vm.store.payType) {
                        if (vm.store.payType.indexOf("balancePay")>= 0 ) {
                            vm.store.balancePay = true;
                        }
                    }
                })

                 $scope.login = function () {
                    $uibModal.open({
                        templateUrl: 'app/account/register/register.html',
                        controller: 'RegisterController',
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                        resolve: {
                            entity: {
                                type: 'Login'
                            },
                        }
                    });
                };

                var orderedModal = function (url, controller) {
                    return $uibModal.open({
                        templateUrl: url,
                        controller: controller,
                        backdrop: 'static',
                        keyboard: true,
                        windowClass: "jh-modal-md",
                        controllerAs: 'vm',
                    });
                };

                $scope.ordered = function () {
                    if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                        $scope.login();
                    } else {
                        orderedModal('app/entities/commodity/number-ordered.html', 'NumberOrderedController').result.then(
                            function (data) {
                                for (var i = 0; i < data.productLines.length; i++) {
									data.productLines[i].articleQuantity = 0;
									for (var c = 0; c < data.orderLines.length; c++) {
										if (data.orderLines[c].productLineId == data.productLines[i].id) {
											data.productLines[i].articleQuantity += data.orderLines[c].copy;
										}
										// 编辑过的订单直接跳转订单详情
										if(data.orderLines[c].orderArticle){
											$state.go("sales-order-view", {storeId:vm.storeId, id:data.id});
											return
										}
									}
								}
                                // 一单一品多份跳转作品管理页
                                if(data.productLines.length == 1 && data.status == 'Submitted' && data.productLines[0].productType == 'CustomPrint' && data.productLines[0].copy>1){
									$state.go("sales-order-manager", {id:data.productLines[0].salesOrderId, plid:data.productLines[0].id, storeId: vm.storeId});
									return
								}
                                $state.go("sales-order-view", {storeId:vm.storeId, id:data.id, tryEdit:true});
                            });
                    }
                };

                $scope.navSelect = function (item) {
                    return window.location.href.indexOf(item) > -1 ? true : false
                }
                $scope.uiSrefCb = function (item) {
                    if (($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) && item != 'commodity') {
                        $scope.login();
                    }else{
                        $state.go(item, {storeId: vm.storeId});
                    }
                }

                $scope.buySize = function() {
                    if (($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null)) {
                        $scope.login();
                    }else{
                        $uibModal.open({
                            templateUrl: 'app/entities/web-gallery/storage-buy.html',
                            controller: 'StorageBuyController',
                            backdrop: 'static',
                            keyboard: true,
                            windowClass: "jh-modal-md",
                            controllerAs: 'vm',
                            resolve: {
                            }
                        });
                    }
                }
            }]
    };

    angular
        .module('editorApp')
        .component('homeNav', homeNav);
})();
