(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('sales-order', {
            parent: 'home1',
            url: '/sales-order?page&sort&search&status',
            data: {
                authorities: [],
                pageTitle: 'editorApp.salesOrder.home.title'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/sales-order/sales-order.html',
                    controller: 'SalesOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        status:$stateParams.status,
                        storeId: $stateParams.storeId
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('salesOrder');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('sales-order-view', {
            parent: 'home2',
            url: '/sales-order/{id}/view/{tryEdit}',
            data: {
                authorities: [],
                pageTitle: 'editorApp.salesOrder.home.title'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/sales-order/sales-order-detail.html',
                    controller: 'SalesOrderViewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('salesOrder');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SalesOrder', function ($stateParams, SalesOrder) {
                    return SalesOrder.get({id: $stateParams.id}).$promise;
                }]
            }
        })

        .state('code-pay-detail', {
            parent: 'sales-order-view',
            url: '/code-pay/{storeId}/{orderId}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sales-order/code-pay.html',
                    controller: 'CodePayController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('sales-order-view', {storeId: $stateParams.storeId, id:$stateParams.id, tryEdit:false}, {reload: true});
                }, function() {
                    $state.go('sales-order-view', {storeId: $stateParams.storeId, id:$stateParams.id, tryEdit:false}, {reload: false});
                });
            }]
        })

        .state('sales-order-manager', {
            parent: 'home2',
            url: '/sales-order/{id}/{plid}/manager',
            data: {
                authorities: [],
                pageTitle: 'editorApp.salesOrder.home.title'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/sales-order/order-manager.html',
                    controller: 'SalesOrderManagerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SalesOrder', function ($stateParams, SalesOrder) {
                    return SalesOrder.get({id: $stateParams.id}).$promise;
                }]
            }
        })

        .state('sales-order-reorder', {
            parent: 'home2',
            url: '/sales-order/{productId}/{itemValueId}/{itemValueLineId}/{articleId}/{copy}/{quantity}/{productXmlId}/reorder?orderLineId&salesOrderId',
            data: {
                authorities: [],
                pageTitle: 'editorApp.salesOrder.home.title'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/sales-order/order-reorder.html',
                    controller: 'SalesOrderReorderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SalesOrder','MessageService', function ($stateParams, SalesOrder, MessageService) {
                    console.log($stateParams)
                    if($stateParams.orderLineId && $stateParams.salesOrderId){
                        return SalesOrder.constructBuyAgain({orderLineId: $stateParams.orderLineId,id:$stateParams.salesOrderId }).$promise
                            .then(function (data) {
                                return data;
                            })
                            .catch(function (error) {
                                console.log('An error occurred while fetching entity data:', error);
                                MessageService.error(error.data.message || '系统错误，请稍后再试')
                                throw error;
                            });
                    }else{
                        return SalesOrder.constructImmediately2({
                            productId: $stateParams.productId,
                            itemValueId: $stateParams.itemValueId,
                            itemValueLineId :$stateParams.itemValueLineId,
                            articleId: $stateParams.articleId,
                            copy:$stateParams.copy,
                            productXmlId:$stateParams.productXmlId,
                            quantity:$stateParams.quantity
                        }).$promise
                        .then(function (data) {
                            return data;
                        })
                        .catch(function (error) {
                            console.log('An error occurred while fetching entity data:', error);
                            MessageService.error(error.data.message || '系统错误，请稍后再试')
                            throw error;
                        });;
                    }
                }],
            }
        })
        .state('sales-order-cart', {
            parent: 'home2',
            url: '/sales-order/{goodIds}/cart',
            data: {
                authorities: [],
                pageTitle: 'editorApp.salesOrder.home.title'
            },
            views: {
                'content': {
                    templateUrl: 'app/entities/sales-order/order-reorder.html',
                    controller: 'SalesOrderReorderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('orderStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SalesOrder','MessageService', function ($stateParams, SalesOrder, MessageService) {
                    return SalesOrder.construct2({goodIds: $stateParams.goodIds}).$promise
                        .then(function (data) {
                            return data;
                        })
                        .catch(function (error) {
                            console.log('An error occurred while fetching entity data:', error);
                            MessageService.error(error.data.message || '系统错误，请稍后再试')
                            throw error;
                        });
                }],
            }
        });
    }

})();
