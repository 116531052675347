(function () {
    'use strict';

    var galleryInfo = {
        templateUrl: 'app/entities/gallery/gallery-info.html',
        bindings: {
            settingFolderId:'=',//图片列表
        },
        controller: ['$stateParams', '$state', '$scope', 'Store', '$localStorage', '$q', 'Qinius', '$timeout','Gallery','Folder','UidService',
            function ($stateParams, $state, $scope, Store, $localStorage, $q, Qinius, $timeout,Gallery,Folder, UidService) {
            var vm = this;
            $scope.data = null;
            vm.domains = Qinius.getDomain();
            vm.tokens = Qinius.get();
            vm.storeId = $stateParams.storeId || $localStorage.storeId;
            $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function () {
                $scope.domain = 'http://' + vm.domains.domains[0] + '/';
                vm.token = vm.tokens.token;
            });
            Folder.get({id:vm.settingFolderId},function (res){
                $scope.data = res;
                console.log($scope.data);
            })

            window.uploadCover = function ($event) {
                var files = $event.target.files;
                if (files != null && files.length > 0) {
                    qiniuUpload(files[0]);
                }
            };

            function qiniuUpload(file) {
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'integral-' + UidService.get());
                formData.append('token', vm.token);
                
                var reader = new FileReader();
                reader.readAsArrayBuffer(file)
                reader.onloadend = function(e) {
                    formData.append('crc32', CRC32.buf(new Uint8Array(this.result)));
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: true,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            $timeout(function () {
                                $scope.data.cover = $scope.domain + data.key;
                            })
                            console.log($scope.data);
                        },
                        error: function (request) {
                            MessageService.error("图片上传失败");
                        }
                    });
                }
            }

            $scope.quit = function () {
                $scope.$emit('to-gallery', 'infoFolderHide');
            }
        }]
    };
    
    angular
        .module('editorApp')
        .component('galleryInfo', galleryInfo);
})();
