(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('AddressController', AddressController);

    AddressController.$inject = ['$scope', 'Principal', 'Product', '$state', 'MessageService', 'Customer', '$rootScope', '$stateParams', '$uibModal', 'Qinius', '$q', 'LocationProvice', 'LocationCity', 'LocationDistrict'];

    function AddressController ($scope, Principal, Product, $state, MessageService, Customer, $rootScope, $stateParams, $uibModal, Qinius, $q, LocationProvice, LocationCity, LocationDistrict) {
        var vm = this;
        vm.storeId = $stateParams.storeId;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;

        init();
        function init() {
            vm.addressMessage = "";
            vm.isFormState = false;
            vm.addressData = {
                address: "",
                city: "",
                contact: "",
                customerId: "",
                district: "",
                id: null,
                isDefault: false,
                phone: null,
                postCode: null,
                province: ""
            };
            vm.location = {
                province: "",
                city: "",
                district: ""
            };
            vm.province = "";
            vm.city = "";
            vm.district = "";
            Customer.current({},function(data){
                vm.customer = data;
                console.log(data);
                vm.addressData.customerId = data.id;
            },function(error){
                console.log(error);
            });
            loadData();
        }

        function loadData() {

            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                if (vm.location.province == null || vm.location.province == "") {
                    return;
                }
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == vm.location.province) {
                        vm.province =  p;
                        break;
                    }
                }
                LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                    vm.citys = data;
                    for (var i = vm.citys.length - 1; i >= 0; i--) {
                        var c = vm.citys[i];
                        if (c.name == vm.location.city) {
                            vm.city = c;
                            break;
                        }
                    }
                    if(vm.city != null ){
                        LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                            vm.districts = data;
                            for (var i = vm.districts.length - 1; i >= 0; i--) {
                                var di = vm.districts[i];
                                if (di.name == vm.location.district) {
                                    vm.district = di;
                                }
                            }
                        });
                    }
                });
            });
        }

        function changeProvince() {
            if (vm.province == null) {
                vm.citys = [];
                vm.districts = [];
                return;
            }
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }
        function changeCity() {
            if (vm.city == null) {
                vm.districts = [];
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }

        //点击编辑
        $scope.addressEditBtn = function (data) {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                        entity: function () {
                            return {
                                addressData:{
                                    address: data.address,
                                    city: "",
                                    contact: data.contact,
                                    district: "",
                                    id: data.id,
                                    isDefault: data.isDefault,
                                    phone: data.phone,
                                    postCode: data.postCode,
                                    province: "",
                                    customerId: vm.customer.id
                                },
                                location:{
                                    province: data.province,
                                    city: data.city,
                                    district: data.district
                                }
                            };
                        }
                    }
                });
            };
            createModal2('app/entities/commodity/address-dialog.html', 'AddressDialogController').result.then(function(data) {
                Customer.updateLocation(data, function (res) {
                    MessageService.success("修改地址成功");
                    init();
                });
            });
        };

        $scope.addressDefault = function (data) {
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确定设置当前地址为默认地址？'
            }, function () {
                data.isDefault = true;
                Customer.setDefaultLocation({id:vm.customer.id, lId:data.id}, function (res) {
                    MessageService.success("默认地址设置成功！");
                    init();
                });
            });
        };
        $scope.addressDelete = function (data) {
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确定删除当前地址？'
            }, function () {
                data.isDefault = true;
                Customer.removeLocation({id:vm.customer.id, lId:data.id}, function (res) {
                    MessageService.success("删除地址成功！");
                    init();
                });
            });
        };
        //新增地址
        $scope.addressAdd = function () {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                        entity: function () {
                            return {
                            };
                        }
                    }
                });
            };
            createModal2('app/entities/commodity/address-dialog.html', 'AddressDialogController').result.then(function(data) {
                Customer.addLocation(data, function (res) {
                    MessageService.success("新增地址成功！");
                    init();
                });
            });

        };
        //编辑地址
        $scope.addressEdit = function () {
            vm.addressData.province = vm.province.name;
            vm.addressData.city = vm.city.name;
            vm.addressData.district = vm.district.name;
            if(vm.addressData.contact == '' || vm.addressData.contact == null){
                MessageService.error("请输入联系人");
                return;
            }
            if(vm.addressData.phone == '' || vm.addressData.phone == null){
                MessageService.error("请输入正确的手机号码");
                return;
            }
            // if(!(/^1[345678]\d{9}$/.test(vm.addressData.phone))){
            //     MessageService.error("请输入正确的手机号码");
            //     return;
            // }
            if(vm.addressData.province == '' || vm.addressData.province == null){
                MessageService.error("请选择省");
                return;
            }
            if(vm.addressData.city == '' || vm.addressData.city == null){
                MessageService.error("请选择市");
                return;
            }
            if(vm.addressData.district == '' || vm.addressData.district == null){
                MessageService.error("请选择区");
                return;
            }
            if(vm.addressData.address == '' || vm.addressData.address == null){
                MessageService.error("请输入详细地址");
                return;
            }
            Customer.updateLocation(vm.addressData, function (res) {
                MessageService.success("新增地址成功！");
                init();
            });
        };

        //自动识别地址
        $scope.$watch("vm.addressMessage",function(newValue,oldValue){
            if(newValue){
                vm.addressMessage = newValue;
                Customer.analysisLocation({value:vm.addressMessage}, function (res) {
                    vm.addressData = {
                        address: res.address,
                        city: "",
                        contact: res.contact,
                        district: "",
                        id: vm.addressData.id,
                        isDefault: res.isDefault,
                        phone: res.phone,
                        postCode: res.postCode,
                        province: "",
                        customerId: vm.customer.id
                    };
                    vm.location = {
                        province: res.province,
                        city: res.city,
                        district: res.district
                    }
                    loadData();
                });
            }
        })
    }
})();
