(function(){
	"use strict";
	angular
	.module("editorApp")
	.controller("EntityMoveFolderController", EntityMoveFolderController);
	EntityMoveFolderController.$inject = ['$scope', '$uibModalInstance', 'MessageService', '$stateParams',  'Folder', 'entity', 'Article', 'Gallery'];

    function EntityMoveFolderController( $scope,  $uibModalInstance, MessageService, $stateParams,  Folder, entity, Article, Gallery) {

       var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.entity = entity;
        if(entity.ids && entity.type){
            $scope.folders =  Folder.getAllByCondition({items: [{key: "type", op: "=", value: entity.type}]});
        }
        vm.folderId = entity.folderId || null;
        console.log(vm.folderId,'当前文件夹');

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.folderSelected = function(folder){
            if (folder == null) {
                //空目录
                $scope.folderId = "";

            }else  if (folder != null) {
                //文件夹
                if (folder.id == $scope.folderId) {
                    $scope.folderId = null;
                    folder.selected = false;
                }else{
                    for (var i = $scope.folders.length - 1; i >= 0; i--) {
                        var f = $scope.folders[i];
                        if (f.selected) {
                            f.selected = false;
                        }
                    }
                    folder.selected = true;
                    $scope.folderId = folder.id;
                }
            }

        }



        $scope.validate = function () {
            if ($scope.folderId == null) {
                MessageService.error("移动失败,请选择一个文件夹");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            if (entity.ids && entity.type == 'Article') {
                Article.moveFolder({articles: entity.ids , folderId: $scope.folderId}, onSaveSuccess, onSaveError)
            }else if (entity.ids && entity.type == 'Gallery') {
                //图片库操作  Gallery
                Gallery.moveFolder({galleries: entity.ids , folderId: $scope.folderId}, onSaveSuccess, onSaveError)

            }
        }
        function onSaveSuccess(result) {
            MessageService.success("移动成功");
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            MessageService.error("移动失败");
            vm.isSaving = false;
        }
    }

})();
