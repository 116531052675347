(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('OrderProtocolAgreementController', OrderProtocolAgreementController);

    OrderProtocolAgreementController.$inject = ['$stateParams', '$state', '$uibModalInstance', 'MessageService',  '$scope', '$q', 'entity' ];

    function OrderProtocolAgreementController($stateParams, $state, $uibModalInstance, MessageService,  $scope, $q, entity) {
        var vm = this;
        vm.data = entity || { quantity: 1};
        vm.data.agree = false;

        init();

        function init() {
            if (!vm.data.quantity) {
                vm.data.quantity = 1;
            }
        }

        $scope.close = function() {
            $uibModalInstance.dismiss();
        }

        $scope.agree = function() {
            $uibModalInstance.close({agree: true});
        }
    }
})();
