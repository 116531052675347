(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('AfterSalePageController', AfterSalePageController);

    AfterSalePageController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'previousState', 'entity', '$state',
    'Qinius', 'Theme', '$localStorage', 'AfterSale', '$timeout', 'MessageService', '$uibModal'];

    function AfterSalePageController($scope, $q, $rootScope, $stateParams, previousState, entity, $state, Qinius, Theme,
        $localStorage, AfterSale, $timeout, MessageService, $uibModal) {
        var vm = this;
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.afterSale = entity;
        vm.domains = Qinius.getDomain1();
        $q.all([vm.afterSale.$promise, vm.domains.$promise ]).then(function() {
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        });

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            // $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".table-responsive").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".table-responsive").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow","auto");
        }

        $scope.finishBtn = function () {
            AfterSale.setStateByOrder({orderId: vm.afterSale.salesOrder.id, state: 'Finish'}, function() {
                MessageService.success("售后反馈处理完成");
                $state.go('after-sale',{storeId: vm.storeId});
            });
        }


        $scope.applyForAfterSales = function(id){
            $uibModal.open({
                templateUrl: 'app/entities/after-sale/after-sale-new.html',
                controller: 'AfterSaleNewController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('after-sale');
                        return $translate.refresh();
                    }],

                    entity: ['$stateParams', 'AfterSale', function($stateParams, AfterSale) {
                        return AfterSale.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'sales-order',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            }).result.then(function() {
                $state.reload();
            }, function() {

            });
        }
    }
})();
