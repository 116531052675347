(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('ProductXmlFileServes', ProductXmlFileServes)
        .factory('ProductXml', ProductXml)
        .factory('Music', Music);

    ProductXml.$inject = ['$resource'];

    ProductXmlFileServes.$inject = ['$resource'];
    Music.$inject = ['$resource'];

    function ProductXmlFileServes ($resource) {
        var resourceUrl =  'fileserves/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function ProductXml ($resource) {
        var resourceUrl =  'manager/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray:true
            },
            "showPic":{
                method:"POST",
                params:{
                    path:"showPic"
                },
            },
            "approve":{
                method:"GET",
                params:{
                    path:"approve"
                }
            },
            "themeClassify":{
                method:"GET",
                params:{
                    path:"saveTheme"
                }
            },
            'update': { method:'PUT' },
            'findOne': { 
                method:'GET',
                params:{
                    path:"findOne"
                }
            }
        });
    }

    function Music($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/music/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'allByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'update': {
                method: 'GET',
                params: {
                    path: 'update'
                }
            },
            'save': {
                method: 'POST'
            },
            'get': {
                method: 'GET'
            },
            'del': {
                method: 'DELETE'
            }
        });
    }
})();
