(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CouponDetailController', CouponDetailController);

    CouponDetailController.$inject = ['$state', 'Coupon', 'ManagerCoupon', '$scope','$stateParams','Customer','$q',
    'DateUtils','MessageService', '$localStorage', '$uibModalInstance'];

    function CouponDetailController($state, Coupon, ManagerCoupon, $scope,$stateParams,Customer,$q,
                                    DateUtils,MessageService, $localStorage, $uibModalInstance) {

        var vm = this;
        vm.storeId = $stateParams.storeId || $state.params.storeId;
        vm.pId = $stateParams.pid || $state.params.pId;
        vm.couponsData = [];
        if (!vm.storeId) {
            return;
        }

        vm.store = $localStorage.store;
        vm.user = Customer.current({});
        vm.coupons2 = Coupon.getAllByCondition({items:[]});
        vm.coupons = ManagerCoupon.getAllByCondition({items:[{key: "endTime", op: ">=", value: DateUtils.convertLocalDateToServer(new Date())}, {key: "sendRule", op: "=",value: "Get"}, {key: "products.id",op: "=",value: vm.pid}]});


        $q.all([vm.user.$promise, vm.coupons.$promise, vm.coupons2.$promise]).then(function(){
            init()
        });
        function init() {
            // vm.emptyFlag = true;
            // angular.forEach(vm.coupons,function (coupon) {
            //     angular.forEach(coupon.products,function (products) {
            //         if(products.id==vm.pId){
            //             vm.couponsData.push(coupon);
            //         }
            //     })
            // })
            // vm.len = vm.couponsData.length;
            // if (vm.couponsData.length > 0) {
            //     for (var i = 0; i < vm.couponsData.length; i ++) {
            //         if(!vm.couponsData[i].time){
            //             vm.couponsData[i].time = 0;
            //         }
            //         for (var j = 0; j < vm.coupons2.length; j ++) {
            //             if (vm.couponsData[i].id == vm.coupons2[j].couponId) {
            //                 vm.couponsData[i].time += 1;
            //                 if(vm.couponsData[i].time >= vm.couponsData[i].accessTopLimit || vm.coupons2[j].status == 'available'){
            //                     vm.couponsData[i].cancelled = true;
            //                 }
            //             }
            //         }
            //     }
            // }
            vm.coupons =  vm.coupons.filter(function(item) {
                return item.totalQuantity > item.sendQuantity;
            });
            if (vm.coupons && vm.coupons.length>0) {
                var ids = "";
                for (var i = vm.coupons.length - 1; i >= 0; i--) {
                    var c = vm.coupons[i];
                    ids = ids+c.id+";";
                }
                if (!!ids) {
                    ids = ids.substring(0,ids.length-1 );
                }
                var couponItems2 =[
                {key:"sendRule",op:"=", value:"Get"},
                {key:"couponId",op:"in", value:ids}]
                Coupon.getAllByCondition({items:couponItems2}, function(data){
                    vm.coupons2 =  data;
                    if (vm.coupons2 && vm.coupons2.length>0) {
                        for (var i = vm.coupons.length - 1; i >= 0; i--) {
                            var c = vm.coupons[i];
                            if(!c.time){c.time = 0;};
                            vm.coupons2.forEach(function (userCoupons) {
                                if (userCoupons.couponId == c.id) {
                                    c.time += 1;
                                    if(c.time >= c.accessTopLimit || userCoupons.status == 'available'){
                                        c.received = true;
                                    }
                                }
                            });
                        }
                    }
                });
            }
            vm.couponsData = vm.coupons;
        }

        $scope.getCoupon = function(c, index){
            vm.isSaving = true;
            Coupon.getCoupon({id : c.id}, function(data) {
                // 伪刷新--数据修改 
                c.received = true;
                MessageService.success(data.message);
                vm.isSaving = false;
            }, function() {
                MessageService.error("优惠券领取失败");
                vm.isSaving = false;
            });
        }
       $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
