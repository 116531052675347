(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('AddCodeController', AddCodeController);

    AddCodeController.$inject = ['$stateParams', 'PayCodes', '$state', '$uibModalInstance', 'MessageService', 'entity', '$scope', '$q', '$localStorage',
    'GiftVoucher',  'ListService', 'ExchangeCode'];

    function AddCodeController($stateParams, PayCodes, $state, $uibModalInstance, MessageService, entity, $scope, $q, $localStorage,
     GiftVoucher, ListService, ExchangeCode) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.storeId = $stateParams.storeId;
        vm.id = $stateParams.id;
        vm.loading = true;
        if (!vm.storeId) {
            return;
        }
        vm.status = "payCode";

        vm.store = $localStorage.store;
        vm.line = entity.line;
        vm.order = entity.order;
        $q.all([vm.line.$promise]).then(function(){
            vm.loading = false;
            getCode();
            if (vm.store.payType.indexOf('paycode')<0) {
                vm.status = "giftVoucher";
            }
        });

        $scope.statusSelected = function(status){
            vm.status = status;
        }

        $scope.codeChange = function(item){
            if (item == null) {
                return;
            }
            if (item.selected) {
                if(vm.order.orderWay == 'orderThenEditor'){
                    for (var i = vm.order.productLines.length - 1; i >= 0; i--) {
                        var line = vm.order.productLines[i];
                        if (line.useAble && line.useAble.length>0) {
                            var result = ListService.inList(item, line.useAble, ["id"]);
                            if (result) {
                                result.unOptional = false;
                            }
                        }
                        if (line.useAbleGiftVouchers && line.useAbleGiftVouchers.length>0) {
                            var result = ListService.inList(item, line.useAbleGiftVouchers,  ["id"]);
                            if (result) {
                                result.unOptional = false;
                            }
                        }
                    }
                }else{
                    for (var i = vm.order.orderLines.length - 1; i >= 0; i--) {
                        var line = vm.order.orderLines[i];
                        if (line.useAble && line.useAble.length>0) {
                            var result = ListService.inList(item, line.useAble,  ["id"]);
                            if (result) {
                                result.unOptional = false;
                            }
                        }
                        if (line.useAbleGiftVouchers && line.useAbleGiftVouchers.length>0) {
                            var result = ListService.inList(item, line.useAbleGiftVouchers,  ["id"]);
                            if (result) {
                                result.unOptional = false;
                            }
                        }
                    }
                }
            }else{
                if(vm.order.orderWay == 'orderThenEditor'){
                    for (var i = vm.order.productLines.length - 1; i >= 0; i--) {
                        var line = vm.order.productLines[i];
                        if (line.itemValueLineId == vm.line.itemValueLineId) {
                            continue;
                        }
                        if (line.useAble && line.useAble.length>0) {
                            var result = ListService.inList(item, line.useAble, ["id"]);
                            if (result) {
                                result.unOptional = true;
                            }
                        }
                        if (line.useAbleGiftVouchers && line.useAbleGiftVouchers.length>0) {
                            var result = ListService.inList(item, line.useAbleGiftVouchers,  ["id"]);
                            if (result) {
                                result.unOptional = true;
                            }
                        }
                    }
                }else{
                    for (var i = vm.order.orderLines.length - 1; i >= 0; i--) {
                        var line = vm.order.orderLines[i];
                        if (vm.line.orderArticle &&  line.orderArticle &&vm.line.orderArticle.articleId == line.orderArticle.articleId) {
                            continue;
                        }
                        if (line.useAble && line.useAble.length>0) {
                            var result = ListService.inList(item, line.useAble,  ["id"]);
                            if (result) {
                                result.unOptional = true;
                            }
                        }
                        if (line.useAbleGiftVouchers && line.useAbleGiftVouchers.length>0) {
                            var result = ListService.inList(item, line.useAbleGiftVouchers,  ["id"]);
                            if (result) {
                                result.unOptional = true;
                            }
                        }
                    }
                }
            }
            item.selected = !item.selected;
            $scope.countQuantity(item);
        }

        $scope.countQuantity = function(item){
            var quantity = 0;
            if (vm.line.useAble && vm.line.useAble.length>0) {
                for (var i = vm.line.useAble.length - 1; i >= 0; i--) {
                    if (vm.line.useAble[i].selected) {
                        quantity++;
                    }
                }
            }
            if (vm.line.useAbleGiftVouchers && vm.line.useAbleGiftVouchers.length>0) {
                for (var i = vm.line.useAbleGiftVouchers.length - 1; i >= 0; i--) {
                    if (vm.line.useAbleGiftVouchers[i].selected) {
                        quantity++;
                    }
                }
            }
            console.log(quantity)
            if (quantity > vm.line.copy) {
                if(vm.status == 'payCode'){
                    var _is = false;
                    for (var i = vm.line.useAble.length - 1; i >= 0; i--) {
                        if (vm.line.useAble[i].selected && item.id != vm.line.useAble[i].id) {
                            vm.line.useAble[i].selected = false;
                            _is = true;
                            break
                        }
                    }
                    if(!_is){
                        for (var i = vm.line.useAbleGiftVouchers.length - 1; i >= 0; i--) {
                            if (vm.line.useAbleGiftVouchers[i].selected) {
                                vm.line.useAbleGiftVouchers[i].selected = false;
                                break
                            }
                        }
                    }
                }
                if(vm.status == 'giftVoucher'){
                    var _is = false;
                    for (var i = vm.line.useAbleGiftVouchers.length - 1; i >= 0; i--) {
                        if (vm.line.useAbleGiftVouchers[i].selected && item.id != vm.line.useAbleGiftVouchers[i].id) {
                            vm.line.useAbleGiftVouchers[i].selected = false;
                            _is = true;
                            break
                        }
                    }
                    if(!_is){
                        for (var i = vm.line.useAble.length - 1; i >= 0; i--) {
                            if (vm.line.useAble[i].selected) {
                                vm.line.useAble[i].selected = false;
                                break
                            }
                        }
                    }
                }
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save(){
            console.log(vm.line)
            console.log(vm.order)
            var data = {line:vm.line, order:vm.order}
            $uibModalInstance.close(data);
        }

        function getCode(callback) {
            var cond = [];
            if (vm.line == null) {
                return;
            }
            var itemValueLineId = null;
            if(vm.line.itemValueLineId!= null){
                itemValueLineId = vm.line.itemValueLineId ;
            }else{
                itemValueLineId = vm.line.orderArticle.itemValueLineId;
            }
            cond.push({uuid:vm.line.uuid, copy:vm.line.copy, quantity:vm.line.quantity, itemValueLineId:itemValueLineId, autoSelect: false});
            PayCodes.codeAvailable2(cond, function(res) {
                var result = res;
                if (res != null || res.length != 0) {
                    for (var j = res.length - 1; j >= 0; j--) {
                        var r = res[j];
                        if (vm.line.uuid == r.uuid) {
                            if (r.useAblePayCode && r.useAblePayCode.length>0) {
                                for (var i = r.useAblePayCode.length - 1; i >= 0; i--) {
                                    var p = r.useAblePayCode[i];
                                    var result = ListService.inList(p, vm.line.useAble, ["id"]);
                                    if (!result) {
                                        vm.line.useAble.push(p);
                                    }
                                }
                            }
                            if (r.useAbleGift && r.useAbleGift.length>0) {
                                for (var i = r.useAbleGift.length - 1; i >= 0; i--) {
                                    var p = r.useAbleGift[i];
                                    var result = ListService.inList(p, vm.line.useAbleGiftVouchers, ["id"]);
                                    if (!result) {
                                        vm.line.useAbleGiftVouchers.push(p);
                                    }
                                }
                            }
                            if (callback) {
                                callback()
                            }
                        }
                    }
                }
            });
        }

        $scope.bind = function(){
            vm.code = trim(vm.code);
            if(vm.code == '' || vm.code == undefined){
                MessageService.error("输入不能为空");
            }else if(vm.code.length < 6  || vm.code.length > 10  ){
                MessageService.error("请输入6~10位码");
            }else{
                if (vm.code.length == 10) {
                    ExchangeCode.toGiftVoucher({code:vm.code}, function(data){
                        if (data.status == 200) {
                            getCode(function () {
                                vm.status = 'giftVoucher';
                                if(data.message && data.message.length>0){
                                    var userCode = false;
                                    data.message.forEach(function (dataItem) {
                                        vm.line.useAbleGiftVouchers.forEach(function(item){
                                            if (item.code == dataItem.code) {
                                                userCode = true;
                                            }
                                        })
                                    })
                                    if (!userCode) {
                                        MessageService.success("兑换成功，该商品无法使用此礼物券");
                                        return
                                    }
                                }
                                MessageService.success("兑换成功");
                            });
                        }else{
                            MessageService.error(data.message);
                        }
                    },function(data){
                        MessageService.error("兑换失败，请稍后再试");
                    })
                }else{
                    PayCodes.bindUser({code: vm.code}, function (msg) {
                        if (msg.status == 200) {
                            getCode(function(){
                                vm.status = 'payCode';
                                var userCode = false;
                                vm.line.useAble.forEach(function(item){
                                    if (item.code == vm.code) {
                                        userCode = true;
                                    }
                                })
                                if (!userCode) {
                                    MessageService.success("兑换成功，该商品无法使用此支付码");
                                    return
                                }
                                MessageService.success("兑换成功");
                            });
                        }else{
                            MessageService.error(msg.message);
                        }
                    },function(){
                        MessageService.error("绑定失败，请稍后再试");
                    });
                }
            }
        }

        function trim(s){
            if (s == null || s =="") {
                return s;
            }
            var reg = new RegExp(" ","g");
            return s.replace(reg,"");
        }
    }
})();
