(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('BalanceController', BalanceController);

    BalanceController.$inject = ['$state', '$scope',  '$stateParams', '$q', 'pagingParams', 'paginationConstants', 'WxJS',
        '$timeout', 'MessageService', 'StoreRechargeSetting', 'entity', 'CustomerPayOrder', '$http', 'CustomerConsumptionList' , '$localStorage', 'Customer'
    ];

    function BalanceController($state, $scope, $stateParams, $q, pagingParams, paginationConstants, WxJS,
        $timeout, MessageService, StoreRechargeSetting, entity, CustomerPayOrder, $http, CustomerConsumptionList, $localStorage, Customer) {

        var vm = this;
        vm.customer = entity;
        vm.status = $stateParams.status ||1;
        vm.otherAmount = false;
        vm.transition = transition;
        vm.jump = jump;
        vm.wechartPayShow = false;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.storeRechargeSettings = StoreRechargeSetting.getAllByCondition({items:[{key:"enable", op:"=", value:"true"}]})


       function refreshCustomerBalance() {
            vm.customer = Customer.current();
       }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }


        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.store = $localStorage.store;
        $scope.loadData = function(){
            if (vm.status ==2) {
                CustomerPayOrder.byCondition1({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                }, function(data, headers){
                    vm.orders = data;
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                });
            }
            if (vm.status ==3) {
                CustomerConsumptionList.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                }, function(data, headers){
                    vm.list = data;
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                });
            }


        }
        $q.all([  vm.customer.$promise,  vm.storeRechargeSettings.$promise]).then(function() {
            $scope.loadData();
            trySelectFirst();
        });

        $scope.statusSelected = function(status){
            vm.status = status;
            vm.page = 1;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                status: vm.status,
                page: vm.page,
                storeId: vm.storeId
            });
        }

        $scope.isRed = function(type){
            if (type == "PayOrder" || type == "PayOrderDonation" || type == "CancelOrder" || type == "OrderRefund" || type == "Wallet" ) {
                return false;
            }
            return true;
        }

        $scope.getType = function(type){
            switch (type) {
                case 'PayOrder':
                    return "充值";
                    break;
                case 'PayOrderDonation':
                    return "充值赠送";
                    break;
                case 'SalesOrder':
                    return "支付订单";
                    break;
                case 'CancelOrder':
                    return "取消订单";
                    break;
                case 'OrderRefund':
                    return "订单退款";
                    break;
                case 'Wallet':
                    return "钱包转入";
                    break;
                default:
                    return "";
            }
        }


        $scope.rechargeSettingSeleted = function(item){
            if (item == null) {
                return;
            }
            item.selected = !item.selected;
            if (item.selected) {
                for (var i = vm.storeRechargeSettings.length - 1; i >= 0; i--) {
                    var setting = vm.storeRechargeSettings[i];
                    if (setting.id == item.id) {
                        continue;
                    }else{
                        setting.selected = false;
                    }

                }
            }
            vm.otherAmount = false;
        }

        $scope.complete = function(){
            vm.wechartPayShow = false;
            if ($scope.timer != null) {
               clearInterval($scope.timer);
            }
            refreshCustomerBalance();
        }

        $scope.payBalance = function(){
            var amount = 0;
            var storeRechargeSettingId = null;
            var donation = null;
            if (vm.otherAmount) {
                amount = vm.amount;
            }else{
                for (var i = vm.storeRechargeSettings.length - 1; i >= 0; i--) {
                    var setting = vm.storeRechargeSettings[i];
                    if (setting.selected) {
                        amount = setting.amount;
                        storeRechargeSettingId = setting.id;
                        donation = setting.donation;
                        break;
                    }
                }
            }
            if (amount == null || amount == 0) {
                MessageService.error("充值金额有误，请确认后再充值");
                return;
            }
            if (!vm.wechartPayShow) {
                CustomerPayOrder.save({amount:amount,storeRechargeSettingId:storeRechargeSettingId,donation:donation}, function(data){
                    if (data && !!data.id) {
                        vm.customerPayOrder = data;
                        WxJS.wechartQRPay({
                            id: vm.customerPayOrder.id,
                            storeId: vm.storeId,
                            totalFee: vm.customerPayOrder.amount,
                            names:"余额充值",
                            type:"web",
                            url: '/manager/api/customer-pay-orders/weChat-notify'
                        }, function(result){
                            console.log(result);
                            $('#qrcode1').html("");
                            // 支付二维码
                            $('#qrcode1').qrcode({
                                render: "canvas",
                                width: 176,
                                height: 176,
                                text: result.url
                            });
                            vm.wechartPayShow = true;
                            //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                            $scope.timer = setInterval(function() {
                                CustomerPayOrder.get({
                                    id: vm.customerPayOrder.id
                                }, function(val) {
                                    if (!!val && val.paid) {
                                        clearInterval($scope.timer);
                                        MessageService.success("支付完成");
                                    }
                                }, function() {});
                            }, 2000);
                        }, function(){
                            MessageService.error("充值订单生成失败，请稍后再试!");
                            return;
                        });
                    }else{
                        MessageService.error("充值订单生成失败，请稍后再试!");
                        return;
                    }
                })
            } else {
                vm.wechartPayShow = !vm.wechartPayShow;
            }
        }

        function trySelectFirst() {
            if (vm.storeRechargeSettings && vm.storeRechargeSettings.length) {
                var setting = vm.storeRechargeSettings[0];
                setting.selected = true;
            } else {
                vm.otherAmount = true;
            }
        }

        $scope.cancel = function(){
            if ($scope.timer != null) {
                clearInterval($scope.timer);
            }
            for (var i = vm.storeRechargeSettings.length - 1; i >= 0; i--) {
                var setting = vm.storeRechargeSettings[i];
                setting.selected = false;
            }
            vm.amount = undefined;
            vm.otherAmount = false;
        }

        $scope.otherAmount = function(){
            vm.otherAmount = !vm.otherAmount;
            for (var i = vm.storeRechargeSettings.length - 1; i >= 0; i--) {
                var setting = vm.storeRechargeSettings[i];
                setting.selected = false;
            }
        }

    }
})();
