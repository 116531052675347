
window.angular.module('common.service', []).
    factory('CommonService', ['$location', '$http', 'SweetAlert', '$timeout', '$rootScope', '$state','MessageNotification',
        function ($location, $http, SweetAlert, $timeout, $rootScope, $state,MessageNotification) {
            /**
             * 封装请求后端的middleware
             * @param params
             * @param success
             * @param failure
             */
            $rootScope.requestCount = 0;
            this.request = function(params, success, failure, hideLoading, errorCodeHandler) {
                if (!hideLoading) {
                    $rootScope.requestCount++;
                }
                params.header = {
                    Accept: 'application/json, text/plain, */*'
                };
                try {
                    $http(params).then(function(resp) {
                        if (!hideLoading) {
                            if ($rootScope.requestCount) {
                                $rootScope.requestCount--;
                            }
                        }
                        if (+resp.data.code) {
                            if (errorCodeHandler) {
                                errorCodeHandler(resp.data)
                            } else {
                                SweetAlert.swal('', resp.data.msg, 'error');
                            }
                        } else {
                            success(resp.data);
                        }
                    }, function(error)  {
                        if (!hideLoading) {
                            if ($rootScope.requestCount) {
                                $rootScope.requestCount--;
                            }
                        }
                        if (failure) {
                            failure(error);
                        }
                        //else {
                        //    SweetAlert.swal('', `request failed ${error.status} ${error.statusText}`, 'error');
                        //}
                    })
                } catch (e) {
                    console.log(e);
                }

            };


            /**
             *
             * Hack 微信中title修改不了的问题
             */
            this.setDocumentTitle = function(title) {
                document.title = title;
                if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
                    var i = document.createElement('iframe');
                    // i.src = '/favicon.ico';
                    i.style.display = 'none';
                    i.onload = function() {
                        setTimeout(function(){
                            i.remove();
                        }, 9)
                    };
                    document.body.appendChild(i);
                }
            };

            this.alert = function(title,text, callback, time, type){
                SweetAlert.swal({
                    title:title,
                    text: text,
                    imageUrl: "content/images/icons/pop_ic_msg_succeed.svg",
                    timer: 2000,
                    showConfirmButton:false
                });
                // SweetAlert.swal('', text, type ? type : 'success');
                $timeout(function()  {
                    if (callback) {
                        callback();
                    }
                }, time ? time : 2000);
            };

            /**
             * 深对比对象是否相等
             */
            this.checkObjectEqual = function(origin, current){
                var unEqual = false;
                Array.prototype.unique = function () {
                    var a = this.concat();
                    for (var i = 0; i < a.length; ++i) {
                        for (var j = i + 1; j < a.length; ++j) {
                            if (a[i] === a[j])
                                a.splice(j--, 1);
                        }
                    }
                    return a;
                };

                var checkObject = function (ori, cur) {
                    if (unEqual) return;
                    if (!ori || !cur) return;
                    const keys1 = Object.keys(ori);
                    const keys2 = Object.keys(cur);
                    const keys = keys1.concat(keys2).unique();
                    //console.log(keys);
                    angular.forEach(keys, function(key) {
                        if (unEqual) return;
                        if (key == '$$hashKey') return;
                        if (angular.isObject(ori[key]) && angular.isObject(cur[key])) {
                            checkObject(ori[key], cur[key]);
                        } else {
                            switch (typeof ori[key]) {
                                case 'string':
                                    if (ori[key].length) {
                                        unEqual = (ori[key] != cur[key]);
                                    } else {
                                        unEqual = !!cur[key] || (angular.isObject(cur[key]) && Object.keys(cur[key].length));
                                    }
                                    break;
                                case 'number':
                                    if (ori[key]) {
                                        unEqual = (ori[key] != cur[key]);
                                    } else {
                                        unEqual = !!cur[key]  && (cur[key]!= '0');
                                    }
                                    break;
                                case 'object':
                                    if (ori[key] == null) {
                                        unEqual = !!cur[key] || (angular.isObject(cur[key]) && Object.keys(cur[key].length));
                                    } else {
                                        if (Object.keys(ori[key]).length) {
                                            unEqual = true;
                                        } else {
                                            unEqual = !!cur[key];
                                        }
                                    }
                                    break;
                                case 'undefined':
                                    unEqual = !!cur[key] || (angular.isObject(cur[key]) && Object.keys(cur[key].length));
                                    break;
                                default:
                                    unEqual = (!ori[key] == !!cur[key]);
                                    break;
                            }
                        }

                    });
                };

                checkObject(origin, current);
                console.log(">>>>>>>>>finall--"+unEqual);
                return unEqual;

            };


            /**
             * 切换路由时检查当前是否有表单为提交
             */
            this.checkDirtyPage = function($scope, checkFormDirty, callback) {
                $scope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
                    $('.sweet-alert').removeClass('hide-button');

                    if (checkFormDirty() && !$scope.cancelCheck) {
                        e.preventDefault();
                        SweetAlert.swal({
                                title: '',
                                text: "当前页面有改动尚未保存，小主确定要离开吗？",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonText: "狠心离开",
                                cancelButtonText: "取消",
                                closeOnConfirm: true,
                                closeOnCancel: true
                            },
                            function () {
                                $scope.cancelCheck = true;
                                $state.go(toState.name, toParams);
                            });
                    } else {
                        $('.sweet-alert .confirm').trigger("click");
                        $rootScope.leaveEditPage = true;
                    }

                });
            };

            this.uploadFile = function(file, callback, shouldReadIMGInfo) {
                // var ext = '-' + ipCookie('userid') + '-' + parseInt(Math.random()*100000) + '.png';
                //
                // if (!this.instance) {
                //     var config = {
                //         bucket: window.diyUpyunConfig.bucket,
                //         expiration: parseInt((new Date().getTime() + 3600000) / 1000),
                //         form_api_secret: window.diyUpyunConfig.form_api_secret
                //     };
                //
                //     var instance = new Sand(config);
                //
                //     this.instance = instance;
                // }
                //
                // this.instance.upload('/upload/template/diy/' + parseInt((new Date().getTime() + 3600000) / 1000) + ext, file, function(resp) {
                //     if (!resp) {
                //         SweetAlert.swal('', "图片上传失败！没有返回值", 'error');
                //         return;
                //     }
                //     var path = imagePath+resp.path;
                //     if (shouldReadIMGInfo) {
                //         $http({url: path+"!imageInfo", method: 'get'}).then(function(resp) {
                //             if (!resp.data) {
                //                 SweetAlert.swal('', '获取图片信息失败！Sad...', 'error');
                //                 return;
                //             }
                //             var response = {
                //                 url: '' + path,
                //                 width: resp.data.width,
                //                 height: resp.data.height
                //             };
                //             if (resp.data.EXIF) {
                //                 var orientation = resp.data.EXIF.Orientation;
                //                 if (orientation == '6' || orientation == '8') {
                //                     response.width = resp.data.height;
                //                     response.height = resp.data.width;
                //                 }
                //             }
                //             callback(response);
                //         });
                //     } else {
                //         callback(path);
                //     }
                //
                // }, function(err) {
                //     console.error(err);
                //     $('.sweet-alert').removeClass('hide-button');
                //     SweetAlert.swal('', "图片上传失败，请重新上传", 'info');
                // });
            };



            this.browserVersions = function () {
                var browser = {
                    versions: function () {
                        var u = navigator.userAgent, app = navigator.appVersion;
                        return {         //移动终端浏览器版本信息
                            trident: u.indexOf('Trident') > -1, //IE内核
                            presto: u.indexOf('Presto') > -1, //opera内核
                            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                            iPad: u.indexOf('iPad') > -1, //是否iPad
                            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
                        };
                    }(),
                    language: (navigator.browserLanguage || navigator.language).toLowerCase()
                }
                return browser;
            }

            this.messageNotfication = function (pageType, timestr,callback) {
                var vm = this;
                MessageNotification.getAllByCondition2({
                    items: [
                        {key: "available", op: "=", value: "true"},
                        {key: "validate", op: ">=", value: timestr},
                        {key: "pageType", op: "=", value: pageType}]
                }, function (res) {
                    res.sort(vm.compare1('seq'));
                    var text = null;
                    if(res&&res.length){
                        text = "\xa0\xa0\xa0\xa0\xa0\xa0";
                        angular.forEach(res, function (resp, i) {
                            text += resp.content
                            if (i != res.length - 1) {
                                text += "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                            }
                        })
                    }
                    callback.call(this, text);
                });
            }

            this.compare1 = function(property){
                return function (a, b) {
                    var value1 = a[property];
                    var value2 = b[property];
                    return value1 - value2;
                }
            }

            this.workSave = function () {
                var r = window.location.href.split('?')[1];
                var typeFlag = r.split('&')[0].split('=')[1];
                var authorize = r.split('&')[1].split('=')[1];
                var productionType = r.split('&')[2].split('=')[1];
                var formLibrary = r.split('&')[3].split('=')[1];
                var editLibrary = decodeURIComponent(formLibrary);
                var workSave = JSON.parse(localStorage.getItem("work-save"));
                var pid = editLibrary.split(',')[0];
                var vid = editLibrary.split(',')[1];
                var xmlId = editLibrary.split(',')[2];
                if (workSave == null) {
                    var obj = {
                        pid: pid,
                        vid: vid,
                        xmlId: xmlId,
                        date: new Date().getTime()
                    };
                    localStorage.setItem("work-save", JSON.stringify(obj));
                    localStorage.removeItem("pages");
                    localStorage.removeItem("gallery");
                    localStorage.removeItem("galleryCopy");
                } else {
                    var currentDate = new Date().getTime();
                    var flag = (currentDate - workSave.date) > 3 * 3600 * 1000 ? true : false;
                    if (workSave.pid != pid || workSave.vid != vid || flag) {
                        localStorage.removeItem("pages");
                        localStorage.removeItem("gallery");
                        localStorage.removeItem("galleryCopy");
                        var obj = {
                            pid: pid,
                            vid: vid,
                            xmlId: xmlId,
                            date: new Date().getTime()
                        };
                        localStorage.setItem("work-save", JSON.stringify(obj));
                    }
                }
                return workSave
            }

            this.isLogin = function ($scope,name,url) {
                setTimeout(function () {
                    var arr={
                        isAutor:true,
                        url:url||null,
                        tag:name||null
                    }
                    $rootScope.$broadcast('changeAutor',arr);
                    $scope.$digest()
                },400)
            }

            return this;

        }]);
