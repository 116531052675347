(function() {
    'use strict';

    angular
        .module('editorApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage', '$stateParams', '$timeout'];

    function authInterceptor ($rootScope, $q, $location, $localStorage, $sessionStorage, $stateParams, $timeout) {
        var service = {
            request: request
        };
        var DateZoneOffset_Value = ( - new Date().getTimezoneOffset() / 60);

        return service;

        function request (config) {
            if (!config || !config.url || /^http/.test(config.url)) return config;

            /*jshint camelcase: false */
            config.headers = config.headers || {};
            config.headers["DateZone-Offset"] = DateZoneOffset_Value;
            if($stateParams.source && $localStorage.authenticationTokenSource){
                var token = $localStorage.authenticationTokenSource || $sessionStorage.authenticationTokenSource;
            }else{
                var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            }
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            if($localStorage.store && $localStorage.user && $localStorage.user.inactive && $localStorage.editorNotifyUrl){
                $timeout(function () {
                    window.location.href = $localStorage.editorNotifyUrl + '/#/' + $localStorage.store.id + '/outofservice';
                    // window.location.href = 'http://localhost:8091/#/' + $localStorage.store.id + '/outofservice';
                },200)
            }
            return config;
        }
    }
})();
