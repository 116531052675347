(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CollectionController', CollectionController);

    CollectionController.$inject = ['$scope', '$state', 'MessageService',
        '$q', '$stateParams', '$uibModal', 'Product', 'Store', '$localStorage', '$timeout',
        'SkuSellLimits', 'Collection'];

    function CollectionController($scope, $state, MessageService,
        $q, $stateParams, $uibModal, Product, Store, $localStorage, $timeout,
        SkuSellLimits, Collection){

        var vm = this;
        vm.storeId = $stateParams.storeId;
        vm.domain = $localStorage.domain;
        vm.collections = [];
        $scope.moveCollectionIds = [];
        $scope.moveProductIds = [];
        vm.page = 0;
        vm.itemsPerPage = 20;
        $scope.collectionManager = false;
        $scope.selectAllCollection = false;
        loadAll();

        function loadAll(){
            Collection.byCondition({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                return ["id,desc"];
            }
            function onSuccess(data,headers) {
                Product.getProductsClient({storeId:vm.storeId},function(res){
                    vm.loading = false;
                    angular.forEach(data.message, function (a) {
                        angular.forEach(res,function(b){
                            if (a.productId==b.id) {
                                a.price=b.price
                            }
                        })
                    })
                    data.message.forEach(function(item){
                        vm.collections.push(item);
                    })
                    console.log(vm.collections)
                },function(){
                    vm.loading = false;
                })
                vm.totalItems = headers('X-Total-Count');
            }
            function onError(error) {
                vm.loading = false;
            }
        }

        $scope.deleteCollections = function() {
            if (!$scope.moveCollectionIds.length) {
                MessageService.error("操作失败,请至少选择一个作品");
                return
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'您确认要取消收藏吗？'
            },function(){
                Collection.batchDelete($scope.moveProductIds, function (){
                    vm.page = 0;
                    vm.collections = [];
                    $scope.moveCollectionIds=[];
                    loadAll()
                    MessageService.success("取消收藏成功")
                }, function() {
                    MessageService.error("操作失败,请稍后操作或者联系管理员")
                })
            });
        }
        $scope.deleteCollectionOne = function (collection,event){
            event.stopPropagation()
            var ids = [];
            ids.push(collection.productId)
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'您确认要取消收藏吗？'
            },function (){
                Collection.batchDelete(ids, function (){
                    vm.page = 0;
                    vm.collections = [];
                    loadAll()
                    MessageService.success("取消收藏成功")
                }, function() {
                    MessageService.error("操作失败,请稍后操作或者联系管理员")
                })
            });
        }

        $scope.selectSingle = function (collection,event) {
            event.stopPropagation()
            if(!$scope.collectionManager){
                return
            }
            collection.selected = !collection.selected;
            if (collection.selected){
                $scope.moveCollectionIds.push(collection.id);
                $scope.moveProductIds.push(collection.productId);
            }else{
                var inde1 = $scope.moveCollectionIds.indexOf(collection.id);
                $scope.moveCollectionIds.splice(inde1,1);
                $scope.moveProductIds.splice(inde1,1)
            }
            if ($scope.moveCollectionIds.length == vm.collections.length) {
                $scope.selectAllCollection = true;
            }else{
                $scope.selectAllCollection = false;
            }
        }

        $scope.managerCollection = function (){
            $scope.collectionManager = true;
        }
        $scope.cancelCollectionManager = function(){
            $scope.moveCollectionIds = [];
            $scope.moveProductIds = [];
            $scope.collectionManager = false;
            for (var i = vm.collections.length - 1; i >= 0; i--) {
                var a = vm.collections[i];
                if (a.selected) {
                    a.selected = false;
                }
            }
        }
        $scope.collectionAll = function (){
            $scope.selectAllCollection = !$scope.selectAllCollection;
            if($scope.selectAllCollection){
                $scope.moveCollectionIds = [];
            }
            angular.forEach(vm.collections, function (collection, i) {
                if ($scope.selectAllCollection) {
                    collection.selected = true;
                    $scope.moveCollectionIds.push(collection.id);
                    $scope.moveProductIds.push(collection.productId);
                } else {
                    collection.selected = false;
                    $scope.moveCollectionIds = [];
                    $scope.moveProductIds = [];
                }
            })
        }

        $scope.commoditySelect = function (data) {
            if($scope.collectionManager){
                return
            }
            if(data.productCancelled){
                return
            }
            if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                $scope.login();
            } else {
                $state.go('standardCommodityDetails', {storeId: vm.storeId, pid: data.productId, from:'collection'});
            }
        };

        $scope.login = function () {
            createModal('app/account/register/register.html', 'RegisterController', "Login").result.then(
                function () {
                });
        };

        // 页面滚动，加载数据
        $('.home-body').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(vm.collections.length < vm.totalItems){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.page++;
                    loadAll()
                }
            }
        });
    }
})();
