(function () {
    'use strict';

    var trimboxPop = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/trimbox-pop.html',
        controllerAs: 'vm',
        bindings: {
        },
        controller: ['$scope',
            function ($scope) {
                var vm = this;
                var lsTrimboxPop = localStorage.getItem('trimbox-pop');
                vm.isTrimbox = lsTrimboxPop && lsTrimboxPop == "false" ? false : true;
                vm.checkbox = false;
                $scope.checkbox = function () {
                    vm.checkbox = !vm.checkbox;
                }
                $scope.save = function () {
                    vm.isTrimbox = false;
                    if(vm.checkbox){
                        localStorage.setItem('trimbox-pop', false);
                    }else{
                        localStorage.setItem('trimbox-pop', true);
                    }
                }
            }
        ]
    };

    angular
        .module('editorApp')
        .component('trimboxPop', trimboxPop);
})();

