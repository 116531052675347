(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('BoxCopyController', BoxCopyController);

    BoxCopyController.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService','entity'];

    function BoxCopyController( $scope, Category, $uibModalInstance, MessageService,entity) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.types = [
            {name: '从当前页开始', page: 'this'},
            {name: '从第一页开始', page: 'first'}
        ];

        vm.data={
            range:'this',
            generLoc:'all'
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function () {
            console.log(vm.data)
            if (vm.data.range == null || vm.data.range == "") {
                MessageService.error("请选择范围");
                return false;
            }
            if (vm.data.generLoc == null || vm.data.generLoc == "") {
                MessageService.error("请选择生成位置");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            onSaveSuccess(vm.data);
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();
