(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('ManagerCoupon', ManagerCoupon)
        .factory('Coupon', Coupon);

    ManagerCoupon.$inject = ['$resource', 'DateUtils'];
    Coupon.$inject = ['$resource', 'DateUtils'];

    function ManagerCoupon ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/coupons/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertLocalDateFromServer(data.startTime);
                        data.endTime = DateUtils.convertLocalDateFromServer(data.endTime);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "getCoupon":{
                method:"GET",
                params:{
                    path:"getCoupon"
                }
            }
        });
    }

    function Coupon ($resource, DateUtils) {
        var resourceUrl =  'service/' + 'api/coupons/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertLocalDateFromServer(data.startTime);
                        data.endTime = DateUtils.convertLocalDateFromServer(data.endTime);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startTime = DateUtils.convertLocalDateToServer(copy.startTime);
                    copy.endTime = DateUtils.convertLocalDateToServer(copy.endTime);
                    return angular.toJson(copy);
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray: true
            },

            "couponAvailable2":{
                method:"POST",
                params:{
                    path:"couponAvailable2"
                },
            },
            "useCoupon":{
                method:"POST",
                params:{
                    path:"useCoupon"
                },
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "getCoupon":{
                method:"GET",
                params:{
                    path:"getCoupon"
                }
            }
        });
    }
})();
