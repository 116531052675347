(function () {
    'use strict';

    var DuplicateListControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/duplicate-list.html',
        controllerAs: 'vm',
        bindings: {
            list: '=',
            onChange:'&'
        },

        controller: ['$scope','FontManagement','$timeout', 'Gallery','MessageService', function ($scope,FontManagement,$timeout,Gallery,MessageService){
            var vm = this;
            $scope.deleteImg = function (index){
                var _item = angular.copy([vm.list[index]]);
                console.log(_item)
                deleteImgs([vm.list[index].id],function (){
                    vm.list.splice(index,1);
                    vm.onChange({text:JSON.stringify(_item)})
                });
            }
            function deleteImgs(ids,callback){
                Gallery.batchDelete(ids,function (res){
                    callback();
                    MessageService.success("去重成功！");
                });
            }
            $scope.modalHide = function (){
                vm.onChange()
            }
            vm.loading = false;
            $scope.modalSubmint = function (){
                vm.loading = true;
                var ids = [];
                vm.list.forEach(function (item){
                    ids.push(item.id)
                })
                deleteImgs(ids,function (){
                    if(vm.list&&vm.list.length>0){
                        vm.onChange({text:JSON.stringify(vm.list)})
                    }else{
                        vm.onChange()
                    }
                    vm.loading = false;
                })
            }
        }]
    };

    angular.module('editorApp')
    	.component('duplicateList', DuplicateListControl);

})();
