(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', '$q', 'ProfileService', 'LoginService', '$scope', '$localStorage', '$uibModal',
    "$stateParams", "Product", "Store", "Qinius", "$cookieStore", 'MessageService', 'StoreConfig','Theme'];

    function NavbarController ($state, Auth, Principal, $q, ProfileService, LoginService, $scope, $localStorage, $uibModal,
        $stateParams, Product, Store, Qinius, $cookieStore, MessageService, StoreConfig, Theme) {
        var vm = this;
        console.log('进来了...')
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.login = login;
        vm.register = register;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.isLogin = false;
        vm.hidden = false; //隐藏所有页面内容 禁止访问
        vm.errorType = null; //1 没有端口 2 没有激活  3 已经过期
        vm.storeId = $stateParams.storeId || $state.params.storeId || $cookieStore.get("store-id");
        if (!vm.storeId) {
            return;
        }
        vm.store = $localStorage.store;
        if (vm.store == null) {
            Store.get({id:vm.storeId},function(data){
                vm.store = data;
                $localStorage.store = vm.store;
                if (vm.store.storeType2.indexOf("WEB")<0) {
                    vm.hidden = true;
                }
            });
        }
        
        $scope.domain = $localStorage.domain;
        if ($scope.domain == null  || $scope.domain == "") {
            Qinius.getDomain1({storeId: vm.storeId},function(da){
                $scope.domain = 'https://' + da.domains[0] + '/';
                $localStorage.domain = $scope.domain;
            });
        }

        vm.sidebar2Shrik = sidebar2Shrik;
        vm.titleNavbar = titleNavbar;
        vm.lmenuClick = lmenuClick;
        vm.account = $localStorage.user;
        vm.navSelection = "";
        vm.storeConfig = StoreConfig.byStoreIdAndType2({storeId:vm.storeId, type:"SHOPPINGCART"});
        vm.theme = StoreConfig.byStoreIdAndType2({type: 'Theme', storeId: vm.storeId});
        // vm.commodityList = Product.getProductsClient({storeId:vm.storeId, source:"web"});
        userAgent();
        $q.all([  vm.storeConfig.$promise , vm.theme.$promise]).then(function () {
            vm.commodityList = vm.commodityList.sort(function(a, b){
                return a.seq - b.seq;
            })
            vm.logoUrl = vm.store && vm.store.menuLogo ? $scope.domain + vm.store.menuLogo : 'content/images/logo.png';
            if (vm.store && vm.store.storeType2.indexOf("WEB")<0) {
                vm.hidden = true;
                vm.errorType = 1;
            }
            if (vm.store && !vm.store.activated) {
                vm.hidden = true;
                vm.errorType = 2;
            }
            if (vm.store && new Date(vm.store.periodOfValidity+" 23:59:59").getTime()< new Date().getTime()) {
                vm.hidden = true;
                vm.errorType = 3;
            }
            if (vm.theme) {
                localStorage.setItem('Theme', vm.theme.value);
            } else {
                localStorage.setItem('Theme', 'one');
            }
            Theme.judge();
        });

        //作品库
        if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
            vm.isLogin = false;
            vm.commodityList = Product.getProductsClient({storeId:vm.storeId});
        }else{
            vm.isLogin = true;
            vm.commodityList = Product.getProductsClient({storeId:vm.storeId, branchStoreId: $localStorage.user.branchStoreId});
        }
         
        $scope.goWebArticle=function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'myWork';
                $state.go("webArticle", {storeId:vm.storeId});
            }

        }

        $scope.getName = function(){
            if (vm.account.name == "未知"  && !!vm.account.phone) {
                return vm.account.phone.substring(0,3)+"****"+vm.account.phone.substring(7);
            }else{
                return vm.account.name;
            }
        }
        $scope.goWebGallery=function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'myPhoto';
                $state.go("webGallery", {storeId:vm.storeId});
            }
        }

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
            $(".titleNavbar").eq(vm.menu).find($(".arrowsB")).removeClass('arrows').addClass('arrowsTop');
            // 菜单刷新后定位
            vm.lmenu = sessionStorage.getItem("lmenu") == null ? 'a_storeManagement' : sessionStorage.getItem("lmenu");
        });

        var $bar1 = $(".sidebar1-btn");
        var $side1 = $(".sidebar1");
        var $bar2 = $(".sidebar2-btn");
        var $side2 = $(".sidebar2");
        var isHide = false;
        var $navbar = $(".navbar-wrap");
        window.showSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left2');
                return
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left');
        };

        window.hideSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
                return
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left');
        };

        function lmenuClick(a_id) {
            vm.lmenu = a_id;
            sessionStorage.setItem("lmenu", a_id);
        }

        var createModal = function (url, controller, type) {
            if (type == null) {
                type = "Login";
            }

            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:{
                        type:type
                    },
                }
            });
        };

        function login() {
            createModal('app/account/register/register.html', 'RegisterController', "Login").result.then(
                function () {
                });
        };

        function register(){
            createModal('app/account/register/register.html', 'RegisterController', "Register").result.then(
                function () {
                });
        }



        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go("commodity",{storeId: vm.storeId}, {reload:true});
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }


        function shrink() {
            $navbar.toggleClass("sidebar-collapse");
            $side2.addClass('transition');
            if (isHide) {
                if ($navbar.hasClass("sidebar-collapse")) {
                    $bar1.removeClass('btn1-shrik1').addClass("btn1-shrik2");
                    $navbar.toggleClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
                    $(".wrap").removeClass("wrap-mar130").removeClass("wrap-mar50").toggleClass("wrap-mar60");
                } else {
                    $bar1.removeClass('btn1-shrik2').addClass("btn1-shrik1");
                    $(".wrap").removeClass("wrap-mar50").removeClass("wrap-mar60").toggleClass("wrap-mar130");
                    $navbar.toggleClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                }
            } else {$bar1.toggleClass('btn1-shrik1').toggleClass("btn1-shrik2");
                $(".wrap").toggleClass("wrap-mar50");
            }
            return false;
        }

        $scope.resetPassword = function(){
            createModal('app/account/password/password.html', 'PasswordController').result.then(
                function(data) {
                });
        };

        function titleNavbar(i) {
            var $titleNavbar = $(".titleNavbar");
            var $menu1 = $(".menu1");
            // click时清空一级菜单样式
            $titleNavbar.find($(".navbar-title")).css({
                background: '#3c434d'
            });
            $titleNavbar.find($(".arrowsB")).removeClass('arrowsTop').addClass('arrows');
            //若二级菜单为隐藏状态：
            if ($titleNavbar.eq(i).find($menu1).is(':hidden')) {
                //箭头运动状态向下
                $titleNavbar.eq(i).find($(".arrowsB")).removeClass('arrows').addClass('arrowsTop');
                // click时改变一级菜单背景色和字体色
                $titleNavbar.eq(i).find($(".navbar-title")).css({
                    background: '#313840'
                });
                //显示当前二级菜单
                $titleNavbar.eq(i).find($menu1).slideDown(400);
                localStorage.setItem("menu1",i);
                //隐藏其他二级菜单
                $titleNavbar.eq(i).siblings().find($(".menu1")).hide();

            } else {
                $titleNavbar.eq(i).find($(".arrowsB")).removeClass('arrowsTop').addClass('arrows');
                $titleNavbar.eq(i).find($(".navbar-title")).css({
                    background: '#313840'
                });
                $titleNavbar.eq(i).find($menu1).slideUp(400);
                $titleNavbar.eq(i).siblings().find($menu1).hide();
            }
        }

        function sidebar2Shrik() {
            if(!($(".sidebar2-shrink").hasClass('shidebar2-shrink-left')||$(".sidebar2-shrink").hasClass('shidebar2-shrink-left2'))){
                return
            }

            $side2.removeClass('transition');
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
            isHide = !isHide;

            if(isHide){
                $(".sidebar2").addClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik1').addClass("btn2-shrik2");
            }else {
                $(".sidebar2").removeClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik2').addClass("btn2-shrik1");
            }
            if ($navbar.hasClass("sidebar-collapse")) {
                $(".wrap").toggleClass("wrap-mar50").toggleClass("wrap-mar60");
                $navbar.removeClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
            } else {
                $navbar.removeClass("sidebar-collapse2").removeClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                $(".wrap").removeClass("wrap-mar50").toggleClass("wrap-mar130");
            }
        }


        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        $scope.salesCommodity = function () {
            route();
            vm.navSelection = "";
            $state.go('commodity', {storeId: vm.storeId});
        };
        $scope.commoditySelect = function (data) {
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = data.id;
               // if (data.productType == 'Standard' || data.productType == 'StandardPrint' ) {
                    $state.go('standardCommodityDetails', {storeId: vm.storeId, pid: data.id});
               // }else {
               //      $state.go('commoditySelect', {storeId: vm.storeId, pid: data.id});
               // }

            }
        };

        $scope.salesOrder = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'myOrder';
                $state.go('sales-order', {storeId: vm.storeId});
            }
        }

        $scope.coupon = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'myCoupon';
                $state.go('coupon', {storeId: vm.storeId});
                // $state.go('coupon-detail', {storeId: vm.storeId});
            }
        }

        $scope.payCard = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'myCard';
                $state.go('pay-code', {storeId: vm.storeId});
            }
        }

        $scope.giftVoucher = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'giftVoucher';
                $state.go('gift-voucher', {storeId: vm.storeId});
            }
        }

        $scope.address = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'myAddress';
                $state.go('address', {storeId: vm.storeId});
            }
        }

        $scope.afterSale = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'mySale';
                $state.go('after-sale', {storeId: vm.storeId});
            }
        }

        $scope.cart = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'cart';
                $state.go('cart', {storeId: vm.storeId});
            }
        }

        $scope.balance = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'balance';
                $state.go('balance', {storeId: vm.storeId});
            }
        }


        $scope.salesOrder = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'myOrder';
                $state.go('sales-order', {storeId: vm.storeId});
            }
        }

        $scope.wechat = function(){
            route();
            if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
                $scope.login();
            }else{
                vm.navSelection = 'myWechat';
                $state.go('webWechat', {storeId: vm.storeId});
            }
        }



        if($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null){
            vm.isLogin = false;
        }else{
            vm.isLogin = true;
        }

        $scope.login = function () {
            createModal('app/account/register/register.html', 'RegisterController').result.then(
                function () {
                });
        };
        function route() {
            var _class = angular.element('#myWork').attr('class');
            if(_class == 'pageOn'){
                angular.element('#myWork').removeClass('pageOn');
            }
        }

        function compareDate(s1,s2){
            return ((new Date(s1.replace(/-/g,"\/")))>(new Date(s2.replace(/-/g,"\/"))));
        }
        function getNowFormatDate() {
            var date = new Date();
            var seperator1 = "-";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        }


        if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
            handleFontSize();
        } else {
            if (document.addEventListener) {
                document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
            } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", handleFontSize);
                document.attachEvent("onWeixinJSBridgeReady", handleFontSize);  }
        }
        function handleFontSize() {
            // 设置网页字体为默认大小
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
            // 重写设置网页字体大小的事件
            WeixinJSBridge.on('menu:setfont', function() {
                WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
            });
        }

        //默认浏览器
        function userAgent() {
            if(!isMobile()){
                var _userAgentTime = localStorage.getItem("userAgentTime");
                if(getExploreName().indexOf('Chrome') == -1 && time()!=_userAgentTime){
                    $uibModal.open({
                        templateUrl: 'app/layouts/navbar/userAgent.html',
                        controller: 'UserAgentController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        resolve: {}
                    });
                }
            }
        }
        function isMobile(){
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        function time() {
            var d = new Date();
            var getFullYear = d.getFullYear();
            var getMonth = d.getMonth() + 1;
            var getDate = d.getDate();
            return getFullYear + '-' + getMonth + '-' + getDate
        }
    }
})();
