(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('Gallery', Gallery)
        .factory('VerifyCode', VerifyCode)
        .factory('Customer', Customer)
        .factory('StoreStoragePrice', StoreStoragePrice)
        .factory('StoreStoragePayOrder', StoreStoragePayOrder)
        .factory('Folder', Folder)
        .factory('CustomerStorages', CustomerStorages)
        .factory('RecommendPics', RecommendPics);

    Gallery.$inject = ['$resource'];
    Folder.$inject = ['$resource'];
    StoreStoragePrice.$inject = ['$resource'];
    StoreStoragePayOrder.$inject = ['$resource'];
    Customer.$inject = ['$resource'];
    VerifyCode.$inject = ['$resource', 'DateUtils'];
    CustomerStorages.$inject = ['$resource', 'DateUtils'];
    RecommendPics.$inject = ['$resource', 'DateUtils'];

    function RecommendPics ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/recommend-pics/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
        });
    }
    function Customer ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/customers/:path/:id';

        return $resource(resourceUrl, {}, {
            "getByView":{
                method:"GET",
                params:{
                    path:"getByView"
                }
            },
            "analysisLocation":{
                method:"GET",
                params:{
                    path:"analysisLocation"
                }
            },
            "getLocation":{
                method:"GET",
                params:{
                    path:"getLocation"
                }
            },
            "addLocation":{
                method:"POST",
                params:{
                    path:"addLocation"
                }
            },
            "updateLocation":{
                method:"POST",
                params:{
                    path:"updateLocation"
                }
            },
            "setDefaultLocation":{
                method:"GET",
                params:{
                    path:"setDefaultLocation"
                }
            },
            "removeLocation":{
                method:"GET",
                params:{
                    path:"removeLocation"
                }
            },
            "current":{
                method:"GET",
                params:{
                    path:"current"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'setPhone': {
                method: 'GET',
                params: {
                    path:"setPhone"
                }
            },
            "register":{
                method:"POST",
                params:{
                    path:"register"
                }
            },
            "register2":{
                method:"POST",
                params:{
                    path:"register2"
                }
            },

            "checkPhone":{
                method:"GET",
                params:{
                    path:"checkPhone"
                }
            },
            "checkPhone2":{
                method:"GET",
                params:{
                    path:"checkPhone2"
                }
            },
            "checkPassword":{
                method:"GET",
                params:{
                    path:"checkPassword"
                }
            },
            "changePassword":{
                method:"POST",
                params:{
                    path:"changePassword"
                }
            },
            "authenticate":{
                method:"GET",
                params:{
                    path:"authenticate"
                }
            }
        });
    }


    function VerifyCode ($resource, DateUtils){
        var resourceUrl = 'manager'+ '/api/verify-codes/:path/:id';
        return $resource(resourceUrl,{},{
            "getVerifyCode":{
                method:"GET",
                params:{
                    path:"getVerifyCode"
                }
            },
            "check":{
                method:"GET",
                params:{
                    path:"check"
                }
            }
        })
    }

    function StoreStoragePayOrder ($resource, DateUtils){
        var resourceUrl = 'manager'+ '/api/customer-storage-pay-orders/:path/:id';
        return $resource(resourceUrl,{},{
            "createOrder":{
                method:"POST",
                params:{
                    path:"createOrder"
                }
            },
            "wechatQRPay":{
                method:"get",
                params:{
                    path:"wechatQRPay"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        })
    }


    function StoreStoragePrice ($resource, DateUtils){
        var resourceUrl = 'manager'+ '/api/store-storage-prices/:path';
        return $resource(resourceUrl,{},{
            "byCondition1":{
                method:"POST",
                params:{
                    path:"byCondition1"
                }
            },
            "getAllByCondition1":{
                method:"POST",
                params:{
                    path:"getAllByCondition1"
                }
            }
        })
    }

    function Gallery ($resource) {
        var resourceUrl =  'service/' + 'api/gallery/:path/:id';

        return $resource(resourceUrl, {}, {
            'unUsed': {
                method: 'POST',
                params: {
                    path: 'unUsed'
                },
            },
            'update': {
                method: 'GET',
                params: {
                    path: 'update'
                },
            },
            'batchDelete':{
                method: 'POST',
                params: {
                    path: 'batchDelete'
                }
            },
            "delete":{
                method:"DELETE"
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'all'
                },
                isArray: true
            },
            'getPage': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'upload':{
                method:'GET',
                params:{
                    path:'upload'
                }
            },
            'getOne':{
                method:'GET',
                params:{
                    path:'getOne'
                }
            },
            'updateNum':{
                method:'POST'
            },
            'moveFolder': {
                method: "GET",
                params: {
                    path: "moveFolder"
                },
            },
            'all': {
                method: "POST",
                params: {
                    path: "all"
                },
                isArray: true
            },
            'checkDeleted': {
                method: "POST",
                params: {
                    path: "checkDeleted"
                },
                isArray: true
            }
        });
    }

    function Folder($resource) {
        var resourceUrl = 'service/' + 'api/folders/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "getAllByConditionAndQuantity": {
                method: "GET",
                params: {
                    path: "getAllByConditionAndQuantity"
                },
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'delete': {
                method: 'DELETE'
            },
            'batchDelete': {
                method: 'GET',
                params: {
                    path: "batchDelete"
                }
            },
            'quit':{
                method: 'GET',
                params: {
                    path: "quit"
                }
            }
        });
    }
    function CustomerStorages($resource) {
        var resourceUrl = 'manager/' + 'api/customer-storages/:path/:id';

        return $resource(resourceUrl, {}, {
            "getByCustomer": {
                method: "GET",
                params: {
                    path: "getByCustomer"
                }
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'delete': {
                method: 'DELETE'
            },
            'batchDelete': {
                method: 'GET',
                params: {
                    path: "batchDelete"
                }
            }
        });
    }
})();
