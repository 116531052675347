(function() {
    'use strict';

    angular
        .module('editorApp')
        .directive('isOver', isOver);

    isOver.$inject = ['$timeout'];


    function isOver($timeout) {

        return {
            restrict: 'A',
            scope: {
                over: '=isOver'
            },
            link:function(scope, elm, attr){
                if(scope.$parent.$last){
                    scope.over = true;
                }
            }
        }
    }
})();
