(function () {
    'use strict';

    var iphoneSize = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        controllerAs: 'vm',
        bindings: {
            isIphoneX: '='
        },

        controller: ['$scope','Iphone', function ($scope,Iphone) {
            var vm = this;
            vm.isIphoneX = Iphone;
            var windowH = $(window).height();
            console.log("------------- " + windowH + " -------------" + $scope.isIphoneX);
            var h = localStorage.getItem('windowMinH');
            if (!h) {
                localStorage.setItem('windowMinH', windowH);
                h = windowH;
            }

            if (vm.isIphoneX) {
                var wh = $(window).height(); //页面可视化区域高度
                if (wh > h) {
                    $(".iphoneX-content").height(36);
                } else {
                    $(".iphoneX-content").height(0);
                }
            }


            // $(window).scroll(function () {
            //     if ($scope.isIphoneX) {
            //         var wh = $(window).height(); //页面可视化区域高度
            //         console.log("wh:"+wh)
            //         if (wh > h) {
            //             $(".iphoneX-content").height(36);
            //         } else {
            //             $(".iphoneX-content").height(0);
            //         }
            //     }
            // })
        }]
    };

    angular
        .module('editorApp')
        .component('iphoneSize', iphoneSize);
})();
