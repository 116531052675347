(function () {
    'use strict';
    angular
        .module('editorApp')
        .controller('UserMessageController', UserMessageController);

    UserMessageController.$inject = ['$scope', 'LoginService', '$state', 'MessageService', 'Customer', '$localStorage', '$q', '$stateParams',
        '$cookieStore', 'Store', 'Theme','$uibModal','UserMessage','entity','$timeout'];

    function UserMessageController($scope, LoginService, $state, MessageService, Customer, $localStorage, $q, $stateParams, $cookieStore, Store,Theme,$uibModal,UserMessage,entity, $timeout){
        var vm = this;
        console.log(vm)
        vm.messageList = [];
        vm.chosenOne = entity;
        vm.page = 0;
        vm.size = 20;
        getList()

        function getList() {
            UserMessage.getAllByCondition({items:[], sort: sort()},onSuccess,onError);
            function sort() {
                return ["createdDate,desc"];
            }
            function onSuccess(res,headers) {
                if (res.length==0){
                    return
                }
                res.forEach(function (item){
                    vm.messageList.push(item)
                    if (item.id == vm.chosenOne.id){
                        item.chosen =true;
                        vm.chosenOne = item;
                    }else {
                        item.chosen = false;
                    }
                })
                if (vm.chosenOne == null) {
                    vm.messageList[0].chosen = true;
                    vm.chosenOne = vm.messageList[0];
                }
                vm.totalItems = headers('X-Total-Count');
                $timeout(function(){
                    if (document.getElementById('message'+vm.chosenOne.id)) {
                        document.getElementById('message'+vm.chosenOne.id).scrollIntoView();
                    }
                })
            }
            function onError(error){
            }
        }
        $scope.msgSelect = function (item) {
            return  item.chosen;
        }
        $scope.showMsgDetail = function (item) {
            vm.chosenOne.chosen = false;
            if (!item.read) {
                UserMessage.getOne({id: item.id}, function () {
                });
            }
            item.chosen = true;
            item.read = true;
            vm.chosenOne = item;
        }

        // $('.message-detail-left').on('scroll', function(event){
        //     var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
        //     if(vm.messageList.length < vm.totalItems){
        //         if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
        //             vm.page++;
        //             getList()
        //         }
        //     }
        // });

    }


})();
