(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('SalesOrderReorderController', SalesOrderReorderController);

    SalesOrderReorderController.$inject = ['$q', '$state', '$scope', 'SalesOrder', '$stateParams', 'Customer', 'Product',
        'MessageService',  'PlatformAbutment', 'ItemValue', 'ListService', 'DateUtils', 'Coupon', 'PayCodes', 'ItemValueLinePackage',
        '$uibModal', 'ServiceWxJS', 'entity', 'GiftVoucher', 'StoreConfig', 'StorePaymentSetting', "$localStorage" , 'Store', "StorePickLocation",'$timeout'
    ];

    function SalesOrderReorderController($q, $state, $scope, SalesOrder, $stateParams, Customer, Product,
        MessageService, PlatformAbutment, ItemValue, ListService, DateUtils, Coupon, PayCodes, ItemValueLinePackage,
        $uibModal, ServiceWxJS, entity, GiftVoucher, StoreConfig, StorePaymentSetting, $localStorage, Store, StorePickLocation, $timeout) {

        var vm = this;
        vm.storeId = $stateParams.storeId;
        console.log($stateParams)
        vm.salesOrder = entity;
        console.log(vm.salesOrder);
        vm.salesOrder.deliveryType = "Delivery";
        vm.salesOrder.sourceType = "WEB";
        vm.goList = goList;
        vm.usedPayCodes = [];
        vm.usedGiftVouchers = [];

        vm.store = Store.get({id:vm.storeId});
        $scope.domain = $localStorage.domain;

        vm.recharge = 0;
        vm.changePay = changePay;
        vm.selectLocationBtn = selectLocationBtn;
        vm.couponUseable = [];
        vm.save = save;
        vm.forcePayCode = true;
        vm.wechartPayState = wechartPayState;
        vm.locationAll = false;
        vm.deliveryList = [{
            'name': '普通快递',
            value:"Delivery",
            'selected': true
        }, ];
        vm.couponConfig = StoreConfig.byStoreIdAndType({
            type: 'COUPON'
        });
        vm.thirds = PlatformAbutment.getAllByCondition({
            items: [{
                key: "enabled",
                op: "=",
                value: "true"
            }]
        });

        vm.paymentSetting = StorePaymentSetting.getCurrent();
        vm.storePickLocations = StorePickLocation.getAllByCondition({});

        //新增地址
        $scope.orderAddLocation = function () {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                        entity: function () {
                            return {
                            };
                        }
                    }
                });
            };
            createModal2('app/entities/commodity/address-dialog.html', 'AddressDialogController').result.then(function(data) {
                Customer.addLocation(data, function (res) {
                    MessageService.success("新增地址成功！");
                    vm.locations = res.locations;
                    var defaultIndex = 0;
                    var defaultLocation = null;
                    for (var i = 0; i < vm.locations.length; i++) {
                        var location = vm.locations[i];
                        if (location.isDefault) {
                            defaultIndex = i;
                            defaultLocation = angular.copy(location);
                            break;
                        }
                    }
                    if (defaultLocation) {
                        vm.locations.splice(defaultIndex,1)
                        vm.locations.unshift(defaultLocation)
                    }
                });
            });
        };


        vm.deliveryIndex = 0;
        vm.currentCustomer = Customer.current({});
        $scope.unUsedShow = true;

        function changePay(orderSource, third) { //第三方支付的时候 third会有值
            vm.salesOrder.orderSource = orderSource;
            if (orderSource == 'LOCAL' || orderSource == 'THIRD2' ) {
                vm.salesOrder.thirdConfigId = null;
            } else {
                vm.salesOrder.locations = [];
                if (vm.salesOrder.orderSource == third.platform && vm.salesOrder.thirdConfigId == third.id) {
                    vm.salesOrder.orderSource = null;
                    vm.salesOrder.thirdConfigId = null;
                } else {
                    vm.salesOrder.orderSource = third.platform;
                    vm.salesOrder.thirdConfigId = third.id;
                }
                vm.salesOrder.coupon = null;
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line =  vm.salesOrder.orderLines[i];
                    line.payCodes = [];
                    line.giftVouchers = [];
                }
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line =  vm.salesOrder.productLines[i];
                    line.payCodes = [];
                    line.giftVouchers = [];
                }
            }
            $scope.settlement();
        }


        $(".navbar").show();
        $(".wrap").show().css({
            'margin': 'margin: 48px 0 0 130px;'
        });

        function goList() {
            $state.go("sales-order", {
                storeId: vm.storeId
            });
        }

        $scope.settlement = function() {
            //抵扣金额
            vm.deductionAmount = 0;
            vm.discountAmount = 0;
            if (vm.salesOrder.orderWay == "orderThenEditor") {
                angular.forEach(vm.salesOrder.productLines, function(line, i) {
                    vm.discountAmount += line.amount - line.payAmount;
                    if (line.payCodes != null && line.payCodes.length>0) {
                        vm.deductionAmount += line.payPrice*line.payCodes.length;
                    }
                    if (line.giftVouchers != null && line.giftVouchers.length>0) {
                        vm.deductionAmount += line.payPrice*line.giftVouchers.length;
                    }
                })
            }else{
                angular.forEach(vm.salesOrder.orderLines, function(line, i) {
                    vm.discountAmount += line.amount - line.payAmount;
                    if (line.payCodes != null && line.payCodes.length>0) {
                        vm.deductionAmount += line.payPrice*line.payCodes.length;
                    }
                    if (line.giftVouchers != null && line.giftVouchers.length>0) {
                        vm.deductionAmount += line.payPrice*line.giftVouchers.length;
                    }
                })
            }
            vm.salesOrder.discountAmount = vm.discountAmount;
            vm.salesOrder.deductionAmount = vm.deductionAmount;
            vm.salesOrder.rechargeAmount = 0;
            vm.salesOrder.thirdAmount = 0;
            if (vm.salesOrder.recharge) {
                //扣除 抵扣券 优惠券 还剩下的钱
                vm.total =  vm.salesOrder.amount - vm.salesOrder.discountAmount - vm.salesOrder.deductionAmount  + vm.salesOrder.freight;
               if (vm.total > vm.customer.balance) {
                    vm.salesOrder.rechargeAmount = vm.customer.balance;
                    vm.salesOrder.thirdAmount = vm.total - vm.salesOrder.rechargeAmount;
                }else{
                    vm.salesOrder.rechargeAmount = vm.total;
                    vm.salesOrder.thirdAmount = 0;
                }
            }else{
                vm.salesOrder.thirdAmount = vm.salesOrder.amount - vm.salesOrder.discountAmount - vm.salesOrder.deductionAmount  + vm.salesOrder.freight;
            }
        }

        function selectLocationBtn(location) {
            if (location!= null) {
                if (location.city.indexOf("辖")>=0 || location.district.indexOf("辖")>=0 ) {
                    MessageService.error("地址信息有误，请更换地址或者前往地址管理重新编辑后下单");
                    return;
                }
                vm.salesOrder.locations = [location];
                 $scope.getSalesOrderCost();
            }
        }


        $scope.rechargeChange = function(){
            vm.salesOrder.recharge = !vm.salesOrder.recharge;
            $scope.settlement();
        }

        $scope.getLine = function(itemPrice){
             if (vm.salesOrder.orderWay == "orderThenEditor") {
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.productLines[i];
                    if (line.uuid == itemPrice.uuid) {
                        return line;
                    }
                }
            }else{
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.orderLines[i];
                    if (line.uuid == itemPrice.uuid) {
                        return line;
                    }
                }
            }
            return null;
        }
        $scope.getCouponLines = function(){
            var productCondition = [];
            if (vm.salesOrder.orderWay == "orderThenEditor") {
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.productLines[i];
                    productCondition.push({productId: line.productId, amount : line.amount, uuid: line.uuid,
                        price:line.price,  quantity: line.editorType == "PICTURE" ? line.copy*line.quantity : line.copy});
                }
            }else{
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.orderLines[i];
                    if (line.orderArticle) {
                        productCondition.push({productId: line.orderArticle.productId, amount : line.amount, uuid: line.uuid,
                            price:line.price,  quantity: line.editorType == "PICTURE" ? line.copy*line.quantity : line.copy});
                    }
                }
            }
            return productCondition;
        }
        $scope.getCoupons = function(){
            if (vm.salesOrder == null) {
                return;
            };
            var productCondition = $scope.getCouponLines();
            Coupon.couponAvailable2(productCondition, function(data){
                if (data.status == 200 && !!data.message) {
                    vm.couponUseable = data.message.list;
                    if (vm.couponUseable.length>0 && data.message.best) {
                        for(var i = 0; i< vm.couponUseable.length ; i++){
                            var c = vm.couponUseable[i];
                            if (c.id == data.message.best.id) {
                                vm.salesOrder.coupon = c;
                                c.select = true;
                                break;
                            }
                        }
                    }
                    if (vm.salesOrder.coupon) {
                        var params = {
                            couponId: vm.salesOrder.coupon.id,
                            lines: productCondition
                        }
                        Coupon.useCoupon(params, function(data){
                            if (data &&data.status == 200) {
                                var results = data.message;
                                for (var j = results.length - 1; j >= 0; j--) {
                                    var r = results[j];
                                    var line = $scope.getLine(r);
                                    if (line!= null) {
                                        line.payAmount = r.payAmount;
                                        line.payPrice = r.payPrice;
                                    }
                                }
                                $scope.settlement();
                            }else{
                                $scope.settlement();
                            }
                        })
                    }else{
                        $scope.settlement();
                    }
                }else{
                    vm.couponUseable = [];
                    $scope.settlement();
                }
            }, function(){});
        }

        $q.all([vm.salesOrder.$promise, vm.currentCustomer.$promise, vm.thirds.$promise, vm.paymentSetting.$promise, vm.store.$promise, vm.storePickLocations.$promise]).then(function() {
            vm.salesOrder.orderWay =  vm.store.orderWay;
            getCode();
            vm.customer = vm.currentCustomer;
            vm.locations = vm.currentCustomer.locations;
            var defaultIndex = 0;
            var defaultLocation = null;
            if (vm.currentCustomer.locations != null && vm.currentCustomer.locations.length > 0) {
                for (var i = 0; i < vm.currentCustomer.locations.length; i++) {
                    var location = vm.currentCustomer.locations[i];
                    if (location.isDefault) {
                        defaultLocation = angular.copy(location);
                        defaultIndex = i;
                        if (location.city.indexOf("辖")>=0 || location.district.indexOf("辖")>=0 ) {
                            MessageService.error("地址信息有误，请更换地址或者前往地址管理重新编辑后下单");
                            break;
                        }
                        vm.salesOrder.locations = [location];
                        break;
                    }
                }
            }
            if (defaultLocation) {
                vm.locations.splice(defaultIndex,1)
                vm.locations.unshift(defaultLocation)
            }
            $scope.getSalesOrderCost();

            if (vm.store.orderSource == "THIRD2" && vm.store.orderWay == 'editorThenOrder') {
                vm.salesOrder.orderSource = 'THIRD2';
            }else{
                vm.salesOrder.orderSource = 'LOCAL';
            }

            if (vm.store.urgent) {
                vm.deliveryList.push({
                    'name': '加急特快',
                    "value":"Delivery_Quick",
                    'selected': false
                });
            };
            if(vm.store.selfLift){
                vm.deliveryList.push({
                    'name': '线下自提',
                    "value":"Self",
                    'selected': false
                });
            }

        });

        $scope.addPayCode = function(line) {
            createModal('app/entities/sales-order/add-code.html', 'AddCodeController', line, vm.salesOrder).result.then(
                function(data) {
                    var currentLine = data.line;
                    vm.salesOrder = data.order;
                    $scope.updatepayCode(currentLine);
                    $scope.getSalesOrderCost();
                }
            )
        }

        var createModal = function(url, controller, line) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        line:line,
                        order:vm.salesOrder
                    }
                }
            });
        };

        $scope.updatepayCode = function(currentLine) {
            var payCodes = [];
            var giftVouchers = [];
            if (currentLine.useAble && currentLine.useAble.length>0) {
                for (var i = currentLine.useAble.length - 1; i >= 0; i--) {
                    if (currentLine.useAble[i].selected) {
                        var p = currentLine.useAble[i];
                        payCodes.push(p);
                    }
                }
            }
            if (currentLine.useAbleGiftVouchers && currentLine.useAbleGiftVouchers.length>0) {
                for (var i = currentLine.useAbleGiftVouchers.length - 1; i >= 0; i--) {
                    if (currentLine.useAbleGiftVouchers[i].selected) {
                        giftVouchers.push(currentLine.useAbleGiftVouchers[i]);
                    }
                }
            }

            currentLine.payCodes = payCodes;
            currentLine.giftVouchers = giftVouchers;
            currentLine.codeStr = "";
            vm.payCodes = [];
            currentLine.payCodes.forEach(function(item){
                vm.payCodes.push(item.code)
            })
            currentLine.giftVouchers.forEach(function(item){
                vm.payCodes.push(item.code)
            })
            if (currentLine.payCodes.length ==1 && currentLine.giftVouchers.length ==0) {
                currentLine.codeStr = currentLine.payCodes[0].code;
            }

            if (currentLine.payCodes.length ==0 && currentLine.giftVouchers.length ==1) {
                currentLine.codeStr = currentLine.giftVouchers[0].code;
            }
            if (currentLine.payCodes.length + currentLine.giftVouchers.length >1) {
                if (currentLine.payCodes.length >0) {
                    currentLine.codeStr = currentLine.payCodes[0].code +"...";
                }else{
                    currentLine.codeStr = currentLine.giftVouchers[0].code +"...";
                }
            }
        }


        $scope.chooseCoupon = function(coup, attr) {
            angular.forEach(vm.couponUseable, function(code, i) {
                code.select = false;
            })
            coup.select = attr;
            if (attr) {
                vm.salesOrder.coupon = coup;
                var productCondition = $scope.getCouponLines();
                var params = {
                    couponId: vm.salesOrder.coupon.id,
                    lines: productCondition
                }
                Coupon.useCoupon(params, function(data){
                    if (data &&data.status == 200) {
                        var results = data.message;
                        for (var j = results.length - 1; j >= 0; j--) {
                            var r = results[j];
                            var line = $scope.getLine(r);
                            if (line!= null) {
                                line.payAmount = r.payAmount;
                                line.payPrice = r.payPrice;
                            }
                        }
                        $scope.settlement();
                    }else{
                        $scope.unUseCoupon();
                        $scope.settlement();
                    }
                })
            } else {
                vm.salesOrder.coupon = null;
                $scope.unUseCoupon();
                $scope.settlement();
            }
        }

        $scope.unUseCoupon = function(){
            if (vm.salesOrder.orderWay == "orderThenEditor") {
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.productLines[i];
                    line.payAmount = line.amount;
                    line.payPrice = line.price;
                }
            }else{
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.orderLines[i];
                    line.payAmount = line.amount;
                    line.payPrice = line.price;
                }
            }
        }

        function wechartPayState() {
            if (!vm.wechartPayShow) {
                ServiceWxJS.payConfig({
                    id: vm.salesOrder.id,
                    storeId: vm.storeId,
                    totalFee: vm.salesOrder.thirdAmount,
                    type: "web",
                    names: vm.salesOrder.productName
                }, function(result) {
                    if (result.status != 200) {
                        MessageService.error("获取支付信息失败!");
                        if (platformAndStoreProduct()) {
                            vm.goList();
                        }else{
                            $state.go('sales-order-view', {
                                id: vm.salesOrder.id,
                                storeId: vm.storeId,
                                tryEdit:true
                            })
                        }
                        return;
                    }
                    vm.wechartPayShow = !vm.wechartPayShow;
                    var data = result.message;
                    $('#qrcode1').html("");
                    // 支付二维码
                    $('#qrcode1').qrcode({
                        render: "canvas",
                        width: 176,
                        height: 176,
                        text: result.message.url
                    });
                }, function(data){
                    MessageService.error("获取支付信息失败!");
                    if (platformAndStoreProduct()) {
                        vm.goList();
                    }else{
                        $state.go('sales-order-view', {
                            id: vm.salesOrder.id,
                            storeId: vm.storeId,
                            tryEdit:true
                        })
                    }
                    return;
                });
            } else {
                if (platformAndStoreProduct()) {
                    vm.goList();
                }else{
                    $state.go('sales-order-view', {
                        id: vm.salesOrder.id,
                        storeId: vm.storeId,
                        tryEdit:true
                    })
                }
            }
        }

        function getCode() {
            var cond = [];
            if (vm.salesOrder.orderWay == 'orderThenEditor') {
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.productLines[i];
                    if ( line.editorType == "PICTURE" && !line.jhiPackage) {
                        continue;
                    }
                    if (line.itemValueLineId) {
                        cond.push({uuid:line.uuid, copy:line.copy, quantity:line.quantity, itemValueLineId:line.itemValueLineId, autoSelect: true});
                    }
                }
            }else{
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.orderLines[i];
                    if (line.orderArticle && line.orderArticle.itemValueLineId && (!line.jhiPackage || (line.jhiPackage && line.orderArticle.articlePageCount==line.orderArticle.packageQuantity))) {
                        cond.push({uuid:line.uuid, copy:line.copy, quantity:line.quantity, itemValueLineId:line.orderArticle.itemValueLineId, autoSelect: true});
                    }
                }
            }
            PayCodes.codeAvailable2(cond, function(res) {
                var result = res;
                if (res != null && res.length != 0) {
                    for (var j = res.length - 1; j >= 0; j--) {
                        var r = res[j];
                        var line = $scope.getLine(r);
                        if (line!= null) {
                            line.useAble = angular.copy( r.useAblePayCode);
                            line.useAbleGiftVouchers = angular.copy( r.useAbleGift);
                        }
                    }
                }else{
                    if (vm.salesOrder.orderWay == 'orderThenEditor') {
                        for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                            var line = vm.salesOrder.productLines[i];
                            if (line.useAble == null) {
                                line.useAble = [];
                                line.useAbleGiftVouchers = [];
                            }
                        }
                    }else{
                        for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                            var line = vm.salesOrder.orderLines[i];
                            if (line.useAble == null) {
                                line.useAble = [];
                                line.useAbleGiftVouchers = [];
                            }
                        }
                    }
                }
                if (vm.salesOrder.orderWay == 'orderThenEditor') {
                    for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                        var line = vm.salesOrder.productLines[i];
                        $scope.updatepayCode(line);
                    }
                }else{
                    for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                        var line = vm.salesOrder.orderLines[i];
                        $scope.updatepayCode(line);
                    }
                }
                $scope.getSalesOrderCost();
            });
        }

        function save() {
            //第三方支付 只校验sourceNumber
            if (vm.salesOrder.orderSource != 'LOCAL' && (vm.salesOrder.sourceNumber == null || vm.salesOrder.sourceNumber == "")) {
                MessageService.error("请选择第三方渠道，并输入单号");
                return;
            }
            //非第三方支付
            if (vm.salesOrder.orderSource == 'LOCAL') {
                //地址
                if (vm.salesOrder.locations == null || vm.salesOrder.locations.length == 0) {
                    MessageService.error("请选择收货信息");
                    return;
                }
                if (!(vm.salesOrder.locations[0].contact).replace(/\s+/g, '')) {
                    MessageService.error("当前地址未填写收货人");
                    return;
                }

                //没有开微信支付 但是支付金额不等于邮费
                if (vm.store.payType.indexOf("wechat") < 0 && vm.salesOrder.thirdAmount != vm.salesOrder.freight) {
                    MessageService.error("下单失败，请使用对应产品抵扣码下单！");
                    return;
                }
                //没有配置web端支付信息
                if ((!vm.paymentSetting || !vm.paymentSetting.id || !vm.paymentSetting.weChatKey
                    || !vm.paymentSetting.weChatMchid || !vm.paymentSetting.weChatNotifyUrl)&& vm.salesOrder.thirdAmount!=0) {
                    MessageService.error("商户未配置微信支付，下单失败！");
                    return;
                }
                //使用强制支付 代表每行必须要有 支付码或者礼物券
                if (vm.forcePayCode) {
                    if (vm.salesOrder.orderWay == 'orderThenEditor') {
                        for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                            var line = vm.salesOrder.productLines[i];
                            var quantity = line.payCodes == null? 0: line.payCodes.length;
                            quantity = quantity + (line.giftVouchers == null ? 0:line.giftVouchers.length);
                            if (quantity != line.copy) {
                                MessageService.error("下单失败，请使用对应产品抵扣码下单！");
                                return;
                            }
                        }
                    }else{
                        for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                            var line = vm.salesOrder.orderLines[i];
                            var quantity = line.payCodes == null? 0: line.payCodes.length;
                            quantity = quantity + (line.giftVouchers == null ? 0:line.giftVouchers.length);
                            if (quantity != line.copy) {
                                MessageService.error("下单失败，请使用对应产品抵扣码下单！");
                                return;
                            }
                        }
                    }
                }
            }
            var payAmount = (vm.salesOrder.amount || 0) - (vm.salesOrder.discountAmount || 0) + (vm.salesOrder.freight || 0);
            if (payAmount > 0) {
                vm.salesOrder.payAmount = payAmount;
            } else {
                vm.salesOrder.payAmount = 0;
            }

            //查第三方单号是否可用
            if (vm.salesOrder.orderSource != 'LOCAL') {
                vm.salesOrder.locations = [];
                var checkdata = {
                    storeId: vm.storeId,
                    packages: []
                };

                if (vm.salesOrder.orderWay == 'orderThenEditor') {
                    for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                        var line = vm.salesOrder.productLines[i];
                        if (line.itemValueLineId) {
                            checkdata.packages.push({
                                quantity: line.copy,
                                itemValueLineId: line.itemValueLineId
                            });
                        }
                    }
                }else{
                    for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                        var line = vm.salesOrder.orderLines[i];
                        if (line.orderArticle && line.orderArticle.itemValueLineId) {
                            checkdata.packages.push({
                                quantity: line.copy,
                                itemValueLineId: line.orderArticle.itemValueLineId
                            });
                        }
                    }
                }
                vm.isSaving = true;
                SalesOrder.checkout2({
                    code: vm.salesOrder.sourceNumber,
                    storeId: vm.storeId
                }, function(data) {
                    if (data.status == 1) {
                        if (vm.salesOrder.orderSource =='THIRD2') {
                            SalesOrder.createBySourceNumber3(vm.salesOrder, function(data) {
                                if (data.status != 200) {
                                    MessageService.error(data.message);
                                } else {
                                    goList();
                                }
                                vm.isSaving = false;
                            }, function() {
                                MessageService.error("下单失败，请稍后再试!");
                                vm.isSaving = false;
                            });
                        }else{
                            SalesOrder.createBySourceNumber(vm.salesOrder, function(data) {
                                if (data.status != 200) {
                                    MessageService.error(data.message);
                                } else {
                                    goList();
                                }
                                vm.isSaving = false;
                            }, function(data) {
                                if (data && data.data) {
                                    MessageService.error(data.data.message);
                                }else{
                                    MessageService.error("下单失败，请稍后再试!");
                                }
                                vm.isSaving = false;
                            });
                        }
                    } else {
                        MessageService.info(data.message);
                        vm.isSaving = false;
                    }
                }, function() {
                    MessageService.error("核验第三方订单失败!");
                    vm.isSaving = false;
                })
            }else{
                vm.isSaving = true;
                SalesOrder.save(vm.salesOrder, function(data) {
                    vm.isSaving = false;
                    vm.salesOrder = data;
                    if (data.thirdAmount > 0) {
                        vm.wechartPayState();
                    } else {
                        if (platformAndStoreProduct()) {
                            vm.goList();
                        }else{
                            $state.go("sales-order-view", {storeId: vm.salesOrder.storeId, id:vm.salesOrder.id ,tryEdit: true});
                        }
                    }
                }, function(error) {
                    vm.isSaving = false;
                    if (error.data && error.data.message) {
                        MessageService.error(error.data.message);
                    } else {
                        MessageService.error("订单确认失败");
                    }
                });
            }
        }

        // 购物车下单，判断商品是否是，平台商品和商户商品组合
        function platformAndStoreProduct(){
            if (vm.salesOrder.productLines.length <= 1) {
                return false;
            }else{
                var platformNum = 0;
                var storeNum = 0;
                vm.salesOrder.productLines.forEach(function(order){
                    if (order.prototype == 'StoreSelf') {
                        storeNum++;
                    }
                    if (order.prototype == 'Store') {
                        platformNum++;
                    }
                })
                if (platformNum>0 && storeNum>0) {
                    return true;
                }else{
                    return false;
                }
            }
        }

        $scope.getSalesOrderCost = function() {
            var uploadProducts = [];
            //作品订购
            if (vm.salesOrder.orderWay == 'orderThenEditor') {
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.productLines[i];
                    if (isNaN(line.copy) || line.copy == null || line.copy < 1) {
                        MessageService.error("下单数量不能小于1");
                        return;
                    }
                    var freightFree = false;
                    var freightCopy = line.copy;
                    if (line.payCodes && line.payCodes.length>0) {
                        for (var j = line.payCodes.length - 1; j >= 0; j--) {
                            var p = line.payCodes[j];
                            if (p.freightFree) {
                                freightFree = true;
                                freightCopy = freightCopy-1;
                            }
                        }
                    }
                    // var copy = line.copy;
                    // if (line.editorType == "PICTURE" && !line.jhiPackage) {
                    //     copy = line.copy*line.quantity;
                    // }
                    var data = {
                        articleId: null,
                        id: line.productId,
                        freightFree:freightFree,
                        freightCopy:freightCopy,
                        itemValueId: line.itemValueId,
                        uuid:line.uuid,
                        itemValueLineId: line.itemValueLineId,
                        copy: line.copy,
                        quantity: line.quantity
                    }
                    uploadProducts.push(data)
                }
            }else{
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.orderLines[i];
                    if (isNaN(line.copy) || line.copy == null || line.copy < 1) {
                        MessageService.error("下单数量不能小于1");
                        return;
                    }
                    var freightFree = false;
                    var freightCopy = line.copy;
                    if (line.payCodes && line.payCodes.length>0) {
                        for (var j = line.payCodes.length - 1; j >= 0; j--) {
                            var p = line.payCodes[j];
                            if (p.freightFree) {
                                freightFree = true;
                                freightCopy =freightCopy-1;
                            }
                        }
                    }
                    var data = {
                        articleId: line.orderArticle.articleId,
                        id: line.orderArticle.productId,
                        freightFree:freightFree,
                        freightCopy:freightCopy,
                        uuid:line.uuid,
                        itemValueId: line.orderArticle.itemValueId,
                        itemValueLineId: line.orderArticle.itemValueLineId,
                        copy: line.copy,
                        quantity: line.orderArticle.articlePageCount
                    }
                    uploadProducts.push(data)
                }
            }
              var data = {
                province: null,
                city: null,
                district: null,
                products: uploadProducts
            }

            data.deliveryType = vm.salesOrder.deliveryType;
            if (data.deliveryType == null) {
                data.deliveryType = "Delivery";
            }

            if (vm.salesOrder.locations && vm.salesOrder.locations.length>0) {
                data.province = vm.salesOrder.locations[0].province;
                data.city = vm.salesOrder.locations[0].city;
                data.district = vm.salesOrder.locations[0].district;
            }
            vm.isSaving = true;
            SalesOrder.getCost2(data, function(data) {
                vm.isSaving = false;
                if (data) {
                    if (!!data.errorMessage) {
                        MessageService.error(data.errorMessage);
                        return;
                    }
                    vm.getCostArray = data.productPrices;
                    vm.salesOrder.freight = data.freight;
                    vm.salesOrder.amount = 0;
                    if (vm.salesOrder.orderWay == 'orderThenEditor') {
                        for (var j = data.productPrices.length - 1; j >= 0; j--) {
                            var productPrice = data.productPrices[j];
                            var line = $scope.getLine(productPrice);
                            if (line!= null) {
                                vm.salesOrder.amount += productPrice.amount;
                                line.amount = productPrice.amount;
                                line.payAmount = productPrice.amount;
                                line.price = productPrice.price;
                                line.payPrice = productPrice.price;
                            }
                        }
                    }else{
                        for (var j = data.productPrices.length - 1; j >= 0; j--) {
                            var productPrice = data.productPrices[j];
                            var line = $scope.getLine(productPrice);
                            if (line!= null) {
                                vm.salesOrder.amount += productPrice.amount;
                                line.amount = productPrice.amount;
                                line.payAmount = productPrice.amount;
                                line.price = productPrice.price;
                                line.payPrice = productPrice.price;
                            }
                        }
                    }
                    if (vm.couponConfig && vm.couponConfig.open) {
                        $scope.getCoupons();
                    }else{
                        $scope.settlement();
                    }
                }
            }, function(data) {
                vm.isSaving = false;
                MessageService.error(data.data.errorMessage);
                return;
            });
            var checkdata = {
                storeId: vm.storeId,
                packages: []
            };
            if (uploadProducts && uploadProducts.length > 0) {
                for (var i = uploadProducts.length - 1; i >= 0; i--) {
                    var p = uploadProducts[i];
                    checkdata.packages.push({
                        quantity: p.quantity*p.pageCount,
                        itemValueLineId: p.itemValueLineId
                    });
                }
            }
            ItemValueLinePackage.checkPackagesStockQuantity(checkdata, function(data) {
                if (data.status == 500) {
                    MessageService.error(data.message);
                    return;
                }
                if (data.status == 200) {
                    vm.forcePayCode = false;
                }
            }, function(data) {
                MessageService.error("订单校验信息失败，请稍后再试!");
                return;
            });
        }

        $scope.chooseDeliveryMethod = function(deliveryType) {
            for (var i = 0; i < vm.deliveryList.length; i++) {
                var item = vm.deliveryList[i];
                if (deliveryType.value == item.value) {
                    item.selected = true;
                }else{
                    item.selected = false;
                }
            }
            if (vm.salesOrder.deliveryType != deliveryType.value) {
                vm.salesOrder.locations = [];
            }
            vm.salesOrder.deliveryType = deliveryType.value;
            $scope.getSalesOrderCost();
        }

        $scope.showDetailImg = false;
        $scope.lookImg = function(group, e) {
            fn(e);
            vm.groupImg = group.detailImg;
            $scope.showDetailImg = true;
        }

        $scope.modalHide = function() {
            $scope.showDetailImg = false;
        }
        $scope.getAllCode = function(payCodes) {
            createModal('app/entities/sales-order/modal/code-list.html', 'CodeListController', payCodes, vm.salesOrder).result.then(
                function(data) {
                }
            )
            // $uibModal.open({
            //     templateUrl: "app/entities/sales-order/modal/code-list.html",
            //     controller: "CodeListController",
            //     controllerAs: 'vm',
            //     size: 'lg',
            //     resolve: {
            //         entity: payCodes
            //     }
            // });
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
    }

})();
