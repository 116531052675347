(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory("AfterSale", AfterSale)
        .factory("AfterSaleProblem", AfterSaleProblem);

    AfterSale.$inject = ['$resource'];
    AfterSaleProblem.$inject = ['$resource'];

    function AfterSale ($resource){
        var resourceUrl = "service/api/afterSale/:path/:id";

        return $resource(resourceUrl, {},{
            "save":{
                method:"POST"
            },
            "changeImgKey": {
                method: "GET",
                params: {
                    path: "changeImgKey"
                }
            },
            "getStateByOrder": {
                method: "GET",
                params: {
                    path: "getStateByOrder"
                }
            },
            "getAfterSaleByOrder": {
                method: "GET",
                params:{
                    path: "getAfterSaleByOrder"
                }
            },
            "getAllByCondition2": {
                method: "POST",
                params:{
                    path: "getAllByCondition2"
                },
                isArray:true
            },
            "setStateByOrder": {
                method: "GET",
                params: {
                    path: "setStateByOrder"
                }
            },
             
            "byCondition2": {
                method: "post",
                params: {
                    path: "byCondition2"
                },
                isArray:true
            },
            "getSomeTypeByStoreAndUser": {
                method: "GET",
                params: {
                    path: "getSomeTypeByStoreAndUser"
                }
            }
        })
    }

    function AfterSaleProblem ($resource){
        var resourceUrl = "service/api/afterSaleProblem/:path/:id";

        return $resource(resourceUrl, {},{
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'getAllByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            'getAllByCondition1': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition1'
                },
                isArray: true
            },
            'getListByparent': {
                method: 'GET',
                params: {
                    path: 'getSubordinate'
                },
                isArray: true
            }
        })
    }
})();
