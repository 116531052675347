(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('CommodityController', CommodityController);

    CommodityController.$inject = ['$scope', '$q', 'Product', '$state', 'MessageService', 'Customer', '$rootScope', 'Qinius', '$stateParams', '$uibModal',
     '$localStorage', 'Article', '$cookieStore', 'Store', 'MessageNotification','DateUtils', 'CommonService', 'StoreConfig', 'CategoryStore', 'HotWords','Theme'];

    function CommodityController($scope, $q, Product, $state, MessageService, Customer, $rootScope, Qinius, $stateParams, $uibModal,
     $localStorage, Article, $cookieStore, Store, MessageNotification,DateUtils, CommonService, StoreConfig, CategoryStore, HotWords, Theme) {
        var vm = this;
        vm.storeId = $stateParams.storeId || $cookieStore.get("store-id");
        vm.commodityList = [];
        vm.isLogin = false;
        vm.libraryList = [];
        vm.hidden = false;
        vm.name = "";
        vm.search = search;
        vm.store = $localStorage.store;
        vm.domain = $localStorage.domain;
        vm.imageShow = false;
        vm.activeClassifyName = '精选推荐';
        vm.activeClassifyId = null;
        vm.activeClassifyIndex = null;
        vm.currentNavtab = 0;
        vm.searchText == '';
        vm.historyWord = JSON.parse(localStorage.getItem('historyWord')) || [];
        vm.hotWords = [];
        vm.messageShow = true;
        $scope.activeClassify = function(item,index){
            vm.subCategories = [];
            vm.activeClassifyName = item.name;
            vm.activeClassifyId = item.id;
            vm.activeClassifyIndex = index;
            vm.currentNavtab = 0;
            vm.noItems = true;
            angular.forEach(vm.categories, function (ca, index) {
                if (ca.id == item.id) {
                    vm.currentNavtab = index;
                    vm.currentNavtab = 0;
                    getCategoryAndProductByCategory(ca);
                    vm.noItems = false;
                }
            })
            if (vm.noItems) {
                vm.commodityList = [];
            }
            console.log(vm.subCategories);
        }
        //切换分类
        $scope.switchTab = function (index, category) {
            vm.currentNavtab = index;
            vm.commodityList =category.products;
            vm.commodityList = vm.commodityList.sort(function(a, b){
                return a.seq - b.seq;
            })
        }

       $scope.selecRecommend = function(){
            vm.activeClassifyName = '精选推荐';
            vm.activeClassifyId = null;
            vm.activeClassifyIndex = null;
            vm.subCategories = [];
            vm.commodityList =vm.choicenessList;
            vm.commodityList = vm.commodityList.sort(function(a, b){
                return a.seq - b.seq;
            })
       }
        init();
        vm.storeConig = StoreConfig.getAllByTypes({storeId:vm.storeId, type:['IMGSHOW','PCSHOWIMG']})
        $q.all([vm.storeConig.$promise]).then(function(){
            if (vm.storeConig.status == 200) {
                vm.configs = vm.storeConig.message;
                if (vm.configs && vm.configs.length>0) {
                    for (var i = vm.configs.length - 1; i >= 0; i--) {
                        var config = vm.configs[i];
                        if (config == null) {
                            continue;
                        }
                        if (config.type == 'IMGSHOW') {
                            vm.imageShow  = config.open;
                        }
                        if (config.type == 'PCSHOWIMG') {
                            vm.pcShowImage  = config.value;
                        }
                    }
                }
            }
        })
        var createModal = function (url, controller, type) {
            if (type == null) {
                type = "Login";
            }
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type
                    },
                }
            });
        };

        $scope.login = function () {
            createModal('app/account/register/register.html', 'RegisterController', "Login").result.then(
                function () {
            });
        };

        var createModal = function (url, controller, type) {
            if (type == null) {
                type = "Login";
            }
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type
                    },
                }
            });
        };

        $scope.ordered = function () {
            if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                $scope.login();
            } else {
                orderedModal('app/entities/commodity/number-ordered.html', 'NumberOrderedController').result.then(
                    function (data) {
                        $state.go("sales-order-view", {storeId:vm.storeId, id:data.id, tryEdit:true});
                    });
            }
        };

        var orderedModal = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
            });
        };
        vm.productinfo={
            storeId:'',
            branchStoreId:'',
            name: ''
        }
        function getProduct(){
            Product.getProductsClient(vm.productinfo,function(data){
                vm.choicenessList = data;
                vm.commodityList =data;
                vm.commodityList = vm.commodityList.sort(function(a, b){
                    return a.seq - b.seq;
                })
            })
        }
        function getCategoriesByProduct(){
            //获取所有一级分类信息
            Product.getCategoriesByProduct({storeId:vm.storeId,branchStoreId:vm.productinfo.branchStoreId}, function (res) {
                vm.categories = res.sort(sortNavTitle("seq"));
            });
        }
        //获取选中分类的下级分类和产品
        function getCategoryAndProductByCategory(category) {
            vm.subCategories = [];
            vm.commodityList = [];
            if(category.categories){
                vm.subCategories = category.categories.sort(sortNavTitle("seq"));
                // if(category.categories.length>0){
                    vm.commodityList =category.categories[0].products
                    vm.commodityList = vm.commodityList.sort(function(a, b){
                        return a.seq - b.seq;
                    })
                // }
            }else if(category.products){
                for (var j = 0; j < category.products.length; j++) {
                    //数据太大，渲染慢
                    var product = category.products[j];
                    product.itemValues = [];
                    product.objectProperties = [];
                }
                vm.commodityList = category.products.sort(sortNavTitle("seq"));
            }
            console.log(vm.commodityList);
        }
        function sortNavTitle(propertyName) {
            return function (object1, object2) {
                var value1 = object1[propertyName];
                var value2 = object2[propertyName];
                return value1 - value2;
            }
        }
        function getHotWordsAll(){
            HotWords.enabled({storeId: vm.storeId},function(res){
                vm.hotWords = res.hotWords;
            })
        }
        function init(){
            if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                vm.productinfo = {
                    storeId:vm.storeId,
                    name: vm.name,
                    branchStoreId:''
                }
                getProduct()
            } else {
                vm.productinfo={
                    storeId:vm.storeId,
                    branchStoreId: $localStorage.user.branchStoreId,
                    name: vm.name
                }
                getProduct()
            }
            getHotWordsAll()
            getCategoriesByProduct()
            // 热门分类
            vm.hotCategories = [];
            CategoryStore.getAllByCondition2({
                items: [
                    {key: "usable", op: "=", value: "true"},
                    {key: "haveSuperior", op: "=", value: "false"},
                    {key: "hot", op: "=", value: true},
                    {key: "storeId", op: "=", value: vm.storeId}
                ]
            }, function (res) {
                vm.hotCategories = res.sort(sortNavTitle("seq"));
            });



            if (vm.domain == null || vm.domain == "") {
                Qinius.getDomain1({storeId: vm.storeId}, function(data){
                     vm.domain = 'https://' + data.domains[0] + '/';
                });
            }
            if (vm.store == null) {
                Store.get({id:vm.storeId},function(data){
                    vm.store = data;
                    if (vm.store.storeType2.indexOf("WEB")<0) {
                        vm.hidden = true;
                        CommonService.setDocumentTitle(vm.store.name);
                    }
                });
            }else{
                if (vm.store.storeType2.indexOf("WEB")<0) {
                    vm.hidden = true;
                    CommonService.setDocumentTitle(vm.store.name);
                }
            }
            //作品库
            if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                vm.isLogin = false;
            } else {
                vm.isLogin = true;
                Article.getAllByCondition({
                    items: [{key: "completed", op: "=", value: 'false'}],
                    sort: ["createdDate,desc"]
                }, function (res) {
                    for (var i = 0; i < res.length; i++) {
                        if (!res[i].completed) {
                            vm.libraryList.push(res[i]);
                        }
                    }
                });
            }
            vm.messageList = "";
            MessageNotification.getAllByCondition2({
                    items:[
                        {key:"pageType", op : "=", value:"Index"},
                        {key:"storeId", op : "=", value:vm.storeId},
                        {key:"available", op : "=", value:"true"},
                        {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                }, function (res) {
                    res.sort(compare('seq'))
                    vm.message = angular.copy(res);
                    res.forEach(function (item, index) {
                        vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                    })
                }
            )
        }
        function search(){
            Product.getProductsClient({storeId:vm.storeId, source:"web", name: vm.name},function(data){
                vm.commodityList =data;
                vm.commodityList = vm.commodityList.sort(function(a, b){
                    return a.seq - b.seq;
                })
            })
        }

        $scope.commoditySelect = function (data) {
            if ($localStorage == null || $localStorage.user == null || $localStorage.authenticationToken == null) {
                $scope.login();
            } else {
                $state.go('standardCommodityDetails', {storeId: vm.storeId, pid: data.id, from:'commodity'});
            }
        };
        $scope.librarySelectView = function (data) {
            $state.go("page-view", {storeId: vm.storeId, aid: data.id});
        };
        $scope.librarySelectEdit = function (data) {
            $state.go("page-my-suffix-remake", {storeId: vm.storeId, aid: data.id});
        };
        $scope.webArticleSelect = function () {
            $state.go("webArticle", {storeId: vm.storeId});
            angular.element('#myWork').addClass('pageOn');
        };
        /*
            首页搜索input和弹窗操作
         */
        $scope.searchMethodClick = function () {
            if (vm.searchText == '') {
                MessageService.error('搜索内容不能为空')
            } else {
                vm.historyWord = vm.historyWord.filter(function (item) {
                    return vm.searchText != item
                })
                vm.historyWord.unshift(vm.searchText);
				vm.historyWord = vm.historyWord.slice(0,15);
                localStorage.setItem('historyWord', JSON.stringify(vm.historyWord));
                // 这里写跳转搜索结果页逻辑，vm.searchText,搜索内容
                $state.go("commoditySearch", {storeId: vm.storeId, search:vm.searchText});
            }
        }
        // 清除历史
        $scope.clearHistory = function(){
            vm.historyWord = [];
            localStorage.removeItem('historyWord')
        }
        // 点击关键词搜索
        $scope.wordSearch = function (name) {
            vm.searchText = name;
            $scope.searchMethodClick()
        }

        vm.focus = false;
        vm.mouseenter = false;
        $scope.focusInput = function(){
            vm.focus = true;
            vm.isHistory = true;
        }
        $scope.blurInput = function (){
            vm.focus = false;
            if(vm.mouseenter){
                return
            }
            vm.isHistory = false;
        }
        $scope.mouseenterHistory = function(){
            vm.mouseenter = true;
            vm.isHistory = true;
        }
        $scope.mouseleaveHistory = function (){
            vm.mouseenter = false;
            if(vm.focus){
                return
            }
            vm.isHistory = false;
        }
    }
})();
