(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('PageMySuffixSave2Controller', PageMySuffixSave2Controller);
    PageMySuffixSave2Controller.$inject = ['$scope', 'Category', '$uibModalInstance', 'MessageService', '$stateParams', 'ProductXmlFileServes',
    'ThemeClassify', 'ProductXml', 'Store', 'xmlId', 'PlatformWechatSetting'];

    function PageMySuffixSave2Controller( $scope, Category, $uibModalInstance, MessageService, $stateParams, ProductXmlFileServes,
        ThemeClassify, ProductXml, Store, xmlId, PlatformWechatSetting) {
        //编辑第三方的模板 弹出窗口

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.id = null;
        vm.themeClassifyList = ThemeClassify.getAllByCondition({items:[]});
        vm.stores = Store.getAllByCondition({items:[]});
        vm.platformSetting = PlatformWechatSetting.get();

        
        if(xmlId){
            vm.id = xmlId;
            ProductXmlFileServes.get({id: vm.id}, function (resp) {
                vm.customStore = resp.customStoreId;
                vm.productXml = resp;
            });
        }

        vm.categories = Category.getAllByCondition({items: []});

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.customStoreChange = function(){
            if (vm.customStore == null) {
                vm.productXml.customStore = null;
            }
            for (var i = vm.stores.length - 1; i >= 0; i--) {
                var s = vm.stores[i];
                if (s.id == vm.customStore) {
                    vm.productXml.customStoreId = s.id 
                    vm.productXml.customStoreName = s.name;
                    break;
                }
            }
        }

        $scope.validate = function () {
            if (vm.productXml.categoryId == null || vm.productXml.categoryId == "") {
                MessageService.error("请选择分类");
                return false;
            }
            
            if (vm.productXml.name == null || vm.productXml.name == "") {
                MessageService.error("请输入作品名称");
                return false;
            }
            if (vm.productXml.themeId == null || vm.productXml.themeId == "") {
                MessageService.error("请选择主题分类");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            ProductXml.update(vm.productXml,function(data){
                MessageService.success("保存成功");
                $uibModalInstance.close(data);
            },function(){
                MessageService.error("保存失败");
            });
        }

        $scope.save2 = function(){
            if (!$scope.validate()) {
                return;
            }
            ProductXml.update(vm.productXml,function(data){
                var url = vm.platformSetting.productXmlUrl + '/#/page-my-suffix/'+data.identifier+'/'+vm.platformSetting.productXmlKey+'/'+data.id+'/'+data.name+'/'+vm.productXml.pattern+'/'+vm.productXml.document.replace;
                window.location.href = url;
            },function(){
                MessageService.error("保存失败");
            });
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }

})();
