(function() {
    "use strict";
    angular
        .module("editorApp")
        .controller("StorageBuyController", StorageBuyController);
    StorageBuyController.$inject = ['$state', '$q', 'UidService', 'StoreStoragePrice', '$scope', '$location', '$stateParams', '$localStorage', 'Qinius',
          '$uibModal', 'MessageService',   '$interval', '$uibModalInstance', 'DateUtils', 'StoreStoragePayOrder',
         'Customer', 'CustomerStorages', "$timeout"
    ];;

    function StorageBuyController($state, $q, UidService, StoreStoragePrice, $scope, $location, $stateParams, $localStorage, Qinius,
         $uibModal, MessageService,  $interval, $uibModalInstance, DateUtils, StoreStoragePayOrder,
         Customer, CustomerStorages, $timeout) {
        var vm = this;
        vm.price = null;
        // 是否点击移动按钮
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        if ($localStorage.user !=null) {
            vm.userId = $localStorage.user.id
        }
        clearInterval($scope.timer);

        vm.store = $localStorage.store;
        $scope.domain = $localStorage.domain;
        vm.customer = Customer.current();

        vm.tokens = Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"});

        vm.customerStorage =  CustomerStorages.getByCustomer({customerId: vm.userId});
        vm.storeStoragePrices =  StoreStoragePrice.getAllByCondition1({customerId: vm.userId});

        $scope.selected = function(p){
            if(vm.price){
                return vm.price.id == p.id;
            }
        }

        $scope.selectPrice = function(p){
            if(p == null || (vm.price != null && p.id == vm.price.id)){
                return;
            }
            vm.price = p;
            vm.thisTime = currentDateLater(p.periodOfValidity);
            vm.storageOrder = null;
            // $('#qrcode1').html('<img src="content/images/RQcode.png" style="width: 168px;" alt="" srcset="">');
            $scope.createOrder();
        }

        $scope.close = function(){
            clearInterval($scope.timer);
            $uibModalInstance.dismiss('cancel');
        }

        function currentDateLater(item) {
            var date = new Date();
            var yearNumber = Math.floor(item / 12);
            var monthNumber = item % 12;
            if ((date.getMonth() + 1 + monthNumber) > 12) {
                yearNumber++;
            }
            var y = date.getFullYear() + yearNumber;
            var m = (date.getMonth() + monthNumber) % 12 + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + m + '-' + d;
        }


        function wechartPayState(p) {
            if (vm.wechartPayShow) {
                ServiceWxJS.payConfig({
                    id: vm.salesOrder.id,
                    storeId: vm.storeId,
                    totalFee: vm.salesOrder.thirdAmount,
                    type:"web",
                    names:vm.salesOrder.productName
                }, function(result){
                    if (result.status!= 200) {
                        MessageService.error("获取支付信息失败");
                        return;
                    }
                    var data = result.message;
                    console.log(result);
                    $('#qrcode1').html("");
                    // 支付二维码
                    $('#qrcode1').qrcode({
                        render: "canvas",
                        width: 200,
                        height: 200,
                        text: result.message.url
                    });
                }, function(){
                    MessageService.error("获取支付信息失败");
                    return;
                });
            }
        }


        $q.all([  vm.tokens.$promise, vm.customerStorage.$promise, vm.storeStoragePrices.$promise]).then(function() {
            if (vm.storeStoragePrices && vm.storeStoragePrices.status == 200) {
                vm.prices = vm.storeStoragePrices.message;
                vm.prices.sort(function(a, b){
                    return a.seq - b.seq;
                })
                if (vm.prices) {
                    for (var i = vm.prices.length - 1; i >= 0; i--) {
                        var p = vm.prices[i];
                        if (p.default) {
                            vm.price = p;
                            $scope.createOrder()
                        }
                    }
                    if (vm.price) {
                        vm.thisTime = currentDateLater(vm.price.periodOfValidity);
                    }
                }
            }
            vm.token = vm.tokens.token;
        });

        $scope.goBack =function(){
            $state.go("webGallery", {storeId:vm.storeId});
        }


        $scope.createOrder = function(){
            if (vm.price == null) {
                return;
            }
            StoreStoragePayOrder.createOrder({storeStoragePriceId:vm.price.id}, function(data){
                if (data && data.status == 200) {
                    vm.storageOrder = data.message;
                    StoreStoragePayOrder.wechatQRPay({id:data.message.id}, function(result){
                        if (result.status!= 200  || !result.message || !result.message.url) {
                            MessageService.error("获取支付信息失败!");
                            return;
                        }
                        clearInterval($scope.timer);
                        //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                        $scope.timer = setInterval(function() {
                            StoreStoragePayOrder.get({
                                id: vm.storageOrder.id
                            }, function(val) {
                                if (!!val && val.message && val.message.paid) {
                                    clearInterval($scope.timer);
                                    MessageService.success("支付完成");
                                    $uibModalInstance.close();
                                }
                            }, function() {});
                        }, 2000);

                        var d = result.message;
                        console.log(result);
                        $('#qrcode1').html("");
                        // 支付二维码
                        $('#qrcode1').qrcode({
                            render: "canvas",
                            width: 168,
                            height: 168,
                            text: result.message.url
                        });
                    })

                }
            })
        }


    }
})();
