(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('CouponDialogController', CouponDialogController);

    CouponDialogController.$inject = ['$timeout', '$scope', 'Product', '$stateParams', '$q', 'Qinius'];

    function CouponDialogController($timeout, $scope, Product, $stateParams, $q, Qinius) {
        var vm = this;
        vm.storeId = $stateParams.storeId;
        vm.commodityList = [];
        vm.templates = JSON.parse(localStorage.getItem("templates"));
        // localStorage.removeItem('templates');
        $scope.domains = Qinius.getDomain1({storeId: vm.storeId});
        var items = [{key: "saleable", op: "=", value: "true"}, {key: "storeId", op: "=", value: vm.storeId}];

        vm.commodityList = Product.getAllByCondition2({
            items: items,
            sort: ["seq,asc"]
        });

        vm.productsData = [];
        $q.all([vm.commodityList.$promise, $scope.domains.$promise]).then(function () {
            vm.domain = 'https://' + $scope.domains.domains[0] + '/';
            angular.forEach(vm.commodityList, function (list) {
                angular.forEach(vm.templates, function (temp) {
                    if (temp.id == list.id) {
                        vm.productsData.push(list)
                    }
                })
            })
            console.log(vm.productsData)
        });

    }
})();
