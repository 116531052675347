(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('AfterSaleNewController', AfterSaleNewController);

    AfterSaleNewController.$inject = ['$scope', '$q', '$stateParams', 'previousState', 'SalesOrder', '$state', 'Qinius', 'ImageService',
    '$localStorage', 'AfterSale', '$timeout', 'AfterSaleProblem', 'UidService', 'entity', 'MessageService','$uibModalInstance'];

    function AfterSaleNewController($scope, $q, $stateParams, previousState, SalesOrder, $state, Qinius, ImageService,
        $localStorage, AfterSale, $timeout, AfterSaleProblem, UidService, entity, MessageService, $uibModalInstance) {
        var vm = this;
        vm.contents = [];
        vm.afterSale = entity;
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.salesOrder = entity.salesOrder;
        vm.orderId = entity.orderId;
        if (!vm.storeId) {
            return;
        }
        vm.removeFile = removeFile;
        // vm.goList = goList;
        vm.domains = Qinius.getDomain1();
        vm.tokens = Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"});

        vm.photos = [];

        // function goList() {
        //    $state.go('sales-order-view', {storeId: vm.storeId, id: vm.orderId, tryEdit:false});
        // }

        // vm.salesOrder = SalesOrder.get({id: vm.orderId});
        $q.all([vm.domains.$promise,  vm.salesOrder.$promise, vm.tokens.$promise]).then(function() {
            vm.token = vm.tokens.token;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            if (vm.salesOrder&& vm.salesOrder.orderLines && vm.salesOrder.orderLines[0] && vm.salesOrder.orderLines[0].orderArticle 
                && vm.salesOrder.orderLines[0].orderArticle.prototype == 'StoreSelf' ) {
                vm.problemsAfterSales =AfterSaleProblem.getAllByCondition({items:[{key: "enabled", op: "=", value: "true"},
                    {key: "storeId", op: "=", value: vm.storeId}], sort:["sort,asc"]});
            }else{
                vm.problemsAfterSales =AfterSaleProblem.getAllByCondition1({items:[{key: "enabled", op: "=", value: "true"}], sort:["sort,asc"]});
            }
            vm.orderNumber = vm.salesOrder.number;
            $scope.problems = vm.problemsAfterSales.filter(function(item){return item.enabled == true});
            document.addEventListener("DOMContentLoaded", function(event){
                ImagesZoom.init({
                    "elem": ".list"
                });
            }, false);

            $scope.save = function () {
                vm.afterSale.photo = vm.photos.toString();
                vm.afterSale.salesOrder.id = vm.salesOrder.id;
                vm.afterSale.salesOrder.number = vm.salesOrder.number;
                vm.afterSale.type = vm.problemsAfterSale.content;
                if (!!vm.problemsAfterSale2) {
                    vm.afterSale.problem = vm.problemsAfterSale2.content;
                } else {
                    vm.afterSale.problem = "";
                }
                vm.afterSale.describe = vm.describe;
                vm.afterSale.storeId = vm.storeId;
                vm.afterSale.problemId = vm.problemsAfterSale.id;
                console.log(vm.afterSale);

                AfterSale.save(vm.afterSale, function (data) {
                    MessageService.success("售后反馈成功");
                    $uibModalInstance.close('success');
                });
            }
        });

        $scope.problemChange = function(problem) {
            if (!!problem) {
                AfterSaleProblem.getListByparent({
                    id: problem.id
                }, function(data) {
                    if(data.length<=1&&data[0].content==null){
                        vm.problemsAfterSales2 = "";
                    }else{

                        vm.problemsAfterSales2 = data;
                    }
                });
            }
        };

        //产品 coverImg更改
        window.updateImgFile = function ($event) {
            var file = $event.target.files[0];
            ImageService.backendUploadPics(file,$scope.domain, vm.token, function (data,f) {
                vm.photos.push(data.key);
                $scope.$digest()
            },function (res) {
                MessageService.error("文件上传失败");
            })
        };

        // 删除上传图片
        function removeFile(index) {
            vm.photos.splice(index, 1);
        }
      $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
