(function () {
    'use strict';

    var impower = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/gallery/impower.html',
        controllerAs: 'vm',
        bindings: {
            storeId:'=',
            isAutor:'='
        },

        controller: ['$scope', '$stateParams', '$q', '$localStorage', 'Qinius', 'Store', 'StoreWechatSetting', 'PlatformWechatSetting', 'Theme', 'Article','Customer',
            function ($scope, $stateParams, $q, $localStorage, Qinius, Store, StoreWechatSetting, PlatformWechatSetting, Theme, Article, Customer) {
                var vm = this;
                vm.isAutor = false;
                vm.url = null;
                vm.storeAppId = $localStorage.storeAppId;
                vm.store =  $localStorage.store;
                vm.editorNotifyUrl= $localStorage.editorNotifyUrl;
                vm.storeNotifyUrl = $localStorage.storeNotifyUrl;
                vm.platformAppId = $localStorage.platformAppId;

                $scope.$on('changeAutor', function(event, args) {
                    console.log(args)
                    vm.isAutor = args.isAutor;
                    vm.url = args.url;
                    // 兼容未配置导航栏
                    if(args.tag){
                        if(args.tag=='gallery'){
                            vm.url=vm.editorNotifyUrl+"/#/"+$stateParams.storeId+'/'+args.tag
                        }else{
                            vm.url=vm.storeNotifyUrl+"/#/"+$stateParams.storeId+'/'+args.tag
                        }
                    }

                });

               
                if(vm.storeAppId == null|| vm.storeAppId=="" || vm.store == null){
                    vm.publicSetting = StoreWechatSetting.getPublicWechatSetting();
                    vm.storeSetting = StoreWechatSetting.getByStoreId({storeId:vm.storeId});
                    vm.store = Store.get({id:vm.storeId});
                    $q.all([vm.storeSetting.$promise, vm.publicSetting.$promise, vm.store.$promise]).then(function(){
                        if (vm.store.wechatType == null || vm.store.wechatType == "" || vm.store.wechatType == "Public") {
                            $localStorage.storeAppId = vm.publicSetting.authorizerAppId;
                        }else{
                            $localStorage.storeAppId = vm.storeSetting.authorizerAppId;
                        }
                        $localStorage.store = vm.store;
                    });
                }

                if (vm.editorNotifyUrl == null || vm.editorNotifyUrl == ""|| vm.storeNotifyUrl == null || vm.storeNotifyUrl == "") {
                    vm.platformSetting = PlatformWechatSetting.get();
                    $q.all([vm.platformSetting.$promise]).then(function(){
                        vm.editorNotifyUrl= vm.platformSetting.editorNotifyUrl;
                        vm.storeNotifyUrl = vm.platformSetting.storeNotifyUrl;
                        vm.platformAppId = vm.platformSetting.appId;
                        $localStorage.storeNotifyUrl = vm.platformSetting.storeNotifyUrl;
                        $localStorage.editorNotifyUrl = vm.platformSetting.editorNotifyUrl;
                        $localStorage.platformAppId = vm.platformSetting.appId;
                    });
                }

                $scope.getUserInfo = function () {
                    if(vm.url==null||vm.url==undefined){
                        return
                    }
                    localStorage.setItem('auth_url',vm.url)
                    var customerNotifyUrl = encodeURIComponent(vm.storeNotifyUrl+"/#/"+$stateParams.storeId+"/auth_notify");
                    if (vm.store.wechatType == null || vm.store.wechatType == "" || vm.store.wechatType == "Public") {
                        window.location.href ="https://open.weixin.qq.com/connect/oauth2/authorize?appid="+vm.storeAppId
                            +"&redirect_uri="+ customerNotifyUrl
                            +"&response_type=code&scope=snsapi_base&state=STATE&component_appid="+vm.platformAppId+"#wechat_redirect";
                    }else{
                        window.location.href ="https://open.weixin.qq.com/connect/oauth2/authorize?appid="+ vm.storeAppId
                            +"&redirect_uri="+ customerNotifyUrl
                            +"&response_type=code&scope=snsapi_userinfo&state=STATE&component_appid="+vm.platformAppId+"#wechat_redirect";
                    }
                }
            }]
    };

    angular
        .module('editorApp')
        .component('impower', impower);
})();
