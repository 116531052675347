(function () {
    'use strict';

    var previewPhoto = {
        templateUrl: 'app/entities/gallery/preview-photo.html',
        bindings: {
            previewList:'=',//图片列表
            initialSlide:'=',//起始页
            memberDelete:'='
        },
        controller: ['$stateParams', '$state', '$scope', 'Store', '$localStorage', '$q', 'Qinius', '$timeout','Gallery',
        function ($stateParams, $state, $scope, Store, $localStorage, $q, Qinius, $timeout,Gallery) {
            var vm = this;
            var swiper= null;
            $scope.activePhoto = 0;
            $scope.deleteUpdate = true;
            $scope.previewList = vm.previewList;
            $scope.initialSlide = vm.initialSlide;
            $scope.domains = Qinius.getDomain1();
            $scope.memberDelete = vm.memberDelete;
            vm.swiperInit = false;
            $q.all([$scope.domains.$promise,$scope.previewList.$promise]).then(function(){
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                setTimeout(function() { 
                    SwiperInit();
                },300)
            })
            function SwiperInit() {
                swiper = new Swiper ('.swiper-container', {
                    initialSlide : $scope.initialSlide,
                    on:{
                        slideChangeTransitionEnd: function(){
                            activeIndexChange(this.activeIndex)
                        },
                    }
                })
            }

            function activeIndexChange(activeIndex){
                console.log(activeIndex);
                $timeout(function(){
                    $scope.activePhoto = activeIndex;
                })
            }

            $scope.deletePhoto = function(){
                var del_arr = [];
                del_arr.push($scope.previewList[$scope.activePhoto].id);
                Gallery.batchDelete(del_arr, function (res) {
                    if (res) {
                        console.log(res);
                        $scope.previewList.splice($scope.activePhoto,1);
                        if ($scope.activePhoto == $scope.previewList.length-1) {
                            $scope.initialSlide = $scope.activePhoto - 1;
                        }else{
                            $scope.initialSlide = $scope.activePhoto;
                        }
                        $scope.deleteUpdate = false;
                        $timeout(function(){
                            $scope.deleteUpdate = true;
                            $timeout(function(){
                                SwiperInit()
                            })
                        })
                    }
                }, function (error) {

                });
                
            }
        }]
    };
    
    angular
        .module('editorApp')
        .component('previewPhoto', previewPhoto);
})();
