(function () {
    'use strict';
    angular
        .module('editorApp')
        .controller('GalleryController', GalleryController)
        .filter('dateFilter', dateFilter)
        .filter('sizeTransition', function () {
            return function(size){
                if (!size)
                    return 0;
                var num = 1000.00;
                if (size < num)
                    return size + "B";
                if (size < Math.pow(num, 2))
                    return (size / num).toFixed(2) + "KB"; //kb
                if (size < Math.pow(num, 3))
                    return (size / Math.pow(num, 2)).toFixed(2) + "MB"; //M
                if (size < Math.pow(num, 4))
                    return (size / Math.pow(num, 3)).toFixed(2) + "GB"; //G
                return (size / Math.pow(num, 4)).toFixed(2) + "TB"; //T
            }
        })
        .directive("ngTouchstart", function () {
            return {
                controller: ["$scope", "$element", function ($scope, $element) {

                    $element.bind("touchstart", onTouchStart);
                    function onTouchStart(event) {
                        var method = $element.attr("ng-touchstart");
                        $scope.$apply(method);
                    }

                }]
            }
        })
        .directive("ngTouchmove", function () {
            return {
                controller: ["$scope", "$element", function ($scope, $element) {

                    $element.bind("touchstart", onTouchStart);
                    function onTouchStart(event) {
                        event.preventDefault();
                        $element.bind("touchmove", onTouchMove);
                        $element.bind("touchend", onTouchEnd);
                    }
                    function onTouchMove(event) {
                        var method = $element.attr("ng-touchmove");
                        $scope.$apply(method);
                    }
                    function onTouchEnd(event) {
                        event.preventDefault();
                        $element.unbind("touchmove", onTouchMove);
                        $element.unbind("touchend", onTouchEnd);
                    }

                }]
            }
        })
        .directive("ngTouchend", function () {
            return {
                controller: ["$scope", "$element", function ($scope, $element) {

                    $element.bind("touchend", onTouchEnd);
                    function onTouchEnd(event) {
                        var method = $element.attr("ng-touchend");
                        $scope.$apply(method);
                    }

                }]
            }
        })
        function dateFilter() {
            return function (input) {
                var year = input.split('年')[0];
                var mon = input.split('年')[1].split('月')[0];
                var day = input.split('年')[1].split('月')[1].split('日')[0];
                if (mon < 10) {
                    mon = '0' + mon;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                input = year + '年' + mon + '月' + day + '日';
                return input;
            }
        }
    GalleryController.$inject = ['$scope', '$state', 'Gallery', '$q', '$stateParams', '$localStorage', 'WxJS', 'Qinius', 'SweetAlert', '$rootScope',
        '$timeout', 'PlatformWechatSetting', 'StoreStyleSetting', 'Theme', 'CommonService', 'Folder', 'FontManagement','Iphone', 'CustomerStorages', 'Customer',
        'CustomerUploadAble' ,'$sce' ,'StoreConfig','MessageService','MessageNotification','DateUtils', '$websocket'];
    function GalleryController($scope, $state, Gallery, $q, $stateParams, $localStorage, WxJS, Qinius, SweetAlert, $rootScope,
                               $timeout, PlatformWechatSetting, StoreStyleSetting, Theme, CommonService, Folder, FontManagement,Iphone, CustomerStorages, Customer,
                               CustomerUploadAble ,$sce , StoreConfig,MessageService,MessageNotification,DateUtils, $websocket) {

        // 数据存储结构
        // $scope.photos -- for -- photo -- photo.galleries -- for -- gallery
        var vm = this;
        var isWx = false;
        vm.path = window.location.href;
        var browser = CommonService.browserVersions();
        if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
            var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //在微信中打开
                // alert("在微信中打开")
                isWx = true;
            }else if(browser.versions.android){
                //是否在安卓浏览器打开
                // alert("是否在安卓浏览器打开")
                isWx = false;
            }
            if (ua.match(/WeiBo/i) == "weibo") {
                //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) == "qq") {
                //在QQ空间打开
            }
            if (browser.versions.ios) {
                //是否在IOS浏览器打开
                isWx = false;
                // alert("是否在IOS浏览器打开")
            }
        } else {
            //否则就是PC浏览器打开
        }
        //这一步是分页下拉  刷新滚动条置顶
        window.scrollTo(0, 0);

        wx.hideOptionMenu();
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.platformSetting = PlatformWechatSetting.get();
        $scope.currentNav = 'gallery';
        $scope.selecting=false
        $scope.preview = false;
        $scope.settingFolder = false;
        $scope.folderId = null;
        // 判断是否重复
        function checkExist(list, obj) {
            if (list == null || list.length == 0) {
                return true;
            }
            for (var i = 0; i < list.length; i++) {
                if (list[i].getFullYear() == obj.getFullYear() &&
                    list[i].getMonth() == obj.getMonth() &&
                    list[i].getDate() == obj.getDate()) {
                    return false;
                }
            }
            return true;
        }
        vm.messageShow = true;
        vm.messageList = "";
        getMessageList();
        function getMessageList() {
            MessageNotification.getAllByCondition({
                    items:[
                        {key:"pageType", op : "=", value:"Gallery"},
                        {key:"storeId", op : "=", value:vm.storeId},
                        {key:"available", op : "=", value:"true"},
                        {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                }, function (res) {
                    res.forEach(function (item, index) {
                        vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                    })
                }
            )
        }

        // 弹窗显示
        $scope.popupShow=function(){
            document.body.style.overflow = 'hidden';
            vm.isPageWarnExamine = true
            StoreConfig.byStoreIdAndType({type:"STORAGETERMS"},function(res){
                // 富文本内容
                $scope.galleryAgreement = $sce.trustAsHtml(res.value);
            })
        }
        // 弹窗关闭
        $scope.popupClose=function(){
            document.body.style.overflow = 'auto';
            vm.isPageWarnExamine = false
        }

        vm.folderDialog = false;
        $scope.folderDialogShow = function(folder){
            document.body.style.overflow = 'hidden';
            $scope.folderIds.push(folder.id)
            $scope.editFolderNameArr = folder;
            if ($scope.currentNav=='sharePhotos') {
                vm.shareFolderDialog = true;
            }else{
                vm.folderDialog = true;
            }
        }
        $scope.folderDialogClose = function(){
            document.body.style.overflow = 'auto';
            $scope.folderIds = [];
            $scope.editFolderNameArr = null;
            vm.folderDialog = false;
        }

        vm.shareFolderDialog = false
        $scope.shareFolderDialogClose = function(){
            document.body.style.overflow = 'auto';
            $scope.folderIds = [];
            $scope.editFolderNameArr = null;
            vm.shareFolderDialog = false;
        }

        $scope.quitPhotos = function(){
            if($scope.folderIds.length==0){
                return
            }
            vm.shareFolderDialog = false;
            addAlertClass()
            swal({
                title: "确定要退出当前相册吗？",
                text: "",
                showCancelButton: true,
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                closeOnConfirm: false
            },
            function (isConfirm) {
                if (isConfirm) {
                    Folder.quit({
                        folderIds:$scope.folderIds
                    }, function (resp) {
                        swal.close();
                        $scope.folderQuit = true;

                        setTimeout(function () {
                            $scope.folderQuit = false;
                            $scope.$digest();
                            loadShareFolders();
                            $scope.selecting = false;
                        },1500)

                    },function (err) {
                        swal.close();
                    })
                } else {
                    console.log('取消');
                }
            });
        }

        $scope.settingFolderId = 0;
        // 相册设置
        $scope.setting = function(){
            $scope.settingFolderId = $scope.folderIds[0];
            $scope.folderDialogClose();
            $scope.settingFolder = true;
        }

        $scope.hideSettingFolder =function(){
            $scope.settingFolder = false;
        }


        var a
        var b
        var c
        // 触摸开始
        $scope.touchStart=function(e){
            vm.onClose=false
            e = e || window.event
            a=e.changedTouches[0].pageY
        }
        // 移动
        $scope.touchmove=function(e){
            vm.onClose=false
            e = e || window.event
            b=e.changedTouches[0].pageY
            if (b-a>0) {
                document.getElementById('slidingDistance').style.bottom=-(b-a)+'px'
            }

        }
        // 触摸结束
        vm.onClose=false
        $scope.endTouch=function(e){
            e = e || window.event
            if (b-a>100) {
                vm.onClose=true
                $scope.popupClose()
            }else{
                document.getElementById('slidingDistance').style.bottom='0px'
            }
        }

        // 是否进入文件夹内
        $scope.folderInside = false;
        // 选中的某一文件夹信息
        $scope.folderOne = {};
        // 是否点击移动按钮
        $scope.moveGallery = false
        // 文件夹点击
        $scope.folderClick = false;
        $scope.selectAllFolder = false;
        $scope.selectOne = {
            folderId: '',
            selected: false
        };
        $scope.selectAllGallery = false;
        $scope.moveGalleryIds = [];
        $scope.folderIds = [];
        $scope.editFolderNameArr = [];
        vm.showIcon = false;

        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $scope.agentIos = true;

        vm.isIPhone6 = isIPhone6();
        vm.galleryPage = [];
        var tempArr = [];

        // 成功多少张
        var indd = 0;
        var errorImageArr = [];
        vm.loadMore = loadMore;
        var repeatCount = 0;
        var cancelledCount = 0;
        vm.indexNum = 0;
        vm.itemsPerPage = 40;
        // var Orientation = 0;
        vm.more = true;
        CommonService.setDocumentTitle("图库");
        vm.preview = {
            previewList : [],
            initialSlide:0
        }
        //区别小程序、公众号
        if (window.location.href.indexOf("?") == -1) {
            localStorage.setItem('distinguish', '');
            var storeAuth = localStorage.getItem('store-' + vm.storeId + '-authenticationToken');
            localStorage.setItem('editor-' + vm.storeId + '-authenticationToken', storeAuth);
            vm.type = 'Wechat';
        } else {
            vm.type = 'Mini';
            var r = window.location.href.split('?')[1];
            var A = r.split('&')[0].split('=')[1];
            var B = r.split('&')[1].split('=')[1];
            // var C = r.split('&')[2].split('=')[1];
            var distinguish = decodeURIComponent(A);
            var tempAuthorization = decodeURIComponent(B);
            // vm.target = decodeURIComponent(C);
            var Authorization = tempAuthorization.slice(7, tempAuthorization.length);
            if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake' || distinguish == 'toutiaoApp' || distinguish == 'alipayApp' || distinguish == 'jdApp') {
                localStorage.setItem('Production', 'gallery');
                localStorage.setItem('distinguish', distinguish);
                $localStorage.authenticationToken = Authorization;
                vm.uploadType = distinguish;
            } else {
                localStorage.setItem('distinguish', '');
            }
            vm.target = getQueryVariable('target');
            vm.source = getQueryVariable('flag');
        }

        function getQueryVariable(variable) {
            // var query = window.location.href.substring(1);
            var query = window.location.href.split('?')[1];
            if (!query) {
                query = window.location.href;
            }
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
            }
            return(false);
        }

        //链接进来判断是否授权
        vm.isAutor = false;
        if ($localStorage.authenticationToken == null) {
            CommonService.isLogin($scope,'gallery')
            return
        }

        // alert(vm.type)
        //避免重复调用
        var production = localStorage.getItem('Production');
        if (production == 'make') {
            return;
        }

        // $scope.photos = Gallery.get();
        $scope.domains = Qinius.getDomain1();
        vm.totalCount = 0;
        var flag = true;//用来标志是否滑动到底 加载完成
        //加载图库Loading
        vm.galleryLoading = false;

        vm.totalCount = null;
        vm.maxId = null;
        function loadAll(folderId) {
            if(vm.galleryLoading) return;
            vm.galleryLoading = true;
            if (folderId == 'isNull') {
                $scope.folderId = null;
            }else{
                $scope.folderId = folderId;
            }
            var items = [];
            if (folderId == 'isNull') {
                items.push({key: "folderId", op: "isNull", value: 1})
            }else{
                items.push({key: "folderId", op: "=",value: folderId});
            }
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            if (folderId == 'isNull') {
                Gallery.getPage({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Gallery.getPage({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                return ["id,desc"];
            }

            function onSuccess(data,headers) {
                vm.galleryLoading = false;
                if (vm.totalCount == null) {
                    vm.totalCount = Number(headers('X-Total-Count'));
                }
                if (data.length > 0 && vm.maxId == null && vm.photoSort == 1) {
                    vm.maxId = data[0].id;
                }
                if (vm.indexNum == 0 && data != null && data.length != 0) {
                    $scope.noPic = false;
                } else {
                    // $scope.noPic = true;
                }
                if (data.length < vm.itemsPerPage) {
                    vm.more = false;
                    flag = false;
                } else {
                    vm.more = true;
                    flag = true;
                }

                angular.forEach(data, function (item) {
                    vm.galleryPage.push(item);
                });
                CommonService.setDocumentTitle("图库");
                getPagePhoto();
            }

            function onError(error) {
                vm.galleryLoading = false;
                MessageService.hint("网络错误，请切换网络重试");
            }
        }

        function loadMore() {
            if(vm.galleryLoading) return;
            vm.indexNum++;
            if ($scope.folderInside) {
                loadAll($scope.folderOne.id);
            } else {
                loadAll('isNull');
            }
        }

        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };

        //判断是ios or Android
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        console.log(localStorage.getItem('distinguish'));
        vm.isWx = false;
        if (wx) {
            vm.isWx = true;
            var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
            //if((localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake') && (isAndroid == true && isiOS == false)){
            //    WxJS.get1({url: url}, function (config) {
            //        alert(config.appId);
            //        alert(config.timestamp);
            //        alert(config.nonceStr);
            //        alert(config.signature);
            //        wx.config({
            //            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            //            appId: config.appId, // 必填，公众号的唯一标识
            //            timestamp: config.timestamp, // 必填，生成签名的时间戳
            //            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            //            signature: config.signature,// 必填，签名，见附录1
            //            jsApiList: ['chooseImage', 'previewImage', 'uploadImage'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            //        });
            //        wx.ready(function () {
            //            console.log('weChatApplet api ready!');
            //        });
            //        wx.error(function (res) {
            //            console.log(res);
            //        });
            //    }, function () {
            //        console.log("editor: wx config error");
            //    });
            //}else{
            WxJS.get({url: url}, function (config) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: config.appId, // 必填，公众号的唯一标识
                    timestamp: config.timestamp, // 必填，生成签名的时间戳
                    nonceStr: config.nonceStr, // 必填，生成签名的随机串
                    signature: config.signature,// 必填，签名，见附录1
                    jsApiList: ['chooseImage', 'previewImage', 'uploadImage'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(function () {
                    console.log('editor: wechat api ready!');
                });
                wx.error(function (res) {
                    // alert("wx.error: "+res.errMsg)
                    console.log(res);
                });
            }, function (res) {
                // alert("data: "+res.data);
                // alert("status: "+res.status);
                // alert("statusText: "+res.statusText);
                console.log("editor: wx config error");
            });
        }
        //}

        //获取分页图片 (删除、上传都会重新刷新获取一定数量的照片)
        function getPagePhoto() {
            console.log(vm.galleryPage);//这个是分页限制的数据格式 每次只显示10条
            var timeList = [];
            vm.photoArray = [];
            //判断日期是否重复
            for (var i = 0; i < vm.galleryPage.length; i++) {
                var photoCreatedDate = new Date(vm.galleryPage[i].createdDate);
                if (checkExist(timeList, photoCreatedDate)) {
                    timeList.push(photoCreatedDate);
                }
            }
            //将相同日期下的照片整合
            for (var m = 0; m < timeList.length; m++) {
                var tempObj = {};
                var tempItems = [];
                for (var j = 0; j < vm.galleryPage.length; j++) {
                    var createdDate = new Date(vm.galleryPage[j].createdDate);
                    if (timeList[m].getFullYear() == createdDate.getFullYear() &&
                        timeList[m].getMonth() == createdDate.getMonth() &&
                        timeList[m].getDate() == createdDate.getDate()) {
                        tempItems.push(vm.galleryPage[j])
                    }
                }
                var time = timeList[m].getFullYear() + '年' + (timeList[m].getMonth() + 1) + '月' + timeList[m].getDate() + '日';
                tempObj = {"createDate": time, "galleries": tempItems};
                vm.photoArray.push(tempObj);
            }
            $scope.photos = vm.photoArray;
            $(".photo-show").show();
            $scope.selectAllGallery = true;
            $scope.photos.forEach(function (photo) {
                photo.select_photoAll = true;
                photo.galleries.forEach(function (gallerie) {
                    if(!gallerie.select_photo){
                        photo.select_photoAll = false;
                        $scope.selectAllGallery = false;
                    }
                })
            })
        };
        vm.theme = StoreConfig.byStoreIdAndType2({type: 'Theme', storeId: vm.storeId});

        vm.storeStyleSetting = StoreStyleSetting.get();
        $scope.navigationSettings = [];
        $q.all([$scope.domains.$promise, vm.storeStyleSetting.$promise,vm.theme.$promise]).then(function () {
            //风格切换  引入不同的css文件
            if (vm.theme) {
                localStorage.setItem('Theme', vm.theme.value);
            } else {
                localStorage.setItem('Theme', 'one');
            }
            Theme.judge();
            if (!getQueryVariable('folderId')) {
                loadAll('isNull');
            }

            loadFolders();
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            $scope.select_photo = false;
            $scope.delBar = false;
            $scope.select_photoAll = false;
            $scope.photoCount = false;
            $scope.column = 3;
            // if (!!$scope.photos.length) {
            //     $scope.noPic = false;
            // }

            vm.storeStyleSetting.navigationSettings.sort(compare('seq'));
            console.log(vm.storeStyleSetting)
            var urlHref = window.location.href.split("?")[0] || window.location.href;
            $scope.navigateOpen = vm.storeStyleSetting.navigateOpen;
            angular.forEach(vm.storeStyleSetting.navigationSettings,function (nav) {
                if(!nav.cancelled&&nav.used){
                    $scope.navigationSettings.push(nav);
                    if(urlHref==nav.pathWechat){
                        $scope.nav_id = nav.id;
                    }
                    if (nav.columnName.length > 4) {
                        nav.columnName = nav.columnName.substring(0, 4) + "..";
                    }
                }
            })

            if(!$scope.navigationSettings.length||!$scope.navigateOpen){
                vm.platformWechatSetting = PlatformWechatSetting.get({});
            }
            vm.isNavigateOpen = true;

            if(vm.uploadType=='alipayApp'){
                $scope.agentIos = true;
            }else if(!isWx){
                $scope.agentIos = false;
            } else if (isAndroid == true && isiOS == false) {
                console.log('android');
                $scope.agentIos = true;

            } else if (isAndroid == false && isiOS == true) {
                console.log('ios');
                // 使用自带的上传图片功能  不调用微信的jssdk
                $scope.agentIos = false;
                //非调用微信公众号上传图片 无9张限制 可多张上传 上传到七牛  并且可以返回路径在页面显示
            }
            document.addEventListener('UniAppJSBridgeReady', function() {})
            getCustomer();
        });

        function getCustomer() {
            Customer.current({},function(data){
                vm.customer = data;
                if(!vm.userId){
                    vm.userId = vm.customer.id
                }
                websocket();
                getByCustomer();
            },function(error){
                console.log(error);
            });
        }
        function getByCustomer() {
            CustomerStorages.getByCustomer({
                customerId: vm.customer.id
            }, function (res) {
                vm.isProgress = true;
                progressInit(res)
            })
        }
        function progressInit(item){
            vm.sumSize =  Number(item.initSize) + Number(item.buySize);
            vm.useSize = Number(item.usedSize);
            vm.percentage = 0;
            $timeout(function () {
                vm.percentage = vm.useSize / vm.sumSize;
            });
        };
        $scope.progressColor = function (size) {
            return progressColor(size)
        }

        $scope.$on('uoloadBroken', function(event, args) {
            if(args){
                updatePic();
            }
        });

        vm.uploadPhotoItem=[];
        vm.today = "";
        $scope.$on('uploadStart',function(){
            console.log("开始上传了");
            console.log($scope.photos);
            var date = new Date();
            vm.today = date.getFullYear() + '年'+ (date.getMonth() + 1) + '月' + date.getDate() + '日';
            if($scope.photos.length > 0 && $scope.photos[0].createDate == vm.today){
                return;
            }
            //造一个空的今日title项
            $scope.photos.unshift({
                createDate: vm.today,
                galleries:[]
            })

        })
        $scope.$on('uoloadFinished', function(event, args) {
            if(args){
                if($scope.photos&&$scope.photos.length){
                    angular.forEach($scope.photos[0].galleries,function (qin) {
                        if(qin.blobCover){
                            qin.blobCover = null;
                        }
                    })
                }
                // updatePic();
                getByCustomer();
            }
        });
        $scope.$on('uoloadDuplicate', function(event, res) {
            updatePic();
        });

        $scope.uploadPhoto = function () {
            if(vm.source&&vm.customer && !vm.customer.phone){
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/pages/information/bindPhone/bindPhone'})
                    return
                }
                wx.miniProgram.redirectTo({url: '/pages/information/bindPhone/bindPhone'});
                return;
            }
            vm.originalNav = $scope.originalNav;
            if (vm.albumOwnerId) {
                uploadVerify2(vm.platformSetting, CustomerUploadAble, vm.storeId, vm.albumOwnerId,true, function () {
                    if($scope.agentIos){
                        $rootScope.$broadcast('clickAndroidUpload',true);
                    }else {
                        $timeout(function () {
                            $("#uploadImageMutipleId").trigger("click");
                        })
                    }
                },function(error){
                    if(error === "networkError"){
                        MessageService.hint("网络错误，请切换网络重试");
                    }

                })
                return
            }
            uploadVerify(vm.platformSetting, CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                if($scope.agentIos){
                    $rootScope.$broadcast('clickAndroidUpload',true);
                }else {
                    $timeout(function () {
                        $("#uploadImageMutipleId").trigger("click");
                    })
                }
            },function(error){
                if(error === "networkError"){
                    MessageService.hint("网络错误，请切换网络重试");
                }

            })
        }

        $scope.noPic = true;
        // var select = true;
        vm.aid = $stateParams.aid;
        vm.vid = '';
        vm.pid = '';
        $scope.dHeight = document.documentElement.clientHeight;
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        if ($stateParams.pid) {
            vm.pid = $stateParams.pid;
            vm.vid = $stateParams.vid;
        }
        $scope.gallaryPic = [];         //个人图片库选择
        $scope.counts = localStorage.getItem('maskCounts');

        //个人图片库单张选中当前图片
        $scope.selectThis = function (gallery, photo, index) {
            console.log('personal gallery');
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            gallery.select_photo = !gallery.select_photo;
            for (var i = 0; i < photo.galleries.length; i++) {
                if (!photo.galleries[i].select_photo) {
                    photo.select_photoAll = false;
                    break;
                } else if (i == photo.galleries.length - 1) {
                    photo.select_photoAll = true;
                }
            }
            var t = 0;
            angular.forEach($scope.photos, function (p) {
                angular.forEach(p.galleries, function (g) {
                    if (g.select_photo) {
                        t++;
                        $scope.gallaryPic.push(g);       //选择导入图片的数组
                        $scope.moveGalleryIds.push(g.id);
                    }
                })
            });
            if(vm.galleryPage.length === $scope.gallaryPic.length){
                $scope.selectAllGallery = true;
            }else{
                $scope.selectAllGallery = false;
            }
            $scope.select_photo = t ? true : false;         //确认按钮的显示
            $scope.delBar = t ? true : false; //删除栏的显示
        };

        //个人图片库全选   这里也不一定就会有图片  说不定就是现场上传图片
        $scope.selectedPhoto = function (index, photo) {
            console.log('personal gallery');
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            if ($scope.photos == '' || $scope.photos == null) {
                return;
            } else {
                if (photo.select_photoAll == null) {
                    photo.select_photoAll = true;
                } else {
                    photo.select_photoAll = !photo.select_photoAll;
                }
                $scope.delBar = true;
                angular.forEach(photo.galleries, function (g, i) {
                    g.select_photo = photo.select_photoAll;
                });

                if (photo.select_photoAll) {
                    $scope.select_photoAll = true;
                } else {
                    $scope.select_photoAll = false;
                }

                var t = 0;
                angular.forEach($scope.photos, function (p, i) {
                    angular.forEach(p.galleries, function (g, j) {
                        if (g.select_photo == true) {
                            $scope.gallaryPic.push(g);      //选择导入图片的数组
                            $scope.moveGalleryIds.push(g.id);
                        }
                    });
                    if (!p.select_photoAll) {
                        t++;
                    }
                    if (i == $scope.photos.length - 1) {
                        if (t == $scope.photos.length && $scope.gallaryPic.length == 0) {
                            $scope.delBar = false;
                        }
                    }
                });

            }
            if(vm.galleryPage.length === $scope.gallaryPic.length){
                $scope.selectAllGallery = true;
            }else{
                $scope.selectAllGallery = false;
            }
        };

        //显示图片选择图标以及删除栏
        $scope.showDeleteBtn = function () {
            $scope.selectAllGallery = !$scope.selectAllGallery;
            $scope.delBar = $scope.selectAllGallery;
            isShowSelectDot($scope.selectAllGallery);
        };

        function isShowSelectDot(flag) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            angular.forEach($scope.photos, function (p) {
                p.select_photoAll = flag;
                angular.forEach(p.galleries, function (g) {
                    g.select_photo = flag;
                    if (flag) {
                        $scope.gallaryPic.push(g);       //选择导入图片的数组
                        $scope.moveGalleryIds.push(g.id);
                    } else {
                        $scope.gallaryPic = [];
                        $scope.moveGalleryIds = [];
                    }
                });
            });
        }

        //删除图库图片（数据库）
        $scope.deleteThis = function () {
            var originalNav = $scope.originalNav;
            var del_arr = [];
            angular.forEach($scope.photos, function (photo, index) {
                angular.forEach(photo.galleries, function (gallery, i) {
                    if (gallery.select_photo) {
                        del_arr.push(gallery.id)
                    }
                })
            });
            if (!del_arr.length) {
                swal({
                    title: "操作失败",
                    text: "请至少选择一张图片",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                return
            }
            addAlertClass()
            swal({
                    title: "确认要删除图片么？",
                    text: "",
                    // imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    closeOnConfirm: false
                },
                function (isConfirm) {
                    $scope.selecting=false
                    if (isConfirm) {
                        swal.close();
                        Gallery.batchDelete(del_arr, function () {
                            $scope.loadingAll = true;

                            setTimeout(function () {
                                $scope.loadingAll = false;
                                $scope.$digest();
                                loadFolders()
                            },1500)
                            updatePic();
                            removeAlertClass()
                            getByCustomer();
                            $scope.originalNav = originalNav;
                        }, function () {
                            removeAlertClass()
                            swal({
                                title: "删除失败",
                                text: "",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        });
                    } else {
                        console.log('取消');
                    }
                });
        };

        $scope.isIphoneX = Iphone;
        //定制商城和公众号 非iPhoneX：56px
        //口令小程序 非iPhoneX：0
        //口令小程序 iPhoneX：30px
        // target=modernPrint' //定制商城
        // target=commandMini' //口令小程序
        $scope.checkoutBottom = function(){
            if(vm.target=='commandMini'){
                return '0'
            }else if(vm.target=='modernPrint'&&$scope.isIphoneX&&vm.type=='Mini'){
                return '86px'
            }else{
                return '54px'
            }
        }
        var windowH = $(window).height();
        var h = localStorage.getItem('windowMinH');
        if (!h) {
            localStorage.setItem('windowMinH', windowH);
            h = windowH;
        }

        $(window).scroll(function () {
            if ($scope.isIphoneX) {
                setTimeout(function () {
                    var wh = $(window).height(); //页面可视化区域高度
                    if (wh > h&&vm.type=='Wechat') {
                        $(".iphoneX-content").height(36);
                        // $(".footer-del").height(96);loadFolder
                    } else {
                        $(".iphoneX-content").height(0);
                        // $(".footer-del").height(60);
                    }
                },400);

            }

            //    向下滑动加载照片
            var scrollTop = $(window).scrollTop();
            var widH = $(window).height();
            var docH = $(document).height();
            if (scrollTop + widH + 50 >= docH) {
                if (flag == true) {
                    loadMore();
                } else {
                    return;
                }
            } else {
                return;
            }
        })

        function loadFolders() {
            console.log('请求文件夹数据');
            Folder.getAllByConditionAndQuantity({
                type:"Gallery"
            }, function (resp) {
                $scope.Folders = resp.message;
            })
        }
        vm.isAddFolder = true;
        // 增加文件夹
        $scope.addFolder = function () {

            addAlertClass();
            $(".folder-footer").hide();
            document.body.scrollTop=document.documentElement.scrollTop=0;
            swal({
                title: "新建照片相册",
                text: "",
                type: "input",
                customClass: "sweetAlert3" ,
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                animation: "slide-from-top",
                inputPlaceholder: "请输入相册名称"
            }, function (inputValue) {
                if (inputValue === false) {
                    $(".folder-footer").show();
                    removeAlertClass();
                    return false;
                }
                if (inputValue === "") {
                    swal.showInputError("请输入名称!");
                    return false
                }
                if (inputValue.indexOf(" ") != -1) {
                    swal.showInputError("请不要输入空格!");
                    return false
                }
                if (!vm.isAddFolder) {
                    return
                }
                vm.isAddFolder = false;
                CheckContent(FontManagement, inputValue, function (res) {
                    if((res.status==200||res.status==201)&& res.message){
                        Folder.save({
                            name: inputValue,
                            type: 'Gallery'
                        }, function (resp) {
                            $scope.Folders.unshift(resp);
                            $(".folder-footer").show();
                            swal.close();
                            removeAlertClass();
                            $scope.Folders = [];
                            loadFolders();
                            vm.isAddFolder = true;
                        },function () {
                            document.body.scrollTop=document.documentElement.scrollTop=0;
                            swal.showInputError("请输入正确的文件名!");
                        })
                    }else{
                        document.body.scrollTop=document.documentElement.scrollTop=0;
                        swal.showInputError("存在敏感字符");
                    }
                })
            });
        }
        function addAlertClass() {
            setTimeout(function () {
                $(".sweet-alert").addClass('folder-alert').addClass('whiteBg');
                // $(".sa-button-container").addClass('folder-alert-top')
                // $(".sa-button-container .cancel").addClass('folder-alert-right')
            })
        }
        function removeAlertClass() {
            setTimeout(function () {
                $(".sweet-alert").removeClass('folder-alert').removeClass('whiteBg');
                $(".sa-button-container").removeClass('folder-alert-top')
                $(".sa-button-container .cancel").removeClass('folder-alert-right')
            },200)
        }


        $scope.showChooseIcon = function(){
            vm.showIcon = !vm.showIcon;
            console.log(vm.showIcon);
            // if(vm.showIcon){
            //     $("#folder").addClass('top-menu2-head-active');
            // }else{
            //     $("#folder").removeClass('top-menu2-head-active');
            // }
        }

        // 修改文件夹名
        $scope.editFolder = function () {
            addAlertClass();
            var len = $scope.folderIds.length;
            if (len > 1 || !len) {
                return
            }
            var folder = $scope.editFolderNameArr;
            $(".folder-footer").hide();
            $(".folder-footer").hide();
            document.body.scrollTop=document.documentElement.scrollTop=0;
            swal({
                title: "重命名",
                text: "",
                customClass: "sweetAlert3" ,
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                animation: "slide-from-top",
                inputPlaceholder: "请输入相册名称",
                inputValue: folder.name
            }, function (inputValue) {
                if (inputValue === false) {
                    $(".folder-footer").show();
                    removeAlertClass();
                    return false;
                }
                if (inputValue === "") {
                    swal.showInputError("请输入名称!");
                    return false
                }
                if (inputValue.indexOf(" ") != -1) {
                    swal.showInputError("请不要输入空格!");
                    return false
                }
                if (inputValue === folder.name) {
                    swal.showInputError("请输入不同的相册名!");
                    return false
                }

                CheckContent(FontManagement, inputValue, function (res) {
                    if((res.status==200||res.status==201)&&res.message){
                        Folder.update({
                            name: inputValue,
                            type: 'Gallery',
                            id: folder.id
                        }, function (resp) {
                            cancelSelectFolders();
                            folder.name = resp.name;
                            $(".folder-footer").show();
                            swal.close();
                            removeAlertClass();
                            document.body.scrollTop=document.documentElement.scrollTop=0;
                            $scope.folderDialogClose()
                        },function () {
                            document.body.scrollTop=document.documentElement.scrollTop=0;
                            swal.showInputError("请输入正确的文件名!");
                        })
                    }else{
                        document.body.scrollTop=document.documentElement.scrollTop=0;
                        swal.showInputError("存在敏感字符");
                    }
                })
            });
        }

        /**
         * 单选
         * @param item
         * @param flag-----   true：文件夹     false:作品
         * @param y
         * @param d
         * @param i
         */
        $scope.selectSingle = function (item, flag, y, d, i) {
            if (!item) {
                $scope.selectOne = {
                    folderId: '',
                    selected: true
                };
                angular.forEach($scope.Folders, function (folder, index) {
                    folder.selected = false;
                });
                return
            }
            item.selected = !item.selected;
            // 移动状态中
            if ($scope.moveGallery) {

                $scope.selectOne = {
                    folderId: item.id,
                    selected: item.selected
                };

                angular.forEach($scope.Folders, function (folder, index) {
                    folder.selected = false;
                });
                item.selected = $scope.selectOne.selected;
                if(item.selected){
                    $scope.folderOne = angular.copy(item);
                }

                return
            }

            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            //未选中时
            if (!item.selected) {
                // 文件夹
                if (flag) {
                    $scope.selectAllFolder = false;
                    var ind = $scope.folderIds.indexOf(item.id);
                    if (ind >= 0) {
                        $scope.folderIds.splice(ind, 1);
                    }
                } else {
                    $scope.selectAllAritle = false;
                    var ind = $scope.moveGalleryIds.indexOf(item.id);
                    if (ind >= 0) {
                        $scope.moveGalleryIds.splice(ind, 1);
                    }
                }
            } else {
                if (flag) {
                    $scope.folderIds.push(item.id);

                }else{
                    // 选中状态时的作品列表
                    // var arr = vm.yearDateList[y].data[d].items[i];
                    // $scope.moveGalleryIds.push(arr.id);
                }
            }
        }

        $scope.selectFolders = function (item) {
            item.selected = !item.selected;

            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            //未选中时
            if (!item.selected) {
                $scope.selectAllFolder = false;
                var ind = $scope.folderIds.indexOf(item.id);
                if (ind >= 0) {
                    $scope.folderIds.splice(ind, 1);
                }

                $scope.editFolderNameArr = [];

            } else {
                $scope.folderIds.push(item.id);
                $scope.editFolderNameArr = item;
            }
        }

        function cancelSelectFolders() {
            $scope.folderIds = [];
            angular.forEach($scope.Folders, function (item) {
                item.selected = false;
            });
        }

        /**
         * 全选
         * @param flag-----  true：文件夹     false:作品
         */
        $scope.chooseAll = function (flag) {
            if (flag) {
                $scope.editFolderNameArr = [];
                $scope.selectAllFolder = !$scope.selectAllFolder;
                angular.forEach($scope.Folders, function (item, index) {
                    if ($scope.selectAllFolder) {
                        item.selected = true;
                        $scope.folderIds.push(item.id);
                    } else {
                        item.selected = false;
                        $scope.folderIds = [];
                    }
                });
            } else {
                $scope.selectAllGallery = !$scope.selectAllGallery;
                angular.forEach($scope.photos, function (photo) {
                    angular.forEach(photo.galleries, function (item) {
                        if ($scope.selectAllGallery) {
                            item.select_photo = true;
                            photo.select_photoAll = true
                        } else {
                            item.select_photo = false;
                            photo.select_photoAll = false
                        }
                    })
                })
            }

        }

        $scope.chooseAll2 = function () {
            $scope.editFolderNameArr = [];
            $scope.selectAllFolder = !$scope.selectAllFolder;
            angular.forEach($scope.shareFolders, function (item, index) {
                if ($scope.selectAllFolder) {
                    item.selected = true;
                    $scope.folderIds.push(item.id);
                } else {
                    item.selected = false;
                    $scope.folderIds = [];
                }
            });
        }

        $scope.saveMove = function () {
            if (!$scope.selectOne.selected) {
                swal({
                    title: "操作失败",
                    text: "请选择一个相册",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                return
            }
            angular.forEach($scope.Folders, function (folder, index) {
                folder.selected = false;
            });
            Gallery.moveFolder({
                galleries: $scope.moveGalleryIds,
                folderId: $scope.selectOne.folderId
            }, function (resp) {
                $(".photo-show").hide();
                $scope.currentNav = 'gallery';
                vm.originalNav = 'gallery';
                $scope.originalNav = vm.originalNav;
                $scope.gallaryPic = [];
                $scope.moveGallery = false;
                $scope.delBar = false;
                $scope.moveGalleryIds = [];
                if ($scope.selectOne.folderId) {
                    $scope.folderInside = true;
                    vm.indexNum = 0;
                    vm.maxId = null;
                    vm.galleryPage = [];
                    loadAll($scope.folderOne.id);
                } else {
                    $scope.folderInside = false;
                    vm.galleryPage = [];
                    vm.indexNum = 0;
                    vm.maxId = null;
                    loadAll('isNull');
                }
                loadFolders();
                $scope.selectOne = {
                    folderId: '',
                    selected: false
                };
            })
        }

        $scope.backTo = function () {
            $scope.currentNav = 'gallery';
            $scope.moveGallery = false;
            $scope.delBar = false;
            $scope.selectOne = {
                folderId: '',
                selected: false
            };
            angular.forEach($scope.Folders, function (folder) {
                folder.selected = false;
            });
            clearGalleryAdd();
        }

        //删除多个文件夹
        $scope.deleteFolders = function () {
            if (!$scope.folderIds.length) {
                swal({
                    title: "操作失败",
                    text: "请至少选择一个相册",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 1500,
                    showConfirmButton: false
                });
                return
            }
            addAlertClass()
            swal({
                    title: "确认要删除相册么？",
                    text: "",
                    // imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    closeOnConfirm: false
                },
                function (isConfirm) {
                    if (isConfirm) {
                        swal.close();
                        Folder.batchDelete({ids: $scope.folderIds}, function () {
                            $scope.loadingAll = true;
                            setTimeout(function () {
                                $scope.loadingAll = false;
                                $scope.$digest();
                            },1500)
                            loadFolders();
                            cancelSelectFolders();
                            removeAlertClass();
                            $scope.folderDialogClose()
                        }, function () {
                            removeAlertClass()
                            swal({
                                title: "删除失败",
                                text: "",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        });
                    } else {
                        console.log('取消');
                    }
                });
        };

        $scope.moveToFolder = function () {
            if (!$scope.gallaryPic.length) {
                swal({
                    title: "移动失败",
                    text: "请至少选择一张图片",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            $scope.moveGallery = true;
            $scope.folderInside = false;
            $scope.selecting = false
            $scope.currentNav = 'folder';
        }

        function clearGalleryAdd() {
            // $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.selectAllGallery = false;
            angular.forEach($scope.photos, function (photo) {
                photo.select_photoAll = false
                angular.forEach(photo.galleries, function (item) {
                    item.select_photo = false;
                })
            })
        }
        // 进入某一文件夹中
        $scope.showFolderContent = function (folder) {
            if ($scope.selecting) {
                $scope.selectFolders(folder)
                return
            }
            if (folder.selected) {
                folder.selected = false;
                $scope.selectAllGallery = false;
                $scope.selectOne = {
                    folderId: '',
                    selected: false
                };

                $scope.selectAllFolder = false;
                var ind = $scope.folderIds.indexOf(folder.id);
                if (ind >= 0) {
                    $scope.folderIds.splice(ind, 1);
                }
                $scope.editFolderNameArr = [];
                return
            }
            if ($scope.currentNav == 'sharePhotos') {
                Folder.get({id:folder.id},function (res){
                    console.log(res);
                    vm.albumOwnerId = res.members[0].userId;
                    websocketByOwnerId(vm.albumOwnerId);
                    $scope.memberDelete = res.memberDelete;
                    $scope.memberUpload = res.memberUpload;
                })
            }
            if ($scope.currentNav == 'folder' ) {
                $scope.memberDelete = true;
                $scope.memberUpload = true;
            }
            $scope.folderInside = true;
            $scope.originalNav = $scope.currentNav;
            $scope.currentNav = 'gallery';
            $scope.folderOne = angular.copy(folder);
            vm.indexNum = 0;
            vm.maxId = null;
            vm.galleryPage = [];
            $scope.folderId = folder.id;
            $(".photo-show").hide();
            loadFolders()
            loadAll(folder.id);
            if (vm.type=='Mini') {
                window.location.href = removeURLParameter(window.location.href,'folderId') + '&folderId=' + folder.id ;
            }
            if(vm.type=='Wechat'){
                shareGallery()
            }
        }

        function removeURLParameter(url, parameter) {
            var urlparts = url.split('?');
            if(urlparts.length >= 2) {
                //参数名前缀
                var prefix = encodeURIComponent(parameter) + '=';
                var pars = urlparts[1].split(/[&;]/g);

                //循环查找匹配参数
                for(var i = pars.length; i-- > 0;) {
                    if(pars[i].lastIndexOf(prefix, 0) !== -1) {
                        //存在则删除
                        pars.splice(i, 1);
                    }
                }

                return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
            }
            return url;
        }

        // 记录进文件夹之前的tab
        $scope.originalNav = '';
        $scope.memberDelete = true;
        $scope.memberUpload = true;
        // 从某一文件夹出去
        $scope.hideFolderContent = function () {
            vm.albumOwnerId = null;
            $scope.folderInside = false;
            $scope.moveGallery = false;
            $scope.currentNav = $scope.originalNav;
            // 矫正进预览后退出的位置
            if (vm.originalNav) {
                $scope.currentNav = vm.originalNav;
                $scope.originalNav = vm.originalNav;
            }
            $scope.photos = []
            $scope.folderOne = {};
            if(vm.originalNav == 'folder'){
                loadFolders();
            }else if(vm.originalNav == 'gallery'){
                vm.galleryPage = [];
                loadAll('isNull');
            }else{
                loadShareFolders();
            }

            // 共享相册关闭链接
            if (ownerWs) {
                ownerWs.close();
                ownerWs = null;
            }
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.selecting = false;
            $scope.selectAllGallery = $scope.delBar = false;

            wx.hideOptionMenu();
        }
        // 图片库nav 点击
        $scope.galleryNavSel = function () {
            vm.galleryPage = [];
            $(".photo-show").hide();
            $scope.currentNav = 'gallery';
            $(".folder-editbar").addClass('hide1');
            $(".arrow-btn").removeClass('folder-arrow-up');
            angular.forEach($scope.Folders, function (folder, index) {
                folder.selected = false;
            });
            $scope.folderId = null;
            $scope.folderIds = [];
            $scope.folderClick = false;
            vm.showIcon = false;
            vm.indexNum = 0;
            vm.maxId = null;
            loadAll('isNull');
            getByCustomer();
            $scope.selecting=false;
            vm.originalNav = 'gallery';
            $scope.originalNav = vm.originalNav;
        }

        // 图片库nav 点击
        $scope.folderNavSel = function () {
            vm.galleryPage = [];
            $scope.currentNav = 'folder';
            vm.originalNav = 'folder';
            $scope.originalNav = vm.originalNav;
            $scope.selecting=false
            $scope.selectAllGallery = $scope.delBar = false;
            $scope.selectAllFolder = false;
        }

        // 共享相册nav 点击
        $scope.sharePhotosNavSel = function(folderId){
            vm.galleryPage = [];
            $scope.currentNav = 'sharePhotos';
            vm.originalNav = 'sharePhotos';
            $scope.originalNav = vm.originalNav;

            $scope.selecting=false
            $scope.selectAllGallery = $scope.delBar = false;
            loadShareFolders(folderId)
            $scope.selectAllFolder = false;
        }

        if (getQueryVariable('folderId')) {
            $scope.sharePhotosNavSel(getQueryVariable('folderId'));
        }

        function loadShareFolders(folderId){
            Folder.getAllByConditionAndQuantity({
                type:"SharedGallery"
            }, function (resp) {
                $scope.shareFolders = resp.message;
                // 跳转进来直接进入相册内
                if (folderId) {
                    $scope.shareFolders.forEach(function(folder){
                        if (folder.id == folderId) {
                            $scope.showFolderContent(folder);
                        }
                    })
                }
            })
        }


        $scope.chooseStyle = function (nav) {
            if(vm.type=='Mini'){
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/'+nav.pathSmallApp})
                }else{
                    uni.redirectTo({url: '/'+nav.pathSmallApp})
                }
            }else{
                window.location.href = nav.pathWechat;
            }
        }



        //风切切换路由判断
        $scope.homeStyle = function(){
            if(vm.type=='Mini'){
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/pages/home/startPage/startPage'})
                    return
                }
                uni.redirectTo({url: '/pages/home/startPage/startPage'});
                return
            }
            if(localStorage.getItem('themeFlag') == 'two'){
                window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/2';
            }else if(localStorage.getItem('themeFlag') == 'three'){
                window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/3';
            }else{
                window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId;
            }
        };

        $scope.categoryStyle = function () {
            if(vm.type=='Mini'){
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/pages/category/categoryListPage/categoryListPage'})
                    return
                }
                uni.redirectTo({url: '/pages/category/categoryListPage/categoryListPage'});
                return
            }
            // 切换时隐藏底部栏
            $(".iphoneX-content").height(0);
            localStorage.setItem('categoryIndex', 0);
            if (localStorage.getItem('themeFlag') == 'two') {
                window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/categoryTwo';
            } else if (localStorage.getItem('themeFlag') == 'three') {
                window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/categoryThree';
            } else {
                window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/category';
            }
            // $state.go('categoryTwo',{storeId:vm.storeId})
        };

        $scope.customerStyle = function () {
            if(localStorage.getItem('distinguish') == 'jdApp'){
                jd.miniProgram.redirectTo({url: '/pages/information/myInformation/myInformation'})
            }else{
                if(vm.type=='Mini'){
                    uni.webView.redirectTo({url: '/pages/information/myInformation/myInformation'});
                    return
                }
                $(".iphoneX-content").height(0);
                window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/customer';
                // if (localStorage.getItem('themeFlag') == 'two' || localStorage.getItem('themeFlag') == 'three') {
                //     window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/customerTwo';
                // } else {
                //     window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/customer';
                // }
            }
        };
        $scope.gotoGallery = function () {
            $state.go('gallery',{storeId:storeId})
        }

        $scope.goGalleryCapacity = function(){
            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/pages/information/galleryCapacity/galleryCapacity'})
                    return
                }
                uni.redirectTo({
                    url: '/pages/information/galleryCapacity/galleryCapacity'
                })
            } else {
                window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/galleryCapacity';
            }
        }
        $scope.redeemStyle = function () {
            if(vm.type=='Mini'){
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/pages/portfolio/myCard/myCard'})
                    return
                }
                uni.redirectTo({url: '/pages/portfolio/myCard/myCard'});
                return
            }
            window.location.href = vm.platformWechatSetting.storeNotifyUrl+'/#/'+storeId+'/redeem-code';
        };

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        // 上传图片后更新图片库/文件夹
        function updatePic() {
            if ($scope.folderOne && $scope.folderOne.id) {
                $scope.folderOne.selected = false;
                $scope.showFolderContent($scope.folderOne);
                $scope.originalNav = vm.originalNav;
            } else {
                $scope.folderInside = false;
                $scope.currentNav = 'gallery';
                $scope.moveGallery = false;
                $scope.gallaryPic = [];
                $scope.moveGalleryIds = [];
                vm.indexNum = 0;
                vm.galleryPage = [];
                vm.totalCount = null;
                vm.maxId = null;
                loadAll('isNull');
            }
        }
        $scope.selectCut=function(){
            $scope.selecting=!$scope.selecting
        }


        vm.share = false;
        $scope.addPeople = function(){
            vm.share = true;
        }
        // 进预览前记录的历史位置
        vm.originalNav='';
        $scope.goToPreview = function(gallery, photo, outerIndex){
            if ($scope.selecting) {
                $scope.selectThis(gallery, photo, outerIndex);
                return
            }
            if ($scope.folderInside) {
                // 文件夹内点击
                Gallery.getPage({
                    items: [{key: "folderId", op: "=", value: $scope.folderId}],
                    size: 0,
                    sort: ["id,desc"]
                }, function(data){
                    angular.forEach(data, function (item,index) {
                        if (item.id == gallery.id) {
                            vm.preview.initialSlide = index
                        }
                        vm.preview.previewList.push(item);
                    });
                    $scope.preview = true;
                    vm.originalNav = $scope.originalNav;
                    console.log($scope.originalNav,'进来之前的位置');
                    console.log($scope.currentNav,'当前的位置');
                    CommonService.setDocumentTitle("图片预览");
                },function(error){

                });

            }else{
                // 图库点击
                Gallery.getPage({
                    items: [{key: "folderId", op: "isNull", value: ''}],
                    size: 0,
                    sort: ["id,desc"]
                }, function(data){
                    angular.forEach(data, function (item,index) {
                        if (item.id == gallery.id) {
                            vm.preview.initialSlide = index
                        }
                        vm.preview.previewList.push(item);
                        vm.originalNav = 'gallery';
                        $scope.originalNav = vm.originalNav;
                    });
                    $scope.preview = true;
                    CommonService.setDocumentTitle("图片预览");
                },function(error){

                });

            }
        }
        $scope.quitPreview = function(){
            vm.preview.previewList = [];
            vm.preview.initialSlide = 0;
            $scope.preview = false;
            $scope.originalNav = vm.originalNav;
            updatePic();
            CommonService.setDocumentTitle("图库");
        }
        $scope.$on('to-gallery', function(event,data) {
            if(data == 'settingFolderHide'){
                $scope.settingFolder= false;
                loadFolders();
            }
            if (data == 'infoFolderHide') {
                $scope.infoFolder = false;
                loadShareFolders();
            }
        });
        $scope.infoFolder = false;
        $scope.hideInfoFolder = function(){
            $scope.infoFolder = false;
        }
        $scope.details = function(){
            $scope.settingFolderId = $scope.folderIds[0];
            $scope.shareFolderDialogClose()
            $scope.infoFolder = true;
        }

        // 公众号分享功能
        function shareGallery(){
            if (wx) {
                var url = window.location.hash && window.location.href.slice(0, -window.location.hash.length) || window.location.href;
                WxJS.get2({
                    url: url, storeId: vm.storeId
                }, function (config) {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: config.appId, // 必填，公众号的唯一标识
                        timestamp: config.timestamp, // 必填，生成签名的时间戳
                        nonceStr: config.nonceStr, // 必填，生成签名的随机串
                        signature: config.signature,// 必填，签名，见附录1
                        jsApiList: [
                            "checkJsApi",
                            "onMenuShareTimeline",
                            "onMenuShareAppMessage",
                            'showOptionMenu'
                        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                    });

                    // 异步处理，方法堆放一起
                    wx.ready(function () {
                        wx.showOptionMenu();
                        wx.checkJsApi({
                            jsApiList: [
                                "checkJsApi",
                                "onMenuShareTimeline",
                                "onMenuShareAppMessage"
                            ],
                            success: function (res) {
                                wx.onMenuShareTimeline({
                                    title: '发现一个有趣的小程序分享给你', // 分享标题
                                    link: vm.platformWechatSetting.storeNotifyUrl+'/#/'+vm.storeId+'/shareGallery?folderId=' + $scope.folderId, // 分享链接
                                    imgUrl: 'https://pic.momentsgo.com/Fl5idY3RzcRe8hCMoKgqb7h5fivM',
                                    success: function (res) {
                                        console.log("分享成功");
                                    },
                                    cancel: function (res) {
                                        console.log("分享取消");
                                    },
                                    fail: function (res) {
                                        console.log(res);
                                    }
                                });
                                //朋友
                                wx.onMenuShareAppMessage({
                                    title: '发现一个有趣的小程序分享给你', // 分享标题
                                    link: vm.platformWechatSetting.storeNotifyUrl+'/#/'+vm.storeId+'/shareGallery?folderId=' + $scope.folderId, // 分享链接
                                    imgUrl: 'https://pic.momentsgo.com/Fl5idY3RzcRe8hCMoKgqb7h5fivM',
                                    type: 'link', // 分享类型,music、video或link，不填默认为link
                                    success: function () {
                                        console.log("分享成功");
                                    },
                                    cancel: function () {
                                        console.log("分享取消");
                                    }
                                });
                            }
                        });
                    });

                    wx.error(function (res) {
                        console.log(res)
                    });
                }, function () {
                    console.log("wx config error");
                });
            }
        }
        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("ws://localhost:8096/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == $scope.folderId) {
                    vm.totalCount++;
                    vm.galleryPage.unshift(photo);
                    getPagePhoto();
                    $scope.noPic = false;
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }

        // 共享相册监听消息
        var ownerWs = null;
        function websocketByOwnerId(ownerId){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            ownerWs = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery" + ownerId);

            ownerWs.onOpen(function() {
                console.log('共享相册的WebSocket连接已打开, url:' + ownerWs.url);
            });

            ownerWs.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == $scope.folderId) {
                    vm.totalCount++;
                    vm.galleryPage.unshift(photo);
                    getPagePhoto();
                    $scope.noPic = false;
                }
            });

            ownerWs.onError(function(error) {
                console.log('共享相册的WebSocket错误：', error);
            });

            ownerWs.onClose(function() {
                console.log('共享相册的WebSocket连接已关闭');
            });
        }
    }
})();
