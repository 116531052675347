(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('WebGalleryController', WebGalleryController);

    WebGalleryController.$inject = ['$state', '$q', 'UidService', 'Gallery', '$scope', '$location', 'paginationConstants', 'pagingParams', '$stateParams', '$localStorage', 'Qinius',
         'Folder', '$uibModal', 'MessageService', 'Theme', 'SweetAlert', 'PlatformWechatSetting', '$interval', 'DateUtils', 'ListService',
        'MessageNotification', 'Convert', 'ImageService', 'CustomerStorages', '$timeout', 'CustomerUploadAble','Customer','$websocket'
    ];

    function WebGalleryController($state, $q, UidService, Gallery, $scope, $location, paginationConstants, pagingParams, $stateParams, $localStorage, Qinius,
         Folder, $uibModal, MessageService, Theme, SweetAlert, PlatformWechatSetting, $interval, DateUtils, ListService,
         MessageNotification, Convert, ImageService, CustomerStorages, $timeout, CustomerUploadAble,Customer, $websocket
         ) {

        var vm = this;
        // vm.search = search;

        // 是否点击移动按钮
        $scope.showAllFolder = false;
        $scope.folderIds = [];
        vm.galleries = [];
        vm.dataGalleries = [];
        $scope.folderManager = false;
        $scope.galleryManager = false;
        vm.uploading = false;
        $scope.moveGalleryIds = [];
        vm.messageShow = true;
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        if ($localStorage.user !=null) {
            vm.userId = $localStorage.user.id
        }
        // vm.loadPage = loadPage;
        // vm.predicate = pagingParams.predicate;
        // vm.reverse = pagingParams.ascending;
        // vm.transition = transition;
        // vm.jump = jump;
        vm.maxId = null;
        vm.itemsPerPage = 40;
        vm.page = 0;
        var indd = 0;
        var len = 0;
        var errorImageArr = [];
        var repeatCount = 0;
        var timer = null;
        vm.platformSetting = PlatformWechatSetting.get();
        vm.tokens = Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"});
        vm.customer = Customer.current();

        vm.store = $localStorage.store;
        $scope.domain = $localStorage.domain;
        $scope.folders = Folder.getAllByConditionAndQuantity({type:"Gallery"});

        $scope.imageEnhance = function(imageUrl){
            Convert.imageEnhance({imageUrl:imageUrl, type:"warm"}, function(data){
            })
        }
        $scope.goBack =function(){
            $state.go("webGallery", {storeId:vm.storeId});
        }

        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                if(values){
                    indd = 0;
                    for (var i = 0; i < values.length; i++) {
                        var value = values[i];
                        var data = {key:value.key,hash:value.hash};
                        serviceUpload(data, value,values.length);
                    }
                }
                bigSizePics=[];
            }, function(){
                vm.uploading = false;
                vm.page = 0;
                vm.maxId = null;
                vm.galleries = [];
                vm.dataGalleries = [];
                loadAll();
                CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                    progressInit(data);
                });
            });
        }

        vm.qrcodeShow = false;
        var qrcode = null;
        $scope.uploadByPhone = function () {
            uploadVerify(null,CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                vm.qrcodeShow = true;
                $uibModal.open({
                    templateUrl: 'app/entities/web-gallery/uploadByPhone.html',
                    controller: 'UploadByPhoneController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: {
                            text: vm.platformSetting.storeNotifyUrl + "/#/" + vm.storeId + "/0/gallery/"+ vm.userId
                        },
                    }
                }).result.then(function() {
                    vm.qrcodeShow = false;
                }, function() {

                });
            }, function () {
                $scope.buySize()
            })
        }

        $scope.closeQrCode = function(){
            $interval.cancel(timer);
            vm.qrcodeShow = false;
        }

        $scope.progressColor = function (size) {
            return progressColor(size)
        }

        vm.customerStorage =  CustomerStorages.getByCustomer({customerId: vm.userId});


        function progressInit(item){
            vm.sumSize =  Number(item.initSize) + Number(item.buySize);
            vm.useSize = Number(item.usedSize);
            vm.percentage = 0;
            $timeout(function () {
                vm.percentage = vm.useSize / vm.sumSize;
            });
        };

        $q.all([vm.customerStorage.$promise, $scope.folders.$promise, vm.platformSetting.$promise, vm.tokens.$promise]).then(function() {
            vm.token = vm.tokens.token;
            progressInit(vm.customerStorage);
            loadAll();
            $scope.folders = $scope.folders.message;
            //消息列表
            vm.messageList = "";
            MessageNotification.getAllByCondition({
                    items:[
                        {key:"pageType", op : "=", value:"Gallery"},
                        {key:"storeId", op : "=", value:vm.storeId},
                        {key:"available", op : "=", value:"true"},
                        {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                }, function (res) {
                    res.forEach(function (item, index) {
                        vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                    })
                }
            )
            websocket();
        });

        $scope.managerFolder = function() {
            $scope.folderManager = true;
            $scope.cancelGalleryManager();
        }

        $scope.managerGallery = function() {
            $scope.galleryManager = true;
            $scope.cancelManagerFolder();
        }

        $scope.cancelManagerFolder = function() {
            $scope.folderIds = [];
            $scope.folderManager = false;
            vm.folderAll = false;
            for (var i = $scope.folders.length - 1; i >= 0; i--) {
                var f = $scope.folders[i];
                if (f.selected) {
                    f.selected = false;
                }
            }
        }

        $scope.cancelGalleryManager = function() {
            $scope.moveGalleryIds = [];
            $scope.galleryManager = false;
            $scope.selectAllGallery = false;
            for (var i = vm.galleriesAll.length - 1; i >= 0; i--) {
                var a = vm.galleriesAll[i];
                if (a.selected) {
                    a.selected = false;
                }
            }
        }

        $scope.goFolder = function(f) {
            if ($scope.folderManager) {
                $scope.folderSelected(f)
                return;
            }
            $state.go("webGalleryFolder", {
                storeId: vm.storeId,
                folderId: f.id
            });
        }

        $scope.upDown = function() {
            $scope.showAllFolder = !$scope.showAllFolder;
        }


        $scope.editName = function(gallery) {
            if ($scope.folderManager) {
                return;
            }
            createModal('app/entities/web-gallery/galery-dialog.html', 'GalleryDialogController', gallery.id, "").result.then(
                function() {
                    vm.page = 0;
                    vm.maxId = null;
                    vm.galleries = [];
                    loadAll()
                });
        }

        $scope.storageDel = function() {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                    }
                });
            };
            createModal2('app/entities/web-gallery/storage-del.html', 'StorageDelController').result.then(function() {

            });
        }

        $scope.buySize = function() {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                    }
                });
            };
            createModal2('app/entities/web-gallery/storage-buy.html', 'StorageBuyController').result.then(function() {
                CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                    progressInit(data);
                });
            });
        }

        $scope.addFolder = function(id,event) {
            if (event) {
                event.stopPropagation()
            }
            if ($scope.folderManager) {
                return;
            }
            createModal('app/entities/web-folder/folder-dialog.html', 'FolderDialogController', id, "Gallery").result.then(
                function() {
                    Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
                        $scope.folders = res.message;
                    });
                });
        }


        var createModal = function(url, controller, id, type) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type,
                        id: id
                    },
                }
            });
        };

        $scope.moveToFolder = function() {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error("移动失败,请至少选择一个照片");
                return
            }
            createModal2('app/entities/web-folder/entity-move-folder.html', 'EntityMoveFolderController', $scope.moveGalleryIds, "Gallery",null).result.then(
                function() {
                    vm.page = 0;
                    vm.maxId = null;
                    vm.galleries = [];
                    loadAll()
                    Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
                        $scope.folders = res.message;
                        $state.reload();
                    });
                });
        }
        $scope.moveToFolderOne = function(f,event) {
            event.stopPropagation();
            var ids=[];
            ids.push(f.id);
            createModal2('app/entities/web-folder/entity-move-folder.html', 'EntityMoveFolderController', ids, "Gallery",null).result.then(
                function() {
                    vm.page = 0;
                    vm.maxId = null;
                    vm.galleries = [];
                    loadAll()
                    Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
                        $scope.folders = res.message;
                        $state.reload();
                    });
                });
        }


        var createModal2 = function(url, controller, ids, type,folderId) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type,
                        ids: ids,
                        folderId:folderId
                    },
                }
            });
        };

        // function search() {
        //     loadAll();
        // }

        $scope.folderSelected = function(folder,$event) {
            if (event) {
                event.stopPropagation()
            }
            if (!$scope.folderManager) {
                return;
            }
            if (folder != null) {
                folder.selected = !folder.selected;
                if (folder.selected) {
                    $scope.folderIds.push(folder.id)
                    if ($scope.folderIds.length == $scope.folders.length) {
                        vm.folderAll = true;
                    }
                }else{
                    $scope.folderIds = $scope.folderIds.filter(function (item) {
                        return folder.id != item
                    })
                    vm.folderAll = false;
                }
            }
        }
        vm.folderAll = false;
        $scope.folderAll = function() {
            if (vm.folderAll) {
                $scope.folders.forEach(function(folder) {
                    folder.selected = false;
                })
                $scope.folderIds = [];
                vm.folderAll = false;
            }else{
                $scope.folderIds = [];
                $scope.folders.forEach(function(folder) {
                    folder.selected = true;
                    $scope.folderIds.push(folder.id)
                })
                vm.folderAll = true;
            }

        }
        $scope.deleteFolder = function() {
            if (!$scope.folderIds.length > 0) {
                MessageService.error("操作失败,请选择文件夹");
                return
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确认',
                msg :'确认要删除文件夹及文件中所有照片么？'
            }, function() {
                Folder.batchDelete({
                    ids: $scope.folderIds
                }, function() {
                    MessageService.success("删除成功");
                    $scope.folderManager = false;
                    $scope.folderIds = [];
                    vm.folderAll = false;
                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                        progressInit(data);
                    });

                    Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
                        $scope.folders = res.message;
                    });
                }, function() {
                    MessageService.error("删除失败");
                })
            });
        }
        $scope.deleteFolderOne = function(f,event) {
            event.stopPropagation()
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确认要删除文件夹及文件中所有照片么？'
            }, function() {
                Folder.batchDelete({
                    ids: f.id
                }, function() {
                    MessageService.success("删除成功");
                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                        progressInit(data);
                    });
                    Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
                        $scope.folders = res.message;
                    });
                }, function() {
                    MessageService.error("删除失败");
                })
            });
        }

        vm.galleriesAll =[];
        $scope.moveGalleryIds = [];
        vm.totalItems = null;
        function loadAll() {
            $scope.selectAllGallery =  false;
            var items = [];
            items.push({
                key: "folderId",
                op: "isNull",
                value: ''
            });
            if (vm.maxId) {
                items.push({
                    key: "id",
                    op: "<=",
                    value: vm.maxId
                });
            }
            Gallery.getPage({
                items: items,
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                return ["id,desc"]
            }
            function onSuccess(data, headers) {
                if (vm.totalItems == null) {
                    vm.totalItems = Number(headers('X-Total-Count'));
                }
                if (data.length==0) {
                    return
                }

                if (!vm.maxId && data.length > 0) {
                    vm.maxId = data[0].id;
                }
                vm.galleries = []
                data.forEach(function(g) {
                    vm.galleriesAll.push(g)
                })
                // vm.dataGalleries = [];
                for (var i = 0; i < data.length; i++) {
                    var g = data[i];

                    g.createdDate = DateUtils.convertLocalDateToServer(g.createdDate);
                    var result = ListService.inList(g, vm.dataGalleries, ["createdDate"] );
                    if (!result) {
                        vm.dataGalleries.push({createdDate:g.createdDate, galleries:[g]});
                    }else{
                        result.galleries.push(g);
                    }
                }
            }

            function onError(error) {}
        }

        // function loadPage(page) {
        //     vm.page = page;
        //     vm.transition();
        // }

        // function transition() {
        //     $state.transitionTo($state.$current, {
        //         page: vm.page,
        //         sort: "createdDate,desc",
        //         storeId: vm.storeId,
        //         search: vm.currentSearch
        //     });
        // }



        $scope.selectSingle = function(gallery,event) {
            event.stopPropagation();
            if(!$scope.galleryManager){
                return
            }
            gallery.selected = !gallery.selected;
            if (gallery.selected) {
                $scope.moveGalleryIds.push(gallery.id);
            } else {
                var inde1 = $scope.moveGalleryIds.indexOf(gallery.id);
                $scope.moveGalleryIds.splice(inde1, 1);
            }
            if ($scope.moveGalleryIds.length == vm.galleries.length) {
                $scope.selectAllGallery = true;
            } else {
                $scope.selectAllGallery = false;
            }
        }


        /**
         * 全选
         * @param flag-----  true：文件夹     false:照片
         */
        $scope.chooseAll = function() {
            $scope.selectAllGallery = !$scope.selectAllGallery;
            $scope.moveGalleryIds = [];
            angular.forEach(vm.galleriesAll, function(gallery, i) {
                if ($scope.selectAllGallery) {
                    gallery.selected = true;
                    $scope.moveGalleryIds.push(gallery.id);
                } else {
                    gallery.selected = false;
                    $scope.moveGalleryIds = [];
                }
            })
        }

        $scope.deleteGalleries = function() {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error("操作失败,请至少选择一个照片");
                return
            }

            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确认要删除选中的照片么？'
            }, function() {
                Gallery.batchDelete($scope.moveGalleryIds, function() {
                    vm.page = 0;
                    vm.maxId = null;
                    vm.galleries = [];
                    loadAll()
                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                        progressInit(data);
                    });
                    MessageService.success("操作成功,您的照片已成功删除")
                    $state.reload();
                }, function() {
                    MessageService.error("操作失败,请稍后操作或者联系管理员")
                })
            });
        }
        $scope.deleteGalleriesOne = function(gallery,event) {
            event.stopPropagation()
            var ids = [];
            ids.push(gallery.id)
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'确认要删除选中的照片么？'
            }, function() {
                Gallery.batchDelete(ids, function() {
                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                        progressInit(data);
                    });
                    $state.reload();
                    MessageService.success("操作成功,您的照片已成功删除")
                }, function() {
                    MessageService.error("操作失败,请稍后操作或者联系管理员")
                })
            });
        }

        $scope.uploadVerify = function(){
            uploadVerify(null,CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                $("#uploadImg").click();
            }, function () {
                $scope.buySize()
            })
        };
        window.uploadImageMutiple = function($event) {

            var files = [];
            for (var i = 0; i < $event.target.files.length; i++) {
                files.push($event.target.files[i])
            }
            var lowerSize = [];
            angular.forEach(files, function(item, index) {
                if (item.size / 1024 < 150) {
                    lowerSize.push(index + 1);
                }
            });
            if (lowerSize.length > 0) {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'存在' + lowerSize.length + '张图片小于150k\n确认上传？'
                },function(){
                    asyncUpload(files);
                },function () {
                    files = files.filter(function(item, index) {
                        return item.size / 1024 >= 150;
                    });
                    if (files.length == 0) {
                        vm.page = 0;
                        vm.maxId = null;
                        vm.galleries = [];
                        loadAll()
                    } else {
                        asyncUpload(files);
                    }
                })
                // swal({
                //     title: '存在' + lowerSize.length + '张图片小于150k\n确认上传？',
                //     imageUrl: Theme.sweetHintImg(),
                //     showCancelButton: true,
                //     confirmButtonText: "确认",
                //     cancelButtonText: "取消"
                // },
                // function(isConfirm) {
                //     if (isConfirm) {
                //         asyncUpload(files);
                //     } else {
                //         files = files.filter(function(item, index) {
                //             return item.size / 1024 >= 150;
                //         });
                //         if (files.length == 0) {
                //             vm.page = 0;
                //             vm.galleries = [];
                //             loadAll()
                //         } else {
                //             asyncUpload(files);
                //         }
                //     }
                // })
            } else {
                asyncUpload(files);
            }
        };

        var bigSizePics = [];
        function asyncUpload(files) {
            bigSizePics = [];
            indd = 0;
            errorImageArr = [];
            len = files.length;
            var files1 = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size>=20*1024*1024 ){
                    file.imageSize= Math.ceil(file.size/1024/1024);
                    bigSizePics.push(file);
                }else{
                    files1.push(file)
                }
            }
            if(files1.length>0){
                vm.uploading = true;
                for (var i = 0; i < files1.length; i++) {
                    var file = files1[i];
                    if (file.size<20*1024*1024 ){
                        ImageService.backendUploadPics(file, $scope.domain, vm.token ,function (res, f) {
                            serviceUpload(res, f,files1.length);
                        },function (res) {
                            errorImageArr.push(res);
                            checkUploadResult();
                        });
                    }
                }
            }else{
                if(bigSizePics.length>0){
                    vm.uploading = true;
                    thumbnailatorPic();
                }
            }
        }

        //使用条款
        $scope.clause = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/web-gallery/clause-modal.html',
                controller: 'ClauseModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xs'
            })
        }
        vm.duplicateList = [];
        vm.isDuplicateList = false;
        function serviceUpload(data, file,fileLength) {
            Gallery.upload({
                hash: data.hash,
                identifiers: data.key,
                provider: "QINIU",
                fileName: file.name,
                imgSize: file.size,
                folderId: vm.folderId
            }, function(resp) {
                if(resp.duplicate){
                    resp.identifier= $scope.domain + resp.identifier;
                    vm.duplicateList.push(resp)
                }
                indd++;
                if ( indd == fileLength) {
                    document.getElementById('uploadImg').value = '';//上传完清空输入框
                    vm.uploading = false;
                    if(vm.duplicateList.length>0){
                        MessageService.confirm({
                            title :'提示',
                            cancel :'取消',
                            confirm :'确认',
                            msg :'检测到图库中有'+vm.duplicateList.length+'张重复图片，是否需要自动去重？',
                            html: '<span class="themecolor">查看重复图片</span>'
                        }, function(res) {
                            if(res == 'htmlClick'){
                                vm.isDuplicateList = true;
                            }else{
                                var ids = [];
                                vm.duplicateList.forEach(function (item){
                                    ids.push(item.id)
                                })
                                Gallery.batchDelete(ids, function () {
                                    MessageService.success("去重成功！");
                                    vm.page = 0;
                                    vm.maxId = null;
                                    vm.galleries = [];
                                    loadAll()
                                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                                        progressInit(data);
                                    });
                                    vm.duplicateList=[];
                                    if (bigSizePics.length > 0) {
                                        thumbnailatorPic();
                                    }
                                    $state.reload();
                                }, function (error) {
                                    MessageService.error("删除失败！");
                                    vm.duplicateList=[];
                                    if (bigSizePics.length > 0) {
                                        thumbnailatorPic();
                                    }
                                });
                            }
                        },function (){
                            vm.duplicateList=[];
                            if (bigSizePics.length > 0) {
                                thumbnailatorPic();
                            }
                        })
                    }else{
                        MessageService.successConfirm({
                            title:'上传完成',
                            imgUrl:'content/images/icon/success-icon.png',
                            btnText:'知道了'
                        },function(){
                            // $state.reload();
                            if(bigSizePics.length){
                                thumbnailatorPic();
                            }else{
                                // vm.dataGalleries = [];
                                // vm.page = 0;
                                // vm.galleries = [];
                                // loadAll()
                            }
                        })
                        CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                            progressInit(data);
                        });
                    }
                }else {
                    checkUploadResult();
                }
            }, function(error) {
                errorImageArr.push(file);
                checkUploadResult();
            });
        }
        $scope.duplicateChange = function (){
            vm.isDuplicateList = false;
            vm.duplicateList=[];
            if (bigSizePics.length > 0) {
                thumbnailatorPic();
                return
            }

            vm.page = 0;
            vm.maxId = null;
            vm.galleries = [];
            loadAll()
            CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                progressInit(data);
            });
            $state.reload();
            console.log(vm.duplicateList)
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function checkUploadResult(){
            if (errorImageArr.length != 0 && len == (indd + errorImageArr.length+ bigSizePics.length)) {
                vm.uploading = false;
                SweetAlert.swal({
                    title: '上传完成',
                    text: "存在" + errorImageArr.length + "张照片中断\n请重新选择上传",
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: "确认",
                    cancelButtonText: "取消"
                }, function(isConfirm) {
                    if (isConfirm) {
                        asyncUpload(errorImageArr);
                    } else {
                        SweetAlert.swal({
                            title: '图片已经取消上传',
                            imageUrl: Theme.sweetHintImg(),
                            text: "",
                            timer: 2000,
                            showConfirmButton: false
                        });
                        // vm.page = 0;
                        // vm.galleries = [];
                        // loadAll()
                    }
                })
            }
        }

        // 页面滚动，加载数据
        $('.home-body').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(vm.galleriesAll.length < vm.totalItems){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.page++;
                    loadAll()
                }
            }
        });
        // 回到顶部
        $scope.scrollIntoViewTop = function(){
            $('.home-body').animate({scrollTop: 0}, 'slow');
        }
        $('.home-body').scroll(function(){
            if($(this).scrollTop()>300){
                 $("#return-top").show()
            }else{
                 $("#return-top").hide()
            }
       })

        $scope.folderSelectedActive= function(event){
            event.preventDefault();
            event.stopPropagation();
        }
        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("wss://md.momentsgo.com/widget/widget/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);
            // var ws = $websocket("ws://localhost:8089/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                if (photo.folderId == null) {
                    vm.galleriesAll.push(photo)
                    vm.totalItems++;
                    photo.createdDate = DateUtils.convertLocalDateToServer(photo.createdDate);
                    var result = ListService.inList(photo, vm.dataGalleries, ["createdDate"] );
                    if (!result) {
                        vm.dataGalleries.unshift({createdDate:photo.createdDate, galleries:[photo]});
                    }else{
                        result.galleries.unshift(photo);
                    }
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
    }
})();
