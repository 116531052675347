(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('OrderProtocolAgreement2Controller', OrderProtocolAgreement2Controller);

    OrderProtocolAgreement2Controller.$inject = ['$stateParams', 'Theme', '$uibModalInstance', 'MessageService',  '$scope', '$q', 'entity' ];

    function OrderProtocolAgreement2Controller($stateParams, Theme, $uibModalInstance, MessageService,  $scope, $q, entity) {
        var vm = this;
        vm.data = entity || { quantity: 1};
        vm.data.agree = false;

        init();

        function init() {
            if (!vm.data.quantity) {
                vm.data.quantity = 1;
            }
        }

        $scope.close = function() {
            $uibModalInstance.dismiss();
        }

        $scope.agree = function() {
            if(!vm.data.agree){
                swal({
                    title: "请先确认阅读",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            $uibModalInstance.close({agree: true});
        }
    }
})();
