(function () {
    'use strict';

    var editorPreviewMini = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/product/editor-preview-mini.html',
        // controller: 'articlePreviewController',
        controllerAs: 'vm',
        bindings: {
            documentPage: '<',
            article: '<',
            fileName: '=',
            isNav: '<',
        },
        controller: ['$scope', 'Qinius', '$http', '$q', 'FontManagement', 'MessageService', '$timeout','MessageNotification','DateUtils',
            function ($scope, Qinius, $http, $q, FontManagement, MessageService, $timeout,MessageNotification,DateUtils) {
                var vm = this;
                vm.loading = true;
                $scope.domains = Qinius.getDomain();
                $scope.isPull = true;
                vm.landscape = false;
                vm.thisPage = 0;
                vm.partData = {};
                vm.bindingtypes = [
                    {id:'LeftNew',name:'左右滑动'},
                    {id:'Tile',name:'平铺展示'},
                ]
                vm.bindingtype = 'LeftNew';
                $q.all([$scope.domains.$promise]).then(function () {
                    $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                    editorInit()
                });
                vm.messageShow = true;
                vm.messageList = "";
                getMessageList();
                function getMessageList() {
                    MessageNotification.getAllByCondition({
                            items:[
                                {key:"pageType", op : "=", value:"View"},
                                {key:"storeId", op : "=", value:vm.storeId},
                                {key:"available", op : "=", value:"true"},
                                {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                        }, function (res) {
                            res.forEach(function (item, index) {
                                vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                            })
                        }
                    )
                }
                function editorInit() {
                    vm.windowW1 = 140;
                    vm.windowH1 = 140;
                    vm.items = [];
                    $scope.template = angular.copy(vm.documentPage);
                    vm.parts = $scope.template.config.parts.list;
                    $scope.template.pages.page=[];
                    vm.documentPage.pages.page.forEach(function (page) {
                        if(!page.backup){
                            $scope.template.pages.page.push(angular.copy(page));
                        }
                    })
                    vm.isEdit = true;
                    documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                        page.custom.ratio1 = 140 / page.custom.width;
                    }, function () {
                        $scope.template.pages.page.forEach(function (page) {
                            if (!page.scene) {
                                pageTrimbox(page)
                            }
                        })
                        ratioInfo()
                        $timeout(function () {
                            loadApp();
                            $timeout(function () {
                                vm.loading = false;
                            },100)
                        },1000)
                    })
                }
                function ratioInfo() {
                    vm.windowH = window.innerHeight-300;
                    vm.windowW = window.innerWidth*0.8;
                    vm.pageMaxWidth = 0;
                    vm.pageMaxHeight = 0;
                    var pageMaxWidtTile = 0;
                    $scope.template.pages.page.forEach(function (page) {
                        page.isUse = false;
                        if (pageMaxWidtTile < page.custom.width) {
                            pageMaxWidtTile = page.custom.width;
                        }
                    })
                    vm.ratioTile = vm.windowW / pageMaxWidtTile;
                    $scope.template.pages.page.forEach(function (page) {
                        page.isUse = false;
                        if(page.config.partid == 'P'){
                            if(vm.pageMaxWidth < (page.spread ? page.custom.width : page.custom.width*2)){
                                vm.pageMaxWidth = (page.spread ? page.custom.width : page.custom.width*2);
                            }
                            if(vm.pageMaxHeight < page.custom.height){
                                vm.pageMaxHeight = page.custom.height;
                            }
                        }
                    })
                    if(!vm.landscape){
                        if(vm.windowH / vm.pageMaxHeight >= vm.windowW / vm.pageMaxWidth ){
                            vm.ratio = vm.windowW / vm.pageMaxWidth;
                        }else {
                            vm.ratio = vm.windowH / vm.pageMaxHeight;
                        }
                    }else{
                        if(vm.windowH / vm.pageMaxWidth >= vm.windowW / vm.pageMaxHeight ){
                            vm.ratio = vm.windowW / vm.pageMaxHeight;
                        }else {
                            vm.ratio = vm.windowH / vm.pageMaxWidth;
                        }
                    }
                    vm.ratio1 = 80 / vm.pageMaxHeight;
                    vm.pageMaxWidth = vm.pageMaxWidth*vm.ratio;
                    vm.pageMaxHeight = vm.pageMaxHeight*vm.ratio;
                    vm.pageMaxWidth1 = vm.pageMaxWidth;
                    vm.pageMaxHeight1 = vm.pageMaxHeight;
                    $scope.template.pages.page.forEach(function (page,index) {
                        if(page.config.partid == 'P'){
                            page.ratio = vm.ratio;
                        }else{
                            var _width = (page.spread || page.config.partid != 'P' ? page.custom.width : page.custom.width*2);
                            var _height = page.custom.height;
                            if(!vm.landscape){
                                if(vm.windowH / _height >= vm.windowW / _width ){
                                    page.ratio = vm.windowW / _width;
                                }else {
                                    page.ratio = vm.windowH / _height;
                                }
                            }else{
                                if(vm.windowH / _width >= vm.windowW / _height ){
                                    page.ratio = vm.windowW / _height;
                                }else {
                                    page.ratio = vm.windowH / _width;
                                }
                            }
                        }
                        // if(index == 0){
                        //     vm.pageMaxWidth = _width * page.ratio;
                        //     vm.pageMaxHeight = _height * page.ratio;
                        // }else{
                        if(vm.pageMaxWidth < _width * page.ratio){
                            vm.pageMaxWidth = _width * page.ratio;
                        }
                        if(vm.pageMaxHeight < _height * page.ratio){
                            vm.pageMaxHeight = _height * page.ratio;
                        }
                        // }
                        for(var i=0; i<vm.parts.length; i++){
                            if(vm.parts[i].id == page.config.partid){
                                if(!vm.parts[i].pages){
                                    vm.parts[i].pages = [];
                                }
                                vm.parts[i].pages.push(page)
                                break
                            }
                        }
                    })
                    vm.insidePages = [];
                    for(var i=0; i<vm.parts.length; i++){
                        if(vm.parts[i].pages &&vm.parts[i].pages.length>0){
                            vm.parts[i].marginLeft = -i*vm.pageMaxWidth - i*50;
                            var _pages = [];
                            if(vm.parts[i].id == 'P'){
                                vm.parts[i].pages.forEach(function (page, index) {
                                    var emptyPage = angular.copy(page);
                                    if(!emptyPage.background){
                                        emptyPage.background = {
                                            color:'#000000',
                                            resource:{
                                                identifier:''
                                            }
                                        }
                                    }else{
                                        emptyPage.background.color = "#000000";
                                        emptyPage.background.resource.identifier="";
                                    }
                                    emptyPage.barcode = null;
                                    emptyPage.qrcode = null;
                                    emptyPage.levels = {
                                        level: []
                                    };
                                    emptyPage.marginLeft = 0;
                                    if(index == 0){
                                        var _emptyPage = angular.copy(emptyPage);
                                        _emptyPage.background.color = "#ddd";
                                        _pages.push({
                                            page:angular.copy(_emptyPage),
                                            partid: vm.parts[i].id,
                                            spread:false
                                        })
                                    }
                                    if(!page.isUse){
                                        if(index == 0 && $scope.template.config.innerOffset == 1){
                                            page.isUse = true;
                                            setPageOrder(page)
                                            emptyPage.uuid = page.uuid;
                                            emptyPage.marginLeft = (vm.pageMaxWidth1/2-emptyPage.custom.width * emptyPage.ratio);
                                            _pages.push({
                                                page:emptyPage,
                                                partid: vm.parts[i].id,
                                                spread:false
                                            })
                                            page.marginLeft = 0;
                                            _pages.push({
                                                page:page,
                                                partid: vm.parts[i].id,
                                                spread:false
                                            })
                                            vm.insidePages.push({
                                                page1:emptyPage,
                                                page2:page,
                                                partid: vm.parts[i].id,
                                                spread:false
                                            })
                                        }else{
                                            if(page.spread){
                                                page.isUse = true;
                                                page.marginLeft = (vm.pageMaxWidth1-page.custom.width * page.ratio) / 2;
                                                var _page = angular.copy(page);
                                                _page.marginLeft = -_page.custom.width*_page.ratio/2;
                                                _pages.push({
                                                    page:page,
                                                    partid: vm.parts[i].id,
                                                    spread:true
                                                })
                                                _pages.push({
                                                    page:_page,
                                                    partid: vm.parts[i].id,
                                                    spread:true
                                                })
                                                setPageOrder(page)
                                                setPageOrder(_page)
                                                vm.insidePages.push({
                                                    page1:page,
                                                    page2:_page,
                                                    partid: vm.parts[i].id,
                                                    spread:true
                                                })
                                            }else{
                                                var _page1 = vm.parts[i].pages[index];
                                                var _page2 = index+1 >= vm.parts[i].pages.length ? null : vm.parts[i].pages[index+1];
                                                _page1.isUse = true;
                                                _page1.marginLeft = vm.pageMaxWidth1/2-_page1.custom.width * _page1.ratio;
                                                setPageOrder(_page1)
                                                if(_page2 && !_page2.spread){
                                                    _page2.isUse = true;
                                                    _page2.marginLeft = 0;
                                                    setPageOrder(_page2)
                                                    _pages.push({
                                                        page:_page1,
                                                        partid: vm.parts[i].id,
                                                        spread:false
                                                    })
                                                    _pages.push({
                                                        page:_page2,
                                                        partid: vm.parts[i].id,
                                                        spread:false
                                                    })
                                                    vm.insidePages.push({
                                                        page1:_page1,
                                                        page2:_page2,
                                                        partid: vm.parts[i].id,
                                                        spread:false
                                                    })
                                                }else{
                                                    _pages.push({
                                                        page:_page1,
                                                        partid: vm.parts[i].id,
                                                        spread:false
                                                    })
                                                    emptyPage.marginLeft = 0;
                                                    emptyPage.uuid = _page1.uuid;
                                                    _pages.push({
                                                        page:emptyPage,
                                                        partid: vm.parts[i].id,
                                                        spread:false
                                                    })
                                                    vm.insidePages.push({
                                                        page1:_page1,
                                                        page2:emptyPage,
                                                        partid: vm.parts[i].id,
                                                        spread:false
                                                    })
                                                }
                                            }
                                        }
                                    }
                                });
                            }else{
                                vm.parts[i].pages.forEach(function (page, index) {
                                    page.pageNumber=index;
                                    page.isUse = true;
                                    page.marginLeft = (vm.pageMaxWidth-page.custom.width * page.ratio) / 2;
                                    _pages.push({
                                        page:page,
                                        partid: vm.parts[i].id,
                                        spread:false
                                    })
                                })
                            }
                            vm.parts[i].pages = _pages;
                        }
                    }
                    vm.partData = vm.parts[0];
                }
                var pageOrder = 0;
                function setPageOrder(page){
                    pageOrder++;
                    page.pageNumber=pageOrder<10?'0'+pageOrder:pageOrder;
                }
                vm.pageInfo = "";
                function loadApp() {
                    $('.flipbook').turn({
                        width: vm.pageMaxWidth1,
                        height: vm.pageMaxHeight1,
                        accelerator: true,
                        when:{
                            turning: function (e, page, view) {
                                vm.turningView = view;
                            },
                            turned: function(event, page, pageObject){
                                vm.thisPage = Math.floor(page/2)-1;
                                if(page == 1){
                                    $('.flipbook').turn("page",2).stop();
                                }
                                $timeout(function () {
                                    if(vm.turningView){
                                        $('.page-wrapper').hide();
                                        $('.page-wrapper[page=' + vm.turningView[0] + ']').show();
                                        $('.page-wrapper[page=' + vm.turningView[1] + ']').show();
                                    }
                                    vm.turningView = "";
                                })
                                vm.firstPage = pageObject[0];
                                vm.lastPage = pageObject[1];
                                if(!$scope.prevPartShow('next')){
                                    vm.thisPage = vm.insidePages.length-1;
                                }
                            },
                            start:function(a,b,c){
                                if(!vm.isNav){
                                    $timeout(function () {
                                        if(b.next == 1){
                                            $scope.prevPart();
                                        }
                                    })
                                }
                            }
                        }
                    });
                }
                //横屏竖屏
                vm.landscape = false;
                vm.windowR = 1;
                $scope.landscape = function (item) {
                    if(item == !vm.landscape){return};
                    vm.landscape = !item;
                    if(vm.landscape){
                        vm.windowH = window.innerHeight-200;
                        vm.windowW = window.innerWidth*0.8;
                        var pageW = vm.pageMaxWidth;
                        var pageH = vm.pageMaxHeight;
                        var r = vm.windowW / vm.windowH;
                        var r1 = pageW / pageH;
                        if (r1 >= r) {
                            vm.windowR = vm.windowW/pageH;
                        } else {
                            vm.windowR = vm.windowH/pageW;
                        }
                    }else{
                        vm.windowR = 1;
                    }
                }
                $scope.jumpPage = function (part, uuid) {
                    vm.partData = part;
                    if(vm.partData.id != 'P'){
                        vm.thisPage = 1;
                        $('.flipbook').turn("page",1);
                    }else{
                        var _pageIndex = 0;
                        for(var i=0; i<vm.insidePages.length; i++){
                            if(vm.insidePages[i].page1.uuid == uuid){
                                // vm.thisPage = i;
                                break
                            }else{
                                _pageIndex += 1;
                            }
                            if(vm.insidePages[i].page2.uuid == uuid){
                                // vm.thisPage = i;
                                break
                            }else{
                                _pageIndex += 1;
                            }
                        }
                        $('.flipbook').turn("page",_pageIndex+2);
                    }
                }
                $scope.prevPartShow = function (item) {
                    if(!vm.parts || vm.parts.length < 2 || !vm.partData || !vm.partData.id){
                        return
                    }
                    var _returnItem = false;
                    if(item == 'prev'){
                        for(var i=0; i<vm.parts.length; i++){
                            if(vm.partData.id == vm.parts[i].id && i>0){
                                _returnItem = true;
                                break
                            }
                        }
                    }
                    if(item == 'next'){
                        for(var i=0; i<vm.parts.length; i++){
                            if(vm.partData.id == vm.parts[i].id && i<vm.parts.length-1){
                                _returnItem = true;
                                break
                            }
                        }
                    }
                    return _returnItem
                }
                $scope.nextPart = function (e) {
                    fn(e)
                    if(!vm.partData || !vm.partData.id){
                        return
                    }
                    for(var i=0; i<vm.parts.length; i++){
                        if(vm.partData.id == vm.parts[i].id && vm.parts[i+1]){
                            vm.partData = vm.parts[i+1];
                            break
                        }
                    }
                }
                $scope.prevPart = function (e) {
                    fn(e)
                    if(!vm.partData || !vm.partData.id){
                        return
                    }
                    for(var i=0; i<vm.parts.length; i++){
                        if(vm.partData.id == vm.parts[i].id && vm.parts[i-1]){
                            vm.partData = vm.parts[i-1];
                            break
                        }
                    }
                }
                $scope.flipbookViewportPage = function(e){
                    fn(e)
                    if(!vm.partData || !vm.partData.id || vm.turningView){
                        return
                    }
                    if(!vm.landscape){
                        var _w = $(".c-preview-landscape").width();
                        if(e.pageX-$(".c-preview-landscape").offset().left > _w / 2){
                            if(($scope.prevPartShow('next') || vm.thisPage<vm.insidePages.length-1)){
                                if(vm.partData.id == 'P' && vm.lastPage < vm.partData.pages.length){
                                    $('.flipbook').turn("next");
                                }else{
                                    $scope.nextPart();
                                }
                            }else{
                                if(vm.windowW>800){
                                    MessageService.error("最后一页");
                                }
                            }
                        }else{
                            if($scope.prevPartShow('prev') || vm.thisPage>0){
                                if(vm.partData.id == 'P' && vm.lastPage > 3){
                                    $('.flipbook').turn("previous");
                                }else{
                                    $scope.prevPart();
                                }
                            }else{
                                if(vm.windowW>800){
                                    MessageService.error("第一页");
                                }
                            }
                        }
                    }else{
                        var _h = $(".c-preview-landscape").height();
                        if(e.pageY-$(".c-preview-landscape").offset().top > _h / 2){
                            if(vm.partData.id == 'P' && vm.lastPage < vm.partData.pages.length){
                                $('.flipbook').turn("next");
                            }else{
                                $scope.nextPart();
                            }
                        }else{
                            if(vm.partData.id == 'P' && vm.lastPage > 3){
                                $('.flipbook').turn("previous");
                            }else{
                                $scope.prevPart();
                            }
                        }
                    }
                }
                $scope.bindingtypeInfo = function () {
                    var _name = "";
                    vm.bindingtypes.forEach(function (item) {
                        if(vm.bindingtype == item.id){
                            _name = item.name;
                        }
                    })
                    return _name
                };
                $scope.bindingtypeSelect = function (item) {
                    vm.bindingtype = item.id;
                }
            }
        ]
    };

    angular
        .module('editorApp')
        .component('editorPreviewMini', editorPreviewMini);
})();
