(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('commodity', {
                parent: 'home',
                url: '/commodity',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.commodity-center'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/commodity/commodity.html',
                        controller: 'CommodityController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodity');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('commoditySearch', {
                parent: 'home',
                url: '/commoditySearch?search',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.commodity-center'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/commodity/commodity-search.html',
                        controller: 'CommoditySearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodity');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            //先编辑 选择模板
            .state('commoditySelect', {
                parent: 'home2',
                url: '/{pid}/{vid}/commoditySelect?from',
                data: {
                    authorities: [],
                    pageTitle: 'commodity.title'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/commodity/commodity-select.html',
                        controller: 'CommoditySelectController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    from: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            from: $stateParams.from
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodity');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Product', function ($stateParams, Product) {
                        return Product.get({id: $stateParams.pid,fixXMLLine:true}).$promise;
                    }]
                }
            })
            //先下单 选择模板
            .state('productXmlSelect', {
                parent: 'home2',
                url: '/{pid}/{vid}/{orderId}/{plid}/productXmlSelect?from',
                data: {
                    authorities: [],
                    pageTitle: 'commodity.title'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/commodity/commodity-select.html',
                        controller: 'CommoditySelectController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    from: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            from: $stateParams.from
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodity');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Product', function ($stateParams, Product) {
                        return Product.get({id: $stateParams.pid,fixXMLLine:true}).$promise;
                    }]
                }
            })
            .state('standardCommodityDetails', {
                parent: 'home2',
                url: '/{pid}/standardCommodityDetails?from',
                data: {
                    authorities: [],
                    pageTitle: 'commodity.title'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/commodity/standard-commodity-details.html',
                        controller: 'StandardCommodityDetailsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    from: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            from: $stateParams.from
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodity');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Product', function ($stateParams, Product) {
                        return Product.get({id: $stateParams.pid}).$promise;
                    }]
                }
            })
            .state('standardCommodityDetailsNull', {
                parent: 'home2',
                url: '/{pid}/standardCommodityDetailsNull?from',
                data: {
                    authorities: [],
                    pageTitle: 'commodity.title'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/commodity/standard-commodity-details-null.html',
                        controller: 'StandardCommodityDetailsNullController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    from: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            from: $stateParams.from
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodity');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Product', function ($stateParams, Product) {
                        return Product.get({id: $stateParams.pid}).$promise;
                    }]
                }
            })
            //地址管理
            .state('address', {
                parent: 'home1',
                url: '/address',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.address'
                },
                views: {
                    'content': {
                        templateUrl: 'app/entities/commodity/address.html',
                        controller: 'AddressController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }
})();
