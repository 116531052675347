(function(){
	"use strict";
	angular
	.module("editorApp")
	.controller("AddressDialogController", AddressDialogController);
	AddressDialogController.$inject = ['$scope', '$uibModalInstance', 'MessageService', '$stateParams', 'LocationCity','LocationDistrict','LocationProvice','Customer','entity'];

    function AddressDialogController( $scope,  $uibModalInstance, MessageService, $stateParams, LocationCity, LocationDistrict,LocationProvice,Customer,entity ) {

        var vm = this;
        vm.clear = clear;
        init();
        function init() {
            vm.province = "";
            vm.city = "";
            vm.district = "";
            vm.addressMessage = "";
            vm.isFormState = false;
            if(entity.addressData && entity.location){
                vm.addressData = entity.addressData;
                vm.location = entity.location;
            }else{
                vm.addressData = {
                    address: "",
                    city: "",
                    contact: "",
                    customerId: "",
                    district: "",
                    id: null,
                    isDefault: false,
                    phone: null,
                    postCode: null,
                    province: ""
                };
                vm.location = {
                    province: "",
                    city: "",
                    district: ""
                };
            }
            Customer.current({},function(data){
                vm.customer = data;
                console.log(data);
                vm.addressData.customerId = data.id;
            },function(error){
                console.log(error);
            });
            loadData();
        }


        function loadData() {

            LocationProvice.query({},function (data) {
                vm.provinces =  data;
                if (vm.location.province == null || vm.location.province == "") {
                    return;
                }
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == vm.location.province) {
                        vm.province =  p;
                        break;
                    }
                }
                LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                    vm.citys = data;
                    for (var i = vm.citys.length - 1; i >= 0; i--) {
                        var c = vm.citys[i];
                        if (c.name == vm.location.city) {
                            vm.city = c;
                            break;
                        }
                    }
                    if(vm.city != null ){
                        LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                            vm.districts = data;
                            for (var i = vm.districts.length - 1; i >= 0; i--) {
                                var di = vm.districts[i];
                                if (di.name == vm.location.district) {
                                    vm.district = di;
                                }
                            }
                        });
                    }
                });
            });
        }
      $scope.changeProvince =  function () {
            if (vm.province == null) {
                vm.citys = [];
                vm.districts = [];
                return;
            }
            LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                vm.citys = data;
                vm.districts = [];
            });
        }
       $scope.changeCity = function () {
            if (vm.city == null) {
                vm.districts = [];
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
            });
        }


        $scope.validate = function () {
            vm.addressData.province = vm.province.name;
            vm.addressData.city = vm.city.name;
            vm.addressData.district = vm.district.name;
            if(vm.addressData.contact == '' || vm.addressData.contact == null){
                MessageService.error("请输入联系人");
                return true;
            }
            if(!isPhoneNumber(vm.addressData.phone)){
                MessageService.error('请输入正确的手机号码')
                return true;
            }
            if(vm.addressData.province == '' || vm.addressData.province == null){
                MessageService.error("请选择省");
                return true;
            }
            if(vm.addressData.city == '' || vm.addressData.city == null){
                MessageService.error("请选择市");
                return true;
            }
            if(vm.addressData.district == '' || vm.addressData.district == null){
                MessageService.error("请选择区");
                return true;
            }
            if(vm.addressData.address == '' || vm.addressData.address == null){
                MessageService.error("请输入详细地址");
                return true;
            }

        };

      $scope.save = function () {
            if ($scope.validate()) {
                return;
            }
            $uibModalInstance.close(vm.addressData);
        }
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.analysis = function(){
            var _value = vm.addressMessage.split("\n").join("");
            Customer.analysisLocation({value:_value}, function (res) {
                vm.addressData = {
                    address: res.address,
                    city: "",
                    contact: res.contact,
                    district: "",
                    id: vm.addressData.id,
                    isDefault: res.isDefault,
                    phone: res.phone,
                    postCode: res.postCode,
                    province: "",
                    customerId: vm.customer.id
                };
                vm.location = {
                    province: res.province,
                    city: res.city,
                    district: res.district
                }
                loadData()
            });
        }

    }

})();
