(function() {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('collection',{
                parent: 'home1',
                url: '/collection',
                data:{
                    authorities: [],
                    pageTitle: '我的收藏'
                },
                views:{
                    'content':{
                        templateUrl: 'app/entities/collection/collections.html',
                        controller: 'CollectionController',
                        controllerAs: 'vm'
                    }
                }
            })

    }

})();
