(function () {
    'use strict';

    angular
        .module('editorApp')
        .factory('Register', Register)
        .factory('AccountP', AccountP);

    Register.$inject = ['$resource'];
    AccountP.$inject = ['$resource'];

    function Register ($resource) {
        return $resource('api/register', {}, {});
    }

    function AccountP($resource) {
        var resourceUrl =  'security/' + 'api/platform-users/:path/:id';
        var service = $resource(resourceUrl, {}, {
            'checkPassword': {
                method: "GET",
                params: {
                    path: "checkPassword"
                },
                transformResponse: function(data) {
                    return data;
                }
            }
        });

        return service;
    }
})();
