(function(){
	"use strict";
	angular
	.module("editorApp")
	.controller("EditorNameDialogController", EditorNameDialogController);
	EditorNameDialogController.$inject = ['$scope', '$uibModalInstance', 'MessageService', '$stateParams',  'Folder', 'entity'];

    function EditorNameDialogController( $scope,  $uibModalInstance, MessageService, $stateParams,  Folder, entity) {

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.name = entity.name || '';
        vm.popName = entity.popName || '修改名称';
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

         

        $scope.validate = function () {
            if (vm.name == null || vm.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            return true;
        };

        function save() {
            if($scope.validate){
                $uibModalInstance.close(vm.name);
            }
            
            
        }

    }

})();
