(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CouponController', CouponController);

    CouponController.$inject = ['$state', 'Coupon', '$scope', 'paginationConstants', 'pagingParams','$stateParams','Customer','$q', '$localStorage'];

    function CouponController($state, Coupon, $scope, paginationConstants, pagingParams,$stateParams,Customer,$q, $localStorage) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.storeId = $stateParams.storeId || $state.params.storeId;
        if (!vm.storeId) {
            return;
        }
        vm.store = $localStorage.store;;
        vm.user = Customer.current({});

        $q.all([vm.user.$promise]).then(function(){
            loadAll();
        });

        function loadAll () {
            var monthStr = new Date().getMonth() + 1;
            var dayStr = new Date().getDate();
            var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
            vm.unuseCoupons = Coupon.getAllByCondition({items:[{key:"used", op:"=", value:"false"}, {key:"endTime", op:">=", value:timestr}], sort : sort()});
            vm.usedCoupons = Coupon.getAllByCondition({items:[{key:"used", op:"=", value:"true"}], sort : sort()});
            // vm.AllCoupons = Coupon.getAllByCondition({items:[{}], sort : sort()});
            vm.outCoupons = Coupon.getAllByCondition({items:[{key:"used", op:"=", value:"false"}, {key:"endTime", op:"<", value:timestr}], sort : sort()});
            vm.status = 'unUsed';
            vm.coupons = vm.unuseCoupons;
 
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function getExpried(coup) {
            if (coup == null) {
                return false;
            };
            // 修正过期时间比较
            var endTime = new Date(coup.endTime+" 23:59:59").getTime();
            var nowTime = new Date().getTime();
            if (nowTime >= endTime) {
                return true;
            }
            return false;
        }

        $scope.isExpired = function(coup){
            return getExpried(coup);
        };

        $scope.statusSelected = function (status) {
            vm.status = status;
            vm.coupons = [];
            if (status == 'unUsed') {
                vm.coupons = vm.unuseCoupons;
                console.log(vm.coupons)
            } else if (status == 'used') {
                vm.coupons = vm.usedCoupons;
            } else if (status == 'done') {

                vm.coupons = vm.outCoupons;
            }
            // selectStatus(status);
        };

        function selectStatus(status) {
            vm.coupons2 = vm.coupons;
            vm.coupons = [];
            if (status == 'unUsed') {
                vm.coupons = vm.unuseCoupons;
                console.log(vm.coupons)
            } else if (status == 'used') {
                vm.coupons = vm.usedCoupons;
            } else if (status == 'done') {

                vm.coupons = vm.outCoupons;
            }
        }

        $scope.gotoProducts = function (coup) {
            if(vm.status == 'unUsed'){
                localStorage.setItem('templates', JSON.stringify(coup.templates));
                $state.go('pay-code-production', {storeId: vm.storeId});
            }
        }
    }
})();
