(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$timeout', '$state', '$scope', '$q', '$http', '$stateParams', 'ItemValue', 'CommonService', 'FontManagement', 'Convert', 'Qinius', '$rootScope', 'Article', 'ImageService', '$localStorage', 'ClipArt', 'PlatformWechatSetting', 'SweetAlert', 'WxJS', 'Theme', 'UidService', 'ListService', 'Store', 'Gallery'];

    function ProductDialogController($timeout, $state, $scope, $q, $http, $stateParams, ItemValue, CommonService, FontManagement, Convert, Qinius, $rootScope, Article, ImageService, $localStorage, ClipArt, PlatformWechatSetting, SweetAlert, WxJS, Theme, UidService, ListService, Store, Gallery) {

        var vm = this;
        //避免重复调用，当状态是make的时候进入新建/编辑作品页面
        var production = localStorage.getItem('Production');

        if (production == 'start' || production == 'gallery') {
            return
        }

        var tt = localStorage.getItem('guide-step');
        $scope.status = localStorage.getItem('Production');
        if ($scope.status == 'make' && tt) {
            localStorage.setItem('countN', 8);
            $scope.countN = 8;
        } else {
            $scope.countN = parseInt(localStorage.getItem('countN'));
        }


        if (tt == 'true') {
            vm.guideStep = true;
        } else if (tt == 'false' || tt == '' || tt == null || tt == undefined) {
            vm.guideStep = false;
        }
        if ($scope.loading == false) {
            $scope.loadingAfter = false;
        }

        //风格切换  引入不同的css文件
        Theme.judge();
        /**
         * wechat api
         */
        var url = "";
        if (!!window.location.hash) {
            url = window.location.href.slice(0, -window.location.hash.length);
        }else{
            url = window.location.href;
        }
        if (wx) {
            WxJS.get({url: url}, function (config) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: config.appId, // 必填，公众号的唯一标识
                    timestamp: config.timestamp, // 必填，生成签名的时间戳
                    nonceStr: config.nonceStr, // 必填，生成签名的随机串
                    signature: config.signature,// 必填，签名，见附录1
                    jsApiList: ['hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(function () {
                    console.log('wechat api ready!');
                    wx.hideMenuItems({
                        menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                    });
                });
                wx.error(function (res) {
                    console.log(res)
                });
            }, function () {
                console.log("wx config error");
            });
        }

        localStorage.setItem('url', url);


        vm.editHeight = document.documentElement.clientHeight;
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});      //设置页面的最小宽度
        $(".well").css({'margin-bottom': 0});
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');

        if (!window.__wxjs_is_wkwebview) {
            $scope.dHeight = $rootScope.dHeight != null? $rootScope.dHeight : document.documentElement.clientHeight;
            // 禁止安卓默认事件
            var androidDefault = document.querySelector('.page-make');
            androidDefault.addEventListener('contextmenu', function (e) {
                e.preventDefault();
            });
        } else {
            $scope.dHeight = localStorage.getItem('windowMinH') != null? localStorage.getItem('windowMinH'): document.documentElement.clientHeight;
        }

        $rootScope.dHeight = $scope.dHeight;

        vm.platformSetting = PlatformWechatSetting.get();
        vm.storeId = $stateParams.storeId != null ? $stateParams.storeId : $localStorage.storeId;
        vm.pid = $stateParams.pid;      //新建作品id
        vm.vid = $stateParams.vid;      //新建作品参数id
        vm.aid = $stateParams.aid;      //编辑作品id
        vm.edit = false;
        vm.counts = 0;
        vm.FirstSeven = 0;
        vm.flags = false;
        vm.isOrientation = false;


        vm.store = Store.get({id: vm.storeId});

        /**
         * mm换算成px
         * @param mm
         * @returns {number}
         */
        var unitTransform = function (mm) {
            return mm * 3;
        };
        /**
         * px换算成mm
         * @param px
         * @returns {number}
         */
        var unitTransformPx = function (px) {
            return px * 1 / 3;
        };

        $scope.isSave = false;
        $rootScope.currentCarouselIndex = 0;
        $scope.selectSketchMenu = 'sketch-color';
        $scope.colorList = ['#ff001e', '#ff5c00', '#ff9700', '#61734d', '#838c97', '#8d4ac8', '#b8e986', '#7e6565', '#ffffff', '#f1e7d9', '#b5ce99', '#82c281', '#dac2ef', '#000', '#797979', '#7c330a', '#eec78d', '#444444', '#589ceb', '#4a7bc8', '#ddabab'];
        $scope.selectedColor = $scope.colorList[0];
        $scope.stickerList = [];
        $scope.selectedFont = '';
        $scope.domain = null;
        $scope.update = false;
        $scope.PAGELEN = 0;
        $scope.AllPAGELEN = 0;
        //底部模板栏，像素栏，图片栏高度:84+pading:8   =100
        $scope.mHeight = 84;
        //52+62+100 +20
        $scope.tHeight = 240;
        // 左移按钮
        $scope.visibleLeft = false;
        // 右移按钮
        $scope.visibleRight = false;
        // 编辑按钮
        $scope.showBarMenu = true;
        // 底部按钮栏
        $scope.showBottomMenu = true;
        $scope.showTempMenu = false;
        $scope.showImageMenu = false;
        $scope.showCut = false;
        $scope.showText = false;
        $scope.loading = true;
        $scope.template = {pages: {page: []}};
        $scope.pageAllPx = {page: []};
        $scope.status = localStorage.getItem('Production');
        $scope.weixinFlag = localStorage.getItem('distinguish');
        $scope.template.maxPageHeight = 0;
        $scope.template.ulWidth = 0;

        var mL = 0, liW = 0, ulW = 0, selectTextIndex = 0;
        var runR = true, runL = true, flag = true, isDragging = false, moveSticker = false, moveText = false, completed = false;
        var drag;
        var dragBox = {}, stickerDragBox = {};
        var gallery = [], goes = [], local_pages = [], single = [], pageIndex = [], pageI = [], proIndex = [], sort = [];
        var $ul = $(".carousel5");
        var $li = $(".template-carousel");
        $li.hide();


        var reviewDoc = null;

        if (vm.pid) {
            getLocalStorage();
            // 一键Go
            goes = JSON.parse(localStorage.getItem("go"));
        } else {
            getLocalStorage()
        }

        function getLocalStorage() {
            // 工作区中编辑作品所用到的图片
            gallery = JSON.parse(localStorage.getItem("gallery")) != null ? JSON.parse(localStorage.getItem("gallery")) : [];
            // $scope.template.pages数据
            local_pages = JSON.parse(localStorage.getItem("pages")) != null? JSON.parse(localStorage.getItem("pages")) : [];
            // 单张导入
            single = JSON.parse(localStorage.getItem("single"));
            // 单张导入到第N页
            pageIndex = localStorage.getItem("index");
            // 单张导入到第N个占位框
            pageI = localStorage.getItem("i");
            // 记录图片Id
            proIndex = localStorage.getItem("$index");

            sort = localStorage.getItem("sort");
        }

        $scope.domains = Qinius.getDomain1();

        if (vm.pid) {
            // 新建作品
            //  显示/隐藏引导页
            $scope.isHide = localStorage.getItem('isHide') != null ? localStorage.getItem('isHide') : false;
            if (!$scope.isHide) {
                $(".come-in").show();
            }
            ItemValue.get({id: $stateParams.vid}, function (res) {
                vm.article = {
                    sku: res.sku,
                    name: res.productName,
                    productId: res.productId,
                    productName: res.productName,
                    categoryId: res.categoryId,
                    categoryName: res.categoryName,
                    themeClassifyId: res.productXmlThemeId,
                    themeClassifyName: res.productXmlThemeName,
                    productXmlId: res.productXmlId,
                    productXmlName: res.productXmlName
                };
                Convert.xmlToDocument({
                    identifier: res.productXmlIdentifier,
                    provider: res.productXmlProvider
                }, function (res) {
                    setPageContent(res);
                });
            });
        } else if (vm.aid) {
            // 编辑作品
            Article.get({id: $stateParams.aid}, function (resp) {
                vm.article = angular.copy(resp);
                $scope.update = true;
                $scope.isHide = true;
                setPageContent(resp.document);
            });
        }

        /**
         * Get document
         * @param obj
         */
        function setPageContent(obj) {
            var k = 0;
            // 正常页
            var arrUse = {
                pages: {
                    page: []
                }
            };
            // 备用页
            var arrUnUse = {
                pages: {
                    page: []
                }
            };

            //新建
            if (vm.pid) {

                for (var i = 0; i < obj.pages.page.length; i++) {
                    var page = obj.pages.page[i];
                    if (page.uuid == null) {
                        page.uuid = UidService.get();
                    }
                    if (!page.backup) {
                        // if()
                        page.count = 1;
                        arrUse.pages.page.push(page);
                        $scope.PAGELEN++;
                        k++;
                        vm.counts += page.imageboxes.imagebox.length;
                        if (k < 4 || $scope.PAGELEN <= 3) {
                            vm.FirstSeven += page.imageboxes.imagebox.length;
                        }
                    }
                    //将模板所有页设为备用页
                    if (page.backup) {
                        page.count = 0;
                    }
                    var p = angular.copy(page);

                    if (p.backup) {
                        arrUnUse.pages.page.unshift(p);
                    } else {
                        arrUnUse.pages.page.push(p);
                    }
                    // arrUnUse.pages.page.push(p);
                    p.backup = true;
                }
            } else {
                for (var i = 0; i < obj.pages.page.length; i++) {
                    var page = obj.pages.page[i];
                    if (page.uuid == null) {
                        page.uuid = UidService.get();
                    }
                    if (!page.backup) {
                        arrUse.pages.page.push(page);
                        $scope.PAGELEN++;
                        k++;
                        vm.counts += page.imageboxes.imagebox.length;
                        if (k < 4 || $scope.PAGELEN <= 3) {
                            vm.FirstSeven += page.imageboxes.imagebox.length;
                        }
                    } else {
                        arrUnUse.pages.page.push(page);
                        // arrUnUse2.pages.page.push(page);
                    }
                }
                //计算页面使用次数
                for (var i = 0; i < arrUnUse.pages.page.length; i++) {
                    var p1 = arrUnUse.pages.page[i];
                    var count = 0;
                    for (var j = arrUse.pages.page.length - 1; j >= 0; j--) {
                        var p2 = arrUse.pages.page[j];
                        if (p1.uuid == p2.uuid) {
                            count++;
                        }
                    }
                    p1.count = count;
                }
            }

            // 将备用页放在正常页面的后面
            obj.pages = angular.copy(arrUse.pages);

            if (vm.counts == 0 || vm.FirstSeven == 0) {
                showPages();
            }

            vm.article.reprint = obj.reprint;
            if ($rootScope.template) {
                vm.article.document = choose(local_pages, $rootScope.template);
            } else {
                vm.article.document = choose(local_pages, obj);
            }
            localStorage.setItem('pages', JSON.stringify(vm.article.document.pages));
            if (!$rootScope.template) {
                //单页编辑 底下可选择的模板
                if (local_pages.page && local_pages.page.length > 0) {
                    for (var i = 0; i < arrUnUse.pages.page.length; i++) {
                        var p = arrUnUse.pages.page[i];
                        var count = 0;
                        for (var j = 0; j < local_pages.page.length; j++) {
                            var p2 = local_pages.page[j];
                            if (p.uuid == p2.uuid) {
                                count++;
                            }
                        }
                        p.count = count;
                    }
                }
                getAll2(arrUnUse.pages);
            }else{
                $scope.pageAllPx = JSON.parse(localStorage.getItem("pageAllPx"));
                angular.forEach($scope.pageAllPx.page,function (page,index) {
                    if (page.imageboxes.imagebox.length > 0) {
                        angular.forEach(page.imageboxes.imagebox, function (img, i) {
                            // setTimeout(function () {
                                maskScale2(img, index, i, img.maskUrl);
                            // },1000);
                        });
                    }
                })
            }
            //平铺页   单页编辑
            getAll(vm.article.document.pages);
        }

        function showPages() {
            $scope.loading = false;
            $(".page-loading").hide().css({'opacity': 0});
            $li.show();
            $(".guide-view").removeClass('opacity-zero');
        }

        function choose(local_pages, res) {
            console.log(vm.store.bokeh);
            // 一键Go
            if ((goes != null || goes != undefined) && goes.length && vm.vid) {
                var i = 0;
                angular.forEach(res.pages.page, function (page) {
                    if (page.backup) {
                        return
                    }
                    var arr = [];
                    angular.forEach(page.imageboxes.imagebox, function (img, j) {
                        var len = page.imageboxes.imagebox.length;
                        if (!img.image) {
                            img.image = {}
                        }
                        if (len > 1) {
                            // 得到占位框的宽高比
                            img.ratio = img.geometry.width / img.geometry.height;
                            if (goes[i]) {
                                goes[i].index = i;
                                arr.push(goes[i]);
                                i++;
                            }
                            if (j == len - 1) {
                                // 按照占位框的宽高比排序
                                page.imageboxes.imagebox.sort(compare('ratio'));
                                // 按照工作区中图片的宽高比排序
                                arr.sort(compare('ratio'));
                                angular.forEach(page.imageboxes.imagebox, function (img, m) {
                                    if (!arr[m]) {
                                        return
                                    }
                                    img.image = imgParamter(img, arr[m], arr[m].index);
                                    if (vm.store.bokeh) {
                                        img.blurred = true;
                                        img.blurredRectangle = {
                                            x: 0,
                                            y: 0,
                                            width: 0,
                                            height: 0
                                        }
                                    } else {
                                        img.blurred = false;
                                    }

                                });
                            }
                        } else {
                            if (!goes[i]) {
                                return
                            }
                            img.image = imgParamter(img, goes[i], i);
                            if (vm.store.bokeh) {
                                img.blurred = true;
                                img.blurredRectangle = {
                                    x: 0,
                                    y: 0,
                                    width: 0,
                                    height: 0
                                }
                            } else {
                                img.blurred = false;
                            }

                            i++
                        }
                    });
                });
                // }

            } else {
                // $scope.template.pages.page中的内容
                if (local_pages.page && local_pages.page.length > 0) {
                    vm.counts = 0;
                    res.pages = angular.copy(local_pages);
                    angular.forEach(local_pages.page, function (page, index) {
                        if (!page.backup) {
                            vm.counts += page.imageboxes.imagebox.length;
                        }
                        angular.forEach(page.imageboxes.imagebox, function (img, i) {
                            if (res.pages.page[index] && img.blobImage) {
                                if (!res.pages.page[index].imageboxes.imagebox[i].image) {
                                    res.pages.page[index].imageboxes.imagebox[i].image = {}
                                }

                                res.pages.page[index].imageboxes.imagebox[i].blobImage = img.blobImage;
                                res.pages.page[index].imageboxes.imagebox[i].blurred = img.blurred;
                                res.pages.page[index].imageboxes.imagebox[i].blurredRectangle = img.blurredRectangle != null ? img.blurredRectangle : {};

                                res.pages.page[index].imageboxes.imagebox[i].image = {
                                    resource: {
                                        identifier: img.image.resource.identifier || '',
                                        provider: 'qiniu'
                                    },
                                    parameter: img.image.parameter != null? img.image.parameter: [],
                                    offsetX: img.image.offsetX != null ?img.image.offsetX : 0,
                                    offsetY: img.image.offsetY != null? img.image.offsetY : 0,
                                    angle: img.image.angle != null ? img.image.angle: 0,
                                    scale: img.image.scale != null? img.image.scale: 1,
                                    index: img.image.index ? img.image.index : vm.aid ? '' : i,
                                    width: img.image.width != null ? img.image.width: '',
                                    height: img.image.height != null ? img.image.height: '',
                                    scaleW: img.image.scaleW != null? img.image.scaleW :1,
                                    scaleH: img.image.scaleH != null ?  img.image.scaleH: 1,
                                    imgSize: img.image.imgSize != null? img.image.imgSize: '',
                                    lowSize: img.image.lowSize == false ? false : true,
                                    resourceId: img.image ? img.image.id ? img.image.id : img.image.resourceId ? img.image.resourceId : '' : ''
                                };
                            }

                        });
                        if (!$rootScope.template && res.pages.page[index]) {
                            res.pages.page[index].textboxes = angular.copy(page.textboxes);
                            res.pages.page[index].imagearts = angular.copy(page.imagearts);
                        }
                    });
                }

                // 单张导入
                if (single) {
                    var img = res.pages.page[pageIndex].imageboxes.imagebox[pageI];
                    if (!img.image) {
                        img.image = {}
                    } else {
                        $scope.imgIndex = img.image.index;
                        $scope.imgId = img.image.resourceId;
                    }
                    img.image = imgParamter(img, single, proIndex);
                    img.image.resource.identifier = single.identifier;
                }
            }

            vm.article.document = {
                pages: res.pages
            };
            reviewDoc = vm.article.document.pages;

            vm.edit = true;     //判断是否是模板上的文字
            localStorage.removeItem("go");
            return res;
        }

        $scope.hideComeIn = function () {
            $scope.isHide = true;
            localStorage.setItem('isHide', true);
        };

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        function imgParamter(img, arr, i) {
            return {
                resource: {
                    identifier: img.image.resource && img.image.resource.identifier != '' ? img.image.resource.identifier : arr.identifier,
                    provider: 'qiniu'
                },
                parameter: arr.parameter,
                offsetX: img.image.offsetX != null ?img.image.offsetX: 0,
                offsetY: img.image.offsetY != null ?img.image.offsetY: 0,
                angle: img.image.angle != null ?img.image.angle: 0,
                scale: img.image.scale != null ?img.image.scale: 1,
                index: i,
                width: arr.width,
                height: arr.height,
                imgSize: arr.imgSize,
                resourceId: arr.id,
                scaleH: img.image.scaleH != null ?img.image.scaleH: 1,
                scaleW: img.image.scaleW != null ?img.image.scaleW: 1,
            };
        }

        function getAll(pages) {
            $q.all([$scope.domains.$promise, vm.platformSetting.$promise]).then(function () {
                //设置title
                CommonService.setDocumentTitle('制作作品');
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                var marLeft = getSingleLocalStorage('marginLeft');

                $(".carousel5").css({'margin-left': -marLeft + 'px'});
                $rootScope.currentCarouselIndex = Math.abs(parseInt(marLeft / ($scope.dWidth * 0.94)));
                $scope.visibleLeft = $rootScope.currentCarouselIndex ? true : false;
                $scope.visibleRight = $rootScope.currentCarouselIndex + 1 == pages.page.length ? true : false;
                getStickerList();
                getFontList();
                // 当有缓存的时候  （添加照片库进入编辑页面）
                if ($rootScope.template) {
                    if (vm.pid) {
                        $scope.galleryList = JSON.parse(localStorage.getItem('gallery'));
                        gallery = angular.copy($scope.galleryList);
                        reviewDoc = JSON.parse(localStorage.getItem('reviewPages'));
                        // $scope.pageAllPx = JSON.parse(localStorage.getItem('pageAllPx'));

                    } else {
                        $scope.galleryList = JSON.parse(localStorage.getItem('gallery'));
                        reviewDoc = JSON.parse(localStorage.getItem('reviewPages'));
                        // $scope.pageAllPx = JSON.parse(localStorage.getItem('pageAllPx'));
                    }

                    var imagebox = reviewDoc.page[$rootScope.currentCarouselIndex].imageboxes.imagebox;
                    var imageLen = imagebox.length;

                    if (vm.store.bokeh && (single || imagebox[pageI]&&imagebox[pageI].blobImage)) {
                        $(".mode1,.mode2").removeClass('hide1');
                        $(".cut-image").hide();
                    } else {
                        $(".mode1,.mode2").addClass('hide1');
                    }


                    if (single) {

                        var reviewImage = reviewDoc.page[pageIndex].imageboxes.imagebox[pageI];
                        if (!reviewImage.image) {
                            reviewImage.image = pages.page[pageIndex].imageboxes.imagebox[pageI].image
                        }
                        reviewImage.imgUrl = $scope.domain + single.identifier;
                        reviewImage.image.resourceId = single.id;
                        reviewImage.image.resource.identifier = single.identifier;
                        reviewImage.image.parameter = angular.fromJson(reviewImage.image.parameter);
                        reviewImage.image.offsetY = 0;
                        reviewImage.image.offsetX = 0;
                        if (vm.store.bokeh) {
                            reviewImage.blurred = true;
                            reviewImage.blurredRectangle = {
                                x: 0,
                                y: 0,
                                width: reviewImage.geometry.width,
                                height: reviewImage.geometry.height
                            }
                        }

                        if ($scope.galleryList.length) {
                            angular.forEach($scope.galleryList, function (arr, i) {
                                if (arr.id == $scope.imgId) {
                                    arr.useNum == 0 ? 0 : arr.useNum--;
                                    gallery = angular.copy($scope.galleryList);
                                    setGalleryStorage();
                                }
                            })
                        }

                        localStorage.removeItem("single");
                    }
                    setImageWidth();
                    showPages();
                    $scope.showImageMenu = true;
                    $scope.showBottomMenu = false;
                    if (imageLen < 2) {
                        $(".change").hide();
                    } else {
                        $(".change").show();
                    }

                    if (imageLen && !imagebox[0].image) {
                        $(".change,.cut-image").hide();
                    }

                    tipStatus(true);

                    for (var i = 0; i < reviewDoc.page.length; i++) {
                        getPageToPx(reviewDoc.page[i], i, true, true);
                    }
                    return;
                }
                reviewDoc = angular.copy(pages);
                // console.log(reviewDoc);
                // localStorage.setItem('pageAllMm', JSON.stringify(pages));
                //取得xml文档内容

                //安卓事件
                if (!window.__wxjs_is_wkwebview) {
                    var x = 0;
                    for (var t = 0; t < reviewDoc.page.length; t += 2) {
                        x++;
                        if (reviewDoc.page.length - t > 2) {
                            distributeFor(t, t + 2, reviewDoc, x);
                        } else {
                            distributeFor(t, reviewDoc.page.length, reviewDoc, x);
                        }
                    }

                } else {
                    for (var i = 0; i < reviewDoc.page.length; i++) {
                        getPageToPx(reviewDoc.page[i], i, true, true);
                    }
                }

            });
        }

        function getAll2(pages) {
            localStorage.setItem('pageAllMm', JSON.stringify(pages));
            var x = 0;
            for (var t = 0; t < pages.page.length; t += 2) {
                x++;
                if (pages.page.length - t > 2) {
                    distributeFor2(t, t + 2, pages, x);
                } else {
                    distributeFor2(t, pages.page.length, pages, x);
                }
            }
        }

        function distributeFor2(l, len, pages, x) {
            setTimeout(function () {
                for (var j = l; j < len; j++) {
                    getPageToPx2(pages.page[j], j, true, pages.page.length);
                }
            }, 1000 * x);
        }

        function distributeFor(l, len, pages, x) {
            setTimeout(function () {
                for (var j = l; j < len; j++) {
                    getPageToPx(pages.page[j], j, true, true);
                }
            }, 1000 * x);
        }


        function getSingleLocalStorage(value) {
            if(localStorage.getItem(value) == null){
                return 0;
            }
            return localStorage.getItem(value);
        }

        function getPageToPx2(page, index, isFirstIn, pageSize) {
            var isMaxWidth = getScale($scope.dWidth, $scope.dHeight, page.mediabox.width, page.mediabox.height, index);

            var styleScale = 1;
            var temp = {
                bgImg: page.background && page.background.resource.identifier ? $scope.domain + page.background.resource.identifier + '?imageMogr2/thumbnail/!40p' : '',
                background: angular.copy(page.background),
                mediabox: angular.copy(page.mediabox),
                trimbox: angular.copy(page.trimbox),     //裁切线
                textboxes: {textbox: []},
                imageboxes: angular.copy(page.imageboxes),
                imagearts: {imageart: []},
                isMaxWidth: isMaxWidth,
                spread: page.spread,
                backup: page.backup,
                uuid: page.uuid,
                count: page.count,
                widthM: page.mediabox.width,
                heightM: page.mediabox.height,
                width: unitTransform(page.mediabox.width),
                height: unitTransform(page.mediabox.height),
                selectImgIndex: 0,
                currentZindex: -1,
                centerRotation: page.centerRotation ? true : false
            };
            if (isMaxWidth) {
                temp.styleScale = styleScale = ($scope.dWidth * 0.94 / (unitTransform(page.mediabox.width)));
                temp.styleScale2 = styleScale;
            } else {
                temp.styleScale = styleScale = (($scope.dHeight - $scope.tHeight) / (unitTransform(page.mediabox.height)));
                temp.styleScale2 = styleScale;
            }

            //得到压缩比例后的最高page的高度

            if (page.barcode) {
                temp.barcode = getGeometry(page.barcode, temp);
                temp.barcodeM = angular.copy(page.barcode);
            }
            if (page.imagearts) {
                angular.forEach(page.imagearts.imageart, function (art, i) {
                    temp.imagearts.imageart[i] = {
                        geometry: getGeometry(art.geometry, temp),
                        resource: angular.copy(art.resource),
                        type: art.type,
                        rotation: {
                            angle: -art.rotation.angle
                        },
                        heightM: art.geometry.height,
                        widthM: art.geometry.width,
                        XM: art.geometry.x,
                        YM: art.geometry.y,
                        zindex: i,
                        imgUrl: art.resource.provider == 'qiniu' ? $scope.domain + art.resource.identifier : art.resource.identifier
                    };
                });
            }
            if (page.imageboxes.imagebox.length > 0) {
                angular.forEach(page.imageboxes.imagebox, function (img, i) {
                    var maskUrl = 'content/images/timg.jpg';
                    temp.imageboxes.imagebox[i] = {
                        geometry: getGeometry(img.geometry, temp),
                        mask: angular.copy(img.mask),
                        imgUrl: 'content/images/timg.jpg',
                        rotation: angular.copy(img.rotation),
                        heightM: img.geometry.height,
                        widthM: img.geometry.width,
                        offsetXM: img.geometry.x,
                        offsetYM: img.geometry.y,
                        blobImage: img.blobImage != null ?img.blobImage : ''
                    };

                    var imagebox = temp.imageboxes.imagebox[i];
                    if (img.mask.resource.identifier) {
                        maskUrl = $scope.domain + img.mask.resource.identifier;
                    }
                    var reviewImage = page.imageboxes.imagebox[i];

                    reviewImage.maskUrl = imagebox.maskUrl = maskUrl;

                    setTimeout(function () {
                        maskScale2(imagebox, index, i, maskUrl);
                    },1000);

                });
            }
            if (page.textboxes.textbox.length > 0) {
                angular.forEach(page.textboxes.textbox, function (text, i) {
                    temp.textboxes.textbox[i] = {
                        geometry: getGeometry(text.geometry, temp),
                        identifier: text.identifier,
                        style: angular.copy(text.style),
                        rotation: {
                            angle: -text.rotation.angle
                        },
                        text: text.text,
                        heightM: text.geometry.height,
                        widthM: text.geometry.width,
                        xM: text.geometry.x,
                        yM: text.geometry.y,
                        styles: 'NORMAL',
                        type: text.type,
                        status: text.status,
                        templateType: vm.edit ? text.type : '',
                        zindex: i
                    };
                    var tempText = temp.textboxes.textbox[i];
                    if (tempText.style.bold && tempText.style.italic) {
                        tempText.styles = 'BOLD_ITALIC';
                    } else if (tempText.style.bold) {
                        tempText.styles = 'BOLD';
                    } else if (tempText.style.italic) {
                        tempText.styles = 'ITALIC';
                    } else {
                        tempText.styles = 'NORMAL';
                    }
                    toImg(tempText, temp.styleScale, index, i);
                });
            }

            if (isFirstIn) {
                $scope.pageAllPx.page[index] = temp;
            }
            if (index == pageSize - 1) {
                if ($scope.status == 'make') {
                    // console.log(reviewDoc);
                    // console.log($scope.pageAllPx);
                    localStorage.setItem('pageAllPx', JSON.stringify($scope.pageAllPx));
                    setUlWidth();
                }
                // if(isFirstIn&&$scope.status == 'view'&&!window.__wxjs_is_wkwebview){
                //     $scope.$digest();
                // }
            }
        }

        function getPageToPx(page, index, flag, isFirstIn) {
            $scope.template.zindex = 0;
            var isMaxWidth = getScale($scope.dWidth, $scope.dHeight, page.mediabox.width, page.mediabox.height, index);
            var temp = [];

            $scope.template.pageI = 0;

            var thumbnailH = 0, styleScale = 1;
            temp = {
                bgImg: page.background && page.background.resource.identifier ? $scope.domain + page.background.resource.identifier + '?imageMogr2/thumbnail/!40p' : '',
                background: angular.copy(page.background),
                mediabox: angular.copy(page.mediabox),
                trimbox: angular.copy(page.trimbox),     //裁切线
                textboxes: {textbox: []},
                imageboxes: angular.copy(page.imageboxes),
                imagearts: {imageart: []},
                isMaxWidth: isMaxWidth,
                spread: page.spread,
                backup: page.backup,
                uuid: page.uuid,
                count: page.count,
                widthM: page.mediabox.width,
                heightM: page.mediabox.height,
                width: unitTransform(page.mediabox.width),
                height: unitTransform(page.mediabox.height),
                selectImgIndex: 0,
                currentZindex: -1,
                centerRotation: page.centerRotation ? true : false
            };
            if (isMaxWidth) {
                $scope.template.ulWidth += $scope.dWidth * 0.94;
                temp.styleScale = styleScale = ($scope.dWidth * 0.94 / (unitTransform(page.mediabox.width)));
                temp.styleScale2 = styleScale;
                thumbnailH = $scope.dWidth * 0.94 * unitTransform(page.mediabox.height) / unitTransform(page.mediabox.width);
            } else {
                $scope.template.ulWidth += ($scope.dHeight - $scope.tHeight) * unitTransform(page.mediabox.width) / unitTransform(page.mediabox.height);
                temp.styleScale = styleScale = (($scope.dHeight - $scope.tHeight) / (unitTransform(page.mediabox.height)));
                temp.styleScale2 = styleScale;
                thumbnailH = ($scope.dHeight - $scope.tHeight) * unitTransform(page.mediabox.width) / unitTransform(page.mediabox.height);
            }
            if ($scope.status == 'view') {
                if (index == 0 || index == $scope.PAGELEN - 1) {
                    temp.styleScale = ($scope.dWidth * 0.6 / (unitTransform(page.mediabox.width)));
                } else {
                    temp.styleScale = ($scope.dWidth * 0.45 / (unitTransform(page.mediabox.width)));
                }
            }
            //得到压缩比例后的最高page的高度
            if ($scope.template.maxPageHeight < thumbnailH) {
                $scope.template.maxPageHeight = thumbnailH;
            }
            if (page.barcode) {
                temp.barcode = getGeometry(page.barcode, temp);
                temp.barcodeM = angular.copy(page.barcode);
            }
            if (page.imagearts) {
                angular.forEach(page.imagearts.imageart, function (art, i) {
                    temp.imagearts.imageart[i] = {
                        geometry: getGeometry(art.geometry, temp),
                        resource: angular.copy(art.resource),
                        type: art.type,
                        rotation: {
                            angle: -art.rotation.angle
                        },
                        heightM: art.geometry.height,
                        widthM: art.geometry.width,
                        XM: art.geometry.x,
                        YM: art.geometry.y,
                        zindex: i,
                        imgUrl: art.resource.provider == 'qiniu' ? $scope.domain + art.resource.identifier : art.resource.identifier
                    };
                });
            }
            if (page.imageboxes.imagebox.length > 0) {
                angular.forEach(page.imageboxes.imagebox, function (img, i) {
                    var maskUrl = 'content/images/timg.jpg';
                    if (flag) {
                        temp.imageboxes.imagebox[i] = {
                            geometry: getGeometry(img.geometry, temp),
                            mask: angular.copy(img.mask),
                            imgUrl: 'content/images/timg.jpg',
                            rotation: angular.copy(img.rotation),
                            heightM: img.geometry.height,
                            widthM: img.geometry.width,
                            offsetXM: img.geometry.x,
                            offsetYM: img.geometry.y,
                            blobImage: img.blobImage != null? img.blobImage : '',
                            blurred: img.blurred == true ? true : false
                        };
                        var imagebox = temp.imageboxes.imagebox[i];
                        if (img.mask.resource.identifier) {
                            maskUrl = $scope.domain + img.mask.resource.identifier;
                        }
                        // page   reviewDoc.page[index]
                        var reviewImage = page.imageboxes.imagebox[i];

                        reviewImage.maskUrl = imagebox.maskUrl = maskUrl;

                        if (img.image && img.image.resource && img.image.resource.identifier) {       //qiniu
                            imagebox.image = {
                                resource: {
                                    identifier: img.image.resource.identifier!= null ?img.image.resource.identifier: '',
                                    provider: img.image.resource.provider != null ? img.image.resource.provider: 'qiniu'
                                },
                                filterResource: {
                                    identifier: img.image.filterResource ? img.image.filterResource.identifier : '',
                                    provider: img.image.filterResource ? img.image.filterResource.provider : 'qiniu'
                                },
                                width: img.image.width,
                                height: img.image.height,
                                imgSize: img.image.imgSize,
                                index: img.image.index,
                                rotation: angular.copy(img.rotation),
                                parameter: angular.fromJson(img.image.parameter) != null ? angular.fromJson(img.image.parameter): [],
                                filter: img.image.filter != null ?  img.image.filter : '',
                                offsetX: img.blobImage ? img.image.offsetX : (unitTransform(img.image.offsetX) * temp.styleScale2),
                                offsetY: img.blobImage ? img.image.offsetY : (unitTransform(img.image.offsetY) * temp.styleScale2),
                                angle: img.image.angle != null ? img.image.angle: 0,
                                scale: img.image.scale != null? img.image.scale: 1,
                                resourceId: img.image.resourceId != null ? img.image.resourceId: '',
                                scaleW: 1,
                                scaleH: 1
                            };

                            imagebox.blurredRectangle = {
                                x: 0,
                                y: 0,
                                width: 0,
                                height: 0
                            };


                            imagebox.adaptationW = img.blobImage ? img.adaptationW : '';
                            imagebox.imgUrl = $scope.domain + img.image.resource.identifier;
                            imgScale(imagebox, index, i, styleScale, temp.styleScale, imagebox.image.offsetX, imagebox.image.offsetY);
                        } else {
                            maskScale(imagebox, index, i, maskUrl);
                        }
                    } else {
                        var imagebox = temp.imageboxes.imagebox[i];
                        imagebox.geometry = getGeometry(img.geometry, temp);
                        imagebox.widthM = img.geometry.width;
                        imagebox.heightM = img.geometry.height;
                        imagebox.offsetXM = img.geometry.x;
                        imagebox.offsetYM = img.geometry.y;
                        if (img.blurred) {
                            var w = imagebox.image.width;
                            var h = imagebox.image.height;

                            if (img.adaptationW) {
                                imagebox.blurredRectangle = {
                                    height: imagebox.geometry.height,
                                    width: imagebox.geometry.height * w / h,
                                    x: (imagebox.geometry.width - imagebox.geometry.height * w / h) / 2,
                                    y: 0
                                }
                            } else {
                                imagebox.blurredRectangle = {
                                    width: imagebox.geometry.width,
                                    height: imagebox.geometry.width * h / w,
                                    x: 0,
                                    y: (imagebox.geometry.height - imagebox.geometry.width * h / w) / 2
                                }
                            }
                        }
                    }

                });
            }
            if (page.textboxes.textbox.length > 0) {
                angular.forEach(page.textboxes.textbox, function (text, i) {
                    // console.log(text.geometry)
                    temp.textboxes.textbox[i] = {
                        geometry: getGeometry(text.geometry, temp),
                        identifier: text.identifier,
                        style: angular.copy(text.style),
                        rotation: {
                            angle: -text.rotation.angle
                        },
                        text: text.text,
                        heightM: text.geometry.height,
                        widthM: text.geometry.width,
                        xM: text.geometry.x,
                        yM: text.geometry.y,
                        styles: 'NORMAL',
                        type: text.type,
                        status: text.status,
                        templateType: vm.edit ? text.type : '',
                        zindex: i
                    };
                    $scope.template.textI = i;
                    var tempText = temp.textboxes.textbox[i];
                    if (tempText.style.bold && tempText.style.italic) {
                        tempText.styles = 'BOLD_ITALIC';
                    } else if (tempText.style.bold) {
                        tempText.styles = 'BOLD';
                    } else if (tempText.style.italic) {
                        tempText.styles = 'ITALIC';
                    } else {
                        tempText.styles = 'NORMAL';
                    }
                    toImg(tempText, temp.styleScale, index, i);
                });
            }

            // if (isFirstIn) {
            //     $scope.pageAllPx.page[index] = temp;
            // }

            if (!page.backup) {
                $scope.template.pages.page[index] = temp;
            }

            if (index == reviewDoc.page.length - 1) {
                $rootScope.template = $scope.template;
                $scope.isLeftRotation = !$scope.template.pages.page[0].centerRotation;
                // if ($scope.status == 'make') {
                //     localStorage.setItem('pageAllPx', JSON.stringify($scope.pageAllPx));
                //     setUlWidth();
                // }
                if (isFirstIn && $scope.status == 'view' && !window.__wxjs_is_wkwebview) {
                    $scope.$digest();
                }
            }
        }

        function getScale(screenW, screenH, pageW, pageH) {
            if (screenW * 0.94 / (screenH - $scope.tHeight) < pageW / pageH) {
                //宽定，高不定
                return true;
            } else {
                //高定，宽不定
                return false;
            }
        }

        function getGeometry(page, temp) {
            return {
                height: unitTransform(page.height) * temp.styleScale,
                width: unitTransform(page.width) * temp.styleScale,
                x: unitTransform(page.x) * temp.styleScale,
                y: unitTransform(page.y) * temp.styleScale
            }
        }

        function getImageScale(imagebox, images) {
            var w0 = angular.copy(imagebox.width);
            var h0 = angular.copy(imagebox.height);

            if (imagebox.angle == 90 || imagebox.angle == 270) {
                w0 = angular.copy(imagebox.height);
                h0 = angular.copy(imagebox.width);
            }
            var x = 0, y = 0;

            var w = angular.copy(w0);
            var h = angular.copy(h0);
            var maskWidth = images.width;
            var maskHight = images.height;
            var isWidth = true;
            if ((w0 <= h0 || maskWidth <= maskHight) && (w0 / h0 < maskWidth / maskHight) || w0 >= h0 && maskWidth >= maskHight && (w0 / h0 < maskWidth / maskHight)) {
                w0 = maskWidth;
                h0 = maskWidth * h / w;
                isWidth = true;
            } else {
                h0 = maskHight;
                w0 = maskHight * w / h;
                isWidth = false;
            }

            var scaleW = w0 / w;
            var scaleH = h0 / h;
            x = x / scaleW;
            y = y / scaleH;
            return {
                isWidth: isWidth,
                nWidth: w0,
                nHeight: h0,
                scaleW: scaleW,
                scaleH: scaleH,
                x: x,
                y: y
            }
        }

        function maskScale2(imagebox, index, id, maskUrl) {
            // console.log(maskUrl)
            var w = imagebox.geometry.width;
            var h = imagebox.geometry.height;
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = 'content/images/timg.jpg';
            newImg.onload = function () {
                $scope.$digest();
                // console.log($(".imgBoxTemp" + index + '' + id))
                maskd($(".imgBoxTemp" + index + '' + id), 'content/images/timg.jpg', maskUrl, 0, 0, w, h, 1, 1, 1, true);
            };

        }

        function maskScale(imagebox, index, id, maskUrl) {
            var w = imagebox.geometry.width;
            var h = imagebox.geometry.height;
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = 'content/images/timg.jpg';
            newImg.onload = function () {
                $scope.$digest();
                maskd($(".imgBox" + index + '' + id), 'content/images/timg.jpg', maskUrl, 0, 0, w, h, 1, 1, 1, true, function (srcs) {
                    f++;
                    $scope.$digest();
                    if (f >= vm.counts) {
                        showPages();
                    }
                });
            };

        }

        var f = 0;

        function imgScale(imagebox, index, id, styleScaleBig, styleScaleSmall, x, y) {
            var scaleW = 1, scaleH = 1;

            var reviewPage = reviewDoc.page[index].imageboxes.imagebox[id];

            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = imagebox.imgUrl + '?imageMogr2/strip/rotate/0/thumbnail/!40p';
            newImg.onload = function () {

                var w = imagebox.image.width = newImg.width;
                var h = imagebox.image.height = newImg.height;

                if (imagebox.image.angle == 90 || imagebox.image.angle == 270) {
                    w = angular.copy(newImg.height);
                    h = angular.copy(newImg.width);
                }

                var wOrigin = angular.copy(w);
                var hOrigin = angular.copy(h);

                var maskWidth = imagebox.geometry.width / styleScaleSmall * styleScaleBig;
                var maskHight = imagebox.geometry.height / styleScaleSmall * styleScaleBig;
                var k = 0;
                if ((wOrigin <= hOrigin || maskWidth <= maskHight) && (wOrigin / hOrigin < maskWidth / maskHight) || wOrigin >= hOrigin && maskWidth >= maskHight && (wOrigin / hOrigin < maskWidth / maskHight)) {
                    wOrigin = imagebox.image.imageW = maskWidth;
                    hOrigin = imagebox.image.imageH = maskWidth * h / w;
                    imagebox.adaptationW = true;
                    if (imagebox.blurred) {
                        imagebox.blurredRectangle.height = imagebox.geometry.height;
                        imagebox.blurredRectangle.width = imagebox.geometry.height * w / h;
                        imagebox.blurredRectangle.x = (imagebox.geometry.width - imagebox.blurredRectangle.width) / 2;
                    }

                    k = 1;
                } else {
                    hOrigin = imagebox.image.imageH = maskHight;
                    wOrigin = imagebox.image.imageW = maskHight * w / h;
                    imagebox.adaptationW = false;
                    if (imagebox.blurred) {
                        imagebox.blurredRectangle.width = imagebox.geometry.width;
                        imagebox.blurredRectangle.height = imagebox.geometry.width * h / w;
                        imagebox.blurredRectangle.y = (imagebox.geometry.height - imagebox.blurredRectangle.height) / 2;
                    }

                    k = 2;
                }

                scaleW = wOrigin / w;
                scaleH = hOrigin / h;
                if (!imagebox.blobImage) {
                    x = x / scaleW;
                    y = y / scaleH;
                }

                imagebox.image.scaleW = scaleW;
                imagebox.image.scaleH = scaleH;

                var parameters = imagebox.image.parameter;

                if (vm.pid && !imagebox.blobImage && parameters != []) {
                    if (parameters.length == 1) {
                        if (k == 1) {
                            if (parameters[0].y / 2.5 * scaleH + parameters[0].height / 2.5 * scaleH > maskHight) {
                                y = -(parameters[0].y / 2.5 * scaleH + parameters[0].height / 2.5 * scaleH - maskHight) / scaleH;
                            }
                        } else {
                            if (parameters[0].x / 2.5 * scaleW + parameters[0].width / 2.5 * scaleW > maskWidth) {
                                x = -(parameters[0].x / 2.5 * scaleW + parameters[0].width / 2.5 * scaleW - maskWidth) / scaleW;
                            }
                        }
                    } else {
                        if (k == 1) {
                            //高图
                            //按照Y轴排序
                            // parameters.sort(sortY);
                            parameters.sort(compare('y'));
                            for (var t = parameters.length - 1; t >= 0; t--) {
                                if (parameters[t].y / 2.5 * scaleH + parameters[t].height / 2.5 * scaleH > maskHight) {
                                    var h = parameters[t].y / 2.5 * scaleH + parameters[t].height / 2.5 * scaleH - maskHight;
                                    //判断第一张头像是否超出显示范围
                                    if (parameters[0].y / 2.5 * scaleH - h >= 0) {
                                        y = -h / scaleH;
                                    }
                                }
                            }
                        } else {
                            //宽图
                            //按照X轴排序
                            // parameters.sort(sortX);
                            parameters.sort(compare('x'));
                            for (var t = parameters.length - 1; t >= 0; t--) {
                                if (parameters[t].x / 2.5 * scaleW + parameters[t].width / 2.5 * scaleW > maskWidth) {
                                    var w = parameters[t].x / 2.5 * scaleW + parameters[t].width / 2.5 * scaleW - maskWidth;
                                    if (parameters[0].x / 2.5 * scaleW - w >= 0) {
                                        x = -w / scaleW;
                                    }
                                }
                            }
                        }
                    }
                }

                getDpi(imagebox, index, id);

                imagebox.image.offsetX = x;
                imagebox.image.offsetY = y;

                reviewPage.adaptationW = imagebox.adaptationW;
                reviewPage.image = angular.copy(imagebox.image);
                $scope.$digest();
                reviewPage.imgUrl = imagebox.imgUrl;
                reviewPage.blurred = imagebox.blurred;
                reviewPage.blurredRectangle = imagebox.blurredRectangle;

                var img = imagebox.imgUrl + '?imageMogr2/strip/rotate/' + imagebox.image.angle + '/thumbnail/!40p';

                if (imagebox.blurred) {
                    maskd3($(".imgBoxSmall" + index + '' + id), img, imagebox, function () {
                        $scope.$digest();
                    });
                    img = img + '/blur/40x1000';
                }

                maskd($(".imgBox" + index + '' + id), img, imagebox.maskUrl, x, y, maskWidth, maskHight, scaleW, scaleH, imagebox.image.scale, false, function (src) {
                    f++;
                    imagebox.blobImage = true;
                    reviewDoc.page[index].imageboxes.imagebox[id].blobImage = true;
                    $scope.$digest();
                    singleToRemember();
                    if (f >= vm.counts) {
                        showPages();
                    }
                });
            };
        }

        function maskd3(canvas, src, imagebox, callback) {
            var imagecanvas = canvas[0];
            var imagecontext = imagecanvas.getContext('2d');
            var c1 = canvas[1];
            var ctx1 = c1.getContext('2d');

            var geo = imagebox.geometry;
            var blurredRec = imagebox.blurredRectangle;

            imagecanvas.width = c1.width = geo.width * 2;
            imagecanvas.height = c1.height = geo.height * 2;
            imagecontext.scale(2, 2);

            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = src;
            newImg.onload = function () {
                newImg.onload = null;
                newImg.width = blurredRec.width;
                newImg.height = blurredRec.height;
                var masks = document.createElement('img');
                masks.crossOrigin = "Anonymous";
                masks.src = imagebox.maskUrl;

                masks.onload = function () {
                    // masks.width = geo.width;
                    // masks.height = geo.height;
                    // imagecanvas.width = c1.width = geo.width;
                    // imagecanvas.height = c1.height = geo.height;

                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, geo.width, geo.height);
                    imagecontext.globalCompositeOperation = 'source-in';

                    imagecontext.drawImage(newImg, blurredRec.x, blurredRec.y, blurredRec.width, blurredRec.height);
                    ctx1.drawImage(imagecanvas, 0, 0);
                    if (canvas.length > 2) {
                        var c2 = canvas[2];
                        var ctx2 = c2.getContext('2d');
                        c2.width = geo.width * 2;
                        c2.height = geo.height * 2;
                        ctx2.drawImage(imagecanvas, 0, 0);
                        if (canvas[3]) {
                            var c3 = canvas[3];
                            var ctx3 = c3.getContext('2d');
                            c3.width = geo.width * 2;
                            c3.height = geo.height * 2;
                            ctx3.drawImage(imagecanvas, 0, 0);
                        }
                    }

                    if (!callback) {
                        return;
                    }
                    callback.call(this, '');
                };
            };
        }

        $scope.modeIn = function () {
            changeBlurred(true);
        };
        $scope.modeOut = function () {
            changeBlurred(false);
        };

        function changeBlurred(modeIn) {
            getCurrentImage();
            var imagebox = $scope.currentImage;

            if (modeIn) {
                if (!imagebox.blurred) {
                    return
                }
                // 大图片模式
                imagebox.blurred = false;
                $(".cut-image").show();
            } else {
                if (imagebox.blurred) {
                    return
                }
                // 虚化模式
                imagebox.blurred = true;
                $(".cut-image").hide();
            }

            var styleScale = $scope.currentPage.styleScale;
            imgScale(imagebox, $scope.index, $scope.i, styleScale, styleScale, 0, 0);
        }

        function maskd(canvas, src, maskUrl, x0, y0, width, height, scaleW, scaleH, scale, hasPic, callback) {

            // var imagecanvas = document.createElement('canvas');
            if (canvas.length == 0) {
                return;
            }
            var imagecanvas = canvas[0];
            var imagecontext = imagecanvas.getContext('2d');
            imagecanvas.width = width * 2;
            imagecanvas.height = height * 2;
            imagecontext.scale(2, 2);

            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = src;
            newImg.onload = function () {
                newImg.onload = null;
                newImg.width = width;
                newImg.height = height;
                var masks = document.createElement('img');
                masks.crossOrigin = "Anonymous";
                masks.src = maskUrl;

                masks.onload = function () {
                    masks.width = width;
                    masks.height = height;
                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = 'source-in';
                    if (!hasPic) {
                        imagecontext.scale(scaleW, scaleH);
                    }
                    imagecontext.scale(scale, scale);

                    if (!hasPic) {
                        imagecontext.drawImage(newImg, x0, y0);
                    } else {
                        imagecontext.drawImage(newImg, x0, y0, width, height);
                    }

                    if (canvas.length != 1) {
                        var c1 = canvas[1];
                        var ctx1 = c1.getContext('2d');
                        var c2 = canvas[2];
                        var ctx2 = c2.getContext('2d');
                        c1.width = c2.width = width * 2;
                        c1.height = c2.height = height * 2;

                        ctx1.drawImage(imagecanvas, 0, 0);
                        ctx2.drawImage(imagecanvas, 0, 0);
                        if (canvas[3]) {
                            var c3 = canvas[3];
                            var ctx3 = c3.getContext('2d');
                            c3.width = width * 2;
                            c3.height = height * 2;
                            ctx3.drawImage(imagecanvas, 0, 0);
                        }

                    }

                    if (!callback) {
                        return;
                    }
                    callback.call(this, '');


                };
            };
        }

        function isView() {
            if ($scope.status == 'view') {
                return;
            }
        }

        /**
         * 3种返回状态
         * 1： 平铺页面---->产品选择
         * 2： 图片编辑---->作品制作当前页
         * 3： 作品制作页面---->平铺页面
         */
        $scope.backTo = function () {
            // 平铺页面---->产品选择
            if ($scope.status == 'view') {
                setTimeout(function(){
                    $(".sweet-alert").addClass('whiteBg');
                })
                swal({
                    title: "当前作品还没有保存，确认要回退么",
                    text: "",
                    // imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: "取消",
                    cancelButtonText: "确认",
                    closeOnConfirm: true
                }, function (isConfirm) {
                    setTimeout(function(){
                        $(".sweet-alert").removeClass('whiteBg');
                    },200)
                    if (isConfirm) {
                    } else {
                        if (vm.pid) {
                            var url = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/' + vm.pid + '/productThumbnail';
                        } else {
                            var url = vm.platformSetting.editorNotifyUrl + '/#/' + vm.storeId + '/' + vm.aid + '/view';
                        }
                        window.location.href = url;
                    }
                }, function () {
                });
            }

            getCurrentImage();

            // 图片编辑---->作品制作当前页
            if ($scope.showCut) {
                $scope.showCut = false;
                $scope.showImageMenu = false;
                $scope.showBottomMenu = true;
                $scope.template.pages.page[$scope.index].imageboxes.imagebox = angular.copy($scope.cutPage);
                reviewDoc = $scope.cutPageReview;
                $scope.cutPage = [];
                tipStatus(false);
                $(".edit-head").css({position: 'fixed'});
                return
            }
            // 文字---->作品制作当前页
            if ($scope.showTextModal) {
                $scope.showTextModal = false;
                $scope.showBottomMenu = true;
                tipStatus(false);
                $scope.template.pages.page[$scope.index].textboxes.textbox = angular.copy($scope.textCopy);
                reviewDoc = $scope.textCopyReview;
                $scope.textCopy = [];
                tipStatus(false);
                return
            }

            // 作品制作页面---->平铺页面
            if ($scope.status == 'make') {
                tipStatus(false);
                $scope.showImageMenu = false;
                $scope.showTempMenu = false;
                $scope.isReplaceShow = false;
                $scope.showStickerMenu = false;
                setProductionStorage('view');
                removeSingleStorage();

                if (vm.pid) {
                    reviewDoc = JSON.parse(localStorage.getItem("saveOldPage"));
                    $scope.pageAllPx = JSON.parse(localStorage.getItem("pageAllPx"));
                    $scope.galleryCopy = JSON.parse(localStorage.getItem("galleryCopy"));
                    localStorage.setItem('gallery', JSON.stringify($scope.galleryCopy));
                } else {
                    var isFirstCome = JSON.parse(localStorage.getItem("saveOldPage"));
                    if (!isFirstCome) {
                        setReviewStorage();
                    }
                    reviewDoc = JSON.parse(localStorage.getItem("saveOldPage"));
                    $scope.pageAllPx = JSON.parse(localStorage.getItem("pageAllPx"));
                    $scope.galleryCopy = JSON.parse(localStorage.getItem("galleryCopy"));
                    localStorage.setItem('gallery', JSON.stringify($scope.galleryCopy));
                }


                $ul.css({marginLeft: '0px'});
                $("#small-pic").addClass('hide1');

                backToScrollTop();
                for (var i = 0; i < reviewDoc.page.length; i++) {
                    if (i == $rootScope.currentCarouselIndex) {
                        getPageToPx(reviewDoc.page[i], i, true, false);
                    } else {
                        getPageToPx(reviewDoc.page[i], i, false, false);
                    }
                }

                return;
            }
        };

        function backToScrollTop() {
            // 设置滚动距离
            var viewScrollTop = localStorage.getItem('view-scrollTop') || 0;
            if (!window.__wxjs_is_wkwebview) {
                $('.template-view').css("transform", "translateY(-" + viewScrollTop + "px)");
                setTimeout(function () {
                    $('.template-view').css("transform", "translateY(0px)");
                    $(".edit-page-content")[0].scrollTop = viewScrollTop;
                }, 10);
            } else {
                setTimeout(function () {
                    $(".edit-page-content")[0].scrollTop = viewScrollTop;
                }, 100);
            }

        }

        $scope.isFirstEdit = true;
        if ($scope.status == 'make') {
            $scope.isFirstEdit = false;
        }
        /**
         * 平铺页面点击进入作品制作页
         * @param page  点击页
         * @param index 点击页的下标
         */
        $scope.clickToEdit = function (page, index) {
            $rootScope.currentCarouselIndex = index;

            // if($scope.countN==7&&!vm.guideStep){
            //     return
            // }
            // 当引导页点击“不在提示”以后就不再count++;
            if (!vm.guideStep) {
                $scope.countN++;
                localStorage.setItem('countN', $scope.countN);
            }

            var str = parseInt($('.template-view')[0].style.webkitTransform.replace(/[^0-9]+/g, '') || 0);
            var scrollT = parseInt($(".edit-page-content")[0].scrollTop);

            localStorage.setItem('view-scrollTop', scrollT + str);
            angular.forEach($scope.template.pages.page, function (page, index) {
                angular.forEach(page.imageboxes.imagebox, function (img, i) {
                    var reviewImage = reviewDoc.page[index].imageboxes.imagebox[i];
                    reviewImage.image = angular.copy(img.image);
                    reviewImage.maskUrl = img.maskUrl;
                    if (img.image && img.image.resource && img.image.resource.identifier) {
                        reviewImage.imgUrl = img.imgUrl;
                        reviewImage.blobImage = img.blobImage;
                    }
                })
            });

            setReviewStorage();
            setProductionStorage('make');

            for (var i = 0; i < reviewDoc.page.length; i++) {
                getPageToPx(reviewDoc.page[i], i, false, $scope.isFirstEdit);
            }

            $scope.showBottomMenu = true;
            $scope.showImageMenu = false;

            $ul.css({marginLeft: -($scope.dWidth * 0.94 * index) + 'px'});

            $scope.visibleLeft = $rootScope.currentCarouselIndex ? true : false;
            $scope.visibleRight = $rootScope.currentCarouselIndex + 1 == $scope.template.pages.page.length ? true : false;

            $('.edit-menu-make').addClass('opacity-zero');
            $('.tip-image').removeClass('hide1');

            $scope.isFirstEdit = false;
        };

        /**
         * 切换当前页所使用的模板
         * @param index     点击模板页下标
         * @param page      被点击模板页
         */
        $scope.changeTemp = function (index, page) {
            // 所要展示的page
            var currentInd = $rootScope.currentCarouselIndex;
            // 首页，尾页不交换提示
            if (currentInd == 0 || currentInd == $scope.PAGELEN - 1) {
                $(".text-tip").removeClass('hide1');
                setTimeout(function () {
                    $(".text-tip").addClass('hide1');
                }, 2000);
                return
            }

            var currentPage = angular.copy($scope.template.pages.page[currentInd]);
            // 两个page 相同时不交换
            if (currentPage.uuid == page.uuid) {
                return;
            }

            // 模板选中状态判断条件
            $scope.selectedLi = page.uuid;

            //使用次数+1
            page.count++;

            var pageAllMm = JSON.parse(localStorage.getItem('pageAllMm'));
            var reviewPage = pageAllMm.page[index];
            if (!reviewPage.imagearts) {
                reviewPage.imagearts = {
                    imageart: []
                }
            }
            if (!reviewPage.textboxes) {
                reviewPage.textboxes = {
                    textbox: []
                }
            }

            // 拷贝被点击模板     page不能受影响
            var clickPage = angular.copy(page);

            // 使用次数-1
            for (var i = $scope.pageAllPx.page.length - 1; i >= 0; i--) {
                var element = $scope.pageAllPx.page[i];
                if (reviewDoc.page[currentInd].uuid == element.uuid) {
                    element.count--;
                }
            }

            // 用户添加的"素材"需要留在当前页面
            if (reviewDoc.page[currentInd].imagearts) {
                var imageartR = reviewDoc.page[currentInd].imagearts.imageart;
                var imageartP = $scope.template.pages.page[currentInd].imagearts.imageart;
                for (var i = 0; i < imageartR.length; i++) {
                    if (imageartR[i].type == 'dynamic') {
                        clickPage.imagearts.imageart.push(imageartP[i]);

                        reviewPage.imagearts.imageart.push(imageartR[i]);
                    }
                }
            }

            // 用户添加的"文字"需要留在当前页面
            if (reviewDoc.page[currentInd].textboxes) {
                var textboxR = reviewDoc.page[currentInd].textboxes.textbox;
                var textboxP = $scope.template.pages.page[currentInd].textboxes.textbox;
                for (var j = 0; j < textboxR.length; j++) {
                    if (textboxR[j].type == 'dynamic') {
                        clickPage.textboxes.textbox.push(textboxP[j]);

                        reviewPage.textboxes.textbox.push(textboxR[j]);
                    }
                }
            }

            // 开始交换
            $scope.template.pages.page[currentInd] = angular.copy(clickPage);
            reviewDoc.page[currentInd] = angular.copy(reviewPage);

            $scope.template.pages.page[currentInd].backup = false;
            reviewDoc.page[currentInd].backup = false;

            // 占位框从图片 有---->无 排序
            var arrs = [];
            var currentImages = currentPage.imageboxes.imagebox;
            if (currentImages.length) {
                for (var i = 0; i < currentImages.length; i++) {
                    if (currentImages[i] && currentImages[i].imgUrl && currentImages[i].image) {
                        if (gallery.length && gallery[currentImages[i].image.index]) {
                            gallery[currentImages[i].image.index].useNum == 0 ? 0 : gallery[currentImages[i].image.index].useNum--;
                            // setGalleryStorage();
                        }
                        arrs.push(currentImages[i])
                    }
                }
            }

            var boxes = $scope.template.pages.page[currentInd].imageboxes.imagebox;
            angular.forEach(boxes, function (imagebox, i) {
                if (arrs[i]) {
                    imagebox.imgUrl = arrs[i].imgUrl;
                    imagebox.image = angular.copy(arrs[i].image);

                    reviewDoc.page[currentInd].imageboxes.imagebox[i].imgUrl = angular.copy(arrs[i].imgUrl);
                    reviewDoc.page[currentInd].imageboxes.imagebox[i].image = angular.copy(arrs[i].image);

                    var styleScale = $scope.template.pages.page[currentInd].styleScale;

                    if (vm.store.bokeh) {
                        imagebox.blurred = true;
                        imagebox.blurredRectangle = {
                            x: 0,
                            y: 0,
                            width: imagebox.geometry.width,
                            height: imagebox.geometry.height
                        }
                    }

                    imgScale(imagebox, currentInd, i, styleScale, styleScale, 0, 0);
                    if (imagebox.image && gallery.length && gallery[imagebox.image.index]) {
                        gallery[imagebox.image.index].useNum++;
                    }

                } else {
                    imagebox.imgUrl = null;
                    imagebox.image = null;
                    imagebox.blobImage = null;
                    reviewDoc.page[currentInd].imageboxes.imagebox[i].imgUrl = null;
                    reviewDoc.page[currentInd].imageboxes.imagebox[i].image = null;
                    reviewDoc.page[currentInd].imageboxes.imagebox[i].blobImage = null;

                    // maskScale(imagebox, currentInd, i, imagebox.maskUrl, false);
                    maskScale(imagebox, currentInd, i, imagebox.maskUrl);
                }
            });

            setGalleryStorage();
            singleToRemember();
            localStorage.setItem('reviewPages', JSON.stringify(reviewDoc));
        };

        var moveLaR = function () {
            var page = $scope.template.pages.page[$rootScope.currentCarouselIndex];
            if ((window.orientation == 90 || window.orientation == -90) && vm.flags) {
                $li.removeClass('template-heng').addClass('template-heng2');
                var heights = $scope.dWidth * 0.94 * page.height / page.width - 1;
                $("#slide" + $rootScope.currentCarouselIndex).find(".slide-content").css({'height': heights});
            }
            $li.removeClass('template-heng3');
            tipStatus(false);
            $(".change-btn").hide();
            mL = Math.abs(parseFloat($ul.css('marginLeft')));
            liW = parseFloat($li.css('width'));
            ulW = liW * $scope.PAGELEN;

            $scope.showBottomMenu = true;
            $scope.showImageMenu = false;
        };

        /**
         * 左移按钮
         */
        $scope.pageLeft = function () {
            moveLaR();
            $scope.visibleRight = false;
            if (mL <= liW + 10) {
                $scope.visibleLeft = false;
            }
            var index = $rootScope.currentCarouselIndex;
            $rootScope.currentCarouselIndex--;

            $ul.css({opacity: 0}).removeClass('fade-out').removeClass('is-paused');
            $ul.css({marginLeft: -(mL - liW) + 'px'});
            setTimeout(function () {
                $ul.addClass('is-paused').addClass('fade-out').css({opacity: 1});
            }, 700);

            setReviewStorage();
            setGalleryStorage();
            localStorage.setItem("marginLeft", $scope.dWidth * 0.94 * $rootScope.currentCarouselIndex);

            getPageToPx(reviewDoc.page[index], index, true, false);

        };

        /**
         * 右移按钮
         */
        $scope.pageRight = function () {
            moveLaR();
            $scope.visibleLeft = true;
            if (runR) {
                if (mL + liW >= ulW - liW - 10) {
                    $scope.visibleRight = true;
                }
                var index = $rootScope.currentCarouselIndex;
                $rootScope.currentCarouselIndex++;

                $ul.css({opacity: 0}).removeClass('fade-out').removeClass('is-paused');
                $ul.css({marginLeft: -(mL + liW) + 'px'});
                setTimeout(function () {
                    runR = true;
                    $ul.addClass('is-paused').addClass('fade-out').css({opacity: 1});
                }, 700);
                setReviewStorage();
                setGalleryStorage();

                localStorage.setItem("marginLeft", $scope.dWidth * 0.94 * $rootScope.currentCarouselIndex);

                getPageToPx(reviewDoc.page[index], index, true, false);

            }
            runR = false;
        };

        // 旋转屏幕
        window.addEventListener('orientationchange', function () {
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                if (window.orientation == 180 || window.orientation == 0) {
                    //竖屏
                    $(".page-orientation").addClass('hide1');
                    if ($scope.isSave) {
                        return
                    }
                    orientationChange(false);
                    if (vm.isOrientation) {

                        for (var i = 0; i < reviewDoc.page.length; i++) {
                            getPageToPx(reviewDoc.page[i], i, true, true);
                        }


                        vm.isOrientation = false;
                        return
                    }

                    var mar = ($scope.dWidth * 0.94 - $li.width()) / 2;
                    $li.css({'margin-left': mar}).removeClass('mar-top-0');
                } else if (window.orientation == 90 || window.orientation == -90) {
                    //横屏
                    $(".page-orientation").removeClass('hide1');
                    if ($scope.isSave) {
                        return
                    }
                    orientationChange(true);
                    var mar = ($scope.dWidth * 0.94 - $li.width()) / 2;
                    $li.css({'margin-left': mar}).addClass('mar-top-0');
                }
            }, 500);
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $(".page-orientation").removeClass('hide1');
            vm.flags = false;
            vm.isOrientation = true;
            $(document.body).addClass('bgColor');
        }

        function orientationChange(flag) {
            vm.flags = flag;
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(document.body).toggleClass('bgColor');

            $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});      //设置页面的最小宽度
            localStorage.setItem("marginLeft", $scope.dWidth * 0.94 * $rootScope.currentCarouselIndex);
        }

        /**
         * 放大缩小及删除
         */
        function changeImgOffset(zoomIn) {
            getCurrentImage();
            var imagebox = $scope.currentImage.image;

            var number = 1.2;
            if (zoomIn) {
                imagebox.scale = imagebox.scale ? imagebox.scale * number : number;
            } else {
                if (imagebox.scale == 1) {
                    return;
                }
                imagebox.scale = imagebox.scale / number;
            }

            imagebox.offsetX = 0;
            imagebox.offsetY = 0;
            $("#cut-img").css({left: imgX, top: imgY});

            $scope.moveImg = {
                width: $("#cut-opacity").width(),
                height: $("#cut-opacity").height(),
                angle: imagebox.angle
            };

            getDpi($scope.currentImage, $scope.index, $scope.i);

        }

        function getCurrentImage() {
            $scope.index = $rootScope.currentCarouselIndex != null ?  $rootScope.currentCarouselIndex: 0;
            $scope.i = $scope.template.pages.page[$scope.index].selectImgIndex;
            $scope.imgs = document.getElementById("imgInput" + $scope.index + '' + $scope.i);
            $scope.imgReals = $(".imgBox" + $scope.index + '' + $scope.i); //document.getElementById("imgInputReal" + $scope.index + '' + $scope.i);
            $scope.currentPage = $scope.template.pages.page[$scope.index];
            $scope.currentImage = $scope.currentPage.imageboxes.imagebox[$scope.i];
            $scope.currentText = $scope.currentPage.textboxes.textbox;
            // $scope.reviewPage = reviewDoc.page[$scope.index];
            // $scope.reviewImage = $scope.reviewPage.imageboxes.imagebox[$scope.i];
        }

        var boxW = 0, boxH = 0, picW = 0, picH = 0, scale = 0;

        function getDpi(box, index, i) {
            boxW = box.widthM;
            boxH = box.heightM;
            picW = box.image.width * 2.5;
            picH = box.image.height * 2.5;
            scale = box.image.scale;
            var x = 0, y = 0;
            // 宽度适配
            if (box.adaptationW) {
                x = picW / (boxW / 25.4) / scale;
                y = boxH * picW / boxW / (boxH / 25.4) / scale;
            } else {
                x = boxW * picH / boxH / (boxW / 25.4) / scale;
                y = picH / (boxH / 25.4) / scale;
            }
            if (x < 150 || y < 150) {
                box.image.lowSize = true;
            } else {
                box.image.lowSize = false;
            }
            reviewDoc.page[index].imageboxes.imagebox[i].image = angular.copy(box.image);
        }

        $scope.zoomIn = function () {
            changeImgOffset(true);
        };

        $scope.zoomOut = function () {
            changeImgOffset(false);
        };

        var imgX = parseFloat($(".cut-position").css('left'));
        var imgY = parseFloat($(".cut-position").css('top'));
        /**
         * 进入图片编辑模式
         */
        $scope.cutImage = function () {
            getCurrentImage();
            $scope.showCut = true;
            $scope.showImageMenu = false;
            var box = $scope.currentImage;

            var ww = angular.copy(box.geometry.width);
            var hh = angular.copy(box.geometry.height);
            $scope.cutPage = angular.copy($scope.currentPage.imageboxes.imagebox);
            $scope.cutPageReview = angular.copy(reviewDoc);
            var s = box.maskUrl;

            if (ww > hh) {
                ww = $scope.dWidth * 0.8;
                hh = ww / box.geometry.width * box.geometry.height;
            } else {
                hh = ($scope.dHeight - 50) * 0.8;
                ww = hh / box.geometry.height * box.geometry.width;
            }
            if (ww > $scope.dWidth * 0.8) {
                ww = $scope.dWidth * 0.8;
                hh = ww / box.geometry.width * box.geometry.height;
            }
            if (hh > ($scope.dHeight - 50) * 0.8) {
                hh = ($scope.dHeight - 50) * 0.8;
                ww = hh / box.geometry.height * box.geometry.width;
            }

            var imageArr = getImageScale(box.image, box.geometry);

            if (box.adaptationW) {
                $scope.wOpacity = ww;
                $scope.hOpacity = '';
            } else {
                $scope.hOpacity = hh;
                $scope.wOpacity = '';
            }

            if (box.image.angle == 90 || box.image.angle == 270) {
                if (imageArr.isWidth) {
                    $scope.w = ww;
                    $scope.h = '';
                } else {
                    $scope.h = hh;
                    $scope.w = '';
                }
            } else {
                if (box.adaptationW) {
                    $scope.w = ww;
                    $scope.h = '';
                } else {
                    $scope.h = hh;
                    $scope.w = '';
                }
            }

            $scope.moveGeo = {
                width: ww,
                height: hh,
                scale: ww / box.geometry.width
            };
            imgX = ($scope.dWidth - ww) / 2;

            $scope.t = imgY;
            $scope.l = imgX;

            ImageService.convertImgToBase64(s, function (s) {
                var imagecanvas = document.createElement('canvas');
                var imagecontext = imagecanvas.getContext('2d');
                // var img = document.createElement('img');
                // img.crossOrigin = "Anonymous";
                // img.src = 'content/images/zhezhao1.png';
                var newImg = document.createElement('img');
                newImg.crossOrigin = "Anonymous";
                newImg.src = 'content/images/zhezhao1.png';
                newImg.onload = function () {
                    newImg.onload = null;
                    var masks = document.createElement('img');
                    masks.src = s;
                    masks.crossOrigin = "anonymous";
                    masks.onload = function () {
                        masks.onload = null;
                        imagecanvas.width = $scope.dWidth;
                        imagecanvas.height = $scope.dHeight - 50;
                        imagecontext.drawImage(masks, $scope.l, $scope.t, ww, hh);
                        imagecontext.globalCompositeOperation = 'source-out';
                        imagecontext.drawImage(newImg, 0, 0, $scope.dWidth, $scope.dHeight - 50);
                        $("#cut-mask").attr('src', imagecanvas.toDataURL());
                        $scope.imgW = ww;
                        $scope.imgH = hh;
                        $scope.moveImg = {
                            width: $("#cut-opacity").width(),
                            height: $("#cut-opacity").height(),
                            angle: box.image.angle
                        };

                        var coordX = box.image.offsetX * box.image.scale * box.image.scaleW * $scope.moveGeo.scale;
                        var coordY = box.image.offsetY * box.image.scale * box.image.scaleH * $scope.moveGeo.scale;
                        $("#cut-img").css({left: coordX + imgX, top: coordY + imgY});

                        $scope.$digest();
                    };
                    masks.src = s;
                };
                newImg.src = 'content/images/zhezhao1.png';
            })
        };

        /**
         * 拖拽编辑的图片
         */
        window.startEdit = function (e) {
            fn(e);
            var box = $scope.currentImage;
            selectImgBoxes($scope.i);
            $scope.imageArr = getImageScale($scope.moveImg, $scope.moveGeo);
            dragBox.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragBox.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragBox.coordX = box.image.offsetX * box.image.scaleW * box.image.scale * $scope.moveGeo.scale * $scope.imageArr.scaleW;
            dragBox.coordY = box.image.offsetY * box.image.scaleH * box.image.scale * $scope.moveGeo.scale * $scope.imageArr.scaleH;
            drag = true;
        };

        window.dragEdit = function (e) {
            getCurrentImage();
            var box = $scope.currentImage;

            //乘以放大缩小倍数后的宽高
            var orignW = $scope.imageArr.nWidth * box.image.scale;
            var orignH = $scope.imageArr.nHeight * box.image.scale;
            var isWidth = $scope.imageArr.isWidth;
            if (drag) {
                var x = 0, y = 0;
                //手指离开屏幕
                if (!isWidth && box.image.scale == 1) {    //宽图
                    x = (dragBox.coordX + (e.changedTouches[0].clientX - dragBox.clientX)) / $scope.imageArr.scaleW;
                } else if (isWidth && box.image.scale == 1) {     //高图
                    y = (dragBox.coordY + (e.changedTouches[0].clientY - dragBox.clientY)) / $scope.imageArr.scaleH;
                } else if (box.image.scale > 1) {
                    x = (dragBox.coordX + (e.changedTouches[0].clientX - dragBox.clientX)) / $scope.imageArr.scaleW;
                    y = (dragBox.coordY + (e.changedTouches[0].clientY - dragBox.clientY)) / $scope.imageArr.scaleH;
                }

                //向上滑动;
                if (-y >= (orignH - $scope.moveGeo.height)) {
                    y = -(orignH - $scope.moveGeo.height);
                }
                //向左滑动;        <-----
                if (-x >= (orignW - ($scope.moveGeo.width))) {
                    x = -(orignW - ($scope.moveGeo.width));
                }
                //向下滑动;
                if (y >= 0) {
                    y = 0;
                }
                // 向右滑动;    ---->
                if (x >= 0) {
                    x = 0;
                }

                var img = box.imgUrl + '?imageMogr2/strip/rotate/' + box.image.angle + '/thumbnail/!40p';

                $("#cut-img").css({left: x + imgX, top: y + imgY});


                box.image.offsetX = x / box.image.scale / $scope.moveGeo.scale / box.image.scaleW;
                box.image.offsetY = y / box.image.scale / $scope.moveGeo.scale / box.image.scaleH;

                $scope.$digest();
                e.stopPropagation();
                e.preventDefault();
                return false;

            }

        };

        window.stopEdit = function () {
            drag = false;
            dragBox = {};
            $rootScope.template = $scope.template;
        };

        /**
         * 保存所编辑过的图片
         */
        $scope.saveMove = function () {
            getCurrentImage();
            var box = $scope.currentImage;
            $scope.showImageMenu = false;
            $scope.showBottomMenu = true;

            var imageArr = getImageScale(box.image, box.geometry);
            box.image.scaleW = imageArr.scaleW;
            box.image.scaleH = imageArr.scaleH;
            tipStatus(false);

            var reviewPage = reviewDoc.page[$scope.index].imageboxes.imagebox[$scope.i];
            reviewPage.image = angular.copy(box.image);

            var img = box.imgUrl + '?imageMogr2/strip/rotate/' + box.image.angle + '/thumbnail/!40p';

            maskd($scope.imgReals, img, box.maskUrl, box.image.offsetX, box.image.offsetY, box.geometry.width, box.geometry.height, box.image.scaleW, box.image.scaleH, box.image.scale, false, function (src) {
                box.blobImage = true;
                reviewPage.blobImage = true;
                $scope.showCut = false;
                singleToRemember();
                $scope.$digest();
            });
        };

        /**
         * 选择图片
         * @param flag   true: 逆时针旋转    false： 顺时针旋转
         */
        $scope.rotateImage = function (flag) {
            var box = $scope.currentImage;
            if (flag) {
                box.image.angle -= 90;
                if (box.image.angle == -90) {
                    box.image.angle = 270
                }
            } else {
                box.image.angle += 90;
                if (Math.abs(box.image.angle) == 360) {
                    box.image.angle = 0
                }
            }
            $scope.moveImg.angle = box.image.angle;
            $scope.imageArr = getImageScale($scope.moveImg, $scope.moveGeo);

            var imageEdit = getImageScale(box.image, box.geometry);

            box.image.scaleW = imageEdit.scaleW;
            box.image.scaleH = imageEdit.scaleH;

            if (imageEdit.isWidth) {
                $scope.w = $scope.imageArr.nWidth;
                $scope.h = '';
            } else {
                $scope.h = $scope.imageArr.nHeight;
                $scope.w = '';
            }

            box.image.offsetX = 0;
            box.image.offsetY = 0;

            $("#cut-img").css({left: imgX, top: imgY});

            $scope.moveImg = {
                width: $("#cut-opacity").width(),
                height: $("#cut-opacity").height(),
                angle: box.image.angle
            }
        };

        /**
         * 显示图片选择栏
         * @param $event
         */
        $scope.changeImage = function ($event) {
            if ($event) {
                $event.stopPropagation();
            }
            $scope.showImageMenu = !$scope.showImageMenu;
            $scope.showBottomMenu = false;
            $scope.showTempMenu = false;

            $scope.galleryList = JSON.parse(localStorage.getItem('gallery'));

            if (!$scope.galleryList) {
                return
            }
            setImageWidth();
        };

        function setImageWidth() {
            if (!$scope.galleryList) {
                return
            }
            var w = 0;
            var len = $scope.galleryList.length;
            for (var i = 0; i < len; i++) {
                //84+10
                w += 94;
            }
            setTimeout(function () {
                $(".image-ul").width(w + 'px');
            })
        }

        $scope.addGallery = function () {
            getCurrentImage();
            $rootScope.template = $scope.template;

            localStorage.setItem('reviewPages', JSON.stringify(reviewDoc));
            localStorage.setItem('pageAllPx', JSON.stringify($scope.pageAllPx));
            goToChoose($scope.index, $scope.i);
            localStorage.setItem('article-count', vm.counts)
        };

        var dragGal = {};
        window.startChange = function (e) {
            getCurrentImage();

            drag = true;
            $(".image-ul-mask").addClass('hide1');

            var i = e.target.dataset.imgindex;
            $scope.galleryArr = $scope.galleryList[i];
            $scope.galleryChoose = $scope.galleryList[i].identifier;

            $scope.galleryId = i;
            $scope.galleryX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX - 30 : 0;
            $scope.galleryY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY - 30 : 0;
            dragGal.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragGal.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragGal.pageX = e.changedTouches[0].pageX ? e.changedTouches[0].pageX : 0;
            dragGal.pageY = e.changedTouches[0].pageY ? e.changedTouches[0].pageY : 0;
            dragGal.coordX = $scope.galleryX;
            dragGal.coordY = $scope.galleryY;
            $scope.$digest();
            return false;
        };

        window.dragChange = function (e) {
            if (drag) {
                getCurrentImage();

                //手指离开屏幕
                $scope.galleryX = (dragGal.coordX + (e.changedTouches[0].clientX - dragGal.clientX));
                $scope.galleryY = (dragGal.coordY + (e.changedTouches[0].clientY - dragGal.clientY));

                if (Math.abs($scope.galleryY - dragGal.coordY) > 10) {
                    $("#small-pic").removeClass('hide1');
                }

                //向上滑动;
                if ($scope.galleryY <= 0) {
                    $scope.galleryY = 0;
                }
                //向左滑动;        <-----
                if ($scope.galleryX <= 0) {
                    $scope.galleryX = 0;
                }
                //向下滑动;
                if ($scope.galleryY >= $scope.dHeight - 62) {
                    $scope.galleryY = $scope.dHeight - 62;
                }
                // 向右滑动;    ---->
                if ($scope.galleryX >= $scope.dWidth - 62) {
                    $scope.galleryX = $scope.dWidth - 62;
                }
                $scope.$digest();
                return false;
            }
        };

        window.stopChange = function (e) {
            $("#small-pic").addClass('hide1');
            if (!drag) {
                return
            }
            drag = false;
            var image = $scope.currentPage.imageboxes.imagebox;
            var pageL = parseFloat($(".template-carousel").css('left')) + parseFloat($("#slide-li" + $scope.index).css('margin-left'));
            var pageT = parseFloat($(".template-carousel").css('margin-top')) + 112;

            for (var i = 0; i < image.length; i++) {
                var geo = image[i].geometry;
                if ($scope.galleryX > (pageL + geo.x) && $scope.galleryX < (pageL + geo.x + geo.width)) {
                    if ($scope.galleryY > (pageT + geo.y) && $scope.galleryY < (pageT + geo.y + geo.height)) {
                        if (image[i].mask.resource.identifier) {
                            image[i].maskUrl = $scope.domain + image[i].mask.resource.identifier;
                        } else {
                            image[i].maskUrl = 'content/images/timg.jpg';
                        }
                        if ($scope.galleryChoose) {       //qiniu
                            if (!image[i].image) {
                                image[i].image = {
                                    resource: {
                                        identifier: $scope.galleryChoose,
                                        provider: 'qiniu'
                                    },
                                    filterResource: {
                                        identifier: '',
                                        provider: 'qiniu'
                                    },
                                    rotation: {
                                        angle: 0
                                    },
                                    parameter: angular.fromJson($scope.galleryArr.parameter) != null ?angular.fromJson($scope.galleryArr.parameter): [],
                                    filter: '',
                                    offsetX: 0,
                                    offsetY: 0,
                                    angle: 0,
                                    scale: 1,
                                    scaleW: 1,
                                    scaleH: 1,
                                    width: $scope.galleryArr.width,
                                    height: $scope.galleryArr.height,
                                    imgSize: $scope.galleryArr.imgSize
                                }
                            }
                            if (vm.store.bokeh) {
                                image[i].blurred = true;
                                image[i].blurredRectangle = {
                                    x: 0,
                                    y: 0,
                                    width: image[i].geometry.width,
                                    height: image[i].geometry.height
                                }
                            }

                            image[i].imgUrl = $scope.domain + $scope.galleryChoose;
                            image[i].image.resource.identifier = $scope.galleryChoose;
                            image[i].image.imgSize = $scope.galleryArr.imgSize;
                            image[i].image.parameter = angular.fromJson($scope.galleryArr.parameter);

                            var styleScale = $scope.currentPage.styleScale;
                            imgScale(image[i], $scope.index, i, styleScale, styleScale, 0, 0);

                            if (image[i].blurred) {
                                $(".cut-image").hide();
                                $(".mode1,.mode2").removeClass('hide1');
                            } else {
                                $(".cut-image").show();
                            }
                            if (image.length < 2) {
                                $(".change").hide();
                            } else {
                                $(".change").show();
                            }
                        }
                        if ($scope.galleryList.length) {
                            var resourceId = image[i].image.resourceId;
                            if (image[i].image) {
                                for (var j = 0; j < $scope.galleryList.length; j++) {
                                    var arr = $scope.galleryList[j];
                                    if (arr.id == resourceId) {
                                        arr.useNum == 0 ? 0 : arr.useNum--;
                                    }
                                }
                            }

                            $scope.galleryArr.useNum += 1;
                            gallery = $scope.galleryList;
                            image[i].image.index = $scope.galleryId;
                            image[i].image.resourceId = $scope.galleryArr.id;
                            reviewDoc.page[$scope.index].imageboxes.imagebox[i].image = angular.copy(image[i].image);
                            setGalleryStorage();
                        }
                    }
                }


            }
        };

        // 点击显示 占位框中的 切换按钮
        $scope.isReplaceShow = true;
        $scope.replaceImage = function () {
            var index = $rootScope.currentCarouselIndex  != null ? $rootScope.currentCarouselIndex : 0;
            var i = $scope.template.pages.page[index].selectImgIndex != null ?$scope.template.pages.page[index].selectImgIndex: 0;
            if ($scope.isReplaceShow) {
                $(".change-btn").show();
            } else {
                $(".change-btn").hide();
            }
            $("#change-btn" + index + '' + i).hide();
            $scope.isReplaceShow = !$scope.isReplaceShow;
        };

        // 切换占位框图片
        $scope.changeMaskImage = function (index, i) {
            var index0 = $rootScope.currentCarouselIndex != null ?$rootScope.currentCarouselIndex: 0;
            var i0 = $scope.template.pages.page[index].selectImgIndex != null ?$scope.template.pages.page[index].selectImgIndex: 0;

            // 需要切换的占位框
            var box0 = $scope.template.pages.page[index0].imageboxes.imagebox[i0];

            // 被切换的占位框
            var box = $scope.template.pages.page[index].imageboxes.imagebox[i];

            var maskWidth0 = box0.geometry.width;
            var maskHight0 = box0.geometry.height;

            var maskWidth = box.geometry.width;
            var maskHight = box.geometry.height;

            var w0 = box0.image.width;
            var h0 = box0.image.height;

            var w = box.image.width;
            var h = box.image.height;

            getValue(box, w, h, maskWidth0, maskHight0, index0, i0, box0, false, index, i);
            getValue(box0, w0, h0, maskWidth, maskHight, index, i, box, true, index0, i0);

        };

        function getValue(box, w, h, maskWidth, maskHight, index, id, box0, flag, originIndex, originI) {
            var imageW = box.image.width;
            var imageH = box.image.height;
            var scaleW = 1, scaleH = 1;

            if ((box.image.width <= box.image.height || maskWidth <= maskHight) && (box.image.width / box.image.height < maskWidth / maskHight) || box.image.width >= box.image.height && maskWidth >= maskHight && (box.image.width / box.image.height < maskWidth / maskHight)) {
                imageW = maskWidth;
                imageH = maskWidth * h / w;
                if (box0.blurred) {
                    box0.blurredRectangle.height = box0.geometry.height;
                    box0.blurredRectangle.width = box0.geometry.height * w / h;
                    box0.blurredRectangle.x = (box0.geometry.width - box0.blurredRectangle.width) / 2;
                    box0.blurredRectangle.y = 0;
                }

            } else {
                imageH = maskHight;
                imageW = maskHight * w / h;
                if (box0.blurred) {
                    box0.blurredRectangle.width = box0.geometry.width;
                    box0.blurredRectangle.height = box0.geometry.width * h / w;
                    box0.blurredRectangle.y = (box0.geometry.height - box0.blurredRectangle.height) / 2;
                    box0.blurredRectangle.x = 0;
                }
            }

            scaleW = imageW / w;
            scaleH = imageH / h;

            box0.image.offsetX = 0;
            box0.image.offsetY = 0;

            var img = box.imgUrl + '?imageMogr2/strip/rotate/' + box.image.angle + '/thumbnail/!40p';

            if (box0.blurred) {
                maskd3($(".imgBoxSmall" + index + '' + id), img, box0);
                img = img + '/blur/40x1000';
            }

            maskd($(".imgBox" + index + '' + id), img, box0.maskUrl, 0, 0, maskWidth, maskHight, scaleW, scaleH, box.image.scale, false, function (src) {
                box0.blobImage = true;
                reviewDoc.page[index].imageboxes.imagebox[id].blobImage = true;

                if (flag) {
                    var arr = angular.copy(box0.image);
                    box0.image = angular.copy(box.image);
                    box.image = angular.copy(arr);
                    var image = box0.imgUrl;
                    box0.imgUrl = box.imgUrl;
                    box.imgUrl = image;

                    $(".change-btn").hide();
                    tipStatus(false);
                    var reviewArr = angular.copy(reviewDoc.page[index].imageboxes.imagebox[id].image);
                    reviewDoc.page[index].imageboxes.imagebox[id].image = angular.copy(reviewDoc.page[originIndex].imageboxes.imagebox[originI].image);
                    reviewDoc.page[originIndex].imageboxes.imagebox[originI].image = angular.copy(reviewArr);
                    singleToRemember();
                }

                $scope.$digest();

            });
        }

        //这块是图片中需要上传的区域
        $scope.chooseImage = function ($index, i) {
            $scope.template.pages.page[$index].selectImgIndex = i;

            $(".change,.cut-image").hide();
            $(".mode1,.mode2").addClass('hide1');

            tipStatus(true);
            $scope.showBottomMenu = false;
            $scope.showImageMenu = !$scope.showImageMenu;

            $scope.galleryList = JSON.parse(localStorage.getItem('gallery'));

            if (!$scope.galleryList) {
                return
            }
            setImageWidth();

        };

        function goToChoose($index, i) {
            localStorage.setItem("index", $index);
            localStorage.setItem("marginLeft", $scope.dWidth * 0.94 * $index);
            localStorage.setItem("i", i);
            localStorage.setItem("choose", 'choose');
            localStorage.setItem("Production", "start");
            localStorage.setItem('pages', JSON.stringify(reviewDoc));
            if (vm.pid) {
                $state.go('product.choose', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid}, {reload: true});
            } else {
                $state.go('product.chooses', {storeId: vm.storeId, aid: vm.aid}, {reload: true});
            }
        }

        var selectImgBoxes = function ($index) {
            if ($rootScope.currentCarouselIndex  == null) {
                $scope.template.pages.page[0].selectImgIndex = $index;
            }else{
                $scope.template.pages.page[index].selectImgIndex = $index;
            }
        };
        var getCurrentPage = function (index) {
            if (typeof index == 'undefined') {
                index = getPageIndex();
            }
            return $scope.template.pages.page[index];
        };

        var getPageIndex = function () {
            if ($rootScope.currentCarouselIndex == null) {
                return 0;
            }
            return $rootScope.currentCarouselIndex;
        };

        /**
         * 拖拽图片
         */
        window.startDrag = function (e) {
            isView();
            fn(e);
            var index = getPageIndex();
            var i = e.target.dataset.imgindex;
            var box = $scope.template.pages.page[index].imageboxes.imagebox[i];
            selectImgBoxes(i);
            var targ = e.target ? e.target : e.srcElement;
            dragBox.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragBox.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragBox.coordX = parseInt(targ.style.left) - parseFloat(box.image.offsetX) * box.image.scaleW;
            dragBox.coordY = parseInt(targ.style.top) - parseFloat(box.image.offsetY) * box.image.scaleH;
            dragBox.offsetX = box.image.offsetX;
            dragBox.offsetY = box.image.offsetY;
            dragBox.imageArr = getImageScale(box.image, box.geometry);
            drag = true;
            dragBox.imagecanvas = document.createElement('canvas');
            dragBox.imagecontext = dragBox.imagecanvas.getContext('2d');
        };

        window.dragImage = function (e) {
            getCurrentImage();
            var box = $scope.currentImage;

            if ($scope.template.pages.page[$scope.index].selectImgIndex != e.target.dataset.imgindex) {
                return;
            }
            if (box.blurred) {
                return
            }

            flag = false;

            var orignW = dragBox.imageArr.nWidth;
            var orignH = dragBox.imageArr.nHeight;
            box.image.scaleW = dragBox.imageArr.scaleW;
            box.image.scaleH = dragBox.imageArr.scaleH;
            var isWidth = dragBox.imageArr.isWidth;
            if (drag) {
                //手指离开屏幕
                if (!isWidth && box.image.scale == 1) {    //宽图
                    box.image.offsetX = (box.geometry.x - dragBox.coordX + (e.changedTouches[0].clientX - dragBox.clientX)) / box.image.scaleW;
                } else if (isWidth && box.image.scale == 1) {     //高图
                    box.image.offsetY = (box.geometry.y - dragBox.coordY + (e.changedTouches[0].clientY - dragBox.clientY)) / box.image.scaleH;
                } else if (box.image.scale > 1) {
                    box.image.offsetX = (box.geometry.x - dragBox.coordX + (e.changedTouches[0].clientX - dragBox.clientX)) / box.image.scaleW;
                    box.image.offsetY = (box.geometry.y - dragBox.coordY + (e.changedTouches[0].clientY - dragBox.clientY)) / box.image.scaleH;
                }
                //向上滑动;
                if (-box.image.offsetY >= (orignH * box.image.scale - box.geometry.height) / box.image.scale / box.image.scaleH) {
                    box.image.offsetY = -(orignH * box.image.scale - box.geometry.height) / box.image.scale / box.image.scaleH;
                }
                //向左滑动;        <-----
                if (-box.image.offsetX >= (orignW * box.image.scale - box.geometry.width) / box.image.scale / box.image.scaleW) {
                    box.image.offsetX = -((orignW * box.image.scale - box.geometry.width) / box.image.scale / box.image.scaleW);
                }
                //向下滑动;
                if (box.image.offsetY >= 0) {
                    box.image.offsetY = 0;
                }
                // 向右滑动;    ---->
                if (box.image.offsetX >= 0) {
                    box.image.offsetX = 0;
                }

                var img = box.imgUrl + '?imageMogr2/strip/rotate/' + box.image.angle + '/thumbnail/!40p';
                // $(".imgBox" + index + '' + id)[0]

                maskd2($("#imgInputReal" + e.target.id.replace("imgInput", "") + 'Make')[0], img, box.maskUrl, box.image.offsetX, box.image.offsetY, box.geometry.width, box.geometry.height, box.image.scaleW, box.image.scaleH, box.image.scale, function (src) {
                    box.blobImage = true;
                    reviewDoc.page[$scope.index].imageboxes.imagebox[$scope.i].blobImage = true;
                });

                $scope.$digest();
                e.stopPropagation();
                e.preventDefault();
                return false;
            }
        };

        window.stopDrag = function () {
            getCurrentImage();
            var box = $scope.currentImage;
            dragBox.imagecanvas = null;
            drag = false;
            var imageLen = $scope.template.pages.page[$scope.index].imageboxes.imagebox.length;
            /**
             * 选中图片框   点击照片出现操作按钮
             */
            if (Math.abs(dragBox.offsetX - box.image.offsetX) <= 6 && Math.abs(dragBox.offsetY - box.image.offsetY) <= 6) {
                selectImgBoxes($scope.i);

                tipStatus(true);

                if (vm.store.bokeh) {
                    $(".mode1,.mode2").removeClass('hide1');
                } else {
                    $(".mode1,.mode2").addClass('hide1');
                }

                if (box.blurred) {
                    $(".cut-image").hide();
                } else {
                    if (box.image && box.image.resource.identifier) {
                        $(".cut-image").show();
                    }
                }


                $(".change-btn").hide();
                $scope.isReplaceShow = true;
                if (imageLen < 2) {
                    $(".change").hide();
                } else {
                    $(".change").show();
                }

                $scope.showImageMenu = !$scope.showImageMenu;
                $scope.showStickerMenu = false;
                if ($scope.showImageMenu) {
                    $scope.showBottomMenu = false;
                } else {
                    $scope.showBottomMenu = true;
                }
                $scope.showTempMenu = false;
                $scope.galleryList = JSON.parse(localStorage.getItem('gallery'));


                setImageWidth();
                $scope.$digest();

            } else {
                flag = true;
            }
            $rootScope.template = $scope.template;
            var reviewPage = reviewDoc.page[$scope.index].imageboxes.imagebox[$scope.i];
            reviewPage.image = angular.copy(box.image);

            singleToRemember();
            dragBox = {};
        };
        function maskd2(imagecanvas, src, maskUrl, x0, y0, width, height, scaleW, scaleH, scale, callback) {
            var imagecontext = imagecanvas.getContext('2d');
            var newImg = document.createElement('img');
            newImg.crossOrigin = "Anonymous";
            newImg.src = src;
            newImg.onload = function () {
                newImg.onload = null;
                newImg.width = width;
                newImg.height = height;
                var masks = document.createElement('img');
                masks.crossOrigin = "Anonymous";
                masks.src = maskUrl;

                masks.onload = function () {
                    masks.width = width;
                    masks.height = height;
                    imagecanvas.width = width;
                    imagecanvas.height = height;
                    imagecontext.mozImageSmoothingEnabled = true;
                    imagecontext.webkitImageSmoothingEnabled = true;
                    imagecontext.msImageSmoothingEnabled = true;
                    imagecontext.imageSmoothingEnabled = true;

                    imagecontext.drawImage(masks, 0, 0, width, height);
                    imagecontext.globalCompositeOperation = 'source-in';

                    imagecontext.scale(scaleW, scaleH);

                    imagecontext.scale(scale, scale);
                    imagecontext.drawImage(newImg, x0, y0);


                    var pic = '';
                    // var pic = ImageService.dataURLtoBlob(imagecanvas.toDataURL());
                    // if (imgs) {
                    //     imgs.src = pic;
                    // }
                    // imgs.src = ImageService.dataURLtoBlob(imagecanvas.toDataURL());
                    if (!callback) {
                        return;
                    }
                    callback.call(this, pic);


                };
            };
        }

        /*******************************************贴纸功能****************************start*********************************************/
        /**
         * 获取贴纸列表
         */
        function getStickerList() {
            ClipArt.getAllByCondition({
                items: [{key: "type", op: "=", value: "wechat"}, {
                    key: "themeClassify.id",
                    op: "=",
                    value: vm.article.themeClassifyId
                }],
                sort: ["id,asc"]
            }, function (resp) {
                angular.forEach(resp, function (obj, i) {
                    if (!obj.cancelled) {
                        if (obj.provider == 'qiniu') {
                            $scope.stickerList.push({
                                name: obj.name,
                                identifier: obj.identifier,
                                provider: obj.provider,
                                imgUrl: $scope.domain + obj.identifier
                            })
                        }
                    }
                });
            });
        }

        /**
         * 切换贴纸菜单
         */
        $scope.toggleStickerMenu = function ($event) {
            if ($event) {
                $event.stopPropagation();
            }
            $scope.showStickerMenu = !$scope.showStickerMenu;
            $scope.showBottomMenu = !$scope.showBottomMenu;
            if ($scope.showStickerMenu) {
                $scope.showTextMenu = false;
            }
        };

        /**
         * 显示模板
         */
        $scope.toggleTempMenu = function ($event) {
            if ($event) {
                $event.stopPropagation();
            }
            $scope.showTempMenu = !$scope.showTempMenu;
            $scope.showBottomMenu = !$scope.showBottomMenu;
            setUlWidth();
            getCurrentImage();
            $scope.selectedLi = $scope.currentPage.uuid;
        };

        /**
         * 获取当前选中的贴纸
         * @returns {Array}
         */
        var getCurrentStickerList = function () {
            var index = $rootScope.currentCarouselIndex != null ? $rootScope.currentCarouselIndex: 0;
            var page = $scope.template.pages.page[index];
            return page.imagearts.imageart
        };
        /**
         * 添加贴纸
         */
        $scope.addSticker = function (e, sticker) {
            if (e) {
                e.stopPropagation();
            }

            var currentPage = $scope.template.pages.page[$rootScope.currentCarouselIndex];
            var pageW = (currentPage.isMaxWidth ? $scope.dWidth * 0.94 : ($scope.dHeight - $scope.tHeight) * currentPage.width / currentPage.height).toFixed(2);

            if (!currentPage.imagearts) {
                currentPage.imagearts = {
                    imageart: []
                }
            }

            $scope.template.pageI = $rootScope.currentCarouselIndex;
            $scope.template.artXM = Math.ceil(20 + currentPage.imagearts.imageart.length * 2);
            $scope.template.artYM = Math.ceil(20 + currentPage.imagearts.imageart.length * 2);

            var img = new Image();
            img.src = sticker.imgUrl;

            currentPage.imagearts.imageart.push({
                geometry: {
                    x: unitTransform($scope.template.artXM) * currentPage.styleScale,
                    y: unitTransform($scope.template.artYM) * currentPage.styleScale,
                    width: pageW / 4,
                    height: pageW / 4 * img.height / img.width
                },
                rotation: {
                    angle: 0
                },
                type: 'dynamic',
                resource: {
                    identifier: sticker.identifier,
                    provider: sticker.provider
                },
                imgUrl: sticker.imgUrl,
                naturalWidth: img.width * currentPage.styleScale,
                naturalHeight: img.height * currentPage.styleScale,
                widthM:unitTransformPx(pageW / 4 / currentPage.styleScale),
                height: unitTransformPx(pageW / 4 * img.height / img.width / currentPage.styleScale),
                XM: $scope.template.artXM,
                YM: $scope.template.artYM
            });

            var currentCreate = reviewDoc.page[$rootScope.currentCarouselIndex];
            if (!currentCreate.imagearts) {
                currentCreate.imagearts = {
                    imageart: []
                };
            }

            currentCreate.imagearts.imageart.push({
                geometry: {
                    x: $scope.template.artXM,
                    y: $scope.template.artYM,
                    width: unitTransformPx(pageW / 4 / currentPage.styleScale),
                    height: unitTransformPx(pageW / 4 * img.height / img.width / currentPage.styleScale)
                },
                rotation: {
                    angle: 0
                },
                type: 'dynamic',
                resource: {
                    identifier: sticker.identifier,
                    provider: sticker.provider
                },
                imgUrl: sticker.imgUrl,
                naturalWidth: img.width * currentPage.styleScale,
                naturalHeight: img.height * currentPage.styleScale,
                widthM:unitTransformPx(pageW / 4 / currentPage.styleScale),
                height: unitTransformPx(pageW / 4 * img.height / img.width / currentPage.styleScale),
                XM: $scope.template.artXM,
                YM: $scope.template.artYM
            });

            $scope.template.artI = $scope.template.pages.page[$rootScope.currentCarouselIndex].imagearts.imageart.length - 1;
            currentPage.imagearts.imageart[$scope.template.artI].zindex = $scope.template.artI;
            currentPage.currentZindex = $scope.template.artI;
            $scope.template.currentMenu = 'art';
            singleToRemember();
            $rootScope.template = $scope.template;
        };

        /**
         * 选中编辑贴纸
         * @param $event
         * @param $index
         */
        $scope.editSticker = function ($event, $index) {
            if ($event) {
                $event.stopPropagation();
            }
            var selectStickerList = getCurrentStickerList();

            $scope.template.artI = $index;
            var currentPage = getCurrentPage();
            if (selectStickerList[$index]) {
                currentPage.currentZindex = selectStickerList[$index].zindex;
            } else {
                currentPage.currentZindex = currentPage.imagearts.imageart.length;
            }
        };

        var getSticker = function (e) {
            var targ = e.target ? e.target : e.srcElement;
            var $index = targ.parentElement.dataset['index'];
            $scope.template.artI = $index;
            var selectStickerList = getCurrentStickerList();
            var sticker = selectStickerList[$index];
            if (sticker != null) {
                var img = new Image();
                img.src = sticker.imgUrl;
                sticker.naturalHeight = img.height * $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale;
                sticker.naturalWidth = img.width * $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale
            }
            $scope.template.currentMenu = 'art';
            return selectStickerList[$index];
        };

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        /**
         * 缩放旋转贴纸
         * @type {boolean}
         */
        window.resizeStickerStart = function (e) {
            fn(e);

            var targ = e.target ? e.target : e.srcElement;
            var sticker = getSticker(e);
            stickerDragBox.clientX = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX : 0;          //鼠标到页面左边的距离
            stickerDragBox.clientY = e.changedTouches[0].clientY != null? e.changedTouches[0].clientY: 0;          //鼠标到页面顶部的距离
            stickerDragBox.coordX = targ.offsetLeft;        //鼠标到模板左边的距离
            stickerDragBox.coordY = targ.offsetTop;         //鼠标到模板顶部的距离

            sticker.scale = sticker.geometry.width / sticker.naturalWidth;
            stickerDragBox.width = angular.copy(sticker.geometry.width);
            stickerDragBox.height = angular.copy(sticker.geometry.height);
            //计算图形的半径
            sticker.radius = parseInt(Math.sqrt(sticker.naturalWidth * sticker.naturalWidth + sticker.naturalHeight * sticker.naturalHeight) / 2 * sticker.scale);

            stickerDragBox.radius = angular.copy(sticker.radius);
            stickerDragBox.stickerRotate = sticker.rotation.angle ? Number(sticker.rotation.angle) : 0;
            //Math.asin(x) 范围（-1，1）
            if (sticker.naturalWidth / 2 / sticker.radius > 1) {
                stickerDragBox.asin = Math.asin(1);
            } else if (sticker.naturalWidth / 2 / sticker.radius < -1) {
                stickerDragBox.asin = Math.asin(-1);
            } else {
                stickerDragBox.asin = Math.asin(sticker.naturalWidth / 2 / sticker.radius);
            }
            stickerDragBox.originalRotate = parseInt(180 * stickerDragBox.asin / (2 * Math.asin(1))) + stickerDragBox.stickerRotate;
            isDragging = true;
        };

        window.resizeStickerOn = function (e) {
            fn(e);
            var sticker = getSticker(e);
            if (isDragging) {
                var offsetX = (e.changedTouches[0].clientX - stickerDragBox.clientX);
                var offsetY = (e.changedTouches[0].clientY - stickerDragBox.clientY);

                var newX = (stickerDragBox.width / 2 + offsetX);
                var newY = (stickerDragBox.width / 2 + offsetY);
                sticker.newRadius = Math.sqrt(newX * newX + newY * newY);
                sticker.geometry.width = stickerDragBox.width * sticker.newRadius / stickerDragBox.radius;
                sticker.geometry.height = stickerDragBox.height * sticker.geometry.width / stickerDragBox.width;
                var rotate = parseInt(180 * Math.asin(newX / sticker.newRadius) / (2 * Math.asin(1)));

                if (newX > 0 && newY > 0) {
                    sticker.rotation.angle = stickerDragBox.originalRotate - rotate;
                } else if (newX < 0 && newY > 0) {
                    if (newX / newY + sticker.naturalWidth / sticker.naturalHeight) {
                        sticker.rotation.angle = stickerDragBox.originalRotate - rotate;
                    } else {
                        sticker.rotation.angle = stickerDragBox.originalRotate + 90 - rotate;
                    }
                } else if (newX < 0 && newY < 0) {
                    sticker.rotation.angle = stickerDragBox.originalRotate + 180 + rotate;

                } else {
                    if (newX / newY + sticker.naturalWidth / sticker.naturalHeight) {
                        sticker.rotation.angle = stickerDragBox.originalRotate + 180 + rotate;
                    } else {
                        sticker.rotation.angle = stickerDragBox.originalRotate + 270 - rotate;
                    }
                }
                $scope.$digest();
            }
            return false;
        };
        window.resizeStickerEnd = function (e) {
            var sticker = getSticker(e);
            var imageart = reviewDoc.page[$rootScope.currentCarouselIndex].imagearts.imageart[$scope.template.artI];
            var styleScale = $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale;

            imageart.rotation.angle = -sticker.rotation.angle;
            imageart.geometry.width = imageart.widthM = sticker.widthM = unitTransformPx(sticker.geometry.width / styleScale);
            imageart.geometry.height = imageart.heightM = sticker.heightM = unitTransformPx(sticker.geometry.height / styleScale);

            singleToRemember();
            isDragging = false;
            stickerDragBox = {};
            $rootScope.template = $scope.template;
        };

        /**
         * 移动贴纸
         * @param e
         */
        window.moveStickerStart = function (e) {
            var sticker = getSticker(e);
            var index = $rootScope.currentCarouselIndex !=null ? $rootScope.currentCarouselIndex: 0;
            $scope.template.pages.page[index].currentZindex = sticker.zindex;

            if (sticker.type == 'static') {
                return
            }

            dragBox.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragBox.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragBox.coordX = sticker.geometry.x;
            dragBox.coordY = sticker.geometry.y;
            moveSticker = true;
            window.event ? window.event.cancelBubble = true : e.stopPropagation();
            window.event ? window.event.returnValue = false : e.preventDefault();
            $scope.$digest();
        };
        window.moveStickerOn = function (e) {
            window.event ? window.event.cancelBubble = true : e.stopPropagation();
            window.event ? window.event.returnValue = false : e.preventDefault();
            if (moveSticker) {
                var sticker = getSticker(e);
                var offsetX = dragBox.coordX + (e.changedTouches[0].clientX - dragBox.clientX);
                var offsetY = dragBox.coordY + (e.changedTouches[0].clientY - dragBox.clientY);

                sticker.geometry.x = offsetX;
                sticker.geometry.y = offsetY;
            }
            $scope.$digest();
            return false;
        };
        window.moveStickerEnd = function (e) {
            var sticker = getSticker(e);
            var imageart = reviewDoc.page[$rootScope.currentCarouselIndex].imagearts.imageart[$scope.template.artI];
            var styleScale = $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale;
            imageart.geometry.x = imageart.XM = sticker.XM = unitTransformPx(sticker.geometry.x / styleScale);
            imageart.geometry.y = imageart.YM = sticker.YM = unitTransformPx(sticker.geometry.y / styleScale);
            singleToRemember();
            moveSticker = false;
            dragBox = {};
            $rootScope.template = $scope.template;
        };

        /**
         * 删除贴纸
         * @param event
         * @param i
         */
        $scope.deleteSticker = function (event, i, index) {
            var selectStickerList = getCurrentStickerList();
            var selectStickerDoc = reviewDoc.page[index].imagearts.imageart;
            deleteAction(event, selectStickerList, selectStickerDoc, i);
            $scope.editSticker(event, selectStickerList.length - 1);
        };

        function deleteAction(e, list, doc, i) {
            if (e) {
                fn(e);
            }
            list.splice(i, 1);
            doc.splice(i, 1);


            var index = $rootScope.currentCarouselIndex !=null ?$rootScope.currentCarouselIndex: 0;
            var currentPage = $scope.template.pages.page[index];
            currentPage.currentZindex -= 1;

            singleToRemember();
            $rootScope.template = $scope.template;
        }

        /*******************************************贴纸功能****************************end*********************************************/


        /*******************************************文字处理****************************start*********************************************/

        /**
         * 更改文字字体
         */
        function getFontList() {
            FontManagement.getAll2({family: ""}, function (res) {
                $scope.fontList = [];
                angular.forEach(res, function (msg, i) {
                    $scope.fontList.push({
                        id: 0,
                        // 字体名称
                        value: msg.family,
                        child: []
                    });
                    var zh = null;
                    angular.forEach(msg.fontStyles, function (font, j) {
                        switch (font.style) {
                            case 'NORMAL':
                                zh = "常规";
                                break;
                            case 'BOLD':
                                zh = "粗体";
                                break;
                            case 'ITALIC':
                                zh = "斜体";
                                break;
                            case 'BOLD_ITALIC':
                                zh = "粗斜体";
                                break;
                            default:
                                zh = null
                        }
                        $scope.fontList[i].child.push({
                            // 字体样式中文
                            value: zh,
                            // 字体样式英文
                            en: font.style,
                            path: font.path,
                            identifier: font.identifier,
                            domain: $scope.domain
                        });
                    });
                    if (i == res.length - 1) {
                        getFont();
                    }
                });
            });
        }

        function getFont() {
            $scope.defaultText = {
                family: $scope.fontList[0].value,
                style: $scope.fontList[0].child[0].en,
                path: $scope.fontList[0].child[0].path,
                identifier: $scope.fontList[0].child[0].identifier,
                domain: $scope.domain
            };

            new MultiPicker({
                input: 'multiPickerInput',//点击触发插件的input框的id
                container: 'targetContainer',//插件插入的容器id
                jsonData: $scope.fontList,
                success: function (arr) {
                    $("#multiPickerInput").val(arr[0].value + "（" + arr[1].value + "）");
                    $scope.defaultText = {
                        family: arr[0].value,
                        style: arr[1].en,
                        path: arr[1].path,
                        identifier: arr[1].identifier,
                        domain: $scope.domain
                    };
                    var text = getCurrentText();
                    text.styles = arr[1].en;
                    text.style.font = arr[0].value;
                    text.identifier = arr[1].path;
                    if (text.styles.indexOf('BOLD') != -1) {
                        text.style.bold = true;
                    } else {
                        text.style.bold = false;
                    }
                    if (text.styles.indexOf('ITALIC') != -1) {
                        text.style.italic = true;
                    } else {
                        text.style.italic = false;
                    }
                }//回调
            });
        }

        /**
         * 文字
         */
        var getCurrentText = function () {
            var index = $rootScope.currentCarouselIndex !=null ? $rootScope.currentCarouselIndex: 0;
            if (!$scope.template.pages.page[index].textboxes.textbox[selectTextIndex]) {
                $scope.template.pages.page[index].textboxes.textbox[selectTextIndex] = {};
                reviewDoc.page[index].textboxes.textbox[selectTextIndex] = {};
            }
            return $scope.template.pages.page[index].textboxes.textbox[selectTextIndex];
        };

        var elem = document.querySelector('input[type="range"]');

        var rangeValue = function () {
            var newValue = elem.value;
            var target = document.querySelector('.text-value');
            target.innerHTML = "当前字号：" + newValue + " px";
            var text = getCurrentText();
            text.style.size = newValue;
        };

        elem.addEventListener("input", rangeValue);

        /**
         * 更新文字
         */
        $scope.updateTextContents = function () {
            var w = $("#text-area").width();
            $("#text-div").width(w);
            var page = $scope.template.pages.page[$scope.template.pageI];
            var maxW = (page.isMaxWidth ? $scope.dWidth * 0.94 : ($scope.dHeight - $scope.tHeight) * page.width / page.height) / page.styleScale;
            var maxH = page.isMaxWidth ? $scope.dWidth * 0.94 * page.height / page.width : ($scope.dHeight - $scope.tHeight) / page.styleScale;
            setTimeout(function () {
                var text = page.textboxes.textbox[$scope.template.textI];
                var $con = $("#text-div");

                var str = $scope.textContent.split('\n').join('<br/>');

                text.text = $scope.textContent;
                $con.html(str);
                text.maxW = maxW;
                text.maxH = maxH;
            }, 200);
        };

        /**
         * 文字输入确认按钮
         */
        $scope.saveText = function () {
            getCurrentImage();
            // $(".fix-bottom-section").removeAttr('style');
            $(window).unbind('scroll');

            $scope.showBottomMenu = true;
            $scope.showBarMenu = false;

            tipStatus(false);
            var i = $scope.template.textI;
            var index = $scope.template.pageI;
            $(".slide-li").eq(index).find(".text-box").eq(i).removeClass('opacity-zero');
            $scope.showTextModal = false;

            $scope.showTextMenu = false;
            var text = getCurrentText();
            text.text = $scope.textContent;


            toImg(text, $scope.currentPage.styleScale, index, i);
            var currentText = reviewDoc.page[index].textboxes.textbox[i];

            currentText.style = angular.copy(text.style);
            currentText.rotation = angular.copy(text.rotation);
            currentText.text = text.text;
            currentText.identifier = text.identifier;
            currentText.xM = text.xM;
            currentText.yM = text.yM;
            currentText.pic = text.pic;
            singleToRemember();


            $rootScope.template = $scope.template;
        };

        /**
         * 文字菜单切换（点击）及初始化
         */
        $scope.toggleTextMenu = function ($event) {
            $(".text-img").css({'height': 0});
            $("#text-area").attr('autofocus', "autofocus");

            getCurrentImage();
            var textbox = angular.copy($scope.currentText);
            $scope.textCopy = angular.copy(textbox);
            $scope.textCopyReview = angular.copy(reviewDoc);

            $scope.showTextModal = !$scope.showTextModal;
            $scope.showTextMenu = !$scope.showTextMenu;

            $scope.showBottomMenu = !$scope.showBottomMenu;

            // 清空样式
            $scope.textContent = "";

            /**
             * 格式化颜色和文字的选择
             */
            $scope.selectedColor = $scope.colorList[0];
            $scope.selectedFont = $scope.fontList[0];
            angular.forEach($scope.fontList, function (item, index) {
                item.selected = !index;
            });


            $(".tip-image").addClass('hide1');

            //初始化并添加一个文字框
            if ($scope.showTextMenu) {
                var currentPage = $scope.template.pages.page[$rootScope.currentCarouselIndex];

                if (!currentPage.textboxes.textbox) {
                    currentPage.textboxes.textbox = [];
                }

                $scope.template.pageI = $rootScope.currentCarouselIndex;
                var textXM = Math.ceil(20 + currentPage.textboxes.textbox.length * 2);
                var textYM = Math.ceil(20 + currentPage.textboxes.textbox.length * 2);
                currentPage.textboxes.textbox.push({
                    geometry: {
                        height: 24 * currentPage.styleScale,
                        width: 200 * currentPage.styleScale,
                        x: unitTransform(textXM) * currentPage.styleScale,
                        y: unitTransform(textYM) * currentPage.styleScale
                    },
                    rotation: {
                        angle: 0
                    },
                    style: {
                        color: $scope.selectedColor,
                        font: $scope.defaultText.family,
                        size: $(".text-range").val(),
                        bold: $scope.defaultText.style.indexOf('BOLD') >= 0 ? true : false,
                        italic: $scope.defaultText.style.indexOf('ITALIC') >= 0 ? true : false
                    },
                    identifier: $scope.defaultText.path,
                    styles: $scope.defaultText.style,
                    pic: '',
                    text: '',
                    type: 'dynamic',
                    status: '',
                    xM: textXM,
                    yM: textYM
                });

                $scope.template.textI = $scope.template.pages.page[$rootScope.currentCarouselIndex].textboxes.textbox.length - 1;
                currentPage.textboxes.textbox[$scope.template.textI].zindex = $scope.template.textI;
                currentPage.currentZindex = $scope.template.textI;

                var currentCreate = reviewDoc.page[$rootScope.currentCarouselIndex];
                if (!currentCreate.textboxes) {
                    currentCreate.textboxes = {
                        textbox: []
                    };
                }
                currentCreate.textboxes.textbox.push({
                    geometry: {
                        height: unitTransformPx(24),
                        width: unitTransformPx(200),
                        x: textXM,
                        y: textYM
                    },
                    rotation: {
                        angle: 0
                    },
                    style: {
                        color: $scope.selectedColor,
                        font: $scope.defaultText.family,
                        size: $(".text-range").val(),
                        bold: $scope.defaultText.style.indexOf('BOLD') >= 0 ? true : false,
                        italic: $scope.defaultText.style.indexOf('ITALIC') >= 0 ? true : false
                    },
                    identifier: $scope.defaultText.path,
                    styles: $scope.defaultText.style,
                    pic: '',
                    text: '',
                    type: 'dynamic',
                    status: '',
                    xM: textXM,
                    yM: textYM
                });

                $scope.template.currentMenu = 'text';
                $scope.selectCurrentTextBox($event, $scope.template.textI);

                $rootScope.template = $scope.template;
            }
        };

        /**
         * 底部 编辑按钮<---->菜单栏 的切换
         * @param event
         */
        $scope.toggleBarMenu = function (event) {
            if (event) {
                event.stopPropagation();
            }
            $scope.showBarMenu = !$scope.showBarMenu;
            // 当引导页点击“不在提示”以后就不再count++;
            if (!vm.guideStep) {
                $scope.countN++;
                localStorage.setItem('countN', $scope.countN);
            }
        };

        /**
         * 关闭按钮--->菜单栏
         */
        $scope.closeToBarMenu = function (event) {
            if (event) {
                event.stopPropagation();
            }
            $scope.showBottomMenu = true;
            $scope.showBarMenu = false;
            $scope.showStickerMenu = false;
            $scope.showTempMenu = false;
            $scope.showImageMenu = false;
        };

        var setUlWidth = function () {
            var len = vm.article.document.pages.page.length;
            if (len < 3) {
                return
            }
            //需要加上选中的边框值：2
            var w = 2;
            if ($scope.pageAllPx && $scope.pageAllPx.page.length) {
                var arr = $scope.pageAllPx.page;
                for (var j = 0; j < arr.length; j++) {
                    arr[j].width = unitTransform(arr[j].mediabox.width);
                    arr[j].height = unitTransform(arr[j].mediabox.height);
                    w += arr[j].width * $scope.mHeight / arr[j].height + 12;
                }
            }

            $(".temp-ul").width(w + 'px');
        };

        /**
         * 选中当前文字框
         */
        $scope.selectCurrentTextBox = function ($event, $index) {
            selectTextIndex = $index;
            if ($event) {
                $event.stopPropagation();
            }
            var currentText = getCurrentText();
            var currentPage = getCurrentPage();
            currentPage.currentZindex = currentText.zindex;

            /**
             * 显示文字颜色及字体选择菜单
             */
            $scope.showTextMenu = true;
            $scope.showStickerMenu = false;

            /**
             * 更新对应的颜色选择和字体
             */
            $scope.selectedColor = currentText.style.color;
            angular.forEach($scope.fontList, function (item) {
                if (item.value == currentText.style.font) {
                    item.selected = true;
                    $scope.selectedFont = item;
                } else {
                    item.selected = false;
                }
            });
        };
        /**
         * 更改文字颜色
         */
        $scope.selectTextColor = function (color) {
            $scope.selectedColor = color;
            var text = getCurrentText();
            text.style.color = color;
        };

        /**
         * 关闭编辑文字modal窗口
         */
        $scope.closeTextModal = function () {
            $scope.showTextModal = false;
            var text = getCurrentText();
            text.text = $scope.textContent;
            var $box = $("#slide" + $scope.template.pageI).find(".text-hidden" + $scope.template.textI);
            var boxWidth = text.geometry.width / $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale;
            $box.html(text.text);

            text.geometry.height = Math.ceil($box.width() / boxWidth) * 12;
            toImg(text);
        };

        $scope.hideMenu = function () {
            $scope.showTextMenu = false;
            $scope.showStickerMenu = false;

            $scope.showBarMenu = true;
            $scope.showTempMenu = false;
            if (!$scope.showImageMenu && !$scope.showTextModal) {
                $scope.showBottomMenu = true;
            }
            if ($scope.showTextModal) {
                $scope.showBottomMenu = false;
            }

            var currentPage = getCurrentPage();
            currentPage.currentZindex = -1;
        };


        function toImg(text, scale, index, textI) {
            if (text.text == '') {
                text.pic = '';
                return
            }
            var styleScale = scale ;
            var fontStyle = text.styles ? text.styles : $scope.defaultText.style;

            var tmpNode = document.createElement("DIV");
            $(tmpNode).html(text.text)
                .css({
                    'font-size': text.style.size
                });

            //根据换行符分数组
            var arrs = $(tmpNode).html().split('\n');
            //先拿到数组中下标为0的宽度
            var $width = $(".text-width").html(arrs[0]).css({
                'font-size': text.style.size + 'px',
                'font-weight': fontStyle,
                'font-family': text.style.font ? text.style.font : $scope.defaultText.family
            });

            var w0 = angular.copy(text.geometry.width);
            var h0 = angular.copy(text.geometry.height);

            var t = 0,
                k = 0;
            if ($width.width() > text.maxW) {
                k = k + parseInt($width.width() / text.maxW);
            }
            if (text.type != 'static' && text.maxW) {
                // console.log(text.text);
                var pattern_char = /[a-zA-Z0-9]/g;
                // var pattern_chin = /[\u4e00-\u9fa5]/g;
                var pattern = $width.html().match(pattern_char);
                var count_char = pattern ? pattern.length : 4;
                var maxW = ($width.width()) > text.maxW ? text.maxW : ($width.width() + count_char * 8);
                var maxH = $width.height();
                if (arrs.length == 1) {
                    w0 = maxW;
                    h0 = maxH * (arrs.length + k);
                } else {
                    for (var i = 1, len = arrs.length; i < len; i++) {
                        var count = arrs[i].match(pattern_char);
                        var over_char = count ? count.length : 4;
                        var overWidth = $width.html(arrs[i]).width() + over_char * 8;
                        //得到数组中宽度最大的一个
                        if (maxW < overWidth && overWidth < text.maxW) {
                            maxW = overWidth;
                            maxH = $width.html(arrs[i]).height();
                            t = i;
                        } else if (maxW < overWidth && overWidth > text.maxW) {
                            maxW = text.maxW;
                            maxH = $width.html(arrs[i]).height();
                            k = k + parseInt($width.width() / text.maxW);
                        }
                    }
                    $width.html(arrs[t]);
                    w0 = maxW;
                    h0 = maxH * (arrs.length + k);
                }
            } else {
                w0 /= styleScale;
                h0 /= styleScale;
            }

            if (text.geometry.height > $(".text-img" + textI).height()) {
                $(".text-img").height(text.geometry.height);
            }


            $http({
                url: '/editor/fileserves/api/convert/textToImg',
                method: 'POST',
                data: {
                    identifier: text.identifier ? text.identifier : $scope.defaultText.path,
                    text: text.text,
                    fontSize: text.style.size,
                    style: fontStyle,
                    height: h0,
                    width: w0,
                    color: text.style.color,
                    family: text.style.font ? text.style.font : $scope.defaultText.family,
                    storeId: vm.storeId
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                text.geometry.width = w0 * styleScale;
                text.geometry.height = h0 * styleScale;
                var reviewText = reviewDoc.page[index].textboxes.textbox[textI];
                if (reviewDoc.page[index] && reviewText) {
                    reviewText.geometry.width = reviewText.widthM = unitTransformPx(text.geometry.width) / styleScale;
                    reviewText.geometry.height = reviewText.heightM = unitTransformPx(text.geometry.height) / styleScale;
                }
                $(".text-img-pic").css({
                    width: w0 * styleScale,
                    height: h0 * styleScale
                });
                if (text.geometry.width < 90) {
                    text.divX = (100 - text.geometry.width) / 2
                } else {
                    text.divX = 10
                }
                if (text.geometry.height < 35) {
                    text.divY = (35 - text.geometry.height) / 2
                } else {
                    text.divY = 10
                }

                var createObjectURL = function (blob) {
                    return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
                };
                var fileURL = URL.createObjectURL(data);
                text.pic = fileURL;
                text.identifier = text.identifier != null ? text.identifier: $scope.defaultText.path;
            }).error(function () {
                console.log("textToImg error");
            });
        }

        /**
         * 删除文字框
         * @param $event
         * @param i
         * @param index
         */
        $scope.deleteSelectedText = function ($event, i, index) {
            $scope.template.textI = i;
            var textList = $scope.template.pages.page[index].textboxes.textbox;
            var textDoc = reviewDoc.page[index].textboxes.textbox;
            deleteAction(event, textList, textDoc, i);
            $scope.showTextMenu = false;
        };

        /**
         * 编辑文字内容
         * @param $event
         * @param i
         * @param text
         * @param index
         */
        $scope.editTextContent = function ($event, i, text, index) {
            $scope.showTextModal = true;
            $scope.showImageMenu = false;
            selectTextIndex = i;
            $(".text-img-pic").css({width: text.geometry.width, height: text.geometry.height});
            $scope.textContent = text.text;
            $scope.template.textI = i;
            $scope.template.pageI = index;
            $(".text-value").html(text.style.size + ' px');
            $(".rangeSize").val(text.style.size);
            // elem.addEventListener("input", rangeValue);

            angular.element('#text-area')[0].focus();

            getCurrentImage();
            var textbox = angular.copy($scope.currentText);
            $scope.textCopy = angular.copy(textbox);
            $scope.textCopyReview = angular.copy(reviewDoc);

            $(".tip-image").addClass('hide1');

        };
        function editorDynamic($index, e) {
            var tag = (arguments[0].target !=null? arguments[0].target: e.srcElement).tagName;
            if (tag == 'SPAN') {
                $scope.showTextModal = true;
                $scope.showImageMenu = false;
                selectTextIndex = $index;
                var text = getCurrentText();
                $scope.textContent = text.text;
                $(".text-value").html(text.style.size + ' px');
                $(".rangeSize").val(text.style.size);
                // elem.addEventListener("input", rangeValue);

                angular.element('#text-area')[0].focus();

                getCurrentImage();
                var textbox = angular.copy($scope.currentText);
                $scope.textCopy = angular.copy(textbox);
                $scope.textCopyReview = angular.copy(reviewDoc);
                $scope.showBarMenu = false;
                $scope.showBottomMenu = false;
                $(".tip-image").addClass('hide1');
            }
        }

        /**
         * 移动文字框
         */
        var getCurrentTextFromEvent = function (e) {
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            $scope.template.textI = targ.dataset.index;
            selectTextIndex = +targ.dataset.index;
            $scope.template.currentMenu = 'text';
            return getCurrentText();
        };

        window.moveTextBoxStart = function (e) {
            var tag = (arguments[0].target != null ? arguments[0].target : e.srcElement).tagName;
            var text = getCurrentTextFromEvent(e);
            var index = $rootScope.currentCarouselIndex != null ?$rootScope.currentCarouselIndex: 0;
            $scope.template.pageI = index;
            $scope.template.pages.page[index].currentZindex = text.zindex;
            $(".text-img-pic").css({width: text.geometry.width, height: text.geometry.height});
            if (text.status == 'dynamic') {
                editorDynamic(text.zindex, e);
                window.event ? window.event.cancelBubble = true : e.stopPropagation();
                window.event ? window.event.returnValue = false : e.preventDefault();
                $scope.$digest();
                return;
            }
            if (tag == 'SPAN' || text.status == 'static') {
                return;
            }

            dragBox.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragBox.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragBox.coordX = text.geometry.x;
            dragBox.coordY = text.geometry.y;
            moveText = true;
            window.event ? window.event.cancelBubble = true : e.stopPropagation();
            window.event ? window.event.returnValue = false : e.preventDefault();
            $scope.$digest();
        };
        window.moveTextBoxOn = function (e) {
            if (moveText) {
                var text = getCurrentTextFromEvent(e);
                var offsetX = dragBox.coordX + (e.changedTouches[0].clientX - dragBox.clientX);
                var offsetY = dragBox.coordY + (e.changedTouches[0].clientY - dragBox.clientY);
                text.geometry.x = offsetX;
                text.geometry.y = offsetY;
            }
            $scope.$digest();
            window.event ? window.event.cancelBubble = true : e.stopPropagation();
            window.event ? window.event.returnValue = false : e.preventDefault();

            return false;
        };
        window.moveTextBoxEnd = function (e) {
            if (!moveText) {
                return
            }
            var text = getCurrentTextFromEvent(e);
            var currentText = reviewDoc.page[$rootScope.currentCarouselIndex].textboxes.textbox[$scope.template.textI];
            currentText.geometry.x = currentText.xM = unitTransformPx(text.geometry.x / $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale);
            currentText.geometry.y = currentText.yM = unitTransformPx(text.geometry.y / $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale);
            singleToRemember();
            moveText = false;
            dragBox = {};
            $rootScope.template = $scope.template;
        };

        /**
         *旋转缩放文字
         * @param e
         */
        window.resizeTextBoxStart = function (e) {
            fn(e);
            // IE uses srcElement, others use target
            var text = getCurrentTextFromEvent(e);
            dragBox.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
            dragBox.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
            dragBox.coordX = angular.copy(text.geometry.x);
            dragBox.coordY = angular.copy(text.geometry.y);
            dragBox.originalWidth = angular.copy(text.geometry.width);
            dragBox.originalFontSize = text.style.size;


            dragBox.textRotate = text.rotation.angle ? Number(text.rotation.angle) : 0;
            dragBox.radius = parseInt(Math.sqrt((text.geometry.width * text.geometry.width + text.geometry.height * text.geometry.height) / $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale / $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale) / 2);
            dragBox.originalRotate = parseInt(180 * Math.asin(text.geometry.width / $scope.template.pages.page[$rootScope.currentCarouselIndex].styleScale / 2 / dragBox.radius) / (2 * Math.asin(1))) + dragBox.textRotate;
            window.isDragging = true;
        };
        window.resizeTextBoxOn = function (e) {

            if (window.isDragging) {

                var text = getCurrentTextFromEvent(e);
                var offsetX = (e.changedTouches[0].clientX - dragBox.clientX);
                var offsetY = (e.changedTouches[0].clientY - dragBox.clientY);
                var newX = (text.geometry.width / 2 + offsetX);
                var newY = (text.geometry.height / 2 + offsetY);
                dragBox.newRadius = Math.sqrt(newX * newX + newY * newY);
                var rotate = parseInt(180 * Math.asin(newX / dragBox.newRadius) / (2 * Math.asin(1)));
                if (newX > 0 && newY > 0) {
                    text.rotation.angle = (dragBox.originalRotate - rotate);
                } else if (newX < 0 && newY > 0) {
                    text.rotation.angle = dragBox.originalRotate - rotate;
                } else if (newX < 0 && newY < 0) {
                    text.rotation.angle = dragBox.originalRotate + 180 + rotate;
                } else {
                    text.rotation.angle = (dragBox.originalRotate + 180 + rotate);
                }
            }

            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();
            return false;
        };
        window.resizeTextBoxEnd = function (e) {
            var text = getCurrentTextFromEvent(e);
            var currentCreate = reviewDoc.page[$rootScope.currentCarouselIndex];
            currentCreate.textboxes.textbox[$scope.template.textI].rotation.angle = -text.rotation.angle;
            singleToRemember();
            window.isDragging = false;
            dragBox = {};
            $rootScope.template = $scope.template;
        };

        /*******************************************文字处理****************************end*********************************************

         /**
         * 保存作品，绘图开始
         */

        $scope.isContinue = function () {
            var hasEmpty = false;
            angular.forEach($scope.template.pages.page, function (item, index) {
                if (item.imageboxes.imagebox && item.imageboxes.imagebox.length) {
                    var isAllAdded = item.imageboxes.imagebox.every(function (box) {
                        return box.image && box.image.resource.identifier;
                    });
                    if (!isAllAdded) {
                        hasEmpty = true;
                        return;
                    }
                }
            });
            if (hasEmpty) {
                completed = false;
                return false;
            } else {
                completed = true;
                return true;
            }
        };

        $scope.pageSize = function () {
            var isLowSize = false;
            angular.forEach($scope.template.pages.page, function (page, index) {
                angular.forEach(page.imageboxes.imagebox, function (img, i) {

                    if (img.image && img.image.imgSize / 1024 < 300) {
                        isLowSize = true;
                        setTimeout(function(){
                            $(".sweet-alert").addClass('whiteBg');
                        })
                        SweetAlert.swal({
                                title: '有像素低的照片，确定要保存吗？',
                                // imageUrl: Theme.sweetHintImg(),
                                showCancelButton: true,
                                confirmButtonText: "确认",
                                cancelButtonText: "取消"
                            },
                            function (isConfirm) {
                                setTimeout(function(){
                                    $(".sweet-alert").removeClass('whiteBg');
                                },200)
                                if (isConfirm) {
                                    $(".save-page").removeClass('hide1');
                                    generateJsonFile();
                                    return;
                                } else {
                                    return;
                                }
                            })
                    }
                })
            });
            if (!isLowSize) {
                $timeout(function () {
                    $(".save-page").removeClass('hide1');
                    generateJsonFile();
                })
            }
        };

        $scope.saveWork = function () {
            var index = $rootScope.currentCarouselIndex;
            // 作品保存后，将页面改为0；
            $rootScope.currentCarouselIndex = 0;
            if ($scope.status == 'make') {
                setProductionStorage('view');
                $scope.showImageMenu = false;
                $scope.showTempMenu = false;
                $scope.isReplaceShow = false;
                $scope.showStickerMenu = false;

                setGalleryStorage();
                removeSingleStorage();

                $ul.css({marginLeft: '0px'});
                backToScrollTop();
                for (var i = 0; i < reviewDoc.page.length; i++) {
                    if (i == index) {
                        getPageToPx(reviewDoc.page[i], i, true, false);
                    } else {
                        getPageToPx(reviewDoc.page[i], i, false, false);
                    }

                }
                return;
            }
            //判断是否有图片未上传  且是否继续保存
            if (!$scope.isContinue()) {
                SweetAlert.swal({
                    title: '当前作品没有制作完成，保存后，项目库照片将会丢失，确认保存？',
                    imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        $timeout(function () {
                            $scope.pageSize();
                        }, 500);
                        return;
                    }
                })
            } else {
                $scope.pageSize();
            }
        };

        //json下载文件中需要的内容
        var generateJsonFile = function () {
            $scope.isSave = true;
            var template = {
                pages: {
                    page: []
                },
                reprint: vm.article.reprint
            };

            angular.forEach($scope.template.pages.page, function (item, index) {
                setTemplete(item, template, index, true)
            });


            var tempLen = $scope.template.pages.page.length;
            for (var i = 0; i < $scope.pageAllPx.page.length; i++) {
                setTemplete($scope.pageAllPx.page[i], template, tempLen + i, false)
            }
            template.hostinfo = hostInfo();
            if ($scope.update) {
                Article.update({
                    id: vm.article.id,
                    name: vm.article.name,
                    completed: completed,
                    sku: vm.article.sku,
                    cover: vm.article.cover,
                    document: template,
                    fileName: vm.article.fileName,
                    productId: vm.article.productId,
                    productName: vm.article.productName,
                    categoryId: vm.article.categoryId,
                    categoryName: vm.article.categoryName,
                    itemValueId: vm.article.itemValueId,
                    themeClassifyId: vm.article.themeClassifyId,
                    themeClassifyName: vm.article.themeClassifyName,
                    productXmlId: vm.article.productXmlId,
                    productXmlName: vm.article.productXmlName

                }, function (resp) {
                    $(".save-page").addClass('hide1');
                    $('.sweet-alert').removeClass('hide-button');
                    swal({
                        title: "修改成功",
                        text: "作品已同步到我的作品",
                        imageUrl: Theme.sweetSuccedImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                    localStorage.setItem('guide-step', true);
                    $state.go('product.view', {
                        aid: resp.id,
                        storeId: vm.storeId
                    }, {reload: true});
                    $scope.isSavingJson = false;
                    removeLocalStorage();
                }, function () {
                    $(".save-page").addClass('hide1');
                    swal({
                        title: "保存失败",
                        text: "保存出错了，请稍后再试，或者联系工作人员",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                })
            } else {
                Article.save({
                    name: vm.article.name,
                    sku: vm.article.sku,
                    productId: vm.article.productId,
                    productName: vm.article.productName,
                    cover: vm.article.cover,
                    completed: completed,
                    document: template,
                    itemValueId: vm.vid,
                    categoryId: vm.article.categoryId,
                    categoryName: vm.article.categoryName,
                    themeClassifyId: vm.article.themeClassifyId,
                    themeClassifyName: vm.article.themeClassifyName,
                    productXmlId: vm.article.productXmlId,
                    productXmlName: vm.article.productXmlName
                }, function (resp) {
                    $(".save-page").addClass('hide1');
                    $('.sweet-alert').removeClass('hide-button');
                    swal({
                        title: "保存成功",
                        text: "作品已同步到我的作品",
                        imageUrl: Theme.sweetSuccedImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                    localStorage.setItem('guide-step', true);
                    $state.go('product.view', {
                        aid: resp.id,
                        storeId: vm.storeId
                    }, {reload: true});
                    $scope.isSavingJson = false;
                    removeLocalStorage();
                }, function (resp) {
                    $(".save-page").addClass('hide1');
                    swal({
                        title: "保存失败",
                        text: "保存出错了，请稍后再试，或者联系工作人员",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 3000,
                        showConfirmButton: false
                    });

                });
            }
        };

        function setTemplete(item, template, index, flag) {
            template.pages.page[index] = {
                centerRotation: item.centerRotation,
                mediabox: angular.copy(item.mediabox),
                trimbox: angular.copy(item.trimbox),
                background: angular.copy(item.background),
                barcode: angular.copy(item.barcodeM),
                imagearts: {imageart: []},
                imageboxes: {imagebox: []},
                textboxes: {textbox: []},
                spread: item.spread,
                backup: item.backup,
                uuid: item.uuid
            };
            var reviewP = reviewDoc.page[index];
            if (!flag) {
                reviewP = item;
            }

            angular.forEach(item.imagearts.imageart, function (art, i) {
                var imageartR = reviewP.imagearts.imageart[i];
                if(!item.backup){
                    imageartR = item.imagearts.imageart[i];
                }

                var imageart = {
                    geometry: {
                        height: imageartR.heightM,
                        width: imageartR.widthM,
                        x: imageartR.XM,
                        y: imageartR.YM
                    },
                    rotation: {
                        angle: -art.rotation.angle
                    },
                    type: art.type,
                    resource: angular.copy(art.resource)
                };
                template.pages.page[index].imagearts.imageart.push(imageart);
            });

            angular.forEach(item.imageboxes.imagebox, function (img, i) {

                var imagebox = {
                    geometry: {
                        height: img.heightM,
                        width: img.widthM,
                        x: img.offsetXM,
                        y: img.offsetYM
                    },
                    rotation: angular.copy(img.rotation),
                    mask: angular.copy(img.mask),
                    image: {
                        resource: {
                            identifier: '',
                            provider: 'qiniu'
                        },
                        scale: 1
                    },
                    blurred: img.blurred
                };
                if (img.blurred) {
                    imagebox.blurredRectangle = {
                        x: unitTransformPx(img.blurredRectangle.x) / item.styleScale,
                        y: unitTransformPx(img.blurredRectangle.y) / item.styleScale,
                        width: unitTransformPx(img.blurredRectangle.width) / item.styleScale,
                        height: unitTransformPx(img.blurredRectangle.height) / item.styleScale,
                    }
                }
                if (img.image) {
                    imagebox.image = {
                        offsetX: unitTransformPx(img.image.offsetX * img.image.scaleW) / item.styleScale2,
                        offsetY: unitTransformPx(img.image.offsetY * img.image.scaleH) / item.styleScale2,
                        scale: img.image.scale,
                        angle: img.image.angle,
                        resource: {
                            identifier: img.image ? img.image.resource.identifier : '',
                            provider: img.image ? img.image.resource.provider : 'wechat'
                        },
                        resourceId: img.image.resourceId
                    }
                }
                template.pages.page[index].imageboxes.imagebox.push(imagebox);
            });

            angular.forEach(item.textboxes.textbox, function (text, i) {

                if (!(text.text == '' || text.text == null)) {
                    // var textR = reviewP.textboxes.textbox[i];
                    var textbox = {
                        geometry: {
                            height: text.heightM,
                            width: text.widthM,
                            x: text.xM,
                            y: text.yM
                        },
                        rotation: {
                            angle: -text.rotation.angle
                        },
                        style: text.style,
                        text: text.text,
                        status: text.status,
                        type: text.type,
                        identifier: text.identifier
                    };
                    template.pages.page[index].textboxes.textbox.push(textbox);
                }
            });
        }

        var removeLocalStorage = function () {
            localStorage.removeItem("choose");
            localStorage.removeItem("multipartUpload");
            localStorage.removeItem("index");
            localStorage.removeItem("i");
            localStorage.removeItem("go");
            localStorage.removeItem("single");
            localStorage.removeItem("pages");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("$index");
            localStorage.removeItem("gallery");
            localStorage.removeItem("work-save");
        };

        function setProductionStorage(attr) {
            localStorage.setItem('Production', attr);
            $scope.status = attr;
        }

        function singleToRemember() {
            localStorage.setItem('pages', JSON.stringify(reviewDoc));
        }

        function setReviewStorage() {
            localStorage.setItem('saveOldPage', JSON.stringify(reviewDoc));
            localStorage.setItem('reviewPages', JSON.stringify(reviewDoc));
            localStorage.setItem('pageAllPx', JSON.stringify($scope.pageAllPx));
            localStorage.setItem('galleryCopy', JSON.stringify(gallery));
        }

        function setGalleryStorage() {
            localStorage.setItem('gallery', JSON.stringify(gallery));
        }

        function removeSingleStorage() {
            localStorage.removeItem('single');
            localStorage.removeItem('marginLeft');
        }

        function tipStatus(flag) {
            if (flag) {
                $('.edit-menu').removeClass('opacity-zero');
                $('.tip-image').addClass('hide1');
                return
            }
            $('.edit-menu').addClass('opacity-zero');
            $('.tip-image').removeClass('hide1');
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        /**
         * 点击其他地方时取消选中贴纸，文字框
         */
        $scope.$on('documentClicked', function () {
            setEveryThingUnselected();
            $scope.$digest();
        });
        var setEveryThingUnselected = function () {
            $scope.showTextMenu = false;
            $scope.showStickerMenu = false;
            $(".change-btn").hide();
            var currentPage = getCurrentPage();
            currentPage.currentZindex = -1;
        };

        /**
         * carousel滑动后初始化编辑菜单
         */
        $scope.$on('carouselSlideAway', function () {
            //关闭贴纸菜单
            $scope.showStickerMenu = false;
            //关闭文字菜单
            if ($scope.showTextMenu) {
                $scope.toggleTextMenu();
            }

            //取消当前页面的选择框
            if ($scope.template) {
                setEveryThingUnselected()
            }
        });

        //    引导页部分
        $scope.nextStep2 = function () {
            $scope.countN++;
            localStorage.setItem('countN', $scope.countN);
        };
        $scope.nextStep3 = function () {
            $scope.countN++;
            localStorage.setItem('countN', $scope.countN);
        };

        $scope.neverHint = function () {
            localStorage.setItem('guide-step', true);
            vm.guideStep = true;
            $(".guide-view").addClass('hide1');
        };
    }
})();
