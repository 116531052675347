(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('PageViewArticleController', PageViewArticleController);

    PageViewArticleController.$inject = ['$scope', '$stateParams', 'Qinius', '$http', '$q', 'ImageService', 'entity', 'PlatformWechatSetting', 'SalesOrder',
        'Article', 'Music', 'ItemValue', 'Store', 'MessageService','$state', 'FontManagement', 'ProductXml', '$uibModal', 'Cart',
        'StoreConfig', 'MessageNotification', 'DateUtils', '$timeout', '$location','Customer', 'Editors', 'Theme','$localStorage','Product'];

    function PageViewArticleController($scope, $stateParams, Qinius, $http, $q, ImageService, entity, PlatformWechatSetting, SalesOrder,
                                       Article, Music, ItemValue, Store, MessageService,$state, FontManagement, ProductXml, $uibModal, Cart,
                                       StoreConfig, MessageNotification, DateUtils, $timeout, $location,Customer, Editors, Theme, $localStorage, Product) {
        var vm = this;
        $(".wrap").show();
        $(".navbar").show();
        //风格切换  引入不同的css文件
        Theme.judge();
        vm.Theme = localStorage.getItem('Theme');
        vm.counts = 0;
        vm.article = entity;
        vm.storeId = $stateParams.storeId;
        vm.aid = $stateParams.aid;
        vm.orderArticleId = $stateParams.orderArticleId;
        vm.salesOrderId = $stateParams.soId ? $stateParams.soId : null;
        vm.productLineId = $stateParams.plId ? $stateParams.plId : null;
        if($stateParams.authorization){
            $localStorage.authenticationToken = $stateParams.authorization;
        }
        vm.messageShow = true;
        vm.musicPage = false;
        var audio = document.getElementById("audio");
        var timer = null;
        vm.clientHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)-200;
        $scope.isRandomView = false;
        vm.submittable = false;
        $scope.viewPage = false;
        $scope.viewTypes = ['默认', '斜插式', '横插式', '翻阅式', '下降式', '心跳式（快）', '心跳式（慢）', '摩天轮', '跳跃式', '旋转式', '下缩式', '缩放式', '上移式',
            '翻转式', '顺滑式', '倒滑式', '滑行式', '轮播式', '扔除式', '左滑式', '右滑式', '左右滑式', '变换式', '挤兑式', '翻转式', '左右轮滑式',
            '淡出式', '上推式', '下推式', '抖动式'];

        if(vm.salesOrderId){
            SalesOrder.get({id:vm.salesOrderId}, function (res) {
                vm.salesOrder = res;
            })
        }
        vm.isNav = false;
        if($location.search() && $location.search().source == "external"){
            vm.isNav = true;
            $(".navbar-gallery").hide();
            $("#back-btn").hide();
            $(".well.transparentBGC").addClass('external-view');
            vm.orderArticleId = true;
        }

        var unitTransform = function (mm) {
            // mm换算成px  Math.ceil(mm / 25.4 * 300);
            // return (mm * 3) - 0;
            return mm / 25.4 * vm.windowDpiW;
        };
        var unitTransformPx = function (px) {
            // px换算成mm  Math.floor(px / 300 * 25.4)
            // return (px * 1 / 3).toFixed(2) - 0;
            return px / vm.windowDpiW * 25.4;
        };

        var $li = $(".templateView-carousel");

        function getSalesOrders() {
            if(vm.store.orderWay=='orderThenEditor' && vm.salesOrderId){
                SalesOrder.submittable({id: vm.salesOrderId},function (res) {
                    if (res.status == 200 &&res.message == true) {
                        vm.submittable = true;
                    }
                })
            }
        }

        js_getDPI();
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            vm.windowDpi = arrDPI[1];
            return arrDPI;
        }

        vm.fontuuids = [];
        $scope.k = 0;
        $scope.isText = false;

        var allWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $scope.dHeight = vm.dHeight = ((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)) / 1.8;
        $scope.dWidth = vm.dWidth = (allWidth - 220) / 1.8;
        $scope.template = {
            pages: {
                page: [{}]
            }
        };
        $scope.document = {
            pages: [{}]
        };
        $scope.len = 0;

        $scope.domains = Qinius.getDomain1();
        vm.platformSetting = PlatformWechatSetting.get();
        $(".modal-content").addClass('opacity0');
        vm.store = Store.get({id: vm.storeId});
        vm.isHideMusicBtn = false;
        vm.requireUserProtocol = false;
        $scope.isPull = true;
        vm.storeConfig = StoreConfig.byStoreIdAndType2({storeId:vm.storeId, type:"SHOPPINGCART"});
        StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"USERPROTOCOL"}, function (res) {
            if (res && res.type == "USERPROTOCOL") {
                vm.requireUserProtocol = res.open || false;
            }
        });


        vm.itemValue = ItemValue.get({id:vm.article.itemValueId});

        vm.messageList = "";
        if ($location.search().source != "external") {
            //消息列表
            MessageNotification.getAllByCondition({
                items:[
                    {key:"pageType", op : "=", value:"View"},
                    {key:"storeId", op : "=", value:vm.storeId},
                    {key:"available", op : "=", value:"true"},
                    {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
            }, function (res) {
                res.forEach(function (item, index) {
                    vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                })
            });
            Music.allByCondition({
                items: [],
                sort: ["id,asc"]
            }, function (resp) {
                $scope.Musics = resp;
            });
            vm.current = Customer.current()
        }
        $q.all([$scope.domains.$promise, vm.article.$promise, vm.platformSetting.$promise, vm.store.$promise,vm.article.$promise,vm.itemValue.promise]).then(function () {
            if (vm.itemValue.bindingType == 'Left2') {
                $scope.isPull = false;
                vm.itemValue.bindingType = 'Left';
            }
            getSalesOrders();
            vm.pattern = vm.itemValue.patternType;
            vm.bindingtype = vm.itemValue.bindingType;
            Product.get({id: vm.article.productId}, function (resP) {
                vm.product = resP;
                ProductXml.get({id:vm.article.productXmlId},function (res) {
                    vm.productXml = res;
                    dataInit();
                });
            })
            $scope.domain = 'http://' + $scope.domains.domains[0] + '/';
            if (vm.article.musicIdentify) {
                audio.src = $scope.domain + vm.article.musicIdentify;
                // 自动播放音乐
                Play();
            } else {
                audio.src = '';
                vm.isHideMusicBtn = true;
            }

            if(Editors.belongToEditorAll(vm.article.editorType)){
                if (vm.current) {
                    $q.all([vm.current.promise]).then(function () {
                        $('#qrcode1').qrcode({
                            render: "canvas",
                            width: 150,
                            height: 150,
                            text: '' + vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + vm.aid + "/viewShare?customerId=" + vm.current.id + "&type=WECHAT"
                        });
                    });
                }
            } else if(vm.article.editorType == "PICTURE"){
                // 分享二维码
                $('#qrcode1').qrcode({
                    render: "canvas",
                    width: 150,
                    height: 150,
                    text: '' + vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + vm.aid + "/editorPhotoPreview1"
                });
            }else {
                // 分享二维码
                $('#qrcode1').qrcode({
                    render: "canvas",
                    width: 150,
                    height: 150,
                    text: '' + vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + vm.aid + "/view2/0"
                });
            }
        });
        function dataInit(){
            if(Editors.belongToEditorAll(vm.article.editorType)){
                // vm.modelType = 'THREE';
                vm.modelType = vm.productXml.modelType ? vm.productXml.modelType : 'TWO';
                if(vm.modelType == 'THREE'){
                    if(!vm.productXml.modelPath){
                        MessageService.error("暂无3D模型");
                        return
                    }
                    vm.threeView = true;
                    vm.threeTemplate = vm.article.document;
                    vm.threeTemplate.pages.page.forEach(function (page, index){
                        page.seq = index;
                    })
                    vm.threeProductXmlData = vm.productXml;
                }
            }
            else if(vm.article.editorType == "PICTURE"){
                vm.modelType = 'PICTURE';
                $scope.template = angular.copy(vm.article.document);
                vm.isEdit = true;
            }else {
                vm.modelType = 'TWO';
            }
        }


        $scope.addCart = function(){
            if (!vm.article.itemValueId) {
                MessageService.error("产品信息不全，请重新制作后再尝试加入购物车");
                return;
            }
            if (!vm.article.completed) {
                MessageService.error("作品未完成，不能加入购物车");
                return;
            }
            if(!vm.productXml || !vm.article || !vm.itemValue){
                return
            }
            if (!articleCheck(vm.article, vm.itemValue,vm.productXml)) {
                MessageService.error("该作品已经下架，不能再购买");
                return;
            }
            var data = {title:"加入购物车", itemValueId:vm.article.itemValueId, pageCount:vm.article.pageCount,article:vm.article};
            createModal3('app/entities/sales-order/confirm-property.html', 'ConfirmPropertyController', data).result.then(
                function(value) {
                    Cart.addGoods({articleId:vm.article.id, quantity:vm.article.pageCount, copy:value.copy, itemValueLineId:value.itemValueLineId, orderWay: vm.store.orderWay, productId: vm.article.productId,
                        itemValueId: vm.article.itemValueId,
                        productXmlId: vm.article.productXmlId,}, function(res){
                        if(res.status == 400){
                            swal({
                                title: "失败",
                                text: res.message || "加入购物车失败",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            return
                        }
                        $scope.$emit('editorApp:cartUpdate', {});
                        $state.go("cart", {storeId: vm.storeId});
                    })
                }
            )
        }

        var createModal3 = function(url, controller, data) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: data
                }
            });
        };
        $scope.edit = function(){
            if(Editors.belongToEditorAll(vm.article.editorType)){
                $state.go('editorAllEdit', {storeId:vm.storeId,aid:vm.aid, soid:vm.salesOrderId, plid:vm.productLineId, returnUrl:$stateParams.returnUrl})
            }
            else if(vm.article.editorType == "PICTURE"){
                $state.go('editorPhotoWebEdit', {storeId:vm.storeId,aid:vm.aid, soid:vm.salesOrderId, plid:vm.productLineId, returnUrl:$stateParams.returnUrl})
            }else {
                $state.go('page-my-suffix-remake', {storeId:vm.storeId,aid:vm.aid, soid: vm.salesOrderId, plid: vm.productLineId, returnUrl:$stateParams.returnUrl})
            }
        }
        vm.isPlay = true;
        //音乐
        function Play() {
            audio.play().then(function () {
                vm.isPlay = true;
                $scope.$digest();
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);

            }).catch(function (error) {
            });
        }

        function Pause() {
            audio.pause();
            vm.isPlay = false;
            $scope.$digest();
        }

        var rot = 10 - 0;
        function begin() {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                if ($(this).children().hasClass('play')) {
                    $("#Play").children("img").removeClass("play").addClass("pause");
                    Play();
                }
            } else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                Pause();
            }
        });

        var audioTest = null;
        $scope.openMusicList = function () {
            vm.musicPage = true;
            audio.pause();
            vm.musicIdentify = vm.article.musicIdentify;
            if (vm.article.musicIdentify) {
                angular.forEach($scope.Musics, function (music, i) {
                    if (music.identify == vm.article.musicIdentify) {
                        $(".music-current").hide();
                        $(".music-current" + i).show();
                        setMusicStyle(i)
                    }
                })
            }

            audioTest = document.getElementById("audition");
            audioTest.crossOrigin = 'anonymous';
        };

        $scope.playList = function (identify, index) {
            // audioTest.pause();
            audioTest.src = $scope.domain + identify;
            vm.musicIdentify = identify;
            audioTest.play();
            setMusicStyle(index);
        };

        function setMusicStyle(index) {
            $(".music-name").removeClass('music-active2');
            $(".music-name" + index).addClass('music-active2');
            $(".music-li").removeClass('music-bgActive2');
            $(".music-li" + index).addClass('music-bgActive2');
            $(".music-icon").hide();
            $(".music-icon" + index).show();
        }

        $scope.saveMusic = function () {
            if (vm.musicIdentify == null || vm.musicIdentify == "") {
                vm.musicPage = false;
            } else {
                Article.updateMusic({id: vm.article.id, musicIdentify: vm.musicIdentify}, function (data) {
                    vm.article = data;
                    vm.musicPage = false;
                    audio.src = $scope.domain + vm.article.musicIdentify;
                    audioTest.pause();
                    audio.play();
                    Play();
                }, function () {

                })
            }

        };

        $scope.cancel = function () {
            vm.musicPage = false;
            audioTest.pause();
            audio.play();
        };
        $scope.toggle = {
            now: false
        };
        vm.qrcodeShow = false
        $scope.shareParents = function () {
            vm.qrcodeShow = !vm.qrcodeShow;
        }

        $scope.reorder = function(){
            if (!vm.article.itemValueId) {
                MessageService.error("产品信息不全，请重新制作后再尝试下单");
                return;
            }
            else if (!vm.article.completed) {
                MessageService.error("该作品还未完成，不能下单");
                return;
            }
            else if (!articleCheck(vm.article, vm.itemValue,vm.productXml)) {
                MessageService.error("该作品已经下架，不能再购买");
                return;
            }else{
                var data = {title:"下单", itemValueId:vm.article.itemValueId, pageCount:vm.article.pageCount,article:vm.article};
                createModal3('app/entities/sales-order/confirm-property.html', 'ConfirmPropertyController', data).result.then(
                    function(value) {
                        $state.go("sales-order-reorder", {storeId: vm.storeId, articleId: vm.article.id, productId:vm.article.productId,
                            itemValueLineId: value.itemValueLineId, lineSku:value.lineSku , lineSkuNumber:value.lineSkuNumber, copy:value.copy });
                    }
                )
            }
            // ItemValue.get({id:vm.article.itemValueId}, function(data){
            //     if (!data.productSaleable || data.cancelled || data.productCancelled) {
            //         MessageService.error("该作品已经下架，不能再购买");
            //         return;
            //     }
            //     // var isPic = swalEditorType(vm.article);
            //     // if(!isPic){
            //     //     return
            //     // }
            //     if (data.productXmlLines && data.productXmlLines.length>0) {
            //         for (var i = data.productXmlLines.length - 1; i >= 0; i--) {
            //             var xmlLine = data.productXmlLines[i];
            //             if (xmlLine.productXmlId == vm.article.productXmlId && xmlLine.cancelled) {
            //                 MessageService.error("该模板已被下架，不能进行该操作！");
            //                 return;
            //             }
            //         }
            //     }
            //     ProductXml.get({id:vm.article.productXmlId},function(data){
            //         if (data){
            //             if (data.cancelled) {
            //                 MessageService.error("该模板已被下架，不能进行该操作！");
            //             }
            //         }else{
            //             MessageService.error("信息获取失败，请稍后重试");
            //         }
            //     },function(){
            //     });
            // },function(){
            //     MessageService.error("信息获取失败，请稍后重试");
            // })
        }

        function swalEditorType(article) {
            if(article.editorType=='PICTURE'){
                MessageService.error("对不起，当前客户端不支持此类作品的操作与订购。");
                return false
            }
            return true
        }
        //返回作品管理
        $scope.backWorks = function () {
            $state.go("sales-order-manager", {id:vm.salesOrderId, plid:vm.productLineId, storeId: vm.storeId});
        }
        //提交生产
        $scope.submit = function(){
            var orderId = vm.salesOrderId;
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认提交订单？'
            }, function() {
                if (vm.store.orderWay == 'orderThenEditor' && vm.requireUserProtocol){

                    checkAgreement(orderId, function() {
                        requestSubmit(orderId);
                    });
                    return;
                } else {
                    requestSubmit(orderId);
                }
            });
        }

        function requestSubmit(orderId) {
            SalesOrder.submit({
                id: orderId
            }, function(data) {
                $state.go("sales-order", {storeId:vm.storeId});
                MessageService.success("提交订单成功");
            }, function() {
                MessageService.error("提交订单失败");
            })
        }

        function checkAgreement(orderId, agreeCallback) {
            SalesOrder.get({id: orderId}, function (order) {
                var quantity = 0;
                for (var i = 0; i < order.productLines.length; i++) {
                    var line = order.productLines[i];
                    quantity += (line.copy || 0);
                }
                $uibModal.open({
                    templateUrl: 'app/entities/sales-order/modal/protocol-agreement.html',
                    controller: 'OrderProtocolAgreementController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: [function () {
                            var _obj = {
                                number: order.number,
                                quantity: quantity
                            }
                            return _obj;
                        }]
                    }
                }).result.then(function (data) {
                    if (data && data.agree) {
                        if (agreeCallback) {
                            agreeCallback();
                        }
                    }
                });
            });
        };

    }
})();
