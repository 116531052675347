(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('UploadByPhoneController', UploadByPhoneController);

    UploadByPhoneController.$inject = ['$uibModalInstance', 'StoreConfig', '$q', '$sce', 'entity','$timeout'];

    function UploadByPhoneController ($uibModalInstance, StoreConfig, $q, $sce, entity, $timeout) {
        var vm = this;
        vm.cancel = cancel;
        vm.text = entity.text;
        console.log(vm.text);
        $timeout(function() {
            var qrcode = null;
            if (qrcode != null) {
                $('#qrcode1').html("");
            }
            // 分享二维码
            qrcode = $('#qrcode1').qrcode({
                render: "canvas",
                width: 128,
                height: 128,
                text: vm.text
            });
        })
        function cancel() {
            $uibModalInstance.close(true);
        }
    }
})();
