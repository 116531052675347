(function () {
    'use strict';

    var calendarSetControl = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/controls/calendar-set.control.html',
        controllerAs: 'vm',
        bindings: {
            data: '=',
            onChange:'&'
        },

        controller: ['$scope', '$timeout', '$uibModal','UidService', function ($scope, $timeout, $uibModal,UidService) {
            var vm = this;
            vm.weekStyleKeys = [
                {id:'w1',value:'星期一'},
                {id:'w2',value:'星期二'},
                {id:'w3',value:'星期三'},
                {id:'w4',value:'星期四'},
                {id:'w5',value:'星期五'},
                {id:'w6',value:'星期六'},
                {id:'w7',value:'星期日'},
            ];
            vm.weekStyleKey = "w1";
            vm.dateConfigKeys = [
                {id:'w1',value:'星期一'},
                {id:'w2',value:'星期二'},
                {id:'w3',value:'星期三'},
                {id:'w4',value:'星期四'},
                {id:'w5',value:'星期五'},
                {id:'w6',value:'星期六'},
                {id:'w7',value:'星期日'},
                {id:'others',value:'其它月'},
            ];
            vm.sampleYears = [
                {id:'2020',value:'2020'},
                {id:'2021',value:'2021'},
                {id:'2022',value:'2022'},
                {id:'2023',value:'2023'},
                {id:'2024',value:'2024'},
                {id:'2025',value:'2025'},
                {id:'2026',value:'2026'},
                {id:'2027',value:'2027'},
                {id:'2028',value:'2028'},
                {id:'2029',value:'2029'},
                {id:'2030',value:'2030'},
            ];
            vm.sampleMonths = [
                {id:'1',value:'1'},
                {id:'2',value:'2'},
                {id:'3',value:'3'},
                {id:'4',value:'4'},
                {id:'5',value:'5'},
                {id:'6',value:'6'},
                {id:'7',value:'7'},
                {id:'8',value:'8'},
                {id:'9',value:'9'},
                {id:'10',value:'10'},
            ];
            vm.dateConfigKey = "w1";
            vm.$onInit = function(data) {
                updateWeekStyleOptions();
                updateDateStyleOptions();
            }
            vm.$onChanges = function(data) {
                if(data.data&&data.data.currentValue){
                    vm.data = data.data.currentValue;
                }
            }

            function updateWeekStyleOptions() {
                var options = [];
                for (var i = 0; i < vm.data.weekStyles.length; i++) {
                    var style = vm.data.weekStyles[i];
                    options.push({name: style.name, uuid: style.uuid});
                }
                vm.weekStyleOptions = options;
            }

            function updateDateStyleOptions() {
                var options = [
                    {name: "默认样式", uuid: 'default'}
                ];
                for (var i = 0; i < vm.data.dateStyles.length; i++) {
                    var style = vm.data.dateStyles[i];
                    options.push({name: style.name, uuid: style.uuid});
                }
                vm.dateStyleOptions = options;
            }
            //属性栏
            vm.tag = 1;
            $scope.tagClick = function(item){
                vm.tag = item;
            }
            $scope.showOthersCb = function(item){
                vm.data.showOthers = item;
                $scope.widgetUpdate();
            }
            //周设置
            $scope.selectWeekLayout = function() {
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/controls/layout-select.html',
                    controller: 'LayoutSelectController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        msg: function() {
                            return {
                                type: 'WEEK'
                            };
                        }
                    }
                }).result.then(function(res) {
                    if (res.id != vm.data.weekLayoutId) {
                        vm.data.weekLayout = res;
                        vm.data.weekLayoutId = res.id;
                        resetWeekStyles();
                    }
                });
            }
            function resetWeekStyles() {
                for (var k = 0; k < vm.data.weekStyles.length; k++) {
                    var style = vm.data.weekStyles[k];

                    style.items = [];
                    if (vm.data.weekLayout && vm.data.weekLayout.items) {
                        for (var i = 0; i < vm.data.weekLayout.items.length; i++) {
                            var item = vm.data.weekLayout.items[i];

                            style.items.push({
                                name: item.name,
                                type: item.type,
                                format: item.format,
                                sample: item.sample,
                                textFontUuid: "",
                                textFamily: "",
                                textSize: item.textSize,
                                textColor: item.textColor,
                                textAlign: item.textAlign,
                                url: item.url
                            });
                        }
                    }
                }
                $scope.widgetUpdate();
            }
            vm.addWeekStyle = function () {
                var style = {
                    name: "样式" + randomString(),
                    uuid: UidService.get(),
                    backgroundColor: null,
                    border: {
                        lineType: null,
                        lineWidth: 0,
                        color: "#cccccc"
                    },
                    items:[]
                };

                if (vm.data.weekLayout && vm.data.weekLayout.items) {
                    for (var i = 0; i < vm.data.weekLayout.items.length; i++) {
                        var item = vm.data.weekLayout.items[i];

                        style.items.push({
                            name: item.name,
                            type: item.type,
                            format: item.format,
                            sample: item.sample,
                            textFontUuid: "",
                            textFamily: "",
                            textSize: item.textSize,
                            textColor: item.textColor,
                            textAlign: item.textAlign,
                            url: item.url
                        });
                    }
                }
                vm.data.weekStyles.push(style);
                updateWeekStyleOptions();
            }
            vm.editWeekStyle = function (style, $index) {
                var layout = vm.data.weekLayout;
                var myStyle = vm.data.weekStyles[$index];
                var data = {
                    obj: null,
                    settings: myStyle,
                    layout: layout,
                    name:style.name
                };
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/controls/cell-settings-modal.html',
                    controller: 'CellSettingsModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        msg: function() {
                            return data;
                        }
                    }
                }).result.then(function(res) {
                    style.name = res.name;
                    if (res.settings) {
                        vm.data.weekStyles[$index].debug = res.settings.debug;
                        vm.data.weekStyles[$index].backgroundColor = res.settings.backgroundColor;
                        if (res.settings.border) {
                            vm.data.weekStyles[$index].border = res.settings.border;
                        }
                        if (res.settings.items) {
                            vm.data.weekStyles[$index].items = res.settings.items || style.items;
                        }
                        $scope.widgetUpdate();
                    }
                });
            }
            vm.removeWeekStyle = function ($index) {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'确认删除？'
                },function(){
                    var wc = vm.data.weekConfig;
                    var s = vm.data.weekStyles[$index];
                    if (!wc.w1 || wc.w1 == s.uuid) {
                        wc.w1 = 'default';
                    }
                    if (!wc.w2 || wc.w2 == s.uuid) {
                        wc.w2 = 'default';
                    }
                    if (!wc.w3 || wc.w3 == s.uuid) {
                        wc.w3 = 'default';
                    }
                    if (!wc.w4 || wc.w4 == s.uuid) {
                        wc.w4 = 'default';
                    }
                    if (!wc.w5 || wc.w5 == s.uuid) {
                        wc.w5 = 'default';
                    }
                    if (!wc.w6 || wc.w6 == s.uuid) {
                        wc.w6 = 'default';
                    }
                    if (!wc.w7 || wc.w7 == s.uuid) {
                        wc.w7 = 'default';
                    }
                    vm.data.weekStyles.splice($index, 1);
                    updateWeekStyleOptions();
                    $scope.widgetUpdate();
                })
            }
            //日设置
            $scope.selectDateLayout = function() {
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/controls/layout-select.html',
                    controller: 'LayoutSelectController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        msg: function() {
                            return {
                                type: 'DATE'
                            };
                        }
                    }
                }).result.then(function(res) {
                    if (res.id != vm.data.dateLayoutId) {
                        vm.data.dateLayout = res;
                        vm.data.dateLayoutId = res.id;
                        resetDateStyles();
                    }
                });
            }
            function resetDateStyles() {
                for (var k = 0; k < vm.data.dateStyles.length; k++) {
                    var style = vm.data.dateStyles[k];

                    style.items = [];
                    if (vm.data.dateLayout && vm.data.dateLayout.items) {
                        for (var i = 0; i < vm.data.dateLayout.items.length; i++) {
                            var item = vm.data.dateLayout.items[i];

                            style.items.push({
                                name: item.name,
                                type: item.type,
                                format: item.format,
                                sample: item.sample,
                                textFontUuid: "",
                                textFamily: "",
                                textSize: item.textSize,
                                textColor: item.textColor,
                                textAlign: item.textAlign,
                                url: item.url
                            });
                        }
                    }
                }
                $scope.widgetUpdate();
            }
            vm.addDateStyle = function () {
                var style = {
                    name: "样式" + randomString(),
                    uuid: UidService.get(),
                    backgroundColor: null,
                    border: {
                        lineType: null,
                        lineWidth: 0,
                        color: "#cccccc"
                    },
                    items:[]
                };

                if (vm.data.dateLayout && vm.data.dateLayout.items) {
                    for (var i = 0; i < vm.data.dateLayout.items.length; i++) {
                        var item = vm.data.dateLayout.items[i];

                        style.items.push({
                            name: item.name,
                            type: item.type,
                            format: item.format,
                            sample: item.sample,
                            textFontUuid: "",
                            textFamily: "",
                            textSize: item.textSize,
                            textColor: item.textColor,
                            textAlign: item.textAlign,
                            url: item.url
                        });
                    }
                }
                vm.data.dateStyles.push(style);
                updateDateStyleOptions();
            }
            vm.editDateStyle = function (style, $index) {

                var layout = vm.data.dateLayout;
                var myStyle = vm.data.dateStyles[$index];

                var data = {
                    obj: null,
                    settings: myStyle,
                    layout: layout
                };
                $uibModal.open({
                    templateUrl: 'app/entities/calendar/controls/cell-settings-modal.html',
                    controller: 'CellSettingsModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        msg: function() {
                            return data;
                        }
                    }
                }).result.then(function(res) {
                    if (res.settings) {
                        vm.data.dateStyles[$index].debug = res.settings.debug;
                        vm.data.dateStyles[$index].backgroundColor = res.settings.backgroundColor;
                        if (res.settings.border) {
                            vm.data.dateStyles[$index].border = res.settings.border;
                        }
                        if (res.settings.items) {
                            vm.data.dateStyles[$index].items = res.settings.items || style.items;
                        }
                        $scope.widgetUpdate();
                    }
                });
            }
            vm.removeDateStyle = function ($index) {
                MessageService.confirm({
                    title:'提示',
                    cancel:'取消',
                    confirm:'确认',
                    msg:'确认删除？'
                },function(){
                    var dc = vm.data.dateConfig;
                    var s = vm.data.dateStyles[$index];
                    if (!dc.w1 || dc.w1 == s.uuid) {
                        dc.w1 = 'default';
                    }
                    if (!dc.w2 || dc.w2 == s.uuid) {
                        dc.w2 = 'default';
                    }
                    if (!dc.w3 || dc.w3 == s.uuid) {
                        dc.w3 = 'default';
                    }
                    if (!dc.w4 || dc.w4 == s.uuid) {
                        dc.w4 = 'default';
                    }
                    if (!dc.w5 || dc.w5 == s.uuid) {
                        dc.w5 = 'default';
                    }
                    if (!dc.w6 || dc.w6 == s.uuid) {
                        dc.w6 = 'default';
                    }
                    if (!dc.w7 || dc.w7 == s.uuid) {
                        dc.w7 = 'default';
                    }
                    if (!dc.others || dc.others == s.uuid) {
                        dc.others = 'default';
                    }
                    vm.data.dateStyles.splice($index, 1);
                    updateDateStyleOptions();
                    $scope.widgetUpdate();
                })
            }
            function randomString() {
                return Math.random().toString(36).slice(-5);
            }
            $scope.widgetUpdate = function() {
                $timeout(function () {
                    vm.onChange({text: JSON.stringify(vm.data)});
                })
            }

        }]
    };
    angular.module('editorApp')
        .component('calendarSet', calendarSetControl);

})();
