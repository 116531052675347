(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('WebWechatController', WebWechatController);

    WebWechatController.$inject = ['$state', '$q', '$scope', '$location', '$stateParams', '$localStorage', 'PlatformWechatSetting',
        '$uibModal', 'MessageService', 'Theme', 'SweetAlert', 'entity', 'Store', 'Qinius', '$interval', 'WechatAuth', 'Account'
    ];

    function WebWechatController($state, $q, $scope, $location, $stateParams, $localStorage, PlatformWechatSetting,
        $uibModal, MessageService, Theme, SweetAlert, entity, Store, Qinius, $interval, WechatAuth, Account) {

        var vm = this;
        vm.customer = entity;
        vm.infoContent = true;
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.store = Store.get({id: vm.storeId});
        vm.domains = Qinius.getDomain1();
        vm.platformWechatSetting = PlatformWechatSetting.get();
        //微信扫码的定时器
        var timer2 = null;

        $scope.showQrCode = function(){
            vm.infoContent = false;
            var obj = new WxLogin({
                id: "login_container",
                appid: vm.platformWechatSetting.webAppId,
                scope: "snsapi_login",
                redirect_uri: encodeURIComponent(vm.platformWechatSetting.editorNotifyUrl+ "/#/"+ vm.storeId+"/wechat"),
                state: "",
                style: "black",
                href: ""});
            timer2 = $interval(function () {
                if (location.hash.indexOf("?")>=0) {
                    var data = {};
                    $interval.cancel(timer2);
                    var search = location.hash.substring(location.hash.indexOf("?")+1);
                    var searchList = search.split("&");
                    if (searchList.length == 2) {
                        for (var i = searchList.length - 1; i >= 0; i--) {
                            var params = searchList[i].split("=");
                            if (params.length >= 2) {
                                data[params[0]]=params[1];
                            }
                        }
                    }
                    WechatAuth.auth_notify4({storeId:vm.storeId, code:data.code}, function(value){
                        if (value.status == 404) {
                            MessageService.error(value.message);
                        }else if (value.status == 200){
                            Account.get().$promise.then(getAccountThen);
                            vm.infoContent = true;
                            location.href =  location.href.substring(0, location.href.indexOf("?"));
                        }
                        },function(){
                            MessageService.error("认证失败");
                        })
                }
            }, 1000);
        }

        function getAccountThen (account) {
            $localStorage.user = account.data;
            if($localStorage.user.inactive){
                $state.go('outofservice',{storeId: vm.storeId})
            }
            $state.reload();
        }


        $q.all([vm.customer.$promise, vm.store.$promise, vm.domains.$promise, vm.platformWechatSetting.$promise]).then(function() {
             $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        });


    }
})();
