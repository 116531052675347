(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('ExchangeCode', ExchangeCode)
        .factory('GiftVoucher', GiftVoucher);

    GiftVoucher.$inject = ['$resource'];
    ExchangeCode.$inject = ['$resource'];

    function ExchangeCode($resource) {
        var resourceUrl = 'service/' + 'api/exchange-code/:path/:id';

        return $resource(resourceUrl, {}, {
            "toGiftVoucher": {
                method: "get",
                params: {
                    path: "toGiftVoucher"
                },
            }
        });
    }

    function GiftVoucher($resource) {
        var resourceUrl = 'service/' + 'api/gift-vouchers/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition3": {
                method: "POST",
                params: {
                    path: "getAllByCondition3"
                },
                isArray: true
            },
            "byCondition3": {
                method: "POST",
                params: {
                    path: "byCondition3"
                },
                isArray: true
            },
             "giftVoucherAvailable":{
                method: "POST",
                params: {
                    path: "giftVoucherAvailable"
                },
                isArray: true
            },
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
