(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$timeout', 'Auth', 'errorConstants', '$scope', 'MessageService', 'VerifyCode',
     '$interval', 'Customer', '$state', '$stateParams', "$cookieStore"];

    function RequestResetController ($timeout, Auth, errorConstants, $scope, MessageService, VerifyCode,
     $interval, Customer, $state, $stateParams, $cookieStore) {
        var vm = this;

        vm.error = null;
        vm.resetPassword = resetPassword;
        $scope.paracont = "获取验证码";
        vm.storeId = $stateParams.storeId|| $cookieStore.get("store-id");
        if (!vm.storeId) {
            return;
        }
        vm.success = null;
        vm.checkCode = false;
        var timer = null;
        $(".navbar").hide();
        $(".wrap").css({margin:0})

        $timeout(function (){angular.element('#email').focus();});

        $scope.checkVerifyCode = function(){ 
            VerifyCode.check({
                storeId:vm.storeId,
                phone: vm.registerAccount.phone,
                code: vm.registerAccount.code,
            }, function (msg) {
                if (msg.status == 200 && msg.message==true) {
                    vm.checkCode = true;
                }else{
                    vm.checkCode = false;
                   MessageService.error("手机验证失败"); 
                }
            },function(){
                vm.checkCode = false;
                MessageService.error("手机验证失败");
            });
        }


        $scope.getVerifyCode = function () {
            if (vm.registerAccount.phone == null || vm.registerAccount.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            if (vm.disabled) {
                return;
            }
            vm.countdown = 120;
            // 发送验证码到手机
            VerifyCode.getVerifyCode({
                phone: vm.registerAccount.phone,
                storeId:vm.storeId,
                incident: "忘记密码"
            }, function (msg) {
                clearInterval(timer);
                timer = $interval(function () {
                    if (vm.countdown > 0) {
                        vm.disabled = true;
                        vm.countdown -- ;
                        $scope.paracont = "重新发送(" + vm.countdown + ")";
                    } else {
                        vm.disabled = false;
                        $interval.cancel(timer);
                        $scope.paracont = "重发验证码";
                    }
                }, 1000);
            });
        };

        
        function resetPassword () {
            vm.error = null;
            var postData = { 
                phone:vm.registerAccount.phone,
                password:vm.registerAccount.password,
                storeId:vm.storeId
            }
            Customer.changePassword(postData,
                function(data) {
                    MessageService.success("密码重置成功，请使用新密码登录");
                    $state.go("home", {storeId:vm.storeId});
                },
                function() {
                    MessageService.error("重置密码失败，请稍后再试");
                });
        }
    }
})();
