(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory('Page', Page)
        .factory('Category', Category)
        .factory('CustomerUploadAble', CustomerUploadAble)
        .factory('FrameConfigs', FrameConfigs)
        .factory('CalendarService', CalendarService);

    Page.$inject = ['$resource'];
    Category.$inject = ['$resource'];
    CustomerUploadAble.$inject = ['$resource'];
    FrameConfigs.$inject = ['$resource'];
    CalendarService.$inject = ['$resource'];

    function FrameConfigs ($resource) {
        var resourceUrl =  'manager/' + 'api/frame-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition3': {
                method: 'POST',
                params: {
                    path: 'byCondition3'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'uploadAble': {
                method:'GET',
                params: {
                    path: 'uploadAble'
                },
            },
            'update': { method:'PUT' }
        });
    }
    function CustomerUploadAble ($resource) {
        var resourceUrl =  'manager/' + 'api/customer-storages/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'uploadAble': {
                method:'GET',
                params: {
                    path: 'uploadAble'
                },
            },
            'update': { method:'PUT' }
        });
    }
    function Page ($resource) {
        var resourceUrl =  'api/pages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function Category ($resource) {
        var resourceUrl =  'manager/' + 'api/categories/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function CalendarService($resource) {
        var resourceUrl = 'manager/api/calendar-service/:path';
        return $resource(resourceUrl, {}, {
            "getYear":{
                method:"GET",
                params:{
                    path:"getYear"
                },
                isArray:true
            },
            "getYears":{
                method:"GET",
                params:{
                    path:"getYears"
                },
                isArray:true
            },
            "getMonth":{
                method:"GET",
                params:{
                    path:"getMonth"
                }
            },
            'getMonthsOfYear': {
                method: 'GET',
                params:{
                    path:'getMonthsOfYear'
                }
            },
            'getDate': {
                method: 'GET',
                params:{
                    path:'getDate'
                }
            },
            'getDatesOfMonth': {
                method: 'GET',
                params:{
                    path:'getDatesOfMonth'
                }
            },
            'getDatesOfTable': {
                method: 'GET',
                params:{
                    path:'getDatesOfTable'
                }
            },
            'getWeekday': {
                method: 'GET',
                params:{
                    path:'getWeekday'
                }
            },
            'getWeekdays': {
                method: 'GET',
                params:{
                    path:'getWeekdays'
                }
            },
            'queryYearMonthSet': {
                method: "POST",
                params: {
                    path: "queryYearMonthSet"
                }
            },
            'queryWeekdaysSet': {
                method: "POST",
                params: {
                    path: "queryWeekdaysSet"
                }
            },
            'queryMonthdaysSet': {
                method: "POST",
                params: {
                    path: "queryMonthdaysSet"
                }
            },
            'queryMonthTable': {
                method: "POST",
                params: {
                    path: "queryMonthTable"
                }
            }
        });
    }
})();
