(function(){
	"use strict";
	angular
	.module("editorApp")
	.controller("FolderDialogController", FolderDialogController);
	FolderDialogController.$inject = ['$scope', '$uibModalInstance', 'MessageService', '$stateParams',  'Folder', 'entity'];

    function FolderDialogController( $scope,  $uibModalInstance, MessageService, $stateParams,  Folder, entity) {

       var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.id = null;
        vm.newFolder = true;
        if(entity.id){
            vm.newFolder = false;
            Folder.get({id:entity.id},function(data){
                vm.folder = data;
            })
        }else{
            vm.folder = {type:entity.type};
        }


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }



        $scope.validate = function () {
            if (vm.folder.name == null || vm.folder.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            if (entity.id) {
                Folder.update(vm.folder , onSaveSuccess, onSaveError)
            }else{
                Folder.save(vm.folder ,onSaveSuccess, onSaveError)
            }

        }
        function onSaveSuccess(result) {
            MessageService.success("新建文件夹成功");
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            MessageService.error("新建文件夹失败");
            vm.isSaving = false;
        }

    }

})();
