(function () {
    'use strict';

    angular
        .module('editorApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('product', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/product',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/product-detail.html',
                        controller: 'ProductDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('product.choose', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{soid}/{plid}/editorPhotoAlbum',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })

            //新版编辑器
            .state('product.chooseNew', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{soid}/{plid}/editorPhotoAlbum',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })

            //edit---->chooses
            .state('product.chooses', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/editorPhotoAlbumEdit',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })

            //新版编辑器edit---->chooses
            .state('product.choosesNew', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/editorPhotoAlbumEdit',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })

            //万物编辑器
            .state('product.chooseThree', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{soid}/{plid}/chooseThree',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/productsThree.html',
                        controller: 'ProductThreeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })
            //新增编辑器Three
            .state('product.chooseThree1', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{soid}/{plid}/chooseThree1',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/productsThree1.html',
                        controller: 'ProductThree1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {id: null};
                    }
                }
            })

            // .state('product.choosesThree', {
            //     parent: 'homeEditor',
            //     url: '/{storeId}/{aid}/choosesThree',
            //     data: {
            //         authorities: [],
            //         pageTitle: 'editorApp.product.home.title2'
            //     },
            //
            //     views: {
            //         'editor': {
            //             templateUrl: 'app/entities/product/productsThree.html',
            //             controller: 'ProductThreeController',
            //             controllerAs: 'vm'
            //         }
            //     },
            //     params: {
            //         page: {
            //             value: '1',
            //             squash: true
            //         },
            //         sort: {
            //             value: 'id,asc',
            //             squash: true
            //         },
            //         search: null
            //     },
            //     resolve: {
            //         pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            //             return {
            //                 page: PaginationUtil.parsePage($stateParams.page),
            //                 sort: $stateParams.sort,
            //                 predicate: PaginationUtil.parsePredicate($stateParams.sort),
            //                 ascending: PaginationUtil.parseAscending($stateParams.sort),
            //                 search: $stateParams.search
            //             };
            //         }],
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('product');
            //             $translatePartialLoader.addPart('gallery');
            //             $translatePartialLoader.addPart('global');
            //             return $translate.refresh();
            //         }],
            //         entity: function () {
            //             return {
            //                 id: null,
            //                 type: 'add'
            //             };
            //         }
            //     }
            // })
            .state('product.choosesThree', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/choosesThree',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/productsThree.html',
                        controller: 'ProductThreeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })


            // h5 小程序  作品预览
            .state('product.view', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soId}/{lineId}/view',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.viewTitle'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/product-view.html',
                        controller: 'ProductViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })
            // h5 小程序 作品分享的预览
            .state('product.view2', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/view2/{viewIndex}?customerId&type',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.shareTitle'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/product-view2.html',
                        controller: 'ProductView2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })

            //h5  订单作品分享的预览
            .state('product.viewOrder', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/view/{oid}',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.shareTitle'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/product-view2.html',
                        controller: 'ProductView2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrderArticle', function ($stateParams, OrderArticle) {
                        return OrderArticle.get({id: $stateParams.oid}).$promise;
                    }]
                }
            })

            //小程序  订单中作品的预览  以及订单中的分享页面  --订单预览没有二维码
            .state('product.view3', {
                parent: 'homeEditor',
                url: '/{storeId}/{id}/productView3',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.show'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/product-view2.html',
                        controller: 'ProductView3Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrderArticle', function ($stateParams, OrderArticle) {
                        return OrderArticle.get({id: $stateParams.id}).$promise;
                    }]
                }
            })

            //h5 小程序 (海报编辑器) 作品Three-- 预览
            .state('product.view5', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/view5',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.viewTitle'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/product-viewThree.html',
                        controller: 'ProductViewThreeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })
            //h5 小程序 (海报编辑器) 作品Three--分享
            .state('product.viewShare', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/viewShare?customerId&type',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.shareTitle'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/product-viewThree-share.html',
                        controller: 'ProductViewShareController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Article', function ($stateParams, Article) {
                        return Article.get({id: $stateParams.aid}).$promise;
                    }]
                }
            })

            //h5 小程序 (海报编辑器) 订单作品Three--预览  --订单预览没有二维码
            .state('product.viewPreview', {
                parent: 'homeEditor',
                url: '/{storeId}/{id}/viewPreview',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.detail.show'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/product-viewThree-preview.html',
                        controller: 'ProductViewThreeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrderArticle', function ($stateParams, OrderArticle) {
                        return OrderArticle.get({id: $stateParams.id}).$promise;
                    }]
                }
            })

            .state('editorPhoto', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/{soid}/{plid}/editorPhoto',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photo.html',
                        controller: 'EditorPhotoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'add'
                        };
                    }
                }
            })
            .state('editorPhotoEdit', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/editorPhotoEdit',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.page.home.title'
                },
                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photo.html',
                        controller: 'EditorPhotoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            type: 'edit'
                        };
                    }
                }
            })
            .state('editorPhotoPreview', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/editorPhotoPreview',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photo-preview.html',
                        controller: 'EditorPhotoPreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('editorPhotoPreview1', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/editorPhotoPreview1?customerId&type',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photo-preview1.html',
                        controller: 'EditorPhotoPreviewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            //新增编辑器Three
            .state('product.editorPhotoAlbum', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{soid}/{plid}/editorPhotoAlbum',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })
            .state('product.editorPhotoAlbum-edit', {
                parent: 'homeEditor',
                url: '/{storeId}/{aid}/{soid}/{plid}/editorPhotoAlbumEdit',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-photoAlbum.html',
                        controller: 'EditorPhotoAlbumController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            type: 'edit'
                        };
                    }
                }
            })
            //极简编辑器
            .state('product.editorMinimalism', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/{vid}/{soid}/{plid}/editorMinimalism',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-minimalism.html',
                        controller: 'EditorMinimalismController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })

            //表单编辑器
            .state('product.editorForm', {
                parent: 'homeEditor',
                url: '/{storeId}/{pid}/{vid}/{xmlId}/{soid}/{plid}/editorForm',
                data: {
                    authorities: [],
                    pageTitle: 'editorApp.product.home.title2'
                },

                views: {
                    'editor': {
                        templateUrl: 'app/entities/product/editor-form.html',
                        controller: 'EditorFormController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('gallery');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            });
    }

})();
