(function () {
    'use strict';

    var SkuExtend = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/product/sku-extend.html',
        controllerAs: 'vm',
        bindings: {
            article: '<',
            onClose:'&',
        },

        controller: ['$scope', '$stateParams', '$q', 'Product','$uibModal','Cart','ItemValue','StoreConfig','MessageService','SkuSellLimits','ProductXml','$localStorage','Qinius','Store',
            function ($scope, $stateParams, $q, Product,$uibModal, Cart, ItemValue, StoreConfig, MessageService, SkuSellLimits, ProductXml, $localStorage,Qinius, Store) {
                var vm = this;
                $scope.article = vm.article;
                vm.product ={};
                vm.property =[];
                vm.itemValueLine = null;
                vm.shoppingCart ={};
                vm.price ="";
                vm.limitQuantity = null;
                vm.warningQuantity = null;
                vm.store = Store.get({id: $scope.article.storeId});
                $scope.domains = Qinius.getDomain1();
                $q.all([$scope.domains.$promise, vm.store.$promise]).then(function () {
                    $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                    init();
                })
                function init() {
                    vm.producxml = ProductXml.get({id:vm.article.productXmlId});
                    vm.storeCart = StoreConfig.byStoreIdAndType({
                        storeId: vm.article.storeId,
                        type:'SHOPPINGCART'
                    });
                    Product.get({id:vm.article.productId}, function (res) {
                        vm.product = res;
                        ItemValue.get({id: vm.article.itemValueId}, function (msg) {
                            vm.itemValue = msg;
                            vm.quantity = vm.itemValue.minimumOrder || 1;
                            getProperty()
                        });
                    })
                };
                function getProperty(){
                    for(var i=0; i<vm.product.objectProperties.length; i++){
                        if(!vm.product.objectProperties[i].cancelled && vm.product.objectProperties[i].propertyType === 'Expanded'){
                            var _property = {
                                name: vm.product.objectProperties[i].propertyName,
                                propertyType: vm.product.objectProperties[i].propertyType,
                                values:[]
                            };
                            for(var j=0; j<vm.product.objectProperties[i].propertyValues.length; j++){
                                var _propertyValues = vm.product.objectProperties[i].propertyValues[j];
                                _propertyValues.propertySku = _propertyValues.propertyId + '_' + _propertyValues.id;
                                if(!_propertyValues.cancelled && getShow(_propertyValues.propertySku)){
                                    _propertyValues.selectable = true;
                                    _property.values.push(_propertyValues)
                                }
                            }
                            if(_property.values.length>0){
                                vm.property.push(_property)
                            }
                        }
                    };
                    if(vm.property.length==0){
                        var itemValueLine = null;
                        for(var i1=0; i1<vm.itemValue.itemValueLines.length; i1++){
                            var _itemValueLines = vm.itemValue.itemValueLines[i1];
                            if(!_itemValueLines.cancelled && _itemValueLines.skuNumber == vm.itemValue.skuNumber){
                                itemValueLine = _itemValueLines;
                            }
                        }
                        vm.itemValueLine = itemValueLine;
                        getPrice();
                    }
                };
                function getShow(propertySku){
                    for(var i1=0; i1<vm.itemValue.itemValueLines.length; i1++){
                        var _itemValueLines = vm.itemValue.itemValueLines[i1];
                        if(!_itemValueLines.cancelled && _itemValueLines.saleable){
                            if(_itemValueLines.skuNumber.indexOf(propertySku) >= 0){
                                return true
                            }
                        }
                    }
                    return false
                };
                $scope.propertySelect = function(parentIndex, index, item){
                    if(!item.selectable){
                        return
                    }
                    vm.itemValueLine = "";
                    vm.price="";
                    vm.amount = "";
                    vm.limitQuantity = null;
                    vm.warningQuantity = null;
                    var _propertySku = [];
                    item.selected = !item.selected ? true:false;
                    if(item.selected){
                        vm.property[parentIndex].values.forEach(function (value,valueI){
                            value.selectable = true;
                            if(valueI != index){
                                value.selected = false;
                            }
                        })
                    }
                    for(var b=0; b<vm.property.length; b++){
                        var _selected = false;
                        for(var b1=0; b1<vm.property[b].values.length; b1++){
                            if(vm.property[b].values[b1].selected){
                                _propertySku.push(vm.property[b].values[b1].propertySku);
                                _selected = true;
                            }
                        }
                    }
                    for(var b=0; b<vm.property.length; b++){
                        for(var b1=0; b1<vm.property[b].values.length; b1++){
                            if(!vm.property[b].values[b1].selected){
                                a(b,vm.property[b].values[b1])
                            }
                        }
                    }
                    if(_propertySku.length>0 && _propertySku.length == vm.property.length){
                        vm.itemValueLine = getItemValueLine(_propertySku);
                        if(vm.itemValueLine){
                            getPrice()
                        }
                    }
                };
                function a(index,property){
                    var _sku=[];
                    for(var b=0; b<vm.property.length; b++){
                        if(index != b){
                            for(var b1=0; b1<vm.property[b].values.length; b1++){
                                if(vm.property[b].values[b1].selected){
                                    _sku.push(vm.property[b].values[b1].propertySku);
                                }
                            }
                        }
                    }
                    var __propertySku = angular.copy(_sku);
                    __propertySku.push(property.propertySku);
                    if(getItemValueLine(__propertySku)){
                        property.selectable = true;
                    }else{
                        property.selectable = false;
                    }
                }
                function getItemValueLine(propertySku){
                    var _itemValueLine = null;
                    for(var i1=0; i1<vm.itemValue.itemValueLines.length; i1++){
                        var _itemValueLines = vm.itemValue.itemValueLines[i1];
                        if(!_itemValueLines.cancelled && _itemValueLines.saleable){
                            if(getSku(_itemValueLines.skuNumber,propertySku)){
                                _itemValueLine = _itemValueLines;
                                break
                            }
                        }
                    }
                    return _itemValueLine
                };
                function getSku(skuNumber,skus){
                    var _rt = true;
                    for(var i=0;i<skus.length;i++){
                        if(skuNumber.indexOf(skus[i]) == -1){
                            _rt = false;
                            break
                        }
                    }
                    return _rt
                }
                function getPrice(callback){
                    if(!vm.itemValueLine){
                        MessageService.hint("请选择完整的属性");
                        return
                    }
                    var quantity = vm.article.pageCount ? vm.article.pageCount : vm.page.length;
                    if(vm.article.pictureType == 'spread'){
                        quantity = quantity/2;
                    }
                    var parameter = {
                        productId: vm.product.id,
                        itemValueId: vm.itemValueLine.itemValueId,
                        itemValueLineId: vm.itemValueLine.id,
                        quantity:quantity,
                        copy:vm.quantity
                    }
                    Product.getCostV2(parameter,function(data){
                        if (data.status == 200) {
                            vm.amount = data.message.toFixed(2);
                            if(vm.store.orderWay=='editorThenOrder'&&vm.article.jhiPackage&&(vm.article.pictureType == 'spread'?vm.article.pageCount/2:vm.article.pageCount) != vm.article.packageQuantity){
                                if(vm.article.pictureType == 'spread'){
                                    vm.price = (data.message/(vm.article.pageCount/2/vm.article.packageQuantity)/vm.quantity);
                                }else{
                                    vm.price = (data.message/(vm.article.pageCount/vm.article.packageQuantity)/vm.quantity);
                                }
                            }
                            console.log(vm.price)
                            if(callback){callback()}
                        }
                    },function(error){
                        MessageService.hint(error.data.errorMessage);
                    });

                    SkuSellLimits.getByItemValueLineId({productId:vm.article.productId,itemValueLineId:vm.itemValueLine.id},function(res){
                        if (res.message) {
                            vm.limitQuantity = res.message.limitQuantity
                            vm.warningQuantity = res.message.warningQuantity
                        }
                    })
                };
                $scope.addQuantity = function(){
                    if (vm.quantity >= vm.limitQuantity  && vm.limitQuantity!=null) {
                        MessageService.hint("最多可购买" + vm.limitQuantity + "件");
                        return;
                    }
                    vm.quantity = vm.quantity + 1;
                    if(vm.itemValueLine){
                        getPrice()
                    }
                };
                $scope.subtract = function(){
                    if(vm.quantity <= vm.itemValue.minimumOrder){
                        MessageService.hint("购买数量不能小于" + vm.itemValue.minimumOrder);
                        return
                    }
                    if(vm.quantity == 1){
                        MessageService.hint("购买数量不能小于1");
                        return
                    }
                    vm.quantity--
                    if(vm.itemValueLine){
                        getPrice()
                    }
                };

                $scope.num = function(quantity){
                    if (quantity == 0 || quantity == null || quantity <= vm.itemValue.minimumOrder) {
                        quantity = vm.itemValue.minimumOrder
                    }
                    if(vm.limitQuantity != null && vm.quantity > vm.limitQuantity){
                        MessageService.hint("最多可购买" + vm.limitQuantity + "件");
                        vm.quantity = vm.limitQuantity;
                        return;
                    }
                    vm.quantity = Math.ceil(quantity);
                    getPrice();
                }

                $scope.shoppingSave = function(){
                    getPrice(function (){
                        Cart.addGoods({
                            articleId: vm.article.id,
                            copy: vm.quantity,
                            quantity: vm.article.pictureType == 'spread' ? vm.article.pageCount/2:vm.article.pageCount,
                            productId: vm.article.productId,
                            itemValueLineId:vm.itemValueLine.id,
                            itemValueId: vm.article.itemValueId,
                            orderWay: vm.store.orderWay,
                            productXmlId: vm.article.productXmlId,
                        }, function (res) {
                            if(res.status == 400){
                                swal({
                                    title: "失败",
                                    text: res.message || "加入购物车失败",
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                return
                            }
                            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {

                                if(localStorage.getItem('distinguish') == 'jdApp'){
                                    jd.miniProgram.redirectTo({url: '/pages/shop/shoppingCar/shoppingCar'})
                                    return
                                }
                                uni.redirectTo({
                                    url: '/pages/shop/shoppingCar/shoppingCar'
                                })
                            } else {
                                window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/cart';
                            }
                        })
                    })
                };
                $scope.purchaseSave = function(){
                    if(!vm.itemValueLine){
                        MessageService.hint("请选择完整的属性");
                        return
                    }
                    if (vm.limitQuantity === 0 || (vm.limitQuantity < vm.itemValue.minimumOrder && vm.limitQuantity != null)) {
                        MessageService.hint("库存不足，无法购买更多");
                        return
                    }
                    if (!articleCheck(vm.article, vm.itemValue,vm.producxml)) {
                        MessageService.hint("该作品已经下架，不能再购买");
                        return;
                    }else if (!vm.article.completed) {
                        MessageService.hint("该作品还未完成，不能购买");
                        return;
                    }
                    $scope.close();
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/modal/protocol-agreement2.html',
                        controller: 'OrderProtocolAgreement2Controller',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: [function () {
                                var _obj = {
                                    orderWay: true
                                }
                                return _obj;
                            }]
                        }
                    }).result.then(function (data) {
                        if (data && data.agree) {
                            getPrice(function (){
                                if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                                    var str = JSON.stringify({
                                        articleId:vm.article.id,
                                        itemValueLineId:vm.itemValueLine.id,
                                        productId: vm.article.productId,
                                        itemValueId: vm.itemValue.id,
                                        copy:vm.quantity,
                                    });
                                    if(localStorage.getItem('distinguish') == 'jdApp'){
                                        jd.miniProgram.redirectTo({url: '/pages/shop/placeOrder/placeOrder?jsonStr=' + str})
                                        return
                                    }
                                    uni.redirectTo({
                                        url: '/pages/shop/placeOrder/placeOrder?jsonStr=' + str
                                    })
                                } else {
                                    var arr={
                                        ids: vm.article.id,
                                        quantity: vm.quantity,
                                        itemValueLineId: vm.itemValueLine.id,
                                        lineSku: vm.itemValueLine.sku,
                                        lineSkuNumber: vm.itemValueLine.skuNumber
                                    }
                                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/' + vm.article.id + '/1/'+ arr.itemValueLineId +'/'+ vm.itemValue.id +'/'+ arr.quantity +'/'+ vm.article.productId + '//new2';
                                }
                            })
                        }
                    })
                };
                $scope.close = function() {
                    vm.onClose();
                };
            }]
    };

    angular
        .module('editorApp')
        .component('skuExtend', SkuExtend);
})();
