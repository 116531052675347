(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('UserAgentController', UserAgentController);

    UserAgentController.$inject = ['$localStorage', '$scope', '$stateParams', '$uibModalInstance'];

    function UserAgentController ($localStorage, $scope, $stateParams, $uibModalInstance) {
        var vm = this;
        localStorage.setItem("userAgentTime",time());
        function time() {
            var d = new Date();
            var getFullYear = d.getFullYear();
            var getMonth = d.getMonth() + 1;
            var getDate = d.getDate();
            return getFullYear + '-' + getMonth + '-' + getDate
        }
        //返回
        $scope.cancel = function() {
            $uibModalInstance.close();
        }
    }
})();
